/** @format */

import * as React from 'react';
import classnames from 'classnames';
import * as Models from '../models';
import { Props as AddFilterComponentProps } from './index';
import { SearchResult } from 'interfaces/search';
import { isNil, get, assign } from 'lodash';
import { SearchDropdown } from 'components/dropdown/search';
import { SearchDropdownContainerProps } from 'components/dropdown/search/models';

type RequiredDropdownContainerProps = {
  searchPool: SearchResult[];
  handleQueryChange: (query: string) => void;
} & Partial<SearchDropdownContainerProps>;

type Props = {
  handleAddFilterClick: () => void;
  handleAddFilterKeyDown: () => void;
  handleResultSelect: (values: SearchResult[]) => void;
  searchDropdownProps: RequiredDropdownContainerProps;
} & AddFilterComponentProps;

class AddFilterComponent extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const animatedSearchButtonClassNames = classnames('animated-search-button', {
      'animated-search-button--pending': this.props.currentMode === Models.Mode.SelectDimension,
    });
    let addFilterButtonTitle = this.props.addFilterButtonText;
    let pendingFilterButtonTitle = 'Close';

    // TODO: Enable this when live
    if ((window as any).Mousetrap) {
      addFilterButtonTitle += ' (Shortcut: Ctrl+Shift+F)';
      pendingFilterButtonTitle += ' (Shortcut: Esc)';
    }

    const addFilterButton: JSX.Element = (
      <button
        className="add-filter-button"
        title={addFilterButtonTitle}
        onClick={this.props.handleAddFilterClick}
        onKeyDown={this.props.handleAddFilterKeyDown}
      >
        <span className="add-filter-button__text">{this.props.addFilterButtonText}</span>
        <span className="add-filter-button__icon filter-button-icon">
          <span className={`flat-icon icon-${this.props.containerHeight / 2} plus-icon`} />
        </span>
      </button>
    );

    const animatedButton: JSX.Element = (
      <button
        className={animatedSearchButtonClassNames}
        onKeyDown={this.props.handleAddFilterKeyDown}
        title={pendingFilterButtonTitle}
      >
        <span className="animated-search-icon">
          <span className="animated-search-icon__blob animated-search-icon__blob--1" />
          <span className="animated-search-icon__blob animated-search-icon__blob--2" />
          <span className="animated-search-icon__blob animated-search-icon__blob--3" />
        </span>
      </button>
    );

    const showAddButton = this.props.currentMode === Models.Mode.Inactive;
    const showManualCancel = this.props.currentMode > Models.Mode.SelectDimension;
    const showLoading = this.props.currentMode === Models.Mode.SelectValue;
    const selectMultiple = get(this.props.selectedComparator, 'multiSelect', false);
    const allowFreeText = get(this.props.selectedComparator, 'allowFreeText', false);
    const showManualSubmit = this.props.currentMode === Models.Mode.SelectValue && selectMultiple;
    const noResultsText = this.props.supportAdvancedSearch
      ? 'No matching filters, hit return to apply advanced search'
      : 'No search results';

    const searchDropdownProps: SearchDropdownContainerProps = assign(
      {},
      this.props.searchDropdownProps,
      {
        button: showAddButton ? addFilterButton : animatedButton,
        handleAddFilterClick: this.props.handleAddFilterClick,
        handleResultSelect: this.props.handleResultSelect,
        dockOption: 'right-mobile',
        closeOnCancel: true,
        showLoading,
        showManualCancel,
        showManualSubmit,
        selectMultiple,
        allowFreeText,
        noResultsText,
      },
    );

    const filterClassNames = classnames('top-level-filter', {
      'top-level-filter--pending': showAddButton,
    });
    const labelClassNames = classnames('top-level-filter__label', {
      'top-level-filter__label--active': !isNil(this.props.selectedDimension),
    });

    return (
      <div className={filterClassNames}>
        <div className={labelClassNames}>
          <span className="filter-pill-dimension filter-pill-item">
            {get(this.props.selectedDimension, 'displayName', '')}
          </span>
          <span className="filter-pill-comparator filter-pill-item">
            {get(this.props.selectedComparator, 'displayName', '')}
          </span>
          <span className="filter-pill-value filter-pill-item">{''}</span>
        </div>
        <div className="top-level-filter__button">
          <SearchDropdown {...searchDropdownProps} />
        </div>
      </div>
    );
  }
}

export { AddFilterComponent };
