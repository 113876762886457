/** @format **/

import React, { FunctionComponent } from 'react';

import { Center } from 'components/center';

import Emblem from './components/emblem';

export const LogoOnlyHeader: FunctionComponent<{}> = () => (
  <header className="layout__header">
    <Center>
      <div className="raygun-header__emblem-logo-only">
        <Emblem />
      </div>
    </Center>
  </header>
);
