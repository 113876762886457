/**
 * @prettier
 */
import * as React from 'react';
import { Button, DropdownButton } from 'components/button/ui/index';
import { Dropdown, DropdownSection, DropdownOption } from 'components/dropdown';

import { STATUS, RESOLVE_IN_VERSION } from 'constants/status';
import { getColorForStatus, getDisplayForStatus } from 'utils/status';

type StatusDropdownProps = {
  onStatusChange: (status: string) => void;
  onResolveInVersion: () => void;
  hasPermanentlyIgnorePermission: boolean;
  disabled?: boolean;
};

const options = [STATUS.ACTIVE, STATUS.RESOLVED, STATUS.IGNORED, STATUS.PERM_IGNORED];

export const StatusDropdown: React.FunctionComponent<StatusDropdownProps> = ({
  onStatusChange,
  onResolveInVersion,
  hasPermanentlyIgnorePermission,
}) => {
  const button = (
    <DropdownButton size={32} type="blue">
      Change status
    </DropdownButton>
  );
  return (
    <Dropdown closeOnClick button={button} overflow>
      <DropdownSection>
        {options.map(option => {
          const disabledState = option === STATUS.PERM_IGNORED && !hasPermanentlyIgnorePermission;
          return (
            <DropdownOption
              key={option}
              color={getColorForStatus(option)}
              onClick={() => onStatusChange(option)}
              disabled={disabledState}
              {...option === STATUS.PERM_IGNORED && {
                tooltip: disabledState,
                tooltipMsg: `You don't have permission to permanently ignore error groups.`,
              }}
            >
              {getDisplayForStatus(option)}
            </DropdownOption>
          );
        })}
      </DropdownSection>
      <DropdownSection>
        <DropdownOption color={getColorForStatus(RESOLVE_IN_VERSION)} onClick={onResolveInVersion}>
          {getDisplayForStatus(RESOLVE_IN_VERSION)}
        </DropdownOption>
      </DropdownSection>
    </Dropdown>
  );
};
