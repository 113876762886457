/**
 * prettier
 *
 * @format
 */

import * as React from 'react';
import classnames from 'classnames';

interface Props {
  type: 'text' | 'textarea' | 'date' | 'time' | 'number';
  placeholder?: string;
  value?: string;
  name?: string;
  id?: string;
  isBlock?: boolean;
  isFullWidth?: boolean;
  isFlatLeft?: boolean;
  isFlatRight?: boolean;
  disabled?: boolean;
  hasMargin?: boolean;
  hasError?: boolean;
  size?: '24' | '32' | '36' | '40';
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  options?: { [name: string]: any };
  className?: string;
}

interface GenerateProps {
  classes: string;
  value: string;
  name: string;
  placeholder: string;
  disabled: boolean;
  type: string;
  id: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  options?: { [name: string]: any };
}

export const Text: React.FunctionComponent<Props> = props => {
  const genProps: GenerateProps = {
    value: props.value,
    name: props.name,
    type: props.type,
    placeholder: props.placeholder,
    disabled: props.disabled,
    onKeyDown: props.onKeyDown,
    onKeyUp: props.onKeyUp,
    onChange: props.onChange,
    onBlur: props.onBlur,
    id: props.id,
    classes: classnames(
      'form-text',
      {
        'form-text--24': props.size === '24',
        'form-text--32': props.size === '32',
        'form-text--36': props.size === '36',
        'form-text--block': props.isBlock,
        'form-text--full-width': props.isFullWidth,
        'form-text--flat-right': props.isFlatRight,
        'form-text--flat-left': props.isFlatLeft,
        'form-text--margin-bottom': props.hasMargin,
        'form-text--invalid': props.hasError,
        'form-text--textarea': props.type === 'textarea',
      },
      props.className,
    ),
    options: props.options,
  };

  if (props.type === 'textarea') {
    return generateTextarea(genProps);
  }

  return generateInput(genProps);
};

Text.defaultProps = {
  placeholder: '',
  type: 'text',
  value: '',
  id: '',
  size: '40',
  isBlock: false,
  isFullWidth: false,
  isFlatLeft: false,
  isFlatRight: false,
  hasMargin: false,
  hasError: false,
  disabled: false,
};

const generateTextarea = (props: GenerateProps): React.ReactElement<any> => {
  return (
    <textarea
      name={props.name}
      className={props.classes}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
      onKeyUp={props.onKeyUp}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      value={props.value}
      id={props.id}
      {...props.options}
    />
  );
};

const generateInput = (props: GenerateProps): React.ReactElement<any> => {
  return (
    <input
      type={props.type}
      className={props.classes}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
      onKeyUp={props.onKeyUp}
      onChange={props.onChange}
      onBlur={props.onBlur}
      id={props.id}
      {...props.options}
    />
  );
};

export default Text;
