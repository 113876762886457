/** @format */

import * as React from 'react';
import classnames from 'classnames';

interface Props {
  height: '16' | '24' | '32' | '40' | '48' | number;
  width?: number;
  unit?: 'px' | '%';
  color?: 'grey' | 'blue';
  radius?: boolean;
  rounded?: boolean;
  circle?: boolean;
  center?: boolean;
  halfWidth?: boolean;
  fullWidth?: boolean;
  block?: boolean;
  style?: any;
  alignTop?: boolean;
}

export const Skeleton: React.FunctionComponent<Props> = props => {
  const classes = classnames(`skeleton`, {
    'skeleton--grey': props.color === 'grey',
    'skeleton--blue': props.color === 'blue',

    'skeleton--radius': props.radius,
    'skeleton--rounded': props.rounded,
    'skeleton--circle': props.circle,
    'skeleton--block': props.block,

    'skeleton--center': props.center,

    'skeleton--width-half': props.halfWidth,
    'skeleton--width-full': props.fullWidth,
    [`skeleton--${props.height}`]: typeof props.height === 'string',
    'skeleton--align-top': props.alignTop,
  });

  const style = {
    width: `${props.width}${props.unit}`,
    height: typeof props.height === 'number' ? `${props.height}px` : null,
    ...props.style,
  };

  return <div className={classes} style={style} />;
};

Skeleton.defaultProps = {
  color: 'grey',
  unit: 'px',
  radius: false,
  rounded: false,
  circle: false,
  center: false,
  halfWidth: false,
  fullWidth: false,
  block: false,
};
