/** @format */

import { IStyle } from 'fela';
import { merge } from 'lodash';

export function clearfix(style: IStyle): IStyle {
  return merge(
    { ...style },
    {
      ':before': {
        content: '" "',
        display: 'table',
      },
      ':after': {
        content: '" "',
        display: 'table',
        clear: 'both',
      },
    },
  );
}
