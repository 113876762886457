/** @format */
import { get, isNil, first } from 'lodash';

import { Plan, PlanLevel } from "interfaces/plan";
import { getApplicationForPage } from 'utils/application';
import { getDashboardForPage } from 'utils/dashboard';

export function getDefaultPlanId(): number {
  const planId: number = get(window, 'RaygunConfiguration.PlanId', null);

  if (!isNil(planId)) {
    return planId;
  }

  const app = getApplicationForPage();
  if (!isNil(app)) {
    return app.planId;
  }

  const dashboard = getDashboardForPage();
  if (!isNil(dashboard)) {
    return dashboard.planId;
  }

  const plan: Plan = first(get(window, 'RaygunConfiguration.Plans', []));

  return plan ? plan.Id : 0;
}