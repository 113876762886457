/** @format **/

import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { Theme } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

import { POSITIONS } from './';

function StyledLabeltipWrapper(): IStyle {
  return {
    position: 'relative',
    display: 'inline-block',
  };
}
export const LabeltipWrapper = createComponentWithProxy(StyledLabeltipWrapper);

type LabeltipProps = {
  position: POSITIONS;
};

const StyledLabeltipContainer = (props: LabeltipProps) => {
  const styles: IStyle = {
    position: 'absolute',
    backgroundColor: Theme.ds.getColorByName('blueGrey800'),
    color: Theme.ds.getColorByName('white'),
    padding: '0 8px',
    borderRadius: '2px',
    cursor: 'default',
    userSelect: 'none',
    zIndex: 1,
  };

  return applyModifiers(
    [
      props.position == 'top',
      {
        left: '50%',
        top: '0',
        transform: 'translate(-50%, calc(-100% - 8px))',
      },
    ],
    [
      props.position === 'bottom',
      {
        left: '50%',
        top: '100%',
        marginTop: '8px',
        transform: 'translateX(-50%)',
      },
    ],
    [
      props.position === 'left',
      {
        left: '0',
        top: '50%',
        transform: 'translate(calc(-100% - 8px), -50%)',
      },
    ],
    [
      props.position === 'right',
      {
        left: '100%',
        top: '50%',
        marginLeft: '8px',
        transform: 'translateY(-50%)',
      },
    ],
    [
      props.position === 'alignWithIconRight',
      {
        left: '-164%',
        marginTop: '8px',
        padding: '8px 16px',
        transform: 'translateX(-50%)',
        width: '211px',
      },
    ],
  )(styles);
};
export const LabeltipContainer = createComponentWithProxy(StyledLabeltipContainer);

const StyledLabeltipLabel = (props: LabeltipProps) => {
  const styles: IStyle = {
    display: 'block',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
  };

  return applyModifiers([
    props.position === 'alignWithIconRight',
    {
      whiteSpace: 'normal',
      lineHeight: 1.5,
    },
  ])(styles);
};
export const LabeltipLabel = createComponentWithProxy(StyledLabeltipLabel, 'span');
