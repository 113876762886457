/** @format */

import * as React from 'react';
import classnames from 'classnames';

import {
  getFillStyleForQueryType,
  getTextStyleForQueryType,
  getQueryNameShort,
  getQueryNameLong,
} from 'apm/constants';

interface Props {
  type: string;
  fullWidth?: boolean;
  height32?: boolean;
  onlyIcon?: boolean;
}

export const QueryTypePill: React.FunctionComponent<Props> = ({
  type,
  fullWidth,
  height32,
  onlyIcon,
}) => {
  const classes = classnames('type-pill', {
    'type-pill--full-width': fullWidth,
    'type-pill--margin-4': height32,
    'type-pill--icon': onlyIcon,
  });

  const style = {
    backgroundColor: getFillStyleForQueryType(type),
    color: getTextStyleForQueryType(type),
  };

  return (
    <span className={classes} style={style} title={getQueryNameLong(type)}>
      {getQueryNameShort(type)}
    </span>
  );
};

QueryTypePill.defaultProps = {
  fullWidth: false,
  height32: false,
  onlyIcon: false,
};
