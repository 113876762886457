/** @format **/
import React, { FunctionComponent } from 'react';
import { map, get } from 'lodash';
import { connect } from 'react-redux';

import { PlanSettingsTabsDefinition } from 'sections/productpages/plansettings/components/header/planSettingsTabs/linkDefinitions';
import { Navigation, NavigationItem } from 'components/page/header/navigation';
import { getPlanIdentifier } from 'app/selectors/configuration';

type StateProps = {
  planIdentifier: string;
  showApplicationSection: boolean;
  showInvoicesSection: boolean;
  showConsumersSection: boolean;
};

type Props = StateProps;

export const UnconnectedTabs: FunctionComponent<Props> = props => {
  const tabLinks = PlanSettingsTabsDefinition(props);

  return (
    <Navigation>
      {map(
        tabLinks,
        l =>
          !l.hide && (
            <NavigationItem
              key={l.label}
              text={l.label}
              to={l.to}
              isActive={l.isActive}
              color={'blue'}
            />
          ),
      )}
    </Navigation>
  );
};

export const Tabs = connect<StateProps, {}, {}, any>(state => ({
  planIdentifier: getPlanIdentifier(state),
  showApplicationSection: get(window, 'RaygunPageData.ShowApplicationSection', false),
  showInvoicesSection: get(window, 'RaygunPageData.ShowInvoicesSection', false),
  showConsumersSection: get(window, 'RaygunPageData.ShowConsumersSection', false),
}))(UnconnectedTabs);
