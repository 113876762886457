/** @format */

import { find, isNil } from 'lodash';

import { Application } from 'interfaces/application';
import { PlanLevel } from 'interfaces/plan';
import { Intervals } from 'utils/echarts';
import * as raygun from 'utils/raygun';

import * as Models from '../models';
import * as Actions from '../actions';

type SelectItem = {
  value: string;
  display: string;
};

type DateRangeGranularityOptions = {
  dateRange: string;
  granularities: SelectItem[];
};

export enum DateRangeValues {
  HOUR = '1h',
  DAY = '1d',
  TWO_DAYS = '2d',
  WEEK = '7d',
  TWO_WEEKS = '14d',
  MONTH = '30d',
}

export const DATE_RANGES: SelectItem[] = [
  {
    value: DateRangeValues.HOUR,
    display: 'The past hour',
  },
  {
    value: DateRangeValues.DAY,
    display: 'The past day',
  },
  {
    value: DateRangeValues.TWO_DAYS,
    display: 'The past 2 days',
  },
  {
    value: DateRangeValues.WEEK,
    display: 'The past week',
  },
  {
    value: DateRangeValues.TWO_WEEKS,
    display: 'The past 2 weeks',
  },
  {
    value: DateRangeValues.MONTH,
    display: 'The past month',
  },
];

export enum GranularityValues {
  MINUTE = '1m',
  TEN_MINUTES = '10m',
  ONE_HOUR = '1h',
  ONE_DAY = '1d',
}

export const GRANULARITIES: SelectItem[] = [
  {
    value: GranularityValues.MINUTE,
    display: 'One minute',
  },
  {
    value: GranularityValues.TEN_MINUTES,
    display: 'Ten minute',
  },
  {
    value: GranularityValues.ONE_HOUR,
    display: 'One hour',
  },
  {
    value: GranularityValues.ONE_DAY,
    display: 'One day',
  },
];

export const RESOURCES: SelectItem[] = [
  {
    value: 'XHR',
    display: 'XHR',
  },
  {
    value: 'PAGE',
    display: 'Page',
  },
];

export const GRANULARITIES_BY_DATE_RANGE: DateRangeGranularityOptions[] = [
  {
    dateRange: DateRangeValues.HOUR,
    granularities: GRANULARITIES.slice(0, 2),
  },
  {
    dateRange: DateRangeValues.DAY,
    granularities: GRANULARITIES.slice(1, 3),
  },
  {
    dateRange: DateRangeValues.TWO_DAYS,
    granularities: GRANULARITIES.slice(1, 3),
  },
  {
    dateRange: DateRangeValues.WEEK,
    granularities: GRANULARITIES.slice(2),
  },
  {
    dateRange: DateRangeValues.TWO_WEEKS,
    granularities: GRANULARITIES.slice(2),
  },
  {
    dateRange: DateRangeValues.MONTH,
    granularities: GRANULARITIES.slice(2),
  },
];

export function getDefaultIntervalForDateRange(dateRange: DateRangeValues): Intervals {
  switch (dateRange) {
    case DateRangeValues.HOUR:
      return GranularityValues.MINUTE;
    case DateRangeValues.DAY:
      return GranularityValues.TEN_MINUTES;
    case DateRangeValues.TWO_DAYS:
    case DateRangeValues.WEEK:
    case DateRangeValues.TWO_WEEKS:
    case DateRangeValues.MONTH:
      return GranularityValues.ONE_HOUR;
    default:
      raygun.logError(new Error(`Unsupported date range '${dateRange}'`));
      return GranularityValues.ONE_HOUR;
  }
}

/**
 * Used by time boards to only show settings cog on tiles when settings other than those below are available for edit.
 */
export const TIMEBOARD_CAN_IGNORE_SETTINGS: string[] = [
  'DateRange',
  'Applications',
  'Title',
  'TableSortingDirection',
  'TextAlignment',
  'TextSize',
];

/**
 * Used by time boards to only show settings cog on tiles whose types are other than those below.
 */
export const TIMEBOARD_CAN_IGNORE_TYPES: string[] = ['textBox', 'textHeader'];

export function granularitiesForDateRange(dateRange: string) {
  return find(GRANULARITIES_BY_DATE_RANGE, x => x.dateRange === dateRange).granularities;
}

export const pulseRequired = (app: Application) => app.pulseEnabled && app.rumHasSentData;
export const crashReportingRequired = (app: Application) =>
  app.crashReportingEnabled && app.crashReportingHasSentData;
export const apmRequired = (app: Application) => app.apmEnabled && app.apmHasSentData;
export const planLevelSBAndAbove = (app: Application) => {
  const planForApplication = find(
    (window as any).RaygunConfiguration.Plans,
    (p: any) => p.Id === app.planId,
  );
  if (isNil(planForApplication)) {
    return false;
  }

  return planForApplication.PlanLevel >= PlanLevel.Medium;
};
export const featureFlagged = (featureFlag: string) => () => {
  return (window as any).RaygunConfiguration.Features.indexOf(featureFlag) > -1;
};
export const fetchAction = (payload: Models.FetchActionPayload) => [Actions.fetchTileData(payload)];
export const DEFAULT_DATE_RANGE = DATE_RANGES[1].value;
export const DEFAULT_GRANULARITY = granularitiesForDateRange(DEFAULT_DATE_RANGE)[0].value;
export const DEFAULT_RESOURCE_TYPE = RESOURCES[0].value;
