/** @format */
import { get, isNil, find } from 'lodash';

import { Dashboard } from 'interfaces/dashboard';

export function getDashboardForPage(): Dashboard {
  const dashboardId: number = get(window, 'RaygunConfiguration.DashboardId', null);

  if(!isNil(dashboardId)) {
    const dashboards: Dashboard[] = get(window, 'RaygunPageData.Dashboards', []);
    const matchingDashboard = find(dashboards, d => d.id === dashboardId);
    if(!isNil(matchingDashboard)) {
      return matchingDashboard;
    }
  }

  return null;
}