/** @format **/

import * as React from 'react';
import { Center } from 'components/center';
import { Padding } from 'components/layout';

export const AnonymousHeader: React.FunctionComponent<{}> = () => (
  <header className="layout__header layout__header--dark">
    <Center width={1120}>
      <Padding left="12" right="12">
        <a href="https://raygun.com" className="raygun-logo raygun-logo--no-margin">
          Raygun
        </a>
      </Padding>
    </Center>
  </header>
);
