/** @format */

import React, { useRef, useEffect, useCallback } from 'react';

type UseOutsideArguments = {
  onOutsideClick: () => void;
};

export function useOutsideClick(args: UseOutsideArguments) {
  const targetRef = useRef<HTMLElement>(); 
  
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (targetRef.current && !targetRef.current.contains(event.target as HTMLElement)) {
        const _ignored = args.onOutsideClick && args.onOutsideClick();
      }
    },
    [args.onOutsideClick]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return {
    /** 
     * Passing as any since the TypeSystems don't like passing references to elements 
     */
    ref: targetRef as any
  };
}