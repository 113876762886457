/** @format **/

import * as React from 'react';
import { get, filter } from 'lodash';

import { Small, Medium } from 'components/breakpoints';

import * as Selectors from '../../../../selectors';
import * as Actions from '../../../../actions';
import * as Constants from '../../../../constants';
import { ErrorsPerAppLoading } from '../../loading/errorsPerApp';
import { SortingHoC, SortingHoCProps } from '../../../sortingHoC';
import { TileDataProvider, Props as ProviderProps } from '../../dataProvider';
import { NoData } from '../../noData';

import { NavigationCellFactory } from './navigationCell';
import { ErrorGroupDrilldown } from './errorGroupDrilldown';
import { ContentWrapper } from './contentWrapper';
import { Footer } from './footer';
import { ActionBar } from './actionBar';
import { ErrorsPerAppTable } from './table';
import { ErrorsPerAppTableMobile } from './tableMobile';

type Props = ProviderProps & SortingHoCProps;
class ErrorsPerAppListTile extends React.Component<Props, {}> {
  NavigationCell: React.FunctionComponent<Actions.ErrorsPerAppListData>;

  constructor(props: Props) {
    super(props);

    this.updateViewedApplication = this.updateViewedApplication.bind(this);
    this.backToTileRoot = this.backToTileRoot.bind(this);

    this.NavigationCell = NavigationCellFactory(this.updateViewedApplication);
  }

  updateViewedApplication(appId: number) {
    this.props.updateTileState({
      isDrilledDown: true,
      viewingApplication: appId,
    });
  }

  backToTileRoot() {
    this.props.updateTileState({
      isDrilledDown: false,
      viewingApplication: Constants.DUMMY_APP_ID,
    });
  }

  render() {
    const data = get(this.props, 'data', []) as Actions.ErrorsPerAppListData[];
    const filteredData = filter(data, d => d.ErrorGroupCount > 0);

    if (filteredData.length === 0) {
      return (
        <ContentWrapper>
          <NoData />
        </ContentWrapper>
      );
    }

    if (get(this.props.tileState, 'isDrilledDown', false)) {
      return (
        <ErrorGroupDrilldown
          appId={this.props.tileState.viewingApplication}
          backToRoot={this.backToTileRoot}
          parentTileId={this.props.instanceId}
          updateSorting={this.props.updateSorting}
          updatePageIndex={this.props.updatePageIndex}
          sortDirection={this.props.sortDirection}
          sortField={this.props.sortField}
        />
      );
    }

    return (
      <>
        <ActionBar id={this.props.instanceId} />

        <ContentWrapper>
          <Small>
            <ErrorsPerAppTableMobile
              data={filteredData}
              updateViewedApplication={this.updateViewedApplication}
            />
          </Small>

          <Medium andAbove>
            <ErrorsPerAppTable
              id={this.props.instanceId}
              data={filteredData}
              navigationCell={this.NavigationCell}
              updateSorting={this.props.updateSorting}
              sortDirection={this.props.sortDirection}
              sortField={this.props.sortField}
            />
          </Medium>
        </ContentWrapper>
      </>
    );
  }
}

const ConnectedTile = TileDataProvider(
  SortingHoC(ErrorsPerAppListTile, { refreshOnSortingChange: false }),
  ErrorsPerAppLoading,
);

ConnectedTile.moduleProps = {
  noPadding: true,
  footer: Footer,
  renderFooter: (state: any, id: string) =>
    get(Selectors.getSelectedErrorGroups(state), id, []).length > 0,
};

export { ConnectedTile as ErrorsPerAppListTile };
