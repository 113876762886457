/**
 * @prettier
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { range, throttle } from 'lodash';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';
import { applyModifiers } from 'fela-rules/modifiers';
import { onGridResize, offGridResize, onGridDrag, offGridDrag } from './grid';
import { Selectors } from 'modules/dashboard';

type ContainerProps = {
  isEditing: boolean;
};
const StyledContainer = (props: ContainerProps) => {
  const styles: IStyle = {
    position: 'relative',
  };

  return applyModifiers([props.isEditing, { paddingBottom: '140px' }])(styles);
};

export const EditingGridContainer = createComponent(StyledContainer);

type StateProps = {
  isEditing: boolean;
  tileCount: number;
};
type DispatchProps = {};
type SuppliedProps = {};
type Props = StateProps & DispatchProps & SuppliedProps;
type State = {
  resizing: boolean;
  dragging: boolean;
};

class DashboardEditingGrid extends React.Component<Props, State> {
  canvas: HTMLCanvasElement;
  resizeStartCbId: number;
  resizeEndCbId: number;
  dragStartCbId: number;
  dragEndCbId: number;
  rafId: number;

  constructor(props: Props) {
    super(props);

    this.renderEditingGrid = this.renderEditingGrid.bind(this);
    this.clearEditingGrid = this.clearEditingGrid.bind(this);
    this.rafHandler = this.rafHandler.bind(this);
  }

  reRenderGrid() {
    this.rafId = window.requestAnimationFrame(this.rafHandler);
  }

  rafHandler() {
    if (this.props.isEditing) {
      this.renderEditingGrid();
      // Re-attach the RAF so that it will go through the rendering process again
      this.reRenderGrid();
    } else {
      this.clearEditingGrid();
    }
  }

  componentDidMount() {
    this.resizeStartCbId = onGridResize(() => this.setState({ resizing: true }), 'onBegin');
    this.resizeEndCbId = onGridResize(() => this.setState({ resizing: false }), 'onEnd');
    this.dragStartCbId = onGridDrag(() => this.setState({ dragging: true }), 'onBegin');
    this.dragEndCbId = onGridDrag(() => this.setState({ dragging: false }), 'onEnd');

    this.reRenderGrid();
  }

  componentWillUnmount() {
    offGridResize(this.resizeStartCbId, 'onBegin');
    offGridResize(this.resizeEndCbId, 'onEnd');
    offGridDrag(this.dragStartCbId, 'onBegin');
    offGridDrag(this.dragEndCbId, 'onEnd');

    window.cancelAnimationFrame(this.rafId);
  }

  componentWillUpdate(prevProps: Props) {
    if (this.props.isEditing != prevProps.isEditing) {
      this.reRenderGrid();
    }
  }

  clearEditingGrid() {
    if (!this.canvas) {
      return;
    }

    const ctx = this.canvas.getContext('2d');

    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  renderEditingGrid() {
    if (!this.canvas) {
      return;
    }

    const ctx = this.canvas.getContext('2d');
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;

    ctx.fillStyle = '#d8d8d8';

    const cols = 24;
    const margin = 10;
    const height = 40;
    const width = (this.canvas.width - (cols - 1) * margin) / cols;
    const requiredRows = Math.floor(this.canvas.height / (height + margin));

    range(0, requiredRows * cols).map(i => {
      const row = Math.floor(i / cols);
      const offset = i % cols;

      const horizontalOffset = Math.round(width * offset + margin * offset);
      const verticalOffset = Math.round(height * row + margin * row);

      // Make them a little smaller and offset them to the right and bottom
      // This means they'll always be inside of a tile if they're underneath it
      ctx.fillRect(horizontalOffset + 1, verticalOffset + 1, width - 2, height - 2);
    });
  }

  render() {
    return (
      <canvas
        ref={c => (this.canvas = c)}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      />
    );
  }
}

const ConnectedGrid = connect<StateProps, DispatchProps, SuppliedProps>((state: any) => ({
  isEditing: Selectors.getEditState(state),
  tileCount: Selectors.getCurrentLayout(state).length,
}))(DashboardEditingGrid);

export { ConnectedGrid as DashboardEditingGrid };
