/** @format **/

import createActionFactory from 'typescript-fsa';
import { Moment } from 'moment';

const createAction = createActionFactory('time');

export type UpdateTimeRangePayload = {
  from: Moment;
  to: Moment;
};
export const updateTimeRange = createAction<UpdateTimeRangePayload>('UPDATE_DATE_RANGE');
