/** @format **/
import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import { setAccountMenu } from 'app/chrome/revamped/actions';

import { Large } from 'components/breakpoints';
import { useOutsideClick } from 'components/ds/hooks/useOutsideClick';

import { getAccountMenuOpen } from '../../../selectors';
import { MenuPopout } from '../../accountMenu';

const StyledAccountWrapper = (): IStyle => ({
  position: 'fixed',
  bottom: '16px',
  left: '64px',
  zIndex: 101,
});

const AccountWrapper = createComponentWithProxy(StyledAccountWrapper);

type StateProps = {
  accountMenuOpen: boolean;
};

type DispatchProps = {
  resetAccountMenu: () => void;
};

type PassedProps = {};

type Props = StateProps & DispatchProps;

const UnconnectedAccountMenu: React.FunctionComponent<Props> = ({
  accountMenuOpen,
  resetAccountMenu,
}) => {
  const outsideProps = useOutsideClick({
    onOutsideClick: accountMenuOpen ? resetAccountMenu : null,
  });

  return accountMenuOpen ? (
    <Large andAbove>
      <AccountWrapper innerRef={outsideProps.ref}>
        <MenuPopout />
      </AccountWrapper>
    </Large>
  ) : null;
};

const ConnectedAccountMenu = connect<StateProps, DispatchProps, PassedProps, AppState>(
  state => ({
    accountMenuOpen: getAccountMenuOpen(state),
  }),
  dispatch => ({
    resetAccountMenu: () => dispatch(setAccountMenu(false)),
  }),
)(UnconnectedAccountMenu);

export { ConnectedAccountMenu as AccountMenu };
