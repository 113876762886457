/** @format */

import * as React from 'react';
import { SearchResult } from 'interfaces/search';

interface Props {
  isChecked: boolean;
  result: SearchResult;
  handleOptionKeyUp: (e: React.KeyboardEvent<HTMLElement>) => void;
  handleOptionKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  checkboxChangeCallback: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class MultiSelectCheckbox extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  static defaultProps: Partial<Props> = {
    isChecked: false,
  };

  render() {
    const result = this.props.result;

    return (
      <span
        className="dropdown2-section__option dropdown2-section__option--blue dropdown2-section__option--checkbox js-dropdown-option"
        onKeyUp={this.props.handleOptionKeyUp}
        onKeyDown={this.props.handleOptionKeyDown}
        tabIndex={1}
        title={result.displayName}
      >
        <label className="checkbox-input">
          <input
            type="checkbox"
            data-display-name={result.displayName}
            data-id={result.id}
            className="checkbox-input__ui"
            onChange={this.props.checkboxChangeCallback}
            checked={this.props.isChecked}
          />
          <span className="checkbox-input__icon" />
          <span className="checkbox-input__label nowrap">{result.displayName}</span>
        </label>
      </span>
    );
  }
}

export { MultiSelectCheckbox };
