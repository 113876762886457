/**
 * @prettier
 */

import React from 'react';

import { FormLayout, FormLayoutInput, FormLayoutLabel } from 'components/input';
import { Checkbox } from 'components/checkbox';
import { Flex } from 'components/flex';
import { Margin } from 'components/layout';

import * as Models from 'modules/dashboard/models';

type Props = {
  color: string;
  onColorChange: (color: string) => void;
};

const DEFAULT_COLOR = '#ffffff';

export const TileColor: React.FunctionComponent<Props> = ({ color, onColorChange }) => {
  const toggleTransparentColor = (checked: boolean) =>
    checked ? onColorChange(Models.TileTransparent) : onColorChange(DEFAULT_COLOR);

  const colorValue = color === Models.TileTransparent ? DEFAULT_COLOR : color;
  const isTransparentColor = color === Models.TileTransparent;

  return (
    <FormLayout marginBottom>
      <FormLayoutLabel>
        <label htmlFor="tile-color">Tile color</label>
      </FormLayoutLabel>
      <FormLayoutInput>
        <Flex direction="row" align="center">
          <Margin right="16">
            <input
              type="color"
              id="tile-color"
              className="form-color form-color--40"
              value={colorValue}
              onChange={e => onColorChange(e.target.value)}
            />
          </Margin>
          <Checkbox isChecked={isTransparentColor} onToggled={toggleTransparentColor}>
            No color
          </Checkbox>
        </Flex>
      </FormLayoutInput>
    </FormLayout>
  );
};
