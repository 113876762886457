/** @format **/

import * as React from 'react';
import * as _ from 'lodash';

import { Plan } from 'interfaces/plan';

import { size } from 'utils/gravatar';
import { Dropdown, DropdownSection } from 'components/dropdown';
import { Icon } from 'components/icon';

interface Props {
  plans: Plan[];
  activePlanId: number;
  onClick: (planId: number) => void;
}

export const PlanSwitcherMobile: React.FunctionComponent<Props> = props => {
  const activePlan: Plan = _.find(props.plans, (plan: Plan) => {
    return plan.Id === props.activePlanId;
  });

  const buttonElement = (
    <div className="plan-switcher-mobile-view plan-switcher-mobile-view--icon">
      <span className="plan-switcher-mobile-view__name">{activePlan.PlanName}</span>
      <span className="plan-switcher-mobile-view__icon">
        <Icon size={24} type="dropdown" />
      </span>
    </div>
  );

  const plans = props.plans.map(plan => {
    return (
      <div
        key={plan.Id}
        className="plan-switcher-mobile-view plan-switcher-mobile-view--option"
        onClick={() => props.onClick(plan.Id)}
      >
        <img
          src={size(plan.Avatar, 24)}
          alt={`Avatar for the ${plan.PlanName} plan`}
          className="plan-switcher-mobile-view__image"
        />
        <span className="plan-switcher-mobile-view__name">{plan.PlanName}</span>
      </div>
    );
  });

  return (
    <Dropdown button={buttonElement} block closeOnClick>
      <DropdownSection>{plans}</DropdownSection>
    </Dropdown>
  );
};
