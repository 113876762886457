/**
 * @prettier
 */
/* tslint:disable:max-file-line-count */

import * as Material from './material';
import * as MaterialDS from './materialDS';
import { isNil } from 'lodash';

export type ThemeRulesType = {
  ds: {
    colors: {
      blue: MaterialDS.BLUE_TYPES;
      blueGrey: MaterialDS.BLUE_GREY_TYPES;
      grey: MaterialDS.GREY_TYPES;
      cyan: MaterialDS.CYAN_TYPES;
      teal: MaterialDS.TEAL_TYPES;
      green: MaterialDS.GREEN_TYPES;
      greenL: MaterialDS.GREENL_TYPES;
      purple: MaterialDS.PURPLE_TYPES;
      red: MaterialDS.RED_TYPES;
      amber: MaterialDS.AMBER_TYPES;
      pink: MaterialDS.PINK_TYPES;
      indigo: MaterialDS.INDIGO_TYPES;
      lime: MaterialDS.LIME_TYPES;
      yellow: MaterialDS.YELLOW_TYPES;
      orange: MaterialDS.ORANGE_TYPES;
      semantic: MaterialDS.SEMANTIC_TYPES;
      special: MaterialDS.SPECIAL_TYPES;
      brand: {
        primary: MaterialDS.BLUE_TYPES['blue1000'];
        secondary: MaterialDS.BLUE_TYPES['blue1700'];
      };
      link: {
        inactive: MaterialDS.BLUE_TYPES['blueRG1050'];
        active: MaterialDS.BLUE_TYPES['blue1500'];
      };
    };
    getColorByName(color: MaterialDS.COLORS): string;
    fonts: {
      standard: '"Open Sans", arial, helvetica, serif';
    };
    standardBoxShadow: '0 2px 3px -1px rgba(38, 45, 58, 0.1)';
    dialogBoxShadow: '0 14px 28px 0 rgba(0,0,0,0.25), 0 10px 10px 0 rgba(0,0,0,0.25)';
  };
  fonts: {
    standard: '"Open Sans", arial, helvetica, serif';
  };
  shadows: {
    box: '0 2px 3px -1px rgba(38, 45, 58, 0.1)';
    boxHeavy: '0 7px 15px rgba(38, 45, 58, 0.1)';
    dropdown: string;
    modal: string;
  };
  colors: {
    border: {
      dark: '#BBB';
      light: '#E8E8E8';
      standard: '#D3D3D3';
      transparent: 'rgba(0,0,0,0.2)';
    };
    grey: {
      darkest: '#BBB';
      dark: '#CFCFCD';
      standard: '#D8D8D8';
      light: '#DDD';
      lighter: '#F1F1F1';
      lightest: '#FAFAFA';
    };
    ember: {
      dark: '#DC280A';
      standard: '#F73415';
      light: '#FA5028';
      highlight: '#FFE1DF';
    };
    stealth: {
      darkest: '#262d3a';
      dark: '#262d3a';
      standard: '#303847';
      light: '#5A606B';
    };
    text: {
      black: '#333';
      blackLight: '#666666';
      blue: '#4285F4';
      green: '#8BC34A';
      grey: '#808080';
      greyDark: '#4A4A4A';
      greyLight: '#BBB';
      purple: '#8A4DC7';
      red: '#DB4437';
      white: '#FFF';
      yellow: '#EFB11C';
      one: '#333';
      two: '#666';
      three: '#999';
    };
    standard: {
      white: '#FFF';
      azure: '#158FEF';
      azureHighlight: '#ECF4FF';
    };
    lightning: {
      standard: '#FFC539';
      light: '#F4E216';
      highlight: '#FFF5DB';
    };
    green: {
      standard: '#1CC526';
      light: '#46D04E';
      dark: '#00AD09';
      success: '#43A047';
    };
    blue: {
      standard: '#158FEF';
      light: '#5EB2F4';
      dark: '#0369D2';
    };
    byzantine: {
      standard: '#8a4dc7';
      light: '#8a4dc7';
      dark: '#511a8c';
    };
    flame: {
      standard: '#FB7A31';
      light: '#fb8c4c';
      dark: '#fa610b';
    };
    red: {
      standard: '#F73415';
      light: '#FA5028';
      dark: '#DC280A';
    };
    material: Material.BLUE_TYPES &
      Material.GREY_TYPES &
      Material.RED_TYPES &
      Material.GREEN_TYPES &
      Material.AMBER_TYPES &
      Material.YELLOW_TYPES;
    social: Material.SOCIAL_TYPES;
  };
};

export type ThemeType = {
  theme?: ThemeRulesType;
};

export const Theme: ThemeRulesType = {
  ds: {
    colors: {
      amber: MaterialDS.AMBER,
      blueGrey: MaterialDS.BLUE_GREY,
      brand: {
        primary: '#158FEF',
        secondary: '#062A47',
      },
      link: {
        inactive: MaterialDS.BLUE.blueRG1050,
        active: MaterialDS.BLUE.blue1500,
      },
      cyan: MaterialDS.CYAN,
      green: MaterialDS.GREEN,
      greenL: MaterialDS.GREENL,
      grey: MaterialDS.GREY,
      purple: MaterialDS.PURPLE,
      blue: MaterialDS.BLUE,
      red: MaterialDS.RED,
      semantic: MaterialDS.SEMANTIC,
      teal: MaterialDS.TEAL,
      pink: MaterialDS.PINK,
      indigo: MaterialDS.INDIGO,
      lime: MaterialDS.LIME,
      yellow: MaterialDS.YELLOW,
      orange: MaterialDS.ORANGE,
      special: MaterialDS.SPECIAL,
    },
    getColorByName(color: MaterialDS.COLORS) {
      // Catches when the color is initially undefined. Fela's just been catching this all the time instead of us
      if (isNil(color)) {
        return '';
      }

      if (color === 'transparent') {
        return MaterialDS.SPECIAL.transparent;
      }
      if (color === 'white') {
        return MaterialDS.SPECIAL.white;
      }
      if (color === 'black') {
        return MaterialDS.SPECIAL.black;
      }

      const colorGroup = color.replace(/[A-Z]*[0-9]+/, '');

      // tslint:disable-next-line:no-invalid-this
      return (this.colors as any)[colorGroup][color];
    },
    standardBoxShadow: '0 2px 3px -1px rgba(38, 45, 58, 0.1)',
    dialogBoxShadow: '0 14px 28px 0 rgba(0,0,0,0.25), 0 10px 10px 0 rgba(0,0,0,0.25)',
    fonts: {
      standard: '"Open Sans", arial, helvetica, serif',
    },
  },
  fonts: {
    standard: '"Open Sans", arial, helvetica, serif',
  },
  shadows: {
    box: '0 2px 3px -1px rgba(38, 45, 58, 0.1)',
    boxHeavy: '0 7px 15px rgba(38, 45, 58, 0.1)',
    dropdown: '0 2px 12px rgba(#262D3A, 0.2)',
    modal: '0 2px 12px rgba(38, 45, 58, 0.2)',
  },
  colors: {
    border: {
      dark: '#BBB',
      light: '#E8E8E8',
      standard: '#D3D3D3',
      transparent: 'rgba(0,0,0,0.2)',
    },
    grey: {
      darkest: '#BBB',
      dark: '#CFCFCD',
      standard: '#D8D8D8',
      light: '#DDD',
      lighter: '#F1F1F1',
      lightest: '#FAFAFA',
    },
    ember: {
      dark: '#DC280A',
      standard: '#F73415',
      light: '#FA5028',
      highlight: '#FFE1DF',
    },
    stealth: {
      darkest: '#262d3a',
      dark: '#262d3a',
      standard: '#303847',
      light: '#5A606B',
    },
    text: {
      black: '#333',
      blackLight: '#666666',
      blue: '#4285F4',
      green: '#8BC34A',
      grey: '#808080',
      greyDark: '#4A4A4A',
      greyLight: '#BBB',
      purple: '#8A4DC7',
      red: '#DB4437',
      white: '#FFF',
      yellow: '#EFB11C',
      one: '#333',
      two: '#666',
      three: '#999',
    },
    standard: {
      white: '#FFF',
      azure: '#158FEF',
      azureHighlight: '#ECF4FF',
    },
    red: {
      dark: '#DC280A',
      light: '#FA5028',
      standard: '#F73415',
    },
    blue: {
      dark: '#0369D2',
      light: '#5EB2F4',
      standard: '#158FEF',
    },
    byzantine: {
      standard: '#8a4dc7',
      light: '#8a4dc7',
      dark: '#511a8c',
    },
    flame: {
      standard: '#FB7A31',
      light: '#fb8c4c',
      dark: '#fa610b',
    },
    green: {
      dark: '#00AD09',
      light: '#46D04E',
      standard: '#1CC526',
      success: '#43A047',
    },
    lightning: {
      light: '#F4E216',
      standard: '#FFC539',
      highlight: '#FFF5DB',
    },
    material: {
      ...Material.BLUE,
      ...Material.RED,
      ...Material.GREY,
      ...Material.GREEN,
      ...Material.AMBER,
      ...Material.YELLOW,
      ...Material.GREENL,
    },
    social: Material.SOCIAL,
  },
};
