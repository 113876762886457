/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

export type BaseDefaultProps = {
  disabled: boolean;
  roundLeftCorners: boolean;
  roundRightCorners: boolean;
};

export const DefaultProps: BaseDefaultProps = {
  disabled: false,
  roundLeftCorners: false,
  roundRightCorners: false,
};

export { BaseWrapper, BaseWrapperProps } from './wrapper';
export { BaseText, BaseTextProps } from './text';
export { BaseIcon, BaseIconProps } from './icon';
