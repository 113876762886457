/**
 * @prettier
 */

import * as React from 'react';

import { MATH_COLORS } from 'constants/colors';

import { DurationChartTypes, DurationOverTime, DurationSeriesData } from 'components/charts';

import { RequestExecutionSpeedPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';
import { NoData } from '../noData';

const Chart: React.FunctionComponent<ProviderProps> = props => {
  const data = props.data as RequestExecutionSpeedPayload;

  if (data.Data.length === 0) {
    return <NoData />;
  }

  const times = data.Data.map(({ Period }) => Period);
  const periods = times.map(time => {
    const validUtcFormatted = time.indexOf('Z') > -1;
    const correctedTime = validUtcFormatted ? time : `${time}Z`;

    return correctedTime;
  });

  const averageData = data.Data.map(({ Average }) => Average / 1000);
  const medianData = data.Data.map(({ Median }) => Median / 1000);
  const p90Data = data.Data.map(({ P90 }) => P90 / 1000);
  const p99Data = data.Data.map(({ P99 }) => P99 / 1000);

  const seriesData: DurationSeriesData[] = [
    {
      time: averageData,
      color: MATH_COLORS.AVG,
      name: 'Average',
    },
    {
      time: medianData,
      color: MATH_COLORS.MEDIAN,
      name: 'Median',
    },
    {
      time: p90Data,
      color: MATH_COLORS.P90,
      name: 'P90',
    },
    {
      time: p99Data,
      color: MATH_COLORS.P99,
      name: 'P99',
    },
  ];

  return (
    <DurationOverTime
      periods={periods}
      data={seriesData}
      height={'100%'}
      chartType={DurationChartTypes.LINE}
      interval={data.Interval}
      groupChart={props.groupCharts}
      allowDateRangeSelect={props.dragToZoom}
      onDashboard
    />
  );
};

const ConnectedTile = TileDataProvider(Chart, ChartTileLoading);

export { ConnectedTile as RequestExecutionSpeedChart };
