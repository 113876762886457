/** @format **/

import * as React from 'react';
import classnames from 'classnames';
import { isString, isNil } from 'lodash';
import { To } from 'redux-first-router-link';
import { Link } from 'components/link';
import { trackLinkClick } from 'components/snowplow';

export const Navigation: React.FunctionComponent<{}> = ({ children }) => {
  return <ul className="header-navigation clearfix">{children}</ul>;
};

export const SecondaryNavigation: React.FunctionComponent<{}> = ({ children }) => {
  return <ul className="header-navigation header-navigation--secondary clearfix">{children}</ul>;
};

interface NavigationItemProps {
  to?: To;
  callback?: (event: React.MouseEvent<HTMLElement>) => void;
  color?: 'blue' | 'dark' | 'purple' | 'green';
  icon?: React.ReactNode | null;
  isActive?: boolean;
  text: string;
  accessKey?: string;
  snowplowId?: string;
}

export const NavigationItem: React.FunctionComponent<NavigationItemProps> = props => {
  const itemClassName = 'header-navigation__item';

  const buttonClassName = classnames('header-navigation-button', {
    'header-navigation-button--blue': props.color === 'blue',
    'header-navigation-button--dark': props.color === 'dark',
    'header-navigation-button--purple': props.color === 'purple',
    'header-navigation-button--green': props.color === 'green',
    'header-navigation-button--noicon': props.icon === null,
    'header-navigation-button--active': props.isActive,
  });

  const onClickCallback = (event: React.MouseEvent<HTMLElement>) => {
    const _ignore = !isNil(props.callback) && props.callback(event);
    if (props.snowplowId) {
      trackLinkClick(props.snowplowId);
    }
  };

  const icon =
    props.icon !== null ? (
      <span className="header-navigation-button__icon">{props.icon}</span>
    ) : null;

  if (isString(props.to) || isNil(props.to)) {
    return (
      <li className={itemClassName}>
        <a href={props.to} className={buttonClassName} onClick={onClickCallback}>
          {icon}
          <span className="header-navigation-button__text">{props.text}</span>
        </a>
      </li>
    );
  } else {
    return (
      <li className={itemClassName} onClick={onClickCallback}>
        <Link to={props.to} className={buttonClassName} accessKey={props.accessKey}>
          {icon}
          <span className="header-navigation-button__text">{props.text}</span>
        </Link>
      </li>
    );
  }
};

NavigationItem.defaultProps = {
  color: 'dark',
  icon: null,
};
