/**
 * @prettier
 */

import * as React from 'react';

import { CALL_TYPES, getFillStyleForType } from 'apm/constants';
import { AverageRequestBreakdownChartPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';
import { NoData } from '../noData';
import { DurationChartTypes, DurationOverTime, DurationSeriesData } from 'components/charts';

const Chart: React.FunctionComponent<ProviderProps> = props => {
  const data = props.data as AverageRequestBreakdownChartPayload;

  if (data.Data.length === 0) {
    return <NoData />;
  }

  const times = data.Data.map(({ Period }) => Period);
  const periods = times.map(time => {
    const validUtcFormatted = time.indexOf('Z') > -1;
    const correctedTime = validUtcFormatted ? time : `${time}Z`;

    return correctedTime;
  });

  const apiCallData = data.Data.map(({ AverageTimeSpentInApiCalls }) =>
    Math.max(0, AverageTimeSpentInApiCalls / 1000),
  );
  const methodCallData = data.Data.map(({ AverageTimeSpentInMethods }) =>
    Math.max(0, AverageTimeSpentInMethods / 1000),
  );
  const queryCallData = data.Data.map(({ AverageTimeSpentInQueries }) =>
    Math.max(0, AverageTimeSpentInQueries / 1000),
  );
  const seriesData: DurationSeriesData[] = [
    {
      name: 'Methods',
      time: methodCallData,
      color: getFillStyleForType(CALL_TYPES.METHOD),
    },
    {
      name: 'API Calls',
      time: apiCallData,
      color: getFillStyleForType(CALL_TYPES.EXTERNAL_API),
    },
    {
      name: 'Queries',
      time: queryCallData,
      color: getFillStyleForType(CALL_TYPES.QUERY),
    },
  ];

  return (
    <DurationOverTime
      periods={periods}
      data={seriesData}
      interval={data.Interval}
      height={'100%'}
      chartType={DurationChartTypes.STACK}
      groupChart={props.groupCharts}
      allowDateRangeSelect={props.dragToZoom}
      onDashboard
    />
  );
};

const ConnectedTile = TileDataProvider(Chart, ChartTileLoading);

export { ConnectedTile as AveragePageBreakdownChart };
