/** @format */

import createActionFactory from 'typescript-fsa';
import { reducerUpdate } from 'utils/reducerUpdate';
import { ScreenState } from 'app/reducers';
import { ActionCreator } from 'typescript-fsa';
import { ReducerBuilder } from 'typescript-fsa-reducers';
import { ReactComponent } from 'utils/types';
import { assign, mapValues } from 'lodash';
import { NOT_FOUND, redirect } from 'redux-first-router';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';

export const notFound = createActionFactory('')(NOT_FOUND);
export type RoutePreEntryCallback<P = any, S = any> = (
  dispatch: Dispatch,
  getState: () => S,
  action: Action<P>,
) => Action<any>;

export interface RoutePreEntryCallbackChain<P = any, S = any> {
  (dispatch: Dispatch, getState: () => S, action: Action<P>): Action<any>;
  chain: (callback: RoutePreEntryCallback<P, S>) => RoutePreEntryCallbackChain<P, S>;
  build: () => { path: string; beforeEntryCallback: RoutePreEntryCallback<P, S> };
}

function getChainFunction<P, S>(callbacks: RoutePreEntryCallback<P, S>[]) {
  return (dispatch: Dispatch, getState: () => S, action: Action<P>) => {
    for (const callback of callbacks) {
      const actionResult = callback(dispatch, getState, action);

      if (actionResult !== action) {
        // redux-first-router Action is a different type, but these will be RFR actions
        // So short circut the type system
        dispatch(redirect(actionResult as any));
      }
    }
  };
}

export function createRoutePreEntryChainWithUrl<P, S>(
  path: string,
): RoutePreEntryCallbackChain<P, S> {
  const callbacks: RoutePreEntryCallback<P, S>[] = [];
  const callbackChain = getChainFunction(callbacks) as RoutePreEntryCallbackChain<P, S>;
  callbackChain.chain = callback => {
    callbacks.push(callback);

    return callbackChain;
  };
  callbackChain.build = () => ({
    path,
    beforeEntryCallback: callbackChain,
  });

  return callbackChain;
}

export function validatePathParam(
  name: string,
  validator: string[] | ((param: string) => boolean),
  failureAction = notFound(),
): RoutePreEntryCallback<any, any> {
  return (_dispatch: Dispatch, _getState: () => any, action: Action<any>) => {
    const paramValue = action.payload[name];

    if (typeof validator === 'function') {
      if (!validator(paramValue)) {
        return failureAction;
      }
    } else {
      if (validator.indexOf(paramValue) == -1) {
        return failureAction;
      }
    }

    return action;
  };
}
