/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { applyModifiers } from 'fela-rules/modifiers';
import { screenLargeAndAbove } from 'fela-rules/breakpoints';
import { Flex } from 'components/flex';
import { Margin } from 'components/layout';
import { Theme } from 'app/theme';

type IconWrapperProps = {
  active: boolean;
  big: boolean;
};

const StyledIconWrapper = (props: IconWrapperProps) =>
  applyModifiers(
    [
      !props.big,
      {
        width: '16px',
        height: '16px',
      },
    ],
    [
      props.big,
      {
        width: '24px',
        height: '24px',
      },
    ],
    [
      props.active,
      {
        fill: Theme.ds.getColorByName('blue900'),
        ...screenLargeAndAbove({
          marginLeft: '4px',
        }),
      },
    ],
  )({
    fill: Theme.ds.getColorByName('blueGrey600'),
    ...screenLargeAndAbove({
      fill: Theme.ds.getColorByName('blueGrey300'),
    }),
  });

export const IconWrapper = createComponent(StyledIconWrapper, 'svg');

type IconProps = {
  iconName: string;
} & IconWrapperProps;

export const Icon: React.FunctionComponent<IconProps> = ({ iconName, ...props }) => (
  <Flex justify="center" align="center" minHeight="100%" minWidth="100%">
    <IconWrapper {...props}>
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={`/Content/Images/sidebar/sidebar-icons-revamped.svg#sidebar-icon--${iconName}-icon`}
      />
    </IconWrapper>
  </Flex>
);
