/** @format */

import { ThunkAction } from 'redux-thunk';
import createActionFactory from 'typescript-fsa';

import * as AppSelectors from 'app/selectors/application';
import { AppState } from 'interfaces/appState';

import { postToApp, deleteOnApp } from 'utils/fetching';

const createAction = createActionFactory('app/pageData');

export type ApplicationFavourite = {
  id: number;
  favourite: boolean;
};

export const favouriteAppSuccess = createAction<ApplicationFavourite>('APP_FAVOURITE_SUCCESS');

export const favouriteApp = (data: ApplicationFavourite): ThunkAction<any, AppState, null, any> => (
  dispatch,
  getState,
) => {
  const state = getState();
  const method = data.favourite ? postToApp : deleteOnApp;

  method(`settings/appswitcher/favorites/application/${data.id}`, AppSelectors.apiKey(state)).then(
    () => {
      dispatch(favouriteAppSuccess(data));
    },
  );
};

export type DashboardFavourite = {
  dashboardId: number;
  planId: number;
  favourite: boolean;
};

export const favouriteDashboardSuccess = createAction<DashboardFavourite>(
  'DASHBOARD_FAVOURITE_SUCCESS',
);

export const favouriteDashboard = (
  dash: DashboardFavourite,
): ThunkAction<any, AppState, null, any> => (dispatch, getState) => {
  const state = getState();
  const method = dash.favourite ? postToApp : deleteOnApp;

  method(
    `settings/appswitcher/${dash.planId}/favorites/dashboard/${dash.dashboardId}`,
    AppSelectors.apiKey(state),
  ).then(() => {
    dispatch(favouriteDashboardSuccess(dash));
  });
};

export const dismissApmWelcomeModal = createAction<{ result: boolean }>(
  'DISMISS_APM_WELCOME_MODAL',
);

type UpdateDashboardNamePayload = {
  dashboardId: number;
  title: string;
};

export const updateDashboardName = createAction<UpdateDashboardNamePayload>(
  'UPDATE_DASHBOARD_NAME',
);
