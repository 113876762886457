import * as React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy, createComponent } from 'react-fela';

import { ThemeType } from 'app/theme';

import { applyModifiers } from 'fela-rules/modifiers';

const StyledMenuItemDivider = (props: ThemeType): IStyle => ({
  display: 'block',
  width: '100%',
  height: '1px',
  backgroundColor: props.theme.ds.getColorByName('blueGrey100'),
  marginTop: '8px',
  marginBottom: '7px'
});

export const MenuItemDivider = createComponentWithProxy(StyledMenuItemDivider);

const StyledMenuItem = (props: ThemeType): IStyle => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '32px',
  fontSize: '1.4rem',
  color: props.theme.ds.getColorByName('grey800'),
  paddingLeft: '16px',
  paddingRight: '16px',
  ':hover': {
    color: props.theme.ds.getColorByName('blue1000')
  },
  ':active': {
    color: props.theme.ds.getColorByName('blue1000')
  }
});

export const MenuItem = createComponentWithProxy(StyledMenuItem, 'a');

type MenuTitleProps = {
  forPlan?: boolean;
  asMenuItem?: boolean;
};

const StyledMenuTitle = (props: ThemeType & MenuTitleProps): IStyle =>
applyModifiers(
  [!props.forPlan, {
    textTransform: 'uppercase',
    color: props.theme.ds.getColorByName('blueGrey400'),
  }],
  [props.forPlan, {
    fontSize: '1.4rem',
    fontWeight: 400,
  }],
  [props.asMenuItem, {
    paddingLeft: '16px',
    paddingRight: '16px'
  }]
)({
  fontSize: '1.3rem',
  lineHeight: '40px',
  paddingLeft: '8px',
  fontWeight: 600,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
});

export const MenuTitle = createComponentWithProxy(StyledMenuTitle, 'h4');

type AvatarContainerProps = {
  active?: boolean;
};

const StyledAvatarContainer = (props: ThemeType & AvatarContainerProps & { as: string }): IStyle => applyModifiers(
  [props.active, {
    borderLeft: `4px solid ${props.theme.ds.getColorByName('blue900')}`,
    paddingLeft: '12px'
  }],
  [props.as === "a", {
    ':hover': {
      color: props.theme.ds.getColorByName('blue1000')
    },
    ':active': {
      color: props.theme.ds.getColorByName('blue1000')
    }
  }]
)({
  paddingRight: '16px',
  paddingLeft: '16px',
  paddingTop: '4px',
  paddingBottom: '4px',
  display: 'flex',
  alignItems: 'center',
  color: props.theme.ds.getColorByName('grey800'),
});

export const MenuAvatarContainer = createComponentWithProxy<AvatarContainerProps>(StyledAvatarContainer);
