/** @format */

import * as React from 'react';
import { find, get } from 'lodash';

import { CustomChart } from 'components/ds/charts';
import * as charting from 'utils/echarts';

import { ErrorCountChartPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';
import { NoData } from '../noData';

const Chart: React.FunctionComponent<ProviderProps> = props => {
  const { applications, settings } = props;
  const data = props.data as ErrorCountChartPayload;

  if (get(data, 'data.length', 0) === 0) {
    return <NoData />;
  }

  let interval = settings.Granularity;

  // if no granularity due to older fixed durations then use default values
  if (!interval) {
    switch (settings.DateRange) {
      case '1h':
        interval = '1m';
        break;
      case '1d':
        interval = '10m';
        break;
      case '2d':
      case '7d':
        interval = '1h';
        break;
      case '14d':
        interval = '1d';
        break;
    }
  }

  const periods = data.times.map(time => {
    const validUtcFormatted = time.indexOf('Z') > -1;
    const correctedTime = validUtcFormatted ? time : `${time}Z`;

    return correctedTime;
  });

  const allAppIds = settings.Applications.split(',') as string[];

  const series = allAppIds.map(appIdString => {
    const appId = parseInt(appIdString, 10);
    const seriesData = find(data.data, x => x.name === appIdString);
    const color = props.colors[appId];

    return {
      name: find(applications, app => app.id === appId).name,
      id: appId,
      symbol: 'none',
      type: 'line',
      stack: 'stack',
      data: seriesData != null ? seriesData.collection : [],
      areaStyle: {
        normal: {
          opacity: 0.5,
          color,
        },
        emphasis: {
          color,
        },
      },
      lineStyle: {
        normal: {
          color,
        },
        emphasis: {
          color,
        },
      },
    };
  });

  const options = (chart: any) => {
    return charting.extendOptions({
      tooltip: {
        show: true,
        confine: true,
        trigger: 'axis',
        formatter: (params: any[]) => {
          return charting.renderSeriesTooltip({
            date: charting.formatUtcTooltipDate(params[0].name, interval as any),
            series: params.map((s: any) => {
              return {
                name: s.seriesName,
                value: s.value.toLocaleString(),
                colorcode: props.colors[s.seriesId],
              };
            }),
          });
        },
      },
      xAxis: [
        {
          type: 'category',
          data: periods,
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: charting.getDateTimeLabelFormatter(
              periods,
              interval as any,
              chart ? chart.getEchartsInstance() : undefined,
            ),
          },
          splitNumber: 8,
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: charting.getIntegerLabelFormatter(
              chart ? chart.getEchartsInstance() : undefined,
            ),
          },
        },
      ],
      series,
    });
  };

  return <CustomChart height={'100%'} options={options} />;
};

const ConnectedTile = TileDataProvider(Chart, ChartTileLoading);

export { ConnectedTile as ErrorCountChart };
