export type PermissionsState = {
  ApplicationPermissions: {
    [key: number]: ApplicationPermissions;
  },
  PlanPermissions: {
    [key: number]: PlanPermissions;
  }
};

export enum PermissionCategory {
  ALL, PLAN, APPLICATION
}

/**
 * These enums are a mirror of the permissions enums stored on the backend.
 * When adding/removing permissions from this list, you must update the corresponding
 * enum on the backend.
 */

export enum PlanPermissions {
  None = "None",
  CancelSubscription = "CancelSubscription",
  ChangeSubscription = "ChangeSubscription",
  ChangeBillingDetails = "ChangeBillingDetails",
  UpdateOrganisationDetails = "UpdateOrganisationDetails",
  ViewUsageDetails = "ViewUsageDetails",
  UpdatePrivacyAndCompliance = "UpdatePrivacyAndCompliance",
  ViewSubscriptionDetails = "ViewSubscriptionDetails",

  ManageTeams = "ManageTeams",
  ManageApplicationsOnTeams = "ManageApplicationsOnTeams",
  ManageMembersOnTeams = "ManageMembersOnTeams",
  CreateNewApplications = "CreateNewApplications",
  DeleteApplications = "DeleteApplications",
}

export enum ApplicationPermissions {
  None = "None",
  UpdateInboundFilters = "UpdateInboundFilters",
  UpdateReport = "UpdateReport",
  MergeErrors = "MergeErrors",
  PermanentlyIgnoreError = "PermanentlyIgnoreErrorGroups",
  DeleteErrorGroups = "DeleteErrorGroups",
  ChangeGroupingStrategy = "ChangeGroupingStrategy",
  UpdateSamplingRate = "UpdateSamplingRate",
}

export type PermissionTypes = PlanPermissions | ApplicationPermissions;

export function GetDisplayPermissionName(permission: PermissionTypes): string {
  switch (permission) {
    case PlanPermissions.ViewSubscriptionDetails:
      return "View subscription details";
    case PlanPermissions.CancelSubscription:
      return "Cancel subscription";
    case PlanPermissions.ChangeSubscription:
      return "Change subscription";
    case PlanPermissions.ChangeBillingDetails:
      return "Change billing details";
    case PlanPermissions.UpdateOrganisationDetails:
      return "Update organisation details";
    case PlanPermissions.ViewUsageDetails:
      return "View usage details";
    case PlanPermissions.UpdatePrivacyAndCompliance:
      return "Update privacy and compliance";

    case PlanPermissions.ManageTeams:
      return "Manage teams";
    case PlanPermissions.ManageApplicationsOnTeams:
      return "Manage applications on teams";
    case PlanPermissions.ManageMembersOnTeams:
      return "Manage members on teams";

    case PlanPermissions.CreateNewApplications:
      return "Create new applications";
    case PlanPermissions.DeleteApplications:
      return "Delete applications";
    case ApplicationPermissions.UpdateInboundFilters:
      return "Update inbound filters";
    case ApplicationPermissions.UpdateReport:
      return "Update reports";
    case ApplicationPermissions.MergeErrors:
      return "Merge errors";
    case ApplicationPermissions.PermanentlyIgnoreError:
      return "Change error status to permanently ignored";
    case ApplicationPermissions.DeleteErrorGroups:
      return "Delete error groups";
    case ApplicationPermissions.ChangeGroupingStrategy:
      return "Change error grouping strategy";
    case ApplicationPermissions.UpdateSamplingRate:
      return "Update sampling rate";
    default:
      throw Error(`Unknown plan permission: ${permission}`);
  }
}