/**
 * @prettier
 */

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import moment from 'moment';

import { nestedReducerUpdate, reducerUpdate } from 'utils/reducerUpdate';

import { WhatsNewState } from './state';
import * as Actions from './actions';
import { NetworkWhatsNewItem, parseWhatsNewCategory, WhatsNewCategory } from './models';

const initialState: WhatsNewState = {
  lastViewedItemDate: new Date(),
  whatsNewItems: [],
  fetching: {
    error: null,
    hasErrored: false,
    isFetching: false,
  },
  filters: {
    categories: {
      [WhatsNewCategory.Platform]: true,
      [WhatsNewCategory.Providers]: true,
      [WhatsNewCategory.RealUserMonitoring]: true,
      [WhatsNewCategory.CrashReporting]: true,
      [WhatsNewCategory.APM]: true,
      [WhatsNewCategory.Integrations]: true,
    },
    tags: [],
  },
  banner: {
    bannerImgSrc: '',
    bannerImgAlt: '',
    bannerLink: '',
    bannerEnabled: false,
  },
};

export const WhatsNewReducer = reducerWithInitialState(initialState)
  .case(Actions.whatsNewItemFetchingStarted, state =>
    nestedReducerUpdate(state, 'fetching', { isFetching: true, hasErrored: false, error: null }),
  )
  .case(Actions.updateWhatsNewItems, (state, whatsNewItems) => {
    const parsedItems =
      typeof whatsNewItems === 'string' ? JSON.parse(whatsNewItems) : whatsNewItems;

    return reducerUpdate(state, {
      whatsNewItems: parsedItems.map((i: NetworkWhatsNewItem) => ({
        ...i,
        relativePermalink: i.relativePermalink.slice(1),
        date: moment(i.date).toDate(),
        type: parseWhatsNewCategory(i.type),
      })),
    });
  })
  .case(Actions.whatsNewItemFetchingSucceeded, state =>
    nestedReducerUpdate(state, 'fetching', {
      error: null,
      hasErrored: false,
      isFetching: false,
    }),
  )
  .case(Actions.whatsNewItemFetchingStarted, (state, error) =>
    nestedReducerUpdate(state, 'fetching', { isFetching: false, hasErrored: true, error }),
  )
  .case(Actions.updateLastViewedDate, (state, date) =>
    reducerUpdate(state, { lastViewedItemDate: date }),
  )
  .case(Actions.toggleCategoryFilter, (state, { category, toggled }) =>
    nestedReducerUpdate(state, 'filters', {
      categories: {
        ...state.filters.categories,
        [category]: toggled,
      },
    }),
  )
  .case(Actions.addTagToFilters, (state, tag) =>
    nestedReducerUpdate(state, 'filters', {
      tags: [...state.filters.tags, tag],
    }),
  )
  .case(Actions.removeTagFromFilters, (state, tagToRemove) =>
    nestedReducerUpdate(state, 'filters', {
      tags: state.filters.tags.filter(tag => tag !== tagToRemove),
    }),
  )
  .case(Actions.whatsNewBannerFetchingStarted, state =>
    nestedReducerUpdate(state, 'fetching', {
      isFetching: true,
      hasErrored: false,
      error: null,
    }),
  )
  .case(Actions.updateWhatsNewBanner, (state, whatsNewBanner) => {
    const parsedBanner =
      typeof whatsNewBanner === 'string' ? JSON.parse(whatsNewBanner) : whatsNewBanner;

    return reducerUpdate(state, {
      banner: parsedBanner,
    });
  })
  .case(Actions.whatsNewBannerFetchingSucceeded, state =>
    nestedReducerUpdate(state, 'fetching', {
      error: null,
      hasErrored: false,
      isFetching: false,
    }),
  );
