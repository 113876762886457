/** @format **/
import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { slice } from 'lodash';

import { applyModifiers } from 'fela-rules/modifiers';
import { ThemeType } from 'app/theme';
import { Flex } from 'components/flex';
import { Width } from 'components/width';
import { Padding } from 'components/layout';

type RowImageProps = {
  indented?: boolean;
  noMargin?: boolean;
  shadow?: boolean;
};

const StyledRowImage = (props: RowImageProps & ThemeType): IStyle =>
  applyModifiers(
    [
      props.noMargin,
      {
        marginRight: 0,
        marginLeft: 0,
      },
    ],
    [
      props.indented,
      {
        width: '16px',
      },
    ],
    [
      props.shadow,
      {
        boxShadow: `-2px 0 0 ${props.theme.ds.getColorByName('white')}`,
      },
    ],
  )({
    width: '24px',
    height: '24px',
    margin: '8px',
    borderRadius: '2px',
    overflow: 'hidden',
  });

export const RowImage = createComponentWithProxy<RowImageProps>(StyledRowImage, 'img');

const StyledRowTextImage = (props: ThemeType): IStyle => ({
  display: 'block',
  borderRadius: '2px',
  width: '24px',
  height: '24px',
  overflow: 'hidden',
  boxShadow: `-2px 0 0 ${props.theme.ds.getColorByName('white')}`,
  color: props.theme.ds.getColorByName('blueGrey400'),
  backgroundColor: props.theme.ds.getColorByName('blueGrey50'),
  fontSize: '1.2rem',
  textAlign: 'center',
  fontWeight: 600,
  lineHeight: '2.1',
});

const RowTextImage = createComponentWithProxy(StyledRowTextImage);

export type ImageProps = {
  src: string;
  alt: string;
};

type RowImagesProps = {
  images: ImageProps[];
};

export const RowImages: React.FunctionComponent<RowImagesProps> = ({ images }) => {
  const firstThreeImages = slice(images, 0, 3);
  const remainingImages = Math.min(images.length - firstThreeImages.length, 99);

  return (
    <Width width={88}>
      <Padding right="8">
        <Flex align="center">
          {firstThreeImages.map((img, i) => (
            <RowImage
              shadow
              noMargin
              indented={i != firstThreeImages.length - 1 || remainingImages > 0}
              key={i}
              as="div"
            >
              <img src={img.src} alt={img.alt} width="24" height="24" />
            </RowImage>
          ))}
          {remainingImages > 0 && <RowTextImage>{`+${remainingImages}`}</RowTextImage>}
        </Flex>
      </Padding>
    </Width>
  );
};
