/**
 * @prettier
 */

import React from 'react';
import { IStyle } from 'fela';
import { isNil } from 'lodash';
import { createComponent } from 'react-fela';

import { Theme, ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { COLORS } from 'app/theme/materialDS';

type Props = {
  marginTop?: number;
  marginBottom?: number;
  backgroundColor?: COLORS;
  textBackgroundColor?: string;
  text?: string;
};

const StyledHorizontalRule = (props: Props & ThemeType): IStyle => {
  const styles: IStyle = {
    height: '1px',
    width: '100%',
    backgroundColor: !isNil(props.backgroundColor)
      ? Theme.ds.getColorByName(props.backgroundColor)
      : `${props.theme.colors.border.standard}`,
  };

  return applyModifiers(
    [
      !!props.marginTop,
      {
        marginTop: `${props.marginTop}px`,
      },
    ],
    [
      !!props.marginBottom,
      {
        marginBottom: `${props.marginBottom}px`,
      },
    ],
    [
      !!props.text,
      {
        position: 'relative',
        textAlign: 'center',
        ':before': {
          content: `"${props.text}"`,
          fontSize: '14px',
          fontStyle: 'italic',
          position: 'absolute',
          backgroundColor: !isNil(props.textBackgroundColor) ? props.textBackgroundColor : '#fff',
          padding: '0 10px 0 8px', // Offset italic text width
          color: '#444',
          transform: 'translate(-50%, -50%)',
        },
      },
    ],
  )(styles);
};

const HorizontalRuleComponent = createComponent<Props>(StyledHorizontalRule, 'div', [
  'aria-label',
  'aria-hidden',
]);

export const HorizontalRule: React.FunctionComponent<Props> = (props: Props) => (
  <HorizontalRuleComponent {...props} aria-label={props.text} aria-hidden={!props.text} />
);

export default HorizontalRule;
