/** @format **/
import { decodeActiveArea } from 'utils/links';

export function getFeatureRequestUrl(): string {
  const baseUrl = 'https://raygun.com';
  const location = window.location.pathname;

  const activeArea = decodeActiveArea(location);

  switch (activeArea) {
    case 'crash':
      return `${baseUrl}/thinktank/forum/2`;
    case 'rum':
      return `${baseUrl}/thinktank/forum/4`;
    case 'apm':
      return `${baseUrl}/thinktank/forum/5`;
    default:
      return `${baseUrl}/forums/forum/2`;
  }
}
