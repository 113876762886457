/** @format **/

export enum SORTING_DIRECTION {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export function getOppositeSortDirection(direction: SORTING_DIRECTION): SORTING_DIRECTION {
  switch (direction) {
    case SORTING_DIRECTION.Ascending:
      return SORTING_DIRECTION.Descending;

    case SORTING_DIRECTION.Descending:
      return SORTING_DIRECTION.Ascending;

    default:
      const _never: never = direction;
  }
}

export function getDirectionShorthand(direction: SORTING_DIRECTION) {
  switch (direction) {
    case SORTING_DIRECTION.Descending:
      return 'desc';
    case SORTING_DIRECTION.Ascending:
    default:
      return 'asc';
  }
}
