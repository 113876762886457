/** @format */

import { uppercaseFirstCharacter } from 'utils/string';

// Call types
export enum CALL_TYPES {
  TRACE = 'trace',
  METHOD = 'method',
  QUERY = 'query',
  SYSTEM = 'system',
  KNOWNLIBRARY = 'library',
  EXCEPTION = 'exception',
  EXTERNAL_API = 'externalapi',
  WAITING = 'waiting',
  SLEEPING = 'sleeping',
  THREAD_SPAWN = 'threadspawn',
  GARBAGE_COLLECT = 'gc',
  DEFAULT = 'default',
  SELF = 'self',
  // Rule specific
  NPLUSONE = 'nplusonequery',
  APDEX = 'apdex',
  OVERUSED_API = 'overusedapi',
  CHATTY_API = 'chattyapi',
  ADHOC = 'adhoc',
  ADHOC_QUERY = 'adhocquery',
  ADHOC_METHOD = 'adhocmethod',
  ADHOC_TRACE = 'adhoctrace',
  ADHOC_EXTERNAL_API = 'adhocexternalapi',
  MANUAL = 'manual',
  CUSTOM = 'custom',
}

export function isAdhocIssue(type: CALL_TYPES): boolean {
  return type.indexOf('adhoc') !== -1;
}

export type TRACE_CALL_TYPES =
  | CALL_TYPES.TRACE
  | CALL_TYPES.METHOD
  | CALL_TYPES.QUERY
  | CALL_TYPES.EXTERNAL_API
  | CALL_TYPES.SYSTEM
  | CALL_TYPES.KNOWNLIBRARY
  | CALL_TYPES.WAITING
  | CALL_TYPES.SLEEPING
  | CALL_TYPES.EXCEPTION
  | CALL_TYPES.THREAD_SPAWN
  | CALL_TYPES.GARBAGE_COLLECT;

export const defaultColor = '#BDBDBD';

export const getFillStyleForType = (methodType: string): string => {
  // Gets the correct fill based on member type
  switch (methodType as CALL_TYPES) {
    case CALL_TYPES.ADHOC_EXTERNAL_API:
    case CALL_TYPES.ADHOC_METHOD:
    case CALL_TYPES.ADHOC_TRACE:
    case CALL_TYPES.ADHOC_QUERY:
    case CALL_TYPES.ADHOC:
    case CALL_TYPES.EXCEPTION:
      return '#D32F2F';
    case CALL_TYPES.SELF:
    case CALL_TYPES.TRACE:
    case CALL_TYPES.THREAD_SPAWN:
      return '#1565C0';
    case CALL_TYPES.QUERY:
    case CALL_TYPES.NPLUSONE:
      return '#FBC02D';
    case CALL_TYPES.METHOD:
      return '#583EB0';
    case CALL_TYPES.EXTERNAL_API:
    case CALL_TYPES.OVERUSED_API:
    case CALL_TYPES.CHATTY_API:
      return '#43A047';
    case CALL_TYPES.KNOWNLIBRARY:
      return '#00D2D2';
    case CALL_TYPES.WAITING:
      return '#FF8C00';
    case CALL_TYPES.GARBAGE_COLLECT:
      return '#555555';
    case CALL_TYPES.SLEEPING:
    case CALL_TYPES.SYSTEM:
      return '#81A1B1';
    case CALL_TYPES.MANUAL:
      return '#E8E8E8';
    case CALL_TYPES.CUSTOM:
      return '#3C4556';
    case CALL_TYPES.DEFAULT:
    default:
      return defaultColor;
  }
};

export function getGreyscaleFillStyleForType(type: string): string {
  switch (type) {
    case CALL_TYPES.SYSTEM:
      return '#C3C3C3';
    case CALL_TYPES.TRACE:
      return '#B9B9B9';
    case CALL_TYPES.METHOD:
      return '#B7B7B7';
    case CALL_TYPES.KNOWNLIBRARY:
      return '#C9C9C9';
    case CALL_TYPES.EXTERNAL_API:
      return '#B4B4B4';
    case CALL_TYPES.QUERY:
      return '#E1E1E1';
    case CALL_TYPES.EXCEPTION:
      return '#B0B0B0';
    case CALL_TYPES.WAITING:
    case CALL_TYPES.GARBAGE_COLLECT:
      return '#CFCFCF';
    case CALL_TYPES.DEFAULT:
    default:
      return '#DEDEDE';
  }
}

export function getTypeDisplayNameForPill(type: string): string {
  switch (type) {
    case CALL_TYPES.ADHOC_EXTERNAL_API:
    case CALL_TYPES.ADHOC_METHOD:
    case CALL_TYPES.ADHOC_TRACE:
    case CALL_TYPES.ADHOC_QUERY:
    case CALL_TYPES.ADHOC:
      return 'AD HOC';
    case CALL_TYPES.EXTERNAL_API:
      return 'API';
    case CALL_TYPES.OVERUSED_API:
    case CALL_TYPES.CHATTY_API:
      return 'API+1';
    case CALL_TYPES.NPLUSONE:
      return 'N+1';
    case CALL_TYPES.KNOWNLIBRARY:
      return 'LIB';
    case CALL_TYPES.SYSTEM:
      return 'SYSTEM';
    case CALL_TYPES.MANUAL:
      return 'Manual';
    default:
      return type.toUpperCase();
  }
}

export const getTypeDisplayNameLong = (type: string, uppercase: boolean = true): string => {
  switch (type) {
    case CALL_TYPES.METHOD:
      return 'My Code';
    case CALL_TYPES.QUERY:
      return 'Queries';
    case CALL_TYPES.NPLUSONE:
      return 'N+1 Query';
    case CALL_TYPES.OVERUSED_API:
      return 'Overused API';
    case CALL_TYPES.EXTERNAL_API:
      return 'External API';
    case CALL_TYPES.KNOWNLIBRARY:
      return 'Known Libraries';
    case CALL_TYPES.WAITING:
      return 'Waiting';
    case CALL_TYPES.SLEEPING:
      return 'Sleeping';
    case CALL_TYPES.EXCEPTION:
      return 'Exceptions';
    case CALL_TYPES.THREAD_SPAWN:
      return 'Threading';
    case CALL_TYPES.GARBAGE_COLLECT:
      return 'Garbage Collection';
  }
  return uppercase ? uppercaseFirstCharacter(type) : type;
};

export const getTextStyleForType = (methodType: string): string => {
  switch (methodType) {
    case CALL_TYPES.QUERY:
    case CALL_TYPES.NPLUSONE:
      return '#2e3137';
    case CALL_TYPES.MANUAL:
      return '#666666';
    case CALL_TYPES.EXCEPTION:
    case CALL_TYPES.SYSTEM:
    case CALL_TYPES.METHOD:
    case CALL_TYPES.TRACE:
    case CALL_TYPES.SELF:
    case CALL_TYPES.DEFAULT:
    case CALL_TYPES.CUSTOM:
    default:
      return '#fcfbdb';
  }
};

// Query Types
export const QUERIES = {
  MYSQL: 'mysql',
  REDIS: 'redis',
  ELASTIC_SEARCH: 'elasticsearch',
  ES: 'es',
  POSTGRES: 'postgres',
  SQLSERVER: 'sqlserver',
  ORACLE: 'oracle',
};

export const getFillStyleForQueryType = (methodType: string, _highlighted = false): string => {
  // Gets the correct fill based on member type
  switch (methodType) {
    case QUERIES.MYSQL:
      return '#4CAF50';
    case QUERIES.REDIS:
      return '#E53935';
    case QUERIES.ELASTIC_SEARCH:
    case QUERIES.ES:
      return '#0278AE';
    case QUERIES.POSTGRES:
      return '#189CCC';
    case QUERIES.SQLSERVER:
      return '#FBC02D';
    default:
      return defaultColor;
  }
};

export const getTextStyleForQueryType = (methodType: string): string => {
  switch (methodType) {
    case QUERIES.MYSQL:
    case QUERIES.REDIS:
    case QUERIES.ELASTIC_SEARCH:
    case QUERIES.POSTGRES:
    case QUERIES.ES:
    default:
      return '#fff';
  }
};

export const getFillStyleForAnyType = (type: string): string => {
  const color = getFillStyleForType(type);
  if (color === defaultColor) {
    return getFillStyleForQueryType(type);
  }
  return color;
};

export const getQueryNameShort = (type: string): string => {
  switch (type) {
    case QUERIES.ELASTIC_SEARCH:
    case QUERIES.ES:
      return 'ES';
    case QUERIES.POSTGRES:
      return 'POSTGRES';
    default:
      return type.toUpperCase();
  }
};

export const getQueryNameLong = (type: string): string => {
  switch (type) {
    case QUERIES.ELASTIC_SEARCH:
    case QUERIES.ES:
      return 'Elasticsearch';
    case QUERIES.POSTGRES:
      return 'PostgreSQL';
    default:
      return `${type.substr(0, 1).toUpperCase()}${type.substr(1)}`;
  }
};
