/** @format */

import * as React from 'react';
import { omit } from 'lodash';

import { FetchableOnly } from 'interfaces/network';
import { Icon } from 'components/icon';

import { Button, Props as ButtonProps } from './button';

type Props = ButtonProps & FetchableOnly;

export const LoadingButton: React.FunctionComponent<Props> = props => {
  const omitedProps = omit(props, 'children');

  if (!props.isFetching) {
    return <Button {...omitedProps}>{props.children}</Button>;
  }

  return (
    <Button {...omit(omitedProps, 'title')} type="grey" disabled>
      <Icon type="loading-small" set="icomoon" size={16} center inline extraClasses="is-spinning" />
    </Button>
  );
};
