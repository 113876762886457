/** @format */

import * as React from 'react';
import { omit } from 'lodash';

import { Icon } from 'components/icon';
import { Button, Props as ButtonProps } from './button';

type Props = ButtonProps;

export const DropdownButton: React.FunctionComponent<Props> = props => {
  const omitedProps = omit(props, 'children');

  return (
    <Button
      size={36}
      {...omitedProps}
      iconAfter={<Icon size={16} type="dropdown" inButton="after" />}
    >
      {props.children}
    </Button>
  );
};
