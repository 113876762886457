/**
 * @prettier
 */

import { CALL_TYPES } from 'apm/constants';
import { DESCENDING } from 'constants/sorting';

import { TileDefinition } from '../../models';

import {
  ApdexChart,
  AveragePageBreakdownChart,
  RequestCountChart,
  RequestExecutionSpeedChart,
  SingleValueTile,
  SlowestMethods,
  SlowestQueries,
  SlowestExternalApiCalls,
  SlowestRequests,
  SlowestTraces,
  TopActiveIssues,
} from '../../components/tiles';

import { apmRequired, DEFAULT_DATE_RANGE, fetchAction } from '../shared';

import { SharedApmTileMap } from './shared';

export const ApmTileMap: TileDefinition[] = [
  ...SharedApmTileMap,
  {
    id: 'slowestMethods',
    name: 'Slowest methods',
    component: SlowestMethods,
    fetchAction,
    category: 'list',
    apiEndpoint: 'overview/slowestCalls',
    requirements: [apmRequired],
    metadata: {
      DefaultWidth: 7,
      DefaultHeight: 5,
      SupportsSettings: true,
      SortableFields: {
        Name: 'Name',
        AverageDuration: 'Average duration',
        TotalDuration: 'Total duration',
      },
      QueryString: { callType: CALL_TYPES.METHOD },
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
      TableSortingDirection: DESCENDING,
      TableSortingField: 'averageDuration',
    },
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'slowestQueries',
    name: 'Slowest queries',
    component: SlowestQueries,
    fetchAction: fetchAction,
    category: 'list',
    apiEndpoint: 'overview/slowestQueries',
    requirements: [apmRequired],
    metadata: {
      DataFetching: {
        apmEndpoint: true,
      },
      SupportsSettings: true,
      QueryString: { callType: CALL_TYPES.QUERY },
      SortableFields: {
        type: 'Type',
        name: 'Name',
        averageDuration: 'Average duration',
        totalDuration: 'Total duration',
      },
    },
    defaultSettings: {
      TableSortingDirection: DESCENDING,
      TableSortingField: 'averageDuration',
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'slowestRequests',
    name: 'Slowest requests',
    component: SlowestRequests,
    fetchAction: fetchAction,
    category: 'list',
    apiEndpoint: 'overview/slowestCalls',
    requirements: [apmRequired],
    metadata: {
      DataFetching: {
        apmEndpoint: true,
      },
      SupportsSettings: true,
      QueryString: { callType: CALL_TYPES.TRACE },
      SortableFields: {
        type: 'Type',
        name: 'Name',
        averageDuration: 'Average duration',
        totalDuration: 'Total duration',
      },
    },
    defaultSettings: {
      TableSortingDirection: DESCENDING,
      TableSortingField: 'averageDuration',
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'slowestExternalApi',
    name: 'Slowest external API calls',
    component: SlowestExternalApiCalls,
    fetchAction: fetchAction,
    category: 'list',
    apiEndpoint: 'overview/slowestQueries',
    requirements: [apmRequired],
    metadata: {
      DataFetching: {
        apmEndpoint: true,
      },
      SupportsSettings: true,
      SortableFields: {
        name: 'Name',
        averageDuration: 'Average duration',
        totalDuration: 'Total duration',
      },
      QueryString: { callType: CALL_TYPES.EXTERNAL_API },
    },
    defaultSettings: {
      TableSortingDirection: DESCENDING,
      TableSortingField: 'averageDuration',
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'slowestTraces',
    name: 'Slowest traces',
    component: SlowestTraces,
    fetchAction: fetchAction,
    category: 'list',
    apiEndpoint: 'overview/slowestTraces',
    requirements: [apmRequired],
    metadata: {
      DataFetching: {
        apmEndpoint: true,
      },
      SupportsSettings: true,
      SortableFields: {
        date: 'Date',
        name: 'Name',
        duration: 'Duration',
      },
    },
    defaultSettings: {
      TableSortingDirection: DESCENDING,
      TableSortingField: 'duration',
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'topActiveIssues',
    name: 'Top active issues',
    component: TopActiveIssues,
    fetchAction: fetchAction,
    category: 'list',
    apiEndpoint: 'overview/activeIssues',
    requirements: [apmRequired],
    metadata: {
      DataFetching: {
        apmEndpoint: true,
      },
      SupportsSettings: true,
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
];
