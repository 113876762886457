/**
 * @prettier
 */
import * as React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';

export type BUTTON_TYPES =
  | 'white'
  | 'grey'
  | 'light-grey'
  | 'blue'
  | 'green'
  | 'red'
  | 'yellow'
  | 'plain'
  | 'subtle'
  | 'purple'
  | 'transparent-blue';

export type BUTTON_SIZES = 24 | 32 | 36 | 40 | 42 | 48 | 56;

export interface Props {
  title?: string;
  url?: string;
  size?: BUTTON_SIZES;
  type?: BUTTON_TYPES;
  block?: boolean;
  extraClasses?: string;
  active?: boolean;
  disabled?: boolean;
  hasIconAfter?: boolean;
  hasIconBefore?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  id?: string;
}

export const Button: React.FunctionComponent<Props> = props => {
  const className = classnames(
    `button button-${props.size}`,
    {
      [`button-${props.type}`]: !isNil(props.type),
      'button-after': props.hasIconAfter,
      'button-before': props.hasIconBefore,
      'button-block': props.block,
      'button--disabled': props.disabled,
      'is-active': props.active,
    },
    `${props.extraClasses}`,
  );

  if (props.url) {
    return (
      <a
        className={className}
        href={props.url}
        title={props.title}
        onClick={props.onClick}
        id={props.id}
      >
        {props.children}
      </a>
    );
  } else {
    return (
      <button
        onClick={props.onClick}
        className={className}
        title={props.title}
        disabled={props.disabled}
        id={props.id}
      >
        {props.children}
      </button>
    );
  }
};

Button.defaultProps = {
  url: '',
  extraClasses: '',
  size: 32,
  block: false,
  hasIconAfter: false,
  hasIconBefore: false,
  disabled: false,
  active: false,
};

export default Button;
