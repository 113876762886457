/**
 * @prettier
 */

import * as React from 'react';
import { FormLayout, FormLayoutLabel, FormLayoutInput } from 'components/input/layout';
import { connect } from 'react-redux';
import { DashboardState } from 'sections/dashboard';
import { Models } from 'modules/dashboard';
import { MultiSelect } from 'components/input/multiselect';
import { getApplicationsForCurrentPlan } from 'app/selectors/application';
import { groupBy, every, isNil } from 'lodash';
import { Icon } from 'components/icon';
import { Button } from 'components/button/ui';
import { Application } from 'interfaces/application';
import { Padding } from 'components/layout';
import { Table, Cell } from 'components/table/active';
import { Avatar } from 'components/avatar';
import { Flex } from 'components/flex';
import { Helptip } from 'components/ds/tooltip';

type DispatchProps = {};
type StateProps = {
  applications: Application[];
};
type SuppliedProps = {
  tile: Models.TileDefinition;
  updateSelectedApplications: (selectedApplications: number[]) => void;
  updateSelectedColor: (color: string, appId: number) => void;
  canChangeColor: boolean;
  selectedColors: { [appId: number]: string };
  selectedApplications: number[];
};

type Props = DispatchProps & StateProps & SuppliedProps;

type Item = {
  value: any;
  display: string;
};

function applicationToItem(app: Application): Item {
  return { value: app.id, display: app.name };
}

function removeSource(
  updateSelectedApplications: (selectedApplications: number[]) => void,
  selectedApplications: number[],
  applicationToRemove: number,
) {
  return () =>
    updateSelectedApplications(selectedApplications.filter(a => a !== applicationToRemove));
}

/* tslint:disable:jsx-key */
const UnconnectedSelectSource: React.FunctionComponent<Props> = props => {
  const {
    tile,
    updateSelectedApplications,
    updateSelectedColor,
    selectedColors,
    canChangeColor,
    applications,
    selectedApplications,
  } = props;
  const validApplications = applications.filter(app => every(tile.requirements, req => req(app)));
  const applicationsById = groupBy(validApplications, a => a.id);
  const getApplication = (id: number) => (applicationsById[id] || [])[0];
  const selectedItems = selectedApplications
    .filter(appId => !isNil(getApplication(appId)))
    .map(appId => applicationToItem(getApplication(appId)));

  // If an application has been removed because it no longer meets the tile requirements then
  // update the setting when the modal is opened
  if (selectedItems.length !== selectedApplications.length) {
    updateSelectedApplications(selectedItems.map(x => x.value));
  }

  return (
    <>
      <FormLayout marginBottom>
        <FormLayoutLabel>
          {!isNil(props.tile.helpTips) && !isNil(props.tile.helpTips.HelpTipText) ? (
            <Flex align="center">
              <Padding right="8">
                <label htmlFor="addSource">Add sources:</label>
              </Padding>
              <Helptip label={props.tile.helpTips.HelpTipText} alignCenter>
                <img
                  src="/Content/Images/icons/ds/help.svg"
                  alt="Help icon."
                  width={16}
                  height={16}
                />
              </Helptip>
            </Flex>
          ) : (
            <label htmlFor="addSource">Add sources:</label>
          )}
        </FormLayoutLabel>
        <FormLayoutInput>
          <MultiSelect
            selectText={'Select application'}
            onChange={selectedItems => updateSelectedApplications(selectedItems.map(x => x.value))}
            items={validApplications.map(applicationToItem)}
            selectedItems={selectedItems}
          />
        </FormLayoutInput>
      </FormLayout>

      <Padding top="24" bottom="16">
        <div className="form-label">
          <label>Selected sources:</label>
        </div>

        <Table
          dataRows={selectedItems}
          noDataMessage="No data sources selected"
          getKey={(item: Item) => item.value}
        >
          {(item: Item) => [
            <Cell size={60} verticalLarge>
              <Avatar url={getApplication(item.value).avatarUrl} size={32} />
            </Cell>,
            <Cell spaceRight verticalLarge>
              {item.display}
            </Cell>,
            canChangeColor ? (
              <Cell size={60} spaceRight verticalLarge>
                <input
                  type="color"
                  className="form-color form-color--32 form-color--full-width"
                  defaultValue={selectedColors[item.value]}
                  onChange={e => updateSelectedColor(e.target.value, item.value)}
                />
              </Cell>
            ) : null,
            <Cell size={60} spaceRight verticalLarge>
              <Button
                icon
                size={32}
                block
                type="white"
                onClick={removeSource(updateSelectedApplications, selectedApplications, item.value)}
              >
                <Icon set="flat" center type="close" />
              </Button>
            </Cell>,
          ]}
        </Table>
      </Padding>
    </>
  );
};

export const SelectSource = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: DashboardState) => ({
    applications: getApplicationsForCurrentPlan(state),
  }),
)(UnconnectedSelectSource);
