/** @format **/

import React from 'react';

import { Icon } from 'components/icon';
import { Small } from 'components/breakpoints';

import { ToggleRowButton } from './toggleRowButton';

type Props = {
  toggleChildRows: () => void;
  showChildRows: boolean;
};

export const RowToggleCell: React.FunctionComponent<Props> = props => (
  <td className="table__cell table__cell-40 table__cell--no-padding-mobile" onClick={props.toggleChildRows}>
    <ToggleRowButton
      expanded={props.showChildRows}
      className="it-toggle-row-button"
      title={props.showChildRows ? 'Collapse rows' : 'Expand rows'}
    >
      <Icon set="icomoon" type="arrow-right" />
    </ToggleRowButton>
    <Small>
      <span>{props.showChildRows ? 'Collapse' : 'Expand'}</span>
    </Small>
  </td>
);
