/** @format **/
import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import { Flex } from 'components/flex';

import { getAccountMenuOpen } from '../../../selectors';
import { setAccountMenu } from '../../../actions';
import { LinkWrapper } from '../link/link';
import { CurrentUserAvatar } from './currentUserAvatar';

type StateProps = {
  isOpen: boolean;
};

type DispatchProps = {
  openAccount: () => void;
};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedActiveUser: React.FunctionComponent<Props> = ({ isOpen, openAccount }) => (
  <LinkWrapper active={false}>
    <a onMouseDown={!isOpen ? openAccount : null}>
      <Flex align="center" justify="center" minHeight="100%">
        <CurrentUserAvatar />
      </Flex>
    </a>
  </LinkWrapper>
);

const ConnectedActiveUser = connect<StateProps, DispatchProps, PassedProps, AppState>(
  state => ({
    isOpen: getAccountMenuOpen(state),
  }),
  dispatch => ({
    openAccount: () => dispatch(setAccountMenu(true)),
  }),
)(UnconnectedActiveUser);

export { ConnectedActiveUser as ActiveUser };
