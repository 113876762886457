/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { COLORS } from 'app/theme/materialDS';

import {
  withInteractivity,
  WithInteractivitySuppliedProps,
} from 'components/ds/hoc/withInteractivity';
import { Icon } from 'components/icon';

import { BaseDefaultProps } from './';

type PublicBaseIconProps = {
  icon: string;

  // colors
  textColor: COLORS;
  disabledTextColor: COLORS;
  backgroundColor: COLORS;
  disabledBackgroundColor: COLORS;

  hoverBackgroundColor: COLORS;
  pressedBackgroundColor: COLORS;
} & BaseDefaultProps &
  React.HTMLProps<HTMLDivElement>;

type BaseIconProps = PublicBaseIconProps & WithInteractivitySuppliedProps;

const StyledBaseIcon = (props: BaseIconProps & ThemeType): IStyle => {
  return applyModifiers(
    [
      props.roundLeftCorners,
      {
        borderTopLeftRadius: '2px',
        borderBottomLeftRadius: '2px',
      },
    ],
    [
      props.roundRightCorners,
      {
        borderTopRightRadius: '2px',
        borderBottomRightRadius: '2px',
      },
    ],
    [
      props.disabled,
      {
        cursor: 'not-allowed',
        backgroundColor: props.theme.ds.getColorByName(props.disabledBackgroundColor),
        color: props.theme.ds.getColorByName(props.disabledTextColor),
      },
    ],
    [
      (props.hovered || props.focused) && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName(props.hoverBackgroundColor),
      },
    ],
    [
      props.pressed && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName(props.pressedBackgroundColor),
      },
    ],
  )({
    color: props.theme.ds.getColorByName(props.textColor),
    backgroundColor: props.theme.ds.getColorByName(props.backgroundColor),
    width: '24px',
    height: '24px',
    padding: '4px',
    cursor: 'pointer',
    display: 'block',
    ':focus': {
      outline: 0,
    },
    ['-webkit-tap-highlight-color' as any]: props.theme.ds.colors.special.transparent,
  });
};

export const BaseIconWrapper = createComponentWithProxy<BaseIconProps>(StyledBaseIcon, 'div');

const BaseIconWithoutInteractivity: React.FunctionComponent<BaseIconProps> = props => (
  <BaseIconWrapper {...props}>
    <Icon set="icomoon" type={props.icon} />
  </BaseIconWrapper>
);

const BaseIcon = withInteractivity<PublicBaseIconProps, BaseIconProps>(
  BaseIconWithoutInteractivity,
);

export { BaseIcon, PublicBaseIconProps as BaseIconProps };
