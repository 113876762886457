/** @format **/

import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as Actions from '../actions';
import { DashboardTileSettingsState } from '../state';
import { TextAlignment } from '../models';

export const defaultState: DashboardTileSettingsState = {
  Actions: '',
  Applications: '',
  DateRange: '',
  Granularity: '',
  StatusFilter: '',
  TableSortingDirection: 'desc',
  TableSortingField: '',
  Title: '',
  TextAlignment: TextAlignment.LEFT,
  TextBold: null,
  TextSize: '14px',
  TextItalic: null,
  TextUnderline: null,
  TileColor: null,
  tileId: '',
};

export const DashboardTileSettingReducer = reducerWithInitialState(defaultState)
  .case(Actions.tileSetting.resetSettings, ({ tileId }) => ({ ...defaultState, tileId }))
  .case(Actions.tileSetting.updateSetting, (state, { key, value }) => ({
    ...state,
    [key]: value,
  }))
  .cases(
    [Actions.tileSetting.loadSettings, Actions.tileSetting.updateMultipleSettings],
    (state, payload) => ({
      ...state,
      ...payload,
    }),
  )
  .case(Actions.tileSetting.setTileId, (state, tileId) => ({
    ...state,
    tileId,
  }));
