/** @format */

import * as React from 'react';
import { Column, Table } from 'components/table';
import { SkeletonCell, SkeletonTableData } from 'components/table/cells';

export const LoadingSlowestQueries: React.FunctionComponent<{}> = () => {
  const columns: Column[] = [
    {
      header: 'Type',
      width: 80,
      cell: new SkeletonCell(),
    },
    {
      header: 'Query name',
      cell: new SkeletonCell(),
    },
    {
      header: 'Average duration',
      width: 160,
      cell: new SkeletonCell(),
    },
  ];

  return <Table data={SkeletonTableData} columns={columns} />;
};
