/** @format */

import * as React from 'react';
import cx from 'classnames';

type FullscreenProp = {
  requestName: string;
  changeName: string;
  enabledName: string;
  exitName: string;
  element: string;
};

let props: FullscreenProp = null;

const fallbacks: FullscreenProp[] = [
  {
    requestName: 'requestFullScreen',
    changeName: 'fullscreenchange',
    enabledName: 'fullscreenEnabled',
    exitName: 'exitFullscreen',
    element: 'fullscreenElement',
  },
  {
    requestName: 'webkitRequestFullscreen',
    changeName: 'webkitfullscreenchange',
    enabledName: 'webkitFullscreenEnabled',
    exitName: 'webkitExitFullscreen',
    element: 'webkitFullscreenElement',
  },
  {
    requestName: 'mozRequestFullScreen',
    changeName: 'mozfullscreenchange',
    enabledName: 'mozFullScreenEnabled',
    exitName: 'mozCancelFullScreen',
    element: 'mozFullScreenElement',
  },
  {
    requestName: 'msRequestFullscreen',
    changeName: 'msfullscreenchange',
    enabledName: 'msFullscreenEnabled',
    exitName: 'msExitFullscreen',
    element: 'msFullscreenElement',
  },
];

// Checks for Polyfill support
for (const i in fallbacks) {
  if ((document.body as any)[fallbacks[i].requestName]) {
    props = fallbacks[i];
    break;
  }
}

const supported = !!props;

let element: HTMLElement;

export function setFullscreenElement(el: HTMLElement) {
  element = el;
}

export function requestFullscreen(el: HTMLElement = element) {
  // Requests fullscreen on a particular element passed
  if (supported && el) {
    (el as any)[props.requestName]();
  }
}

export function exitFullscreen() {
  // Exits fullscreen mode
  if (supported) {
    (document as any)[props.exitName]();
  }
}

export function isActive() {
  // Returns a bool indicating if fullscreen mode is currently active or not
  if (!supported) {
    return false;
  }
  return (document as any)[props.element] !== null;
}

type RemoveOnChange = () => void;

export function onChange(callback: (isActive: boolean) => void): RemoveOnChange {
  const func = function() {
    callback(isActive());
  }.bind(window);

  if (supported) {
    document.addEventListener(props.changeName, func);
  }

  return () => {
    if (supported) {
      document.removeEventListener(props.changeName, func);
    }
  };
}

type FullscreenState = {
  isActive: boolean;
};

export class Fullscreen extends React.Component<{}, FullscreenState> {
  constructor(props: {}) {
    super(props);
    this.attachFullscreen = this.attachFullscreen.bind(this);
  }

  state: FullscreenState = {
    isActive: false,
  };

  attachFullscreen(el: HTMLElement) {
    this.el = el;
    setFullscreenElement(this.el);
  }

  el: HTMLElement;

  unbindCb: () => void;

  componentDidMount() {
    this.unbindCb = onChange(isActive => this.setState({ isActive }));
  }

  componentWillUnmount() {
    this.unbindCb();
  }

  render() {
    const classes = cx('fullscreen', {
      'fullscreen--active': this.state.isActive,
    });

    return (
      <div className={classes} ref={this.attachFullscreen}>
        {this.props.children}
      </div>
    );
  }
}
