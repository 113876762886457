/** @format **/

import React, { Dispatch, SetStateAction, useState } from 'react';
import { find, get, isFunction } from 'lodash';

import { NavigationItem, SecondaryNavigation } from 'components/page/header/navigation';
import { SecondaryHeaderLink } from './models';

export type Props = {
  secondaryLinks: SecondaryHeaderLink[];
  callbacks?: { [key: string]: (event?: React.MouseEvent<HTMLElement>) => void };
};

export const createSecondaryNavigationTabs = (
  props: Props,
  state: [string, Dispatch<SetStateAction<string>>],
): JSX.Element[] => {
  const tabs: JSX.Element[] = [];
  const [activeTab, setActiveTab] = state;

  props.secondaryLinks.forEach(link => {
    tabs.push(
      <NavigationItem
        key={link.linkText}
        text={link.linkText}
        to={link.linkUrl}
        callback={
          link.callbackName !== undefined
            ? event => {
                if (isFunction(get(props.callbacks, link.callbackName))) {
                  props.callbacks[link.callbackName](event);
                }
                setActiveTab(link.linkText);
              }
            : null
        }
        isActive={activeTab === link.linkText}
        color={'blue'}
      />,
    );
  });

  return tabs;
};

export const SecondaryTabs: React.FunctionComponent<Props> = props => {
  const activeTabState = useState(
    get(find(props.secondaryLinks, s => s.isActive), 'linkText', props.secondaryLinks[0].linkText),
  );
  const tabs = createSecondaryNavigationTabs(props, activeTabState);

  return <SecondaryNavigation>{tabs}</SecondaryNavigation>;
};

SecondaryTabs.defaultProps = {
  callbacks: {},
};

export { SecondaryHeaderLink };
