/**
 * @prettier
 */

import React from 'react';
import { ReactComponent } from 'utils/types';

export type WithFocusSuppliedProps = {
  focused: boolean;
  onMouseDown: React.MouseEventHandler<HTMLElement>;
  onFocus: React.FocusEventHandler<HTMLElement>;
  onBlur: React.FocusEventHandler<HTMLElement>;
};
type WithFocusState = {
  focused: boolean;
};

export function withFocus<TPublicProps, TFullProps extends WithFocusSuppliedProps>(
  Component: ReactComponent<TFullProps>,
): React.ComponentType<TPublicProps> {
  return (class WithFocus extends React.Component<TFullProps, WithFocusState> {
    constructor(props: TFullProps) {
      super(props);

      this.state = {
        focused: false,
      };

      this.onMouseDown = this.onMouseDown.bind(this);
      this.onMouseUp = this.onMouseUp.bind(this);
      this.onFocus = this.onFocus.bind(this);
      this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
      document.addEventListener('mouseup', this.onMouseUp);
    }

    componentWillUnmount() {
      document.removeEventListener('mouseup', this.onMouseUp);
    }

    onMouseDown(e: React.MouseEvent<HTMLButtonElement>) {
      this.setState({ focused: true });

      const _ignored = this.props.onMouseDown && this.props.onMouseDown(e);
    }

    onMouseUp() {
      this.setState({ focused: false });
    }

    onFocus(e: React.FocusEvent<HTMLElement>) {
      this.setState({ focused: true });

      const _ignored = this.props.onFocus && this.props.onFocus(e);
    }

    onBlur(e: React.FocusEvent<HTMLElement>) {
      this.setState({ focused: false });

      const _ignored = this.props.onBlur && this.props.onBlur(e);
    }

    render() {
      return (
        <Component
          {...this.props}
          focused={this.state.focused}
          onMouseDown={this.onMouseDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      );
    }
  } as unknown) as React.ComponentType<TPublicProps>;
}
