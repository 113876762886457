/** @format **/

import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getPlanIdentifier } from "app/selectors/configuration";
import { openModal } from "components/modal";
import { InviteTeamModal } from 'modules/inviteTeamModal';
import { AppState } from "interfaces/appState";
import { Link } from "../link";

type StateProps = {
  planIdentifier: string;
};

type DispatchProps = {
  openInviteTeamModal: () => void;
};

type Props = StateProps & DispatchProps;
export const UnconnectedInviteTeamLink: React.FunctionComponent<Props> = ({ planIdentifier, openInviteTeamModal }) => (
  <Link label="Invite team" active={false} svgSymbol="teams" url={`/plan/${planIdentifier}/teams`} onClick={openInviteTeamModal} />
);

export const InviteTeam = connect<StateProps, DispatchProps>(
  (state: AppState) => ({
    planIdentifier: getPlanIdentifier(state)
  }),
  (dispatch: Dispatch) => ({
    openInviteTeamModal: () => dispatch(openModal(InviteTeamModal))
  })
)(UnconnectedInviteTeamLink);
