/**
 * @prettier
 */

import * as React from 'react';
import { CheckboxIcon } from './icon';
import { CheckboxInput } from './input';
import { CheckboxLabel } from './label';
import { CheckboxWrapper, RuleProps } from './wrapper';
import { omit } from 'lodash';

export type Props = {
  isChecked?: boolean;
  isHovered?: boolean;
  onToggled?: (isChecked: boolean) => void;
  id?: string;
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
  isRadioButton?: boolean;
  disabled?: boolean;
  checkedBoxColor?: string;
} & RuleProps;

interface State {
  isChecked: boolean;
  isHovered: boolean;
}

export class Checkbox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isChecked: props.isChecked,
      isHovered: props.isHovered,
    };

    this.handleClick = this.handleClick.bind(this);
    this.triggerHover = this.triggerHover.bind(this);
    this.disableHover = this.disableHover.bind(this);
  }

  static defaultProps: Partial<Props> = {
    noPadding: false,
    dropdown: false,
  };

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.isChecked != this.props.isChecked) {
      this.setState({ isChecked: nextProps.isChecked });
    }

    if (nextProps.isHovered != this.props.isHovered) {
      this.setState({ isHovered: nextProps.isHovered });
    }
  }

  handleClick() {
    this.setState(prevState => {
      const isChecked = !prevState.isChecked;

      if (this.props.onToggled) {
        this.props.onToggled(isChecked);
      }

      return { isChecked };
    });
  }

  triggerHover() {
    this.setState({ isHovered: true });
  }

  disableHover() {
    this.setState({ isHovered: false });
  }

  renderLabel() {
    const children = this.props.children;

    if (children) {
      return <CheckboxLabel disabled={this.props.disabled}>{children}</CheckboxLabel>;
    }
  }

  render() {
    const wrapperProps = omit(this.props, 'children', 'isChecked', 'onToggled');

    return (
      <CheckboxWrapper
        onMouseEnter={this.triggerHover}
        onMouseLeave={this.disableHover}
        {...wrapperProps}
      >
        <CheckboxInput
          checked={this.state.isChecked}
          onChange={this.handleClick}
          isRadioButton={this.props.isRadioButton}
          disabled={this.props.disabled}
        />
        <CheckboxIcon
          checked={this.state.isChecked}
          hovered={this.state.isHovered}
          disabled={this.props.disabled}
          isRadioButton={this.props.isRadioButton}
          noPadding={this.props.noPadding}
          dropdown={this.props.dropdown}
          checkedBoxColor={this.props.checkedBoxColor}
        />
        {this.renderLabel()}
      </CheckboxWrapper>
    );
  }
}

export default Checkbox;
