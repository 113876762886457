/** @format **/

import * as Palettes from './palettes';

export * from './palettes';

export function getGraphColors(paletteType: Palettes.PALETTE_TYPES, numberOfColors: number) {
  let palette;

  switch (paletteType) {
    case Palettes.PALETTE_TYPES.Categorical:
      palette = Palettes.categorical;
      break;
    case Palettes.PALETTE_TYPES.Sequential:
      palette = Palettes.sequential;
      break;
    case Palettes.PALETTE_TYPES.TrafficLight:
      palette = Palettes.trafficLight;
      break;
    default:
      const _ec: never = paletteType;
  }

  return palette[numberOfColors];
}

export * from './colors';
