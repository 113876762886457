/** @format **/
import React from 'react';

import { Application } from 'interfaces/application';

import { generateCurrentPathFromId } from 'utils/links';

import { Flex } from 'components/flex';
import { TextEllipse } from 'components/text/ellipse';

import { Row } from './row';
import { RowFavourite } from './favourite';
import { RowText } from './text';
import { RowImage } from './image';

type ApplicationRowProps = {} & Application;

export const ApplicationRow: React.FunctionComponent<ApplicationRowProps> = props => (
  <Row href={generateCurrentPathFromId(props.id)}>
    <RowImage src={props.avatarUrl} alt={`${props.name} icon`} />
    <Flex fitContent>
      <RowText>
        <TextEllipse ellipseFullWidth>{props.name}</TextEllipse>
      </RowText>
    </Flex>
    <RowFavourite favourited={props.isFavourite} />
  </Row>
);
