/**
 * @prettier
 */

import * as React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createComponent } from 'react-fela';

import { ThemeType } from 'app/theme';

import * as Actions from 'modules/whatsnew/actions';
import { FilterTag } from './filterTag';

type StateProps = {};

type DispatchProps = {
  applyFilter: (tag: string) => void;
};

type PassedProps = {
  tags: string[];
};

type Props = StateProps & DispatchProps & PassedProps;

class UnconnectedTags extends React.Component<Props, {}> {
  tagItems = map(this.props.tags, tag => (
    <FilterTag key={tag} onClick={() => this.props.applyFilter(tag)}>
      {tag}
    </FilterTag>
  ));

  render() {
    return <>{this.tagItems}</>;
  }
}

const ConnectedTags = connect<StateProps, DispatchProps, PassedProps>(
  null,
  (dispatch: Dispatch) => ({
    applyFilter: tag => dispatch(Actions.addTagToFilters(tag)),
  }),
)(UnconnectedTags);

export { ConnectedTags as Tags };
