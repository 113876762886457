/** @format */

import * as React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';

import { Error } from 'components/error';

interface Props {
  forLoading?: boolean;
  color?: 'grey' | null;
  padding?: 'all' | 'vertical' | 'horizontal' | 'none' | null;
  paddingTop?: 'normal' | 'tight' | null;
  paddingBottom?: 'normal' | 'tight' | null;
  paddingLeft?: 'normal' | 'tight' | null;
  paddingRight?: 'normal' | 'tight' | null;
  borderTop?: boolean;
  borderBottom?: boolean;
  borderBottomInset?: boolean;
  paddingTight?: boolean;
  clearfix?: boolean;
  error?: Error;
}

const isPaddingActive = (props: Props, isTight: boolean, globalMatcher: string): boolean => {
  return props.paddingTight === isTight && props.padding === globalMatcher;
};

export const ModuleContent: React.FunctionComponent<Props> = props => {
  const classes = classnames('module-content', {
    'module-content--padding-all': isPaddingActive(props, false, 'all'),
    'module-content--padding-all-tight': isPaddingActive(props, true, 'all'),
    'module-content--padding-vertical': isPaddingActive(props, false, 'vertical'),
    'module-content--padding-vertical-tight': isPaddingActive(props, true, 'vertical'),
    'module-content--padding-horizontal': isPaddingActive(props, false, 'horizontal'),
    'module-content--padding-horizontal-tight': isPaddingActive(props, true, 'horizontal'),
    'module-content--padding-top': props.paddingTop === 'normal',
    'module-content--padding-top-tight': props.paddingTop === 'tight',
    'module-content--padding-bottom': props.paddingBottom === 'normal',
    'module-content--padding-bottom-tight': props.paddingBottom === 'tight',
    'module-content--padding-left': props.paddingLeft === 'normal',
    'module-content--padding-left-tight': props.paddingLeft === 'tight',
    'module-content--padding-right': props.paddingRight === 'normal',
    'module-content--padding-right-tight': props.paddingRight === 'tight',
    'module-content--border-top': props.borderTop,
    'module-content--border-bottom': props.borderBottom,
    'module-content--border-bottom-inset': props.borderBottomInset,
    'module-content--grey': props.color === 'grey',
    'module-content--loading': props.forLoading,
    clearfix: props.clearfix,
  });

  const hasError = !isNil(props.error);

  return (
    <div className={classes}>
      {!hasError && props.children}
      {hasError && <Error error={props.error} />}
    </div>
  );
};

ModuleContent.defaultProps = {
  forLoading: false,
  color: null,
  padding: 'all',
  paddingTight: false,
  paddingTop: null,
  paddingBottom: null,
  paddingLeft: null,
  paddingRight: null,
  borderTop: false,
  borderBottom: false,
  borderBottomInset: false,
  clearfix: false,
};

export default ModuleContent;
