/**
 * @prettier
 */

import { round } from 'lodash';

export const MICROSECONDS: 'microseconds' = 'microseconds';
export const MILLISECONDS: 'milliseconds' = 'milliseconds';
export const SECONDS: 'seconds' = 'seconds';
export const MINUTES: 'minutes' = 'minutes';
export const HOURS: 'hours' = 'hours';

const getTimeDisplay = (long?: boolean) => ({
  milliseconds: long ? ' milliseconds' : 'ms',
  seconds: long ? ' seconds' : 's',
  minutes: long ? ' minutes' : 'm',
  hours: long ? ' hours' : 'h',
  microseconds: long ? ' microseconds' : 'μs',
});

export type DurationUnit =
  | typeof MICROSECONDS
  | typeof MILLISECONDS
  | typeof SECONDS
  | typeof HOURS
  | typeof MINUTES;

const MILLISECONDS_IN_ONE_SECOND = 1000;
const MILLISECONDS_IN_ONE_MINUTE = MILLISECONDS_IN_ONE_SECOND * 60;
const MILLISECONDS_IN_ONE_HOUR = MILLISECONDS_IN_ONE_SECOND * 60 * 60;

export function getIdealUnit(milliseconds: number): DurationUnit {
  if (milliseconds < 1) {
    return MICROSECONDS;
  }
  if (milliseconds <= 500) {
    return MILLISECONDS;
  }
  if (milliseconds >= MILLISECONDS_IN_ONE_HOUR) {
    return HOURS;
  }
  if (milliseconds >= MILLISECONDS_IN_ONE_MINUTE) {
    return MINUTES;
  }
  return SECONDS;
}

export function convertFromMicroseconds(
  milliseconds: number,
  unit: DurationUnit = getIdealUnit(milliseconds),
): number {
  if (unit === MICROSECONDS) {
    return milliseconds * 1000;
  }
  if (unit === MILLISECONDS) {
    return milliseconds;
  }
  if (unit === SECONDS) {
    return milliseconds / 1000;
  }
  if (unit === MINUTES) {
    return milliseconds / 60000;
  }
  if (unit === HOURS) {
    return milliseconds / 3600000;
  }
  return milliseconds;
}

export function formatDuration(
  milliseconds: number,
  unit: DurationUnit = getIdealUnit(milliseconds),
): string {
  return `${round(convertFromMicroseconds(milliseconds, unit), 2).toLocaleString()}${
    getTimeDisplay(false)[unit]
  }`;
}

export function formatMillisecondsAsMultiPartDuration(
  milliseconds: number,
  long: boolean = false,
  roundingPrecision: number = 0,
  secondsThreshold: number = 9,
): string {
  const timeDisplays = getTimeDisplay(long);

  if (!milliseconds) {
    return `0${timeDisplays.milliseconds}`;
  }

  if (milliseconds < 1) {
    return `${(milliseconds * MILLISECONDS_IN_ONE_SECOND).toLocaleString()}${
      timeDisplays.microseconds
    }`;
  }

  const remaining = Math.max(0, round(milliseconds, roundingPrecision));
  const hours = round(remaining / MILLISECONDS_IN_ONE_HOUR, roundingPrecision);

  if (hours >= 1) {
    return `${round(remaining / MILLISECONDS_IN_ONE_HOUR, 2).toLocaleString()}${
      timeDisplays.hours
    }`;
  }

  const minutes = round(remaining / MILLISECONDS_IN_ONE_MINUTE, roundingPrecision);
  if (minutes > 1) {
    return `${round(remaining / MILLISECONDS_IN_ONE_MINUTE, 2).toLocaleString()}${
      timeDisplays.minutes
    }`;
  }

  const seconds = Math.floor(remaining / MILLISECONDS_IN_ONE_SECOND);
  if (seconds > secondsThreshold) {
    return `${round(remaining / MILLISECONDS_IN_ONE_SECOND, 2).toLocaleString()}${
      timeDisplays.seconds
    }`;
  }

  return `${remaining.toLocaleString()}${timeDisplays.milliseconds}`;
}

export const formatMillisecondsAsLongMultiPartDuration = (milliseconds: number): string =>
  formatMillisecondsAsMultiPartDuration(milliseconds, true);
