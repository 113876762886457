/**
 * @prettier
 */
import React from 'react';
import { isNil } from 'lodash';

import { Error, Props as ErrorProps } from './error';

declare const rg4js: any;

type Props = {
  blackhole?: boolean;
} & Partial<ErrorProps>;

export class ErrorBoundary extends React.Component<Props, { error: Error }> {
  constructor(props: Props) {
    super(props);

    this.state = { error: undefined };
  }

  componentDidCatch(e: Error, _info: any) {
    if ((window as any).TESTING) {
      throw e;
    }

    this.setState({ error: e });

    const _ignored = !isNil((window as any).rg4js) && rg4js('send', { error: e, tags: ['React'] });
  }

  render() {
    if (this.state.error) {
      if (this.props.blackhole) {
        return null;
      }

      return <Error error={this.state.error} {...this.props} />;
    }

    return this.props.children;
  }
}
