/**
 * @prettier
 */

import * as React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { AppState } from 'interfaces/appState';
import * as Selectors from 'modules/whatsnew/selectors';
import * as Actions from 'modules/whatsnew/actions';

import { default as Text } from 'components/text';
import { WhatsNewState } from '../state';
import { ThemeType } from 'app/theme';
import { FilterTag } from './filterTag';

type StateProps = {
  filters: WhatsNewState['filters'];
  announcementCount: number;
};

type DispatchProps = {
  removeTagFilter: (tag: string) => void;
};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

const StyledFilterContainer = (): IStyle => ({
  display: 'flex',
  position: 'relative',
  marginBottom: '20px',
  flexWrap: 'wrap',
  alignContent: 'center',
});

const FilterContainer = createComponent(StyledFilterContainer);

class UnconnectedTagFilterBar extends React.Component<Props, {}> {
  render() {
    const tagItems = map(this.props.filters.tags, tag => (
      <FilterTag key={tag} onClick={() => this.props.removeTagFilter(tag)}>
        {tag}
      </FilterTag>
    ));

    if (this.props.filters.tags.length === 0) {
      return null;
    }

    return (
      <FilterContainer>
        {tagItems}
        <Text size="medium" color="black" weight="semi-bold" layout="inline">{`${
          this.props.announcementCount
        } announcements`}</Text>
      </FilterContainer>
    );
  }
}

const ConnectedTagFilterBar = connect<StateProps, DispatchProps, PassedProps>(
  (state: AppState) => ({
    filters: Selectors.getFilters(state),
    announcementCount: Selectors.getFilteredAnnouncementCount(state),
  }),
  (dispatch: Dispatch) => ({
    removeTagFilter: tag => dispatch(Actions.removeTagFromFilters(tag)),
  }),
)(UnconnectedTagFilterBar);

export { ConnectedTagFilterBar as TagFilterBar };
