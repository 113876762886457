/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

import { Icon } from 'components/icon';

const StyledModalClose = (): IStyle => ({
  position: 'absolute',
  right: '0',
  top: '0',
  width: '40px',
  height: '40px',
  padding: '4px',
});

const ModalCloseContainer = createComponent(StyledModalClose, 'div', ['onClick']);

const StyledModalCloseButton = (props: ThemeType): IStyle => ({
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  display: 'block',
  width: '32px',
  height: '32px',
  padding: '8px',
  borderRadius: '4px',
  color: `${props.theme.colors.text.black}`,
  background: 'none',
  ':hover': {
    color: `white`,
    background: `${props.theme.colors.material.red500}`,
  },
  ':active': {
    color: 'white',
    background: `${props.theme.colors.material.red700}`,
  },
});

const ModalCloseButton = createComponent(StyledModalCloseButton);

type Props = {
  onClick: () => void;
};

export const ModalClose: React.FunctionComponent<Props> = ({ onClick }) => (
  <ModalCloseContainer onClick={onClick}>
    <ModalCloseButton>
      <Icon size={16} type="close" />
    </ModalCloseButton>
  </ModalCloseContainer>
);
