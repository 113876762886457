/**
 * @prettier
 */

import * as React from 'react';

import { ASCENDING, SortDirection } from 'constants/sorting';

import { Column, SortedTable } from 'components/table';
import { NumberCell } from 'components/table/cells';

import * as Actions from '../../../../actions';

import { ApplicationCell } from './applicationCell';

type Props = {
  id: string;
  data: Actions.ErrorsPerAppListData[];
  navigationCell: React.FunctionComponent<Actions.ErrorsPerAppListData>;
  sortField: string;
  sortDirection: SortDirection;
  updateSorting: (sortField: string, sortDirection: SortDirection) => void;
};

export const ErrorsPerAppTable: React.FunctionComponent<Props> = props => {
  const columns: Column<Actions.ErrorsPerAppListData>[] = [
    {
      header: 'Application',
      cell: ApplicationCell,
      sortOptions: {
        defaultSortDirection: ASCENDING,
        sortField: 'Name',
      },
    },
    {
      header: 'Groups',
      width: 100,
      cell: new NumberCell({
        accessor: (rowItem: Actions.ErrorsPerAppListData) =>
          rowItem.ErrorGroupCount.toLocaleString(),
      }),
      sortOptions: {
        defaultSortDirection: ASCENDING,
        sortField: 'ErrorGroupCount',
      },
    },
    {
      header: 'Instances',
      width: 100,
      cell: new NumberCell({
        accessor: (rowItem: Actions.ErrorsPerAppListData) =>
          rowItem.ErrorInstanceCount.toLocaleString(),
      }),
      sortOptions: {
        defaultSortDirection: ASCENDING,
        sortField: 'ErrorInstanceCount',
      },
    },
    { header: '', width: 40, cell: props.navigationCell },
  ];

  return (
    <SortedTable
      columns={columns}
      data={props.data}
      sortField={props.sortField}
      sortDirection={props.sortDirection}
      getSortField={(
        item: Actions.ErrorsPerAppListData,
        field: keyof Actions.ErrorsPerAppListData,
      ) => item[field]}
      onSortChange={props.updateSorting}
    />
  );
};
