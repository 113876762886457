/** @format */

import { call, fork, select, take, takeEvery } from 'redux-saga/effects';

import { trackPage } from 'utils/raygun';
import { ROUTE_ACTION_BASE } from 'utils/routing';
import { AppState } from 'interfaces/appState';
import { getLocation } from 'selectors/router';

import { HydrateNotificationsFromCookie } from 'app/notifications/sagas';

function* postRouteToRaygun() {
  yield call(trackPage);
}

function* trackSpaCallsRaygun() {
  // Omit the first route action before posting for all routes, this stops duplicate entries coming through.
  yield take(routeAction);
  yield takeEvery(routeAction, postRouteToRaygun);
}

export function* scrollBackToTop(action: Action) {
  const state: AppState = yield select();
  const lastLocation = getLocation(state);

  if (lastLocation !== action.type) {
    yield call(window.scrollTo, 0, 0);
  }
}

type Action = { type: string };
const routeAction = ({ type }: Action) => type.indexOf(ROUTE_ACTION_BASE) > -1;

export function* AppSaga() {
  yield fork(trackSpaCallsRaygun);
  yield fork(HydrateNotificationsFromCookie);
  yield takeEvery(routeAction, scrollBackToTop);
}
