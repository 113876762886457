/** @format */

import * as React from 'react';
import { TypeBadge } from 'apm/components/typeBadge';
import { QueryTypePill } from 'apm/components/queryTypePill';
import { CellBase } from 'components/table/tableModel';

export enum CELL_TYPES {
  METHOD,
  QUERY,
}

export class TypeCell extends CellBase {
  asIcon: boolean = false;

  type?: CELL_TYPES;

  constructor(init: Partial<TypeCell>) {
    super(init);
  }

  render(rowItem: any) {
    const value = (this.getValue(rowItem) as string) || 'unknown';

    if (this.type === CELL_TYPES.QUERY) {
      return <QueryTypePill type={value} onlyIcon={this.asIcon} />;
    } else {
      return <TypeBadge type={value} onlyIcon={this.asIcon} />;
    }
  }
}
