/** @format **/

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { keys, isNil } from 'lodash';

import { nestedReducerUpdate, reducerUpdate } from 'utils/reducerUpdate';

import { SystemTeams } from 'interfaces/teams';

import { LocalStorageUtil } from '../../utils/storage';
import * as Actions from './actions';
import * as Models from './models';
import { InviteTeamModalState } from './state';

const defaultRecipient: Models.Recipient = {
  id: '0',
  emailAddress: '',
  accessLevel: SystemTeams.Users,
};

export const initialState: InviteTeamModalState = {
  recipientData: {
    '0': {
      ...defaultRecipient,
      id: '0',
    },
    '1': {
      ...defaultRecipient,
      id: '1',
    },
    '2': {
      ...defaultRecipient,
      id: '2',
    },
  },
  seenInviteModal: !isNil(LocalStorageUtil.getItem('inviteteam:notification')),
};

export const InviteTeamModalReducer = reducerWithInitialState(initialState)
  .case(Actions.addRecipient, state => {
    const id = keys(state.recipientData).length.toString();

    return nestedReducerUpdate(state, 'recipientData', {
      [id]: {
        ...defaultRecipient,
        id,
      },
    });
  })
  .case(Actions.updateRecipient, (state, payload) =>
    nestedReducerUpdate(state, 'recipientData', {
      [payload.id]: {
        emailAddress: payload.emailAddress,
        accessLevel: payload.accessLevel || SystemTeams.Users,
        id: payload.id,
      },
    }),
  )
  .case(Actions.resetRecipients, state => {
    return nestedReducerUpdate(state, 'recipientData', initialState.recipientData);
  })
  .case(Actions.seenInviteTeam, state => {
    localStorage.setItem('inviteteam:notification', 'true');
    return reducerUpdate(state, { seenInviteModal: true });
  });
