/** @format */
import React from 'react';
import { connect } from 'react-redux';
import { first, isNil } from 'lodash';

import { buildPlanLink } from 'utils/links';

import { AppState } from 'interfaces/appState';
import { Plan } from 'interfaces/plan';
import { getPlanForPage, isOnPlanLevelPage, getPlans } from 'app/selectors/configuration';

import { MenuItem, MenuTitle } from './items';

type StateProps = {
  plan: Plan;
  hasPlanForPage: boolean;
};

type DispatchProps = {};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedPlanMenuItem: React.FunctionComponent<Props> = ({ plan, hasPlanForPage }) =>
  hasPlanForPage ? (
    <>
      <MenuTitle asMenuItem>{plan.PlanName}</MenuTitle>
      <MenuItem href={buildPlanLink(plan.Id)}>Manage plan &amp; billing</MenuItem>
    </>
  ) : null;

const ConnectedSinglePlanMenuItem = connect<StateProps, DispatchProps, PassedProps>(
  (state: AppState) => {
    const plan = first(getPlans(state));
    return {
      plan,
      hasPlanForPage: !isNil(plan),
    };
  },
)(UnconnectedPlanMenuItem);

const ConnectedCurrentPlanMenuItem = connect<StateProps, DispatchProps, PassedProps>(
  (state: AppState) => ({
    plan: getPlanForPage(state),
    hasPlanForPage: isOnPlanLevelPage(state),
  }),
)(UnconnectedPlanMenuItem);

export {
  ConnectedCurrentPlanMenuItem as CurrentPlanMenuItem,
  ConnectedSinglePlanMenuItem as SinglePlanMenuItem,
};
