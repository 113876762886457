/**
 * @prettier
 */
/* tslint:disable:max-file-line-count */

import * as React from 'react';
import { get, keys, has, debounce, isEmpty, isNil } from 'lodash';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ASCENDING, SortDirection } from 'constants/sorting';
import { HorizontalRule } from 'components/horizontalRule';
import { OPTION } from 'components/select';

import { DashboardState } from 'sections/dashboard';

import * as Models from '../../models';
import * as Constants from '../../constants';
import * as Actions from '../../actions';
import * as Selectors from '../../selectors';

import { SelectSource } from './selectSource';
import * as SettingComponents from './settingComponents';
import { SettingsModalBodyContent } from './modal';

type StateProps = {
  colors: { [appId: number]: string };
  settings: Models.TileSettings;
  isTimeboard: boolean;
};
type DispatchProps = {
  updateAppColor: (color: string, appId: number) => void;
  settingActions: typeof Actions.tileSetting;
};
type SuppliedProps = {
  tile: Models.TileDefinition;
  initialSettings?: Models.TileSettings;
};

type Props = StateProps & DispatchProps & SuppliedProps;

const defaultColors = [
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#CDDC39',
  '#FFC107',
  '#FF5722',
  '#9E9E9E',
  '#f44336',
  '#9C27B0',
  '#3F51B5',
  '#03A9F4',
  '#009688',
  '#8BC34A',
  '#FFEB3B',
  '#FF9800',
  '#795548',
  '#607D8B',
];

type State = {
  firstRender: boolean;
};
class TileSettingsEditor extends React.Component<Props, State> {
  updateSourceSetting: (applicationIds: number[]) => void;
  updateSingleSourceSetting: (applicationId: number) => void;
  updateSelectedColor: (color: string, appId: number) => void;

  constructor(props: Props) {
    super(props);

    this.updateSetting = this.updateSetting.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.updateDateRangeSetting = this.updateDateRangeSetting.bind(this);
    this.updateStatusFilterSetting = this.updateStatusFilterSetting.bind(this);
    this.handleSortDirectionChange = this.handleSortDirectionChange.bind(this);
    this.getGranularityOptions = this.getGranularityOptions.bind(this);
    this.getGranularityOrDefault = this.getGranularityOrDefault.bind(this);

    this.updateSourceSetting = appIds => {
      appIds.forEach(id => {
        if (has(this.props.colors, id)) {
          return;
        }

        this.updateSelectedColor(defaultColors[id % defaultColors.length], id);
      });

      this.updateSetting('Applications', appIds.map(String).join(','));
    };

    this.updateSingleSourceSetting = appId => {
      this.updateSetting('Applications', appId.toString());
    };

    this.updateSelectedColor = debounce(props.updateAppColor, 250);

    this.props.settingActions.resetSettings();

    if (!isNil(this.props.initialSettings)) {
      this.props.settingActions.loadSettings(this.props.initialSettings);
    } else {
      this.props.settingActions.loadSettings({
        ...this.props.tile.defaultSettings,
        Title: this.props.tile.name,
      });
    }

    this.state = {
      firstRender: true,
    };
  }

  getSetting<K extends keyof Models.TileSettings>(
    settingType: K,
    defaultIfNull: Models.TileSettings[K] = null,
  ): Models.TileSettings[K] {
    return get(this.props.settings, settingType, defaultIfNull);
  }

  updateSetting<K extends keyof Models.TileSettings>(setting: K, newValue: Models.TileSettings[K]) {
    this.props.settingActions.updateSetting({ key: setting, value: newValue });
  }

  updateMultipleSettings(settings: Partial<Models.TileSettings>) {
    this.props.settingActions.updateMultipleSettings(settings);
  }

  updateDateRangeSetting(newValue: any) {
    // When changing date range if there is a granularity then ensure the value is in the valid range
    const settings: Pick<Models.TileSettings, 'DateRange' | 'Granularity'> = {};

    settings.DateRange = newValue;

    const currentGranularity = this.getSetting('Granularity');
    const validGranularities = Constants.granularitiesForDateRange(newValue);
    const hasGranularity = validGranularities.some(x => x.value === currentGranularity);

    if (!hasGranularity) {
      // current granularity is not valid anymore so reset it
      settings.Granularity = validGranularities[0].value;
    }

    this.updateMultipleSettings(settings);
  }

  updateStatusFilterSetting(isChecked: boolean, status: string) {
    const resultString = this.getSetting('StatusFilter');
    const result = resultString ? resultString.split(',') : [];

    if (isChecked) {
      result.push(status);
    } else {
      result.splice(result.indexOf(status), 1);
    }

    this.updateSetting('StatusFilter', result.join(','));
  }

  hasSetting(setting: keyof Models.TileSettings, requiresTimeboard: boolean = false): boolean {
    if (requiresTimeboard && this.props.isTimeboard) {
      return false;
    }

    const settings = keys(this.props.tile.defaultSettings) || [];
    return settings.some(i => setting === i);
  }

  hasMetadata(attribute: keyof Models.TileMetadata): boolean {
    const metadata = keys(this.props.tile.metadata) || [];
    return metadata.some(i => attribute === i);
  }

  handleActionChange(type: string) {
    let selectedActions = this.getSetting('Actions').split(',');

    if (selectedActions.indexOf(type) > -1) {
      selectedActions = selectedActions.filter(i => i !== type);
    } else {
      selectedActions.push(type);
    }

    this.updateSetting('Actions', selectedActions.join(','));
  }

  getGranularityOptions(): OPTION[] {
    const dateRange = this.getSetting('DateRange');

    return Constants.granularitiesForDateRange(dateRange).map(d => ({
      text: d.display,
      value: d.value,
    }));
  }

  getGranularityOrDefault(): string {
    const dateRange = this.getSetting('DateRange');

    return this.getSetting('Granularity', Constants.granularitiesForDateRange(dateRange)[0].value);
  }

  getSortFieldOptions(): OPTION[] {
    const options = [];
    const sortableFields = this.props.tile.metadata.SortableFields;

    for (const i in sortableFields) {
      options.push({
        value: i,
        text: sortableFields[i],
      });
    }

    return options;
  }

  handleSortDirectionChange(direction: SortDirection) {
    this.updateSetting('TableSortingDirection', direction);
  }

  render() {
    // This is a hack
    // The constructor calls redux actions to update the store state
    // This is so we can
    // 1. reset the store state from the previous tile
    // 2. load either the existing settings in the case of an edit, or
    // the default settings in the case of a new tile
    // Since this is done during the constructor the initial render happens
    // with the old state. This short circuits the first render so the nothing
    // crashes from bad data.
    if (this.state.firstRender) {
      this.setState({ firstRender: false });

      return null;
    }

    // Dynamic settings here...
    const dateRange = this.hasSetting('DateRange', true) ? (
      <SettingComponents.DateRange
        dateRangeSetting={this.getSetting('DateRange')}
        updateDateRangeSetting={this.updateDateRangeSetting}
      />
    ) : null;

    const statusFilterSettingString = this.getSetting('StatusFilter');

    const statusFilter = this.hasSetting('StatusFilter') ? (
      <SettingComponents.StatusFilter
        statusFilterSettingString={statusFilterSettingString}
        updateStatusFilterSetting={this.updateStatusFilterSetting}
      />
    ) : null;

    const tableSorting = this.hasSetting('TableSortingField') ? (
      <SettingComponents.TableSorting
        sortFieldOptions={this.getSortFieldOptions()}
        tableSortingField={this.getSetting('TableSortingField')}
        tableSortingDirection={this.getSetting('TableSortingDirection', ASCENDING)}
        updateSortingField={field => this.updateSetting('TableSortingField', field)}
        handleSortDirectionChange={this.handleSortDirectionChange}
      />
    ) : null;

    const chartGranularity = this.hasSetting('Granularity') ? (
      <SettingComponents.ChartGranularity
        granularityOptions={this.getGranularityOptions()}
        granularityOrDefault={this.getGranularityOrDefault()}
        updateSetting={range => this.updateSetting('Granularity', range)}
      />
    ) : null;

    const getSelectedApplications = (applications: string) =>
      applications
        .split(',')
        .filter(x => x.length > 0)
        .map(appId => parseInt(appId, 10));

    const selectedApplications =
      this.hasSetting('Applications', true) && !this.hasMetadata('SingleApplicationOnly') ? (
        <>
          <HorizontalRule marginBottom={32} marginTop={32} />
          <SelectSource
            tile={this.props.tile}
            updateSelectedApplications={this.updateSourceSetting}
            selectedApplications={getSelectedApplications(this.getSetting('Applications', ''))}
            canChangeColor={this.props.tile.metadata.SupportsAppColors}
            selectedColors={this.props.colors}
            updateSelectedColor={this.updateSelectedColor}
          />
        </>
      ) : null;

    const selectedApplication =
      this.hasSetting('Applications') && this.hasMetadata('SingleApplicationOnly') ? (
        <>
          <HorizontalRule marginBottom={32} marginTop={32} />
          <SettingComponents.SelectSingularSource
            tile={this.props.tile}
            selectedApplication={this.getSetting('Applications', '')}
            updateSelectedApplication={appId => this.updateSingleSourceSetting(parseInt(appId))}
          />
        </>
      ) : null;

    const resourceType = this.hasSetting('ResourceType') ? (
      <SettingComponents.ResourceSelector
        resourceSetting={this.getSetting('ResourceType')}
        updateResourceSetting={(resourceType: string) =>
          this.updateSetting('ResourceType', resourceType)
        }
      />
    ) : null;

    const selectedActions = this.getSetting('Actions', '').split(',');

    const selectActions = this.hasSetting('Actions') ? (
      <SettingComponents.SelectActions
        selectedActions={selectedActions}
        handleActionChange={this.handleActionChange}
      />
    ) : null;

    const textStyleActions = this.hasSetting('TextSize') ? (
      <SettingComponents.TextStyles
        {...this.props.settings}
        showTextColor={this.hasSetting('TextColor')}
        updateTextSetting={(key, setting) => this.updateSetting(key, setting)}
      />
    ) : null;

    const tileColor = this.hasSetting('TileColor') ? (
      <SettingComponents.TileColor
        color={this.getSetting('TileColor')}
        onColorChange={color => this.updateSetting('TileColor', color)}
      />
    ) : null;

    const textInputActions = this.hasSetting('Text') ? (
      <SettingComponents.Text
        text={this.getSetting('Text')}
        updateText={text => this.updateSetting('Text', text)}
      />
    ) : null;

    return (
      <SettingsModalBodyContent
        title={this.getSetting('Title')}
        onTitleChange={title => this.updateSetting('Title', title)}
      >
        {statusFilter}
        {tableSorting}
        {dateRange}
        {chartGranularity}
        {resourceType}
        {selectedApplication}
        {selectActions}
        {selectedApplications}
        {textStyleActions}
        {tileColor}
        {textInputActions}
      </SettingsModalBodyContent>
    );
  }
}

const ConnectedTileSettingsEditor = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: DashboardState) => ({
    colors: Selectors.getCurrentColors(state),
    settings: Selectors.tileSetting.getSettings(state),
    isTimeboard: Selectors.isTimeboard(state),
  }),
  (dispatch: Dispatch) => ({
    updateAppColor: (color, appId) => dispatch(Actions.updateAppColor({ color, appId })),
    settingActions: bindActionCreators(Actions.tileSetting, dispatch),
  }),
)(TileSettingsEditor);

export { ConnectedTileSettingsEditor as TileSettingsEditor };
