/** @format **/

import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { isNil } from 'lodash';

import { applyModifiers } from 'fela-rules/modifiers';
import {
  screenLargeAndAbove,
  screenMediumAndAbove,
  screenMediumOnly,
  screenSmallOnly,
  screenXXLargeOnly,
} from 'fela-rules/breakpoints';
import { Omit } from 'utils/types';

type Props = {
  align?: IStyle['alignItems'];
  alignContent?: IStyle['alignContent'];
  justify?: IStyle['justifyContent'];
  direction?: IStyle['flexDirection'];
  wrap?: IStyle['flexWrap'];
  flow?: IStyle['flexFlow'];
  minHeight?: string;
  minWidth?: string;
  order?: number;
  flex?: number;
  basis?: IStyle['flexBasis'];
  smallScreens?: Omit<Props, 'smallScreens'>;
  mediumScreens?: Omit<Props, 'mediumScreens'>;
  xxLargeScreens?: Omit<Props, 'xxLargeScreens'>;
  flexOnMediumAndAbove?: boolean;
  flexOnLargeOnly?: boolean;
  fitContent?: boolean;
};

const StyledFlex = (props: Props): IStyle =>
  applyModifiers(
    [!isNil(props.align), { alignItems: props.align as any }],
    [!isNil(props.alignContent), { alignContent: props.alignContent }],
    [!isNil(props.flow), { flexFlow: props.flow }],
    [!isNil(props.justify), { justifyContent: props.justify }],
    [!isNil(props.direction), { flexDirection: props.direction }],
    [!isNil(props.minHeight), { minHeight: props.minHeight }],
    [!isNil(props.minWidth), { minWidth: props.minWidth }],
    [!isNil(props.order), { order: props.order }],
    [!isNil(props.flex), { flex: props.flex }],
    [!isNil(props.basis), { flexBasis: props.basis }],
    [!isNil(props.wrap), { flexWrap: props.wrap }],
    [
      !isNil(props.smallScreens),
      // Need to have this guard here as well or it always evaluates this expression
      // === infinite loop
      !isNil(props.smallScreens) && screenSmallOnly(StyledFlex(props.smallScreens)),
    ],
    [
      !isNil(props.mediumScreens),
      !isNil(props.mediumScreens) && screenMediumOnly(StyledFlex(props.mediumScreens)),
    ],
    [
      !isNil(props.xxLargeScreens),
      !isNil(props.xxLargeScreens) && screenXXLargeOnly(StyledFlex(props.xxLargeScreens)),
    ],
    [
      props.flexOnLargeOnly,
      {
        display: 'block',
        ...screenLargeAndAbove({ display: 'flex' }),
      },
    ],
    [
      props.flexOnMediumAndAbove,
      {
        display: 'block',
        ...screenMediumAndAbove({ display: 'flex' }),
      },
    ],
    // fitContent will cause the element to expand/shrink to the naturally available space.
    // This can help fix issues with long content which needs to be truncated
    [
      props.fitContent,
      {
        flexGrow: 1,
        width: 0,
      },
    ],
  )({
    display: 'flex',
  });

export const Flex = createComponent(StyledFlex);

type StyledFlexBlockProps = {
  order?: number;
  grow?: number;
  shrink?: number;
  basis?: string;
  alignRight?: boolean;
};

const StyledFlexBlock = (props: StyledFlexBlockProps): IStyle =>
  applyModifiers([
    props.alignRight,
    {
      marginLeft: 'auto',
    },
  ])({
    order: props.order,
    flexGrow: props.grow,
    flexShrink: props.shrink,
    flexBasis: props.basis,
  });

export const FlexBlock = createComponent(StyledFlexBlock);
