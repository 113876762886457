/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import React from 'react';

import { Omit } from 'utils/types';

import { Icon } from 'components/icon';
import { Actiontip } from 'components/ds/tooltip';

import { BaseInput, BaseInputProps, BaseDefaultProps, DefaultProps } from './base';

type InputProps = Omit<BaseInputProps, 'textArea'>;
export class Input extends React.Component<InputProps> {
  static defaultProps: BaseDefaultProps = DefaultProps;

  render() {
    const { children, ...props } = this.props;

    return (
      <BaseInput textArea={false} {...props}>
        {children}
      </BaseInput>
    );
  }
}

type SearchInputProps = Omit<BaseInputProps, 'icon' | 'iconOnRight' | 'textArea'>;
export class SearchInput extends React.Component<SearchInputProps> {
  static defaultProps: BaseDefaultProps & Pick<BaseInputProps, 'iconRight'> = {
    ...DefaultProps,
    iconLeft: <Icon type={'ds-search'} set={'icomoon'} size={16} dsColor={'blueGrey600'} />,
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <BaseInput textArea={false} leftIconSpacing {...props}>
        {children}
      </BaseInput>
    );
  }
}

type State = {
  actionTipVisibility: boolean;
};

type CredentialsInputProps = Omit<BaseInputProps, 'icon' | 'iconOnRight' | 'textArea'>;
export class CredentialsInput extends React.Component<CredentialsInputProps, State> {
  static defaultProps: BaseDefaultProps & Pick<BaseInputProps, 'onCopyButtonClick'> = {
    ...DefaultProps,
    borderColor: 'blueRG1050',
  };

  constructor(props: CredentialsInputProps) {
    super(props);

    this.setActionTipVisibility = this.setActionTipVisibility.bind(this);

    this.state = {
      actionTipVisibility: false,
    };
  }

  setActionTipVisibility() {
    this.setState({ actionTipVisibility: true });
    setTimeout(() => {
      this.setState({ actionTipVisibility: false });
    }, 1500);
  }

  getMaskedValue(credentials: string) {
    let result = credentials.substring(0, 2);
    for (let i = 2; i < credentials.length - 2; i++) {
      result = result.concat('•');
    }
    result = result.concat(credentials.substring(credentials.length - 2, credentials.length));
    return result;
  }

  render() {
    const { children, ...props } = this.props;
    const maskedValue = this.getMaskedValue(this.props.value);

    return (
      <Actiontip
        label={'Copied to clipboard'}
        hideArrow={false}
        fullWidthTrigger
        visible={this.state.actionTipVisibility}
        alignRight
      >
        <BaseInput
          textArea={false}
          {...props}
          readOnly
          onCopyButtonClick={this.setActionTipVisibility}
          value={maskedValue}
          credentialsValue={this.props.value}
        >
          {children}
        </BaseInput>
      </Actiontip>
    );
  }
}

type TextAreaProps = Omit<BaseInputProps, 'textArea' | 'icon' | 'iconOnRight' | 'clearable'>;
export class TextArea extends React.Component<TextAreaProps> {
  static defaultProps: BaseDefaultProps = {
    ...DefaultProps,
    required: false,
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <BaseInput textArea {...props}>
        {children}
      </BaseInput>
    );
  }
}
