/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from 'interfaces/appState';

import * as Models from './models';
import * as Selectors from './selectors';
import * as Actions from './actions';
import * as Sagas from './sagas';
import { Notification } from './components/notification';

interface DispatchProps {
  dismiss: (id: string) => void;
}

interface StateProps {
  notifications: Models.Notification[];
}

interface Props extends StateProps, DispatchProps {}

interface State {}

class Notifications extends React.Component<Props, State> {
  render() {
    const bottomRightNotifications = this.props.notifications.filter(
      n => n.position === Models.Position.RIGHT_BOTTOM,
    );
    const centreTopNotifications = this.props.notifications.filter(
      n => n.position === Models.Position.CENTRE_TOP,
    );

    return (
      <div>
        <div className="notifications-centre-top">
          {centreTopNotifications.map(n => (
            <Notification key={n.id} dismiss={this.props.dismiss} {...n} />
          ))}
        </div>
        <div className="notifications-bottom-right">
          {bottomRightNotifications.map(n => (
            <Notification key={n.id} dismiss={this.props.dismiss} {...n} />
          ))}
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps, {}>(
  (state: AppState) => ({
    notifications: Selectors.notifcationsByCreated(state),
  }),
  (dispatch: Dispatch) => ({
    dismiss: id => dispatch(Actions.remove(id)),
  }),
)(Notifications);

export { Models, Selectors, Actions, Sagas };
