/** @format **/

import React from 'react';

import { Fade } from 'components/animated/fade';

import { ActiontipArrow, ActiontipContainer, ActiontipLabel, ActiontipWrapper } from './components';
import { IconBefore } from '../../../icon/iconBefore';
import { Icon } from 'components/icon';

export type ActiontipProps = {
  label: React.ReactNode;
  visible: boolean;
  hideArrow?: boolean;
  fullWidthTrigger?: boolean;
  alignRight?: boolean;
};
export const Actiontip: React.FunctionComponent<ActiontipProps> = ({
  children,
  label,
  visible,
  alignRight,
  hideArrow,
  fullWidthTrigger,
}) => {
  return (
    <ActiontipWrapper fullWidthTrigger={fullWidthTrigger}>
      {children}
      <Fade visible={visible} duration={visible ? 400 : 200}>
        <ActiontipContainer visible={visible} alignRight={alignRight}>
          {!hideArrow && <ActiontipArrow />}
          <ActiontipLabel>
            <IconBefore icon={<Icon type="tick" set="icomoon" color="white" />}>{label}</IconBefore>
          </ActiontipLabel>
        </ActiontipContainer>
      </Fade>
    </ActiontipWrapper>
  );
};
