/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { COLORS } from 'app/theme/materialDS';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

import { BaseDefaultProps } from './';

type PublicBaseTextProps = {
  underline?: boolean;
  forDropdownTrigger?: boolean;

  // colors
  textColor: COLORS;
  disabledTextColor: COLORS;
  fontWeight: IStyle['fontWeight'];

  backgroundColor: COLORS;
  disabledBackgroundColor: COLORS;

  hoverBackgroundColor: COLORS;
  pressedBackgroundColor: COLORS;
} & BaseDefaultProps &
  React.HTMLAttributes<any>;

const StyledBaseText = (props: PublicBaseTextProps & ThemeType): IStyle => {
  return applyModifiers(
    [
      props.roundLeftCorners,
      {
        borderTopLeftRadius: '2px',
        borderBottomLeftRadius: '2px',
        paddingLeft: '8px',
      },
    ],
    [
      props.roundRightCorners,
      {
        borderTopRightRadius: '2px',
        borderBottomRightRadius: '2px',
        paddingRight: '8px',
      },
    ],
    [
      props.disabled,
      {
        color: props.theme.ds.getColorByName(props.disabledTextColor),
        backgroundColor: props.theme.ds.getColorByName(props.disabledBackgroundColor),
        transition: 'none',
        cursor: 'not-allowed',
      },
    ],
    [
      props.underline || props.forDropdownTrigger,
      {
        textDecoration: 'underline',
      },
    ],
  )({
    whiteSpace: 'nowrap',
    backgroundColor: props.theme.ds.getColorByName(props.backgroundColor),
    transition: 'background-color 100ms ease-out',
    display: 'block',
    color: props.theme.ds.getColorByName(props.textColor),
    fontWeight: props.fontWeight,
    fontSize: '14px',
    fontFamily: props.theme.ds.fonts.standard,
    minHeight: '24px',
    padding: '2px 4px',
    lineHeight: '20px',
    maxWidth: '280px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':hover': {
      backgroundColor: props.theme.ds.getColorByName(props.hoverBackgroundColor),
    },
    ':active': {
      backgroundColor: props.theme.ds.getColorByName(props.pressedBackgroundColor),
    },
    ':focus': {
      backgroundColor: props.theme.ds.getColorByName(props.hoverBackgroundColor),
      outline: 0,
    },
    ['-webkit-tap-highlight-color' as any]: props.theme.ds.colors.special.transparent,
  });
};

const BaseText = createComponentWithProxy<PublicBaseTextProps>(StyledBaseText, 'span');

export { BaseText, PublicBaseTextProps as BaseTextProps };
