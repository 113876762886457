/** @format */

import * as React from 'react';
import { assign } from 'lodash';

import { Formatting } from 'utils/date';
import { CellBase } from '../tableModel';

export class TimeAgoCell extends CellBase {
  constructor(init?: Partial<TimeAgoCell>) {
    super(init);
    assign(this, init);
  }

  render(rowItem: any) {
    const value = this.getValue(rowItem) as Date;

    return (
      <span className="text-ellipsis line-height-24">{Formatting.formatAsTimeAgo(value)}</span>
    );
  }
}

export default TimeAgoCell;
