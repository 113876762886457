/**
 * @prettier
 */

import * as React from 'react';

import { WhatsNewCategory } from '../models';

import { Module, ModuleContent } from 'components/module';
import { Flex } from 'components/flex';

import { COLOR_TYPES, getTextColorClass, HEADING_CLASSES } from 'components/text';
import { Icon } from 'components/icon';
import { Padding } from 'components/layout';
import { Avatar } from 'components/avatar';
import { Text } from 'components/text';
import { Button } from 'components/button';
import { Width, MaxWidth } from 'components/width';

import { Tags } from './tags';
import { ItemDivider } from './itemDivider';
import { AnnouncementGraphic } from './announcementGraphic';
import { Components } from 'modules/time';
import Tooltip from 'components/tooltip';
import { Markdown } from 'components/markdown';

type Props = {
  cardType: WhatsNewCategory;
  issueDate: Date;
  heading: string;
  author: string;
  authorRole: string;
  authorAvatarImg: string;
  authorAvatarImgAlt: string;
  category: string;
  contentImageSrc: string;
  contentImageAlt: string;
  contentPassage: string;
  contentLink: string;
  tags: string[];
  pinned: boolean;
};

const categoryToMetadata: {
  [catgory: string]: {
    color: COLOR_TYPES;
    icon: string;
  };
} = {
  [WhatsNewCategory.CrashReporting]: {
    color: 'blue',
    icon: 'cr',
  },
  [WhatsNewCategory.RealUserMonitoring]: {
    color: 'blue',
    icon: 'rum',
  },
  [WhatsNewCategory.Platform]: {
    color: 'blue',
    icon: 'platform',
  },
  [WhatsNewCategory.Integrations]: {
    color: 'green',
    icon: 'integration',
  },
  [WhatsNewCategory.Providers]: {
    color: 'yellow',
    icon: 'provider',
  },
  [WhatsNewCategory.APM]: {
    color: 'blue',
    icon: 'apm',
  },
};

export const Announcement: React.FunctionComponent<Props> = props => {
  const { color, icon } = categoryToMetadata[props.category];

  return (
    <Module borderTopColor={color as any}>
      <ModuleContent padding="none">
        <Padding top="40" bottom="40" left="54" right="54">
          <Flex direction="row" justify="space-between">
            <Flex direction="row">
              <Padding right="6">
                <Icon set="icomoon" size={16} type={icon} color={color} />
              </Padding>

              <h3 className={`${HEADING_CLASSES.FIVE} ${getTextColorClass(color)}`}>
                {props.cardType.valueOf()}
              </h3>
            </Flex>

            <Flex direction="row" align="center">
              <p className={`${HEADING_CLASSES.FIVE} ${getTextColorClass('grey')}`}>
                <Components.DateFormatter format="custom" dateFormat="DD MMM, YYYY">
                  {props.issueDate}
                </Components.DateFormatter>
              </p>
              {props.pinned && (
                <Padding left="10">
                  <Tooltip label="Pinned announcement" dock="top">
                    <Icon set="icomoon" size={20} type="pin" color="black" inline />
                  </Tooltip>
                </Padding>
              )}
            </Flex>
          </Flex>

          <Padding top="16" bottom="24">
            <h1 className={`${HEADING_CLASSES.ONE} ${getTextColorClass('dark')}`}>
              <a href={props.contentLink}>{props.heading}</a>
            </h1>
          </Padding>

          <Flex direction="row">
            <ItemDivider paddingRight={54}>
              <Flex direction="row">
                <Avatar
                  url={props.authorAvatarImg}
                  alt={`${props.authorAvatarImgAlt} avatar`}
                  circle
                  size={28}
                />

                <Padding left="10">
                  <Text size="small" color="dark" weight="semi-bold">
                    {props.author}
                  </Text>

                  <Text size="small" color="light">
                    {props.authorRole}
                  </Text>
                </Padding>
              </Flex>
            </ItemDivider>

            <ItemDivider paddingLeft={26} paddingTop={5} paddingBottom={5}>
              <Tags tags={props.tags} />
            </ItemDivider>
          </Flex>

          <Padding top="34" bottom="34">
            <a href={props.contentLink}>
              <AnnouncementGraphic src={props.contentImageSrc} alt={props.contentImageAlt} />
            </a>

            <Markdown
              containerClassName={`${getTextColorClass('dark')} line-height-24`}
              source={props.contentPassage}
            />
          </Padding>

          <Width width={296} center>
            <Button size={48} block type="blue" url={props.contentLink}>
              Learn more
            </Button>
          </Width>
        </Padding>
      </ModuleContent>
    </Module>
  );
};
