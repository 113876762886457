/** @format **/

import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Modal, ModalFooter, ModalHeader, ModalProps } from 'components/modal';
import { Flex } from 'components/flex';
import { Margin } from 'components/layout';
import { TextButton, EmphasisButton } from 'components/ds/button';

import { getDefinitionForTile } from '../constants';
import { TileDefinition } from '../models';
import * as Selectors from '../selectors';
import * as Actions from '../actions';
import { DashboardModuleStateAtom } from '../state';

import { TileSettingsEditor } from './settingsEditor';

type StateProps = {
  tileId: string;
  tileValid: boolean;
  tile: TileDefinition;
  settingsAllowed: boolean;
};
type DispatchPropsWithoutState = {
  createTile: (tileId: string) => void;
  createTileWithDefaults: (tileId: string) => void;
};
type DispatchProps = {
  createTile: () => void;
  createTileWithDefaults: () => void;
};
type SuppliedProps = ModalProps;
type Props = StateProps & DispatchProps & SuppliedProps;

const AddTileModal: React.FunctionComponent<Props> = ({
  tile,
  createTile,
  createTileWithDefaults,
  tileValid,
  settingsAllowed,
  ...modalProps
}) => {
  if (!settingsAllowed) {
    createTileWithDefaults();
    modalProps.close(modalProps.id);

    return null;
  }

  const addOnClick = () => {
    createTile();
    modalProps.close(modalProps.id);
  };

  const header = (
    <ModalHeader>
      <h4>Add a tile</h4>
    </ModalHeader>
  );
  const footer = (
    <ModalFooter>
      <Flex justify="flex-end">
        <TextButton onClick={() => modalProps.close(modalProps.id)}>Cancel</TextButton>

        <Margin left="16">
          <EmphasisButton disabled={!tileValid} onClick={addOnClick}>
            Add tile
          </EmphasisButton>
        </Margin>
      </Flex>
    </ModalFooter>
  );

  return (
    <Modal header={header} footer={footer} closeOnBackdropClick={false} {...modalProps}>
      <TileSettingsEditor tile={tile} />
    </Modal>
  );
};

const ConnectedAddTileModal = connect<StateProps, DispatchPropsWithoutState, SuppliedProps>(
  (state: DashboardModuleStateAtom) => {
    const tileId = Selectors.tileSetting.getTileId(state);
    return {
      tileId,
      tileValid: Selectors.tileSetting.isValid(state),
      tile: getDefinitionForTile(tileId),
      settingsAllowed: Selectors.settingsAllowed(state),
    };
  },
  (dispatch: Dispatch) => ({
    createTile: (tileId: string) =>
      dispatch(Actions.tileSetting.createTile(getDefinitionForTile(tileId))),
    createTileWithDefaults: (tileId: string) =>
      dispatch(Actions.tileSetting.createTileWithDefaults(getDefinitionForTile(tileId))),
  }),
  (state: StateProps, dispatch: DispatchPropsWithoutState, passed: SuppliedProps) => ({
    ...state,
    ...dispatch,
    ...passed,
    createTile: () => dispatch.createTile(state.tileId),
    createTileWithDefaults: () => dispatch.createTileWithDefaults(state.tileId),
  }),
)(AddTileModal);

export { ConnectedAddTileModal as AddTileModal };
