/** @format */

import * as React from 'react';
import cx from 'classnames';

import { AddTileButton } from 'modules/dashboard/components/addTile';

import { Center } from 'components/center';
import { Padding } from 'components/layout';
import { Flex, FlexBlock } from 'components/flex';

import { Delete, DiscardChanges, SaveChanges, TileControls } from './components';

type Props = {
  isEditing: boolean;
  hasUpdates: boolean;
  canDelete: boolean;
  canReset: boolean;
};

export const DashboardControls: React.FunctionComponent<Props> = (props: Props) => {
  const dashClasses = cx('dashboard-editbar', {
    'dashboard-editbar--animate-in': props.isEditing,
  });

  const navClasses = cx('subnavigation subnavigation--index-3', {
    'subnavigation--animate-in': props.isEditing,
  });

  return (
    <div className={dashClasses}>
      <div className={navClasses}>
        <Center width="full">
          <Flex justify="space-between" align="center">
            <FlexBlock>
              <Delete {...props} />
              <TileControls {...props} />
            </FlexBlock>

            <FlexBlock>
              <Flex direction="row" justify="space-between">
                <Padding right="16">
                  <AddTileButton type="free-text" />
                </Padding>
                <Padding right="16">
                  <AddTileButton type="list" />
                </Padding>
                <Padding right="16">
                  <AddTileButton type="value" />
                </Padding>
                <AddTileButton type="chart" />
              </Flex>
            </FlexBlock>

            <FlexBlock>
              <SaveChanges {...props} />
              <DiscardChanges {...props} />
            </FlexBlock>
          </Flex>
        </Center>
      </div>
    </div>
  );
};
