/** @format */

import * as React from 'react';
import { createComponent } from 'react-fela';
import { isNil } from 'lodash';

import { screenLargeAndAbove, screenMediumAndAbove } from "fela-rules/breakpoints";
import { applyModifiers } from 'fela-rules/modifiers';

interface Props {
  width: string | number;
  largeAndAboveWidth?: number;
  units?: string;
  inline?: boolean;
  center?: boolean;
}

const getUnits = (units: string) => {
  return units ? units : 'px';
};

const StyledWidth = (props: Props) =>
  applyModifiers(
    [
      !isNil(props.largeAndAboveWidth),
      {
        ...screenLargeAndAbove({
          width: `${props.largeAndAboveWidth}${getUnits(props.units)}`,
        }),
      },
    ],
    [props.center, { margin: '0 auto' }],
    [
      props.inline,
      {
        display: 'inline-block',
        verticalAlign: 'top',
      },
    ],
  )({
    display: 'block',
    width: `${props.width}${getUnits(props.units)}`,
    maxWidth: `${props.width}${getUnits(props.units)}`,
  });

export const Width = createComponent<Props>(StyledWidth);

type MaxWidthProps = {
  width?: string | number;
  mediumAndAboveWidth?: string | number;
  largeAndAboveWidth?: string | number;
  units?: string;
  center?: boolean;
  inline?: boolean;
};

const StyledMaxWidth = (props: MaxWidthProps) =>
  applyModifiers(
    [
      props.center,
      {
        margin: '0 auto',
      },
    ],
    [
      !isNil(props.width),
      {
        width: '100%',
        maxWidth: `${props.width}${getUnits(props.units)}`,
      },
    ],
    [
      !isNil(props.mediumAndAboveWidth),
      {
        ...screenMediumAndAbove({
          width: '100%',
          maxWidth: `${props.mediumAndAboveWidth}${getUnits(props.units)}`,
        }),
      },
    ],
    [
      !isNil(props.largeAndAboveWidth),
      {
        ...screenLargeAndAbove({
          width: '100%',
          maxWidth: `${props.largeAndAboveWidth}${getUnits(props.units)}`,
        }),
      },
    ],
  )({
    display: props.inline ? 'inline-block' : 'block',
  });

export const MaxWidth = createComponent<MaxWidthProps>(StyledMaxWidth, 'div');

MaxWidth.defaultProps = {
  units: 'px',
  center: false,
};