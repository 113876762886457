/**
 * @format
 */
/* tslint:disable:max-file-line-count */

import { TileDefinition } from 'modules/dashboard/models';

import {
  LiveSessionDistributionMap,
  LoadTimeDistributionMap,
  SessionCountChart,
  SessionDistributionMap,
  SingleValueTile,
  UserCountChart,
} from '../components/tiles';
import {
  DEFAULT_DATE_RANGE,
  DEFAULT_GRANULARITY,
  DEFAULT_RESOURCE_TYPE,
  featureFlagged,
  fetchAction,
  planLevelSBAndAbove,
  pulseRequired,
} from './shared';
import { AverageLoadTimeChart } from '../components/tiles/RUM/averageLoadTimeChart';

export const RumTileMap: TileDefinition[] = [
  {
    id: 'totalUserCount',
    name: 'Total users',
    category: 'value',
    fetchAction,
    apiEndpoint: 'totalusercount',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-users',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'newUserCount',
    name: 'New users',
    category: 'value',
    fetchAction,
    apiEndpoint: 'newusercount',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-users',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'userSatisfaction',
    name: 'User satisfaction',
    category: 'value',
    fetchAction,
    apiEndpoint: 'usersatisfaction',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'raw',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-health',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'sessionCount',
    name: 'Sessions',
    category: 'value',
    fetchAction,
    apiEndpoint: 'sessioncount',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-sessions',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'medianSessionLength',
    name: 'Median session length',
    category: 'value',
    fetchAction,
    apiEndpoint: 'mediansessionlength',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'duration',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-sessions',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'averageLoadTime',
    name: 'Average load time',
    category: 'value',
    fetchAction,
    apiEndpoint: 'averageloadtime',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'duration',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-loadtime',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'xhrAverageLoadingTime',
    name: 'Average load time distribution',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'xhraverageloadtime',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 12,
      DefaultHeight: 6,
      SupportsSettings: true,
      SingleApplicationOnly: true,
    },
    defaultSettings: {
      ResourceType: DEFAULT_RESOURCE_TYPE,
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: AverageLoadTimeChart,
    icon: 'dashboard--tiles-loadtime',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'healthScore',
    name: 'Health score',
    category: 'value',
    fetchAction,
    apiEndpoint: 'healthscore',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'raw',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-health',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'returningUserCount',
    name: 'Returning users',
    category: 'value',
    fetchAction,
    apiEndpoint: 'returningusercount',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-users',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'userCountOverTime',
    name: 'User count',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'usercountovertime',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 4,
      SupportsSettings: true,
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
      Granularity: DEFAULT_GRANULARITY,
    },
    component: UserCountChart,
    icon: 'dashboard--tiles-users',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'sessionCountOverTime',
    name: 'Session count',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'sessioncountovertime',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 4,
      SupportsSettings: true,
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
      Granularity: DEFAULT_GRANULARITY,
    },
    component: SessionCountChart,
    icon: 'dashboard--tiles-sessions',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'liveHealthScore',
    name: 'Live health score',
    category: 'value',
    fetchAction,
    apiEndpoint: 'livehealthscore',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      Live: true,
      DisplayMode: 'raw',
    },
    defaultSettings: {
      Applications: '',
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-health',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'liveSessionCount',
    name: 'Live sessions',
    category: 'value',
    fetchAction,
    apiEndpoint: 'livesessioncount',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      Live: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-sessions',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'liveUserCount',
    name: 'Live users',
    category: 'value',
    fetchAction,
    apiEndpoint: 'liveusercount',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      Live: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-users',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'liveLoadTime',
    name: 'Live load time',
    category: 'value',
    fetchAction,
    apiEndpoint: 'liveloadtime',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      Live: true,
      DisplayMode: 'duration',
    },
    defaultSettings: {
      Applications: '',
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-loadtime',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'geoLoadTimeDistribution',
    name: 'Load time distribution',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'geoloadtimedistribution',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 6,
      SupportsSettings: true,
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: LoadTimeDistributionMap,
    icon: 'dashboard--tiles-loadtime',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'geoSessionDistribution',
    name: 'Session distribution',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'geosessiondistribution',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 6,
      SupportsSettings: true,
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SessionDistributionMap,
    icon: 'dashboard--tiles-sessions',
    products: ['RealUserMonitoring'],
  },
  {
    id: 'liveGeoSessionDistribution',
    name: 'Live active sessions',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'livegeosessiondistribution',
    requirements: [pulseRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 6,
      SupportsSettings: true,
      Live: true,
    },
    defaultSettings: {
      Applications: '',
    },
    component: LiveSessionDistributionMap,
    icon: 'dashboard--tiles-sessions',
    products: ['RealUserMonitoring'],
  },
];
