/** @format **/
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { Theme } from 'app/theme';

type PillProps = {
  backgroundColor: string;
};

const StyledPill = (props: PillProps): IStyle => ({
  backgroundColor: props.backgroundColor,
  padding: '0 0.35rem',
  borderRadius: '0.2rem',
  color: Theme.colors.standard.white,
  fontSize: '1.3rem',
  fontWeight: 600,
  lineHeight: '1.6rem',
  marginRight: '1.2rem',
});

export const Pill = createComponent(StyledPill, 'span');
