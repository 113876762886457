/**
 * @prettier
 */

import * as React from 'react';
import {
  breakpointSmallOnly,
  breakpointSmallAndAbove,
  breakpointMediumOnly,
  breakpointMediumAndAbove,
  breakpointMediumAndBelow,
  breakpointLargeOnly,
  breakpointLargeAndAbove,
  breakpointLargeAndBelow,
} from 'fela-rules/breakpoints';

type MediaQueryCall = () => MediaQueryList;

type Props = {
  andAbove?: boolean;
  andBelow?: boolean;
};

type State = {
  mediaMatches: boolean;
};

function mediaQueryFactory(
  baseQuery: MediaQueryCall,
  andAbove: MediaQueryCall,
  andBelow?: MediaQueryCall,
) {
  return class MediaQuery extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.checkBreakpoint = this.checkBreakpoint.bind(this);

      this.state = {
        mediaMatches: false,
      };
    }

    currentQuery: MediaQueryList;

    static defaultProps: Props = {
      andAbove: false,
      andBelow: false,
    };

    bindMediaQuery() {
      this.currentQuery = (this.props.andAbove
        ? andAbove
        : this.props.andBelow
        ? andBelow
        : baseQuery)();

      if (typeof this.currentQuery.addListener !== 'undefined') {
        this.currentQuery.addListener(this.checkBreakpoint);
      }

      this.setState({ mediaMatches: this.currentQuery.matches });
    }

    unbindMediaQuery() {
      if (this.currentQuery) {
        this.currentQuery.removeListener(this.checkBreakpoint);
      }
    }

    componentDidMount() {
      this.bindMediaQuery();
    }

    componentWillUnmount() {
      this.unbindMediaQuery();
    }

    componentDidUpdate(prevProps: Props) {
      if (this.props.andAbove != prevProps.andAbove) {
        this.unbindMediaQuery();
        this.bindMediaQuery();
      }
    }

    checkBreakpoint(e: MediaQueryListEvent) {
      this.setState({ mediaMatches: e.matches });
    }

    render() {
      return this.state.mediaMatches ? <>{this.props.children}</> : null;
    }
  };
}

export const Small = mediaQueryFactory(
  breakpointSmallOnly,
  breakpointSmallAndAbove,
  breakpointSmallOnly,
);
export const Medium = mediaQueryFactory(
  breakpointMediumOnly,
  breakpointMediumAndAbove,
  breakpointMediumAndBelow,
);
export const Large = mediaQueryFactory(
  breakpointLargeOnly,
  breakpointLargeAndAbove,
  breakpointLargeAndBelow,
);
