/**
 * @prettier
 */

import * as React from 'react';
import * as Showdown from 'showdown';

import { Typography } from 'components/text';
import { ErrorBoundary } from 'components/errorBoundary';

import { Emojis } from './emoji';
import { Mention } from './mentions';
import { sanitizeHtml } from './sanitize';

type Props = {
  source: string;
  childrenAfter?: boolean;
  containerClassName?: string;
  textBreak?: boolean;
};

type State = {};

const converter = new Showdown.Converter({
  simplifiedAutoLink: true,
  literalMidWordUnderscores: true,
  strikethrough: true,
  simpleLineBreaks: true,
  extensions: [Mention, Emojis],
});

export class Markdown extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    childrenAfter: false,
  };

  get markdown() {
    const html = converter.makeHtml(this.props.source);
    return { __html: sanitizeHtml(html) };
  }

  render() {
    return (
      <ErrorBoundary>
        <Typography break={this.props.textBreak}>
          {!this.props.childrenAfter && this.props.children}
          <div className={this.props.containerClassName} dangerouslySetInnerHTML={this.markdown} />
          {this.props.childrenAfter && this.props.children}
        </Typography>
      </ErrorBoundary>
    );
  }
}
