/** @format **/

import React from 'react';

import { CONTACT_US_URL, SUPPORT_CLASS } from "utils/constants";

import { Helptip } from "components/ds/tooltip";
import { SnowplowLink } from "components/snowplow";
import { useHubSpotLoaded } from "hooks/useHubSpotLoaded";

export const ContactRaygunMenuItem: React.FunctionComponent = () => {
  const isHubSpotLoaded = useHubSpotLoaded();

  if (!isHubSpotLoaded) {
    return (
      <Helptip
        label={'Due to your browser settings or having an ad blocker enabled, our in-app support widget is unavailable.'}
        link={CONTACT_US_URL}
        linkText={'Contact us here'}
        openLinkInNewTab
        block
        itemBlock
        showOnRight
        alignRight
      >
        <li>
          <SnowplowLink interactionId={'sp-app-chrome-sidebar-clickContactLink'}>
            <a className={`sidebar-button`} href={CONTACT_US_URL} target={'_blank'}>
              <span className="sidebar-button-content">
                <svg className="sidebar-icon">
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/Content/Images/sidebar/sidebar-icons.svg#sidebar-icon--blue-contact`}
                  />
                </svg>
                <span className="sidebar-label">Contact Raygun</span>
              </span>
            </a>
          </SnowplowLink>
        </li>
      </Helptip>
    );
  }

  return (
    <li>
      <a className={`sidebar-button ${SUPPORT_CLASS}`}>
        <span className="sidebar-button-content">
          <svg className="sidebar-icon">
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref={`/Content/Images/sidebar/sidebar-icons.svg#sidebar-icon--blue-contact`}
            />
          </svg>
          <span className="sidebar-label">Contact Raygun</span>
        </span>
      </a>
    </li>
  );
};

export default ContactRaygunMenuItem;