/** @format */

import React, { useState } from 'react';
import { UtilityIconButton } from 'components/ds/button';
import { Padding } from 'components/layout';
import { Flex } from 'components/flex';
import { Callout } from 'components/callout';
import { HasMultiplePlans } from '../multiplePlans';
import { FormSubmit, Link } from './menuItems/components';
import { MenuContainer, MenuSection, DesktopMenuContainer } from './menu';
import { Plans } from './plans';
import { AccountMenu } from './accountMenu';

export const MenuPopout: React.FunctionComponent = () => {
  const [showingPlans, setPlanVisiblityState] = useState(false);

  return (
    <DesktopMenuContainer>
      <MenuContainer>
        <MenuSection hidden={showingPlans}>
          <Callout title="Enjoying the new Raygun UI?">
            <Flex justify="space-between" minWidth="100%">
              <Link href="https://raygun.com/about/contact">Give feedback</Link>
              <form action="/toggle-chrome" method="post">
                <FormSubmit type="submit" value="Switch to legacy" />
              </form>
            </Flex>
          </Callout>
        </MenuSection>
        <MenuSection hidden={showingPlans}>
          <AccountMenu openPlanSettings={() => setPlanVisiblityState(true)} />
        </MenuSection>
        <HasMultiplePlans>
          <MenuSection isSubMenu hidden={!showingPlans}>
            <Padding left="8">
              <UtilityIconButton icon="arrow-back" onClick={() => setPlanVisiblityState(false)} />
            </Padding>
            <Plans />
          </MenuSection>
        </HasMultiplePlans>
      </MenuContainer>
    </DesktopMenuContainer>
  );
};

export { Plans, AccountMenu };
