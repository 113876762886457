/** @format */
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';
import { textEllipsis } from 'fela-rules/text';

const StyledName = (props: ThemeType):IStyle => textEllipsis({
  color: props.theme.ds.getColorByName('grey800'),
  fontSize: '1.4rem', 
  fontWeight: 600, 
});

export const Name = createComponentWithProxy(StyledName, 'h2');

const StyledContainer = ():IStyle => ({
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: '40px',
  cursor: 'pointer',
  userSelect: 'none'
});

export const Container = createComponentWithProxy(StyledContainer);