/** @format */

import { takeLatest, takeEvery, put, call, select, take } from 'redux-saga/effects';
import { SagaIterator, eventChannel, delay } from 'redux-saga';
import { last, find, isString } from 'lodash';
import { Action } from 'typescript-fsa';

import { createFetchSaga, createSavingSaga } from 'utils/sagas';
import { deleteOnApp, postToApp, fetchFromApp, fetchFromAPI } from 'utils/fetching';
import { onChange } from 'utils/fullscreen';
import { toId } from 'utils/linking';

import { getPrevLocation } from 'selectors/router';
import { planIdentifier, apiKey } from 'app/selectors/application';
import { getDashboardId, getDashboardIdentifier } from 'app/selectors/configuration';
import * as PageDataActions from 'app/actions/pageData';

import { GoToDashboard, GoToPrimaryDashboard } from 'apm/modules/routingActions';

import { DashboardModuleStateAtom } from '../state';
import * as Models from '../models';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Constants from '../constants';

export function fetchDashboardLayoutData(
  navigationActionType: string,
  getCurrentLayout: (state: DashboardModuleStateAtom) => Models.Tile[],
) {
  return function*() {
    // Check how old the layout is and if we need to refetch or not
    const prevType: string = yield select(getPrevLocation);

    if (prevType !== navigationActionType) {
      yield put(Actions.fetchLayout.base({}));
      const action: { type: string } = yield take([
        Actions.fetchLayout.done.type,
        Actions.fetchLayout.error.type,
      ]);
      if (action.type === Actions.fetchLayout.error.type) {
        return;
      }
    }

    // Execute timeboard actions
    const state: DashboardModuleStateAtom = yield select();
    if (Selectors.isTimeboard(state as any)) {
      const { TimeRange, Applications } = Selectors.getCurrent(state as any);
      // Set global applicationIds and timerange
      yield put(Actions.silentUpdateGlobalDateRange(TimeRange));
      yield put(Actions.silentUpdateGlobalApplications(Applications.map(a => toId(a))));
    }

    // Get tile data & do fetches
    const tiles = getCurrentLayout(state);

    for (const tile of tiles) {
      yield executeActionsForTile(tile.Type, tile.InstanceId);
    }
  };
}

export function fullscreen() {
  return eventChannel<boolean>(emitter => {
    const removeEvent = onChange((isActive: boolean) => {
      emitter(isActive);
    });

    return () => {
      removeEvent();
    };
  });
}

function fetchRouteData() {
  return fetchDashboardLayoutData(GoToDashboard.type, Selectors.getCurrentLayout);
}

function fetchConfigLayout() {
  return createFetchSaga(
    Actions.fetchLayout,
    (_p, state: DashboardModuleStateAtom) => Selectors.getFetchLayoutRoute(state),
    undefined,
    fetchFromApp,
    { includeBaseName: false },
  );
}

const postConfigLayout = createFetchSaga(
  Actions.saveLayout,
  (_p, state) => `tiledashboard/${Selectors.dashboardIdentifier(state)}`,
  (state: DashboardModuleStateAtom) => Selectors.getCurrent(state),
  postToApp,
  { includeBaseName: false },
);

function* saveConfigLayout(action: any): SagaIterator {
  const state: DashboardModuleStateAtom = yield select();

  if (Selectors.hasPendingUpdates(state)) {
    yield call(postConfigLayout, action);
  } else {
    yield put(Actions.revertLayout());
  }
}

function* resetConfigLayout(action: any): SagaIterator {
  const state = yield select();
  let data: { ApplicationIds: string[] };

  try {
    data = yield call(
      fetchFromAPI,
      `tiledashboard/${planIdentifier(state)}/topapplications`,
      apiKey(state),
    );
  } catch (e) {
    console.error(e);
    yield put(Actions.resetLayout.error(e));
    return;
  }

  const resetLayoutFetchSaga = createFetchSaga<DashboardModuleStateAtom>(
    Actions.resetLayout,
    (_p: any, state: DashboardModuleStateAtom) =>
      `tiledashboard/${Selectors.dashboardIdentifier(state)}/reset`,
    () => ({
      ApplicationIds: data.ApplicationIds,
    }),
    fetchFromApp,
    { includeBaseName: false },
  );

  yield call(resetLayoutFetchSaga, action);
}

function deleteDashboard() {
  return createFetchSaga<DashboardModuleStateAtom>(
    Actions.deleteDashboard,
    (_p, state) => `tiledashboard/${Selectors.dashboardIdentifier(state)}/delete`,
    undefined,
    deleteOnApp,
    { includeBaseName: false },
  );
}

export function* tileAdded({ payload }: Action<Actions.AddTilePayload>) {
  const state: DashboardModuleStateAtom = yield select();
  // -1 because the tile has already been added to the array at this point
  const tileId = last(Selectors.getCurrent(state).Tiles).InstanceId;

  yield executeActionsForTile(payload.Type, tileId);
}

function* tileUpdated({ payload }: Action<Actions.UpdateTilePayload>) {
  const state: DashboardModuleStateAtom = yield select();
  const { Type, InstanceId } = find(
    Selectors.getCurrent(state).Tiles,
    t => t.InstanceId === payload.instanceId,
  );

  yield executeActionsForTile(Type, InstanceId);
}

function* refetchTileData() {
  yield delay(200);
  const state: DashboardModuleStateAtom = yield select();
  const tiles = Selectors.getCurrent(state).Tiles;

  var i = tiles.length;
  while (i--) {
    const tile = tiles[i];
    yield executeActionsForTile(tile.Type, tile.InstanceId);
  }
}

function* redirectToPrimaryDashboard() {
  const state = yield select();
  const pId = planIdentifier(state);

  yield put(GoToPrimaryDashboard({ planIdentifier: pId }));

  window.location.reload();
}

function* updateDashboardIdentifierFromPayload(action: Action<{ dashboardIdentifier: string }>) {
  const { dashboardIdentifier } = action.payload;

  yield put(Actions.updateDashboardIdentifier(dashboardIdentifier));
}

function* updateDashboardIdentifierFromConfig() {
  const state: DashboardModuleStateAtom = yield select();
  const dashboardIdentifier = getDashboardIdentifier(state);

  yield put(Actions.updateDashboardIdentifier(dashboardIdentifier));
  yield put(Actions.setFetchLayoutRoute(`tiledashboard/${dashboardIdentifier}`));
}

function* onFullscreenUpdate(fullscreen: boolean) {
  yield put(Actions.toggleFullscreen(fullscreen));
}

function* executeActionsForTile(type: string, id: string) {
  const actions = Constants.tiles.getFetchActionsForTile(type)({ instanceId: id });

  for (const action of actions) {
    yield put(action);
  }
}

function* updateCurrentTimeboardDateRange() {
  const state = yield select();
  if (!Selectors.isTimeboard(state)) {
    return;
  }

  const time = Selectors.getGlobalDateRange(state);
  if (isString(time)) {
    yield put(Actions.setCurrentDateRange(time));
  }
}

function* updateCurrentTimeboardApplications() {
  const state = yield select();
  if (!Selectors.isTimeboard(state)) {
    return;
  }

  const applications = Selectors.getGlobalApplicationIds(state);
  yield put(Actions.setCurrentApplications(applications.map(a => a.toString(36))));
}

function* setDashboardTitle(action: Action<string>) {
  const state = yield select();
  const dashboardId = getDashboardId(state);
  const title = action.payload;

  yield put(Actions.updateTitle(title));
  yield put(PageDataActions.updateDashboardName({ title, dashboardId }));
}

export function* Saga(): SagaIterator {
  yield takeLatest([GoToDashboard.type, GoToPrimaryDashboard.type], fetchRouteData() as any);

  yield takeLatest(GoToDashboard.type, updateDashboardIdentifierFromPayload);
  yield takeLatest(GoToPrimaryDashboard.type, updateDashboardIdentifierFromConfig);

  yield takeLatest(Actions.fetchLayout.base.type, fetchConfigLayout() as any);
  yield takeLatest(Actions.saveLayout.base.type, saveConfigLayout);
  yield takeLatest(Actions.resetLayout.base.type, resetConfigLayout as any);

  yield takeEvery(Actions.addTile.type, tileAdded as any);
  yield takeEvery(Actions.updateTile.type, tileUpdated as any);
  yield takeLatest(
    [
      Actions.setCurrentApplications.type,
      Actions.setCurrentDateRange.type,
      Actions.revertLayout.type,
    ],
    refetchTileData as any,
  );

  yield takeLatest(Actions.deleteDashboard.base.type, deleteDashboard() as any);
  yield takeLatest(Actions.deleteDashboard.done.type, redirectToPrimaryDashboard as any);
  yield takeLatest(Actions.updateGlobalDateRange.type, updateCurrentTimeboardDateRange as any);
  yield takeLatest(Actions.toggleGlobalApplication.type, updateCurrentTimeboardApplications as any);
  yield takeLatest(Actions.setDashboardTitle.type, setDashboardTitle);

  yield takeEvery(fullscreen(), onFullscreenUpdate);

  yield createSavingSaga(Actions.saveLayout, { done: 'Saved successfully' });
  yield createSavingSaga(Actions.deleteDashboard, {
    saving: 'Deleting dashboard...',
    done: 'Dashboard deleted',
  });
}
