/**
 * @prettier
 */

import { Button } from './button';

export * from './button';
export * from './loadingButton';

export default Button;
