/** @format */

import React from 'react';

import { ChartTileLoading } from '../loading';

const ReactMap = React.lazy(() =>
  import(/* 
  webpackChunkName: "worldMapData" 
*/ 'utils/echarts/worldMap'),
);

// Cast as any because the ReactEChartsCore component doesn't have types
type Props = any;

export const WorldMap: React.FunctionComponent<Props> = props => {
  return (
    <React.Suspense fallback={<ChartTileLoading />}>
      <ReactMap {...props} />
    </React.Suspense>
  );
};
