/** @format **/

import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { ThemeType } from 'app/theme';

import { applyModifiers } from 'fela-rules/modifiers';

type SlideoutMenuProps = {
  open: boolean;
};

const StyledSlideoutMenu = (props: ThemeType & SlideoutMenuProps): IStyle =>
  applyModifiers([
    props.open,
    {
      transform: 'translateX(0)',
    },
  ])({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1000,
    overflow: 'auto',
    transform: 'translateX(-100%)',
    transition: 'transform 500ms ease-out',
    width: '100%',
    maxWidth: '312px',
    backgroundColor: props.theme.ds.colors.special.white,
  });

export const SlideoutMenu = createComponentWithProxy(StyledSlideoutMenu);

const StyledSlideoutBackground = (props: ThemeType): IStyle => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 999,
  backgroundColor: props.theme.ds.colors.special.black,
  opacity: 0.75,
});

export const SlideoutBackground = createComponentWithProxy(StyledSlideoutBackground);
