/**
 * @prettier
 */

import * as React from 'react';
import classnames from 'classnames';

import { getTextColorClass, COLOR_TYPES } from 'components/text';
import { SIZES, HEIGHTS } from './constants';
import { isEmpty, isNil } from 'lodash';
import { Flex } from 'components/flex';
import { Padding } from 'components/layout';
import { COLORS } from 'app/theme/materialDS';
import { Theme } from 'app/theme';

export * from './iconBefore';
export * from './iconAfter';
export * from './disc';
export * from './button';
export * from './integration';

export interface IconProps {
  set?: 'icomoon' | 'flat';
  size?: SIZES | null;
  height?: HEIGHTS;
  type?: string;
  color?: COLOR_TYPES;
  dsColor?: COLORS;
  extraClasses?: string;
  inButton?: 'after' | 'before' | null;
  center?: boolean;
  alignCenter?: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  inline?: boolean;
  after?: boolean;
  before?: boolean;
  styles?: object;
  title?: string;
}

export const Icon: React.FunctionComponent<IconProps> = props => {
  const classNames = classnames(
    {
      'raygun-icon': props.set === 'icomoon',
      'flat-icon': props.set === 'flat',
      [`icon--${props.type}`]: props.set === 'icomoon',
      [`${props.type}-icon`]: props.set === 'flat',
      [`icon-${props.size}`]: !isNil(props.size),
      [`icon--height-${props.height}`]: props.height !== undefined,
      'icon-after': props.inButton === 'after',
      'icon-before': props.inButton === 'before',
      'icon-center': props.center,
      clickable: !isNil(props.onClick),
    },
    getTextColorClass(props.color),
    `${props.extraClasses}`,
  );

  const styles: { [styleName: string]: any } = {};
  if (props.dsColor) {
    styles.color = Theme.ds.getColorByName(props.dsColor);
  }
  if (props.inline) {
    styles.display = 'inline-block';
  }

  const spanProps = {
    className: classNames,
    onClick: props.onClick,
    style: { ...styles, ...(props.styles || {}) },
    title: props.title,
  };

  if (isEmpty(styles)) {
    delete spanProps.style;
  }

  if (props.alignCenter) {
    spanProps.style = {
      ...spanProps.style,
      marginLeft: 'auto',
      marginRight: 'auto',
    };
  }

  const renderedIcon = <span {...spanProps} />;

  if (isNil(props.children)) {
    return renderedIcon;
  }

  return (
    <Flex align="center" justify="flex-start">
      <Flex order={props.before ? 0 : 2}>
        <Padding left={props.before ? undefined : '8'} right={props.after ? undefined : '8'}>
          {renderedIcon}
        </Padding>
      </Flex>

      {props.children}
    </Flex>
  );
};

Icon.defaultProps = {
  set: 'flat',
  size: 16,
  type: 'dropdown',
  extraClasses: '',
  inButton: null,
  center: false,
  inline: false,
};
