/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { Omit } from 'utils/types';

import { applyModifiers } from 'fela-rules/modifiers';

import { COLORS } from 'app/theme/materialDS';
import { ThemeType } from 'app/theme';

import { InteractionCircle } from '../../shared/interactionCircle';
import { withInteractivity, WithInteractivitySuppliedProps } from '../../hoc/withInteractivity';

export type FelaProps = {
  disabled: boolean;
  checked: boolean;
  small: boolean;
  id?: string;
  className?: string;
};
export type ThumbFelaProps = {
  thumbColor: COLORS;

  focusCheckedColor: COLORS;
  hoverCheckedColor: COLORS;
  focusUncheckedColor: COLORS;
  hoverUncheckedColor: COLORS;
};
export type TrackFelaProps = {
  trackCheckedColor: COLORS;
  trackCheckedDisabledColor: COLORS;
  trackUncheckedColor: COLORS;
  trackUncheckedDisabledColor: COLORS;
  trackUncheckedFocusColor: COLORS;
};

const StyledSwitchInput = (): IStyle => ({
  visibility: 'hidden',
  position: 'absolute',
});

const SwitchInput = createComponentWithProxy(StyledSwitchInput);

const StyledSwitchWrapper = (props: FelaProps): IStyle =>
  applyModifiers([
    props.small,
    {
      width: '56px',
    },
  ])({
    height: '40px',
    position: 'relative',
    width: '64px',
    display: 'block',
  });

const SwitchWrapper = createComponentWithProxy(StyledSwitchWrapper);

const StyledTrack = (props: TrackFelaProps & FelaProps & ThemeType): IStyle =>
  applyModifiers(
    [
      props.checked,
      {
        backgroundColor: props.theme.ds.getColorByName(props.trackCheckedColor),
      },
    ],
    [
      props.disabled && props.checked,
      {
        backgroundColor: props.theme.ds.getColorByName(props.trackCheckedDisabledColor),
      },
    ],
    [
      props.disabled && !props.checked,
      {
        backgroundColor: props.theme.ds.getColorByName(props.trackUncheckedDisabledColor),
      },
    ],
    [
      props.small,
      {
        width: '28px',
        height: '12px',
      },
    ],
  )({
    backgroundColor: props.theme.ds.getColorByName(props.trackUncheckedColor),
    borderRadius: '8px',
    cursor: 'pointer',
    height: '14px',
    width: '36px',
    display: 'inline-block',
    transform: 'translate(14px, 13px)',
    ':active': { backgroundColor: props.theme.ds.getColorByName(props.trackUncheckedFocusColor) },
  });

const SwitchTrack = createComponentWithProxy(StyledTrack);

const StyledThumbWrapper = (props: Omit<FelaProps, 'disabled'> & ThemeType): IStyle =>
  applyModifiers(
    [
      props.checked,
      {
        transform: 'translate(24px, 0)',
      },
    ],
    [
      props.small,
      {
        transform: 'translate(0, 0)',
      },
    ],
    [
      props.small && props.checked,
      {
        transform: 'translate(16px, 0)',
      },
    ],
  )({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    position: 'absolute',
    transform: 'translate(0, 0)',
    transition: 'transform 200ms ease, background-color 300ms ease',
    width: '40px',
    zIndex: 3,
  });

const SwitchThumbWrapper = createComponentWithProxy(StyledThumbWrapper);

const StyledThumbGrabber = (
  props: Pick<ThumbFelaProps, 'thumbColor'> & Pick<FelaProps, 'small'> & ThemeType,
): IStyle =>
  applyModifiers([props.small, { width: '16px', height: '16px' }])({
    backgroundColor: props.theme.ds.getColorByName(props.thumbColor),
    borderRadius: '40px',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.35)',
    cursor: 'pointer',
    height: '20px',
    position: 'relative',
    width: '20px',
    zIndex: 2,
  });

const SwitchThumbGrabber = createComponentWithProxy(StyledThumbGrabber, 'div');

type PublicThumbProps = ThumbFelaProps & FelaProps;
const SwitchThumb: React.FunctionComponent<PublicThumbProps & WithInteractivitySuppliedProps> = ({
  checked,
  thumbColor,
  onClick,
  small,
  ...props
}) => (
  <SwitchThumbWrapper
    checked={checked}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    onClick={onClick}
    small={small}
  >
    <SwitchThumbGrabber thumbColor={thumbColor} small={small} />
    <InteractionCircle
      hoverHighlightColor={checked ? props.hoverCheckedColor : props.hoverUncheckedColor}
      pressedHighlightColor={checked ? props.focusCheckedColor : props.focusUncheckedColor}
      hovered={props.hovered}
      pressed={props.pressed}
      focused={props.focused}
      disabled={props.disabled}
      interactiveOpacity={0.5}
    />
  </SwitchThumbWrapper>
);

const SwitchThumbWithHoc = withInteractivity<
  PublicThumbProps,
  PublicThumbProps & WithInteractivitySuppliedProps
>(SwitchThumb);

export { SwitchInput, SwitchThumbWithHoc as SwitchThumb, SwitchTrack, SwitchWrapper };
