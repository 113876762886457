/** @format */

import { IStyle } from 'fela';
import { reduce, merge } from 'lodash';

export function applyModifiers(...rules: [boolean, IStyle][]): (style: IStyle) => IStyle {
  return function addTruthyModifiersToStyle(style: IStyle) {
    return reduce(
      rules,
      (modifiedStyle, [shouldApply, modifier]) => {
        if (shouldApply) {
          return merge({ ...modifiedStyle }, { ...modifier });
        }

        return modifiedStyle;
      },
      style,
    );
  };
}
