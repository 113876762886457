/** @format */

export function filterByName<T extends { name:string }>(itemsWithName: T[], filter:string) {
  const trimmedFilter = filter.toLowerCase().trim(); 

  if(trimmedFilter.length == 0) {
    return itemsWithName;
  }

  return itemsWithName.filter(i => i.name.toLowerCase().indexOf(trimmedFilter) > -1);  
}