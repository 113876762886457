/** @format **/

import { SagaIterator } from 'redux-saga';
import { Action } from 'typescript-fsa';
import { put, select, takeLatest, call } from 'redux-saga/effects';

import { TileDefinition, TileMetadata, TileSettings } from '../models';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import { DashboardModuleStateAtom } from '../state';

function* createTileWithSettings(action: Action<TileDefinition>): SagaIterator {
  const state: DashboardModuleStateAtom = yield select();

  const settings = Selectors.tileSetting.getSettings(state);

  yield call(createTile, settings, action.payload.id, action.payload.metadata);
}

function* createTileWithDefaults(action: Action<TileDefinition>): SagaIterator {
  const settings: TileSettings = {
    ...action.payload.defaultSettings,
    Title: action.payload.name,
  };

  yield call(createTile, settings, action.payload.id, action.payload.metadata);
}

function* createTile(settings: TileSettings, type: string, metadata: TileMetadata): SagaIterator {
  yield put(
    Actions.addTile({
      Metadata: metadata,
      Settings: settings,
      Type: type,
    }),
  );
}

function* updateTile(action: Action<Actions.tileSetting.UpdateTilePayload>): SagaIterator {
  const state: DashboardModuleStateAtom = yield select();
  const updatedSettings = Selectors.tileSetting.getSettings(state);

  const idToUpdate = action.payload.tileInstanceId;

  yield put(
    Actions.updateTile({
      instanceId: idToUpdate,
      settings: updatedSettings,
    }),
  );
}

export function* Saga(): SagaIterator {
  yield takeLatest(Actions.tileSetting.createTile, createTileWithSettings);
  yield takeLatest(Actions.tileSetting.createTileWithDefaults, createTileWithDefaults);
  yield takeLatest(Actions.tileSetting.updateTile, updateTile);
}
