/** @format **/

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from 'interfaces/appState';

import { openModal } from "components/modal";

import { isAppSwitcherOpen, isDashboardSwitcherOpen } from '../../../selectors/chrome';
import { showApplicationSwitcher, showDashboardSwitcher } from '../../../actions/chrome';

import {
  ConnectedSwitcher,
  SWITCHER_TYPE,
  SwitcherPassedDispatchProps,
  SwitcherPassedStateProps,
} from './components/switcher';

import { DashboardsGatedFeatureModal } from "./components/dashboardsFeatureGating";

export const AppSwitcherContainer = connect<SwitcherPassedStateProps, SwitcherPassedDispatchProps>(
  (state: AppState) => ({
    type: SWITCHER_TYPE.Applications,
    isSwitcherOpen: isAppSwitcherOpen(state),
    title: 'Applications',
  }),
  (dispatch: Dispatch) => ({
    onClose: () => dispatch(showApplicationSwitcher(false)),
  }),
)(ConnectedSwitcher);

export const DashboardSwitcherContainer = connect<
  SwitcherPassedStateProps,
  SwitcherPassedDispatchProps
>(
  (state: AppState) => ({
    type: SWITCHER_TYPE.Dashboards,
    isSwitcherOpen: isDashboardSwitcherOpen(state),
    title: 'Dashboards',
  }),
  (dispatch: Dispatch) => ({
    onClose: () => dispatch(showDashboardSwitcher(false)),
    openFeatureGatingModal: () => dispatch(openModal(DashboardsGatedFeatureModal)),
  }),
)(ConnectedSwitcher);
