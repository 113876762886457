/**
 * @prettier
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { groupBy, get, keys } from 'lodash';
import { DashboardState } from 'sections/dashboard/index';
import { Text } from 'components/text/index';
import { Padding } from 'components/layout/index';
import * as Selectors from '../../../../selectors';
import * as Actions from '../../../../actions';
import * as Models from '../../../../models';

type StateProps = {
  errorGroupCount: number;
  applicationCount: number;
};

type DispatchProps = {
  onDeselect: () => void;
};

type PassedProps = {
  id: string;
};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedFooter: React.FunctionComponent<Props> = props => (
  <Padding top="4">
    <Text size="small">
      <strong>{`${props.errorGroupCount} error${props.errorGroupCount > 1 ? 's' : ''} `}</strong>
      selected from
      <strong>{` ${props.applicationCount} application${
        props.applicationCount > 1 ? 's' : ''
      }. `}</strong>
      <a className="text-red clickable" onClick={props.onDeselect}>
        Deselect all
      </a>
    </Text>
  </Padding>
);

export const ConnectedFooter = connect<StateProps, DispatchProps, PassedProps>(
  (state: DashboardState, ownProps: PassedProps) => {
    const errorGroups: Models.SelectedErrorGroup[] = get(
      Selectors.getSelectedErrorGroups(state),
      ownProps.id,
      [],
    );
    return {
      applicationCount: keys(groupBy(errorGroups, e => e.applicationId)).length,
      errorGroupCount: errorGroups.length,
    };
  },
  (dispatch: Dispatch, ownProps: PassedProps) => ({
    onDeselect: () => dispatch(Actions.deselectAllErrorGroups(ownProps.id)),
  }),
)(UnconnectedFooter);

export { ConnectedFooter as Footer };
