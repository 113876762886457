/** @format */

import React from 'react';

import { Body, H4 } from 'components/ds/text';
import { Flex } from 'components/flex';
import { Margin } from 'components/layout';

type Props = {
  height: string;
  message?: string;
  subtext?: string | JSX.Element;
};

export const NoData: React.FunctionComponent<Props> = ({ height, message, subtext }) => (
  <Flex style={{ height, textAlign: 'center' }} justify={'center'}>
    <Flex direction="column" justify={'center'} align={'center'}>
      <Margin bottom={8}>
        <img src="/Content/Images/nodata/sad-data.svg" alt="No data icon" height="56px" />
      </Margin>

      <H4>{message ? message : 'No data to display'}</H4>

      {subtext && (
        <Margin top={4}>
          <Body>{subtext}</Body>
        </Margin>
      )}
    </Flex>
  </Flex>
);
