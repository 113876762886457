/** @format */

import * as React from 'react';
import { connect } from 'react-redux';

import * as Actions from 'modules/dashboard/actions';
import { State as DashboardState } from 'modules/dashboard';

import { Tooltip } from 'components/tooltip';
import { Padding } from 'components/layout';
import { EmphasisButton } from 'components/ds/button';
import { openModal } from 'components/modal';
import { ConfirmModal, ConfirmModalProps } from 'components/modal/prefabs';

type StateProps = {};

type DispatchProps = {
  openConfirmModal: () => void;
};

type PassedProps = {
  hasUpdates: boolean;
};

type Props = StateProps & DispatchProps & PassedProps;

const SaveModalProps: ConfirmModalProps = {
  headerText: 'Save Overview',
  confirmText:
    'Are you sure you want to save? These changes will be visible to everyone who can access this application.',
  confirmButtonText: 'Yes, save',
  callback: dispatch => dispatch(Actions.saveLayout.base({})),
  doNotShowAgain: true,
};

export const UnconnectedSaveChanges: React.FunctionComponent<Props> = props => (
  <Tooltip label="Save your dashboard">
    <Padding left="4">
      <EmphasisButton onClick={props.openConfirmModal}>Save changes</EmphasisButton>
    </Padding>
  </Tooltip>
);

export const SaveChanges = connect<StateProps, DispatchProps, PassedProps, DashboardState>(
  null,
  (dispatch, passedProps) => ({
    openConfirmModal: () => {
      if (!passedProps.hasUpdates) {
        SaveModalProps.callback(dispatch);
      } else {
        dispatch(openModal(ConfirmModal, SaveModalProps));
      }
    },
  }),
)(UnconnectedSaveChanges);
