/** @format **/

import React from 'react';
import { connect } from 'react-redux';

import { Plan } from 'interfaces/plan';
import { AppState } from 'interfaces/appState';

import { getDefaultPlanId } from 'utils/plans';
import { buildPlanLink } from 'utils/links';

import { getPlans } from 'app/selectors/configuration';

import { PlanAvatar } from 'components/ds/planAvatar';

import { MenuTitle, MenuAvatarContainer } from './menuItems';

type StateProps = {
  plans: Plan[];
  currentPagePlanId: number;
};

type DispatchProps = {};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedPlans: React.FunctionComponent<Props> = ({ plans, currentPagePlanId }) => (
  <>
    {plans.map((p, i) => (
      <MenuAvatarContainer
        key={p.Id}
        active={currentPagePlanId === p.Id}
        as="a"
        href={buildPlanLink(p.Id)}
      >
        <PlanAvatar name={p.PlanName} identifier={p.Id} big={false} planNumber={i} />
        <MenuTitle forPlan>{p.PlanName}</MenuTitle>
      </MenuAvatarContainer>
    ))}
  </>
);

const ConnectedPlans = connect<StateProps, DispatchProps, PassedProps>((state: AppState) => ({
  plans: getPlans(state),
  currentPagePlanId: getDefaultPlanId(),
}))(UnconnectedPlans);

export { ConnectedPlans as Plans };
