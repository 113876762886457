/** @format **/

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { get, first } from 'lodash';

import { AppState } from 'interfaces/appState';
import { Message } from 'interfaces/messages';
import { Products, ProductTypes } from 'interfaces/products';
import { messagesSelector } from 'app/selectors/messages';
import { fetchMessages } from 'app/actions/messages';

import { Message as MessageComponent } from './messages';

type StateProps = {
  message: Message;
};

type DispatchProps = {
  fetchMessages: () => void;
};

type PassedProps = {
  show: boolean;
  productType?: ProductTypes;
};

type Props = StateProps & DispatchProps & PassedProps;

class DumbMessages extends React.Component<Props, {}> {
  componentDidMount() {
    if (this.props.show) {
      this.props.fetchMessages();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.show && nextProps.show) {
      this.props.fetchMessages();
    }
  }

  render() {
    const message = this.props.message;
    if (!this.props.show || !message) {
      return null;
    }

    return (
      <div>
        <MessageComponent {...message} />
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps, PassedProps>(
  (state: AppState, passedProps: PassedProps) => ({
    message: first(
      messagesSelector(state).filter(message => {
        if (passedProps.productType) {
          const productType = get(message, 'Data.ProductType', null);
          return !productType || productType === passedProps.productType;
        }
        return true;
      }),
    ),
  }),
  (dispatch: Dispatch) => ({
    fetchMessages: () => dispatch(fetchMessages() as any),
  }),
)(DumbMessages);
