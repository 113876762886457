/** @format */

import React from 'react';
import cx from 'classnames';

function isDefined(value: any) {
  return typeof value !== 'undefined';
}

const HorizontalAlignments = {
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
  SPACED: 'spaced',
};

const VerticalAlignments = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
  STRETCH: 'stretch',
};

type RowProps = {
  className?: string;
  upOnSmall?: number;
  upOnMedium?: number;
  upOnLarge?: number;
  horizontalAlignment?: 'center' | 'right' | 'justify' | 'spaced';
  verticalAlignment?: 'top' | 'middle' | 'bottom' | 'stretch';
  unstackOnSmall?: boolean;
  unstackOnMedium?: boolean;
  unstackOnLarge?: boolean;
  collapseOnSmall?: boolean;
  collapseOnMedium?: boolean;
  collapseOnLarge?: boolean;
  collapseOnAll?: boolean;
  uncollapseOnSmall?: boolean;
  uncollapseOnMedium?: boolean;
  uncollapseOnLarge?: boolean;
  isCollapsed?: boolean;
  isExpanded?: boolean;
  isColumn?: boolean;
  noDefaultClassName?: boolean;
  overflow?: string;
  backgroundColor?: string;
};

export const Row: React.FunctionComponent<RowProps> = props => {
  const className = cx(
    props.noDefaultClassName ? null : 'row',
    props.className,
    isDefined(props.upOnSmall) ? `small-up-${props.upOnSmall}` : null,
    isDefined(props.upOnMedium) ? `medium-up-${props.upOnMedium}` : null,
    isDefined(props.upOnLarge) ? `large-up-${props.upOnLarge}` : null,
    isDefined(props.overflow) ? `overflow--${props.overflow}` : null,
    {
      'align-right': props.horizontalAlignment === HorizontalAlignments.RIGHT,
      'align-center': props.horizontalAlignment === HorizontalAlignments.CENTER,
      'align-justify': props.horizontalAlignment === HorizontalAlignments.JUSTIFY,
      'align-spaced': props.horizontalAlignment === HorizontalAlignments.SPACED,
      'align-top': props.verticalAlignment === VerticalAlignments.TOP,
      'align-middle': props.verticalAlignment === VerticalAlignments.MIDDLE,
      'align-bottom': props.verticalAlignment === VerticalAlignments.BOTTOM,
      'align-stretch': props.verticalAlignment === VerticalAlignments.STRETCH,
      'small-unstack': props.unstackOnSmall,
      'medium-unstack': props.unstackOnMedium,
      'large-unstack': props.unstackOnLarge,
      'small-collapse': props.collapseOnSmall || props.collapseOnAll,
      'medium-collapse': props.collapseOnMedium || props.collapseOnAll,
      'large-collapse': props.collapseOnLarge || props.collapseOnAll,
      'small-uncollapse': props.uncollapseOnSmall,
      'medium-uncollapse': props.uncollapseOnMedium,
      'large-uncollapse': props.uncollapseOnLarge,
      collapse: props.isCollapsed,
      expanded: props.isExpanded,
      column: props.isColumn,
    },
  );

  const style = {
    overflow: isDefined(props.overflow) ? props.overflow : '',
    backgroundColor: isDefined(props.backgroundColor) ? props.backgroundColor : '',
  };

  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
};

type ColumnProps = {
  className?: string;
  small?: number;
  medium?: number;
  large?: number;
  xLarge?: number;
  offsetOnSmall?: number;
  offsetOnMedium?: number;
  offsetOnLarge?: number;
  offsetOnXLarge?: number;
  pushOnSmall?: number;
  pushOnMedium?: number;
  pushOnLarge?: number;
  pushOnXLarge?: number;
  pullOnSmall?: number;
  pullOnMedium?: number;
  pullOnLarge?: number;
  orderOnSmall?: number;
  orderOnMedium?: number;
  orderOnLarge?: number;
  orderOnXLarge?: number;
  centerOnSmall?: boolean;
  centerOnMedium?: boolean;
  centerOnLarge?: boolean;
  centerOnXLarge?: boolean;
  uncenterOnSmall?: boolean;
  uncenterOnMedium?: boolean;
  uncenterOnLarge?: boolean;
  uncenterOnXLarge?: boolean;
  expandOnSmall?: boolean;
  expandOnMedium?: boolean;
  expandOnLarge?: boolean;
  expandOnXLarge?: boolean;
  isShrunk?: boolean;
  isLast?: boolean;
  isColumn?: boolean;
  noDefaultClassName?: boolean;
  backgroundColor?: string;
  fullHeight?: boolean;
  fullHeightMediumUp?: boolean;
  hideOnSmall?: boolean;
  hideOnMedium?: boolean;
  showOnSmall?: boolean;
  overflow?: boolean;
  noPadding?: boolean;
  flex?: boolean;
};

export const Column: React.FunctionComponent<ColumnProps> = props => {
  const defaultClassName = props.isColumn ? 'column' : 'columns';
  const className = cx(
    props.noDefaultClassName ? null : defaultClassName,
    props.className,
    props.small ? `small-${props.small}` : 'small-12',
    props.medium ? `medium-${props.medium}` : null,
    props.large ? `large-${props.large}` : null,
    props.xLarge ? `extra-large-${props.xLarge}` : null,
    props.hideOnSmall ? `hide-mob` : null,
    props.showOnSmall ? `hide show-mob` : null,
    props.hideOnMedium ? `hide-min` : null,
    props.fullHeight ? `height-100vh` : null,
    props.fullHeightMediumUp ? `height-100vh--medium-up` : null,
    props.overflow === false ? 'overflow--hidden' : null,
    props.flex ? 'flex-container' : null,
    isDefined(props.offsetOnSmall) ? `small-offset-${props.offsetOnSmall}` : null,
    isDefined(props.offsetOnMedium) ? `medium-offset-${props.offsetOnMedium}` : null,
    isDefined(props.offsetOnLarge) ? `large-offset-${props.offsetOnLarge}` : null,
    isDefined(props.offsetOnXLarge) ? `extra-large-offset-${props.offsetOnXLarge}` : null,
    isDefined(props.pushOnSmall) ? `small-offset-${props.pushOnSmall}` : null,
    isDefined(props.pushOnMedium) ? `medium-offset-${props.pushOnMedium}` : null,
    isDefined(props.pushOnLarge) ? `large-offset-${props.pushOnLarge}` : null,
    isDefined(props.pushOnXLarge) ? `extra-large-offset-${props.pushOnXLarge}` : null,
    isDefined(props.pullOnSmall) ? `small-pull-${props.pullOnSmall}` : null,
    isDefined(props.pullOnMedium) ? `medium-pull-${props.pullOnMedium}` : null,
    isDefined(props.orderOnSmall) ? `small-order-${props.orderOnSmall}` : null,
    isDefined(props.orderOnMedium) ? `medium-order-${props.orderOnMedium}` : null,
    isDefined(props.orderOnLarge) ? `large-order-${props.orderOnLarge}` : null,
    isDefined(props.orderOnXLarge) ? `large-order-${props.orderOnXLarge}` : null,
    {
      'small-centered': props.centerOnSmall,
      'medium-centered': props.centerOnMedium,
      'large-centered': props.centerOnLarge,
      'extra-large-centered': props.centerOnXLarge,
      'small-uncentered': props.uncenterOnSmall,
      'medium-uncentered': props.uncenterOnMedium,
      'large-uncentered': props.uncenterOnLarge,
      'extra-large-uncentered': props.uncenterOnXLarge,
      'small-expand': props.expandOnSmall,
      'medium-expand': props.expandOnMedium,
      'large-expand': props.expandOnLarge,
      'extra-large-expand': props.expandOnXLarge,
      shrink: props.isShrunk,
      end: props.isLast,
    },
  );

  const style = {
    backgroundColor: isDefined(props.backgroundColor) ? props.backgroundColor : '',
    paddingLeft: props.noPadding ? '0' : undefined,
    paddingRight: props.noPadding ? '0' : undefined,
  };

  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
};
