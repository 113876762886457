/** @format */

import * as React from 'react';
import classnames from 'classnames';

import { getTextColorClass, COLOR_TYPES } from 'components/text';
import { SIZES, HEIGHTS } from './constants';

export type TYPES =
  | 'amazonsqs'
  | 'asana'
  | 'assembla'
  | 'azureservicebus'
  | 'bitbucket'
  | 'bugherd'
  | 'catechnologies'
  | 'campfire'
  | 'fogbugz'
  | 'github'
  | 'gitlab'
  | 'intercom'
  | 'jira'
  | 'jiraoauth'
  | 'lighthouse'
  | 'moxtra'
  | 'octopus'
  | 'pagerduty'
  | 'pivotaltracker'
  | 'caagilecentral'
  | 'slack'
  | 'sprintly'
  | 'slack-icon-white'
  | 'targetprocess'
  | 'trello'
  | 'victorops'
  | 'visualstudioonline'
  | 'webhook'
  | 'youtrack'
  | 'zapier'
  | 'zendesk';

export interface IconProps {
  size?: SIZES;
  height?: HEIGHTS;
  type: TYPES;
  color?: COLOR_TYPES;
  extraClasses?: string;
  inButton?: 'after' | 'before' | null;
  center?: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export const Integration: React.FunctionComponent<IconProps> = props => {
  const classNames = classnames(
    `integration-icon integration-icon--${props.type}`,
    {
      [`icon-${props.size}`]: props.size !== undefined,
      [`icon--height-${props.height}`]: props.height !== undefined,
      'icon-center': props.center,
    },
    getTextColorClass(props.color),
    `${props.extraClasses}`,
  );

  return <span className={classNames} onClick={props.onClick} />;
};

Integration.defaultProps = {
  size: 16,
  extraClasses: '',
  inButton: null,
  center: false,
};

export default Integration;
