/** @format */

import { makeRoutingReducer, makeRouteMap } from 'utils/routing';
import { LayoutWithSideBar } from './components/layout';

import { GoToWhatsNew } from 'sections/productpages/routing';

import { WhatsNew } from './screens';

export const Routes = makeRouteMap({
  [GoToWhatsNew.type]: 'whats-new',
});

export const Reducer = makeRoutingReducer([[GoToWhatsNew, WhatsNew]], LayoutWithSideBar);
