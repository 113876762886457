/** @format */

import * as React from 'react';
import { Margin } from 'components/layout';
import { HasRevampedChrome } from './hasRevampedChrome';
import { ApplicationTrigger, DashboardTrigger } from './switcher/trigger';

type Props = {
  bottomMargin?: string;
};

export const ApplicationTriggerHeader: React.FunctionComponent<Props> = props => (
  <HasRevampedChrome>
    <Margin bottom={props.bottomMargin}>
      <ApplicationTrigger />
    </Margin>
  </HasRevampedChrome>
);

ApplicationTriggerHeader.defaultProps = {
  bottomMargin: '8',
};

export const DashboardTriggerHeader: React.FunctionComponent<Props> = props => (
  <HasRevampedChrome>
    <Margin bottom={props.bottomMargin}>
      <DashboardTrigger />
    </Margin>
  </HasRevampedChrome>
);

DashboardTriggerHeader.defaultProps = {
  bottomMargin: '8',
};
