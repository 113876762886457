/** @format **/
import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { Icon } from 'components/icon';

type RowFavouriteProps = {
  favourited: boolean;
};

const StyledFavourite = (): IStyle => ({
  paddingLeft: '8px',
  paddingRight: '8px',
  marginLeft: 'auto',
});

export const Favourite = createComponentWithProxy(StyledFavourite);

export const RowFavourite: React.FunctionComponent<RowFavouriteProps> = ({ favourited }) => (
  <Favourite>
    <Icon set="icomoon" type="favourite-solid" dsColor={favourited ? 'amber600' : 'blueGrey200'} />
  </Favourite>
);
