/** @format */
import { createSelector } from 'reselect';
import { groupBy, some, find, filter } from 'lodash';

import { getDashboards, getApplications } from 'app/selectors/application';
import { filterByName } from 'utils/filters';
import { toIdentifier } from 'utils/linking';
import { getPlans } from 'app/selectors/configuration';

import { PermissionManager, PlanPermissions } from 'modules/permissions';

import { RevampedChromeState } from '../reducers';
import { SWITCHER_TYPE } from '../models';

const getSwitcher = (state: RevampedChromeState) => state.revampedChrome.switcher;

export const getSwitcherPlanId = createSelector(
  getSwitcher,
  state => state.currentPlanId,
);

export const getSwitcherSelectedPlan = createSelector(
  getSwitcherPlanId,
  getPlans,
  (planId, plans) => find(plans, p => p.Id === planId),
);

export const getFilterText = createSelector(
  getSwitcher,
  state => state.filter,
);

export const isSwitcherOpen = createSelector(
  getSwitcher,
  state => state.open,
);

export const getSwitcherType = createSelector(
  getSwitcher,
  state => state.type,
);

export const isApplicationSwitcher = createSelector(
  getSwitcherType,
  type => type === SWITCHER_TYPE.Application,
);

export const getApplicationsForPlan = createSelector(
  getSwitcherPlanId,
  getApplications,
  (planId, applications) => applications.filter(a => a.planId === planId),
);

export const getFilteredApplicationsForPlan = createSelector(
  getApplicationsForPlan,
  getFilterText,
  (apps, filter) => filterByName(apps, filter),
);

export const getFavouriteApplicationsForPlan = createSelector(
  getApplicationsForPlan,
  applications => applications.filter(a => a.isFavourite),
);

export const getFilteredFavouriteApplicationsForPlan = createSelector(
  getFavouriteApplicationsForPlan,
  getFilterText,
  (apps, filter) => filterByName(apps, filter),
);

export const getDashboardsForPlan = createSelector(
  getSwitcherPlanId,
  getDashboards,
  (planId, dashboards) => filter(dashboards, d => d.planId === planId),
);

export const getFilteredDashboardsForPlan = createSelector(
  getDashboardsForPlan,
  getFilterText,
  (dashboards, filter) => filterByName(dashboards, filter),
);

export const getFavouriteDashboardsForPlan = createSelector(
  getDashboardsForPlan,
  dashboards => dashboards.filter(d => d.isFavourite),
);

export const getFilteredFavouriteDashboardsForPlan = createSelector(
  getFavouriteDashboardsForPlan,
  getFilterText,
  (dashboards, filter) => filterByName(dashboards, filter),
);

const SHOW_FILTER_APPLICATION_COUNT = 10;

export const showFilterInput = createSelector(
  getApplications,
  applications =>
    some(groupBy(applications, a => a.planId), a => a.length >= SHOW_FILTER_APPLICATION_COUNT),
);

export const getCreateApplicationLink = createSelector(
  getSwitcherPlanId,
  planId => `/createApplication?planIdentifier=${toIdentifier(planId)}`,
);

export const getCreateDashboardLink = createSelector(
  getSwitcherPlanId,
  planId => `/tiledashboard/${toIdentifier(planId)}/create?type=0`,
);

const hasReachedAppLimitForPlan = createSelector(
  getSwitcherSelectedPlan,
  activePlan => activePlan.HasReachedAppLimit,
);

const hasPermissionToCreateApplications = createSelector(
  getSwitcherSelectedPlan,
  activePlan =>
    PermissionManager.can(PlanPermissions.CreateNewApplications, null, null, activePlan.Id),
);

export const canCreateApplicationsForPlan = createSelector(
  hasPermissionToCreateApplications,
  hasReachedAppLimitForPlan,
  (hasPermission, hasReachedAppLimit) => hasPermission && !hasReachedAppLimit,
);

export const getTooltipLabelForDisabledButton = createSelector(
  hasPermissionToCreateApplications,
  hasReachedAppLimitForPlan,
  (hasPermission, hasReachedAppLimit) => {
    if (!hasPermission) {
      return "You don't have permission to create an application.";
    } else if (hasReachedAppLimit) {
      return 'You have reached the application limit for this plan';
    }

    return null;
  },
);
