/** @format **/

import * as React from 'react';
import classnames from 'classnames';

import { SnowplowLink } from 'components/snowplow';

interface State {
  spinning: boolean;
  shockwaves: number[];
}

let wavenumber = 0;

class Emblem extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.animateEmblem = this.animateEmblem.bind(this);
    this.animateEmblemEnd = this.animateEmblemEnd.bind(this);
    this.shockWaveEnd = this.shockWaveEnd.bind(this);
  }

  state: State = {
    spinning: false,
    shockwaves: [],
  };

  animateEmblem() {
    this.setState((prevState: State) => {
      return {
        spinning: true,
        shockwaves: [...prevState.shockwaves, wavenumber++],
      };
    });
  }
  animateEmblemEnd() {
    this.setState((prevState: State) => {
      return {
        spinning: false,
        shockwaves: [...prevState.shockwaves],
      };
    });
  }
  shockWaveEnd() {
    this.setState((prevState: State) => {
      return {
        spinning: prevState.spinning,
        shockwaves: prevState.shockwaves.slice(1),
      };
    });
  }
  render() {
    const ringClass = classnames('raygun-emblem__ring', {
      'raygun-emblem__ring--animate': this.state.spinning,
    });

    const boltClass = classnames('raygun-emblem__bolt', {
      'raygun-emblem__bolt--animate': this.state.spinning,
    });

    const shockwaves = this.state.shockwaves.map(val => {
      return (
        <div
          key={val}
          className={'raygun-emblem__shockwave raygun-emblem__shockwave--animate'}
          onAnimationEnd={this.shockWaveEnd}
        />
      );
    });

    return (
      <SnowplowLink interactionId="sp-app-chrome-switcher-clickEmblemButton">
        <div className="raygun-emblem" onClick={this.animateEmblem}>
          <div className={ringClass} onAnimationEnd={this.animateEmblemEnd} />
          <div className={boltClass} />
          {shockwaves}
        </div>
      </SnowplowLink>
    );
  }
}

export default Emblem;
