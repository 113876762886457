/**
 * @prettier
 */
import React from 'react';
import { createComponentWithProxy } from 'react-fela';
import { IStyle } from 'fela';
import { omit } from 'lodash';

import { COLORS } from 'app/theme/materialDS';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

import { Icon } from 'components/icon';

import { BaseEventProps, BasePassthroughProps } from '../../';
import { InteractionCircleAfterStyles } from '../../shared/interactionCircle';

export type PublicBaseIconButtonProps = {
  // display
  disabled: boolean;
  icon: string;

  // colors
  textColor: COLORS;

  disabledTextColor: COLORS;

  hoverHighlightColor: COLORS;
  pressedHighlightColor: COLORS;
  title?: string;
} & BaseEventProps<HTMLElement> &
  BasePassthroughProps;

type BaseIconButtonProps = PublicBaseIconButtonProps;

const StyledBaseIconButton = (props: BaseIconButtonProps & ThemeType): IStyle =>
  applyModifiers([
    props.disabled,
    {
      color: props.theme.ds.getColorByName(props.disabledTextColor),
      cursor: 'not-allowed',
      transition: 'none',
    },
  ])({
    backgroundColor: props.theme.ds.colors.special.transparent,
    color: props.theme.ds.getColorByName(props.textColor),
    width: '40px',
    height: '40px',
    padding: '12px',
    border: 'none',
    cursor: 'pointer',
    position: 'relative',
    ':focus': {
      outline: 0,
    },
    ['-webkit-tap-highlight-color' as any]: props.theme.ds.colors.special.transparent,
    ['> .raygun-icon' as any]: {
      position: 'relative',
      zIndex: 2,
    },
    ...InteractionCircleAfterStyles(props),
  });

const FelaBaseIconButton = createComponentWithProxy<BaseIconButtonProps>(
  StyledBaseIconButton,
  'button',
);

export type BaseDefaultProps = Pick<PublicBaseIconButtonProps, 'disabled'>;

export const DefaultProps: BaseDefaultProps = {
  disabled: false,
};

class BaseIconButton extends React.Component<BaseIconButtonProps> {
  static defaultProps: BaseDefaultProps = DefaultProps;

  render() {
    return (
      <FelaBaseIconButton {...omit(this.props, 'icon')}>
        <Icon set="icomoon" type={this.props.icon} />
      </FelaBaseIconButton>
    );
  }
}

export { BaseIconButton };
