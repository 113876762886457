/** @format */
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { DashboardState } from 'sections/dashboard/index';
import { TableControls, TableControlsButton, TableControlsCheckbox } from 'components/table/index';
import { Checkbox } from 'components/checkbox/index';
import { Button } from 'components/button/ui/index';
import { StatusDropdown } from './statusDropdown';
import { openModal } from 'components/modal/index';

import { STATUS } from 'constants/status';
import { ApplicationPermissions, PermissionManager } from 'modules/permissions';
import Tooltip from 'components/tooltip';

import * as Selectors from '../../../../selectors';
import * as Actions from '../../../../actions';

import { DeleteErrorsModal } from './deleteErrorsModal';
import { PermIgnoreModal } from './permIgnoreModal';
import { ResolveInVersionModal } from './resolveInVersionModal';
import { IgnoredForModal } from "./ignoreForModal";

type StateProps = {
  isAllSelected: boolean;
  shouldRenderDelete: boolean;
  hasPermanentlyIgnorePermission: boolean;
};

type DispatchProps = {
  toggleAllSelected: () => void;
  onDelete: () => void;
  onStatusChange: (status: string) => void;
  onResolveInVersion: () => void;
};

type PassedProps = {
  id: string;
  drilldown?: boolean;
  appId?: number;
  hasSelectAllOption?: boolean;
};

type Props = StateProps & DispatchProps & PassedProps;

const CheckboxCell: React.FunctionComponent<Props> = props => (
  <Checkbox
    noPadding
    isChecked={props.isAllSelected}
    onToggled={props.toggleAllSelected}
    inlineBlock
  />
);

const UnconnectedActionBar: React.FunctionComponent<Props> = props => {
  const checkbox = (
    <TableControlsCheckbox>
      <CheckboxCell {...props} />
    </TableControlsCheckbox>
  );

  return (
    <TableControls includesCheckbox={props.hasSelectAllOption} border={props.hasSelectAllOption}>
      {props.hasSelectAllOption && checkbox}
      <TableControlsButton>
        <StatusDropdown
          onStatusChange={props.onStatusChange}
          onResolveInVersion={props.onResolveInVersion}
          hasPermanentlyIgnorePermission={props.hasPermanentlyIgnorePermission}
        />
      </TableControlsButton>
      <TableControlsButton>
        <Tooltip
          label="You don't have permission to delete error groups."
          dock="right"
          show={!props.shouldRenderDelete}
        >
          <Button
            size={32}
            type="red"
            onClick={props.onDelete}
            disabled={!props.shouldRenderDelete}
          >
            Delete
          </Button>
        </Tooltip>
      </TableControlsButton>
    </TableControls>
  );
};

const Connector = connect<StateProps, DispatchProps, PassedProps>(
  (state: DashboardState, ownProps: PassedProps) => {
    const hasPermanentlyIgnorePermission = Selectors.hasPermissionForAllApplications(
      ownProps.id,
      ApplicationPermissions.PermanentlyIgnoreError,
    );
    const shouldRenderDelete = Selectors.shouldRenderDelete(ownProps.id);
    const allSelected = Selectors.allErrorGroupsSelectedFactory(
      ownProps.id,
      ownProps.drilldown,
      ownProps.appId,
    );

    return {
      isAllSelected: allSelected(state),
      hasPermanentlyIgnorePermission: hasPermanentlyIgnorePermission(state),
      shouldRenderDelete: shouldRenderDelete(state),
    };
  },
  (dispatch: Dispatch, ownProps: PassedProps) => ({
    toggleAllSelected: () => {
      dispatch(
        Actions.toggleSelectAllErrorGroups({
          tileId: ownProps.id,
          isDrilldown: ownProps.drilldown,
          applicationId: ownProps.appId,
        }),
      );
    },
    onDelete: () => {
      dispatch(
        openModal(DeleteErrorsModal, {
          tileId: ownProps.id,
        }),
      );
    },
    onResolveInVersion: () => dispatch(openModal(ResolveInVersionModal, { tileId: ownProps.id })),
    onStatusChange: (status: string) => {
      if (status === STATUS.PERM_IGNORED) {
        dispatch(openModal(PermIgnoreModal, { tileId: ownProps.id }));
      } else if (status === STATUS.IGNORED) {
        dispatch(openModal(IgnoredForModal, { tileId: ownProps.id }));
      } else {
        dispatch(
          Actions.setErrorGroupStatus({
            tileId: ownProps.id,
            status,
          }),
        );
      }
    },
  }),
);

const ConnectedActionBar = Connector(UnconnectedActionBar);

ConnectedActionBar.defaultProps = {
  hasSelectAllOption: true,
};

const ConnectedCheckboxHeader = Connector(CheckboxCell);

export { ConnectedActionBar as ActionBar, ConnectedCheckboxHeader as CheckboxHeader };