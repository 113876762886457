/** @format */

import createActionFactory from 'typescript-fsa';
import { ProductTypes } from 'interfaces/products';

const createAction = createActionFactory('chrome');

export const showSidebar = createAction<boolean>('TOGGLE_SIDEBAR');
export const toggleMobileSidebar = createAction<boolean>('TOGGLE_MOBILE_SIDEBAR');
export const setSidebarActiveLink = createAction<string>('SET_SIDEBAR_ACTIVE_LINK');
export const setSidebarSecondaryActiveLink = createAction<string>(
  'SET_SIDEBAR_SECONDARY_ACTIVE_LINK',
);
export const setLayout = createAction<'product' | 'platform' | 'anonymous' | 'logo-only' | 'none'>(
  'SET_LAYOUT',
);
export const showMessages = createAction<boolean>('SHOW_MESSAGES');
export const setMessageProductTypeRestrictions = createAction<ProductTypes>(
  'SET_MESSAGE_PRODUCT_TYPE_RESTRICTIONS',
);
export const bootApp = createAction('BOOT_APP');

export const showApplicationSwitcher = createAction<boolean>('SET_APP_SWITCHER_STATE');

export const showDashboardSwitcher = createAction<boolean>('SHOW_DASHBOARD_SWITCHER');

export const setAppSwitcherPlanId = createAction<number>('SET_APP_SWITCHER_PLAN_ID');
