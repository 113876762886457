/**
 * @prettier
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { get } from 'lodash';

import { DashboardState } from 'sections/dashboard/index';

import * as Selectors from '../../../../selectors';
import { ContentWrapper } from './contentWrapper';

import { ActionBar } from '../errorGroupList/actionBar';

type StateProps = {
  hasSelectedErrors: boolean;
};

type PassedProps = {
  id: string;
};

type Props = StateProps & PassedProps;

export const UnconnectedActionBar: React.FunctionComponent<Props> = props =>
  props.hasSelectedErrors ? (
    <ContentWrapper forActionBar>
      <ActionBar id={props.id} drilldown={false} hasSelectAllOption={false} />
    </ContentWrapper>
  ) : null;

export const ConnectedActionBar = connect<StateProps, {}, PassedProps>(
  (state: DashboardState, ownProps: PassedProps) => ({
    hasSelectedErrors: get(Selectors.getSelectedErrorGroups(state), ownProps.id, []).length > 0,
  }),
)(UnconnectedActionBar);

export { ConnectedActionBar as ActionBar };
