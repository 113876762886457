/** @format **/

import { isNil } from 'lodash';
import { getTargetFromCookie } from 'utils/cookies';
import * as Models from './models';

// Get the notification from cookies
export function getNotificationFromCookie(cookie: string = document.cookie): Models.Notification {
  const notificationInCookie = getTargetFromCookie(cookie, 'Notification');

  if (!(isNil(notificationInCookie) || cookie === '')) {
    // notification key looks like "Notification.[Success|Error|Warning|Info]"
    const notificationName = notificationInCookie.split('=')[0];
    const notificationType = notificationName.split('.')[1];
    const notificationMessage = notificationInCookie.split('=')[1];

    let enumNotificationType: Models.TYPES;
    switch (notificationType) {
      case 'Success':
        enumNotificationType = Models.TYPES.SUCCESS;
        break;
      case 'Error':
        enumNotificationType = Models.TYPES.ERROR;
        break;
      case 'Warning':
        enumNotificationType = Models.TYPES.WARNING;
        break;
      default:
        const _ec: never = notificationType as never;
    }

    if (!isNil(enumNotificationType)) {
      return {
        id: notificationName,
        message: notificationMessage,
        type: enumNotificationType,
        created: Date.now(),
        position: Models.Position.CENTRE_TOP,
      };
    }
  }

  return null;
}

// clear notification from cookie
export function clearNotificationFromCookie(notificationName: string) {
  document.cookie = `${notificationName}= ; ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}
