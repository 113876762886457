/** @format **/

import * as React from 'react';

import { Dropdown } from 'components/dropdown';
import { Button } from 'components/button';
import { Icon } from 'components/icon';

import { SORT_METHODS } from '../../constants';

interface Props {
  sortMethod: string;
  onSelect: (method: string) => void;
}

export const Sort: React.FunctionComponent<Props> = props => {
  const button = (
    <Button size={32} type="white" block extraClasses="text-left" hasIconAfter>
      <span>{props.sortMethod}</span>
      <Icon size={16} inButton="after" type="dropdown" />
    </Button>
  );

  const sortElements = SORT_METHODS.map(method => {
    return (
      <a key={method} className="dropdown2-section__option" onClick={() => props.onSelect(method)}>
        {method}
      </a>
    );
  });

  return (
    <Dropdown button={button} dockOption="left" closeOnClick block>
      <div className="dropdown2-section">{sortElements}</div>
    </Dropdown>
  );
};
