/** @format **/

import moment, { Moment } from 'moment';

import { DateRange, DateRangeParam, isDateRange } from './types';
import * as DurationFormatting from './durationFormatting';
import * as Converters from './converters';

export { DurationFormatting, Converters };
export * from './types';

export function unpackRange(range: DateRangeParam | DateRange): { from: Moment; to: Moment } {
  const dateRangeParam = isDateRange(range) ? Converters.dateRangeToParam(range) : range;

  let from: Moment;
  switch (dateRangeParam) {
    case '1d':
      from = moment().subtract(1, 'days');
      break;
    case '7d':
      from = moment().subtract(7, 'days');
      break;
    case '30d':
      from = moment().subtract(30, 'days');
      break;
    default:
      const _ec: never = dateRangeParam;
  }

  return { from, to: moment() };
}
