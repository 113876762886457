/** @format **/

import { isEmpty, isFinite, isNumber, map, sum } from 'lodash';

function charToNumber(char: string): number {
  let numberValue = 0;
  let currentIndex = 0;
  let currentCharCode: number;

  while (isFinite((currentCharCode = char.charCodeAt(currentIndex)))) {
    numberValue += currentCharCode;
    currentIndex++;
  }

  return numberValue;
}

/**
 * Converts a supplied input to a number. If the input is a number it gets passed through.
 * If the input is a string all the code points of the string are extracted and summed together to
 * retrieve the resulting output number.
 * ie.
 *
 * const test = "abc";
 * stringToNumber(test) => 294
 * test.charCodeAt(0) => 97
 * test.charCodeAt(1) => 98
 * test.charCodeAt(2) => 99
 * 97 + 98 + 99 => 294
 *
 * Passing an empty string, undefined, or null will result in 0 being returned
 * @param input string or number to be converted to a number
 */
export function stringToNumber(input: string | number): number {
  if (isNumber(input)) {
    return input;
  } else if (isEmpty(input)) {
    return 0;
  }

  return sum(map(input, charToNumber));
}
