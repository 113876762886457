/** @format */

import createActionFactory from 'typescript-fsa';
import * as Models from './models';
import { uuid } from 'utils/generators';

const createAction = createActionFactory('popups');

type PopupId = string;

type OpenPopup = {
  component: Models.PopupComponent;
  props?: any;
  id: PopupId;
};

export const open = createAction<OpenPopup>('OPEN_POPUP');
export const close = createAction<PopupId>('CLOSE_POPUP');

export const getId = () => uuid();
