/** @format */
/* tslint:disable:max-file-line-count */

import moment from 'moment-timezone';
import { isNil } from 'lodash';

import { formatAsShorthand } from 'utils/numberFormatting';
import { getIdealUnit, formatDuration } from 'utils/durationFormatting';
import { convertUtcToUsersMoment, Formatting } from 'utils/date';

import { ONE_DAY, SEVEN_DAYS } from 'constants/dateTimeFilter';

import { Intervals, MarkArea, MarkAreaData, MarkAreaItemStyle } from './models';

export * from './defaultOptions';
export * from './tooltips';
import * as rum from './rum';
export * from './models';
export { rum };

export const lowBlueColor = '#0C558F';
export const highBlueColor = '#158FEF';

// 1 = Fastest - 10 = Slowest
export const distributionColor1 = '#388E3C';
export const distributionColor2 = '#4CAF50';
export const distributionColor3 = '#76C47D';
export const distributionColor4 = '#B0E1B5';
export const distributionColor5 = '#FFEB3B';
export const distributionColor6 = '#FFD740';
export const distributionColor7 = '#FFAB00';
export const distributionColor8 = '#F08888';
export const distributionColor9 = '#EE5C52';
export const distributionColor10 = '#E20000';

export const emphasisColor = '#BBD9EC';

export const newUsersColor = '#6E449A';
export const returningUsersColor = '#3E88C7';

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const millisecondsInOneMinute = 1000 * 60;
export const millisecondsInTenMinutes = 600000;
export const millisecondsInOneHour = 3600000;
export const millisecondsInOneDay = 86400000;

export const oneMinuteLabelIntervals = [1, 2, 3, 6, 12, 24];
export const tenMinuteLabelIntervals = [1, 2, 3, 6, 12, 24, 48];
export const oneHourLabelIntervals = [1, 2, 3, 4, 6, 12, 24, 48, 72];
export const oneDayLabelIntervals = [1, 2, 3, 4, 5, 10, 15, 20, 25, 30];

export const axisPointerLine = {
  lineStyle: {
    color: '#989898',
  },
  animation: false,
};

export function egcMarkArea(render: boolean, xPos1: number, xPos2: number): MarkArea {
  if (isNil(xPos1) || isNil(xPos2)) {
    return null;
  }

  return egcDefaultMarkArea(
    render,
    [
      [
        {
          name: 'Selection',
          xAxis: xPos1,
        },
        {
          xAxis: xPos2,
        },
      ],
    ],
    {
      normal: {
        color: '#e1e1e1',
        opacity: 0.5,
      },
    },
  );
}

export function egcMarkLine(xPos: number) {
  return {
    symbol: 'none',
    silent: true,
    data: [
      {
        name: 'click',
        xAxis: xPos,
      },
    ],
    label: {
      normal: {
        show: false,
      },
      emphasis: {
        show: false,
      },
    },
    lineStyle: {
      normal: {
        color: '#616161',
        width: '2',
        type: 'solid',
      },
      emphasis: {
        color: '#616161',
        width: '2',
        type: 'solid',
      },
    },
    animation: false,
  };
}

export function egcDefaultMarkArea(
  render: boolean,
  data: MarkAreaData,
  itemStyle: MarkAreaItemStyle,
): MarkArea {
  if (!render || isNil(data) || isNil(data[0]) || data[0].length < 2) {
    return { data: [] };
  }

  return {
    silent: true,
    data,
    label: {
      normal: {
        show: false,
      },
      emphasis: {
        show: false,
      },
    },
    itemStyle,
    animation: false,
  };
}

// Get the pixel width of the plotting area within the given eCharts object
function getPlotWidth(chart: any) {
  if (chart) {
    const option = chart.getOption();

    if (option.grid && option.grid.length > 0) {
      return chart.getWidth() - option.grid[0].x - option.grid[0].x2;
    }

    return chart.getWidth();
  }

  return 0;
}

// Get the pixel height of the plotting area within the given eCharts object
function getPlotHeight(chart: any) {
  if (chart) {
    const option = chart.getOption();

    if (option.grid && option.grid.length > 0) {
      return chart.getHeight() - option.grid[0].y - option.grid[0].y2;
    }

    return chart.getHeight();
  }

  return 0;
}

function getLabelInterval(
  plotSize: number,
  labelSize: number,
  labelCount: number,
  intervals: number[],
) {
  const padding = 6;
  const maxLabelCount = plotSize / (labelSize + padding);
  const interval = Math.floor(labelCount / maxLabelCount);

  for (const testInterval of intervals) {
    if (testInterval > interval) {
      return testInterval;
    }
  }

  return intervals[intervals.length - 1];
}

export function getDateTimeLabelFormatter(periods: string[], interval: Intervals, chart: any) {
  if (interval === '1m') {
    return function(value: string, index: number) {
      const date = moment(value);
      // Calculate the interval between labels based on the available space:
      const plotWidth = getPlotWidth(chart);
      const labelWidthInPixels = 65;
      const labelInterval = getLabelInterval(
        plotWidth,
        labelWidthInPixels,
        periods.length,
        oneMinuteLabelIntervals,
      );
      // Get the beginning of the hour of the first period to pivot the label interval from:
      const pivotMoment = moment(periods[0]);
      const pivot = new Date(
        pivotMoment.year(),
        pivotMoment.month(),
        pivotMoment.date(),
        pivotMoment.hours(),
        0,
        0,
        0,
      );
      // Use the label interval and pivot to decide when to render a label:
      if (
        Math.round((date.valueOf() - pivot.getTime()) / millisecondsInOneMinute) % labelInterval ===
        0
      ) {
        var label = Formatting.legacyFormatTimeForChart(date);
        // Add the date as a second line on day boundaries:
        // index <= 2 should cover all the positions of the first label but not include any second positions
        // didn't actually check this, but I don't think the labels can ever be that close
        if ((date.hours() === 0 && date.minutes() === 0) || index <= 2) {
          label += `\n${date.date()} ${monthNames[date.month()]}`;
        }
        return label;
      } else if (index === 1) {
        return `${date.date()} ${monthNames[date.month()]}`;
      }
      return '';
    };
  }

  if (interval === '10m') {
    return function(value: string, index: number) {
      const date = moment(value);
      // Calculate the interval between labels based on the available space:
      const plotWidth = getPlotWidth(chart);
      const labelWidthInPixels = 53;
      const labelInterval = getLabelInterval(
        plotWidth,
        labelWidthInPixels,
        periods.length,
        tenMinuteLabelIntervals,
      );
      // Get the beginning of the hour of the first period to pivot the label interval from:
      const pivotMoment = moment(periods[0]);
      const pivot = new Date(
        pivotMoment.year(),
        pivotMoment.month(),
        pivotMoment.date(),
        pivotMoment.hours(),
        0,
        0,
        0,
      );
      // Use the label interval and pivot to decide when to render a label:
      if (
        Math.round((date.valueOf() - pivot.getTime()) / millisecondsInTenMinutes) %
          labelInterval ===
        0
      ) {
        var label = Formatting.legacyFormatTimeForChart(date);
        // Add the date as a second line on day boundaries:
        // index <= 2 should cover all the positions of the first label but not include any second positions
        // didn't actually check this, but I don't think the labels can ever be that close
        if ((date.hours() === 0 && date.minutes() === 0) || index <= 2) {
          label += `\n${date.date()} ${monthNames[date.month()]}`;
        }
        return label;
      } else if (index === 1) {
        return `${date.date()} ${monthNames[date.month()]}`;
      }

      return '';
    };
  }

  if (interval === '1h') {
    return function(value: string) {
      const date = moment(value);
      // Calculate the interval between labels based on the available space:
      const plotWidth = getPlotWidth(chart);
      const labelWidthInPixels = 37;
      const labelInterval = getLabelInterval(
        plotWidth,
        labelWidthInPixels,
        periods.length,
        oneHourLabelIntervals,
      );
      // Get the beginning of the day of the first period to pivot the label interval from:
      const pivotMoment = moment(periods[0]);
      const pivot = new Date(
        pivotMoment.year(),
        pivotMoment.month(),
        pivotMoment.date(),
        0,
        0,
        0,
        0,
      );
      // Use the label interval and pivot to decide when to render a label:
      if (
        Math.round((date.valueOf() - pivot.getTime()) / millisecondsInOneHour) % labelInterval ===
        0
      ) {
        let label = Formatting.format(value, 'h a', 'HH:mm');
        // Add date as second line if 12am:
        if (date.hours() === 0) {
          label += `\n${date.date()} ${monthNames[date.month()]}`;
        }
        return label;
      }
      return '';
    };
  }

  if (interval === '1d') {
    return function(value: string) {
      const date = convertUtcToUsersMoment(value);
      // Calculate the interval between labels based on the available space:
      const plotWidth = getPlotWidth(chart);
      const labelWidthInPixels = 21;
      const labelInterval = getLabelInterval(
        plotWidth,
        labelWidthInPixels,
        periods.length,
        oneDayLabelIntervals,
      );
      // Use the label interval and first period to decide when to render a label:
      if (
        Math.round((date.valueOf() - moment(periods[0]).valueOf()) / millisecondsInOneDay) %
          labelInterval ===
        0
      ) {
        return `${date.date()}\n${monthNames[date.month()]}`;
      }
      return '';
    };
  }
}

export function getIntegerLabelFormatter(chart: any, suffix: string = '') {
  return function(value: any, index: number) {
    const plotHeight = getPlotHeight(chart);
    const labelHeightInPixels = 9;
    const labelCount = 6; // Estimate based on eCharts default splitNumber
    const labelInterval = getLabelInterval(plotHeight, labelHeightInPixels, labelCount, [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
    ]);

    if (index % labelInterval === 0 && Math.round(value) === value) {
      return `${formatAsShorthand(value)}${suffix}`;
    }

    return '';
  };
}

export function getDurationLabelFormatter(chart: any, maxUnit: number) {
  const unit = getIdealUnit(maxUnit);

  return function(value: any, index: number) {
    const plotHeight = getPlotHeight(chart);
    const labelHeightInPixels = 9;
    const labelCount = 6;
    const labelInterval = getLabelInterval(plotHeight, labelHeightInPixels, labelCount, [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
    ]);

    if (index % labelInterval === 0 && Math.round(value) === value) {
      return `${formatDuration(value, unit)}`;
    }

    return '';
  };
}

export function formatUtcTooltipDate(date: Date, interval: Intervals) {
  if (interval === '1m' || interval === '10m' || interval === '1h') {
    return Formatting.legacyFormatLongDateTime(date);
  }

  if (interval === '1d') {
    return Formatting.legacyFormatDate(date);
  }
}

export function getHistoricalDateTimeLabelFormatter(periods: string[], range: string, chart: any) {
  if (range === ONE_DAY) {
    return function(value: string) {
      const date = moment(value);
      // Calculate the interval between labels based on the available space:
      const plotWidth = getPlotWidth(chart);
      const labelWidthInPixels = 53;
      const labelInterval = getLabelInterval(
        plotWidth,
        labelWidthInPixels,
        periods.length,
        tenMinuteLabelIntervals,
      );
      // Get the beginning of the hour of the first period to pivot the label interval from:
      const pivotMoment = moment(periods[0]);
      const pivot = new Date(
        pivotMoment.year(),
        pivotMoment.month(),
        pivotMoment.date(),
        pivotMoment.hours(),
        0,
        0,
        0,
      );
      // Use the label interval and pivot to decide when to render a label:
      if (
        Math.round((date.valueOf() - pivot.getTime()) / millisecondsInTenMinutes) %
          labelInterval ===
        0
      ) {
        return Formatting.legacyFormatTimeForChart(date, false);
      }
      return '';
    };
  }

  if (range === SEVEN_DAYS) {
    return function(value: string) {
      const date = convertUtcToUsersMoment(value);

      if (date.hour() === 0) {
        return `${date.format('ddd')}`;
      }
      return '';
    };
  }
}

export function formatUtcTooltipHistorical(date: Date, range: string) {
  const userDate = convertUtcToUsersMoment(date);

  if (range === ONE_DAY) {
    return userDate.format('h:mm a');
  }

  return userDate.format('dddd, h:mm a');
}

export function formatUtcHistoricalSeriesDate(date: Date) {
  return convertUtcToUsersMoment(date).format('MMMM D');
}
