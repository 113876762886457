/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { enableSecretMode } from 'app/chrome/revamped/actions';
import { EmblemBolt, EmblemContainer, EmblemRing, EmblemShockwave } from './components';

type State = {
  spinning: boolean;
  shockwaves: number[];
};

type StateProps = {};

type DispatchProps = {
  enableSecret: () => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

let wavenumber = 0;

export class UnconnectedEmblem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.animateEmblem = this.animateEmblem.bind(this);
    this.animateEmblemEnd = this.animateEmblemEnd.bind(this);
    this.shockWaveEnd = this.shockWaveEnd.bind(this);
    this.emblemOnClick = this.emblemOnClick.bind(this);
  }

  state: State = {
    spinning: false,
    shockwaves: [],
  };

  animateEmblem() {
    this.setState((prevState: State) => {
      return {
        spinning: true,
        shockwaves: [...prevState.shockwaves, wavenumber++],
      };
    });
  }
  animateEmblemEnd() {
    this.setState((prevState: State) => {
      return {
        spinning: false,
        shockwaves: [...prevState.shockwaves],
      };
    });
  }
  shockWaveEnd() {
    this.setState((prevState: State) => {
      return {
        spinning: prevState.spinning,
        shockwaves: prevState.shockwaves.slice(1),
      };
    });
  }
  emblemOnClick() {
    this.animateEmblem();
    if (this.state.shockwaves.length > 5) {
      this.props.enableSecret();
    }
  }

  render() {
    const shockwaves = this.state.shockwaves.map(val => {
      return <EmblemShockwave key={val} onAnimationEnd={this.shockWaveEnd} />;
    });

    return (
      <EmblemContainer onClick={this.emblemOnClick}>
        <EmblemRing spinning={this.state.spinning} onAnimationEnd={this.animateEmblemEnd} />
        <EmblemBolt spinning={this.state.spinning} />
        {shockwaves}
      </EmblemContainer>
    );
  }
}

export const Emblem = connect<StateProps, DispatchProps, SuppliedProps, any>(
  null,
  dispatch => ({ enableSecret: () => dispatch(enableSecretMode()) }),
)(UnconnectedEmblem);
