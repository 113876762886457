/**
 * @prettier
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import * as ChromeActions from 'app/actions/chrome';
import { Container as PageContainer } from 'components/page/container';
import { Center } from 'components/center';
import { Header as PageHeader } from 'components/page/header';
import { Header } from '../components/header';

import { ProductPagesState } from 'sections/productpages';

type StateProps = {
  showSidebar: boolean;
};
type DispatchProps = {
  dispatch: Dispatch;
};
type Props = StateProps & DispatchProps;

class Layout extends React.Component<Props, {}> {
  componentWillMount() {
    const { dispatch } = this.props;

    dispatch(ChromeActions.showSidebar(this.props.showSidebar));
    dispatch(ChromeActions.setLayout('platform'));
    dispatch(ChromeActions.bootApp());
  }

  render() {
    return (
      <>
        <PageHeader>
          <Center width={1120}>
            <Header />
          </Center>
        </PageHeader>
        <PageContainer>
          <Center width={1120}>{this.props.children}</Center>
        </PageContainer>
      </>
    );
  }
}

const ConnectedLayoutWithSidebar = connect<{}, DispatchProps, {}>(
  () => ({
    showSidebar: true,
  }),
  (dispatch: Dispatch) => ({ dispatch }),
)(Layout);

const ConnectedLayoutWithoutSidebar = connect<{}, DispatchProps, {}>(
  () => ({
    showSidebar: false,
  }),
  (dispatch: Dispatch) => ({ dispatch }),
)(Layout);
export {
  ConnectedLayoutWithSidebar as LayoutWithSideBar,
  ConnectedLayoutWithoutSidebar as LayoutWithoutSideBar,
};
