/**
 * @prettier
 */
import * as React from 'react';

import { CrashReporting } from 'utils/linking';

import { Flex } from 'components/flex/index';
import { Padding } from 'components/layout/index';
import { Avatar } from 'components/avatar';
import { Icon, IconAfter } from 'components/icon/index';

import { ErrorsPerAppListData } from '../../../../actions';

export const ApplicationCell: React.FunctionComponent<ErrorsPerAppListData> = ({
  AvatarUrl,
  Id,
  Name,
}) => (
  <Flex align="center">
    <Padding right="20" inline>
      <Avatar size={40} url={AvatarUrl} alt="Avatar" />
    </Padding>
    <Padding inline>
      <IconAfter
        icon={
          <a href={CrashReporting.applicationById(Id)} target={'_blank'}>
            <Icon set="icomoon" type="new-window" />
          </a>
        }
      >
        <a href={CrashReporting.applicationById(Id)}>{Name}</a>
      </IconAfter>
    </Padding>
  </Flex>
);
