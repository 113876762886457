/** @format */

const host = window.document.location.host ? window.document.location.host : '';

export const isLocal = host.indexOf('raygun.local') !== -1;

export const isOffice1 = host.indexOf('app1.o') !== -1;

export const isOffice2 = host.indexOf('app2.o') !== -1;

export const isOffice3 = host.indexOf('app3.o') !== -1;

export const isOffice = isOffice1 || isOffice2 || isOffice3;

export const isBeta = host.indexOf('app.beta') !== -1;

export const isIntegrationTest = host.indexOf('test.raygun.local') !== -1;
