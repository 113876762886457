/** @format */

import { find, groupBy, includes, isNil, keys } from 'lodash';
import { createSelector } from 'reselect';

import { AppState } from 'interfaces/appState';
import { Dashboard } from 'interfaces/dashboard';
import { Application } from 'interfaces/application';
import { Plan } from 'interfaces/plan';

import { findApplicationId, getLastViewedApplicationIdentifier } from 'utils/links';
import { toIdentifier } from 'utils/linking';

import { PermissionManager, PlanPermissions } from 'modules/permissions';

import { SWITCHER_TYPE } from '../chrome/classic/switcher/components/switcher';

import * as ConfigSelectors from './configuration';
import { getPage, getPlanForPage, getPlans } from './configuration';
import * as PageDataSelectors from './pageData';
import { getCurrentApplication } from './application';
import { getAppAvatarUrl } from './configuration';

const appSelector = (state: AppState) => state.app;
const locationSelector = (state: AppState) => state.location;

const chromeSelector = createSelector(
  appSelector,
  app => app.chrome,
);

export const isAppSwitcherOpen = createSelector(
  chromeSelector,
  chrome => chrome.appSwitcherOpen,
);

export const isDashboardSwitcherOpen = createSelector(
  chromeSelector,
  chrome => chrome.isDashboardSwitcherOpen,
);

export const isSidebarOpen = createSelector(
  chromeSelector,
  chrome => chrome.isSidebarOpen,
);

export const sidebarStatus = createSelector(
  chromeSelector,
  chrome => chrome.sidebar,
);

export const getCurrentApplicationSwitcherName = createSelector(
  getCurrentApplication,
  currentApp => (!isNil(currentApp) ? currentApp.name : 'Applications'),
);

export const getCurrentApplicationSwitcherAvatar = createSelector(
  getCurrentApplication,
  getAppAvatarUrl,
  (currentApp, appAvatarUrl) =>
    !isNil(currentApp)
      ? {
          url: appAvatarUrl,
          alt: `${currentApp.name} avatar`,
        }
      : null,
);

export const getApplicationsGroupedByPlanId = createSelector(
  appSelector,
  app => groupBy(app.pageData.Applications, (app: Application) => app.planId),
);

export const getDashboardsGroupedByPlanId = createSelector(
  appSelector,
  app => groupBy(app.pageData.Dashboards, (dash: Dashboard) => dash.planId),
);

const activePlanId = createSelector(
  chromeSelector,
  chrome => chrome.appSwitcherPlanId,
);

const getPlanIdForCurrentApplication = createSelector(
  ConfigSelectors.getAppId,
  PageDataSelectors.getApplications,
  (appId, applications) => {
    if (!appId || applications.length == 0) {
      return null;
    }

    // Get current applications planId
    const app = find(applications, a => a.id === appId);

    if (app) {
      return app.planId;
    }

    return null;
  },
);

export const getAppSwitcherActivePlanId = createSelector(
  activePlanId,
  ConfigSelectors.getPlanId,
  getPlanIdForCurrentApplication,
  ConfigSelectors.getPlans,
  (appSwitcherPlanId, configPlanId, currentAppPlanId, plans) => {
    const planId = !isNil(plans[0]) ? plans[0].Id : 0;
    return appSwitcherPlanId || configPlanId || currentAppPlanId || planId;
  },
);

export const getActivePlanForSwitcher = createSelector(
  getAppSwitcherActivePlanId,
  getPlans,
  (activePlanId, plans) => {
    return find(plans, (plan: Plan) => plan.Id === activePlanId);
  },
);

export const isCurrentlyViewingADashboard = createSelector(
  locationSelector,
  location => {
    const url = location.pathname;
    return includes(url, 'tiledashboard');
  },
);

export const isCurrentlyViewingAnApplication = createSelector(
  locationSelector,
  getCurrentApplication,
  (location, app) => {
    const url = location.pathname;
    if (isNil(app)) {
      return false;
    }

    return includes(url, app.id.toString(36));
  },
);

export const hasReachedDashboardLimit = createSelector(
  getActivePlanForSwitcher,
  plan => (!isNil(plan) ? plan.HasReachedDashboardLimit : false),
);

export const isDashboardPaywallActive = (state: AppState, type: SWITCHER_TYPE) => {
  const numberOfDashboards = keys(getDashboardsGroupedByPlanId(state)).length;
  const reachedDashboardLimit = hasReachedDashboardLimit(state);
  const isDashboardSwitcher = type === SWITCHER_TYPE.Dashboards;
  return isDashboardSwitcher && reachedDashboardLimit && numberOfDashboards === 0;
};

export const showCrashRazorHeader = createSelector(
  getPage,
  page =>
    [
      'settings:application:dsymupload',
      'settings:application:jssourcemapupload',
      'settings:application:proguardupload',
      'settings:application:symbolsupload',
    ].some(p => p === page),
);

export const showRumRazorHeader = createSelector(
  getPage,
  page =>
    ['settings:application:pulse', 'settings:application:pulse:insights'].some(p => p === page),
);

export const showPlanSettingsHeader = createSelector(
  getPage,
  locationSelector,
  (page, location) =>
    ['settings:user', 'slider:plan'].some(p => p === page) && !includes(location.pathname, 'user'),
);

export const getSecondaryLinksForProduct = createSelector(
  getPage,
  showCrashRazorHeader,
  showRumRazorHeader,
  showPlanSettingsHeader,
  () => {
    const lastViewedAppIdentifier = getLastViewedApplicationIdentifier();
    return findApplicationId((window as any).RaygunConfiguration, lastViewedAppIdentifier);
  },
  (
    page,
    showCrashRazorHeader,
    showRumRazorHeader,
    showPlanSettingsHeader,
    applicationIdentifier,
  ) => {
    if (showCrashRazorHeader) {
      return [
        {
          linkText: 'dSYM',
          linkUrl: `/settings/${applicationIdentifier}/symbols`,
          isActive: page === 'settings:application:dsymupload',
        },
        {
          linkText: 'JS source map',
          linkUrl: `/settings/${applicationIdentifier}/jssymbols`,
          isActive: page === 'settings:application:jssourcemapupload',
        },
        {
          linkText: 'ProGuard',
          linkUrl: `/settings/${applicationIdentifier}/proguardsymbols`,
          isActive: page === 'settings:application:proguardupload',
        },
        {
          linkText: 'Breakpad',
          linkUrl: `/settings/${applicationIdentifier}/breakpad/symbols`,
          isActive: page === 'settings:application:symbolsupload',
        },
      ];
    } else if (showRumRazorHeader || showPlanSettingsHeader) {
      return null;
    }
  },
);

export const getCreateApplicationLink = createSelector(
  getActivePlanForSwitcher,
  plan => `/createApplication?planIdentifier=${toIdentifier(plan.Id)}`,
);

export const getCreateDashboardLink = createSelector(
  getActivePlanForSwitcher,
  plan => `/tiledashboard/${toIdentifier(plan.Id)}/create?type=0`,
);

const hasReachedAppLimitForPlan = createSelector(
  getActivePlanForSwitcher,
  activePlan => activePlan.HasReachedAppLimit,
);

const hasPermissionToCreateApplications = createSelector(
  getActivePlanForSwitcher,
  activePlan =>
    PermissionManager.can(PlanPermissions.CreateNewApplications, null, null, activePlan.Id),
);

export const hasBasicPlanForSwitcher = createSelector(
  getActivePlanForSwitcher,
  activePlan => {
    return activePlan ? activePlan.PlanLevelEnum === 'Basic' : false;
  },
);

export const canCreateApplicationsForPlan = createSelector(
  hasPermissionToCreateApplications,
  hasReachedAppLimitForPlan,
  (hasPermission, hasReachedAppLimit) => hasPermission && !hasReachedAppLimit,
);

export const getTooltipLabelForDisabledButton = createSelector(
  hasPermissionToCreateApplications,
  hasReachedAppLimitForPlan,
  (hasPermission, hasReachedAppLimit) => {
    if (!hasPermission) {
      return "You don't have permission to create an application.";
    } else if (hasReachedAppLimit) {
      return 'You have reached the application limit for this plan';
    }

    return null;
  },
);
