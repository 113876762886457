/** @format **/

import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { makeMediaQuery, screenSmallOnly } from 'fela-rules/breakpoints';
import { applyModifiers } from 'fela-rules/modifiers';

type GatedFeatureModalProps = {
  isBasicModal: boolean;
};
const StyledIconWrapper = (): IStyle => ({
  paddingRight: '8px',
});
export const IconWrapper  = createComponentWithProxy(StyledIconWrapper, 'span');

const StyledFeatureListContainer = (props: GatedFeatureModalProps): IStyle  => applyModifiers(
  [
    props.isBasicModal,
    {
      ...makeMediaQuery(
        {
          padding: '48px',
        },
        1061,
      ),
    }
  ]
)({
  ...makeMediaQuery(
    {
      padding: '24px 48px 24px 24px',
    },
    0,
    641,
  ),
  ...makeMediaQuery(
    {
      padding: '32px',
    },
    640,
    1181,
  ),
  ...makeMediaQuery(
    {
      padding: '48px',
    },
    1181,
  ),
  display: 'block',
  width: '100%',
  maxWidth: '912px',
  overflowY: 'auto',
});
export const FeatureListContainer = createComponentWithProxy(StyledFeatureListContainer);

const StyledProductDescriptionListItem = (): IStyle => ({
  lineHeight: '25px',
  listStyleType: 'none',
  display: 'flex',
  fontSize: '14px',
  ['::before' as any]: {
    content: '"\\2022"',
    fontSize: '15px',
    color: 'black',
    marginRight: '10px',
  }
});
export const ProductDescriptionListItem = createComponentWithProxy(StyledProductDescriptionListItem, 'li');

const StyledHeaderPadding = (): IStyle => ({
  ...screenSmallOnly(
    {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  ),

  paddingTop: '24px',
  paddingBottom: '24px',
});
export const HeaderPadding = createComponentWithProxy(StyledHeaderPadding);

const StyledBottomSectionContainer = (props: GatedFeatureModalProps): IStyle => applyModifiers(
  [
    props.isBasicModal,
    {
      ...makeMediaQuery(
        {
          paddingTop: '40px',
          paddingBottom: '40px',
        },
        1061,
      ),
    }
  ]
)({
  ...screenSmallOnly({
    padding: '24px',
  }),

  ...makeMediaQuery(
    {
      padding: '32px',
    },
    640,
    1181,
  ),

  // XLarge and above screens
  padding: '40px 48px 48px 48px'
});
export const BottomSectionContainer = createComponentWithProxy(StyledBottomSectionContainer);

const StyledGetFeatureText = (): IStyle => ({
  fontSize: '1.3rem',
  lineHeight: '22px',
});
export const GetFeatureText = createComponentWithProxy(StyledGetFeatureText, 'p');

const StyledUpgradeButtonWrapper = (): IStyle => ({
  ...makeMediaQuery(
    {
      paddingTop: '16px',
    },
    0,
    1181,
  ),
  ...makeMediaQuery(
    {
      paddingTop: '24px',
    },
    1181
  ),
  paddingBottom: '24px',
});
export const UpgradeButtonWrapper = createComponentWithProxy(StyledUpgradeButtonWrapper);

const StyledModalImagesContainer = (): IStyle => ({
  ...makeMediaQuery(
    {
      display: 'flex',
    },
    844,
    1181,
  ),
  height: '100%',
  backgroundImage: 'url("/Content/Images/product-screenshots/feature-gating/feature-gating-backdrop.png")',
  backgroundSize: 'cover',
});
export const ModalImagesContainer = createComponentWithProxy(StyledModalImagesContainer);

const StyledModalImage = (): IStyle => ({
  ...makeMediaQuery(
    {
      height: '555.15px',
      width:' 389.97px',
      alignSelf: 'center',
    },
    844,
    1181,
  ),
});
export const ModalImage = createComponentWithProxy(StyledModalImage, 'img');