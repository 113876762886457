/**
 * @prettier
 */

import React from 'react';
import { isEqual, first, last, isNil, isEmpty } from 'lodash';

const RECHECK_DELAY = 150;

type Props = {
  children: (
    onMouseOver: React.MouseEventHandler<any>,
    onMouseLeave: React.MouseEventHandler<any>,
    hovered: boolean,
    hoverMenuRef: (instance: any) => void,
  ) => JSX.Element;
};
type State = {
  hovered: boolean;
};

type Point2D = {
  x: number;
  y: number;
};

function slope(a: Point2D, b: Point2D) {
  return (b.y - a.y) / (b.x - a.x);
}

// Inspired by Ben Kamens jQuery-menu-aim plugin
// https://github.com/kamens/jQuery-menu-aim/blob/3ee5ae67f99be92a2280ae96bcfd0b8e999c2222/jquery.menu-aim.js#L266
export class SlopeHover extends React.Component<Props, State> {
  lastMouseLocations: Point2D[] = [];
  hoverMenuRef: HTMLElement;
  timeoutId: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      hovered: false,
    };

    this.possiblyHoverOff = this.possiblyHoverOff.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.getHoverOffDelay = this.getHoverOffDelay.bind(this);
  }

  onMouseOver() {
    this.setState({ hovered: true });
  }

  possiblyHoverOff() {
    const delay = this.getHoverOffDelay();

    if (delay) {
      this.timeoutId = setTimeout(() => this.possiblyHoverOff(), delay) as any;
    } else {
      this.setState({ hovered: false });
    }
  }

  onMouseMove(e: MouseEvent) {
    this.lastMouseLocations.push({ x: e.pageX, y: e.pageY });

    // Keep 4 so we can take a slightly further away mouse position when we take first and last
    // This is just to hedge against floating point errors in a slow moving mouse
    if (this.lastMouseLocations.length > 4) {
      this.lastMouseLocations.shift();
    }
  }

  getHoverOffDelay() {
    const locs = this.lastMouseLocations;
    const ref = this.hoverMenuRef;

    if (isNil(ref) || isEmpty(locs)) {
      return 0;
    }

    const oldestLoc = first(locs);
    const loc = last(locs);

    const upperLeft: Point2D = {
      x: ref.offsetLeft,
      y: ref.offsetTop,
    };
    const upperRight: Point2D = {
      x: ref.offsetLeft + ref.offsetWidth,
      y: ref.offsetTop,
    };
    const lowerLeft = {
      x: ref.offsetLeft,
      y: ref.offsetTop + ref.offsetHeight,
    };
    const lowerRight = {
      x: ref.offsetLeft + ref.offsetWidth,
      y: ref.offsetTop + ref.offsetHeight,
    };

    if (isEqual(oldestLoc, loc) || loc.x > upperRight.x || loc.y > lowerRight.y) {
      return 0;
    }

    if (
      loc.x > upperLeft.x &&
      loc.x < upperRight.x &&
      loc.y > upperRight.y &&
      loc.y < lowerRight.y
    ) {
      return RECHECK_DELAY;
    }

    const decreasingSlope = slope(loc, upperLeft);
    const increasingSlope = slope(loc, lowerLeft);
    const prevDecreasingSlope = slope(oldestLoc, upperLeft);
    const prevIncreasingSlope = slope(oldestLoc, lowerLeft);

    if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
      return RECHECK_DELAY;
    }

    return 0;
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);

    clearTimeout(this.timeoutId);
  }

  render() {
    return this.props.children(
      this.onMouseOver,
      this.possiblyHoverOff,
      this.state.hovered,
      ref => (this.hoverMenuRef = ref),
    );
  }
}
