/** @format **/

import { GRAPH_COLORS as gc } from './colors';

export enum PALETTE_TYPES {
  Categorical,
  Sequential,
  TrafficLight,
}

type PaletteType = {
  [key: number]: string[];
};

export const categorical: PaletteType = {
  1: [gc.cyan500],
  2: [gc.cyan500, gc.purpleDA700],
  3: [gc.cyan500, gc.purpleDA700, gc.purple400],
  4: [gc.cyan500, gc.purpleDA700, gc.purple400, gc.pink300],
  5: [gc.cyan500, gc.blueRG1000, gc.purpleDA700, gc.purple400, gc.pink300],
  6: [gc.cyan500, gc.blueLA100, gc.blueRG1000, gc.purpleDA700, gc.purple400, gc.pink300],
  7: [
    gc.cyan500,
    gc.blueLA100,
    gc.blueRG1000,
    gc.purpleDA700,
    gc.purple400,
    gc.pink300,
    gc.pink100,
  ],
  8: [
    gc.cyan800,
    gc.cyan500,
    gc.blueLA100,
    gc.blueRG1000,
    gc.purpleDA700,
    gc.purple400,
    gc.pink300,
    gc.pink100,
  ],
};

export const sequential: PaletteType = {
  2: [gc.indigo600, gc.blueL600],
  3: [gc.indigo600, gc.blueL600, gc.cyan300],
  4: [gc.purpleD700, gc.indigo600, gc.blueL600, gc.cyan300],
  5: [gc.purpleD700, gc.indigo600, gc.blueRG1200, gc.blueL400, gc.cyan300],
  6: [gc.purpleD700, gc.indigo600, gc.blueRG1200, gc.blueL400, gc.cyan300, gc.cyan200],
  7: [gc.purpleD700, gc.indigo600, gc.blueRG1200, gc.blueL600, gc.blueL400, gc.cyan300, gc.cyan200],
  8: [
    gc.purpleD700,
    gc.indigo600,
    gc.blueRG1200,
    gc.blueRG1100,
    gc.blueL600,
    gc.blueL400,
    gc.cyan300,
    gc.cyan200,
  ],
};

export const trafficLight: PaletteType = {
  2: [gc.green500, gc.red500],
  3: [gc.green500, gc.amber300, gc.red500],
  4: [gc.green500, gc.greenL500, gc.amber300, gc.red500],
  5: [gc.green500, gc.greenL500, gc.amber300, gc.orange400, gc.red500],
  6: [gc.green500, gc.greenL500, gc.lime400, gc.amber300, gc.orange400, gc.red500],
  7: [gc.green500, gc.greenL500, gc.lime400, gc.yellow300, gc.amber300, gc.orange400, gc.red500],
  8: [
    gc.green500,
    gc.greenL500,
    gc.lime400,
    gc.yellow300,
    gc.amber300,
    gc.orange400,
    gc.orangeD400,
    gc.red500,
  ],
};
