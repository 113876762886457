/** @format */

export type Dimension = {
  id: string;
  displayName: string;
  isBoolean?: boolean;
  isHidden?: boolean;
  autoApplyValue?: boolean;
};

export type DimensionWithComparators = Dimension & {
  availableComparators: Comparator[];
};

export type Comparator = {
  id: string;
  displayName: string;
  multiSelect?: boolean;
  allowFreeText?: boolean;
};

export type Filter = {
  id?: string;
  dimension: Dimension;
  comparator: Comparator;
  values: FilterValue[];
  isAdvancedSearch?: boolean;
};

export type FilterValue = {
  id?: string;
  displayName: string;
};

export enum Mode {
  Inactive,
  SelectDimension,
  SelectComparator,
  SelectValue,
}

export function dimensionWithComparatorsToDimension(
  dimension: DimensionWithComparators,
): Dimension {
  return {
    displayName: dimension.displayName,
    id: dimension.id,
    isBoolean: dimension.isBoolean,
  };
}

type ServerDimension = {
  Id: string,
  DisplayName: string;
  IsBoolean: boolean;
};

type ServerComparator = {
  Id: string;
  DisplayName: string;
  MultiSelect: boolean;
  AllowFreeText: boolean;
};

export type ServerTopLevelFilter = {
  Dimension: ServerDimension;
  Comparator: ServerComparator;
  Values: string[];
};