/** @format */

import React from 'react';
import { omit } from 'lodash';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { Margin } from 'components/layout';
import { Icon } from 'components/icon';

type SubtleDropdownButtonProps = {
  disabled?: boolean;
};

export const SubtleDropdownButton: React.FunctionComponent<SubtleDropdownButtonProps> = ({
  children,
  ...props
}) => (
  <DropdownButtonWrapper {...omit(props, ['fullWidth', 'forDropdownTrigger'])}>
    {children}
    <Margin left="4">
      <Icon set="icomoon" type="dropdown" size={16} />
    </Margin>
  </DropdownButtonWrapper>
);

SubtleDropdownButton.defaultProps = {};

const StyledDropdownButtonWrapper = (props: SubtleDropdownButtonProps & ThemeType): IStyle =>
  applyModifiers([
    props.disabled,
    {
      color: props.theme.ds.getColorByName('grey300'),
      cursor: 'none',
      userSelect: 'none',
    },
  ])({
    display: 'inline-flex',
    lineHeight: '1.6rem',
    fontSize: '1.4rem',
    fontWeight: 600,
    color: props.theme.ds.getColorByName('grey800'),
    fontFamily: props.theme.ds.fonts.standard,
    cursor: 'pointer',
    [':hover']: {
      color: props.theme.ds.getColorByName('blue1300'),
      outline: '0',
    },
    [':focus']: {
      color: props.theme.ds.getColorByName('grey800'),
    },
  });

const DropdownButtonWrapper = createComponentWithProxy(StyledDropdownButtonWrapper);
