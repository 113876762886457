/**
 * @prettier
 */

import React from 'react';

import { FormLayout, FormLayoutInput, FormLayoutLabel } from 'components/input';
import { OPTION, Select } from 'components/select';

import { Constants } from 'modules/dashboard';

type Props = {
  resourceSetting: string;
  updateResourceSetting: (range: string) => void;
};

export const ResourceSelector: React.FunctionComponent<Props> = (props: Props) => {
  const resourceOptions: OPTION[] = Constants.RESOURCES.map(r => ({
    text: r.display,
    value: r.value,
  }));

  return (
    <FormLayout marginBottom>
      <FormLayoutLabel>
        <label htmlFor="resource">Resource</label>
      </FormLayoutLabel>
      <FormLayoutInput>
        <Select
          id="resource"
          options={resourceOptions}
          value={props.resourceSetting}
          onChange={props.updateResourceSetting}
        />
      </FormLayoutInput>
    </FormLayout>
  );
};
