/**
 * @prettier
 */

import React from 'react';

import { FormLayout, FormLayoutInput, FormLayoutLabel } from 'components/input';
import { OPTION, Select } from 'components/select';
import { ToggleButtonGroup, ToggleButtonIcon } from 'components/ds/button';
import { Flex } from 'components/flex';
import { Margin } from 'components/layout';

import * as Models from 'modules/dashboard/models';

type Props = Models.TextSettings & {
  showTextColor?: boolean;
  updateTextSetting: (key: keyof Models.TextSettings, textSettings: string) => void;
};

const TEXT_SIZE_OPTIONS: OPTION[] = [
  {
    text: '14px',
    value: '14px',
  },
  {
    text: '20px',
    value: '20px',
  },
  {
    text: '26px',
    value: '26px',
  },
];

export const TextStyles: React.FunctionComponent<Props> = (props: Props) => {
  const {
    TextBold,
    TextItalic,
    TextUnderline,
    TextSize,
    TextAlignment,
    TextColor,
    updateTextSetting,
    showTextColor,
  } = props;

  return (
    <Flex direction="row" align="center">
      <Margin right="24">
        <FormLayout marginBottom>
          <FormLayoutLabel>Text size</FormLayoutLabel>
          <FormLayoutInput>
            <Select
              value={TextSize}
              options={TEXT_SIZE_OPTIONS}
              size={'40'}
              onChange={val => updateTextSetting('TextSize', val)}
            />
          </FormLayoutInput>
        </FormLayout>
      </Margin>
      <Margin right="24">
        <FormLayout marginBottom>
          <FormLayoutLabel>Text style</FormLayoutLabel>
          <Flex align="center" direction="row">
            <Margin right="8">
              <ToggleButtonGroup>
                <ToggleButtonIcon
                  icon="bold"
                  onClick={() =>
                    updateTextSetting(
                      'TextBold',
                      Models.isTextBold(TextBold) ? '' : Models.TextBold,
                    )
                  }
                  selected={Models.isTextBold(TextBold)}
                />
              </ToggleButtonGroup>
            </Margin>
            <Margin right="8">
              <ToggleButtonGroup>
                <ToggleButtonIcon
                  icon="italic"
                  onClick={() =>
                    updateTextSetting(
                      'TextItalic',
                      Models.isTextItalic(TextItalic) ? '' : Models.TextItalic,
                    )
                  }
                  selected={Models.isTextItalic(TextItalic)}
                />
              </ToggleButtonGroup>
            </Margin>
            <ToggleButtonGroup>
              <ToggleButtonIcon
                icon="underline"
                onClick={() =>
                  updateTextSetting(
                    'TextUnderline',
                    Models.isTextUnderline(TextUnderline) ? '' : Models.TextUnderline,
                  )
                }
                selected={Models.isTextUnderline(TextUnderline)}
              />
            </ToggleButtonGroup>
          </Flex>
        </FormLayout>
      </Margin>
      <FormLayout marginBottom>
        <FormLayoutLabel>Text alignment</FormLayoutLabel>
        <FormLayoutInput>
          <ToggleButtonGroup>
            <ToggleButtonIcon
              icon="left-align"
              selected={TextAlignment === Models.TextAlignment.LEFT}
              onClick={() => updateTextSetting('TextAlignment', Models.TextAlignment.LEFT)}
            />
            <ToggleButtonIcon
              icon="center-align"
              selected={TextAlignment === Models.TextAlignment.CENTER}
              onClick={() => updateTextSetting('TextAlignment', Models.TextAlignment.CENTER)}
            />
            <ToggleButtonIcon
              icon="right-align"
              selected={TextAlignment === Models.TextAlignment.RIGHT}
              onClick={() => updateTextSetting('TextAlignment', Models.TextAlignment.RIGHT)}
            />
          </ToggleButtonGroup>
        </FormLayoutInput>
      </FormLayout>
      {showTextColor && (
        <Margin left="24">
          <FormLayout marginBottom>
            <FormLayoutLabel>Text color</FormLayoutLabel>
            <FormLayoutInput>
              <Flex direction="row" align="center">
                <Margin right="16">
                  <input
                    type="color"
                    className="form-color form-color--40"
                    value={TextColor}
                    onChange={e => updateTextSetting('TextColor', e.target.value)}
                  />
                </Margin>
              </Flex>
            </FormLayoutInput>
          </FormLayout>
        </Margin>
      )}
    </Flex>
  );
};
