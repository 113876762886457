/** @format */

import React from 'react';

import { Formatting } from 'utils/date';
import { DateInput } from 'utils/types';

import { DateFormat } from '../../models';

type SuppliedProps = {
  children: DateInput;
  format?: DateFormat;
  dateFormat?: string;
  omitSuffix?: boolean;
};
type Props = SuppliedProps;

export const DateFormatter: React.FunctionComponent<Props> = ({
  children,
  format,
  dateFormat,
  omitSuffix,
}) => {
  switch (format) {
    case 'date-time':
      return <>{Formatting.legacyFormatMediumDateTime(children)}</>;
    case 'date':
      return <>{Formatting.legacyFormatDate(children)}</>;
    case 'time-ago':
      return <>{Formatting.formatAsTimeAgo(children, omitSuffix)}</>;
    case 'custom':
      return <>{Formatting.format(children, dateFormat)}</>;
    case 'duration':
      return <>{Formatting.formatAsDuration(children)}</>;
  }
};

DateFormatter.defaultProps = {
  format: 'date',
};
