/** @format **/

import React from 'react';

import { Features, isFeatureEnabled } from '../../models';

type Props = {
  feature: Features;
  children: React.ReactElement;
  negate?: boolean;
};

export const FeatureCheck: React.FunctionComponent<Props> = ({ feature, children, negate }) => {
  if (negate !== isFeatureEnabled(feature)) {
    return <>{children}</>;
  }

  return null;
};

FeatureCheck.defaultProps = {
  negate: false,
};
