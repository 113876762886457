/** @format **/

import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';

import { TotalListHeight } from '../links';

const StyledSidebarWrapper = (props: ThemeType): IStyle => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 101,
  height: '100%',
  width: '64px',
  background: props.theme.ds.getColorByName('blue1700'),
  [`@media (max-height: ${TotalListHeight}px)`]: {
    overflowY: 'auto',
    overflowX: 'hidden',
    ['&::-webkit-scrollbar']: {
      width: '0px',
      backgroundColor: 'transparent',
      transition: 'width 150ms linear',
    },
    ['&::-webkit-scrollbar-thumb']: {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
      borderRadius: '2px',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    ':hover': {
      ['&::-webkit-scrollbar']: {
        width: '4px',
      },
    },
  },
});

export const SidebarWrapper = createComponent(StyledSidebarWrapper, 'nav');

const StyledListWrapper = (): IStyle => ({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative'
});

export const ListWrapper = createComponent(StyledListWrapper);

const StyledEmblemWrapper = (): IStyle => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

export const EmblemWrapper = createComponent(StyledEmblemWrapper);
