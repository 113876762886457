/** @format */
/* tslint:disable:max-file-line-count */

import * as React from 'react';
import { ToplevelFiltersState } from '../state';
import * as Models from '../models';
import * as Selectors from '../selectors';
import * as Actions from '../actions';
import { SearchResult } from 'interfaces/search';
import { SearchDropdownContainerProps } from 'components/dropdown/search/models';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { head, get, debounce, assign } from 'lodash';
import { uuid } from 'utils/generators';
import { AddFilterComponent } from './addFilterComponent';
import { FilterValue } from '../models';

type DispatchProps = {
  addFilter: (filter: Models.Filter) => void;
  updateAddFilterState: (state: Actions.UpdateAddFilterStatePayload) => void;
  updateEditFilterState: (state: Actions.UpdateEditFilterStatePayload) => void;
  addValuesForDimension: (dimension: string, values: FilterValue[]) => void;
  fetchDimensionValues: (ignoreQueryText?: boolean) => void;
};
type StateProps = {
  availableDimensions: Models.DimensionWithComparators[];
  values: Models.FilterValue[];
  valuesLoading: boolean;
  selectedDimension: Models.DimensionWithComparators;
  selectedComparator: Models.Comparator;
  currentQuery: string;
  currentMode: Models.Mode;
  containerHeight: 32 | 48;
};
type SuppliedProps = {
  addFilterButtonText: string;
  transformValue?: (value: Models.FilterValue, dimension?: Models.Dimension) => Models.FilterValue;
  handleAdvancedSearch: (query: string) => void;
  supportAdvancedSearch: boolean;
  valuesLimit?: number;
  showNoResultsText: boolean;
};
type Props = DispatchProps & StateProps & SuppliedProps;

type State = {
  isOpen: boolean;
  searchInputHasFocus: boolean;
};

class UnconnectedAddFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.reset = this.reset.bind(this);
    this.handleAddFilterClick = this.handleAddFilterClick.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handleAddFilterKeyDown = this.handleAddFilterKeyDown.bind(this);
    this.handleCloseDropdown = this.handleCloseDropdown.bind(this);
    this.handleOpenDropdown = this.handleOpenDropdown.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSearchInputFocusChange = this.handleSearchInputFocusChange.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.getSearchPool = this.getSearchPool.bind(this);
  }

  public static defaultProps: Partial<Props> = {
    transformValue: (value: Models.FilterValue) => value,
    valuesLimit: 5,
  };

  private KEYBOARD_SHORTCUT_OPEN = 'ctrl+shift+f';
  private KEYBOARD_SHORTCUT_CLOSE = 'esc';

  state: State = {
    isOpen: false,
    searchInputHasFocus: true,
  };

  componentDidMount() {
    if ((window as any).Mousetrap) {
      const Mousetrap = (window as any).Mousetrap;
      Mousetrap.bind([this.KEYBOARD_SHORTCUT_OPEN], () => {
        this.handleAddFilterClick();
        this.handleOpenDropdown();
      });
      Mousetrap.bind(this.KEYBOARD_SHORTCUT_CLOSE, () => {
        this.closeDropdown();
      });
    }
  }

  componentWillUnmount() {
    if ((window as any).Mousetrap) {
      const Mousetrap = (window as any).Mousetrap;
      Mousetrap.unbind([this.KEYBOARD_SHORTCUT_OPEN, this.KEYBOARD_SHORTCUT_CLOSE]);
    }
  }

  reset(close: boolean) {
    this.props.updateAddFilterState({
      currentMode: close ? Models.Mode.Inactive : Models.Mode.SelectDimension,
      selectedComparatorId: null,
      selectedDimensionId: null,
      currentQuery: '',
    });
  }

  handleAddFilterClick() {
    this.props.updateAddFilterState({ currentMode: Models.Mode.SelectDimension });
  }

  handleResultSelect(values: SearchResult[]) {
    switch (this.props.currentMode) {
      case Models.Mode.SelectDimension:
        const dimensionId = head(values).id;

        if (head(values).isBoolean) {
          // Skip to the last step if is a boolean type dimension
          this.props.updateAddFilterState({
            currentMode: Models.Mode.SelectValue,
            selectedDimensionId: dimensionId,
            selectedComparatorId: 'eq', // equals
          });
        } else {
          this.props.updateAddFilterState({
            currentMode: Models.Mode.SelectComparator,
            selectedDimensionId: dimensionId,
          });
        }
        this.props.fetchDimensionValues(true);
        break;
      case Models.Mode.SelectComparator:
        this.props.updateAddFilterState({
          currentMode: Models.Mode.SelectValue,
          selectedComparatorId: head(values).id,
        });
        break;
      case Models.Mode.SelectValue:
        this.props.addFilter({
          comparator: this.props.selectedComparator,
          dimension: Models.dimensionWithComparatorsToDimension(this.props.selectedDimension),
          values: values,
        });

        this.reset(true);
        break;
    }

    this.props.updateAddFilterState({ currentQuery: '' });
  }

  handleQuery(currentQuery: string) {
    this.props.updateAddFilterState({ currentQuery });
    if (this.props.currentMode === Models.Mode.SelectValue) {
      this.props.fetchDimensionValues();
    }
  }

  handleAddFilterKeyDown() {
    this.props.updateAddFilterState({ currentMode: Models.Mode.SelectDimension });
  }

  closeDropdown() {
    this.reset(true);
    this.setState({ isOpen: false });
  }

  handleCloseDropdown() {
    if (this.state.isOpen) {
      this.closeDropdown();
    }
  }

  handleOpenDropdown() {
    this.setState({ isOpen: true });
  }

  handleCancel() {
    this.reset(true);
  }

  handleSearchInputFocusChange(hasFocus: boolean) {
    this.setState({ searchInputHasFocus: hasFocus });
  }

  handleEnterKey(_query: string) {
    if (this.props.supportAdvancedSearch && this.state.searchInputHasFocus) {
      this.props.handleAdvancedSearch(_query);
      const searchMatches = this.getSearchPool().filter(
        q => q.displayName.toLowerCase().indexOf(_query.toLowerCase()) !== -1,
      );

      // Add a new filter: if there is a query and there are no matching search results
      if (_query.length > 0 && searchMatches.length === 0) {
        const advancedSearchFilter: Models.Filter = {
          id: uuid(),
          dimension: {
            id: 'advancedSearch',
            displayName: null,
            isHidden: true,
          },
          comparator: {
            id: 'c',
            displayName: null,
            allowFreeText: true,
          },
          values: [
            {
              id: _query,
              displayName: _query,
            },
          ],
          isAdvancedSearch: true,
        };
        this.props.addFilter(advancedSearchFilter);
        // TODO: As far as I can tell this would cause a bug, but I'm not fixing now
        this.props.addValuesForDimension('advancedSearch', [_query] as any);
        this.reset(false);
      }
    }
  }

  getSearchPool(): SearchResult[] {
    switch (this.props.currentMode) {
      case Models.Mode.SelectDimension:
        return this.props.availableDimensions
          .filter(d => d.isHidden !== true)
          .map(dimension => ({
            id: dimension.id,
            displayName: dimension.displayName,
            isBoolean: dimension.isBoolean,
          }));
      case Models.Mode.SelectComparator:
        return this.props.selectedDimension.availableComparators.map(comparator => ({
          id: comparator.id,
          displayName: comparator.displayName,
        }));
      case Models.Mode.SelectValue:
        return this.props.values.map(value => {
          const transformedValue = this.props.transformValue(value, this.props.selectedDimension);
          return {
            id: transformedValue.id,
            displayName: transformedValue.displayName,
          };
        });
    }
  }

  render() {
    const searchDropdownProps: SearchDropdownContainerProps = {
      showLoading: false,
      searchPool: this.getSearchPool(),
      button: <div>Add filter</div>,
      handleQueryChange: this.handleQuery,
      handleResultSelect: (values: SearchResult[]) => ({ values }),
      handleCancel: this.handleCancel,
      handleClose: this.handleCloseDropdown,
      handleOpen: this.handleOpenDropdown,
      handleEnterKey: this.handleEnterKey,
      openDropdown: this.props.currentMode !== Models.Mode.Inactive,
      loadingData: this.props.valuesLoading,
      query: this.props.currentQuery,
      handleSearchInputFocusChange: this.handleSearchInputFocusChange,
      enableKeyboardControl: true,
      autoApplyValue: get(this.props.selectedDimension, 'autoApplyValue', false),
      limit: 100,
    };
    const addFilterComponentProps = assign({}, this.props, {
      handleAddFilterClick: this.handleAddFilterClick,
      handleAddFilterKeyDown: this.handleAddFilterKeyDown,
      handleResultSelect: this.handleResultSelect,
      searchDropdownProps,
    });
    return <AddFilterComponent {...addFilterComponentProps} />;
  }
}

const AddFilterContainer = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: ToplevelFiltersState): StateProps => ({
    availableDimensions: Selectors.getDimensions(state),
    values: Selectors.getCurrentValues(state),
    valuesLoading: Selectors.areValuesLoading(state),
    selectedDimension: Selectors.getActiveDimension(state),
    selectedComparator: Selectors.getActiveComparator(state),
    currentQuery: Selectors.getAddFilterState(state).currentQuery,
    currentMode: Selectors.getAddFilterState(state).currentMode,
    containerHeight: Selectors.getExternalConfiguration(state).containerHeight,
  }),
  (dispatch: Dispatch) => ({
    addFilter: (filter: Models.Filter) => dispatch(Actions.addFilter(filter)),
    updateAddFilterState: (state: Actions.UpdateAddFilterStatePayload) =>
      dispatch(Actions.updateAddFilterState(state)),
    updateEditFilterState: (state: Actions.UpdateEditFilterStatePayload) =>
      dispatch(Actions.updateEditFilterState(state)),
    addValuesForDimension: (dimension: string, values: Models.FilterValue[]) =>
      dispatch(Actions.addValuesForDimension({ dimension, values })),
    fetchDimensionValues: debounce(
      (ignoreQueryText?: boolean) => dispatch(Actions.fetchDimensionValues(ignoreQueryText) as any) as any,
      250,
      {
        leading: true,
      },
    ),
  }),
)(UnconnectedAddFilter);

export { Props, AddFilterContainer as AddFilter };