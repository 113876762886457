/** @format */

import { round } from 'lodash';

export const formatNumber = (value: number, precision = 0): string => {
  return round(value, precision).toLocaleString();
};

export function getFormatNumber(precision = 0): (value: number) => string {
  return function formatNumberWrapper(value) {
    return formatNumber(value, precision);
  };
}

/**
 * Determine whether the current value is at the natural limit of being shortened
 * i.e., value is between:
 * - 999,999.5 -> 999,999.9 (1M instead of 1000K)
 * - 999,999,999.5 -> 999,999,999.9 (1B instead of 1000M)
 * @param value
 */
function isAtShorthandLimit(value: number): boolean {
  return value % 10 !== 0 && (1e6 - value <= 0.5 || 1e9 - value <= 0.5);
}

export const formatAsShorthand = (value: number, decimalPlaces: number = 0): string => {
  let negateFactor = 1;
  let initialRounding = decimalPlaces;

  if (decimalPlaces > 0 && isAtShorthandLimit(value)) {
    initialRounding = 0;
  }

  value = round(value, initialRounding);

  if (value < 0) {
    negateFactor = -1;
    value = Math.abs(value);
  }

  if (value < 1000) {
    return round(value * negateFactor, decimalPlaces).toString();
  }

  const exp = Math.floor(Math.log(value) / Math.log(1000));
  const suffix = 'KMB'[exp - 1];
  const val = value / Math.pow(1000, exp);

  return round(val * negateFactor, decimalPlaces).toString() + suffix;
};

// This defaults the decimal places for the numbers on the histogram y-axis to 2 to
// prevent larger numbers being clipped and incorrectly formatted
const histogramYAxisDecimalPlaces = 2;
export const formatAsHistogramShorthand = (value: number): string => {
  return formatAsShorthand(value, histogramYAxisDecimalPlaces);
};

export function formatPrice(price: number) {
  if (isNaN(price)) {
    price = 0;
  }
  return price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function formatPercentage(
  value: number,
  precision: number = 2,
  clamp: boolean = true,
): string {
  if (clamp) {
    const minValue = precision === 0 ? 1 : Math.pow(10, -precision);
    const maxValue = 100 - minValue;

    if (value >= 100) {
      return '100%';
    } else if (value <= 0) {
      return '0%';
    } else if (value > maxValue) {
      return `> ${maxValue}%`;
    } else if (value < minValue) {
      return `< ${minValue}%`;
    }
  }

  const result = round(value, precision);

  return `${result.toString()}%`;
}
