/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */
import * as React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy, createComponent } from 'react-fela';

import { ThemeType } from 'app/theme';
import { COLORS } from 'app/theme/materialDS';

type PublicBaseTrackProps = {
  backgroundColor: COLORS;
};

type BaseTrackProps = PublicBaseTrackProps;

const StyledBaseTrack = (props: BaseTrackProps & ThemeType): IStyle => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '4px',
  borderRadius: '2px',
  backgroundColor: props.theme.ds.getColorByName(props.backgroundColor),
});

const FelaBaseTrack = createComponentWithProxy<BaseTrackProps>(StyledBaseTrack, 'div');

const FelaBaseTrackItem = createComponent({ margin: '4px' }, 'div');

class BaseTrack extends React.Component<PublicBaseTrackProps> {
  render() {
    const { children, ...props } = this.props;

    const childrenWithMargin = React.Children.map(children, (child: React.ReactNode) => (
      <FelaBaseTrackItem>{child}</FelaBaseTrackItem>
    ));

    return <FelaBaseTrack {...props}>{childrenWithMargin}</FelaBaseTrack>;
  }
}

export { BaseTrack, PublicBaseTrackProps as BaseTrackProps };
