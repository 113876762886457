/** @format */

import { isNil } from 'lodash';
const echarts = require('echarts/lib/echarts');

export const DEFAULT_GROUP_ID = 'chart-group';

export function addChartToGroup(chart: any, groupId: string = DEFAULT_GROUP_ID) {
  if (chart && !isNil(groupId)) {
    const instance = chart.getEchartsInstance();

    instance.group = groupId;

    /**
     * Disconnect & re-connect the whole chart group so that the chart just added
     * will be synced up to any existing groups. For places such as "dashboards" where
     * they can be dynamically added/removed
     */
    echarts.disconnect(groupId);
    echarts.connect(groupId);
  }
}
