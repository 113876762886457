/** @format **/

import React from 'react';

import { TextButton } from 'components/ds/button';
import { Flex, FlexBlock } from 'components/flex';
import { Padding, Margin } from 'components/layout';

import { RecipientInputRow } from '../recipientInputRow';
import Text from 'components/text';

export type Props = {
  recipients: string[];
  addRecipient: () => void;
  error: string;
  hasError: boolean;
};

export const RecipientTable: React.FunctionComponent<Props> = (props: Props) => (
  <>
    <Margin bottom={8} applyToChildren>
      <Flex>
        <FlexBlock grow={1} shrink={1}>
          <strong>Team member</strong>
        </FlexBlock>
        <FlexBlock grow={0} shrink={0} basis={'134px'}>
          <Padding left={8}>
            <strong>Access</strong>
          </Padding>
        </FlexBlock>
      </Flex>
      {props.recipients.map(id => (
        <RecipientInputRow key={id} id={id} />
      ))}
    </Margin>

    {props.hasError && (
      <Margin bottom="8">
        <Text color={'red'}>{props.error}</Text>
      </Margin>
    )}

    <Flex justify="flex-end">
      <TextButton onClick={props.addRecipient}>Add another</TextButton>
    </Flex>
  </>
);
