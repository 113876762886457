/** @format */
import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';

import { User } from 'interfaces/user';
import { UserAvatar } from 'components/ds/userAvatar';
import { getUser } from 'app/selectors/configuration';

import { MenuTitle, MenuAvatarContainer } from './items';

type StateProps = {
  user: User;
};

type DispatchProps = {};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedUserMenuItem: React.FunctionComponent<Props> = ({ user }) => (
  <MenuAvatarContainer>
    <UserAvatar user={user} big={false} />
    <MenuTitle>{user.fullName}</MenuTitle>
  </MenuAvatarContainer>
);

const ConnectedUserMenuItem = connect<StateProps, DispatchProps, PassedProps>(
  (state: AppState) => ({
    user: getUser(state)
  })
)(UnconnectedUserMenuItem);

export { ConnectedUserMenuItem as UserMenuItem };