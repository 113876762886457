/** @format **/

export enum Features {
  AuditLog = 'AuditLog',
  ApmOverviewDeployments = 'ApmOverviewDeployments',
  GranularPermissions = 'GranularPermissions',
  RumResponseStats = 'RumResponseStats',
  Teams = 'Teams',
  CrashReportingForShopify = 'CrashReportingForShopify',
}

export function getEnabledFeatures(): Features[] {
  return (window as any).RaygunConfiguration.Features
    ? ((window as any).RaygunConfiguration.Features as Features[])
    : [];
}

export function isFeatureEnabled(feature: Features) {
  return getEnabledFeatures().indexOf(feature) > -1;
}
