/** @format **/

import * as React from 'react';

import { generateCurrentPathFromId } from 'utils/links';
import { Application } from 'interfaces/application';
import { Avatar } from 'components/avatar';

import { Favourite } from './favouriteButton';
import { NoData } from './noData';

interface Props {
  apps: Application[];
}

interface ItemProps {
  app: Application;
}

const ApplicationListItem: React.FunctionComponent<ItemProps> = props => {
  return (
    <li className="context-switcher-view-list__item context-switcher-view-list__item--list">
      <a
        href={generateCurrentPathFromId(props.app.id)}
        className="context-list-item"
        tabIndex={1}
        title={props.app.name}
      >
        <div className="context-list-item__favourite">
          <Favourite
            applicationId={props.app.id}
            name={props.app.name}
            active={props.app.isFavourite}
          />
        </div>
        <div className="context-list-item__image">
          <Avatar
            size={24}
            url={props.app.avatarUrl}
            alt={`Avatar for the ${props.app.name} application`}
          />
        </div>
        <p className="context-list-item__name context-list-item__name--application">
          {props.app.name}
        </p>
      </a>
    </li>
  );
};

export const Applications: React.FunctionComponent<Props> = props => {
  const applications = props.apps.map(app => {
    return <ApplicationListItem app={app} key={app.id} />;
  });

  const appView = applications.length ? applications : <NoData text="No applications found" />;

  return <ul className="context-switcher-view-list">{appView}</ul>;
};
