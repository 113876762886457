/**
 * @prettier
 */
import * as React from 'react';

import { Theme } from 'app/theme';
import { CountChartTypes, CountOverTime } from 'components/charts';

import { RequestCountChartPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';

const Chart: React.FunctionComponent<ProviderProps> = props => {
  const data = props.data as RequestCountChartPayload;
  const chartData = data.Data.reduce((acc, dataPoint) => [...acc, dataPoint], []);

  const seriesData = {
    periods: data.Times,
    count: chartData,
    name: 'Requests',
  };

  return (
    <CountOverTime
      chartType={CountChartTypes.LINE}
      color={Theme.colors.blue.standard}
      label={'Requests'}
      data={seriesData}
      interval={data.Interval}
      height={'100%'}
      groupChart={props.groupCharts}
      allowDateRangeSelect={props.dragToZoom}
      onDashboard
    />
  );
};

const ConnectedTile = TileDataProvider(Chart, ChartTileLoading);

export { ConnectedTile as RequestCountChart };
