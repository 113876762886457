/** @format */
import React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';

import { Dashboard } from 'interfaces/dashboard';
import { AppState } from 'interfaces/appState';
import { Margin } from 'components/layout';

import { getFilteredDashboardsForPlan, getFilteredFavouriteDashboardsForPlan } from '../../../selectors';
import { DashboardRow, NoData } from '../row';
import { Favourite } from './favourite';

type StateProps = {
  dashboards: Dashboard[];
};

type DispatchProps = {};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedAllDashboards: React.FunctionComponent<Props> = ({ dashboards }) => (
  <Margin top="40" as='nav' aria-label="All dashboards">
    {map(dashboards, (d) => (
      <DashboardRow 
        key={d.id}
        {...d}
      />
    ))}
    {dashboards.length === 0 && (     
      <NoData>There are no dashboards</NoData>
    )}
  </Margin>
);

export const UnconnectedFavouriteDashboards: React.FunctionComponent<Props> = ({ dashboards }) => (
  <>
    {dashboards.length > 0 && (
      <Margin bottom="40" as='nav' aria-label="Favourite dashboards">
        <Favourite>Favorites</Favourite>
        {map(dashboards, (a) => (
          <DashboardRow 
            key={a.id}
            {...a}
          />
        ))}
      </Margin>
    )}
  </>
);

export const AllDashboards = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => ({
    dashboards: getFilteredDashboardsForPlan(state)
  })
)(UnconnectedAllDashboards);

export const FavouriteDashboards = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => ({
    dashboards: getFilteredFavouriteDashboardsForPlan(state)
  })
)(UnconnectedFavouriteDashboards);