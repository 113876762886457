/** @format */

import React from 'react';

import { Base, PublicProps, ZoomProps, GroupingProps } from '../base';

type Props = {
  options: any;
} & PublicProps &
  GroupingProps &
  ZoomProps;

export const CustomChart: React.FunctionComponent<Props> = ({ options, ...props }) => {
  return <Base {...props} options={options} series={{}} applyDefaultStyling={false} />;
};

CustomChart.defaultProps = {
  group: true,
  zoomOnDateRange: false,
};
