/**
 * @prettier
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { DashboardState } from 'sections/dashboard/index';

import {
  ModalProps,
  Modal,
  ModalHeaderWithTitle,
  ModalMessage,
  ModalBodyContent,
  ModalFooter,
} from 'components/modal/index';
import { Button } from 'components/button/index';
import { ButtonPatternCancelSubmit } from 'components/button/pattern';
import { Typography } from 'components/text/index';

import * as Models from '../../../../models';
import * as Actions from '../../../../actions';

type DispatchProps = {
  deleteErrors: (tileId: string) => void;
};

type PassedProps = {
  tileId: string;
} & ModalProps;

type Props = PassedProps & DispatchProps;

export const UnconnectedDeleteErrorsModal: React.FunctionComponent<Props> = props => {
  const close = () => props.close(props.id);

  const onSubmit = () => {
    close();
    props.deleteErrors(props.tileId);
  };

  const cancel = (
    <Button onClick={close} type="white" block size={36}>
      Abort!
    </Button>
  );

  const submit = (
    <Button onClick={onSubmit} type="red" block size={36}>
      I am sure, please blast this group
    </Button>
  );

  const footer = (
    <ModalFooter smallPadding>
      <ButtonPatternCancelSubmit submit={submit} cancel={cancel} />
    </ModalFooter>
  );

  return (
    <Modal
      header={<ModalHeaderWithTitle>Delete error groups</ModalHeaderWithTitle>}
      footer={footer}
      modalBody={{ noPadding: true }}
      {...props}
    >
      <ModalMessage color="yellow">
        <Typography>
          <h4>
            Danger! If you delete these error groups there is no way of recovering the stored data
          </h4>
        </Typography>
      </ModalMessage>
      <ModalBodyContent>
        <Typography>
          <p>
            The selected errors will be deleted immediately and will no longer be visible in your
            group list. The data associated with these errors will be shortly removed from Raygun by
            a background process.
          </p>
          <p>
            Any new error occurences receieved by Raygun after confirming this delete will cause new
            errors to be raised.
          </p>
          <p>
            <em>Note: Deleting errors does not reduce your monthly processing count</em>
          </p>
        </Typography>
      </ModalBodyContent>
    </Modal>
  );
};

const ConnectedDeleteErrorsModal = connect<null, DispatchProps, PassedProps>(
  null,
  (dispatch: Dispatch) => ({
    deleteErrors: (tileId: string) => dispatch(Actions.deleteErrors(tileId)),
  }),
)(UnconnectedDeleteErrorsModal);

export { ConnectedDeleteErrorsModal as DeleteErrorsModal };
