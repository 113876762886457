/** @format **/

import React from 'react';
import { FelaInjectedProps } from 'react-fela';

import { useHover } from 'components/ds/hooks/useHover';
import { Fade } from 'components/animated/fade';

import { LabeltipWrapper, LabeltipContainer, LabeltipLabel } from './components';

export type POSITIONS = 'top' | 'bottom' | 'left' | 'right' | 'alignWithIconRight';

type BaseProps = {
  label: string;
  position: POSITIONS;
};

export type LabelTipProps = BaseProps & FelaInjectedProps<BaseProps> & React.HTMLProps<any>;

export const Labeltip: React.FunctionComponent<LabelTipProps> = ({
  children,
  label,
  position,
  ...props
}) => {
  const { hovered, ...eventProps } = useHover();

  return (
    <LabeltipWrapper {...props}>
      <span {...eventProps}>{children}</span>

      <Fade visible={hovered} duration={400}>
        <LabeltipContainer position={position}>
          <LabeltipLabel position={position}>{label}</LabeltipLabel>
        </LabeltipContainer>
      </Fade>
    </LabeltipWrapper>
  );
};

Labeltip.defaultProps = {
  position: 'bottom',
};
