/** @format **/

import { find, isEmpty, isNil, some, filter } from 'lodash';

import { Models as ResourceModels } from 'modules/resources';
import { isFunction } from 'utils/types';
import { HTTP_METHODS, QueryParameters } from 'utils/fetching/utils';

type StaticDataResult<OS extends ResourceModels.ResourceObject> = Array<OS>;
type DynamicDataResult<OS extends ResourceModels.ResourceObject> = (
  params: QueryParameters,
  matches?: RegExpMatchArray,
) => Array<OS>;
type DataResult<OS extends ResourceModels.ResourceObject> =
  | StaticDataResult<OS>
  | DynamicDataResult<OS>;

type Pagination = {
  nextCursor: string;
  prevCursor: string;
};
type StaticPaginationResult = Pagination;
type DynamicPaginationResult<OS extends ResourceModels.ResourceObject> = (
  data: Array<OS>,
) => Pagination;
type PaginationResult<OS extends ResourceModels.ResourceObject> =
  | StaticPaginationResult
  | DynamicPaginationResult<OS>;
export type RequestMatcher<OS extends ResourceModels.ResourceObject> = {
  pathMatch: RegExp;
  data: DataResult<OS>;
  method?: HTTP_METHODS;
  status?: ResourceModels.ResponseTypes;
  errors?: ResourceModels.ResourceError[];
  pagination?: PaginationResult<OS>;
};

export let registeredMatchers: RequestMatcher<any>[] = [];

export function registerMatcher<OS extends ResourceModels.ResourceObject>(
  matcher: RequestMatcher<OS>,
) {
  if (isNil(matcher)) {
    throw new Error('Passed in a nil matcher. This could indicate a problem with your code');
  } else if (isNil(matcher.pathMatch)) {
    throw new Error('Passed in a matcher with a nil pathMatch regex. This action is not permitted');
  }

  const existingMatcher = some(
    registeredMatchers,
    m => m.pathMatch.toString() === matcher.pathMatch.toString() && m.method === matcher.method,
  );
  if (existingMatcher) {
    throw new Error(
      `Adding a matcher with an existing pathMatch regex ${matcher.pathMatch.toString()}. Check you aren't including your matchers multiple times!`,
    );
  }

  if (isNil(matcher.status)) {
    matcher.status = ResourceModels.ResponseTypes.Success;
  }
  if (isNil(matcher.errors)) {
    matcher.errors = [];
  }

  registeredMatchers.push(matcher);
}

export function clearMatchers() {
  registeredMatchers = [];
}

export function hasMatcherForPath(path: string, method: HTTP_METHODS) {
  if (isEmpty(path)) {
    return false;
  }

  return some(
    registeredMatchers,
    matcher => (isNil(matcher.method) || matcher.method === method) && matcher.pathMatch.test(path),
  );
}

export function executeMatcherForPath(
  path: string,
  parameters: QueryParameters,
  method: HTTP_METHODS,
): [
  RequestMatcher<any>['data'],
  RequestMatcher<any>['status'],
  RequestMatcher<any>['errors']?,
  RequestMatcher<any>['pagination']?,
] {
  if (isEmpty(path)) {
    throw new Error(`Invalid path passed in (${path}). It must be non null/undefined/empty`);
  }

  const matchers = filter(registeredMatchers, matcher => matcher.pathMatch.test(path));
  const matcher =
    matchers.length > 1 ? find(matchers, m => m.method === method) || matchers[0] : matchers[0];
  if (isNil(matcher)) {
    throw new Error(
      `No matcher found for path (${path}). Always check if there is a matcher first`,
    );
  }

  const matches = matcher.pathMatch.exec(path);
  const data = isFunction(matcher.data) ? matcher.data(parameters, matches) : matcher.data;
  const pagination = isFunction(matcher.pagination) ? matcher.pagination(data) : matcher.pagination;

  return [data, matcher.status, matcher.errors, pagination];
}
