/**
 * @prettier
 */

import React from 'react';
import { connect } from 'react-redux';

import { formatMillisecondsAsMultiPartDuration } from 'utils/durationFormatting';

import { DESCENDING } from 'constants/sorting';

import { Column, SortedTable } from 'components/table';
import { NumberCell } from 'components/table/cells';
import { Padding } from 'components/layout';
import { Text } from 'components/text';
import { FlexEllipse } from 'components/flex/flexEllipse';

import { TileDataProvider, Props as ProviderProps } from '../dataProvider';

import { SlowestCallPayload } from '../../../actions';
import { LoadingSlowestMethods } from '../loading';
import { SortingHoC, SortingHoCProps } from '../../sortingHoC';
import { DashboardModuleStateAtom } from 'modules/dashboard/state';
import { getApplicationsById } from 'app/selectors/application';

type Props = ProviderProps & SortingHoCProps;

type MessageCellSuppliedProps = SlowestCallPayload;
type MessageCellStateProps = { applicationName: string };
type MessageCellProps = MessageCellStateProps & MessageCellSuppliedProps;
const UnconnectedMessageCell: React.FunctionComponent<MessageCellProps> = props => {
  const link = `/apm/${props.applicationId.toString(36)}/discover/methods/${props.identifier}`;

  return (
    <>
      <Text size="medium" ellipsis>
        <a href={link} title={props.name}>
          <FlexEllipse splitOn=".">{props.name}</FlexEllipse>
        </a>
      </Text>
      <Padding top="4">
        <Text size="small" color="grey">
          {props.applicationName}
        </Text>
      </Padding>
    </>
  );
};
const MessageCell = connect<MessageCellStateProps, {}, MessageCellSuppliedProps>(
  (state: DashboardModuleStateAtom, ownProps: MessageCellSuppliedProps) => ({
    applicationName: getApplicationsById(state)[ownProps.applicationId].name,
  }),
)(UnconnectedMessageCell);

const SlowestMethods: React.FunctionComponent<Props> = props => {
  const columns: Column[] = [
    {
      header: 'Name',
      sortOptions: { sortField: 'name', defaultSortDirection: DESCENDING },
      cell: MessageCell,
      spaceLeft: true,
      headerSpaceRight: true,
    },
    {
      header: 'Average duration',
      width: 160,
      sortOptions: { sortField: 'averageDuration', defaultSortDirection: DESCENDING },
      cell: new NumberCell({
        accessor: rowItem => Math.round(rowItem.averageDuration / 1000),
        formatter: formatMillisecondsAsMultiPartDuration,
        title: rowItem =>
          `Hit count: ${rowItem.count}\nTotal duration: ${formatMillisecondsAsMultiPartDuration(
            rowItem.totalDuration / 1000,
          )}`,
      }),
    },
    {
      header: 'Total duration',
      width: 160,
      sortOptions: { sortField: 'totalDuration', defaultSortDirection: DESCENDING },
      cell: new NumberCell({
        accessor: rowItem => Math.round(rowItem.totalDuration / 1000),
        formatter: formatMillisecondsAsMultiPartDuration,
        title: rowItem =>
          `Hit count: ${rowItem.count}\nAverage duration: ${formatMillisecondsAsMultiPartDuration(
            rowItem.averageDuration / 1000,
          )}`,
      }),
    },
  ];

  return (
    <SortedTable
      columns={columns}
      data={props.data as any}
      onSortChange={props.updateSorting}
      sortField={props.sortField}
      sortDirection={props.sortDirection}
      noBorderOnLastRow
    />
  );
};

const ConnectedSlowestMethods = TileDataProvider(SortingHoC(SlowestMethods), LoadingSlowestMethods);

ConnectedSlowestMethods.moduleProps = {
  noPadding: true,
};

export { ConnectedSlowestMethods as SlowestMethods };
