/** @format **/
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { ThemeType } from 'app/theme';

const StyledFavourite = (props: ThemeType):IStyle => ({
  lineHeight: '3',
  color: props.theme.ds.getColorByName('blueGrey300'),
  fontSize: '1.3rem',
  textTransform: 'uppercase',
  fontWeight: 600,
  display: 'block'
});

export const Favourite = createComponentWithProxy(StyledFavourite);
