/** @format **/

import { includes } from 'lodash';

export const getTargetFromCookie = (cookie: string = document.cookie, target: string) => {
  return ('; ' + cookie).split('; ').filter(c => includes(c, target))[0];
};

export const getTargetValueFromCookie = (cookie: string = document.cookie, target: string) => {
  const retrievedTarget = getTargetFromCookie(cookie, target);
  return retrievedTarget && retrievedTarget.split('=')[1];
};
