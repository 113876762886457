/** @format */

import { find, keyBy, some } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from 'interfaces/appState';
import { Application } from 'interfaces/application';
import { PlanLevel } from 'interfaces/plan';

const appSelector = (state: AppState) => state.app;

const configurationSelector = createSelector(
  appSelector,
  app => app.configuration,
);

const pageDataSelector = createSelector(
  appSelector,
  app => app.pageData,
);

export const applicationIdentifier = createSelector(
  configurationSelector,
  config => config.ApplicationIdentifier,
);

export const getAppId = createSelector(
  configurationSelector,
  config => config.ApplicationId,
);

export const planIdentifier = createSelector(
  configurationSelector,
  config => config.PlanIdentifier,
);

export const planName = createSelector(
  configurationSelector,
  config => config.PlanTitle,
);

export const apiKey = createSelector(
  configurationSelector,
  config => config.ApiKey,
);

export const getAppApiKey = createSelector(
  configurationSelector,
  config => config.ApplicationApiKey,
);

export const getTaggedUsers = createSelector(
  configurationSelector,
  config => config.TagUsers,
);

export const getPlanId = createSelector(
  configurationSelector,
  config => config.PlanId,
);

export const getActivePlan = createSelector(
  configurationSelector,
  getPlanId,
  (config, planId) => find(config.Plans, plan => plan.Id === planId),
);

export const isPlanOwner = createSelector(
  configurationSelector,
  config => config.PlanOwner,
);

export const isInPlanOwnersTeam = createSelector(
  configurationSelector,
  config => config.IsInOwnersTeam,
);

export const getApplications = createSelector(
  pageDataSelector,
  pageData => pageData.Applications,
);

export const getApplicationsForCurrentPlan = createSelector(
  getPlanId,
  getApplications,
  (planId, applications) => applications.filter(a => a.planId === planId),
);

export const hasApmApplication = createSelector(
  getApplicationsForCurrentPlan,
  applications => some(applications, (application: Application) => application.apmEnabled),
);

export const getCurrentApplication = createSelector(
  getAppId,
  getApplications,
  (appId, applications) => find(applications, app => app.id === appId),
);

export const getNoAPMDataSent = createSelector(
  pageDataSelector,
  pageData => pageData.NoAPMDataSent,
);

export const getApplicationsById = createSelector(
  getApplications,
  applications => keyBy(applications, a => a.id),
);

export const getDashboards = createSelector(
  pageDataSelector,
  pageData => pageData.Dashboards,
);