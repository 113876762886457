/**
 * @prettier
 */

import { isNil } from 'lodash';

import { isString } from 'utils/types';

export function getDateRangeHeader(
  isLive: boolean,
  tileDateRange: string,
  globalDateRange: string | { dateFrom: string; dateTo: string },
) {
  const isOverriden = !isNil(globalDateRange);
  const isCustom = isOverriden && !isString(globalDateRange);

  if (isLive) {
    return 'Live';
  }

  if (!isOverriden) {
    return tileDateRange;
  }

  if (isCustom) {
    return 'Custom';
  }

  return globalDateRange;
}
