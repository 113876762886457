/** @format **/

import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

type Props = Pick<HTMLFormElement, 'action' | 'method'> & { className?: string };

export const SnowplowForm: React.FunctionComponent<Props> = ({
  children,
  className,
  ...formProps
}) => {
  const classNames = cx('sp-enable-tracking', {
    [className]: !isNil(className),
  });

  return (
    <form className={classNames} {...formProps}>
      {children}
    </form>
  );
};
