/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { loader } from 'utils/loader';
import { isNil } from 'lodash';
import { FlexibleHeightSpinner } from 'components/loading';
import { Error as ErrorView } from 'components/error';
import { DashboardModuleStateAtom, GlobalDashboardSettings } from '../../state';
import { Models, Selectors as DashboardSelectors } from 'modules/dashboard';
import { Application } from 'interfaces/application';

import * as AppSelectors from 'app/selectors/application';
import { ReactComponent } from 'utils/types';
import * as Selectors from '../../selectors';
import * as Actions from '../../actions';
import { TileData, TileState } from '../../models';
import { ErrorBoundary } from 'components/errorBoundary';

type DispatchProps = {
  updateTileState?: (tileState: Partial<TileState>) => void;
};
type StateProps = TileData &
  Partial<{
    applications: Application[];
    colors: { [appId: number]: string };
    tileState: TileState;
  }> &
  GlobalDashboardSettings;
type SuppliedProps = Partial<Models.TileProps>;
export type Props = StateProps & SuppliedProps & DispatchProps;

const TileErrored: React.FunctionComponent<Props> = ({ error }) => <ErrorView error={error} />;
const TileSubsequentLoad: React.FunctionComponent<Props> = () => (
  <FlexibleHeightSpinner smallSpinner />
);

export const TileDataProvider = (
  Tile: Models.TileComponent<Props>,
  TileLoading: ReactComponent<any> = Tile,
): Models.TileComponent => {
  const UnconnectedProvider = loader<Props>(
    props => props.loading !== false,
    props => !isNil(props.error),
  )(Tile, TileLoading, TileErrored, TileSubsequentLoad);

  const ConnectedProvider = connect<StateProps, DispatchProps, SuppliedProps>(
    (state: DashboardModuleStateAtom, props: Props) => ({
      ...Selectors.getTileData(state)[props.instanceId],
      ...Selectors.getGlobalSettings(state),
      applications: AppSelectors.getApplicationsForCurrentPlan(state),
      colors: DashboardSelectors.getCurrentColors(state),
      tileState: Selectors.getTileState(state)[props.instanceId],
    }),
    (dispatch: Dispatch, props: Props) => ({
      updateTileState: (tileState: TileState) =>
        dispatch(Actions.updateTileState({ tileId: props.instanceId, tileState })),
    }),
  )(UnconnectedProvider);

  const TileComponent: Models.TileComponent = props => (
    <ErrorBoundary>
      <ConnectedProvider {...props} />
    </ErrorBoundary>
  );
  return TileComponent;
};
