/**
 * @prettier
 */

import React from 'react';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';
import { applyModifiers } from 'fela-rules/modifiers';
import { Theme } from 'app/theme';
import { isNil } from 'lodash';

import { Props as ComponentProps } from './';

type SwitchWrapperProps = {
  inline?: boolean;
  float?: 'left' | 'right';
};

const StyledSwitchWrapper = (props: SwitchWrapperProps): IStyle =>
  applyModifiers([
    !isNil(props.inline),
    {
      display: 'inline-block',
    },
  ])({
    display: 'block',
    cursor: 'pointer',
    float: props.float,
  });

export const SwitchWrapper = createComponent(StyledSwitchWrapper, 'label');

type SwitchVisibleProps = Partial<ComponentProps>;

const StyledSwitchVisible = (props: SwitchVisibleProps): IStyle => {
  const pinWidth = props.height - props.padding * 2;
  const duration = '200ms';
  const animation = 'cubic-bezier(0.165, 0.84, 0.44, 1)';
  const transformOffset = props.width - pinWidth - props.padding * 2;

  return applyModifiers(
    [
      !isNil(props.width),
      {
        width: `${props.width}px`,
      },
    ],
    [
      !isNil(props.padding),
      {
        width: `${props.width}px`,
      },
    ],
    [
      props.isChecked,
      {
        backgroundColor: props.checkedBackgroundColor,
        ':after': {
          transform: `translate(${transformOffset}px, 0)`,
        },
      },
    ],
    [
      props.reverse,
      {
        ':after': {
          transform: `translate(${transformOffset}px, 0)`,
        },
      },
    ],
    [
      props.reverse && props.isChecked,
      {
        ':after': {
          transform: 'translate(0, 0)',
        },
      },
    ],
  )({
    display: 'block',
    position: 'relative',
    transition: `background-color ${duration} ${animation}`,
    transform: 'translate(0, 0)',
    width: `${props.width}px`,
    height: `${props.height}px`,
    padding: `${props.padding}px`,
    borderRadius: `${props.width + props.padding * 2}px`,
    backgroundColor: Theme.ds.colors.grey.grey400,
    ':after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: '#fff',
      transition: `transform ${duration} ${animation}`,
      left: `${props.padding}px`,
      width: `${pinWidth}px`,
      height: `${pinWidth}px`,
    },
  });
};

export const SwitchVisible = createComponent(StyledSwitchVisible);

const StyledSwitchCheckbox = (): IStyle => ({
  visibility: 'hidden',
  position: 'absolute',
});

export const SwitchCheckbox = createComponent(StyledSwitchCheckbox, 'input', [
  'type',
  'checked',
  'onChange',
]);

type SwitchLabelWrapperProps = {
  float?: 'left' | 'right';
};

const StyledSwitchLabelWrapper = (props: SwitchLabelWrapperProps): IStyle => ({
  float: props.float,
  ':after': {
    display: 'block',
    content: '" "',
    clear: 'both',
  },
});

export const SwitchLabelWrapper = createComponent(StyledSwitchLabelWrapper);

type SwitchLabelProps = {
  smallFontSize?: boolean;
  active?: boolean;
};

const StyledSwitchLabelLabel = (props: SwitchLabelProps): IStyle =>
  applyModifiers(
    [
      props.smallFontSize,
      {
        fontSize: '1.2rem',
      },
    ],
    [
      props.active,
      {
        color: '#666',
      },
    ],
  )({
    fontSize: '1.4rem',
    lineHeight: '24px',
    color: '#c3c3c3',
    float: 'left',
  });

export const SwitchLabelLabel = createComponent(StyledSwitchLabelLabel);

SwitchLabelLabel.defaultProps = {
  active: true,
};

type SwitchLabelSwitchProps = {
  marginLeft?: number;
  marginRight?: number;
};

const StyledSwitchLabelSwitch = (props: SwitchLabelSwitchProps): IStyle => ({
  float: 'left',
  marginLeft: `${props.marginLeft}px`,
  marginRight: `${props.marginRight}px`,
});

export const SwitchLabelSwitch = createComponent(StyledSwitchLabelSwitch);

SwitchLabelSwitch.defaultProps = {
  marginLeft: 12,
  marginRight: 12,
};
