/** @format **/

import { put, call, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import moment from 'moment';

import * as MarionetteUtils from 'utils/marionette';
import { decodeActiveArea } from 'utils/links';
import { trackLinkClick } from 'components/snowplow';

import * as Actions from '../actions';

type DateChange = {
  dateFrom: string;
  dateTo: string;
};

function createMarionetteDateChangeChannel() {
  return eventChannel<DateChange>(emitter => {
    const removeEvent = MarionetteUtils.addEventListener<DateChange>('app:update:timerange', e => {
      emitter({
        dateFrom: e.detail.dateFrom,
        dateTo: e.detail.dateTo,
      });
    });

    return () => {
      removeEvent();
    };
  });
}

export function* updateDateRangeFromMarionette() {
  const dateChangeChannel = yield call(createMarionetteDateChangeChannel);
  let initialTrigger = false;

  while (true) {
    const { dateFrom, dateTo }: DateChange = yield take(dateChangeChannel);
    let activeProduct = decodeActiveArea(window.location.pathname);
    activeProduct = activeProduct === 'crash' ? 'cr' : activeProduct;

    if (initialTrigger) {
      trackLinkClick(`sp-app-${activeProduct}-dateRangeUpdated`);
    } else {
      initialTrigger = true;
    }

    yield put(
      Actions.updateTimeRange({
        from: moment(dateFrom),
        to: moment(dateTo),
      }),
    );
  }
}
