/** @format */

import * as React from 'react';

import classnames from 'classnames';

interface Props {
  // Note: I wonder if we can figure this out by deep iterating through the children?
  // Probably not worth it tbh but still
  hasNavigation?: boolean;
  displayHeader?: boolean;
  lowerDepth?: boolean;
  transparentBg?: boolean;
}

export const Header: React.FunctionComponent<Props> = props => {
  if (!props.displayHeader) {
    return null;
  }

  const className = classnames('page-header', {
    'page-header--navigation': props.hasNavigation,
    'page-header--z-index-4': props.lowerDepth,
    'page-header--transparent-bg': props.transparentBg,
  });

  return <header className={className}>{props.children}</header>;
};

Header.defaultProps = {
  hasNavigation: false,
  displayHeader: true,
  lowerDepth: false,
  transparentBg: false,
};

export default Header;
