/** @format **/
/* tslint:disable:max-file-line-count */

import { forEach, get, has, identity, isEmpty, isFunction, isNil, map } from 'lodash';
import { Store } from 'redux';
import { Action } from 'typescript-fsa';

import { Omit } from 'utils/types';
import { SORTING_DIRECTION } from 'interfaces/sorting';

import * as Actions from '../actions';
import {
  CreateActions,
  DeleteActions,
  FetchActions,
  FilteringActions,
  PaginationActions,
  PaginationDirection,
  PatchActions,
  PerformActions,
  Resource,
  ResourceActions,
  ResourceCapabilities,
  ResourceId,
  ResourceObject,
  SortingActions,
} from './types';
import { PAGINATION_NO_MORE_RESULTS_CURSOR } from './constants';
import { ResourceContainer, ResourcesStateAtom } from '../state';

export { getNetworkErrorString, hasNetworkError } from './utils';

export * from './types';
export * from './constants';

export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Fetching,
): ResourceActions<EU, OS> & FetchActions<OS>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Patching,
): ResourceActions<EU, OS> & PatchActions<OS>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Deleting,
): ResourceActions<EU, OS> & DeleteActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Actions,
): ResourceActions<EU, OS> & PerformActions<A>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Fetching | ResourceCapabilities.Patching,
): ResourceActions<EU, OS> & PatchActions<OS> & FetchActions<OS>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Fetching | ResourceCapabilities.Deleting,
): ResourceActions<EU, OS> & FetchActions<OS> & DeleteActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Fetching | ResourceCapabilities.Pagination,
): ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Fetching | ResourceCapabilities.Filtering,
): ResourceActions<EU, OS> & FetchActions<OS> & FilteringActions<OS>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities.Fetching | ResourceCapabilities.Sorting,
): ResourceActions<EU, OS> & FetchActions<OS> & SortingActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Pagination
    | ResourceCapabilities.Sorting,
): ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions & SortingActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Pagination
    | ResourceCapabilities.Filtering,
): ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions & FilteringActions<OS>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Pagination
    | ResourceCapabilities.Filtering
    | ResourceCapabilities.Sorting,
): ResourceActions<EU, OS> &
  FetchActions<OS> &
  PaginationActions &
  FilteringActions<OS> &
  SortingActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Pagination
    | ResourceCapabilities.Sorting
    | ResourceCapabilities.Actions,
): ResourceActions<EU, OS> &
  FetchActions<OS> &
  PaginationActions &
  SortingActions &
  PerformActions<A>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Creating
    | ResourceCapabilities.Deleting,
): ResourceActions<EU, OS> & FetchActions<OS> & CreateActions<CS, OS> & DeleteActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Creating
    | ResourceCapabilities.Deleting
    | ResourceCapabilities.Actions,
): ResourceActions<EU, OS> &
  FetchActions<OS> &
  CreateActions<CS, OS> &
  DeleteActions &
  PerformActions<A>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Patching
    | ResourceCapabilities.Deleting,
): ResourceActions<EU, OS> & FetchActions<OS> & PatchActions<OS> & DeleteActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Patching
    | ResourceCapabilities.Creating,
): ResourceActions<EU, OS> & FetchActions<OS> & PatchActions<OS> & CreateActions<CS, OS>;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Pagination
    | ResourceCapabilities.Deleting,
): ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions & DeleteActions;
export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS = OS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities:
    | ResourceCapabilities.Fetching
    | ResourceCapabilities.Pagination
    | ResourceCapabilities.Patching,
): ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions & PatchActions<OS>;

export function registerResource<
  ST,
  OS extends ResourceObject,
  EU,
  RS,
  CS = Omit<OS, 'id'>,
  A extends string = any
>(
  resource: Resource<ST, OS, EU, RS, CS, A>,
  capabilities: ResourceCapabilities,
):
  | (ResourceActions<EU, OS> & FetchActions<OS>)
  | (ResourceActions<EU, OS> & PatchActions<OS>)
  | (ResourceActions<EU, OS> & DeleteActions)
  | (ResourceActions<EU, OS> & CreateActions<CS, OS>)
  | (ResourceActions<EU, OS> & PerformActions<A>)
  | (ResourceActions<EU, OS> & FetchActions<OS> & FilteringActions<OS>)
  | (ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions)
  | (ResourceActions<EU, OS> & FetchActions<OS> & SortingActions)
  | (ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions & SortingActions)
  | (ResourceActions<EU, OS> &
      FetchActions<OS> &
      PaginationActions &
      FilteringActions<OS> &
      SortingActions)
  | (ResourceActions<EU, OS> & FetchActions<OS> & PatchActions<OS>)
  | (ResourceActions<EU, OS> & FetchActions<OS> & DeleteActions)
  | (ResourceActions<EU, OS> & FetchActions<OS> & CreateActions<CS, OS>)
  | (ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions & DeleteActions)
  | (ResourceActions<EU, OS> & FetchActions<OS> & PaginationActions & PatchActions<OS>)
  | (ResourceActions<EU, OS> &
      FetchActions<OS> &
      CreateActions<CS, OS> &
      DeleteActions &
      PerformActions<A> &
      PatchActions<OS>) {
  if (has(resourceMap, resource.name)) {
    throw new Error(`Resource ${resource.name} already registered`);
  }

  resourceMap[resource.name] = resource;
  const resourceName = resource.name;
  const actions: Partial<
    ResourceActions<EU, OS> &
      FetchActions<OS> &
      PatchActions<OS> &
      CreateActions<CS, OS> &
      PerformActions<A> &
      DeleteActions &
      PaginationActions &
      SortingActions &
      FilteringActions<OS>
  > = {};

  if (capabilities & ResourceCapabilities.Fetching) {
    actions.fetch = (resourceId, completionCallback) =>
      dispatch(Actions.fetchingResourceStarted({ resourceId, resourceName, completionCallback }));
    actions.fetchList = completionCallback => {
      dispatch(Actions.resetPagination({ resourceName, resetBatchSize: false }));
      dispatch(
        Actions.fetchingListResourceStarted({
          resourceName,
          completionCallback,
          replaceResources: true,
        }),
      );
    };

    actions.isLoading = (resourceId, state) =>
      get(getResourceById(resourceName, resourceId, state), 'isFetching', false);
    actions.isListLoading = state =>
      get(getResourceFromStore(resourceName, state), 'isFetching', false);
  }

  if (capabilities & ResourceCapabilities.Patching) {
    actions.patch = (resourceId, updates, completionCallback) =>
      dispatch(
        Actions.patchingResourceStarted({ updates, resourceName, resourceId, completionCallback }),
      );
    actions.isPatching = (resourceId, state) =>
      get(getResourceById(resourceName, resourceId, state), 'isPatching', false);
  }

  if (capabilities & ResourceCapabilities.Creating) {
    actions.create = (updates, completionCallback) =>
      dispatch(Actions.postingResourceStarted({ resourceName, updates, completionCallback }));
    actions.isCreating = state =>
      get(getResourceFromStore(resourceName, state), 'isCreating', false);
  }

  if (capabilities & ResourceCapabilities.Actions) {
    actions.perform = (type, payload, completionCallback) =>
      dispatch(Actions.resourceActionStarted({ resourceName, type, payload, completionCallback }));
    actions.isPerformingAction = (type, state) =>
      get(getResourceFromStore(resourceName, state), `isPerformingAction.${type}`, false);
  }

  if (capabilities & ResourceCapabilities.Deleting) {
    actions.deleteItem = (resourceId, completionCallback) =>
      dispatch(Actions.deletingResourceStarted({ resourceName, resourceId, completionCallback }));
    actions.isDeleting = (resourceId, state) =>
      get(getResourceById(resourceName, resourceId, state), 'isDeleting', false);
  }

  if (capabilities & ResourceCapabilities.Sorting) {
    actions.sortBy = (attribute, direction) => {
      dispatch(
        Actions.updateSorting({
          resourceName,
          attribute,
          sortDirection: direction,
        }),
      );
    };
    actions.getSortDirection = state =>
      get(getResourceFromStore(resourceName, state), 'sorting.direction', null);
    actions.getSortAttribute = state =>
      get(getResourceFromStore(resourceName, state), 'sorting.attributeName', null);
  }

  if (capabilities & ResourceCapabilities.Pagination) {
    actions.setBatchSize = size =>
      dispatch(Actions.updatePaginationBatchSize({ resourceName, batchSize: size }));
    actions.loadMore = completionCallback => {
      if (actions.canLoadMore(store.getState())) {
        dispatch(
          Actions.fetchingListResourceStarted({
            resourceName,
            completionCallback,
            replaceResources: false,
          }),
        );
      }
    };
    actions.nextPage = completionCallback => {
      if (actions.canLoadMore(store.getState())) {
        dispatch(
          Actions.fetchingListResourceStarted({
            resourceName,
            completionCallback,
            replaceResources: true,
            paginationDirection: PaginationDirection.FORWARDS,
          }),
        );
      }
    };
    actions.previousPage = completionCallback => {
      if (actions.canPaginateBackwards(store.getState())) {
        dispatch(
          Actions.fetchingListResourceStarted({
            resourceName,
            completionCallback,
            replaceResources: true,
            paginationDirection: PaginationDirection.BACKWARDS,
          }),
        );
      }
    };
    actions.resetPagination = () =>
      dispatch(Actions.resetPagination({ resourceName, resetBatchSize: true }));
    actions.canLoadMore = state =>
      get(getResourceFromStore(resourceName, state), 'pagination.nextCursor', '') !==
      PAGINATION_NO_MORE_RESULTS_CURSOR;
    actions.canPaginateBackwards = state => {
      const prevCursor = get(
        getResourceFromStore(resourceName, state),
        'pagination.prevCursor',
        '',
      );
      return !isNil(prevCursor) && !isEmpty(prevCursor);
    };
    actions.getTotalNumberOfRecords = state =>
      get(getResourceFromStore(resourceName, state), 'pagination.totalRecords', null);
    actions.getBatchSize = state =>
      get(getResourceFromStore(resourceName, state), 'pagination.batchSize', null);
    actions.getNextCursor = state =>
      get(getResourceFromStore(resourceName, state), 'pagination.nextCursor', null);
  }

  if (capabilities & ResourceCapabilities.Filtering) {
    actions.updateFilters = (filters, triggerFetch = false, completionCallback) => {
      dispatch(Actions.updateFilters({ resourceName, filters }));
      if (triggerFetch) {
        dispatch(Actions.resetPagination({ resourceName, resetBatchSize: true }));
        dispatch(
          Actions.fetchingListResourceStarted({
            resourceName,
            completionCallback,
            replaceResources: true,
          }),
        );
      }
    };
    actions.getFilters = state =>
      get(getResourceFromStore(resourceName, state), 'filtering.filters', []);
  }

  const notificationAction = Actions.createNotificationSagaForResource({ resource });
  const initAction = Actions.initializeResource({
    resourceName: resource.name,
    paginated: (capabilities & ResourceCapabilities.Pagination) > 0,
    sortable: (capabilities & ResourceCapabilities.Sorting) > 0,
    filterable: (capabilities & ResourceCapabilities.Filtering) > 0,
  });
  if (isNil(store)) {
    dispatchQueue.push(notificationAction);
    dispatchQueue.push(initAction);
  } else {
    dispatch(notificationAction);
    dispatch(initAction);
  }

  const getResourceObjects = (resources: ResourceContainer['resources']) =>
    map(resources, r => ((r as any).object as unknown) as OS);
  const fullActions: typeof actions = {
    getError: (resourceId, state) =>
      get(getResourceById(resourceName, resourceId, state), 'errors', null),
    isActive: (resourceId, state) => {
      const r = getResourceById(resourceName, resourceId, state);

      if (r === null) {
        return false;
      }

      return r.isFetching || r.isPatching || r.isDeleting;
    },
    store: (resourceObject, transform = true) => {
      const transformer = getResourceByName(resourceName).transformer || identity;
      const object = transform ? transformer(resourceObject) : resourceObject;

      dispatch(Actions.storeResource({ resourceName, object }));
    },
    getGlobalError: state => get(getResourceFromStore(resourceName, state), 'errors', null),
    clear: () => dispatch(Actions.clearResources({ resourceName })),
    get: (resourceId, state) =>
      (get(getResourceById(resourceName, resourceId, state), 'object', undefined) as unknown) as OS,
    getAll: state => {
      const resourceContainer = getResourceFromStore(resourceName, state);
      const resources = get(resourceContainer, 'resources', {});
      const resourceList = getResourceObjects(resources);

      if (ResourceCapabilities.Sorting) {
        const sorting: any = get(resourceContainer, 'sorting', null);

        if (sorting && sorting.enabled) {
          const { attributeName, direction } = sorting;

          resourceList.sort((a, b) => {
            if (direction === SORTING_DIRECTION.Ascending) {
              return a[attributeName] > b[attributeName] ? 1 : -1;
            }

            return a[attributeName] < b[attributeName] ? 1 : -1;
          });
        }
      }

      return resourceList;
    },
    ...actions,
  };

  return fullActions as any;
}

let resourceMap: { [resourceName: string]: Resource<any, any, any> } = {};

export function getResourceByName<ST, OS extends ResourceObject, EU, RS, CS, A extends string>(
  name: string,
): Resource<ST, OS, EU, RS, CS, A> {
  if (!has(resourceMap, name)) {
    throw new Error(`Resource ${name} not registered`);
  }

  return resourceMap[name];
}

const dispatchQueue: Action<any>[] = [];
let store: Store<ResourcesStateAtom>;

export function setStore(s: Store<ResourcesStateAtom>) {
  store = s;

  forEach(dispatchQueue, action => store.dispatch(action));
}

function dispatch(a: Action<any>) {
  if (store) {
    store.dispatch(a);
  } else {
    dispatchQueue.push(a);
  }
}

export function clearResources() {
  resourceMap = {};
}

function getResourceFromStore(resourceName: string, state: ResourcesStateAtom) {
  if (isNil(state) || !has(state.resources, resourceName)) {
    // ignore coverage
    return null;
  }

  return state.resources[resourceName];
}

function getResourceById(resourceName: string, resourceId: ResourceId, state: ResourcesStateAtom) {
  const resource = getResourceFromStore(resourceName, state);

  if (resource === null || !has(resource.resources, resourceId)) {
    return null;
  }

  return resource.resources[resourceId];
}
