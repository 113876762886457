/** @format **/

import { some, identity, every, memoize } from 'lodash';

import { urlContains } from 'utils/links/utils';
import { toIdentifier } from 'utils/linking';
import { Dashboard } from 'interfaces/dashboard';
import { Configuration } from 'interfaces/configuration';

import { ProductType } from '../../interfaces/plan';

// Generate URLs
// ___ Description ___
// This function takes in a target application ID and based on the current page location will return a URI
// to be used when switching applications. It factors in various situations and defaults for the various page types.
// ___ When it should be updated ___
// This function should be updated when a new area of the app, specific to users applications is added / modified.
// ___ Why is this required ___
// Due to un-uniform URI structures on the Raygun app there's no simple way to achieve building our links based on
// the current path. Regex provided the simplest way to capture & mutate a wide variety of URLs at the cost of readability.
export const generateCurrentPathFromId = memoize(
  function(id: number, path: string = window.location.pathname): string {
    const appBaseAreas = [
      'crashreporting',
      'dashboard',
      'pulse',
      'reporting',
      'search',
      'settings',
    ];
    const appUriAreaAtBase = some(appBaseAreas.map(area => path.indexOf(area) === 1));

    const appUriRegex = /\/((?:crashreporting|dashboard|pulse|reporting|search|settings))\/[\w]+(\/?)([\/\w\?\=\+\-\%\.\&\#]+)?/gi;
    const apmUriRegex = /\/(apm)\/(?:(?!rules|sampling|filtering)[\w]+)(\/[\w]+)?(\/(?:methods|queries|requests|externalapis|traces))?(?:\/(?:.*))?/gi;
    const tlfQueryRegex = /toplevelfilters=[A-Za-z0-9]+(?:%3D)?(?:&)?/gi;
    const crInstanceRegex = /\/(?:.*)crashreporting(?:.*)errors(?:.*)/gi;
    const userInstanceRegex = /\/(?:.*)reporting(?:.*)errorsforuser(?:.*)/gi;
    const cxmInstanceRegex = /\/(?:.*)reporting(?:.*)customers\/(?:.*)/gi;
    const deploymentInstanceRegex = /\/deployments(?:.*)\/[0-9]+/gi;
    const apiInstanceRegex = /\/api\/.*/gi;

    // Remove any top level filters from path
    const cleanPath = path.replace(tlfQueryRegex, '');

    // Return base Crash Reporting link if on error instance
    if (crInstanceRegex.test(cleanPath)) {
      return `/crashreporting/${toIdentifier(id)}?#active`;
    }

    // Return APM style url if on product, includes instance logic
    if (apmUriRegex.test(cleanPath)) {
      return cleanPath.replace(apmUriRegex, `/$1/${toIdentifier(id)}$2$3`).replace('/products/expired/', `/`);
    }

    // Return base users link if on a user instance
    if (userInstanceRegex.test(cleanPath)) {
      return `/reporting/${toIdentifier(id)}/affectedusers`;
    }

    if (cxmInstanceRegex.test(cleanPath)) {
      return `/reporting/${toIdentifier(id)}/customers`;
    }

    // Return deployment dashboard link if on a deployment instance
    if (deploymentInstanceRegex.test(cleanPath)) {
      return `/dashboard/${toIdentifier(id)}/deployments`;
    }

    if (apiInstanceRegex.test(cleanPath)) {
      return `/api/${toIdentifier(id)}`;
    }

    // Replace and return new path or default
    if (appUriAreaAtBase && appUriRegex.test(cleanPath)) {
      return cleanPath.replace(appUriRegex, `/$1/${toIdentifier(id)}$2$3`);
    } else {
      return buildApplicationLink(id);
    }
  },
  (
    id: number,
    path: string = window.location.pathname,
    rgConfig: Configuration = (window as any).RaygunConfiguration,
  ) => [id, path.replace(rgConfig.ApplicationIdentifier, '')].join('_'),
);

export function buildPlanLink(id: number): string {
  return `/plan/${toIdentifier(id)}`;
}

export function buildApplicationLink(id: number): string {
  return `/home/${toIdentifier(id)}`;
}

export function buildDashboardLink(dashboard: Dashboard): string {
  if (dashboard.isPrimary) {
    return `/tiledashboard/${toIdentifier(dashboard.planId)}/primary`;
  }

  return `/tiledashboard/${toIdentifier(dashboard.id)}/display`;
}

export function buildAlertingLink(planIdentifier: string): string {
  return `/plan/${planIdentifier}/alerts`;
}

export function buildUsageLink(planIdentifier: string, type?: ProductType): string {
  let subArea;

  switch (type) {
    case ProductType.CRASH_REPORTING:
      subArea = '/crashreporting';
      break;
    case ProductType.RUM:
      subArea = '/pulse';
      break;
    case ProductType.APM:
      subArea = '/apm';
      break;
    default:
      subArea = '';
      break;
  }

  return `/plan/${planIdentifier}/usage${subArea}`;
}

// onPage checks
// Crash reporting
export function onFilters() {
  return urlContains('filtering');
}
export function onReports() {
  return urlContains('customreports');
}
export function onExport() {
  return urlContains('export');
}
export function onSymbols() {
  return some(['symbols', 'jssymbols', 'proguardsymbols'].map(name => urlContains(name)), identity);
}
export function onErrors() {
  return every([onFilters(), onReports(), onExport(), onSymbols()], b => !b);
}

// APM
export function onIssues() {
  return urlContains('issues');
}
export function onDiscover() {
  return urlContains('discover');
}
export function onTraces() {
  return urlContains('traces');
}
export function onSettings() {
  return urlContains('settings');
}
export function onSampling() {
  return urlContains('sampling');
}
export function onRules() {
  return urlContains('rules');
}
export function activeApmHeaderTab() {
  if (onIssues()) {
    return 'issues';
  }
  if (onDiscover()) {
    return 'discover';
  }
  if (onTraces()) {
    return 'traces';
  }
  if (onSettings()) {
    return 'settings';
  } else {
    return 'overview';
  }
}

// Alerting
export function onAlerting() {
  return urlContains('alerts');
}

export function onApiLanding() {
  return urlContains('/api/');
}

// Plan Selector
export function onPlanSelector() {
  return urlContains('planselector');
}

// String based url generation
// Dashboards
export function dashboardUrl(planIdentifier: number | string): string {
  return `/tiledashboard/${planIdentifier}/primary`;
}

// Crash URLs
export function crashUrl(applicationIdentifier: string): string {
  return `/crashreporting/${applicationIdentifier}`;
}
export function errorsUrl(applicationIdentifier: string): string {
  return `/crashreporting/${applicationIdentifier}`;
}
export function filtersUrl(applicationIdentifier: string): string {
  return `/settings/${applicationIdentifier}/crash/filtering`;
}
export function reportsUrl(applicationIdentifier: string): string {
  return `/crashreporting/${applicationIdentifier}/customreports`;
}
export function exportUrl(applicationIdentifier: string): string {
  return `/crashreporting/${applicationIdentifier}/export`;
}
export function symbolsUrl(applicationIdentifier: string): string {
  return `/settings/${applicationIdentifier}/symbols`;
}
export function searchUrl(applicationIdentifier: string): string {
  return `/search/${applicationIdentifier}`;
}

// RUM URLs
export function rumUrl(applicationIdentifier: string): string {
  return `/pulse/${applicationIdentifier}`;
}

// APM URLs
export function apmUrl(applicationIdentifier: string): string {
  return `/apm/${applicationIdentifier}`;
}
export function apmIssuesUrl(applicationIdentifier: string): string {
  return `/apm/${applicationIdentifier}/issues`;
}
export function apmDiscoverUrl(applicationIdentifier: string): string {
  return `/apm/${applicationIdentifier}/discover`;
}
export function apmTracesUrl(applicationIdentifier: string): string {
  return `/apm/${applicationIdentifier}/traces`;
}
export function apmBlacklistUrl(applicationIdentifier: string): string {
  return `/settings/${applicationIdentifier}/apm`;
}
export function apmSamplingUrl(applicationIdentifier: string): string {
  return `/settings/${applicationIdentifier}/apm/sampling`;
}
export function apmRulesUrl(applicationIdentifier: string): string {
  return `/settings/${applicationIdentifier}/apm/rules`;
}

// Misc
export function deploymentsUrl(applicationIdentifier: string): string {
  return `/dashboard/${applicationIdentifier}/deployments`;
}
export function errorsForUserUrl(applicationIdentifier: string, userIdentifier: string): string {
  return `/reporting/${applicationIdentifier}/errorsforuser?userIdentifier=${userIdentifier}`;
}
export function usersUrl(applicationIdentifier: string): string {
  return `/reporting/${applicationIdentifier}/affectedusers`;
}
export function whatsNewUrl(applicationIdentifier: string): string {
  return `/whats-new?applicationIdentifier=${applicationIdentifier}`;
}
export function inviteTeamUrl(planIdentifier: string): string {
  return `/plan/${planIdentifier}/teams`;
}
export function integrationsUrl(applicationIdentifier: string): string {
  return `/settings/${applicationIdentifier}/plugins`;
}

export * from './utils';
export * from './planSettings';
