/** @format **/

import { get, set } from 'lodash';
import { StorageType } from './models';

export const MEMORY_STORAGE_KEY = 'RaygunMemoryStorage';

export class StorageUtil {
  storageType: StorageType;
  root: any = window;

  constructor(storageType: StorageType, root: any = window) {
    this.root = root;
    this.storageType = storageType;
  }

  isStorageSupported() {
    try {
      return !!this.root[this.storageType];
    } catch (_e) {
      //ignore
    }

    return false;
  }

  getItem(key: string) {
    if (this.isStorageSupported()) {
      return this.root[this.storageType].getItem(key);
    } else {
      return get(this.root, `${MEMORY_STORAGE_KEY}[${key}]`);
    }
  }

  setItem(key: string, value: string) {
    if (this.isStorageSupported()) {
      return this.root[this.storageType].setItem(key, value);
    } else {
      return set(this.root, `${MEMORY_STORAGE_KEY}[${key}]`, value);
    }
  }

  clear() {
    if (this.isStorageSupported()) {
      return this.root[this.storageType].clear();
    } else {
      return set(this.root, `${MEMORY_STORAGE_KEY}`, null);
    }
  }
}

export function LocalStorageUtilFactory(root = window) {
  return new StorageUtil(StorageType.Local, root);
}

export function SessionStorageUtilFactory(root = window) {
  return new StorageUtil(StorageType.Session, root);
}

export const LocalStorageUtil = LocalStorageUtilFactory();
export const SessionStorageUtil = SessionStorageUtilFactory();
