/** @format */
import * as React from 'react';
import { isNil, get, merge } from 'lodash';
import { connect } from 'react-redux';

import * as charting from 'utils/echarts';
import { MarkLine, NO_DATA_ITEM_NAME } from 'utils/echarts/apdex';

import { Container, Graph, Legend, LegendItem } from 'components/echarts/legend';
import { AppState } from 'interfaces/appState';
import { renderTitle } from 'components/charts/title';
import { DEFAULT_GROUP_ID } from 'components/ds/charts/utils';
import { getCurrentRouteData } from 'selectors/router';
import { createActionForDate, CurrentRouteProps } from 'utils/actions';
import { CustomChart } from 'components/ds/charts';

export enum CountChartTypes {
  BAR,
  LINE,
}

type StateProps = {
  routeData: CurrentRouteProps;
};

type DispatchProps = {
  updateDateRange: (dateFrom: string, dateTo: string) => void;
};

type SeriesData = {
  name: string;
  value: string;
  colorCode: string;
};

export type DayNumber =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30;

export type MarkLineData = {
  value: DayNumber;
  color?: string;
  label?: string;
};

const asMarkLine = (markLineData: MarkLineData) => {
  return {
    name: '',
    type: 'bar',
    data: [[markLineData.value, 0]],
    markLine: {
      silent: true, // ignore mouse events
      symbol: ['none'],
      label: { show: false },
      data: [
        {
          type: 'average',
          name: 'Marker Line',
          valueIndex: 0,
          itemStyle: { normal: { color: markLineData.color || '#666' } },
        },
      ],
    },
  };
};

type SuppliedProps = {
  data: { count: number[]; periods: string[] };
  color: string;
  interval: charting.Intervals;
  label?: string;
  title?: string;
  height?: string;
  chartGroupId?: string;
  groupChart?: boolean;
  showYAxis?: boolean;
  className?: string;
  allowDateRangeSelect?: boolean;
  chartType?: CountChartTypes;
  legendItems?: LegendItem[];
  onDashboard?: boolean;
  customOptions?: any;
  markLine?: MarkLine;
  tooltipRenderer?: (
    params: any,
    interval: charting.Intervals,
  ) => { date: string; series: SeriesData[] };
  formatTooltipDate?: (date: any) => string;
  markLines?: MarkLineData[];
};

type Props = SuppliedProps & StateProps & DispatchProps;

function getChartStyles(chartType: CountChartTypes, color: string) {
  if (chartType === CountChartTypes.BAR) {
    return {
      itemStyle: {
        normal: {
          barBorderRadius: [0, 0, 0, 0],
          color,
        },
        emphasis: {
          barBorderRadius: [0, 0, 0, 0],
          color,
        },
      },
    };
  }
  return {
    symbol: 'none',
    lineStyle: {
      normal: { color },
      emphasis: { color },
    },
  };
}

const UnconnectedCountOverTimeChart: React.FunctionComponent<Props> = props => {
  const {
    data,
    color,
    interval,
    label,
    title,
    markLine,
    formatTooltipDate,
    markLines = [],
  } = props;

  const baseSeries = {
    name: label,
    type: props.chartType === CountChartTypes.BAR ? 'bar' : 'line',
    data: data.count,
    ...getChartStyles(props.chartType, color),
    markLine,
  };

  const markLineSeries = markLines.map(asMarkLine);
  const all = [baseSeries, ...markLineSeries];
  const options = (chart: any) => {
    const isLineChart = props.chartType === CountChartTypes.LINE;

    const rawOptions = charting.extendOptions({
      tooltip: {
        show: true,
        confine: true,
        trigger: 'axis',
        formatter: (params: any[]) => {
          if (props.tooltipRenderer) {
            return charting.renderSeriesTooltip(props.tooltipRenderer(params, interval));
          } else {
            const date = params[0].name;
            return charting.renderSeriesTooltip({
              date: !isNil(formatTooltipDate)
                ? formatTooltipDate(date)
                : charting.formatUtcTooltipDate(date, interval),
              series: params.map((s: any) => ({
                name: s.seriesName,
                value: get(s, 'value', NO_DATA_ITEM_NAME).toLocaleString(),
                colorcode: color,
              })),
            });
          }
        },
        axisPointer: isLineChart ? null : charting.rum.AxisPointerShadow,
      },
      xAxis: [
        {
          type: 'category',
          data: data.periods,
          boundaryGap: !isLineChart,
          axisLabel: {
            formatter: charting.getDateTimeLabelFormatter(
              data.periods,
              interval,
              chart ? chart.getEchartsInstance() : undefined,
            ),
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            show: props.showYAxis,
            formatter: charting.getIntegerLabelFormatter(
              chart ? chart.getEchartsInstance() : undefined,
            ),
          },
        },
      ],
      series: all,
      ...(!props.showYAxis ? { grid: { x: 20 } } : {}),
    });
    return props.customOptions ? merge({}, rawOptions, props.customOptions) : rawOptions;
  };
  const markLineLegend = markLines.map(x => {
    return { label: x.label || '', colorCode: x.color || '#666' } as LegendItem;
  });

  const legendItems = !isNil(props.legendItems)
    ? props.legendItems
    : !isNil(label)
    ? [{ label, colorCode: color }]
    : [];

  const hasLegend = legendItems.length >= 1;

  return (
    <Container>
      {!isNil(title) && renderTitle(title)}
      <Graph hasLegend={hasLegend} positionInside={props.onDashboard}>
        <CustomChart
          group={props.groupChart}
          groupingId={props.chartGroupId}
          zoomOnDateRange={props.allowDateRangeSelect}
          onDateRangeChange={props.updateDateRange}
          periods={props.data.periods}
          height={props.height}
          options={options}
          className={props.className}
        />
      </Graph>
      {hasLegend && (
        <Legend
          legendItems={[...legendItems, ...markLineLegend]}
          positionInside={props.onDashboard}
        />
      )}
    </Container>
  );
};

UnconnectedCountOverTimeChart.defaultProps = {
  chartGroupId: DEFAULT_GROUP_ID,
  groupChart: true,
  showYAxis: true,
  chartType: CountChartTypes.BAR,
  label: 'Occurrences',
};

type ReduxDispatchProps = {
  updateDateRange: (routeData: CurrentRouteProps, dateFrom: string, dateTo: string) => void;
};

const ConnectedCountOverTimeChart = connect<
  StateProps,
  ReduxDispatchProps,
  SuppliedProps,
  Props,
  AppState
>(
  state => ({
    routeData: getCurrentRouteData(state),
  }),
  dispatch => ({
    updateDateRange: (routeData: CurrentRouteProps, dateFrom: string, dateTo: string) =>
      dispatch(createActionForDate(routeData, dateFrom, dateTo)),
  }),
  (stateProps: StateProps, dispatchProps: ReduxDispatchProps, passedProps: SuppliedProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...passedProps,
    updateDateRange: (dateFrom: string, dateTo: string) =>
      dispatchProps.updateDateRange(stateProps.routeData, dateFrom, dateTo),
  }),
)(UnconnectedCountOverTimeChart);
export { ConnectedCountOverTimeChart as CountOverTime };
