/** @format */

import * as React from 'react';
const echarts = require('echarts/lib/echarts');

import { Container, Graph, Legend } from 'components/echarts/legend';
import * as charting from 'utils/echarts/index';
import { Props as ProviderProps } from '../dataProvider';
import { WorldMap } from './worldMap';

type Props = {
  tooltipFormatter: (params: any) => string;
  countries: { name: string; value: number }[];
  bucketingPieces: { color: string; min: number; max: number }[];
  legendLabels: { lower: string; higher: string };
} & ProviderProps;

export class GeoDistributionTile extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.onChartReady = this.onChartReady.bind(this);
  }

  onChartReady() {
    this.forceUpdate();
  }

  render() {
    const { tooltipFormatter, countries, bucketingPieces, legendLabels } = this.props;

    const options = {
      tooltip: {
        trigger: 'item',
        formatter: tooltipFormatter,
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        showDelay: 0, // ms
        hideDelay: 0, // ms
        transitionDuration: 0, // s
      },
      visualMap: {
        type: 'piecewise',
        pieces: bucketingPieces,
        show: false,
      },
      series: [
        {
          type: 'map',
          mapType: 'world',
          roam: false,
          zoom: 1.2,
          data: countries,
          hoverable: false,
          clickable: false,
          itemStyle: {
            normal: {
              borderWidth: 0,
            },
            emphasis: {
              borderWidth: 0,
              areaColor: charting.emphasisColor,
            },
          },
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
        },
      ],
    };

    return (
      <Container>
        <Graph positionInside hasLegend>
          <WorldMap
            style={{ height: '100%' }}
            echarts={echarts}
            option={options}
            onChartReady={this.onChartReady}
          />

          <Legend
            blockLabel
            lowerLegendLabel={legendLabels.lower}
            higherLegendLabel={legendLabels.higher}
            legendItems={[
              { colorCode: charting.distributionColor10, blockLabel: true },
              { colorCode: charting.distributionColor9, blockLabel: true },
              { colorCode: charting.distributionColor8, blockLabel: true },
              { colorCode: charting.distributionColor7, blockLabel: true },
              { colorCode: charting.distributionColor6, blockLabel: true },
              { colorCode: charting.distributionColor5, blockLabel: true },
              { colorCode: charting.distributionColor4, blockLabel: true },
              { colorCode: charting.distributionColor3, blockLabel: true },
              { colorCode: charting.distributionColor2, blockLabel: true },
              { colorCode: charting.distributionColor1, blockLabel: true },
            ]}
          />
        </Graph>
      </Container>
    );
  }
}
