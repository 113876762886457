/**
 * @prettier
 */

import * as React from 'react';
import cx from 'classnames';

import BreadcrumbItem from './item';
import BreadcrumbModel from './model';

type Props = {
  items: BreadcrumbModel[];
  clip?: boolean;
  heading?: boolean;
  headingWithoutPadding?: boolean;
};

const Breadcrumbs: React.FunctionComponent<Props> = props => {
  const baseClasses = cx({
    breadcrumb: !props.heading,
    'breadcrumb-heading': props.heading,
    'breadcrumb-heading--breadcrumb': props.heading,
  });

  const itemClasses = cx({
    breadcrumb__item: !props.heading,
    'breadcrumb-heading__item': props.heading,
  });

  const elements = props.items.map((item, index) => {
    return (
      <li key={index} id={item.id} className={itemClasses}>
        <BreadcrumbItem
          heading={props.heading}
          model={item}
          isLast={index + 1 === props.items.length}
        />
      </li>
    );
  });

  return <ul className={baseClasses}>{elements}</ul>;
};

export { Breadcrumbs, BreadcrumbModel };
