/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';

const StyledModalHeader = (props: ThemeType): IStyle => ({
  padding: '12px 24px',
  borderBottom: `1px solid ${props.theme.colors.border.standard}`,
});

export const ModalHeader = createComponent(StyledModalHeader);

export const ModalHeaderWithTitle: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <ModalHeader>
      <h5>
        <strong>{children}</strong>
      </h5>
    </ModalHeader>
  );
};
