/** @format **/

import React from 'react';

import { Icon } from 'components/icon';

import { ToggleRowButton } from './toggleRowButton';

type Props = {
  toggleAllGroupedData: () => void;
  showAllGroupedData: boolean;
};

export const HeaderToggleCell: React.FunctionComponent<Props> = props => (
  <th className="table__cell table__cell-40">
    <ToggleRowButton
      onClick={props.toggleAllGroupedData}
      expanded={props.showAllGroupedData}
      className="it-toggle-all-table-rows"
      title={props.showAllGroupedData ? 'Collapse all rows' : 'Expand all rows'}
    >
      <Icon set="icomoon" type="arrow-right" />
    </ToggleRowButton>
  </th>
);
