/** @format */

import { get } from 'lodash';

import { Configuration } from 'interfaces/configuration';

const config: Configuration = get(window, 'RaygunConfiguration', {});

export const getTimeZoneName = () => config.TimeZoneName;

export const get24HourDisplay = () => config.Display24HourTimes;

export const getTimeZoneOffset = () => config.TimeZoneOffsetInHours;
