/**
 * @prettier
 */

import React from 'react';

import { FormLayout, FormLayoutLabel, FormLayoutInput } from 'components/input';
import { Checkbox } from 'components/checkbox';

type Props = {
  handleActionChange: (action: string) => void;
  selectedActions: string[];
};

export const SelectActions: React.FunctionComponent<Props> = (props: Props) => {
  const errorChecked = props.selectedActions.indexOf('Error') > -1;
  const applicationChecked = props.selectedActions.indexOf('Application') > -1;
  const planChecked = props.selectedActions.indexOf('Plan') > -1;

  return (
    <FormLayout marginBottom>
      <FormLayoutLabel>
        <label>Actions to include</label>
      </FormLayoutLabel>
      <FormLayoutInput>
        <ul className="form-list">
          <li>
            <Checkbox onToggled={() => props.handleActionChange('Error')} isChecked={errorChecked}>
              Error actions
            </Checkbox>
          </li>
          <li>
            <Checkbox
              onToggled={() => props.handleActionChange('Application')}
              isChecked={applicationChecked}
            >
              Application actions
            </Checkbox>
          </li>
          <li>
            <Checkbox onToggled={() => props.handleActionChange('Plan')} isChecked={planChecked}>
              Plan actions
            </Checkbox>
          </li>
        </ul>
      </FormLayoutInput>
    </FormLayout>
  );
};
