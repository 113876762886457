/**
 * @prettier
 */

import * as React from 'react';

import { PopupProps as ModalProps } from 'app/popups/models';

import { ModalHeader, ModalHeaderWithTitle } from './header';
import { ModalFooter } from './footer';
import { ModalClose } from './close';
import { ModalBody, ModalBodyContent, ModalBodyProps } from './body';
import { ModalMessage } from './message';
import { ModalBase, ModalBaseProps } from './modalbase';

type Props = {
  header: React.ReactNode;
  footer?: React.ReactNode;
  showClose?: boolean;
  modalBody?: Partial<ModalBodyProps>;
} & ModalBaseProps &
  ModalProps;

class Modal extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    width: 640,
    footer: null,
    header: null,
    showClose: true,
    closeOnBackdropClick: true,
    modalBody: {},
  };

  render() {
    return (
      <ModalBase {...this.props}>
        {this.props.header}
        {this.renderClose()}
        <ModalBody {...this.props.modalBody}>{this.props.children}</ModalBody>
        {this.props.footer}
      </ModalBase>
    );
  }

  renderClose() {
    if (this.props.showClose) {
      return <ModalClose onClick={() => this.props.close(this.props.id)} />;
    }

    return null;
  }
}

export {
  ModalProps,
  ModalBase,
  Modal,
  ModalHeader,
  ModalHeaderWithTitle,
  ModalFooter,
  ModalClose,
  ModalBodyContent,
  ModalMessage,
};
