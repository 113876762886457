/** @format */

export const ONE_HOUR: '1h' = '1h';
export const SIX_HOUR: '6h' = '6h';
export const ONE_DAY: '1d' = '1d';
export const SEVEN_DAYS: '7d' = '7d';
export const THIRTY_DAYS: '30d' = '30d';

export const CUSTOM: 'custom' = 'custom';

export type DateTimeFilterPredefinedRange = typeof ONE_DAY | typeof SEVEN_DAYS | typeof THIRTY_DAYS;

export const DEFAULT_DATE_TIME_FILTER_RANGE = SEVEN_DAYS;
