/**
 * @prettier
 */

import { ActionCreatorFactory, ActionCreator, EmptyActionCreator, Action } from 'typescript-fsa';

import { DateTimeFilterPredefinedRange } from 'constants/dateTimeFilter';
import { Moment } from 'moment';
import { isString } from 'lodash';

type FetchActions<B, D, E> = {
  start: EmptyActionCreator;
  error: ActionCreator<E>;
  done: ActionCreator<D>;
  base: ActionCreator<B>;
};

export function createFetchActions<B = {}, D = {}, E = {}>(
  createAction: ActionCreatorFactory,
  prefix: string,
): FetchActions<B, D, E & Error> {
  return {
    start: createAction(`${prefix}_STARTED`),
    error: createAction<E & Error>(`${prefix}_FAILED`),
    done: createAction<D>(`${prefix}_SUCCEEDED`),
    base: createAction<B>(`${prefix}`),
  };
}

type FetchActionsWithPayload<B, S, E, D> = {
  base: ActionCreator<B>;
  startWithPayload: (payload: B) => Action<S>[];
  errorWithPayload: (e: Error, payload: B) => Action<E>[];
  doneWithPayload: (data: any, payload: B) => Action<D>[];
};
export function createFetchActionsWithPayload<B extends object = {}, D extends object = {}>(
  createAction: ActionCreatorFactory,
  prefix: string,
): FetchActionsWithPayload<B, B, B & { error: Error }, B & D> {
  const actions = {
    start: createAction<B>(`${prefix}_STARTED`),
    error: createAction<B & { error: Error }>(`${prefix}_FAILED`),
    done: createAction<B & D>(`${prefix}_SUCCEEDED`),
  };

  return {
    base: createAction<B>(prefix),
    startWithPayload: (payload: B) => [actions.start(payload)],
    errorWithPayload: (e: Error, payload: B) => [actions.error({ ...(payload as any), error: e })],
    doneWithPayload: (data: D, payload: B) => [
      actions.done({ ...(payload as any), ...(data as any) }),
    ],
  };
}

export type CurrentRouteProps = {
  location: any;
  payload: any;
  query: any;
};

export const createActionForRange = (
  { location, payload, query }: CurrentRouteProps,
  range: DateTimeFilterPredefinedRange,
) => ({
  type: location,
  payload,
  meta: {
    query: {
      ...query,
      range,
      dateFrom: undefined,
      dateTo: undefined,
    },
  },
});

export const createActionForDate = (
  { location, payload, query }: CurrentRouteProps,
  dateFrom: Moment | string,
  dateTo: Moment | string,
) => ({
  type: location,
  payload,
  meta: {
    query: {
      ...query,
      dateFrom: isString(dateFrom) ? dateFrom : dateFrom.format(),
      dateTo: isString(dateTo) ? dateTo : dateTo.format(),
      range: undefined,
    },
  },
});
