/** @format **/

import React from 'react';
import { isNil, omit, get, isEmpty } from 'lodash';

import { Link } from 'components/ds/text';
import { Icon } from 'components/icon';
import { Margin } from 'components/layout';
import { Svg } from 'components/svg';

import { isNotNilOrEmpty } from 'utils/string';

import {
  FelaBaseInput,
  FelaBaseInputArea,
  FelaInputContainer,
  BaseDefaultProps,
  DefaultProps,
  FelaBaseInputContainerProps,
  InputWrapper,
  FelaErrorText,
  FelaInputLabel,
  FelaRequiredAstrix,
  FelaSubtext,
  IconRightContainer,
  Prefix,
} from './components';
import { SIZES } from '../../icon/constants';
import { IconContainer } from '../dropdown/menu/items/icon';

export { DefaultProps, BaseDefaultProps };

// Public facing props
export type BaseInputProps = FelaBaseInputContainerProps &
  React.InputHTMLAttributes<any> & { block: boolean; leftIconSpacing: boolean };

// Public input component, handles prop delegation between all the different sub-components.
export class BaseInput extends React.Component<BaseInputProps> {
  input: HTMLElement;

  static defaultProps: BaseDefaultProps = DefaultProps;

  constructor(props: BaseInputProps) {
    super(props);

    this.clearText = this.clearText.bind(this);
    this.renderIconOnLeft = this.renderIconOnLeft.bind(this);
    this.renderIconOnRight = this.renderIconOnRight.bind(this);
    this.renderCopyButton = this.renderCopyButton.bind(this);
  }

  clearText() {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      (window as any).HTMLInputElement.prototype,
      'value',
    ).set;
    nativeInputValueSetter.call(this.input, '');

    const ev2 = new Event('input', { bubbles: true });
    this.input.dispatchEvent(ev2);
  }

  renderIconOnLeft() {
    const { iconLeft, selection, disabled, leftIconSpacing } = this.props;
    const hasIconOnLeft = !isNil(iconLeft);

    if (hasIconOnLeft) {
      const iconSize: SIZES = selection ? 24 : 16;
      const dsColor = disabled ? 'grey400' : iconLeft.props.dsColor;

      const isSvg = iconLeft.type === Svg;
      const defaultProps = isSvg ? { disabled } : { size: iconSize, dsColor, inline: true };

      return (
        <IconContainer height={iconSize} leftSpacing>
          {React.cloneElement(iconLeft, defaultProps)}
        </IconContainer>
      );
    }
  }

  renderIconOnRight() {
    const { clearable, disabled, iconRight } = this.props;
    const iconCloseDisplay = clearable && !disabled;
    const hasValue = get(this.props, 'value', '').trim().length > 0;

    // Clear text icon
    if (iconCloseDisplay && hasValue) {
      return (
        <Margin left="8" right="16">
          <Icon type="cross" set="icomoon" onClick={this.clearText} />
        </Margin>
      );
    } else if (!isNil(iconRight)) {
      const dsColor = disabled ? 'grey400' : iconRight.props.dsColor;
      return (
        <IconRightContainer>
          {React.cloneElement(iconRight, { size: 16, dsColor })}
        </IconRightContainer>
      );
    }
  }

  renderPrefix() {
    const { disabled, prefixText, iconLeft } = this.props;

    // Don't render the prefix component if iconLeft is enabled
    if (!isNil(iconLeft)) {
      return;
    }

    const dsColor = disabled ? 'grey400' : 'grey800';

    if (isNotNilOrEmpty(prefixText)) {
      return (
        <Margin left="16" right="8">
          <Prefix prefixColor={dsColor}>{prefixText}</Prefix>
        </Margin>
      );
    }
  }

  renderCopyButton() {
    const { onCopyButtonClick, credentialsValue } = this.props;

    function copyToClipboard() {
      window.navigator.clipboard.writeText(credentialsValue);
    }

    if (onCopyButtonClick) {
      return (
        <Margin left="8" right="16">
          <Link
            color={'blueRG1050'}
            onClick={() => {
              copyToClipboard();
              onCopyButtonClick();
            }}
          >
            Copy
          </Link>
        </Margin>
      );
    }
  }

  render() {
    const { subtext, label, ...props } = this.props;
    const showError = !isNil(props.error) && !isEmpty(props.error) && !props.disabled;
    const baseProps = omit(
      props,
      'block',
      'small',
      'backgroundColor',
      'errorBorderColor',
      'borderColor',
      'disabledBorderColor',
      'value',
      'credentialsValue',
      'labelColor',
      'labelBold',
      'clearable',
      'fullWidthOnMobile',
    );
    const containerProps = omit(
      props,
      'iconLeft',
      'value',
      'disabledPlaceholderTextColor',
      'placeholderTextColor',
      'disabledTextColor',
      'textColor',
      'onChange',
      'onBlur',
      'id',
      'labelBold',
      'placeholder',
      'labelColor',
    );

    return (
      <InputWrapper block={props.block}>
        {!isNil(label) &&
          (!isEmpty(label) && (
            <FelaInputLabel
              labelBold={props.labelBold}
              labelColor={props.labelColor}
              htmlFor={props.id}
            >
              {label} {props.required && <FelaRequiredAstrix>*</FelaRequiredAstrix>}
            </FelaInputLabel>
          ))}

        <FelaInputContainer {...omit(containerProps, 'tabIndex')}>
          {props.textArea ? (
            <FelaBaseInputArea {...baseProps} />
          ) : (
            <>
              {this.renderIconOnLeft()}
              {this.renderPrefix()}

              <FelaBaseInput
                {...baseProps}
                required={props.required}
                value={props.value}
                innerRef={r => (this.input = r)}
                readOnly={props.readOnly || props.selection}
              />

              {this.renderIconOnRight()}
              {this.renderCopyButton()}
            </>
          )}
        </FelaInputContainer>
        {showError && (
          <FelaErrorText absoluteError={props.absoluteError}>{props.error}</FelaErrorText>
        )}
        {!isNil(subtext) && <FelaSubtext>{subtext}</FelaSubtext>}
      </InputWrapper>
    );
  }
}
