/** @format */
import { createSelector } from 'reselect';

import { RevampedChromeState } from '../reducers';

const getSidebar = (state: RevampedChromeState) => state.revampedChrome.sidebar;

export const getAccountMenuOpen = createSelector(
  getSidebar,
  state => state.accountMenuOpen,
);

export const getSlideoutMenuOpen = createSelector(
  getSidebar,
  state => state.slideoutMenuOpen,
);
