/** @format **/
import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from '../app/theme';

const StyledCalloutWrapper = (props: ThemeType): IStyle => ({
  padding: '16px',
  marginBottom: '10px',
  background: props.theme.ds.getColorByName('amber50'),
});
const CalloutWrapper = createComponent(StyledCalloutWrapper);

const StyledTitle = (props: ThemeType): IStyle => ({
  fontSize: '1.3rem',
  fontWeight: 600,
  lineHeight: '1.6',
  marginBottom: '4px',
  color: props.theme.ds.getColorByName('grey800'),
});
const Title = createComponent(StyledTitle, 'h4');

type Props = {
  title: string;
};

export const Callout: React.FunctionComponent<Props> = props => (
  <CalloutWrapper>
    <Title>{props.title}</Title>
    {props.children}
  </CalloutWrapper>
);
