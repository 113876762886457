/** @format */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import * as Models from './models';
import * as Selectors from './selectors';

import { Popup } from './popup';

type StateProps = {
  popups: Models.Popup[];
};

type DispatchProps = {};

type Props = StateProps & DispatchProps;

type State = {};

class Popups extends React.Component<Props, State> {
  render() {
    const popups = this.props.popups.map(popup => {
      return <Popup {...popup} key={popup.id} />;
    });

    return <div>{popups}</div>;
  }
}

export default connect<StateProps, DispatchProps, {}>((state: AppState) => ({
  popups: Selectors.popups(state),
}))(Popups);
