/** @format **/

import React, { Dispatch, SetStateAction } from 'react';
import { forIn, forEach, orderBy, keys, sumBy, clone, get, memoize } from 'lodash';

import { CellPadding, Column, TableProps } from 'components/table';
import { TableRow, TableRowProperties } from 'components/table/tableRow';

import { DiscColorMap, ExpandedRows, GroupedDataItem } from './models';

export type GroupedTableRowProps = {
  key: string;
  groupId: string;
  count: number;
  item: any;
  columns: Column[];
  padding: CellPadding;
  props: TableRowProperties;
  discColorMap: DiscColorMap;
  toggleChildRows?: () => void;
  showChildRows?: boolean;
  showRow?: boolean;
  isParentRow?: boolean;
  isChildRow?: boolean;
};

export function getParentRowData(key: string, items: GroupedDataItem, _groupOnField: string = null) {
  const root = clone(items[0]);
  const itemKeys = keys(root);

  forEach(itemKeys, k => {
    root[k] = sumBy(items, i => i[k]);
  });

  const groupOnField = _groupOnField || itemKeys[0];

  root[groupOnField] = (key as unknown) as number;

  return root;
}

export function getGroupedTableData(
  props: TableProps,
  expandedRows: ExpandedRows,
  toggleExpandedRows: Dispatch<SetStateAction<ExpandedRows>>,
): GroupedTableRowProps[] {
  const rowData: GroupedTableRowProps[] = [];

  const {
    groupedData,
    showAllGroupedData,
    groupCountField,
    discColorMap,
    groupOnField
  } = props.groupedTableProps;

  forIn(groupedData, (items: GroupedDataItem, key: string) => {
    const showRow = get(expandedRows, key, showAllGroupedData);
    const parentRowItem = getParentRowData(key, items, groupOnField);

    rowData.push({
      key,
      groupId: key,
      columns: props.columns,
      item: parentRowItem,
      count: parentRowItem[groupCountField],
      props: props.rowProps[key],
      padding: props.padding,
      discColorMap: discColorMap,
      isParentRow: true,
      showChildRows: showRow,
      toggleChildRows: () => {
        toggleExpandedRows({
          ...expandedRows,
          [key]: !showRow,
        });
      },
      ...props,
    });

    forEach(items, (row, index) => {
      rowData.push({
        groupId: key,
        key: `${key}_${index}`,
        columns: props.columns,
        item: row,
        count: row[groupCountField],
        props: props.rowProps[key],
        padding: props.padding,
        discColorMap: discColorMap,
        isChildRow: true,
        showRow,
        ...props,
      });
    });
  });

  // Sort by groupId primarily, count secondarily
  return orderBy(rowData, ['groupId', groupCountField], ['desc', 'desc']);
}

export function getGroupedTableRows(
  props: TableProps,
  expandedRows: ExpandedRows,
  toggleExpandedRows: Dispatch<SetStateAction<ExpandedRows>>,
) {
  const _getGroupedTableData = memoize(getGroupedTableData);
  const sortedRowData = _getGroupedTableData(props, expandedRows, toggleExpandedRows);
  return sortedRowData.map(row => <TableRow key={row.key} {...row} />);
}
