/** @format */

import { createRoutingAction } from 'utils/routing';

export const GoToAffectedUsers = createRoutingAction<{ applicationIdentifier: string }>(
  'GO_TO_AFFECTED_USERS',
);

export const GoToAffectedUsersAfterDataIsSent = createRoutingAction<{
  applicationIdentifier: string;
}>('GO_TO_AFFECTED_USERS_AFTER_DATA_IS_SENT');

export const GoToAffectedUser = createRoutingAction<{
  applicationIdentifier: string;
  userIdentifier: string;
}>('GO_TO_AFFECTED_USER');
