/** @format */

import * as React from 'react';
import classnames from 'classnames';

interface Props {
  margin?: boolean;
  right?: boolean;
}

export const Section: React.FunctionComponent<Props> = props => {
  const className = classnames('page-header__section', {
    'page-header__section--margin': props.margin,
    'page-header__section--right': props.right,
  });

  return <div className={className}>{props.children}</div>;
};

Section.defaultProps = {
  margin: false,
};
