import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from "interfaces/appState";

import { AnonymousHeader } from "./anonymousHeader";
import { ConnectedHeader } from "./header";
import { LogoOnlyHeader } from "./logoOnlyHeader";

type PassedProps = {
  marionetteToggleSidebar?: () => void;
};

type StateProps = {
  layout: string;
};

type Props = PassedProps & StateProps;
/**
 * Abstracts the header selection out so that we never have to manually chose which one needs to be
 * rendered
 * @param props
 */
const HeaderComponent: FunctionComponent<Props> = (props) => {
  if (props.layout === 'anonymous') {
    return <AnonymousHeader />;
  } else if (props.layout === 'logo-only') {
    return <LogoOnlyHeader />;
  }
  return <ConnectedHeader {...props}/>;
};

export const Header = connect<StateProps>(
  (state: AppState) => ({
    layout: state.app.chrome.layout
  })
)(HeaderComponent);
