/** @format */

import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { reducerUpdate } from 'utils/reducerUpdate';
import { getDefaultPlanId } from 'utils/plans';

import * as Actions from '../actions';
import { SWITCHER_TYPE } from '../models';

export type SwitcherStateAtom = {
  currentPlanId: number;
  filter: string;
  open: boolean;
  type: SWITCHER_TYPE;
};

const defaultState = {
  currentPlanId: getDefaultPlanId(),
  filter: '',
  open: false,
  type: SWITCHER_TYPE.Application
};

export const SwitcherReducer = reducerWithInitialState<SwitcherStateAtom>(defaultState)
  .case(Actions.selectPlan, (state, currentPlanId) => {
    return reducerUpdate(state, { currentPlanId });
  })
  .case(Actions.setFilter, (state, filter) => {
    return reducerUpdate(state, { filter }); 
  })
  .case(Actions.toggleSwitcher, (state, type) => {
    return reducerUpdate(state, {
      open: !state.open,
      type,
      filter: !state.open ? '' : state.filter
    });
  })
  .case(Actions.closeSwitcher, (state) => {
    return reducerUpdate(state, { open: false });
  });
