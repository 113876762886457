import React from 'react';

import { Icon } from 'components/icon';
import { SupportLink } from 'components/supportLink';

import { HasMultiplePlans, HasSinglePlan } from '../multiplePlans';
import { MenuItem, MenuItemDivider, CurrentPlanMenuItem, SinglePlanMenuItem, UserMenuItem } from './menuItems';

type AccountMenuProps = {
  openPlanSettings: () => void;
};

export const AccountMenu: React.FunctionComponent<AccountMenuProps> = ({ openPlanSettings }) => (
  <>
    <UserMenuItem />
    <MenuItem href="/user">Personal settings</MenuItem>
    <MenuItemDivider />
    <HasMultiplePlans>
      <CurrentPlanMenuItem />
    </HasMultiplePlans>
    <HasSinglePlan>
      <SinglePlanMenuItem />
    </HasSinglePlan>
    <MenuItem href="/auditlog">Audit log</MenuItem>
    <HasMultiplePlans>
      <MenuItem onClick={openPlanSettings}>
        Switch Plans
        <Icon set="icomoon" type="arrow-right" />
      </MenuItem>
    </HasMultiplePlans>
    <MenuItemDivider />
    <SupportLink>
      <MenuItem as="span">Support</MenuItem>
    </SupportLink>
    <MenuItemDivider />
    <MenuItem href="/signout">Sign out</MenuItem>
  </>
);