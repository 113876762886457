/** @format **/
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { ThemeType } from 'app/theme';

type RowTextProps = {};

const StyledRowText = (props: ThemeType & RowTextProps): IStyle => ({
  userSelect: 'none',
  lineHeight: '24px',
  paddingTop: '8px',
  paddingBottom: '8px',
  fontSize: '1.4rem',
  color: props.theme.ds.getColorByName('grey800'),
  whiteSpace: 'nowrap',
  textOverflow: 'clip',
  overflow: 'hidden'
});

export const RowText = createComponentWithProxy<RowTextProps>(StyledRowText);
