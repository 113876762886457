/**
 * @prettier
 */

import React from 'react';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';
import { COLORS } from 'app/theme/materialDS';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { Omit } from 'utils/types';

type InteractionCircleProps = {
  disabled: boolean;
  pressed: boolean;
  hovered: boolean;
  focused: boolean;

  hoverHighlightColor: COLORS;
  pressedHighlightColor: COLORS;
  interactiveOpacity?: number;
  hoverInteractiveOpacity?: number;
  activeInteractiveOpacity?: number;
  focusInteractiveOpacity?: number;
};

/*
 * This style is designed to be included as part of the Checkboxes, IconButton and other component base styles.
 * It should be used when you are noticing performance loss from using the 'withInteractivity' HOC.
 *
 * For these styles to work:
 * 1. Its owner is position: relative
 * 2. The sibling element has a z-index of 2 or greater (and a position property if it doesn't already)
 */
type InteractionCircleAfterStyles = Omit<InteractionCircleProps, 'pressed' | 'hovered' | 'focused'>;

export function InteractionCircleAfterStyles(
  props: InteractionCircleAfterStyles & ThemeType,
): IStyle {
  return applyModifiers([
    !props.disabled,
    {
      [':hover::after' as any]: {
        backgroundColor: props.theme.ds.getColorByName(props.hoverHighlightColor),
        opacity: props.interactiveOpacity || props.hoverInteractiveOpacity || 1,
        transform: 'scale(1)',
      },
      [':active::after' as any]: {
        backgroundColor: props.theme.ds.getColorByName(props.pressedHighlightColor),
        opacity: props.interactiveOpacity || props.activeInteractiveOpacity || 1,
        transform: 'scale(1)',
      },
      [':focus::after' as any]: {
        opacity: props.interactiveOpacity || props.activeInteractiveOpacity || 1,
        transform: 'scale(1)',
        outline: 'none',
      },
    },
  ])({
    // Requirements of the styles
    position: 'relative',
    // Interaction circle itself
    ['::after' as any]: {
      content: '" "',
      display: 'block',
      height: '40px',
      width: '40px',
      opacity: 0.09,
      transform: 'scale(0.5)',
      transition: 'opacity 200ms linear, transform 150ms linear',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: props.theme.ds.colors.special.transparent,
      borderRadius: '50%',
      zIndex: 1,
    },
  });
}

/*
 * This component is designed to be used for the IconButton, Checkboxes, Radio buttons and Switches
 * They all have the same type of interaction circle, a 40x40px box with a border radius applied
 *
 * To make this work correctly you need to ensure it that
 * 1. Its parent is position: relative and the wrapper of the element (the 40x40px bounding box)
 * 2. It is a sibling element of the element having the interaction circle applied
 * 3. The sibling element has a z-index of 2 or greater (and a position property if it doesn't already)
 */
const StyledInteractionCircle = (props: InteractionCircleProps & ThemeType): IStyle =>
  applyModifiers(
    [
      (props.hovered || props.focused) && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName(props.hoverHighlightColor),
      },
    ],
    [
      props.pressed && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName(props.pressedHighlightColor),
      },
    ],
    [
      props.pressed || props.hovered || props.focused,
      {
        opacity: props.interactiveOpacity || 1,
        transform: 'scale(1)',
      },
    ],
  )({
    opacity: 0.06,
    transform: 'scale(0.5)',
    transition: 'opacity 200ms linear, transform 150ms linear',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '40px',
    height: '40px',
    backgroundColor: props.theme.ds.colors.special.transparent,
    borderRadius: '50%',
    zIndex: 1,
  });

export const InteractionCircle = createComponent<InteractionCircleProps>(
  StyledInteractionCircle,
  'div',
);
