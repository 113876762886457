/** @format **/

import { find, get, isFunction, isNil, some } from 'lodash';
import moment from 'moment';

import { AppState } from 'interfaces/appState';
import { QueryParameters } from 'utils/fetching/utils';

import { getTopLevelFilterQueryString } from 'app/selectors/topLevelFilters';
import { getTimeZoneName } from 'selectors/time';
import { getHeadlineForError } from 'components/error';

import {
  GenericResourceErrors,
  NetworkError,
  PaginationDirection,
  ResourceAction,
  ResourceError,
} from './types';
import * as Models from './';

export function getEndpointForAction(
  endpoint: string,
  action: ResourceAction,
  resourceId?: string,
): string {
  switch (action) {
    case ResourceAction.FetchingSingle:
    case ResourceAction.Patching:
    case ResourceAction.Deleting:
      if (endpoint.indexOf(':resourceId') > -1) {
        return endpoint.replace(':resourceId', resourceId);
      }
      return `${endpoint}/${resourceId}`;
    case ResourceAction.Creating:
    case ResourceAction.FetchingAll:
    case ResourceAction.Action:
      return endpoint;
    default:
      // ignore coverage
      const _ec: never = action;
  }
}

function isNetworkError(error: ResourceError): error is NetworkError {
  if (isNil(error)) {
    return false;
  }

  return error.errorCode === GenericResourceErrors.NetworkError;
}

export function getNetworkError(errors: ResourceError[]): Error {
  return get(find(errors, isNetworkError), 'meta.error', undefined);
}

// ignore coverage
export function getNetworkErrorString(errors: ResourceError[]): string {
  // ignore coverage
  return getHeadlineForError(getNetworkError(errors));
}

export function hasNetworkError(errors: ResourceError[]) {
  return some(errors, isNetworkError);
}

export function generateInjectedQueryString(
  resource: Models.Resource<any, any, any>,
  state: AppState,
  action: Models.ResourceAction,
  paginationDirection?: PaginationDirection,
): QueryParameters {
  let qs = {};
  const meta = resource.meta;

  if (meta) {
    const injectTopLevelFilters = isFunction(meta.injectTopLevelFilters)
      ? meta.injectTopLevelFilters()
      : meta.injectTopLevelFilters;

    if (meta.injectDateTime) {
      qs = {
        ...qs,
        dateFrom: moment(state.time.from)
          .utc()
          .format(),
        dateTo: moment(state.time.to)
          .utc()
          .format(),
        timeZoneName: getTimeZoneName(),
      };
    }

    if (injectTopLevelFilters) {
      qs = {
        ...qs,
        ...getTopLevelFilterQueryString(state),
      };
    }
  }

  if (action === ResourceAction.FetchingAll) {
    const sorting = state.resources[resource.name].sorting;
    const pagination = state.resources[resource.name].pagination;
    const filtering = state.resources[resource.name].filtering;

    if (pagination && pagination.enabled) {
      const cursor =
        paginationDirection === PaginationDirection.BACKWARDS
          ? pagination.prevCursor
          : pagination.nextCursor;

      qs = {
        ...qs,
        count: pagination.batchSize,
        cursor,
      };
    }

    if (sorting && sorting.enabled) {
      const { attributeName: sortAttribute, direction: sortDirection } = sorting;

      qs = {
        ...qs,
        sortDirection,
        sortAttribute,
      };
    }

    if (filtering && filtering.enabled) {
      const { filters } = filtering;

      /**
       * The filters must be stringified with the JSON library at this point, as the fetch operation uses qs.stringify before the request is made. The qs implementation will loop over the array if it is not turned into a string before hand causing the query string to contain multiple 'filter' entries, one for each item in the array. Also shortens the query string.
       */
      qs = {
        ...qs,
        filters: JSON.stringify(filters),
      };
    }
  }

  return qs;
}
