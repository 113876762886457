/** @format **/

import React from 'react';
import { bindAll, filter, isEmpty, map, intersection, isNil } from 'lodash';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { MultiSelectItem } from 'components/input';

import { TileCategories } from '../../models';

import { AddTileMenu, Tile } from './menu';
import { Button } from './button';
import { getHumanType } from './utils';

export type Props = {
  type: TileCategories;
  tiles: Tile[];
  onTileAdded: (tileId: string) => void;
};
export type State = {
  buttonHovered: boolean;
  menuHovered: boolean;
  menuOpen: boolean;
  nameFilter: string;
  filteredProducts: MultiSelectItem[];
};
export class AddTile extends React.Component<Props, State> {
  buttonRef: any;
  menuRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      buttonHovered: false,
      menuHovered: false,
      menuOpen: false,
      nameFilter: '',
      filteredProducts: [],
    };

    bindAll(this, 'onButtonClick', 'onWindowClick', 'onTileAdded', 'getFilteredProducts');
  }

  componentDidMount() {
    document.addEventListener('click', this.onWindowClick);
  }

  componentWillMount() {
    document.removeEventListener('click', this.onWindowClick);
  }

  getFilteredProducts() {
    return map(this.state.filteredProducts, p => p.value.replace(/ /g, ''));
  }

  onButtonClick() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  onWindowClick(e: MouseEvent) {
    if (isNil(this.menuRef)) {
      return;
    }

    if (
      e.target === this.menuRef ||
      this.menuRef.contains(e.target) ||
      e.target === this.buttonRef ||
      this.buttonRef.contains(e.target) ||
      // This is a hack to work around the fact the clear search filter button
      // is removed from the DOM as soon as it is clicked, which is before
      // this code executes
      !document.contains(e.target as any)
    ) {
      return;
    }

    this.setState({ menuOpen: false });
  }

  onTileAdded(tileId: string) {
    this.setState({ menuOpen: false });
    this.props.onTileAdded(tileId);
  }

  render() {
    const { nameFilter } = this.state;
    const filteredProducts = this.getFilteredProducts();
    const filteredTilesByProduct = filter(
      this.props.tiles,
      t =>
        (isEmpty(filteredProducts) || intersection(filteredProducts, t.products).length > 0),
    );

    if (filteredTilesByProduct.length === 0) {
      return null;
    }

    const filteredTiles = filter(filteredTilesByProduct, t => t.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1);

    return (
      <div style={{ position: 'relative' }}>
        <Button
          type={this.props.type}
          onClick={this.onButtonClick}
          ref={r => (this.buttonRef = r)}
        />

        {this.state.menuOpen && (
          <MenuWrapper innerRef={r => (this.menuRef = r)}>
            <AddTileMenu
              tileAddedCb={this.onTileAdded}
              tiles={filteredTiles}
              title={getHumanType(this.props.type)}
              selectedProducts={this.state.filteredProducts}
              searchFilter={this.state.nameFilter}
              updateSearchFilter={filter => this.setState({ nameFilter: filter })}
              updateProductFilter={filteredProducts => this.setState({ filteredProducts })}
            />
          </MenuWrapper>
        )}
      </div>
    );
  }
}

const StyledMenuWrapper = (): IStyle => ({
  marginTop: '4px',
  position: 'absolute',
  zIndex: 5,
});

const MenuWrapper = createComponent(StyledMenuWrapper, 'div', ['onMouseEnter', 'onMouseLeave']);
