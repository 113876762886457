/** @format **/

import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { ThemeType } from 'app/theme';
import { screenLargeAndAbove } from 'fela-rules/breakpoints';

/**
 * Note: A duplicate styling class exist for at `sass/chrome/_layout` named `layout--revamped-chrome`
 *
 * The above class is rendered on legacy pages for when they are using the Revamped Chrome.
 * This way elements do not overlap one-another.
 */
const StyledContentArea = (props: ThemeType): IStyle => ({
  backgroundColor: props.theme.ds.colors.special.body,
  minHeight: '100%',
  ...screenLargeAndAbove({
    paddingLeft: '64px',
  }),
});

export const ContentArea = createComponentWithProxy(StyledContentArea);
