/**
 * @prettier
 */

import React from 'react';
import { ReactComponent } from 'utils/types';
import { withPressed, WithPressedSuppliedProps } from 'components/hoc/withPressed';
import { withHover, WithHoverSuppliedProps } from 'components/hoc/withHover';
import { withFocus, WithFocusSuppliedProps } from 'components/hoc/withFocus';
import { withEventDisabling, WithEventDisablingProps } from 'components/hoc/withEventDisabling';

export type WithInteractivitySuppliedProps = WithPressedSuppliedProps &
  WithHoverSuppliedProps &
  WithFocusSuppliedProps &
  WithEventDisablingProps<HTMLElement>;

export function withInteractivity<TPublicProps, TFullProps extends WithInteractivitySuppliedProps>(
  Component: ReactComponent<TFullProps>,
): React.ComponentType<TPublicProps> {
  // The HoCs are fucked with the Typescript type system. No way to handle HoCs that wrap props (ie event handlers)
  // This just wraps up all the nasty stuff into one place that the DS components can use
  return withPressed<TPublicProps, TFullProps>(
    withFocus<TFullProps, TFullProps>(
      withHover<TFullProps, TFullProps>(withEventDisabling(Component)),
    ),
  );
}
