/** @format **/

import { Models as ResourceModels } from 'modules/resources';
import { ProductPagesState } from 'sections/productpages';

import * as Models from '../models';
import { ResourceCapabilities } from 'modules/resources/models';

const InviteTeamResourceDefinition: ResourceModels.Resource<ProductPagesState, Models.Recipient> = {
  name: 'InviteTeam',
  endpoint: () => `plan/:planIdentifier/teams/invitemembers`,
  useApp: true,
  meta: {
    notifications: {
      actions: true,
      actionMessages: {
        started: 'Sending invites...',
        failed: 'Failed to invite team members',
        successful: 'Awesome, your invites have been sent!',
      },
    },
  },
  actions: {
    createteam: 'plan/:planIdentifier/teams/invitemembers',
  },
};

const inviteTeamResourceActions = ResourceModels.registerResource(
  InviteTeamResourceDefinition,
  ResourceCapabilities.Actions,
);

export { inviteTeamResourceActions as InviteTeamResource };
