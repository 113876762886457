/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { clearfix } from 'fela-rules/layout';

const StyledDropdownButtonSection = (props: ThemeType): IStyle =>
  clearfix({
    display: 'block',
    padding: '8px 4px',
    borderBottom: `1px solid ${props.theme.colors.border.light}`,
  });

export const DropdownButtonSection = createComponent(StyledDropdownButtonSection);

const StyledDropdownButton = (): IStyle => ({
  display: 'block',
  width: '50%',
  float: 'left',
  padding: '4px',
});

export const DropdownButton = createComponent(StyledDropdownButton, 'div');
