/**
 * @prettier
 */

import { STATUS } from 'constants/status';
import { TileDefinition } from 'modules/dashboard/models';

import {
  ErrorCountChart,
  ErrorGroupListTile,
  ErrorsPerAppListTile,
  SingleValueTile,
} from '../components/tiles';
import {
  crashReportingRequired,
  DEFAULT_DATE_RANGE,
  DEFAULT_GRANULARITY,
  fetchAction,
} from './shared';
import { DESCENDING } from 'constants/sorting';

export const CrashReportingTileMap: TileDefinition[] = [
  {
    id: 'errorCount',
    name: 'Error instances',
    category: 'value',
    fetchAction,
    apiEndpoint: 'errorcount',
    requirements: [crashReportingRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
      StatusFilter: STATUS.ACTIVE,
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-errors',
    products: ['CrashReporting'],
  },
  {
    id: 'errorGroupCount',
    name: 'Error groups count',
    category: 'value',
    fetchAction,
    apiEndpoint: 'errorgroupcount',
    requirements: [crashReportingRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
      StatusFilter: STATUS.ACTIVE,
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-errors',
    products: ['CrashReporting'],
  },
  {
    id: 'recentCrashCount',
    name: 'Recent crashes',
    category: 'value',
    fetchAction,
    apiEndpoint: 'recentcrashcount',
    requirements: [crashReportingRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      Live: true,
      DisplayMode: 'number',
    },
    defaultSettings: {
      Applications: '',
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-errors',
    products: ['CrashReporting'],
  },
  {
    id: 'errorGroupsList',
    name: 'Error groups',
    category: 'list',
    fetchAction,
    apiEndpoint: 'errorgroupslist',
    requirements: [crashReportingRequired],
    metadata: {
      DefaultWidth: 5,
      DefaultHeight: 7,
      SupportsSettings: true,
      SortableFields: {
        ErrorCount: 'Error count',
        LastOccurredOn: 'Last occurred on',
        Message: 'Message',
        UserCount: 'User count',
      },
    },
    defaultSettings: {
      Applications: '',
      StatusFilter: STATUS.ACTIVE,
      TableSortingField: 'LastOccurredOn',
      TableSortingDirection: DESCENDING,
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: ErrorGroupListTile,
    icon: 'dashboard--tiles-errors',
    products: ['CrashReporting'],
  },
  {
    id: 'errorsPerApp',
    name: 'Errors per app',
    category: 'list',
    fetchAction,
    apiEndpoint: 'errorsperapp',
    requirements: [crashReportingRequired],
    metadata: {
      DefaultWidth: 5,
      DefaultHeight: 7,
      SupportsSettings: true,
      SortableFields: {
        ErrorGroupCount: 'Error group count',
        ErrorInstanceCount: 'Error instance count',
        Name: 'Name',
      },
    },
    defaultSettings: {
      Applications: '',
      StatusFilter: STATUS.ACTIVE,
      TableSortingField: 'ErrorGroupCount',
      TableSortingDirection: DESCENDING,
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: ErrorsPerAppListTile,
    icon: 'dashboard--tiles-errors',
    products: ['CrashReporting'],
  },
  {
    id: 'errorCountOverTime',
    name: 'Error count',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'errorcountovertime',
    requirements: [crashReportingRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 4,
      SupportsSettings: true,
      SupportsAppColors: true,
    },
    defaultSettings: {
      Applications: '',
      StatusFilter: STATUS.ACTIVE,
      DateRange: DEFAULT_DATE_RANGE,
      Granularity: DEFAULT_GRANULARITY,
    },
    component: ErrorCountChart,
    icon: 'dashboard--tiles-errors',
    products: ['CrashReporting'],
  },
];
