/** @format */

import * as Showdown from 'showdown';

export const Mention = 'mention';

// ~@user_name-123~
// Note: Serverside adds the ~ to the strings confirming the user
const ServerRegExp = /(~@[a-z0-9_\-\+]+~)/gim;

const MentionExtension = function() {
  const mention: Showdown.RegexReplaceExtension = {
    type: 'lang',
    regex: ServerRegExp,
    replace: (_match: string, mention: string) => {
      return `<span class="text-highlight">${mention.slice(1, -1)}</span>`;
    },
  };

  return [mention];
};

Showdown.extension(Mention, MentionExtension);
