/** @format **/

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isString, find } from 'lodash';

import { AppState } from 'interfaces/appState';
import * as Messages from 'interfaces/messages';

import { applicationIdentifier } from 'app/selectors/application';
import { dismissMessage } from 'app/actions/messages';
import { ReactComponent } from 'utils/types';
import * as MessageComponents from './messages';

import { Banner } from 'components/page/banner';
import { Center } from 'components/center';
import { Typography } from 'components/text';

type MessagePassedProps = Messages.Message;

type MessageDispatchProps = {
  onDismiss: () => void;
};

type MessageProps = MessagePassedProps & MessageDispatchProps;

const DumbMessage: React.FunctionComponent<MessageProps> = (props: MessageProps) => {
  const mapping = getMappingForMessage(props.Key);

  if (!mapping) {
    throw new Error(`Message information not found for '${props.Key}'`);
  }

  const Component = mapping.component;
  const color = isString(mapping.bannerColor) ? mapping.bannerColor : mapping.bannerColor(props);

  return (
    <Banner color={color} dismissable={mapping.dismissable} onDismiss={props.onDismiss}>
      <Center width={1120}>
        <Typography alignment={mapping.alignment}>
          <Component {...props} />
        </Typography>
      </Center>
    </Banner>
  );
};

export const Message = connect<{}, MessageDispatchProps, MessagePassedProps>(
  null,
  (dispatch: Dispatch, passedProps: MessagePassedProps) => ({
    onDismiss: () => dispatch(dismissMessage({ MessageId: passedProps.MessageId }) as any),
  }),
)(DumbMessage);

type bannerColors = 'yellow' | 'blue' | 'red';

type bannerColorMapping = (message: Messages.Message) => bannerColors;

type MessageMapping = {
  key: string[];
  component: ReactComponent<Messages.Message>;
  dismissable?: boolean;
  bannerColor: bannerColors | bannerColorMapping;
  alignment?: 'center';
};

const MessageMappings: MessageMapping[] = [
  {
    key: [
      Messages.MessageTypes.PlanUsageExceeded,
      Messages.MessageTypes.PlanMonthlyProcessingUsageExceededAutoUpgradeable,
      Messages.MessageTypes.PlanMonthlyProcessingUsageCapped,
      Messages.MessageTypes.PlanMonthlyProcessingUsageApproachingAutoUpgradeable,
    ],
    component: MessageComponents.PlanUsageExceeded,
    bannerColor: 'yellow',
    alignment: 'center',
  },
  {
    key: [Messages.MessageTypes.InviteApplicationUsers],
    component: MessageComponents.InviteApplicationUsers,
    bannerColor: 'yellow',
    alignment: 'center',
  },
  {
    key: [Messages.MessageTypes.IntegrationFailure],
    component: MessageComponents.IntegrationFailure,
    bannerColor: 'yellow',
  },
  {
    key: [Messages.MessageTypes.ExpiringCard],
    component: MessageComponents.ExpiredCard,
    bannerColor: 'yellow',
    dismissable: true,
    alignment: 'center',
  },
  {
    key: [Messages.MessageTypes.EmailBlacklisted],
    component: MessageComponents.EmailBlacklisted,
    bannerColor: 'yellow',
    dismissable: true,
    alignment: 'center',
  },
  {
    key: [Messages.MessageTypes.DunningProcess],
    component: MessageComponents.DunningProcess,
    bannerColor: 'yellow',
    alignment: 'center',
  },
  {
    key: [Messages.MessageTypes.AdminGenericMessage],
    component: MessageComponents.AdminGenericMessage,
    bannerColor: (messages: Messages.AdminGenericMessage) => messages.Data.BannerColor,
    alignment: 'center',
    dismissable: true,
  },
  {
    key: [Messages.MessageTypes.AzureSubscriptionSuspended],
    component: MessageComponents.AzureSubscriptionSuspended,
    bannerColor: 'yellow',
    alignment: 'center',
    dismissable: false,
  },
];

function getMappingForMessage(key: string): MessageMapping {
  return find(MessageMappings, map => map.key.indexOf(key) !== -1);
}
