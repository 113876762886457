/** @format **/

import { reduce, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { AppState } from 'interfaces/appState';
import * as Models from './models';

const notificationsSelector = (state: AppState) => state.app.notifications;

export const notifcationsByCreated = createSelector(
  notificationsSelector,
  notificationState => {
    const notificationArray = reduce(
      notificationState,
      (r: Models.Notification[], v: Models.Notification) => [...r, v],
      [],
    );

    return sortBy(notificationArray, (n: Models.Notification) => n.created);
  },
);
