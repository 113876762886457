/** @format */

import * as React from 'react';
import Downshift, { GetItemPropsOptions, GetInputPropsOptions } from 'downshift';
import { some, isNil, debounce, bindAll, isEqual } from 'lodash';
import { Button } from 'components/button/ui';
import { ButtonPatternCancelSubmit } from 'components/button/pattern';
import { Icon } from 'components/icon';
import { DropdownMenu, DropdownSection } from 'components/dropdown/menu';
import { Checkbox } from 'components/checkbox';
import { Search as SearchInput } from 'components/input/search';

export type MultiSelectItem = {
  value: any;
  display: string;
  disabled?: boolean;
};

type Props = {
  selectText: string;
  onChange: (selectedItems: MultiSelectItem[]) => void;
  items: MultiSelectItem[];
  selectedItems?: MultiSelectItem[];
  closeOnOuterClick?: boolean;
  confirmationButtons?: boolean;
  onConfirm?: () => void;
  selectAllOptions?: boolean;
};

type State = {
  selectedItems: MultiSelectItem[];
  isOpen: boolean;
  inputValue: string;
};

const SelectAllItem: MultiSelectItem = Object.freeze({
  display: 'Select all',
  value: 'select-all',
});

export class MultiSelect extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    closeOnOuterClick: true,
    confirmationButtons: false,
    selectAllOptions: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedItems: props.selectedItems || [],
      isOpen: false,
      inputValue: '',
    };

    bindAll(
      this,
      'getItems',
      'toggleDropdown',
      'closeDropdown',
      'updateInputValue',
      'updateFilteredItems',
      'addConditions',
      'renderMenu',
      'renderConfirmationButtons',
    );
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!isNil(nextProps.selectedItems)) {
      this.setState({ selectedItems: nextProps.selectedItems });
    }
  }

  // For reasons unknown to me the Downshift onClick handler is called twice for every click
  // This results in the onChange handler being triggered twice, selecting and deselecting the item
  onChange = debounce((selectedItem: MultiSelectItem) => {
    if (selectedItem.disabled) {
      return;
    }

    let selectedItems: MultiSelectItem[];

    const filteredItems = this.updateFilteredItems(this.state.inputValue);

    if (selectedItem.value === 'select-all') {
      selectedItems = isEqual(this.state.selectedItems.sort(), filteredItems.sort())
        ? []
        : filteredItems;
    } else if (some(this.state.selectedItems, selectedItem)) {
      selectedItems = this.state.selectedItems.filter(i => i.value !== selectedItem.value);
    } else {
      selectedItems = [...this.state.selectedItems, selectedItem];
    }

    this.setState({ selectedItems });
    this.props.onChange(selectedItems);
  }, 0);

  getItems(
    selectedIndex: number,
    selectedItems: MultiSelectItem[],
    getItemProps: (opts: GetItemPropsOptions<MultiSelectItem>) => any,
  ) {
    const filteredItems = this.updateFilteredItems(this.state.inputValue);
    const allSelected = isEqual(this.state.selectedItems.sort(), filteredItems.sort());

    const isItemChecked = (item: MultiSelectItem) => {
      if (item.value === 'select-all') {
        return allSelected;
      }

      return !!item.disabled || some(selectedItems, item);
    };

    if (this.props.selectAllOptions && filteredItems.length > 0) {
      filteredItems.unshift(SelectAllItem);
    }

    return filteredItems.map((item, idx) => (
      <Checkbox
        {...getItemProps({ item })}
        key={item.value}
        isHovered={idx === selectedIndex}
        isChecked={isItemChecked(item)}
        disabled={item.disabled || false}
      >
        {this.itemToString(item)}
      </Checkbox>
    ));
  }

  toggleDropdown() {
    this.setState({ isOpen: !this.state.isOpen, inputValue: '' });
  }

  closeDropdown() {
    this.setState({ isOpen: false });
  }

  updateInputValue(newInput: string) {
    this.setState({ inputValue: newInput });
  }

  updateFilteredItems(searchInput: string) {
    const filteredItems = this.props.items.filter(
      item => item.display.toLowerCase().indexOf(searchInput.toLowerCase()) > -1,
    );

    return filteredItems;
  }

  addConditions() {
    this.props.onConfirm();
    this.setState({ isOpen: false });
  }

  itemToString(item: MultiSelectItem) {
    return item.display;
  }

  renderMenu(
    selectedIndex: number,
    selectedItems: MultiSelectItem[],
    getInputProps: (opts?: GetInputPropsOptions) => any,
    getItemProps: (opts?: GetItemPropsOptions<MultiSelectItem>) => any,
  ) {
    return (
      <DropdownMenu>
        <DropdownSection searchInput>
          <SearchInput {...getInputProps()} onInput={this.updateInputValue} />
        </DropdownSection>
        <DropdownSection checkboxList>
          {this.getItems(selectedIndex, selectedItems, getItemProps)}
        </DropdownSection>
        {this.props.confirmationButtons ? this.renderConfirmationButtons() : null}
      </DropdownMenu>
    );
  }

  renderConfirmationButtons() {
    return (
      <DropdownSection searchInput>
        <ButtonPatternCancelSubmit
          cancel={
            <Button
              type="white"
              size={36}
              text="center"
              block
              fullWidth
              onClick={this.closeDropdown}
            >
              Cancel
            </Button>
          }
          submit={
            <Button
              type="blue"
              size={36}
              text="center"
              block
              fullWidth
              onClick={this.addConditions}
            >
              Add
            </Button>
          }
        />
      </DropdownSection>
    );
  }

  render() {
    return (
      <Downshift
        onChange={this.onChange}
        isOpen={this.state.isOpen}
        itemToString={this.itemToString}
        selectedItem={this.state.selectedItems}
        inputValue={this.state.inputValue}
        onOuterClick={() => this.props.closeOnOuterClick && this.setState({ isOpen: false })}
      >
        {({
          getToggleButtonProps,
          getItemProps,
          getInputProps,
          isOpen,
          highlightedIndex,
          selectedItem,
        }) => (
          <div className="dropdown2 dropdown2--block">
            <Button
              icon
              block
              fullWidth
              size={40}
              text="left"
              type="white"
              iconAfter={<Icon set="flat" inButton="after" type="dropdown" />}
              {...getToggleButtonProps({ onClick: this.toggleDropdown })}
              multiSelectButton
              fontWeightNormal
            >
              {this.props.selectText}
            </Button>

            {isOpen
              ? this.renderMenu(highlightedIndex, selectedItem, getInputProps, getItemProps)
              : null}
          </div>
        )}
      </Downshift>
    );
  }
}
