/**
 * @prettier
 */

export enum WhatsNewCategory {
  Platform = 'Platform',
  CrashReporting = 'Crash Reporting',
  RealUserMonitoring = 'Real User Monitoring',
  APM = 'APM',
  Providers = 'Providers',
  Integrations = 'Integrations',
}

export type WhatsNewItem = {
  author: string;
  date: Date;
  featuredImage: string;
  pinned: boolean;
  preview: boolean;
  relativePermalink: string;
  utmTracking: string;
  summary: string;
  tags: string[];
  title: string;
  type: WhatsNewCategory;
};

export type NetworkWhatsNewItem = WhatsNewItem & {
  date: string;
  type: string;
};

export type WhatsNewBanner = {
  bannerImgSrc: string;
  bannerImgAlt: string;
  bannerLink: string;
  bannerEnabled: boolean;
};

export function parseWhatsNewCategory(str: string): WhatsNewCategory {
  switch (str) {
    case 'Platform':
      return WhatsNewCategory.Platform;
    case 'Crash Reporting':
      return WhatsNewCategory.CrashReporting;
    case 'Real User Monitoring':
      return WhatsNewCategory.RealUserMonitoring;
    case 'APM':
      return WhatsNewCategory.APM;
    case 'Providers':
      return WhatsNewCategory.Providers;
    case 'Integrations':
      return WhatsNewCategory.Integrations;
  }
}
