/**
 * @prettier
 */

import React from 'react';
import { forEach } from 'lodash';

import { PropsOf, ReactComponent } from 'utils/types';
import { BaseEventNames, BaseEventProps } from 'components/ds';

export type WithEventDisablingProps<TElement extends HTMLElement> = {
  disabled: boolean;
  loading: boolean;
} & BaseEventProps<TElement>;

export function withEventDisabling<
  TElement extends HTMLElement,
  TComp extends React.ComponentType<P>,
  P extends WithEventDisablingProps<TElement> = PropsOf<TComp>
>(Component: ReactComponent<P>): React.ComponentType<P> {
  return class WithEventDisabling extends React.Component<P> {
    constructor(props: P) {
      super(props);

      forEach(BaseEventNames, ev => {
        // Forgive me father, for I have sinned.
        // @ts-ignore
        this[ev] = (e: any) =>
          // @ts-ignore
          !this.props.disabled && !this.props.loading && this.props[ev] && this.props[ev](e);
      });
    }

    render() {
      return (
        <Component
          {...this.props}
          // @ts-ignore
          onClick={this.onClick}
          // @ts-ignore
          onMouseUp={this.onMouseUp}
          // @ts-ignore
          onMouseDown={this.onMouseDown}
          // @ts-ignore
          onMouseEnter={this.onMouseEnter}
          // @ts-ignore
          onMouseLeave={this.onMouseLeave}
          // @ts-ignore
          onKeyUp={this.onKeyUp}
          // @ts-ignore
          onKeyDown={this.onKeyDown}
          // @ts-ignore
          onKeyPress={this.onKeyPress}
          // @ts-ignore
          onFocus={this.onFocus}
          // @ts-ignore
          onBlur={this.onBlur}
          // @ts-ignore
          onTouchEnd={this.onTouchEnd}
          // @ts-ignore
          onTouchMove={this.onTouchMove}
          // @ts-ignore
          onTouchStart={this.onTouchStart}
          // @ts-ignore
          onChange={this.onChange}
        />
      );
    }
  };
}
