/** @format **/

import React, { FunctionComponent } from 'react';

import { ModalProps } from 'components/ds/dialog';
import { GatedFeatureModal, ProductDescriptionListItem } from 'components/paywall';

type Props = ModalProps;

export const DashboardsGatedFeatureModal: FunctionComponent<Props> = props => {
  return (
    <GatedFeatureModal
      id={props.id}
      close={props.close}
      heading={'Create unlimited visualizations of your chosen error and performance data'}
      docsLink={'product-guides/dashboards/'}
      spId={'chrome-dashboards'}
      featureName={'Dashboards'}
      featureImage={'dashboards'}
    >
      <ProductDescriptionListItem>
        Build your own dashboard to show the most critical metrics for each team or project
      </ProductDescriptionListItem>
      <ProductDescriptionListItem>
        Arrange data into the most logical view with easy drag-and-drop tiles
      </ProductDescriptionListItem>
      <ProductDescriptionListItem>
        Easily share intuitive visual representations with teams outside engineering
      </ProductDescriptionListItem>
    </GatedFeatureModal>
  );
};