/** @format **/

import React from 'react';

import { Dialog, ModalProps } from 'components/ds/dialog';
import { InviteTeamModule } from './module';

type Props = ModalProps;

export const InviteTeamModal: React.FunctionComponent<Props> = props => (
  <Dialog {...props} closeOnBackdropClick>
    <InviteTeamModule close={() => props.close(props.id)} />
  </Dialog>
);
