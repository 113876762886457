/** @format **/

import * as React from 'react';
import { connect } from 'react-redux';

import { getPage } from 'app/selectors/configuration';
import { AppState } from 'interfaces/appState';
import { findApplicationId, getLastViewedApplicationIdentifier } from 'utils/links';
import { Navigation, NavigationItem } from 'components/page/header/navigation';
import { Controls } from 'modules/trace/components/flamechart/controls';

type StateProps = {
  currentPage: string;
};

type DispatchProps = {};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

type State = {
  activeHash: string;
};

export class UnconnectedTabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setActiveHash = this.setActiveHash.bind(this);
  }

  state: State = {
    activeHash: window.location.hash,
  };

  setActiveHash() {
    this.setState({ activeHash: window.location.hash });
  }

  componentDidMount() {
    window.addEventListener('onhashchange', this.setActiveHash);
  }

  componentWillUnmount() {
    window.removeEventListener('onhashchange', this.setActiveHash);
  }

  render() {
    const lastViewedAppIdentifier = getLastViewedApplicationIdentifier();
    const applicationIdentifier = findApplicationId(
      (window as any).RaygunConfiguration,
      lastViewedAppIdentifier,
    );

    return (
      <Navigation>
        <NavigationItem
          key="Live"
          text="Live"
          to={`/pulse/${applicationIdentifier}#live`}
          isActive={this.state.activeHash === '#live'}
          color={'blue'}
        />
        <NavigationItem
          key="Performance"
          text="Performance"
          to={`/pulse/${applicationIdentifier}#performance`}
          isActive={this.state.activeHash === '#performance'}
          color={'blue'}
        />
        <NavigationItem
          key="Sessions"
          text="Sessions"
          to={`/pulse/${applicationIdentifier}#sessions`}
          isActive={this.state.activeHash === '#sessions'}
          color={'blue'}
        />
        <NavigationItem
          key="Users"
          text="Users"
          to={`/pulse/${applicationIdentifier}#users`}
          isActive={this.state.activeHash === '#users'}
          color={'blue'}
        />
        <NavigationItem
          key="Browsers"
          text="Browsers"
          to={`/pulse/${applicationIdentifier}#browsers`}
          isActive={this.state.activeHash === '#browsers'}
          color={'blue'}
        />
        <NavigationItem
          key="Platforms"
          text="Platforms"
          to={`/pulse/${applicationIdentifier}#platforms`}
          isActive={this.state.activeHash === '#platforms'}
          color={'blue'}
        />
        <NavigationItem
          key="Geo"
          text="Geo"
          to={`/pulse/${applicationIdentifier}#geo`}
          isActive={this.state.activeHash === '#geo'}
          color={'blue'}
        />
        <NavigationItem
          key="Compare"
          text="Compare"
          to={`/pulse/${applicationIdentifier}#compare`}
          isActive={this.state.activeHash === '#compare'}
          color={'blue'}
        />
        <NavigationItem
          key="Settings"
          text="Settings"
          to={`/settings/${applicationIdentifier}/pulse`}
          isActive={
            this.props.currentPage === 'settings:application:pulse' ||
            this.props.currentPage === 'settings:application:pulse:insights'
          }
          color={'blue'}
        />
      </Navigation>
    );
  }
}

const ConnectedTabs = connect<StateProps, DispatchProps, PassedProps, AppState>(state => ({
  currentPage: getPage(state),
}))(UnconnectedTabs);

export { ConnectedTabs as Tabs };
