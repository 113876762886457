/** @format **/

import createActionFactory from 'typescript-fsa';

import * as Models from './models';

const createAction = createActionFactory('setupInstructions/inviteTeamModal');

export const resetRecipients = createAction('RESET_RECIPIENTS');

export const addRecipient = createAction('ADD_RECIPIENT');

export type UpdateRecipientPayload = {
  id: string;
} & Models.Recipient;

export const updateRecipient = createAction<UpdateRecipientPayload>('UPDATE_RECIPIENT');

export const seenInviteTeam = createAction('SEEN_INVITE_TEAM');
