/** @format **/

import * as React from 'react';
import classNames from 'classnames';

import { buildAlertingLink } from "utils/links";

import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { SnowplowLink } from 'components/snowplow';

type Props = {
  title?: string;
  planIdentifier: string;
};

export const AlertingButton: React.FunctionComponent<Props> = ({ title, planIdentifier }) => {
  const classes = classNames('header-switcher-button header-switcher-button--basic');

  return (
    <SnowplowLink interactionId={'sp-app-chrome-switcher-clickAlertingButton'}>
      <a className={classes} title={'Alerting'} href={buildAlertingLink(planIdentifier)}>
        <Icon extraClasses={'header-switcher-button__icon-mobile'} size={16} type={'bell'} center />
        <div className={'hide-mob'}>
          <Flex>
            <div className="header-switcher-button__text">{title}</div>
          </Flex>
        </div>
      </a>
    </SnowplowLink>
  );
};

AlertingButton.defaultProps = {
  title: 'Alerting',
};