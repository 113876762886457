/** @format **/

import React, { FunctionComponent } from 'react';
import { connect } from "react-redux";
import Link from "redux-first-router-link";

import { Module, ModuleBody, ModuleHeaderWithButton } from "components/ds/module";
import { Dialog, ModalProps } from 'components/ds/dialog';
import { H1, H4 } from 'components/ds/text';
import { EmphasisButton, UtilityIconButton } from "components/ds/button";
import { SnowplowLink } from 'components/snowplow';

import { HorizontalRule } from 'components/horizontalRule';
import { Column, Row } from "components/grid";
import { Padding } from "components/layout";
import { Icon } from "components/icon";

import { getPlanIdentifier } from "app/selectors/configuration";
import { ProductPagesState } from "sections/productpages";
import {
  IconWrapper,
  FeatureListContainer,
  HeaderPadding,
  BottomSectionContainer,
  GetFeatureText,
  UpgradeButtonWrapper,
  ModalImagesContainer,
  ModalImage,
} from "./styles";

import { isLaptopSize, isDesktopSize, calculateMaxWidth, calculateMaxWidthForBasicModal } from "./utils";

type StateProps = {
  planIdentifier: string;
};

type PassedProps = {
  heading: string;
  docsLink: string;
  spId: string;
  featureName: string;
  featureImage?: string;
};

type Props = StateProps & PassedProps & ModalProps;

const UnconnectedGatedFeatureModal: FunctionComponent<Props> = props => {
  const isHeroImageVersion = !!props.featureImage && (isLaptopSize() || isDesktopSize());
  const isBasicModal = !props.featureImage;

  return (
    <Dialog width={isBasicModal ? calculateMaxWidthForBasicModal() : calculateMaxWidth()} {...props}>
      <Module forDialog>
        <ModuleHeaderWithButton forButton clearHeader overlapContent>
          <Padding top={4} right={4}>
            <SnowplowLink interactionId={`sp-app-${props.spId}-gatedFeatureModal-clickedClose`}>
              <UtilityIconButton icon="cross" onClick={() => props.close(props.id)}/>
            </SnowplowLink>
          </Padding>
        </ModuleHeaderWithButton>
        <ModuleBody padding={'none'}>
          <Row>
            <Column small={isHeroImageVersion ? 6 : 12} noPadding>
              <FeatureListContainer isBasicModal={isBasicModal}>
                <H4>
                  <IconWrapper>
                    <Icon size={12} set={'icomoon'} type={'crown'} dsColor={'yellow700'} inline />
                  </IconWrapper>
                  Upgrade to use this feature
                </H4>
                <HeaderPadding>
                  <H1>{props.heading}</H1>
                </HeaderPadding>
                <ul>
                  {props.children}
                </ul>
              </FeatureListContainer>

              <HorizontalRule/>

              <BottomSectionContainer isBasicModal={isBasicModal}>
                <GetFeatureText>Get {props.featureName} and more from $80.00/month on Team and Business plans.</GetFeatureText>

                <UpgradeButtonWrapper>
                  <SnowplowLink interactionId={`sp-app-${props.spId}-gatedFeatureModal-clickedSeeUpgradeOptions`}>
                    <a href={`/plan/${props.planIdentifier}?editing=true`} onClick={() => props.close(props.id)}>
                      <EmphasisButton>See upgrade options</EmphasisButton>
                    </a>
                  </SnowplowLink>
                </UpgradeButtonWrapper>

                <SnowplowLink interactionId={`sp-app-${props.spId}-gatedFeatureModal-clickedDocsLink`}>
                  <Link
                    to={`https://raygun.com/documentation/${props.docsLink}`}
                    target={'_blank'}
                  >
                    Learn more in the docs
                  </Link>
                </SnowplowLink>
              </BottomSectionContainer>
            </Column>

            {isHeroImageVersion && (
              <Column small={6} noPadding>
                <ModalImagesContainer>
                  <ModalImage src={`/Content/Images/product-screenshots/feature-gating/${props.featureImage}.png`} alt={'Raygun Feature'}/>
                </ModalImagesContainer>
              </Column>
            )}
          </Row>
        </ModuleBody>
      </Module>
    </Dialog>
  );
};

const ConnectedGatedFeatureModal = connect<StateProps, {}, {}, ProductPagesState>(
  state => ({
    planIdentifier: getPlanIdentifier(state),
  }),
)(UnconnectedGatedFeatureModal);

export { ConnectedGatedFeatureModal as GatedFeatureModal };