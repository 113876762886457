/** @format */

import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { IStyle } from 'fela';

export type RuleProps = {
  inlineBlock?: boolean;
  rightPadding?: boolean;
  noPadding?: boolean;
  bottomMargin?: boolean;
  bottomBorder?: boolean;
  left?: boolean;
  dropdown?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
};
type Props = RuleProps & ThemeType;

const StyledCheckboxWrapper = (props: Props) => {
  const styles: IStyle = {
    padding: '4px 4px 4px 24px',
    cursor: 'pointer',
    display: 'block',
    position: 'relative',
    lineHeight: '16px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    minHeight: '16px',
  };

  return applyModifiers(
    [props.inlineBlock, { display: 'inline-block' }],
    [props.rightPadding, { paddingRight: '16px' }],
    [props.noPadding, { padding: '0 0 0 24px' }],
    [props.bottomMargin, { marginBottom: '24px' }],
    [
      props.bottomBorder,
      {
        borderBottom: `1px solid ${props.theme.colors.border.standard}`,
        paddingBottom: '16px',
        marginBottom: '16px',
      },
    ],
    [props.left, { padding: '4px 24px 4px 4px' }],
    [props.dropdown, { padding: '10px 16px 10px 32px' }],
  )(styles);
};

export const CheckboxWrapper = createComponent(StyledCheckboxWrapper, 'label', [
  'onMouseEnter',
  'onMouseLeave',
  'className',
  'onClick',
]);
