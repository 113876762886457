/** @format */

import * as React from 'react';
import classnames from 'classnames';

interface Props {
  sections: React.ReactNode[];
  tightPadding?: boolean;
}

export const ModuleSidebar: React.FunctionComponent<Props> = props => {
  const classes = classnames('module-sidebar__section', {
    'module-sidebar__section--padding-16': props.tightPadding,
  });

  const items = props.sections.map((item, index) => {
    return (
      <div key={index} className={classes}>
        {item}
      </div>
    );
  });

  return <div className="module-sidebar">{items}</div>;
};

ModuleSidebar.defaultProps = {
  tightPadding: false,
};

export default ModuleSidebar;
