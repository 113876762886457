/**
 * @prettier
 */

import * as React from 'react';

import { Flex } from 'components/flex/index';
import { Icon, IconButton } from 'components/icon/index';

import { ErrorsPerAppListData } from '../../../../actions';

export const NavigationCellFactory = (
  performNavigation: (appId: number) => void,
): React.FunctionComponent<ErrorsPerAppListData> => ({ Id }) => (
  <Flex justify="flex-end">
    <a href="javascript:;" onClick={() => performNavigation(Id)}>
      <IconButton color="blue" iconSize={16} size={32}>
        <Icon set="flat" type="forward" />
      </IconButton>
    </a>
  </Flex>
);
