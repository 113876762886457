/** @format */

import * as React from 'react';
import { get } from 'lodash';
import { List, ListItem, ListCell } from 'components/list';
import { Avatar } from 'components/avatar';
import { Formatting } from 'utils/date';
const { ActivityActions } = require('utils/auditlog/activityActions');
import { AuditLogListData } from '../../actions';
import { AuditLogListLoading } from './loading/auditlogList';
import { TileDataProvider, Props as ProviderProps } from './dataProvider';

type Props = ProviderProps;

function parseAuditLogEntry(entry: AuditLogListData): JSX.Element {

  const MicrosoftTeamsUser = "msteams+integration+internal@raygun.io";
  const SlackUser = "slack+integration+internal@raygun.io";

  const prettyAction = (() => {
    const action = ActivityActions.getActionPretty(entry.Action);

    if (entry.Action === 'UnlockedProduct' && entry.ActivityItemOverviewCount > 0) {
      return `${action} ${entry.ActivityItemOverviews[0].Item} in`;
    }

    return action;
  })();
  const linkText = (() => {
    if (entry.Action === 'UnlockedProduct') {
      return entry.ApplicationName;
    }

    if (
      entry.Action === 'CreatedPersonalAccessToken' ||
      entry.Action === 'UpdatedPersonalAccessToken' ||
      entry.Action === 'RegeneratedPersonalAccessToken' ||
      entry.Action === 'DeletedPersonalAccessToken'
    ) {
      // first item has the format "{tokenName} with scopes:"
      // we remove the "with scopes:" as we are only displaying a single item (ie no scopes)
      const index = entry.ActivityItemOverviews[0].Item.indexOf('with scopes:');
      return entry.ActivityItemOverviews[0].Item.substring(0, index);
    }

    if (entry.ActivityItemOverviewCount > 0) {
      return entry.ActivityItemOverviews[0].Item;
    }
  })();
  const linkUrl = (() => {
    if (entry.ActivityItemOverviewCount > 0) {
      return entry.ActivityItemOverviews[0].Url;
    }
  })();

  const getUserGravatar = () => {
    if (entry.User == SlackUser) {
      return "/Content/Images/icons/integrations/slack-icon-40.png";
    } else if (entry.User == MicrosoftTeamsUser) {
      return "/Content/Images/icons/integrations/ms-teams-icon-40.png";
    }
    return entry.UserGravatar;
  };

  return (
    <ListItem type="white" onlyBorderBetween key={entry.ActivityEventId}>
      <ListCell noSpaceLeft width={60}>
        <div className="avatar-layout">
          <div className="avatar-layout__main">
            <Avatar alt="Application" url={entry.OverviewGravatar} size={32} />
          </div>
          <div className="avatar-layout__secondary">
            <Avatar alt="Avatar" url={getUserGravatar()} size={24} circle />
          </div>
        </div>
      </ListCell>
      <ListCell>
        <div className="dashboard-list-component">
          <div className="dashboard-list-component__audit-action">
            <p className="text-ellipsis">
              {entry.User} {prettyAction}
            </p>
          </div>
        </div>
        <div className="dashboard-list-component__audit-url">
          <p className="text-ellipsis">
            {linkUrl && <a href={linkUrl}>{linkText}</a>}
            {!linkUrl && linkText}
          </p>
        </div>
        <div className="dashboard-list-component__app">
          {entry.ApplicationName ? `${entry.ApplicationName} - ` : (entry.PlanName ? `${entry.PlanName} - ` : '')}{' '}
          {Formatting.formatAsTimeAgo(entry.OccurredOn)}
        </div>
      </ListCell>
    </ListItem>
  );
}

const AuditLogListTile: React.FunctionComponent<Props> = props => {
  const data = get(props, 'data', []) as AuditLogListData[];

  return <List>{data.map(item => parseAuditLogEntry(item))}</List>;
};

const ConnectedTile = TileDataProvider(AuditLogListTile, AuditLogListLoading);

export { ConnectedTile as AuditLogTile };
