/**
 * @prettier
 */

import React from 'react';
import { assign } from 'lodash';

import { SortDirection } from 'constants/sorting';
import { TableCellWidths } from 'constants/widths';

import { TableRowProperties } from 'components/table/tableRow';

type RowItemFunction = (rowItem: any) => any;
type Action = () => any;

export abstract class CellBase {
  accessor?: string | RowItemFunction | Action;

  constructor(init?: Partial<CellBase>) {
    assign(this, init);
  }

  getValue(rowItem: any) {
    if (this.accessor) {
      if (typeof this.accessor === 'string') {
        return rowItem[this.accessor];
      } else {
        const func = this.accessor as RowItemFunction;
        if (func) {
          return func(rowItem);
        } else {
          return (this.accessor as Action)();
        }
      }
    }
    return null;
  }

  abstract render(rowItem: any): JSX.Element;
}

export interface SortOptions {
  defaultSortDirection: SortDirection;
  sortField?: string; // If this is not provided, the accessor from the cell is used (if it's a string)
}

export interface Column<T = {}> {
  header: string | JSX.Element;
  cellName?: string;
  width?: TableCellWidths | undefined;
  sortOptions?: SortOptions; // The existence of this defines whether or not this column can be clicked to sort
  cell: CellBase | React.ComponentType<T>;
  padding?: CellPadding;
  align?: 'top' | 'middle';
  flatLeft?: boolean;
  flatRight?: boolean;
  spaceLeft?: boolean;
  headerSpaceRight?: boolean;
}

export type CellPadding = 'tight' | 'standard' | 'large' | 'xlarge' | 'xxlarge' | 'none';

export type TableRowPropsMap = {
  [key: string]: TableRowProperties;
};

export type DefaultTableProps = {
  columns: Column[];
  data: any[];
  padding?: CellPadding;
  gutters?: boolean;
  isHeaderHidden?: boolean;
  noDataMessage?: string;
  header?: React.ReactNode;
  rowClassName?: string;
  rowProps?: TableRowPropsMap;
};
