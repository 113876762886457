/** @format **/

import React from 'react';
import { isNil } from 'lodash';

import { IconProps } from 'components/icon';

import { Checkbox } from 'components/ds/selection';

import { MenuItem, MenuItemProps } from './item';
import { SvgProps } from 'components/svg';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';
import { applyModifiers } from 'fela-rules/modifiers';

type IconContainerProps = {
  height: number;
  spacing?: boolean;
  leftSpacing?: boolean;
};
const StyledIconContainer = (props: IconContainerProps): IStyle =>
  applyModifiers(
    [
      props.spacing && isNil(props.leftSpacing),
      {
        marginLeft: '16px',
        marginTop: '8px',
      },
    ],
    [
      props.leftSpacing && isNil(props.spacing),
      {
        marginLeft: '16px',
      },
    ],
  )({
    height: `${props.height}px`,
    marginLeft: '8px',
    marginRight: '8px',
  });
export const IconContainer = createComponent(StyledIconContainer, 'div');

export type MenuItemWithIconProps = {
  Icon: React.ReactElement<IconProps> | React.ReactElement<SvgProps>;
  iconHeight?: number;
  spacing?: boolean;
  leftSpacing?: boolean;
} & MenuItemProps;

export const MenuItemWithIcon: React.FunctionComponent<MenuItemWithIconProps> = ({
  Icon,
  iconHeight,
  spacing,
  leftSpacing,
  ...menuItemProps
}) => {
  return (
    <MenuItem {...menuItemProps} icon>
      <IconContainer height={iconHeight} spacing={spacing} leftSpacing={leftSpacing}>
        {React.cloneElement(Icon as any, {
          size: iconHeight,
          width: iconHeight,
          height: iconHeight,
        })}
      </IconContainer>
    </MenuItem>
  );
};

MenuItemWithIcon.defaultProps = {
  iconHeight: 24,
};
