/** @format */
import React from 'react';
import { AllApplications, FavouriteApplications } from './applicationList';
import { AllDashboards, FavouriteDashboards } from './dashboardList';

export const Applications: React.FunctionComponent = () => (
  <>
    <FavouriteApplications />
    <AllApplications />
  </>
);

export const Dashboards: React.FunctionComponent = () => (
  <>
    <FavouriteDashboards />
    <AllDashboards />
  </>
);