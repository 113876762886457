/** @format */

import { IStyle } from 'fela';
import * as CssTypes from 'csstype';
import { createComponentWithProxy } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

import * as Colors from 'app/theme/materialDS';
import { Theme } from 'app/theme';

type TextStyles = {
  size: number;
  weight: 400 | 600;
  lineHeight: string | number;
};

type TextProps = {
  forLink?: boolean;
  bold?: boolean;
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: CssTypes.FontWeightProperty;
  color?: Colors.COLORS;
  inline?: boolean;
  wrapWithEllipsis?: boolean;
  alignment?: CssTypes.TextAlignProperty;
  title?: string;
  textDecorationLine?: CssTypes.TextDecorationLineProperty;
  whiteSpace?: CssTypes.WhiteSpaceProperty;
  overflowWrap?: CssTypes.OverflowWrapProperty;
};

const StyledTextGenerator = ({ size, weight, lineHeight }: TextStyles) => (
  props: TextProps & { as: string },
): IStyle =>
  applyModifiers(
    [
      props.forLink || props.as === 'a',
      {
        color: Theme.ds.colors.link.inactive,
        [':hover' as any]: {
          color: Theme.ds.colors.link.active,
        },
        [':active' as any]: {
          color: Theme.ds.colors.link.active,
        },
        [':focus' as any]: {
          color: Theme.ds.colors.link.active,
        },
        cursor: 'pointer',
      },
    ],
    [
      !!props.color,
      {
        color: Theme.ds.getColorByName(props.color),
        [':hover' as any]: {
          color: Theme.ds.getColorByName(props.color),
        },
        [':active' as any]: {
          color: Theme.ds.getColorByName(props.color),
        },
        [':focus' as any]: {
          color: Theme.ds.getColorByName(props.color),
        },
      },
    ],
    [
      props.bold,
      {
        fontWeight: 600,
      },
    ],
    [
      !!props.lineHeight,
      {
        lineHeight: props.lineHeight,
      },
    ],
    [
      !!props.fontSize,
      {
        fontSize: props.fontSize,
      },
    ],
    [
      !!props.fontWeight,
      {
        fontWeight: props.fontWeight,
      },
    ],
    [
      props.inline,
      {
        display: 'inline',
      },
    ],
    [
      props.wrapWithEllipsis,
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    ],
    [
      !!props.alignment,
      {
        textAlign: props.alignment,
      },
    ],
    [
      !!props.textDecorationLine,
      {
        textDecorationLine: props.textDecorationLine,
      },
    ],
    [
      !!props.whiteSpace,
      {
        whiteSpace: props.whiteSpace,
      },
    ],
    [
      !!props.overflowWrap,
      {
        overflowWrap: props.overflowWrap,
      },
    ],
  )({
    display: 'block',
    font: `${Theme.fonts}`,
    color: Theme.ds.getColorByName('grey800'),
    fontSize: `${size}rem`,
    fontWeight: weight,
    lineHeight: `${lineHeight}`,
  });

export const H1 = createComponentWithProxy<TextProps>(
  StyledTextGenerator({
    size: 2.4,
    weight: 600,
    lineHeight: 1.333,
  }),
  'h1',
);

export const H2 = createComponentWithProxy<TextProps>(
  StyledTextGenerator({
    size: 1.8,
    weight: 600,
    lineHeight: 1.333,
  }),
  'h2',
);

export const H3 = createComponentWithProxy<TextProps>(
  StyledTextGenerator({
    size: 1.6,
    weight: 600,
    lineHeight: 1.5,
  }),
  'h3',
);

export const H4 = createComponentWithProxy<TextProps>(
  StyledTextGenerator({
    size: 1.4,
    weight: 600,
    lineHeight: 1.714,
  }),
  'h4',
);

export const LargeBody = createComponentWithProxy<TextProps>(
  StyledTextGenerator({
    size: 1.6,
    weight: 400,
    lineHeight: 1.75,
  }),
  'p',
);

export const Body = createComponentWithProxy<TextProps>(
  StyledTextGenerator({
    size: 1.4,
    weight: 400,
    lineHeight: 1.714,
  }),
  'p',
);

export const SmallBody = createComponentWithProxy<TextProps>(
  StyledTextGenerator({
    size: 1.3,
    weight: 400,
    lineHeight: 1.850,
  }),
  'p',
);

export const Link = createComponentWithProxy<TextProps>(
  StyledTextGenerator({
    size: 1.4,
    weight: 400,
    lineHeight: 1.714,
  }),
  'a',
);

Link.defaultProps = {
  forLink: true,
};

const UnderlineStyle = (): IStyle => ({
  textDecorationStyle: 'dashed',
  textUnderlineOffset: '2px',
  whiteSpace: 'nowrap',
  color: Colors.GREY.grey800,
  textDecorationColor: Colors.BLUE_GREY.blueGrey300,
});

export const Underline = createComponentWithProxy(UnderlineStyle, 'u');