/** @format */

import * as React from 'react';
import classnames from 'classnames';

import * as Models from '../models';

interface Props extends Models.Notification {
  dismiss: (id: string) => void;
}

interface State {
  dismiss: boolean;
}

export class Notification extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.dismiss = this.dismiss.bind(this);
    this.resetDismissTimeout = this.resetDismissTimeout.bind(this);
  }

  timeoutId: number;

  resetDismissTimeout() {
    if (!this.props.disableDismiss) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = window.setTimeout(this.dismiss, 2500);
    }
  }

  state: State = {
    dismiss: false,
  };

  dismiss() {
    if (!this.props.disableDismiss) {
      this.setState(() => {
        setTimeout(() => this.props.dismiss(this.props.id), 250);
        return { dismiss: true };
      });
    }
  }

  componentDidMount() {
    this.resetDismissTimeout();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.disableDismiss && !this.props.disableDismiss) {
      // Startup the timer if we were dismissing
      this.resetDismissTimeout();
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const classes = classnames('notification it-notification', {
      'notification--success it-notification-success': this.props.type === Models.TYPES.SUCCESS,
      'notification--error it-notification-error': this.props.type === Models.TYPES.ERROR,
      'notification--warning': this.props.type === Models.TYPES.WARNING,
      'notification--disappear': this.state.dismiss,
    });

    return (
      <div className={classes} onClick={this.dismiss} onMouseEnter={this.resetDismissTimeout}>
        <p className="notification__message">{this.props.message}</p>
      </div>
    );
  }
}
