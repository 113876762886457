/** @format */

import prefixer from 'fela-plugin-prefixer';
import fallbackValue from 'fela-plugin-fallback-value';
import unit from 'fela-plugin-unit';
import monolithic from 'fela-monolithic';
import beautifier from 'fela-beautifier';
import { createRenderer as felaCreateRenderer } from 'fela';
import { get } from 'lodash';

const devEnhancers = [
  // Typescript definitions don't have the argument defined
  // Doesn't matter, just cast to any
];

declare var process: {};

export function createRenderer() {
  const build: string = get(process || {}, 'env.NODE_ENV', 'dev');

  const enhancers =
    build === 'production' ? [] : [(monolithic as any)({ prettySelectors: true }), beautifier()];

  return felaCreateRenderer({
    enhancers: enhancers,
    plugins: [prefixer(), fallbackValue(), unit('rem')],
  });
}
