/** @format */

import { Action } from 'typescript-fsa';
import React from 'react';

import { Application } from 'interfaces/application';
import { Products } from 'interfaces/products';
import { Plan } from 'interfaces/plan';
import * as DateTimeRanges from 'constants/dateTimeFilter';
import { SortDirection } from 'constants/sorting';

import { QueryParameters } from 'utils/fetching/utils';

import { FetchDataPayload } from '../actions';
import { TextAlignment } from './text';

export type DisplayMode = 'number' | 'raw' | 'percent' | 'duration';

export type TileMetadata = {
  MinWidth?: number;
  MinHeight?: number;
  DefaultWidth?: number;
  DefaultHeight?: number;
  DisplayMode?: DisplayMode;
  SupportsAppColors?: boolean;
  SupportsSettings?: boolean;
  Live?: boolean;
  QueryString?: QueryParameters | { (state: TileState, settings: TileSettings): QueryParameters };
  HideHeader?: boolean;
  DataFetching?: {
    dataOnWebApi?: boolean;
    needsPlanIdentifier?: boolean;
    apmEndpoint?: boolean;
  };
  SortableFields?: { [key: string]: string };
  SingleApplicationOnly?: boolean;
};
export type TileSettings = {
  Title: string;
  DateRange?: string;
  StatusFilter?: string;
  TableSortingField?: string;
  TableSortingDirection?: SortDirection;
  Granularity?: string;
  ResourceType?: string;
  Applications?: string;
  Actions?: string;
  TileColor?: string;
  Text?: string;
} & TextSettings;

export type TileHelpTips = {
  HelpTipText: string;
};

export const TileTransparent = 'transparent';

export const TextBold = 'bold';

export const TextItalic = 'italic';

export const TextUnderline = 'underline';

export const isTextBold = (weight: typeof TextBold) => weight === TextBold;

export const isTextItalic = (italic: typeof TextItalic) => italic === TextItalic;

export const isTextUnderline = (underline: typeof TextUnderline) => underline === TextUnderline;

export type TextSettings = {
  TextSize?: string;
  TextAlignment?: TextAlignment;
  TextBold?: typeof TextBold;
  TextItalic?: typeof TextItalic;
  TextUnderline?: typeof TextUnderline;
  TextColor?: string;
};

export type TileData = {
  data: FetchDataPayload;
  loading: boolean;
  error: Error;
};

export type TileState = {
  isDrilledDown: boolean;
  viewingApplication?: number;
  sorting?: {
    sortField: string;
    sortDirection: SortDirection;
  };
  pageIndex?: number;
};

export type SelectedErrorGroup = {
  applicationId: number;
  errorGroupId: number;
};

export type SortingState = {
  sortField: string;
  sortDirection: SortDirection;
};

export type Tile = {
  InstanceId: string;
  Settings: TileSettings;
  Metadata: TileMetadata;
  Type: string;
  X: number;
  Y: number;
  Height: number;
  Width: number;
};

export enum DashboardTypes {
  Screenboard,
  Timeboard,
}

export type TimeboardTimeRanges =
  | typeof DateTimeRanges.ONE_HOUR
  | typeof DateTimeRanges.SIX_HOUR
  | typeof DateTimeRanges.ONE_DAY
  | typeof DateTimeRanges.SEVEN_DAYS
  | typeof DateTimeRanges.THIRTY_DAYS;

export type DashboardDefinition = {
  Title: string;
  Colors: { [key: string]: string };
  Tiles: Tile[];
  Type: DashboardTypes;
  Applications: string[];
  TimeRange: TimeboardTimeRanges;
};

export type TileProps = {
  instanceId: string;
  type: string;
} & { settings: TileSettings } & { metadata: TileMetadata };

export type FooterTypes = React.ReactNode | React.Component<{ id: string }>;

export type RenderFooterCallback = (state: any, id: string) => boolean;

export type ModuleProps = {
  footer: FooterTypes;
  renderFooter: RenderFooterCallback;
  id: string;
  noScrolling: boolean;
  overflow: boolean;
  contentAbove: boolean;
  noPadding: boolean;
};

export type TileComponent<T = TileProps> = {
  moduleProps?: Partial<ModuleProps>;
} & React.ComponentType<T>;

export enum ActionFilterType {
  Error = 'Error',
  Application = 'Application',
  Plan = 'Plan',
}

export type TileCategories = 'free-text' | 'list' | 'value' | 'chart';

export type TileRequirement = (app: Application, plan?: Plan) => boolean;

export type TileDefinition = {
  id: string;
  name: string;
  component: TileComponent;
  fetchAction: (payload: FetchActionPayload) => FetchAction[];
  apiEndpoint?: string;
  category: TileCategories;
  icon: string;
  products: (keyof typeof Products)[];
  requirements?: TileRequirement[];
  defaultSettings?: Partial<TileSettings>;
  metadata?: TileMetadata;
  helpTips?: TileHelpTips;
};

export type FetchActionPayload = {
  instanceId: string;
};
type FetchAction = Action<any>;

export type getTileInfo = () => TileDefinition[];

export type getDefinitionForTile = (id: string) => TileDefinition;

export type getComponentForTile = (id: string) => TileComponent;

export type getFetchActionsForTile = (id: string) => (payload: FetchActionPayload) => FetchAction[];

export type getIconForTile = (id: string) => React.ReactNode;

export type getApiEndpointForTile = (id: string) => string;

export type getTilesByTileCategory = (id: TileCategories) => TileDefinition[];

export type TileMap = {
  getTileInfo: getTileInfo;
  getDefinitionForTile: getDefinitionForTile;
  getComponentForTile: getComponentForTile;
  getFetchActionsForTile: getFetchActionsForTile;
  getIconForTile: getIconForTile;
  getApiEndpointForTile: getApiEndpointForTile;
  getTilesByTileCategory: getTilesByTileCategory;
};

// This must match the following enums: 
// - backend at src/App/Mindscape.Raygun.Api/Resources/Errors/IgnoredType.cs
// - frontend/marionette at src/App/Mindscape.Raygun.Website/Scripts/Raygun/modules/global/constants.js
export enum IgnoredType {
  IgnoreByOccurrences = 0,
  IgnoreForDuration = 1,
}