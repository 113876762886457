/**
 * @prettier
 */

import { createRoutingAction } from 'utils/routing';

export const GoToPaywall = createRoutingAction<{ product: string; planIdentifier: string }>(
  'GO_TO_PAYWALL',
);


export const GoToAllProductsExpiredPaywall = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_ALL_PRODUCTS_EXPIRED_PAYWALL',
);
