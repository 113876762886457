/** @format */

import * as React from 'react';
import { connect } from 'react-redux';

import * as Actions from 'modules/dashboard/actions';
import { State as DashboardState } from 'modules/dashboard';

import { Tooltip } from 'components/tooltip';
import { Padding } from 'components/layout';
import { UtilityIconButton } from 'components/ds/button';
import { openModal } from 'components/modal';
import { ConfirmModal, ConfirmModalProps } from 'components/modal/prefabs';

type StateProps = {};

type DispatchProps = {
  openConfirmModal: () => void;
};

type PassedProps = {
  hasUpdates: boolean;
};

type Props = StateProps & DispatchProps & PassedProps;

const DiscardModalProps: ConfirmModalProps = {
  headerText: 'Revert Overview',
  confirmText: 'Are you sure you wish to discard all changes you have made?',
  confirmButtonText: 'Yes, revert',
  callback: dispatch => dispatch(Actions.revertLayout()),
  doNotShowAgain: true,
};

export const UnconnectedDiscardChanges: React.FunctionComponent<Props> = props => (
  <Tooltip label="Discard changes" dock="bottom-right">
    <Padding left="4">
      <UtilityIconButton icon="cross" onClick={props.openConfirmModal} />
    </Padding>
  </Tooltip>
);

export const DiscardChanges = connect<StateProps, DispatchProps, PassedProps, DashboardState>(
  null,
  (dispatch, passedProps) => ({
    openConfirmModal: () => {
      if (!passedProps.hasUpdates) {
        DiscardModalProps.callback(dispatch);
      } else {
        dispatch(openModal(ConfirmModal, DiscardModalProps));
      }
    },
  }),
)(UnconnectedDiscardChanges);
