/** @format **/

import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import {
  getSecondaryLinksForProduct,
  showCrashRazorHeader,
  showRumRazorHeader,
  showPlanSettingsHeader,
} from 'app/selectors/chrome';
import { Center } from 'components/center';
import { Header as PageHeader } from 'components/page/header';

import { HeaderTypes, SecondaryHeaderLink } from '../secondaryTabs/models';
import { Header } from './header';

type StateProps = {
  secondaryLinkArray: SecondaryHeaderLink[];
  showCrashRazorHeader: boolean;
  showRumRazorHeader: boolean;
  showPlanSettingsHeader: boolean;
};

type DispatchProps = {};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedRazorPageHeader: React.FunctionComponent<Props> = props => {
  const showRazorHeader =
    props.showCrashRazorHeader || props.showRumRazorHeader || props.showPlanSettingsHeader;

  var headerType: HeaderTypes = 'base';

  if (props.showCrashRazorHeader) {
    headerType = 'crash';
  } else if (props.showRumRazorHeader) {
    headerType = 'rum';
  } else if (props.showPlanSettingsHeader) {
    headerType = 'planSettings';
  }

  const title = props.showPlanSettingsHeader ? 'Plan & Billing' : null;

  if (showRazorHeader) {
    return (
      <PageHeader hasNavigation>
        <Center width="full">
          <Header headerType={headerType} title={title} secondaryLinks={props.secondaryLinkArray} />
        </Center>
      </PageHeader>
    );
  } else {
    return null;
  }
};

const ConnectedRazorPageHeader = connect<StateProps, DispatchProps, PassedProps, AppState>(
  state => ({
    secondaryLinkArray: getSecondaryLinksForProduct(state),
    showCrashRazorHeader: showCrashRazorHeader(state),
    showRumRazorHeader: showRumRazorHeader(state),
    showPlanSettingsHeader: showPlanSettingsHeader(state),
  }),
)(UnconnectedRazorPageHeader);

export { ConnectedRazorPageHeader as RazorPageHeader };
