/** @format */

import { takeLatest, takeEvery, take, put, call, select, fork, race } from 'redux-saga/effects';
import { SagaIterator, channel, Channel } from 'redux-saga';
import { Action } from 'typescript-fsa';
import { get, find } from 'lodash';

import { planIdentifier, apiKey } from 'app/selectors/application';
import { fetchFromApp, fetchFromAPI } from 'utils/fetching';
import { getTimeZoneName, getTimeZoneOffset } from 'selectors/time';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Models from '../models';
import * as Constants from '../constants';
import { DashboardModuleStateAtom } from '../state';

import { TileDefinition, TileSettings } from '../models';

import { deleteErrorGroups, setErrorGroupStatus } from './errorGroups';

const tileDataOnWebApi = {
  value: true,
  chart: true,
};
const needsPlanIdentifier = {
  sessionCountOverTime: true,
};
const needsApmEndpoint = {
  apdex: true,
  requestBreakdown: true,
  requestExecutionSpeed: true,
  requestsPerMinute: true,
  externalApiCallsPerMinute: true,
  requestsPerMinuteSvt: true,
  queriesPerMinute: true,
  topActiveIssues: true,
  slowestMethods: true,
};

function* fetchData(
  settings: TileSettings,
  tile: TileDefinition,
  tileId: string,
  successChan: Channel<Actions.FetchDataPayload>,
  errorChan: Channel<Error>,
) {
  const state: DashboardModuleStateAtom = yield select();
  const globalSettings = Selectors.getGlobalSettings(state);
  const tileState = Selectors.getTileState(state)[tileId];

  const dataOnWebApi = get(tileDataOnWebApi, tile.category, false);
  const usePlanIdentifierOnWebApi = get(needsPlanIdentifier, tile.id, false);
  const useApmEndpoint =
    get(needsApmEndpoint, tile.id, false) || get(tile.metadata, 'DataFetching.apmEndpoint', false);

  const queryString = Models.getQueryString(settings, tile.metadata, tileState, globalSettings);

  const webapiUrl = `tiledashboard/${tile.apiEndpoint}`;
  const webapiUrlWithPlanIdentifier = `tiledashboard/${planIdentifier(state)}/${tile.apiEndpoint}`;
  const webappUrl = `tiledashboard/${planIdentifier(state)}/${tile.apiEndpoint}`;
  const apmUrl = `apm/${planIdentifier(state)}/${tile.apiEndpoint}`;

  let url = '';

  if (useApmEndpoint) {
    url = apmUrl;
  } else if (dataOnWebApi) {
    if (usePlanIdentifierOnWebApi) {
      url = webapiUrlWithPlanIdentifier;
    } else {
      url = webapiUrl;
    }
  } else {
    url = webappUrl;
  }

  try {
    const tileData = yield call(
      dataOnWebApi || useApmEndpoint ? fetchFromAPI : fetchFromApp,
      url,
      apiKey(state),
      {
        ...queryString,
        timezoneoffset: getTimeZoneOffset(),
        timeZoneName: getTimeZoneName(),
      },
    );

    yield put(successChan, tileData);
  } catch (e) {
    yield put(errorChan, e);
  }
}

function* fetchTileData(action: Action<Models.FetchActionPayload>) {
  const { instanceId } = action.payload;
  const state: DashboardModuleStateAtom = yield select();

  yield put(Actions.fetchTileDataStarted({ instanceId }));

  const tile = find(Selectors.getCurrent(state).Tiles, t => t.InstanceId === instanceId);
  const tileMetadata = Constants.getDefinitionForTile(tile.Type);

  const successChan = yield call(channel);
  const errorChan = yield call(channel);

  yield fork(fetchData, tile.Settings, tileMetadata, instanceId, successChan, errorChan);

  const { success, error } = yield race({
    success: take(successChan),
    error: take(errorChan),
  });

  if (success) {
    yield put(Actions.fetchTileDataSucceeded({ instanceId, payload: success }));
  } else {
    yield put(Actions.fetchTileDataFailed({ instanceId, error }));
  }
}

function* fetchDrilldownData(action: Action<Actions.FetchDrilldownDataBase>) {
  const state: DashboardModuleStateAtom = yield select();

  const tile = find(
    Selectors.getCurrent(state).Tiles,
    t => t.InstanceId === action.payload.parentTileId,
  );
  const tileMetadata = Constants.getDefinitionForTile('errorGroupsList');
  const settings: TileSettings = {
    ...tile.Settings,
    Applications: action.payload.appId.toString(),
  };

  const successChan = yield call(channel);
  const errorChan = yield call(channel);

  yield fork(
    fetchData,
    settings,
    tileMetadata,
    action.payload.parentTileId,
    successChan,
    errorChan,
  );

  const { success, error } = yield race({
    success: take(successChan),
    error: take(errorChan),
  });

  if (success) {
    yield put(Actions.fetchDrilldownData.done({ ...action.payload, data: success }));
  } else {
    yield put(Actions.fetchDrilldownData.error({ ...action.payload, error }));
  }
}

export function* Saga(): SagaIterator {
  yield takeEvery(Actions.fetchTileData.type, fetchTileData);
  yield takeEvery(Actions.fetchDrilldownData.start.type, fetchDrilldownData);
  yield takeLatest(Actions.deleteErrors.type, deleteErrorGroups);
  yield takeLatest(Actions.setErrorGroupStatus.type, setErrorGroupStatus);
}
