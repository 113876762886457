/** @format */

export const isAnonymousPage = window.location.href.indexOf('external') !== -1;

export const anonymousPagePrefix = 'external/';

export function addAnonymousRoutePrefix(url: string, isAnon: boolean = isAnonymousPage): string {
  if (isAnon) {
    return `${anonymousPagePrefix}${url}`;
  }
  return url;
}
