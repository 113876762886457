/** @format **/

import { IRenderer, IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { applyModifiers } from 'fela-rules/modifiers';

import { Theme } from 'app/theme';

type ActiontipWrapperProps = {
  fullWidthTrigger?: boolean;
};

type ActiontipContainerProps = {
  visible: boolean;
  alignRight?: boolean;
};

function StyledActiontipWrapper(props: ActiontipWrapperProps): IStyle {
  return applyModifiers([
    props.fullWidthTrigger,
    {
      width: '100%',
    },
  ])({
    position: 'relative',
    display: 'inline-block',
  });
}

export const ActiontipWrapper = createComponentWithProxy(StyledActiontipWrapper);

function StyledActiontipContainer(props: ActiontipContainerProps, renderer: IRenderer): IStyle {
  const transformString = 'translate(-50%, calc(-100% - 16px))';
  const rightAlignedTransformString = 'translate(calc(50% - 33px), calc(-100% - 16px))';

  const animateIn = () => ({
    ['0%' as any]: {
      transform: 'translate(-50%, 0px)',
    },
    ['100%' as any]: {
      transform: transformString,
    },
  });

  const rightAlignedAnimateIn = () => ({
    ['0%' as any]: {
      transform: 'translate(calc(50% - 33px), 0px)',
    },
    ['100%' as any]: {
      transform: rightAlignedTransformString,
    },
  });

  return applyModifiers(
    [
      props.visible,
      {
        animation: `${renderer.renderKeyframe(
          props.alignRight ? rightAlignedAnimateIn : animateIn,
          props,
        )} 0.4s cubic-bezier(0.0, 0.0, 0.2, 1);`,
      },
    ],
    [
      props.alignRight,
      {
        right: '0',
        left: 'initial',
        transform: rightAlignedTransformString,
        whiteSpace: 'nowrap',
        width: 'auto',
      },
    ],
  )({
    left: '50%',
    top: '0',
    transform: transformString,
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: Theme.ds.getColorByName('green500'),
    color: Theme.ds.getColorByName('white'),
    padding: '10px 16px',
    borderRadius: '2px',
    fontSize: '13px',
    fontWeight: 600,
    fontStyle: 'normal',
    width: '100%',
    maxWidth: '246px',
  });
}

export const ActiontipContainer = createComponentWithProxy(StyledActiontipContainer);

function StyledActiontipLabel(): IStyle {
  return {
    display: 'block',
  };
}

export const ActiontipLabel = createComponentWithProxy(StyledActiontipLabel, 'span');

function StyledActiontipArrow(): IStyle {
  const arrowWidthDelta = -(8 / 2) + 1;
  return applyModifiers()({
    position: 'absolute',
    bottom: `${arrowWidthDelta}px`,
    left: '50%',
    transform: 'translateX(-50%) rotate(45deg)',
    width: '16px',
    height: '16px',
    backgroundColor: Theme.ds.getColorByName('green500'),
    display: 'block',
  });
}

export const ActiontipArrow = createComponentWithProxy(StyledActiontipArrow);
