/**
 * @prettier
 */

import * as React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';
import { Tooltip } from '../tooltip';

interface SectionProps {
  hidden?: boolean;
  searchInput?: boolean;
  searchResultsSize?: 'medium' | 'large' | null;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const DropdownSection: React.FunctionComponent<SectionProps> = props => {
  const dropdownSectionClassNames = classnames('dropdown2-section', {
    'dropdown2-section--search-input': props.searchInput,
    'dropdown2-section--search-results': !isNil(props.searchResultsSize),
    'dropdown2-section--search-results-medium': props.searchResultsSize === 'medium',
    'dropdown2-section--search-results-large': props.searchResultsSize === 'large',
    hidden: props.hidden,
  });

  return (
    <div className={dropdownSectionClassNames} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export type OPTION_COLORS = 'red' | 'blue' | 'yellow' | 'green' | 'purple';

interface OptionProps {
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
  onSelect?: () => void;
  color?: OPTION_COLORS;
  forCheckbox?: boolean;
  tabIndex?: number;
  id?: string;
  className?: string;
  disabled?: boolean;
  tooltip?: boolean;
  tooltipMsg?: string;
}

const DropdownOption: React.FunctionComponent<OptionProps> = props => {
  const classes = classnames(
    'dropdown2-section__option',
    {
      [`dropdown2-section__option--${props.color}`]: !isNil(props.color) && !props.disabled,
      'dropdown2-section__option--checkbox': props.forCheckbox,
      'dropdown2-section__option--disabled': props.disabled,
    },
    props.className,
  );
  const onClick =
    !props.disabled && (props.onClick || props.onSelect)
      ? (e: React.MouseEvent<any>) => {
          if (props.onClick) {
            props.onClick(e);
          }
          if (props.onSelect) {
            props.onSelect();
          }
        }
      : null;

  const onKeyUp =
    props.onKeyUp || props.onSelect
      ? (e: React.KeyboardEvent<any>) => {
          if (props.onKeyUp) {
            props.onKeyUp(e);
          }
          if (props.onSelect && e.key === 'Enter') {
            props.onSelect();
          }
        }
      : null;

  return (
    <Tooltip show={props.tooltip} label={props.tooltipMsg} block dock="right">
      <a
        className={classes}
        href={props.href}
        onClick={onClick}
        onKeyUp={onKeyUp}
        tabIndex={props.tabIndex}
        id={props.id}
      >
        {props.children}
      </a>
    </Tooltip>
  );
};

DropdownOption.defaultProps = {
  href: null,
  onClick: null,
  disabled: false,
  tooltip: false,
};

const DropdownTitle: React.FunctionComponent<{}> = props => {
  return <div className="dropdown2-section__title">{props.children}</div>;
};

interface DropdownSelectProps {
  selected?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
  tabIndex?: number;
  id?: string;
}

const DropdownSyntaxOption: React.FunctionComponent<DropdownSelectProps> = props => {
  const classes = classnames('dropdown2-section__syntax', {
    'dropdown2-section__syntax--active': props.selected,
  });

  return (
    <a
      className={classes}
      onClick={props.onClick}
      onKeyUp={props.onKeyUp}
      tabIndex={props.tabIndex}
      id={props.id}
    >
      {props.children}
    </a>
  );
};

export { DropdownSection, DropdownTitle, DropdownOption, DropdownSyntaxOption };
