/** @format */

import * as React from 'react';
import { CellBase } from 'components/table/tableModel';
import { identity } from 'lodash';

import { Button, Props as ButtonProps } from 'components/button';

export class ButtonCell extends CellBase {
  buttonProps: ButtonProps;

  onClick: (rowItem: any) => void;

  constructor(init?: Partial<ButtonCell>) {
    super(init);
  }

  render(rowItem: any) {
    const value = this.getValue(rowItem);

    return (
      <Button onClick={() => this.onClick(rowItem)} {...this.buttonProps}>
        {value}
      </Button>
    );
  }
}
