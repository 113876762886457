/**
 * @prettier
 */

import React from 'react';
import { omit } from 'lodash';

import { Theme } from 'app/theme';

import {
  SwitchWrapper,
  SwitchVisible,
  SwitchCheckbox,
  SwitchLabelWrapper,
  SwitchLabelLabel,
  SwitchLabelSwitch,
} from 'components/switch/content';

export type Props = {
  isChecked: boolean;
  onToggled: (checked: boolean) => void;
  reverse?: boolean;
  smallFontSize?: boolean;
  inline?: boolean;
  width?: number;
  height?: number;
  padding?: number;
  checkedBackgroundColor?: string;
  small?: boolean;
};

const SMALL_WIDTH = 36;
const SMALL_HEIGHT = 20;

export class Switch extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  static defaultProps: Partial<Props> = {
    smallFontSize: false,
    width: 56,
    height: 24,
    padding: 2,
    checkedBackgroundColor: Theme.colors.green.standard,
  };

  onChange(input: React.ChangeEvent<HTMLInputElement>) {
    this.props.onToggled(input.currentTarget.checked);
  }

  render() {
    const widthOverride = this.props.small ? SMALL_WIDTH : this.props.width;
    const heightOverride = this.props.small ? SMALL_HEIGHT : this.props.height;

    const checkbox = (
      <SwitchWrapper inline={this.props.inline}>
        <SwitchCheckbox type="checkbox" checked={this.props.isChecked} onChange={this.onChange} />
        <SwitchVisible
          {...omit(this.props, ['ref', 'onToggled', 'children', 'width', 'height'])}
          width={widthOverride}
          height={heightOverride}
        />
      </SwitchWrapper>
    );

    return this.props.children ? (
      <SwitchLabel label={this.props.children} smallFontSize={this.props.smallFontSize}>
        {checkbox}
      </SwitchLabel>
    ) : (
      checkbox
    );
  }
}

type LabelProps = {
  label: React.ReactNode;
  smallFontSize?: boolean;
};

const SwitchLabel: React.FunctionComponent<LabelProps> = props => {
  return (
    <SwitchLabelWrapper>
      <SwitchLabelLabel smallFontSize={props.smallFontSize}>{props.label}</SwitchLabelLabel>
      <SwitchLabelSwitch marginRight={0}>{props.children}</SwitchLabelSwitch>
    </SwitchLabelWrapper>
  );
};
