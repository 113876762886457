/** @format **/

import { QueryParameters } from 'utils/fetching/utils';
import { Omit } from 'utils/types';
import { AppState } from 'interfaces/appState';
import { SORTING_DIRECTION } from 'interfaces/sorting';

export enum ResourceCapabilities {
  Fetching = 1,
  Patching = 2,
  Deleting = 4,
  Creating = 8,
  Actions = 16,
  Pagination = 32,
  Sorting = 64,
  Filtering = 128,
}

export enum ResourceAction {
  FetchingAll,
  FetchingSingle,
  Patching,
  Deleting,
  Creating,
  Action,
}

export enum ResponseTypes {
  Success = 0,
  PartialSuccess = 1,
  Failure = 2,
  PaginationSuccess = 3,
}

export enum GenericResourceErrors {
  NetworkError = 999,
  ResourceNotFound = 1000,
  UnableToDeleteResource = 1001,
  ValidationError = 1002,
  FailedToGenerateQueryString = 1003,
}

export enum PaginationDirection {
  FORWARDS = 'Forwards',
  BACKWARDS = 'Backwards',
}

export type NetworkError = {
  errorCode: GenericResourceErrors.NetworkError;
  meta: {
    error: Error;
  };
};

export type ResourceError =
  | {
      errorCode: number;
      meta?: object;
    }
  | NetworkError;

export type SuccessResponse = {
  type: ResponseTypes.Success;
  resources: ResourceObject[];
};
export type PaginationSuccessResponse = {
  type: ResponseTypes.PaginationSuccess;
  resources: ResourceObject[];
  pagination: {
    nextCursor: string;
    prevCursor: string;
    totalRecords: number;
  };
};
export type PartialSuccessResponse = {
  type: ResponseTypes.PartialSuccess;
  resources: ResourceObject[];
  errors: ResourceError[];
};
export type FailureResponse = {
  type: ResponseTypes.Failure;
  errors: ResourceError[];
};
export type Response =
  | SuccessResponse
  | PaginationSuccessResponse
  | PartialSuccessResponse
  | FailureResponse;

export type ResourceId = string | number;

export type NotificationMessages = {
  started: string;
  successful: string;
  failed: string;
};

export type ResourceObject = {
  id: ResourceId;
  [key: string]: any;
};

export type Resource<
  StateType,
  ObjectSchema extends ResourceObject,
  _ErrorUnion = ResourceError,
  ResponseSchema = ObjectSchema,
  _CreateSchema = Omit<ObjectSchema, 'id'>,
  _Actions extends string = any
> = {
  name: string;
  endpoint: string | { (state: StateType): string };
  queryString?:
    | QueryParameters
    | { (state: StateType, actionType: ResourceAction, resourceId?: ResourceId): QueryParameters };
  transformer?: (response: ResponseSchema) => ObjectSchema;
  useApp?: boolean;
  caching?: {
    expirationTime: number;
  };
  meta?: {
    injectDateTime?: boolean;
    injectTopLevelFilters?: boolean | (() => boolean);
    notifications?: {
      fetching?: boolean | ((response: ResponseSchema) => boolean);
      fetchingMessages?: NotificationMessages;
      patching?: boolean | ((response: ResponseSchema) => boolean);
      patchingMessages?: NotificationMessages;
      deleting?: boolean | ((response: ResponseSchema) => boolean);
      deletingMessages?: NotificationMessages;
      creating?: boolean | ((response: ResponseSchema) => boolean);
      creatingMessages?: NotificationMessages;
      actions?: boolean;
      actionMessages?: NotificationMessages;
    };
  };
  actions?: { [type: string]: string | { (state: StateType): string } };
};

export type FetchActions<ObjectSchema extends ResourceObject> = {
  fetch: (resourceId: ResourceId, completionCallback?: (object: ObjectSchema[]) => void) => void;
  fetchList: (completionCallback?: (object: ObjectSchema[]) => void) => void;
  get: (resourceId: ResourceId, state: AppState) => ObjectSchema;
  getAll: (state: AppState) => ObjectSchema[];
  isLoading: (resourceId: ResourceId, state: AppState) => boolean;
  isListLoading: (state: AppState) => boolean;
};

export type PatchActions<ObjectSchema> = {
  patch: (
    resourceId: ResourceId,
    updates: Partial<ObjectSchema>,
    completionCallback?: (object: ObjectSchema[]) => void,
  ) => void;
  isPatching: (resourceId: ResourceId, state: AppState) => boolean;
};

export type DeleteActions = {
  deleteItem: (resourceId: ResourceId, completionCallback?: () => void) => void;
  isDeleting: (resourceId: ResourceId, state: AppState) => boolean;
};

export type CreateActions<CreateSchema, ObjectSchema> = {
  create: (updates: CreateSchema, completionCallback?: (object: ObjectSchema[]) => void) => void;
  isCreating: (state: AppState) => boolean;
};

export type PerformActions<ActionTypes> = {
  perform: (type: ActionTypes, payload: any, completionCallback?: (res: any) => void) => void;
  isPerformingAction: (type: ActionTypes, state: AppState) => boolean;
};

export type ResourceActions<ErrorUnion, ObjectSchema> = {
  isActive: (resourceId: ResourceId, state: AppState) => boolean;
  getError: (resourceId: ResourceId, state: AppState) => (ErrorUnion | ResourceError)[];
  getGlobalError: (state: AppState) => (ErrorUnion | ResourceError)[];
  clear: () => void;
  store: (resource: ObjectSchema, transform?: boolean) => void;
};

export type PaginationActions = {
  setBatchSize: (batchSize: number) => void;
  previousPage: (completionCallback?: () => void) => void;
  nextPage: (completionCallback?: () => void) => void;
  loadMore: (completionCallback?: () => void) => void;
  canLoadMore: (state: AppState) => boolean;
  canPaginateBackwards: (state: AppState) => boolean;
  resetPagination: () => void;
  getTotalNumberOfRecords: (state: AppState) => number;
  getBatchSize: (state: AppState) => number;
  getNextCursor: (state: AppState) => string;
};

export type SortingActions = {
  sortBy: (attribute: string, direction?: SORTING_DIRECTION) => void;
  getSortDirection: (state: AppState) => SORTING_DIRECTION;
  getSortAttribute: (state: AppState) => string;
};

export type FilteringActions<ObjectSchema extends ResourceObject> = {
  updateFilters: (
    filters: Filter[],
    triggerFetch?: boolean,
    completionCallback?: (res: ObjectSchema[]) => void,
  ) => void;
  getFilters: (state: AppState) => any[];
};

export type DeleteResourceResponse = {
  success: boolean;
  errorMessage?: string;
};

export type Filter = string | number;
