/** @format */

import * as React from 'react';
import classnames from 'classnames';

type Props = {
  sidebar: React.ReactNode;
  width?: '320' | '400' | '280' | '220' | '200' | '48';
  border?: boolean;
  isChart?: boolean;
  matchBackgroundColor?: boolean;
  showSidebar?: boolean;
  borderBottom?: boolean;
  showResizeButton?: boolean;
  showResizeButtonAsClosed?: boolean;
  onResizeButtonClick?: () => void;
};

export const ModuleLayout: React.FunctionComponent<Props> = props => {
  const sidebarClassnames = classnames('module-layout__item', {
    'module-layout__item--48': props.width === '48',
    'module-layout__item--200': props.width === '200',
    'module-layout__item--220': props.width === '220',
    'module-layout__item--280': props.width === '280',
    'module-layout__item--320': props.width === '320',
    'module-layout__item--400': props.width === '400',
    'module-layout__item--match-background': props.matchBackgroundColor,
    'module-layout__item--no-border': props.border === false,
    'module-layout__item--resize-button': props.showResizeButton,
  });
  const sidebarStyles = props.showSidebar ? {} : { width: '0' };
  const sidebarWrapperStyles = props.showSidebar ? {} : { display: 'none' };

  const contentClassnames = classnames('module-layout__item', {
    'module-layout__item--chart': props.isChart,
  });

  const moduleClasses = classnames('module-layout', {
    'module-layout--border-bottom': props.borderBottom,
  });

  const resizeClasses = classnames('module-layout__item-resize-button', {
    'module-layout__item-resize-button--open': props.showResizeButtonAsClosed,
  });

  const resizeButton = <div className={resizeClasses} onClick={props.onResizeButtonClick} />;

  return (
    <div className={moduleClasses}>
      <div className={contentClassnames}>{props.children}</div>
      <div className={sidebarClassnames} style={sidebarStyles}>
        {props.showResizeButton && resizeButton}
        <div style={sidebarWrapperStyles}>{props.sidebar}</div>
      </div>
    </div>
  );
};

ModuleLayout.defaultProps = {
  border: true,
  width: '320',
  isChart: false,
  matchBackgroundColor: false,
  showSidebar: true,
  borderBottom: false,
};

export default ModuleLayout;
