import React from 'react';
import { isNil } from "lodash";
import { connect } from 'react-redux';
import { AppState } from "interfaces/appState";
import {
  getCurrentApplicationSwitcherAvatar,
  getCurrentApplicationSwitcherName,
  isAppSwitcherOpen,
  isDashboardSwitcherOpen,
} from "app/selectors/chrome";
import { getCurrentApplication } from "app/selectors/application";
import { Switcher, SwitcherProps } from "./switcher";

export const ApplicationSwitcherButton = connect<SwitcherProps>(
  (state: AppState) => ({
    open: isAppSwitcherOpen(state),
    title: getCurrentApplicationSwitcherName(state),
    enableTitle: !isNil(getCurrentApplication(state)),
    avatar: getCurrentApplicationSwitcherAvatar(state)
  })
)(Switcher);

export const DashboardSwitcherButton = connect<SwitcherProps>(
  (state: AppState) => ({
    open: isDashboardSwitcherOpen(state),
    title: "Dashboards"
  })
)(Switcher);

