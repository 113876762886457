/** @format **/

import moment, { Moment } from 'moment';
import { isNil } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { getQueryStringParam } from 'utils/routing/queryString';

import * as Actions from '../actions';
import { TimeState } from '../state';

const ONE_EIGHTY_DAYS = '180 day';
const ONE_MONTH = '1 month';
const ONE_DAY = '1 day';

/**
 * Check the query string for the `range` param, and add return the date from and to - only if the range has been set
 */
function getDateParamsFromRange(): { dateFrom: string | undefined; dateTo: string | undefined } {
  const range = getQueryStringParam('range');
  let dateFrom: Moment;

  switch (range) {
    case ONE_EIGHTY_DAYS:
      dateFrom = moment().subtract('180', 'days');
      break;
    case ONE_MONTH:
      dateFrom = moment().subtract('1', 'month');
      break;
    case ONE_DAY:
      dateFrom = moment().subtract('1', 'day');
      break;
    default:
      dateFrom = moment().subtract('1', 'week');
      break;
  }

  return {
    dateFrom: !isNil(dateFrom) ? dateFrom.toISOString() : undefined,
    dateTo: !isNil(range) ? moment().toISOString() : undefined,
  };
}

const dateRangeParams = getDateParamsFromRange();
const dateFrom = getQueryStringParam('dateFrom', dateRangeParams.dateFrom);
const dateTo = getQueryStringParam('dateTo', dateRangeParams.dateTo);

export const defaultState: TimeState = {
  from: dateFrom ? moment(dateFrom).format() : undefined,
  to: dateTo ? moment(dateTo).format() : undefined,
};

export const TimeReducer = reducerWithInitialState(defaultState).case(
  Actions.updateTimeRange,
  (_state, { from, to }) => ({
    from: from.format(),
    to: to.format(),
  }),
);
