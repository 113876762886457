/** @format **/

import { Store } from 'redux';
import { isNil } from 'lodash';

import { AppState } from 'interfaces/appState';

import { getPlanId, getAppId, isInOwnersTeam } from 'app/selectors/configuration';

import {
  ApplicationPermissions,
  PermissionCategory,
  PermissionTypes,
  PlanPermissions,
} from './models';
import * as Selectors from './selectors';

let _store: Store<AppState>;
let _state: AppState;

export function setStore(store: Store<AppState>) {
  _store = store;
  _state = store.getState();
}

/**
 * Used only to make the manager testable
 * @param state: AppState
 */
export function setState(state: AppState) {
  _state = state;
}

/**
 * Handles Plan based permissions
 * @param planPermissions, all plan permissions user currently has
 * @param permissionRequired, the permission that we are checking for
 * @param planId?, optional target plan to check permission on.
 */
function hasPlanPermission(
  planPermissions: { [key: number]: PlanPermissions },
  permissionRequired: PermissionTypes,
  planId: number,
) {
  const targetPlanId = planId ? planId : getPlanId(_state);
  const currentPlanPermissions = planPermissions[targetPlanId];
  return !isNil(currentPlanPermissions) && currentPlanPermissions.indexOf(permissionRequired) > -1;
}

/**
 * Handles Application based permissions
 * @param applicationPermissions, all plan permissions user currently has
 * @param permissionRequired, the permission that we are checking for
 * @param appId? optional target app to check permission on.
 */
function hasApplicationPermission(
  applicationPermissions: { [key: number]: ApplicationPermissions },
  permissionRequired: PermissionTypes,
  appId: number,
) {
  const targetAppId = appId ? appId : getAppId(_state);
  const currentAppPermissions = applicationPermissions[targetAppId];
  return !isNil(currentAppPermissions) && currentAppPermissions.indexOf(permissionRequired) > -1;
}

/**
 * Main permission checking function
 * @param permissionRequired
 * @param category, type of permission i.e PLAN or APPLICATION.
 * @param appId, target app to check
 * @param planId, target plan to check
 */
export function can(
  permissionRequired: PermissionTypes,
  category: PermissionCategory = PermissionCategory.ALL,
  appId: number = null,
  planId: number = null,
): boolean {
  const targetPlanId = planId ? planId : getPlanId(_state);
  const permissions = Selectors.getPermissions(_state);
  const { PlanPermissions, ApplicationPermissions } = permissions;

  if (category === PermissionCategory.PLAN) {
    return hasPlanPermission(PlanPermissions, permissionRequired, targetPlanId);
  } else if (category === PermissionCategory.APPLICATION) {
    return hasApplicationPermission(ApplicationPermissions, permissionRequired, appId);
  }

  const planPermission = hasPlanPermission(PlanPermissions, permissionRequired, targetPlanId);
  const applicationPermission = hasApplicationPermission(
    ApplicationPermissions,
    permissionRequired,
    appId,
  );
  return planPermission || applicationPermission;
}
