/** @format */

import * as React from 'react';
import { assign } from 'lodash';

import { CellBase } from '../tableModel';
import { Switch } from 'components/switch';

export class SwitchCell extends CellBase {
  onToggled: (rowItem: any, isChecked: boolean) => void;

  label: string = null;

  small: boolean;

  constructor(init?: Partial<SwitchCell>) {
    super(init);
    assign(this, init);
  }

  render(rowItem: any) {
    const value = this.getValue(rowItem) as boolean;

    const handleToggled = (isChecked: boolean) => {
      this.onToggled(rowItem, isChecked);
    };

    return (
      <Switch onToggled={handleToggled} isChecked={value} small={this.small}>
        {this.label}
      </Switch>
    );
  }
}
