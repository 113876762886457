/** @format */

import { SagaIterator } from 'redux-saga';
import { fork } from 'redux-saga/effects';

import * as TilesSagas from './tiles';
import * as DashboardSagas from './dashboard';
import * as TileSettingSagas from './tileSetting';

export { TilesSagas, DashboardSagas };

export function* Saga(): SagaIterator {
  yield fork(TilesSagas.Saga);
  yield fork(DashboardSagas.Saga);
  yield fork(TileSettingSagas.Saga);
}
