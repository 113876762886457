/** @format */
import { combineReducers } from 'redux';

import { SwitcherReducer, SwitcherStateAtom } from './switcher';
import { SidebarReducer, SidebarStateAtom } from './sidebar';

type RevampedChromeState = {
  revampedChrome: {
    switcher: SwitcherStateAtom;
    sidebar: SidebarStateAtom;
  }
};

const RevampedChromeReducer = combineReducers({
  switcher: SwitcherReducer,
  sidebar: SidebarReducer
});

export {
  RevampedChromeState,
  RevampedChromeReducer
};