/**
 * @prettier
 */

import React from 'react';
import { capitalize, toArray, isNil } from 'lodash';

import { STATUS, STATUS_TITLE } from 'constants/status';

import { FormLayout } from 'components/input';
import { Checkbox } from 'components/checkbox';
import { Text as BasicText } from 'components/text';

type Props = {
  statusFilterSettingString: string;
  updateStatusFilterSetting: (isChecked: boolean, status: string) => void;
};

export const StatusFilter: React.FunctionComponent<Props> = (props: Props) => {
  const statusFilterSetting: string[] = !isNil(props.statusFilterSettingString)
    ? props.statusFilterSettingString.split(',')
    : [];

  const statusCheckboxes = toArray(STATUS).map(status => (
    <li key={`status-code-${status}`}>
      <Checkbox
        isChecked={statusFilterSetting.indexOf(status) !== -1}
        onToggled={isChecked => props.updateStatusFilterSetting(isChecked, status)}
      >
        {capitalize(STATUS_TITLE[status])}
      </Checkbox>
    </li>
  ));

  return (
    <FormLayout marginBottom>
      <BasicText height="32">Statuses to include</BasicText>
      <ul className="form-list">{statusCheckboxes}</ul>
    </FormLayout>
  );
};
