/** @format */

import * as React from 'react';
import classnames from 'classnames';

import { ListCellWidths } from 'constants/widths';

export interface Props {
  width?: ListCellWidths;
  noSpaceLeft?: boolean;
  spaceLeft?: boolean;
  spaceRight?: boolean;
  borderRight?: boolean;
  tightTop?: boolean;
  spaceVertical?: '4' | '12' | '16' | '24';
}

export const Cell: React.FunctionComponent<Props> = props => {
  const className = classnames('active-list-cell', {
    'active-list-cell--vertical-small': props.spaceVertical === '4',
    'active-list-cell--vertical-large': props.spaceVertical === '12',
    'active-list-cell--vertical-16': props.spaceVertical === '16',
    'active-list-cell--vertical-24': props.spaceVertical === '24',
    'active-list-cell--space-left-none': props.noSpaceLeft,
    'active-list-cell--space-left': props.spaceLeft,
    'active-list-cell--space-right': props.spaceRight,
    'active-list-cell--border-right': props.borderRight,
    'active-list-cell--tight-space-top': props.tightTop,
    [`active-list-cell--${props.width}`]: props.width !== undefined,
  });

  return <span className={className}>{props.children}</span>;
};

Cell.defaultProps = {
  spaceLeft: false,
  spaceRight: false,
  borderRight: false,
  noSpaceLeft: false,
};

export default Cell;
