/** @format **/

import { IRenderer, IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { isNil } from 'lodash';

import { applyModifiers } from 'fela-rules/modifiers';
import { screenLargeAndAbove, screenLargeAndBelow } from 'fela-rules/breakpoints';

import { Theme } from 'app/theme';

type HelptipWrapperProps = {
  centered?: boolean;
  block?: boolean;
  fullWidthTrigger?: boolean;
  showOnRight?: boolean;
  paddingRight?: boolean;
};

type HelptipContainerProps = {
  big: boolean;
  small: boolean;
  hasLink: boolean;
  hovered: boolean;
  centered?: boolean;
  alignRight?: boolean;
  positionRight?: boolean;
  block?: boolean;
  showOnRight?: boolean;
  disableAnimation?: boolean;
};

type HelptipArrowPositionProps = {
  arrowPosition?: number;
  showOnRight?: boolean;
};

type HelptipLinkProps = {
  inline?: boolean;
};

function StyledHelptipWrapper(props: HelptipWrapperProps): IStyle {
  return applyModifiers(
    [
      props.centered,
      {
        display: 'flex',
        justifyContent: 'center',
      },
    ],
    [
      props.fullWidthTrigger,
      {
        width: '100%',
      },
    ],
    [
      !props.showOnRight,
      {
        position: 'relative',
      },
    ],
    [
      props.block,
      {
        display: 'block',
      },
    ],
    [
      props.paddingRight,
      {
        paddingRight: '8px',
      },
    ],
  )({
    display: 'inline-block',
    cursor: 'default',
  });
}

export const HelptipWrapper = createComponentWithProxy(StyledHelptipWrapper);

function StyledHelptipContainer(props: HelptipContainerProps, renderer: IRenderer): IStyle {
  const animateIn = () => ({
    ['0%' as any]: {
      transform: 'translateY(30px)',
    },
    ['100%' as any]: {
      transform: 'translateY(0px)',
    },
  });

  return applyModifiers(
    [
      props.hovered && !props.showOnRight && !props.positionRight,
      {
        animation: `${renderer.renderKeyframe(
          animateIn,
          props,
        )} 0.4s cubic-bezier(0.0, 0.0, 0.2, 1);`,
      },
    ],
    [
      props.positionRight,
      {
        left: 'calc(100% + 24px)',
        marginTop: '-8px',
        position: 'absolute',
        transform: 'translateY(-50%)',
      },
    ],
    [
      props.alignRight,
      {
        right: 0,
      },
    ],
    [
      props.showOnRight,
      {
        ...screenLargeAndAbove({
          left: '100%',
          marginLeft: '16px',
          transform: 'translateY(-50%)',
        }),
        ...screenLargeAndBelow({
          marginTop: '46px',
        }),
      },
    ],
    [
      props.block && props.showOnRight,
      {
        ...screenLargeAndAbove({
          transform: 'translateY(-75%)',
        }),
      },
    ],
  )({
    marginTop:
      props.centered && !props.block ? '24px' : props.block && props.centered ? '57px' : '8px',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: Theme.ds.getColorByName('blueGrey900'),
    width: `${props.big ? 400 : props.small ? 201 : 240}px`,
    color: Theme.ds.getColorByName('white'),
    padding: '16px',
    borderRadius: '2px',
    fontSize: '13px',
    lineHeight: 1.468,
    fontWeight: 600,
  });
}

export const HelptipContainer = createComponentWithProxy(StyledHelptipContainer);

function StyledHelptipLabel(): IStyle {
  return {
    display: 'block',
  };
}

export const HelptipLabel = createComponentWithProxy(StyledHelptipLabel, 'span');

function StyledHelptipLink(props: HelptipLinkProps): IStyle {
  return applyModifiers([
    !props.inline,
    {
      display: 'block',
      marginTop: '16px',
    },
  ])({
    color: Theme.ds.getColorByName('blue900'),
    fontSize: '13px',
    fontWeight: 600,
    ':hover': {
      color: Theme.ds.getColorByName('blue1100'),
    },
  });
}

export const HelptipLink = createComponentWithProxy(StyledHelptipLink, 'a');

function StyledHelptipArrow(props: HelptipArrowPositionProps): IStyle {
  const arrowWidthDelta = -(8 / 2) + 1;
  return applyModifiers(
    [
      !isNil(props.arrowPosition),
      {
        [`left`]: `${props.arrowPosition}%`,
      },
    ],
    [
      props.showOnRight,
      {
        left: `${arrowWidthDelta}px`,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },
    ],
  )({
    position: 'absolute',
    top: `${arrowWidthDelta}px`,
    left: '50%',
    transform: 'translateX(-50%) rotate(45deg)',
    width: '16px',
    height: '16px',
    backgroundColor: Theme.ds.getColorByName('blueGrey900'),
    display: 'block',
  });
}

export const HelptipArrow = createComponentWithProxy(StyledHelptipArrow);