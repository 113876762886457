/** @format */

import { get } from 'lodash';

import { Configuration } from 'interfaces/configuration';

import { reducerUpdate } from 'utils/reducerUpdate';

import * as Actions from 'app/actions';

export const ConfigurationReducer = function(
  state: Configuration = get(window, 'RaygunConfiguration', {}),
  action: any,
) {
  switch (action.type) {
    case Actions.setApiKey.type:
      return reducerUpdate(state, { ApiKey: action.payload });
    default:
      return state;
  }
};
