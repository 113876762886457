/**
 * @prettier
 */

import * as React from 'react';
import { round } from 'lodash';

import { CustomChart } from 'components/ds/charts';
import * as charting from 'utils/echarts';

import { renderTitle } from '../title';

export type PieChartSeriesData = {
  point: number;
  color: string;
  name?: string;
};

type PieChartProps = {
  data: PieChartSeriesData[];
  height: string;
  title?: string;
};

type Props = PieChartProps;

export const PieChart: React.FunctionComponent<Props> = props => {
  const options = charting.extendOptions({
    tooltip: {
      show: true,
      trigger: 'item',
      formatter: (data: any) => {
        return charting.renderSeriesTooltip({
          series: [
            {
              name: data.name,
              value: `${round(data.percent)}% - ${round(data.value).toLocaleString()}ms`,
              colorcode: data.color,
            },
          ],
        });
      },
    },
    xAxis: [
      {
        show: false,
      },
    ],
    yAxis: [
      {
        show: false,
      },
    ],
    series: [
      {
        type: 'pie',
        radius: [0, '100%'],
        itemStyle: {
          normal: {
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
          },
        },
        data: props.data.map(d => ({
          name: d.name,
          value: d.point,
          itemStyle: {
            normal: {
              color: d.color,
            },
          },
        })),
      },
    ],
  });

  return (
    <>
      {renderTitle(props.title)}
      <CustomChart height={props.height} options={options} group={false} />
    </>
  );
};
