/** @format */

import { merge } from 'lodash';

export const defaultOptions = {
  animation: false,
  noDataLoadingOption: {
    effect: function() {
      return {
        start: function(): any {
          return undefined;
        },
        stop: function(): any {
          return undefined;
        },
      };
    },
  },
  tooltip: {
    show: false,
    confine: true,
    z: 0,
    padding: 0,
    borderWidth: 0,
    borderRadius: 0,
    showDelay: 0, // ms
    hideDelay: 0, // ms
    transitionDuration: 0, // s
  },
  grid: {
    borderColor: 'transparent',
    borderWidth: 0,
    x: 50,
    y: 10,
    x2: 20,
    y2: 34,
  },
  xAxis: [
    {
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: '#5a5a58',
          fontFamily: '"Open Sans", arial, helvetica, serif',
        },
        interval: 0,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
  ],
  yAxis: [
    {
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: '#5a5a58',
          fontFamily: '"Open Sans", arial, helvetica, serif',
        },
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#eaeaea',
          type: 'solid',
        },
      },
      splitArea: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
  ],
};

export function extendOptions(chartOptions: object) {
  return merge({}, defaultOptions, chartOptions);
}
