/** @format */
import React from 'react';
import { IStyle } from 'fela';
import { connect } from 'react-redux';
import { createComponent } from 'react-fela';

import { AppState } from 'interfaces/appState';

import { Flex, FlexBlock } from 'components/flex';
import { Margin, Padding } from 'components/layout';
import { Large, Medium } from 'components/breakpoints';
import { UtilityIconButton } from 'components/ds/button';

import { isApplicationSwitcher } from '../../../selectors';
import { setSlideoutMenu } from '../../../actions';
import { HasMultiplePlans } from '../../multiplePlans';
import { VerticalPlanSelector } from '../planSelector';
import { Filter } from '../filter';
import { Dashboards, Applications } from '../lists';
import { Header } from '../header';
import { ApplicationTrigger, DashboardTrigger } from '../trigger';
import { CloseSwitcher } from '../closeSwitcher';

type StateProps = {
  forApplications: boolean;
};

type DispatchProps = {
  openMenu: () => void;
};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

const StyledMenuButtonContainer = (): IStyle => ({
  position: 'absolute',
  left: '0',
});
const MenuButtonContainer = createComponent(StyledMenuButtonContainer);

export const UnconnectedSwitcher: React.FunctionComponent<Props> = ({
  forApplications,
  openMenu,
}) => (
  <Padding left="24" right="24" top="8" as="article">
    <Padding bottom="16">
      <Flex justify="space-between">
        <div>
          <Medium andBelow>
            <Margin left="-12" right="8">
              <MenuButtonContainer>
                <UtilityIconButton icon="menu" onClick={openMenu} />
              </MenuButtonContainer>
            </Margin>
            <Margin left="24">
              {forApplications && <ApplicationTrigger />}
              {!forApplications && <DashboardTrigger />}
            </Margin>
          </Medium>
          <Large andAbove>
            {forApplications && <ApplicationTrigger />}
            {!forApplications && <DashboardTrigger />}
          </Large>
        </div>
        <CloseSwitcher />
      </Flex>
    </Padding>
    <Flex justify="space-between" as="nav">
      <HasMultiplePlans>
        <Large andAbove>
          <Padding right="32">
            <VerticalPlanSelector />
          </Padding>
        </Large>
      </HasMultiplePlans>
      <FlexBlock grow={1}>
        <Padding bottom="56" largeScreens={{ right: '96', units: 'px' }} as="section">
          <Header />
          <Filter />
          {!forApplications && <Dashboards />}
          {forApplications && <Applications />}
        </Padding>
      </FlexBlock>
    </Flex>
  </Padding>
);

const ConnectedSwitcher = connect<StateProps, DispatchProps, PassedProps, AppState>(
  state => ({
    forApplications: isApplicationSwitcher(state),
  }),
  dispatch => ({
    openMenu: () => dispatch(setSlideoutMenu(true)),
  }),
)(UnconnectedSwitcher);

export { ConnectedSwitcher as Switcher };
