/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { COLORS } from 'app/theme/materialDS';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import {
  withInteractivity,
  WithInteractivitySuppliedProps,
} from 'components/ds/hoc/withInteractivity';
import { SharedProps } from './base';

type PublicBaseToggleButtonItemProps = {
  selected: boolean;
  isIcon: boolean;
} & SharedProps &
  React.HTMLProps<any>;

type BaseToggleButtonItemProps = PublicBaseToggleButtonItemProps & WithInteractivitySuppliedProps;

const StyledBaseToggleItem = (props: BaseToggleButtonItemProps & ThemeType): IStyle =>
  applyModifiers(
    [
      props.isIcon,
      {
        width: '38px',
        minWidth: '32px',
        paddingLeft: '11px',
        paddingRight: '11px',
      },
    ],
    [
      props.disabled,
      {
        color: props.theme.ds.getColorByName('grey400'),
        cursor: 'not-allowed',
        transition: 'none',
      },
    ],
    [
      props.disabled && props.selected,
      {
        backgroundColor: props.theme.ds.getColorByName('grey200'),
      },
    ],
    [
      props.hovered && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName('blueGrey75'),
      },
    ],
    [
      props.focused && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName('blueGrey100'),
      },
    ],
    [
      props.selected && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName('blue1400'),
        color: 'white',
        fontWeight: 600,
      },
    ],
    [
      props.pressed && props.selected && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName('blue1600'),
        color: 'white',
        fontWeight: 600,
      },
    ],
    [
      props.small,
      {
        minWidth: '40px',
        height: '24px',
        padding: '0 8px',
        lineHeight: '24px',
        ['> span' as any]: {
          lineHeight: '24px',
        },
      },
    ],
  )({
    backgroundColor: 'inherit',
    color: props.theme.ds.getColorByName('grey800'),
    minWidth: '50px',
    transition: 'background-color 200ms linear',
    height: '32px',
    border: 'none',
    cursor: 'pointer',
    padding: '0 16px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '2px',
    fontFamily: props.theme.ds.fonts.standard,
    whiteSpace: 'nowrap',
    ':focus': {
      outline: 0,
    },
    flex: 1,
    ['> span' as any]: {
      lineHeight: '32px',
    },
  });

const FelaToggleButtonItemWithoutInteractivity = createComponentWithProxy<
  BaseToggleButtonItemProps
>(StyledBaseToggleItem, 'button');

const FelaToggleButtonItem = withInteractivity<
  PublicBaseToggleButtonItemProps,
  BaseToggleButtonItemProps
>(FelaToggleButtonItemWithoutInteractivity);

class BaseToggleButtonItem extends React.Component<PublicBaseToggleButtonItemProps> {
  render() {
    return (
      <FelaToggleButtonItem {...this.props}>
        <span>{this.props.children}</span>
      </FelaToggleButtonItem>
    );
  }
}

export { BaseToggleButtonItem, PublicBaseToggleButtonItemProps as BaseToggleButtonItemProps };
