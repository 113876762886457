/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */
/* ignore file coverage */

import React from 'react';

import { BaseButton, BaseButtonProps, BaseDefaultProps, DefaultProps } from './base';

type DefaultPropKeys =
  | 'textColor'
  | 'disabledTextColor'
  | 'backgroundColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | 'disabledBackgroundColor'
  | keyof BaseDefaultProps;
type EmphasisButtonProps = BaseButtonProps;
export class EmphasisButton extends React.Component<EmphasisButtonProps> {
  static defaultProps: Pick<EmphasisButtonProps, DefaultPropKeys> = {
    ...DefaultProps,
    textColor: 'white',
    backgroundColor: 'blue1000',
    hoverBackgroundColor: 'blue1200',
    pressedBackgroundColor: 'blue1300',
    disabledTextColor: 'grey400',
    disabledBackgroundColor: 'grey200',
  };

  render() {
    const { children, ...props } = this.props;

    return <BaseButton {...props}>{children}</BaseButton>;
  }
}

type ButtonProps = BaseButtonProps;
export class Button extends React.Component<ButtonProps> {
  static defaultProps: Pick<ButtonProps, DefaultPropKeys> = {
    ...DefaultProps,
    textColor: 'grey800',
    backgroundColor: 'blueGrey50',
    hoverBackgroundColor: 'blueGrey100',
    pressedBackgroundColor: 'blueGrey200',
    disabledTextColor: 'grey400',
    disabledBackgroundColor: 'grey200',
  };

  render() {
    const { children, ...props } = this.props;

    return <BaseButton {...props}>{children}</BaseButton>;
  }
}

type DangerButtonProps = BaseButtonProps;
export class DangerButton extends React.Component<DangerButtonProps> {
  static defaultProps: Pick<DangerButtonProps, DefaultPropKeys> = {
    ...DefaultProps,
    textColor: 'white',
    backgroundColor: 'red600',
    hoverBackgroundColor: 'red700',
    pressedBackgroundColor: 'red900',
    disabledTextColor: 'grey400',
    disabledBackgroundColor: 'grey200',
  };

  render() {
    const { children, ...props } = this.props;

    return <BaseButton {...props}>{children}</BaseButton>;
  }
}
