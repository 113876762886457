/**
 * @format
 */

import React from 'react';

import { Large } from 'components/breakpoints';

import { WhatsNewBanner } from '../../models';

export const WhatsNewAdvert: React.FunctionComponent<WhatsNewBanner> = ({
  bannerImgSrc,
  bannerImgAlt,
  bannerLink,
  bannerEnabled,
}) => (
  <>
    {bannerEnabled && (
      <Large andAbove>
        <a href={bannerLink} className="hide-min" target="_blank">
          <img
            className="responsive-image"
            src={`https://raygun.com/${bannerImgSrc}`}
            alt={bannerImgAlt}
          />
        </a>
      </Large>
    )}
  </>
);
