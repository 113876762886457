/**
 * @prettier
 */
import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

const StyledCheckboxInput = (): IStyle => ({
  display: 'none',
  userSelect: 'none',
});

const StyledInput = createComponent<Props>(StyledCheckboxInput, 'input', [
  'checked',
  'onChange',
  'type',
  'disabled',
]);

type Props = {
  checked: boolean;
  onChange: () => void;
  isRadioButton?: boolean;
  disabled?: boolean;
};

export const CheckboxInput: React.FunctionComponent<Props> = props => (
  <StyledInput type={props.isRadioButton ? 'radio' : 'checkbox'} {...props} />
);
