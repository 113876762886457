/** @format **/

import { isNil, map } from 'lodash';

import * as MarkLine from '../markLine';
import * as ChartModels from '../models';
import * as Utils from '../utils';
import * as Models from './models';

export function getSeries(
  data: Models.LineChartData[],
  markLine: MarkLine.EchartsMarkLineOptions,
): Models.LineChartSeries {
  return map(data, item => ({
    symbol: 'none',
    type: 'line',
    lineStyle: {
      width: 2,
    },
    name: item.name,
    data: item.data,
    markLine,
  }));
}

export function getLabelFormatter(
  dataType: Models.LineChartDataType,
): ChartModels.eChartsLabelFormatter {
  switch (dataType) {
    case Models.LineChartDataType.Number:
    case Models.LineChartDataType.Count:
      return Utils.numericLabel;
    case Models.LineChartDataType.Duration:
    default:
      return Utils.durationLabel;
  }
}

export function getTooltipFormatter(
  dataType: Models.LineChartDataType,
): ChartModels.eChartsTooltipFormatter {
  switch (dataType) {
    case Models.LineChartDataType.Number:
      return Utils.numericTooltip;
    case Models.LineChartDataType.Count:
      return Utils.countTooltip;
    case Models.LineChartDataType.Duration:
    default:
      return Utils.dateTimeTooltip;
  }
}

export function getValuesForLogScale(data: Models.LineChartData[], forceLogBase: number) {
  if (!isNil(forceLogBase)) {
    return {
      logBase: forceLogBase,
      min: 0.1,
    };
  }

  const maxYAxisValue = Math.max.apply(
    Math,
    data.map(lineChartData => Math.max.apply(Math, lineChartData.data)),
  );

  const logBase = Math.max(Math.round(Math.log(maxYAxisValue)), 2);

  const min = Math.min.apply(
    Math,
    data.map(lineChartData => Math.min.apply(Math, lineChartData.data.filter(data => data != 0))),
  );

  return {
    logBase,
    min,
  };
}
