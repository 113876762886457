/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

interface Props {
  border?: boolean;
  circle?: boolean;
  url?: string;
  alt?: string;
  size?: 16 | 20 | 24 | 28 | 32 | 40 | 48 | 64 | 128;
  radius?: boolean;
}

type AvatarSharedProps = {
  size?: number;
  circle?: boolean;
  radius?: boolean;
};

type StyledAvatarWrapperProps = {
  border?: boolean;
  center?: boolean;
} & AvatarSharedProps;

type StyledAvatarImageProps = {} & AvatarSharedProps;

const StyledAvatarWrapper = (props: StyledAvatarWrapperProps): IStyle => ({
  overflow: 'hidden',
  display: 'block',
  width: `${props.size}px`,
  height: `${props.size}px`,
  borderRadius: props.circle ? '50%' : '3px',
  border: props.border && `2px solid #fff`,
});

const AvatarWrapper = createComponent(StyledAvatarWrapper, 'span');

const StyledAvatarImage = (props: StyledAvatarImageProps): IStyle => ({
  display: 'block',
  width: `${props.size}px`,
  minWidth: `${props.size}px`,
  height: `${props.size}px`,
  borderRadius: props.circle ? '50%' : props.radius ? '2px' : null,
  overflow: 'hidden',
});

const AvatarImage = createComponent(StyledAvatarImage, 'img', ['src', 'alt']);

export const Avatar: React.FunctionComponent<Props> = props => {
  if (props.url.length > 0) {
    return <AvatarImage src={props.url} alt={props.alt} {...props} />;
  }

  return <AvatarWrapper {...props}>{props.children}</AvatarWrapper>;
};

Avatar.defaultProps = {
  size: 24,
  url: '',
  alt: '',
  circle: false,
  border: false,
  radius: false
};
