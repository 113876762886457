/**
 * @prettier
 */

import { createStore, applyMiddleware, combineReducers, Reducer, compose, Middleware } from 'redux';
import { addRoutes as RfRAddRoutes, connectRoutes } from 'redux-first-router';
import { Store } from 'redux';
import * as querySerializer from 'qs';
import { createBrowserHistory, createMemoryHistory } from 'history';
import createSagaMiddleware, { SagaIterator } from 'redux-saga';
import thunk from 'redux-thunk';
import { assign, mapValues, forEach, isNil } from 'lodash';
import { ReducerBuilder } from 'typescript-fsa-reducers';
import dynamicMiddlewares, { addMiddleware } from 'redux-dynamic-middlewares';

import { RoutePreEntryCallback } from 'utils/routing';

import {
  ConfigurationReducer,
  PageDataReducer,
  ScreenReducer,
  ScreenState,
  MessagesReducer,
  ChromeReducer,
} from 'app/reducers';

import { Reducer as WhatsNewReducer, Saga as WhatsNewSaga } from 'modules/whatsnew';
import * as Time from 'modules/time';
import * as Resources from 'modules/resources';
import { PermissionManager } from 'modules/permissions';
import { Reducer as InviteTeamModalReducer } from 'modules/inviteTeamModal';

import { Reducer as TopLevelFilterReducer } from 'legacy/components/topLevelFilters';

import { NotificationReducer } from './notifications/reducer';
import { Reducer as RevampedChromeReducer } from './chrome/revamped';
import { PopupReducer } from './popups/reducer';
import { AppSaga } from './sagas';

declare const process: any;
declare const rg4js: any;
const isTest = process.env.NODE_ENV === 'test';
type AsyncReducers = {
  [key: string]: Reducer<any>;
};
type Route = { path: string; beforeEntryCallback?: RoutePreEntryCallback<any, any> };
type RoutesMap = {
  [type: string]: Route;
};

let reducers: Reducer<any>;
let store: Store<any>;

export function getReducers() {
  return reducers;
}

export function getStore<T = any>() {
  return store as Store<T>;
}

export default function createReduxStoreForSection(
  sectionName: string,
  sectionReducers: Reducer<any>,
  sectionSagas: (() => SagaIterator)[],
  sectionBaseRoute: string,
  sectionRoutes: RoutesMap,
  sectionRouteReducers: ((
    builder: ReducerBuilder<ScreenState, ScreenState>,
  ) => ReducerBuilder<ScreenState, ScreenState>)[],
  extraTopLevelReducers: { [key: string]: Reducer<any> } = {},
  middleware: Middleware[] = [],
) {
  const routesWithBasenameAdded = mapValues(sectionRoutes, mapping => ({
    ...mapping,
    path: `${sectionBaseRoute}${mapping.path}`,
  }));

  const builtScreenReducer = sectionRouteReducers.reduce(
    (accumulator, routeReducer) => routeReducer(accumulator),
    ScreenReducer,
  );

  const sagas = [AppSaga, WhatsNewSaga, Time.Saga, Resources.Sagas, ...sectionSagas];

  const storeStuff = createReduxStore();
  store = storeStuff.store;

  middleware.forEach(m => addMiddleware(m));
  storeStuff.registerReducer('screens', builtScreenReducer);
  forEach(extraTopLevelReducers, (reducer, name) => storeStuff.registerReducer(name, reducer));
  storeStuff.registerReducer(sectionName, sectionReducers);

  // Init the Permission manager, gives it access to the store
  PermissionManager.setStore(storeStuff.store);

  if (!isTest) {
    sagas.forEach(saga => storeStuff.registerSaga(saga));

    storeStuff.addRoutes(routesWithBasenameAdded);
  }

  Resources.Models.setStore(storeStuff.store);

  storeStuff.initialDispatch();

  return storeStuff;
}

const asyncReducers: AsyncReducers = {};
export function createReduxStore(
  initialReducers: { [key: string]: Reducer<any> } = {},
  initialState: any = undefined,
  useMemoryHistory: boolean = false,
) {
  const routesMap: RoutesMap = {};

  const {
    reducer: locationReducer,
    middleware: navigationMiddleware,
    enhancer,
    initialDispatch,
  } = connectRoutes(
    useMemoryHistory ? createMemoryHistory() : createBrowserHistory(),
    {},
    {
      querySerializer,
      initialDispatch: false,
      notFoundPath: null,
      onBeforeChange: (dispatch, getState, action) => {
        const mapping = routesMap[action.action.type];
        const cb = mapping ? mapping.beforeEntryCallback : undefined;

        if (cb) {
          cb(dispatch, getState, action.action);
        }
      },
    },
  );

  reducers = createReducers({ ...initialReducers, location: locationReducer });

  const devtoolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = devtoolsCompose
    ? devtoolsCompose({
        name: 'Raygun App',
        maxAge: 150,
      })
    : compose;
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(
      enhancer,
      applyMiddleware(thunk, navigationMiddleware, sagaMiddleware, dynamicMiddlewares),
    ),
  );

  function registerReducer(name: string, reducer: Reducer<any>) {
    asyncReducers[name] = reducer;

    reducers = createReducers({ ...initialReducers, location: locationReducer, ...asyncReducers });
    store.replaceReducer(reducers);
  }

  function registerSaga(saga: () => SagaIterator) {
    const errorHandler: any = (err: any) => {
      const _ignored = !isNil(rg4js) && rg4js('send', { error: err, tags: ['Saga'] });

      window.setTimeout(() => {
        const sagaTask = sagaMiddleware.run(saga);

        sagaTask.done.catch(errorHandler);
      }, 1000);
    };
    const sagaTask = sagaMiddleware.run(saga);

    sagaTask.done.catch(errorHandler);
  }

  function addRoutes(routes: RoutesMap) {
    forEach(routes, (path, type) => (routesMap[type] = path));
    const routesWithoutCallbacks = mapValues(routes, mapping => mapping.path);

    store.dispatch(RfRAddRoutes(routesWithoutCallbacks));
  }

  return {
    store,
    reducers,
    registerReducer,
    registerSaga,
    addRoutes,
    initialDispatch,
  };
}

export function createReducers(extraReducers: { [key: string]: Reducer<any> } = {}) {
  const AppReducer = combineReducers({
    configuration: ConfigurationReducer,
    pageData: PageDataReducer,
    notifications: NotificationReducer,
    popups: PopupReducer,
    messages: MessagesReducer,
    chrome: ChromeReducer,
  });

  return combineReducers(
    assign({}, extraReducers, {
      app: AppReducer,
      toplevelFilters: TopLevelFilterReducer,
      whatsnew: WhatsNewReducer,
      inviteTeamModal: InviteTeamModalReducer,
      time: Time.Reducer,
      resources: Resources.Reducer,
      revampedChrome: RevampedChromeReducer,
    }),
  );
}
