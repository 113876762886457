/** @format */

import { clamp } from 'lodash';

export function getDataPointIndexFromEvent(chart: any, event: any, dataMax: number) {
  const index = !chart
    ? 0
    : chart
        .getEchartsInstance()
        .convertFromPixel({ seriesIndex: 0 }, [event.offsetX, event.offsetY])[0];
  return clamp(index, 0, dataMax);
}
