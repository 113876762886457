/** @format **/

import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setAppSwitcherPlanId } from 'app/actions/chrome';
import { getAppSwitcherActivePlanId } from 'app/selectors/chrome';

import { size } from 'utils/gravatar';
import { Plan } from 'interfaces/plan';
import { AppState } from 'interfaces/appState';
import { Tooltip } from 'components/tooltip';

import { PlanSwitcherMobile } from './planSwitcherMobile';

type StateProps = {
  activePlanId: number;
};

type DispatchProps = {
  switchActivePlan: (id: number) => void;
};

type PassedProps = {
  plans: Plan[];
};

type Props = StateProps & DispatchProps & PassedProps;

class UnconnectedPlanSwitcher extends React.Component<Props, {}> {
  render() {
    const { switchActivePlan, activePlanId } = this.props;

    const plans = this.props.plans.sort((a, b) => {
      return b.ApplicationCount - a.ApplicationCount;
    });

    const planItems = plans.map(plan => {
      const className = classnames('plan-switcher-plan', {
        'plan-switcher-plan--active': plan.Id === activePlanId,
      });

      return (
        <li key={plan.Id} className="plan-switcher-list__item">
          <Tooltip label={plan.PlanName} block dock="right">
            <a className={className} onClick={() => switchActivePlan(plan.Id)} data-id={plan.Id}>
              <img
                src={size(plan.Avatar, 40)}
                alt={`Avatar of the ${plan.PlanName} plan`}
                className="plan-switcher-plan__graphic"
              />
            </a>
          </Tooltip>
        </li>
      );
    });

    return (
      <div>
        <div>
          <ul className="plan-switcher-list">{planItems}</ul>
        </div>
        <div className="plan-switcher-mobile">
          <PlanSwitcherMobile
            activePlanId={activePlanId}
            plans={plans}
            onClick={switchActivePlan}
          />
        </div>
      </div>
    );
  }
}

export const PlanSwitcher = connect<StateProps, DispatchProps, PassedProps>(
  (state: AppState) => ({
    activePlanId: getAppSwitcherActivePlanId(state),
  }),
  (dispatch: Dispatch) => ({
    switchActivePlan: (id: number) => dispatch(setAppSwitcherPlanId(id)),
  }),
)(UnconnectedPlanSwitcher);
