import { every } from "lodash";

export function emailAddress(email: string, multipleEmails: boolean = false) {
  if(multipleEmails) {
    const emails = email.split(';');
    return every(emails, e => {
      if(e.trim().length > 0) {
        return e.indexOf('@') > -1 && e.split('@')[1].length > 2;
      }

      return true;
    });
  }

  return email.indexOf('@') > -1 && email.split('@')[1].length > 2;
}
