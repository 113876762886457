/**
 * @prettier
 */

import { createComponent } from 'react-fela';
import { RaygunIcon } from 'fela-rules/icon';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type Props = {
  checked?: boolean;
  hovered?: boolean;
  right?: boolean;
  noPadding?: boolean;
  dropdown?: boolean;
  isRadioButton?: boolean;
  disabled?: boolean;
  checkedBoxColor?: string;
};

const StyledCheckboxIcon = (props: Props & ThemeType) => {
  const defaultHoverColor = props.theme.colors.text.black;

  const styles = RaygunIcon({
    display: 'block',
    position: 'absolute',
    top: '4px',
    left: 0,
    ':before': {
      color: props.theme.colors.text.greyLight,
      display: 'block',
      width: '16px',
      height: '16px',
      fontSize: '16px',
      lineHeight: '16px',
    },
  });

  return applyModifiers(
    [props.right, { left: 'initial', right: 0 }],
    [props.noPadding, { top: 0 }],
    [props.dropdown, { top: '10px', left: '9px' }],
    [
      !props.isRadioButton,
      {
        ':before': {
          content: props.checked && !props.disabled ? '"\\e649"' : '"\\e648"',
        },
      },
    ],
    [
      props.isRadioButton,
      {
        ':before': {
          content: props.checked ? '"\\e607"' : '"\\e600"',
        },
      },
    ],
    [
      !props.disabled && props.checked,
      {
        ':before': {
          color: props.checkedBoxColor || defaultHoverColor,
        },
      },
    ],
    [
      !props.disabled && props.hovered,
      {
        ':before': {
          color: defaultHoverColor,
        },
      },
    ],
    [
      props.disabled,
      {
        ':before': {
          backgroundColor: 'grey',
        },
      },
    ],
  )(styles);
};

export const CheckboxIcon = createComponent(StyledCheckboxIcon, 'span');
