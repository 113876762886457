/** @format */

import { IStyle } from 'fela';

export const RaygunIcon = (style: IStyle): IStyle => ({
  fontFamily: 'raygun-icons',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontVariant: 'normal',
  textTransform: 'none',
  lineHeight: 1,
  ['-webkit-font-smoothing' as any]: 'antialiased',
  ['-moz-osx-font-smoothing' as any]: 'grayscale',
  ...style,
});
