/**
 * @prettier
 */

import * as React from 'react';
import classnames from 'classnames';

type LoadingSpinnerProps = {
  active?: boolean;
  semiTransparent?: boolean;
  smallSpinner?: boolean;
};

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = props => {
  const classes = classnames('loading', {
    'loading--active': props.active,
    'loading--semi-transparent': props.semiTransparent,
  });

  const spinnerClasses = classnames('loading__spinner', {
    'loading__spinner--small': props.smallSpinner,
  });

  return (
    <div className={classes}>
      <div className={spinnerClasses} />
    </div>
  );
};

LoadingSpinner.defaultProps = {
  active: true,
  semiTransparent: false,
  smallSpinner: false,
};

type OverlayProps = {
  smallSpinner?: boolean;
};

export const Overlay: React.FunctionComponent<OverlayProps> = props => (
  <div className="loading-layout it-loading-spinner">
    <div className="loading-layout__content">{props.children}</div>
    <div className="loading-layout__spinner">
      <LoadingSpinner semiTransparent smallSpinner={props.smallSpinner} />
    </div>
  </div>
);

Overlay.defaultProps = {
  smallSpinner: false,
};

export const Loading: React.FunctionComponent<{}> = () => (
  <div className="loading-region">
    <LoadingSpinner />
  </div>
);

type StandaloneLoadingSpinnerProps = {
  height?: string;
} & LoadingSpinnerProps;

export const StandaloneLoadingSpinner: React.FunctionComponent<StandaloneLoadingSpinnerProps> = ({
  height,
  ...props
}) => (
  <div className="loading-region" style={{ height }}>
    <LoadingSpinner {...props} />
  </div>
);

export const FlexibleHeightSpinner: React.FunctionComponent<OverlayProps> = props => (
  <div className="loading-flexible">
    {props.children}
    <div className="loading-region loading-region--fill-space">
      <LoadingSpinner {...props} />
    </div>
  </div>
);
