/** @format **/
/* tslint:disable:max-file-line-count */
import React from 'react';
import { IStyle } from 'fela';
import { isNil } from 'lodash';
import { createComponentWithProxy, createComponent } from 'react-fela';

import { applyModifiers } from 'fela-rules/modifiers';
import { screenSmallOnly } from 'fela-rules/breakpoints';

import { ThemeType } from 'app/theme';
import { COLORS } from 'app/theme/materialDS';

import { IconProps } from 'components/icon';

import { isNotNilOrEmpty } from 'utils/string';

import { BaseInputProps } from './base';

type FelaInputProps = {
  disabled?: boolean;
  readOnly?: boolean;
  error?: string | JSX.Element;
  iconLeft: React.ReactElement<IconProps>;
  iconRight: React.ReactElement<IconProps>;
  prefixText?: string;
  onCopyButtonClick?: () => void;
  textArea: boolean;
  selection: boolean;
  fullWidthOnMobile?: boolean;
  smallPadding?: boolean;

  textColor: COLORS;
  disabledTextColor: COLORS;

  placeholderTextColor: COLORS;
  disabledPlaceholderTextColor: COLORS;

  disabledBackgroundColor: COLORS;
  readOnlyBackgroundColor: COLORS;
};

const StyledInput = (props: FelaInputProps & ThemeType): IStyle =>
  applyModifiers(
    [
      props.disabled,
      {
        ':disabled': {
          backgroundColor: props.theme.ds.getColorByName(props.disabledBackgroundColor),
          color: props.theme.ds.getColorByName(props.disabledTextColor),
        },
        '::placeholder': {
          color: props.theme.ds.getColorByName(props.disabledPlaceholderTextColor),
        },
      },
    ],
    [
      props.readOnly && !props.selection, // prevents select dropdowns from inheriting the readOnly background colour from its readOnly attribute
      {
        backgroundColor: props.theme.ds.getColorByName(props.readOnlyBackgroundColor),
      },
    ],
    [
      !isNil(props.iconRight),
      {
        paddingLeft: '16px',
      },
    ],
    [
      !isNil(props.onCopyButtonClick),
      {
        paddingLeft: '16px',
      },
    ],
    [
      props.textArea,
      {
        minHeight: '96px',
        paddingTop: '8px',
      },
    ],
    [
      !isNil(props.iconLeft) || isNotNilOrEmpty(props.prefixText),
      {
        paddingLeft: 0,
      },
    ],
    [
      props.selection,
      {
        paddingRight: 0,
      },
    ],
    [
      props.selection && !props.disabled,
      {
        ':hover': {
          cursor: 'pointer',
        },
      },
    ],
    [
      props.smallPadding,
      {
        paddingLeft: '8px',
        paddingRight: '8px',
      }
    ],
  )({
    width: '100%',
    height: '100%',
    border: 'none',
    resize: 'none',
    paddingLeft: '16px',
    paddingRight: '16px',
    outline: 'transparent',
    color: props.theme.ds.getColorByName(props.textColor),
    '::placeholder': {
      color: props.theme.ds.getColorByName(props.placeholderTextColor),
    },
    /**
     * Disables edge/IE's own clear icon since we support adding our own
     */
    '::-ms-clear': {
      display: 'none',
    },
  });

export const FelaBaseInput = createComponentWithProxy(StyledInput, 'input', [
  'disabled',
  'readOnly',
]);
export const FelaBaseInputArea = createComponentWithProxy(StyledInput, 'textarea');

export type FelaBaseInputContainerProps = {
  // Component type
  textArea: boolean;

  // Colors
  borderColor: COLORS;
  errorBorderColor: COLORS;
  disabledBorderColor: COLORS;

  textColor: COLORS;
  labelColor: COLORS;

  disabledTextColor: COLORS;
  placeholderTextColor: COLORS;

  disabledPlaceholderTextColor: COLORS;
  backgroundColor: COLORS;
  disabledBackgroundColor: COLORS;
  readOnlyBackgroundColor: COLORS;

  // Functional props
  value?: string;
  disabled: boolean;
  readOnly?: boolean;
  label?: string;
  labelBold?: boolean;
  error?: string | JSX.Element;
  absoluteError?: boolean;
  subtext?: string;
  defaultValue?: string; // Used to pre-populate inputs with text
  block: boolean;
  small: boolean;
  placeholder?: string;
  selection: boolean; // Used to remove text input functionality
  fullWidthOnMobile?: boolean;
  credentialsValue?: string; // Used to pass the credential's unmasked value
  onCopyButtonClick?: () => void;

  // Icon props
  iconLeft: React.ReactElement<IconProps>;
  iconRight: React.ReactElement<IconProps>;
  clearable: boolean;
  leftIconSpacing?: boolean;

  // Prefix props
  prefixText?: string;
  
  // Utility props
  smallPadding?: boolean;
};

export type BaseDefaultProps = Pick<
  BaseInputProps,
  | 'iconRight'
  | 'iconLeft'
  | 'prefixText'
  | 'onCopyButtonClick'
  | 'credentialsValue'
  | 'disabled'
  | 'readOnly'
  | 'required'
  | 'errorBorderColor'
  | 'borderColor'
  | 'disabledBorderColor'
  | 'textColor'
  | 'disabledTextColor'
  | 'placeholderTextColor'
  | 'disabledPlaceholderTextColor'
  | 'backgroundColor'
  | 'disabledBackgroundColor'
  | 'readOnlyBackgroundColor'
  | 'clearable'
  | 'selection'
  | 'fullWidthOnMobile'
  | 'labelColor'
  | 'labelBold'
  | 'small'
  | 'leftIconSpacing'
> & { block: boolean };
export const DefaultProps: BaseDefaultProps = {
  iconLeft: null,
  iconRight: null,
  prefixText: null,
  onCopyButtonClick: null,
  credentialsValue: null,
  disabled: false,
  readOnly: false,
  required: false,
  backgroundColor: 'white',
  disabledBackgroundColor: 'grey200',
  readOnlyBackgroundColor: 'blueGrey10',
  errorBorderColor: 'red500',
  borderColor: 'blueGrey200',
  disabledBorderColor: 'grey200',
  disabledPlaceholderTextColor: 'grey400',
  placeholderTextColor: 'blueGrey400',
  disabledTextColor: 'grey400',
  textColor: 'grey800',
  labelColor: 'grey800',
  labelBold: false,
  clearable: false,
  block: true,
  small: false,
  selection: false,
  fullWidthOnMobile: false,
  leftIconSpacing: false,
};

type InputContainerProps = Pick<
  FelaBaseInputContainerProps,
  | 'iconRight'
  | 'onCopyButtonClick'
  | 'credentialsValue'
  | 'errorBorderColor'
  | 'disabledBorderColor'
  | 'disabledBackgroundColor'
  | 'readOnlyBackgroundColor'
  | 'borderColor'
  | 'backgroundColor'
  | 'error'
  | 'disabled'
  | 'readOnly'
  | 'textArea'
  | 'clearable'
  | 'selection'
  | 'label'
  | 'fullWidthOnMobile'
  | 'block'
  | 'small'
>;
const StyledInputContainer = (props: InputContainerProps & ThemeType): IStyle => {
  const {
    iconRight,
    clearable,
    textArea,
    disabled,
    readOnly,
    error,
    selection,
    fullWidthOnMobile,
    block,
    small,
  } = props;

  return applyModifiers(
    [
      selection,
      {
        width: block ? '100%' : small ? '182px' : '280px',
        ...screenSmallOnly({
          width: fullWidthOnMobile && '100%',
        }),
      },
    ],
    [
      selection && !disabled,
      {
        ':hover': {
          cursor: 'pointer',
        },
      },
    ],
    [
      !isNil(error),
      {
        borderColor: props.theme.ds.getColorByName(props.errorBorderColor),
      },
    ],
    [
      disabled,
      {
        borderColor: props.theme.ds.getColorByName(props.disabledBorderColor),
        backgroundColor: props.theme.ds.getColorByName(props.disabledBackgroundColor),
      },
    ],
    [
      readOnly && !selection,
      {
        backgroundColor: props.theme.ds.getColorByName(props.readOnlyBackgroundColor),
      },
    ],
    [
      !textArea,
      {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
      },
    ],
    [
      !isNil(iconRight) || clearable,
      {
        flexFlow: 'row',
      },
    ],
  )({
    width: '100%',
    border: `1px solid ${props.theme.ds.getColorByName(props.borderColor)}`,
    backgroundColor: props.theme.ds.getColorByName(props.backgroundColor),
    display: 'flex',
    borderRadius: '2px',
    flexFlow: !isNil(iconRight) ? 'row-reverse' : 'row',
  });
};

export const FelaInputContainer = createComponentWithProxy(StyledInputContainer, 'div');

const StyledInputWrapper = (props: { block: boolean }): IStyle =>
  applyModifiers([props.block, { width: '100% ' }])({});
export const InputWrapper = createComponentWithProxy(StyledInputWrapper);

type StyledInputLabelProps = {
  labelColor: COLORS;
  labelBold: boolean;
};
const StyledInputLabel = ({
  labelColor,
  labelBold,
  theme,
}: StyledInputLabelProps & ThemeType): IStyle =>
  applyModifiers([
    labelBold,
    {
      fontWeight: 600,
    },
  ])({
    marginBottom: '8px',
    color: theme.ds.getColorByName(labelColor),
    display: 'block',
  });
export const FelaInputLabel = createComponent(StyledInputLabel, 'label', ['htmlFor']);

const StyledIconRightContainer = (): IStyle => ({
  position: 'absolute',
  right: '16px',
});
export const IconRightContainer = createComponent(StyledIconRightContainer);

const StyledSubtext = (props: ThemeType): IStyle => ({
  color: props.theme.ds.getColorByName('grey600'),
  display: 'block',
  margin: '4px auto',
});
export const FelaSubtext = createComponent(StyledSubtext, 'p');

const StyledErrorText = (props: ThemeType & { absoluteError?: boolean }): IStyle =>
  applyModifiers([
    props.absoluteError,
    {
      position: 'absolute',
    },
  ])({
    color: props.theme.ds.getColorByName('red500'),
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: '24px',
  });
export const FelaErrorText = createComponent(StyledErrorText, 'p');

const StyledRequiredAstrix = (props: ThemeType): IStyle => ({
  color: props.theme.ds.getColorByName('red400'),
});
export const FelaRequiredAstrix = createComponent(StyledRequiredAstrix, 'span');

const StyledPrefix = (props: ThemeType & { prefixColor?: COLORS }): IStyle => ({
  color: props.theme.ds.getColorByName(props.prefixColor),
});
export const Prefix = createComponent(StyledPrefix);