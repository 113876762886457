/** @format **/

import { createSelector } from 'reselect';
import moment from 'moment';

import { getTimeZoneName } from 'selectors/time';
import { TimeStateAtom } from '../state';

const timeSelector = (state: TimeStateAtom) => state.time;

export const getActiveDateRange = createSelector(
  timeSelector,
  time => ({ dateFrom: moment(time.from), dateTo: moment(time.to) }),
);

export const getDateTimeQs = createSelector(
  getActiveDateRange,
  getTimeZoneName,
  ({ dateFrom, dateTo }, timeZoneName) => ({
    dateFrom: dateFrom.utc().format(),
    dateTo: dateTo.utc().format(),
    timeZoneName,
  }),
);
