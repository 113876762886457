/** @format */
/* tslint:disable:max-file-line-count */

import * as React from 'react';
import classnames from 'classnames';
import { Button } from 'components/button';
import { ToplevelFiltersState } from '../state';
import * as Models from '../models';
import * as Selectors from '../selectors';
import * as Actions from '../actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Icon } from 'components/icon';
import { SearchDropdown } from 'components/dropdown/search';
import { SearchDropdownContainerProps } from 'components/dropdown/search/models';
import { SearchResult } from 'interfaces/search';
import { debounce, find, assign } from 'lodash';

type DispatchProps = {
  editFilter: (filter: Models.Filter) => void;
  deleteFilter: (filterId: string) => void;
  updateAddFilterState: (state: Actions.UpdateAddFilterStatePayload) => void;
  updateEditFilterState: (state: Actions.UpdateEditFilterStatePayload) => void;
  fetchDimensionValues: () => void;
  fetchDimensionValuesByFilter: (filter: Models.Filter, query: string) => void;
};
type StateProps = {
  filter: Models.Filter;
  containerHeight: 32 | 48;
  activeDimension: Models.DimensionWithComparators;
  currentValues: Models.FilterValue[];
  editing: boolean;
  selectedMode: Models.Mode;
  dimension: Models.DimensionWithComparators;
  values: Models.FilterValue[];
  isLoadingData: boolean;
  containerCollapsed: boolean;
};
type SuppliedProps = {
  id: string;
  transformValue?: (value: Models.FilterValue, dimension: Models.Dimension) => Models.FilterValue;
  enableEditing?: boolean;
};
type Props = DispatchProps & StateProps & SuppliedProps;

type State = {
  editable: boolean;
  openDropdown: boolean;
};

class UnconnectedActiveFilter extends React.Component<Props, State> {
  stopClose: boolean = false;
  valuesLoaded: boolean = false;
  deleteFilter: () => void;
  comparatorElement: HTMLElement;
  valuesElement: HTMLElement;

  state: State = {
    editable: false,
    openDropdown: null,
  };

  constructor(props: Props) {
    super(props);

    this.deleteFilter = props.deleteFilter.bind(null, props.id);
    this.loadValues = this.loadValues.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleSearchInputClick = this.handleSearchInputClick.bind(this);
    this.handleEditComparatorClick = this.handleEditComparatorClick.bind(this);
    this.handleEditValueClick = this.handleEditValueClick.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.forceOpenDropdown = this.forceOpenDropdown.bind(this);
    this.forceCloseDropdown = this.forceCloseDropdown.bind(this);
  }

  componentDidMount() {
    this.loadValues();
    this.resetAddFilterState();
  }

  loadValues() {
    this.props.updateAddFilterState({
      selectedDimensionId: this.props.filter.dimension.id,
    });
    this.props.fetchDimensionValues();
    this.valuesLoaded = true;
  }

  isEditable() {
    return this.props.enableEditing && !this.props.containerCollapsed;
  }

  resetAddFilterState() {
    this.props.updateAddFilterState({
      selectedDimensionId: null,
      currentMode: Models.Mode.Inactive,
    });
  }

  handleEditComparatorClick() {
    if (!this.isEditable()) {
      return;
    }

    this.props.updateEditFilterState({
      selectedFilterId: this.props.filter.id,
      selectedMode: Models.Mode.SelectComparator,
    });
  }

  handleEditValueClick() {
    if (!this.isEditable()) {
      return;
    }

    this.props.updateEditFilterState({
      selectedFilterId: this.props.filter.id,
      selectedMode: Models.Mode.SelectValue,
    });
  }

  handleResultSelect(values: SearchResult[]) {
    if (values.length === 0) {
      return;
    }
    const { filter, selectedMode } = this.props;

    if (selectedMode === Models.Mode.SelectComparator) {
      const isMultiSelect =
        values[0].multiSelect || values[0].id === 'oof' || values[0].id === 'noof';
      const allowFreeText = values[0].allowFreeText || !isMultiSelect;
      filter.comparator = {
        id: values[0].id,
        displayName: values[0].displayName,
        multiSelect: isMultiSelect,
        allowFreeText: allowFreeText,
      };
    } else if (selectedMode === Models.Mode.SelectValue) {
      filter.values = values;
    }

    this.forceCloseDropdown();

    this.props.editFilter(filter);
  }

  forceOpenDropdown() {
    this.setState({ openDropdown: true });
  }

  forceCloseDropdown() {
    this.setState({ openDropdown: false });
  }

  handleSearchInputClick() {
    this.stopClose = true;
  }

  handleQueryChange(currentQuery: string) {
    this.props.updateAddFilterState({ currentQuery });
    this.props.fetchDimensionValuesByFilter(this.props.filter, currentQuery);
  }

  transformValue(value: string, _dimension: Models.Dimension) {
    return value;
  }

  getSearchPool(
    mode: Models.Mode,
    comparators: Models.Comparator[],
    values: Models.FilterValue[],
  ): SearchResult[] {
    if (mode === Models.Mode.SelectComparator) {
      return comparators.map(c => ({
        id: c.id,
        displayName: c.displayName,
        multiSelect: c.multiSelect,
        allowFreeText: c.allowFreeText,
      }));
    } else {
      return values.map(v => {
        const transformedValue = this.props.transformValue(v, this.props.filter.dimension);
        return {
          id: transformedValue.id,
          displayName: transformedValue.displayName,
        };
      });
    }
  }

  render() {
    const { filter, containerHeight, selectedMode, dimension, values } = this.props;
    const displayValue = filter.values.map(v => v.displayName).join(', ');
    const dimensionClassNames = classnames('filter-pill-dimension filter-pill-item', {
      hidden: !filter.dimension.displayName,
    });
    const comparatorClassNames = classnames('filter-pill-comparator filter-pill-item', {
      hidden: !filter.comparator.displayName,
      'filter-pill-item--editable': this.isEditable(),
    });
    const valueClassNames = classnames('filter-pill-value filter-pill-item', {
      hidden: !displayValue,
      'filter-pill-item--editable': this.isEditable(),
    });
    const { availableComparators } = dimension;
    const isSelectValueMode = selectedMode === Models.Mode.SelectValue;

    const filterValuesAsSearchResults: SearchResult[] = filter.values.map(v => ({
      id: v.id,
      displayName: v.displayName,
    }));

    const multiSelect = filter.comparator.multiSelect;
    const allowFreeText = filter.comparator.allowFreeText;

    const editButtonDropdownProps = {
      handleQueryChange: this.handleQueryChange,
      handleResultSelect: this.handleResultSelect,
      handleCancel: this.forceCloseDropdown,
      handleManualSubmit: this.forceCloseDropdown,
      handleOpen: this.forceOpenDropdown,
      allowFreeText: isSelectValueMode && allowFreeText,
      showManualSubmit: isSelectValueMode && multiSelect,
      selectMultiple: isSelectValueMode && multiSelect,
      showManualCancel: true,
      showNoResultsText: true,
      query: '',
      loadingData: this.props.isLoadingData,
      showLoading: isSelectValueMode,
      hideSearchSection: !isSelectValueMode,
      cancelText: 'Close',
      currentData: isSelectValueMode && multiSelect ? filterValuesAsSearchResults : [],
      openDropdown: this.state.openDropdown,
      disabled: !this.props.enableEditing,
    };

    const comparatorDisplayName = filter.comparator.displayName;

    const comparatorDropdownProps: SearchDropdownContainerProps = assign(
      {},
      editButtonDropdownProps,
      {
        button: <span className="edit-filter-button">{comparatorDisplayName}</span>,
        searchPool: this.getSearchPool(Models.Mode.SelectComparator, availableComparators, values),
      },
    );

    const valueDropdownProps: SearchDropdownContainerProps = assign({}, editButtonDropdownProps, {
      button: <span className="edit-filter-button">{displayValue}</span>,
      searchPool: this.getSearchPool(Models.Mode.SelectValue, availableComparators, values),
    });

    return (
      <div className="top-level-filter">
        <div className="top-level-filter__label top-level-filter__label--active">
          <div className={dimensionClassNames}>{filter.dimension.displayName}</div>
          <div
            className={comparatorClassNames}
            title={comparatorDisplayName}
            onClick={this.handleEditComparatorClick}
          >
            <SearchDropdown {...comparatorDropdownProps} />
          </div>
          <div className={valueClassNames} title={displayValue} onClick={this.handleEditValueClick}>
            <SearchDropdown {...valueDropdownProps} />
          </div>
        </div>
        <div className="top-level-filter__button">
          <Button
            title="Delete filter"
            onClick={this.deleteFilter}
            extraClasses="filter-button-icon filter-button-icon--delete"
            size={24}
          >
            <Icon size={(containerHeight / 2) as any} set="flat" type="cross" />
          </Button>
        </div>
      </div>
    );
  }
}

export const ActiveFilter = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: ToplevelFiltersState, props: SuppliedProps) => {
    const filter = find(Selectors.getFilters(state), filter => filter.id === props.id);
    const dimension = Selectors.getDimensionsById(state)[filter.dimension.id];
    const currentStoredValues = Selectors.getValues(state);
    const hasValues = currentStoredValues && currentStoredValues[filter.dimension.id];
    const values = hasValues ? currentStoredValues[filter.dimension.id].values : [];
    const isLoadingData = hasValues
      ? Selectors.getValues(state)[filter.dimension.id].loading
      : true;
    const containerCollapsed = Selectors.getComponentState(state).collapse;

    return {
      editing: Selectors.getEditFilterState(state).selectedFilterId === props.id,
      containerHeight: Selectors.getExternalConfiguration(state).containerHeight,
      activeDimension: Selectors.getActiveDimension(state),
      currentValues: Selectors.getCurrentValues(state),
      selectedMode: Selectors.getEditFilterState(state).selectedMode,
      filter,
      dimension,
      values,
      isLoadingData,
      containerCollapsed,
    };
  },
  (dispatch: Dispatch) => ({
    editFilter: (filter: Models.Filter) => dispatch(Actions.editFilter(filter)),
    deleteFilter: (filterId: string) => dispatch(Actions.removeFilter(filterId)),
    updateAddFilterState: (state: Actions.UpdateAddFilterStatePayload) =>
      dispatch(Actions.updateAddFilterState(state)),
    updateEditFilterState: (state: Actions.UpdateEditFilterStatePayload) =>
      dispatch(Actions.updateEditFilterState(state)),
    fetchDimensionValues: () => dispatch(Actions.fetchDimensionValues() as any),
    fetchDimensionValuesByFilter: debounce(
      (filter: Models.Filter, query: string) =>
        dispatch(Actions.fetchDimensionValuesByFilter(filter, query) as any) as any,
      250,
    ),
  }),
)(UnconnectedActiveFilter);
