/**
 * @prettier
 */

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { CrashReporting } from 'utils/linking';

import { ThemeType } from 'app/theme/index';

import { Flex } from 'components/flex/index';
import { Avatar } from 'components/avatar';
import { Icon, IconAfter } from 'components/icon/index';
import Text from 'components/text/index';

import * as Actions from '../../../../actions';
import { Padding } from 'components/layout/index';
import HorizontalRule from 'components/horizontalRule';

const StyledNavigationButton = (props: ThemeType): IStyle => ({
  borderRadius: '4px',
  backgroundColor: props.theme.colors.blue.standard,
  width: '40px',
  height: '40px',
});

const NavigationButton = createComponent(StyledNavigationButton, 'div', ['onClick']);

type Props = {
  data: Actions.ErrorsPerAppListData[];
  updateViewedApplication: (appId: number) => void;
};
export const ErrorsPerAppTableMobile: React.FunctionComponent<Props> = ({
  data,
  updateViewedApplication,
}) => {
  const rows = data.map(({ AvatarUrl, ErrorGroupCount, ErrorInstanceCount, Id, Name }) => (
    <>
      <Flex direction="row">
        <Flex>
          <Avatar url={AvatarUrl} size={48} />
        </Flex>

        <Flex direction="column" flex={1} minWidth="70%">
          <Padding left="12" right="8">
            <a href={CrashReporting.applicationById(Id)} target="_blank">
              <Icon set="icomoon" type="new-window" after>
                <Text ellipsis size="large" height="24">
                  {Name}
                </Text>
              </Icon>
            </a>
          </Padding>

          <Padding left="12" top="8">
            <Text weight="bold" layout="inline">
              {ErrorGroupCount}
            </Text>{' '}
            Groups |{' '}
            <Text weight="bold" layout="inline">
              {ErrorInstanceCount}
            </Text>{' '}
            Instances
          </Padding>
        </Flex>

        <Flex>
          <NavigationButton onClick={() => updateViewedApplication(Id)}>
            <Flex justify="center" align="center" minHeight="40px">
              <Icon set="icomoon" type="chevron-right" size={24} color="white" />
            </Flex>
          </NavigationButton>
        </Flex>
      </Flex>

      <Padding bottom="16" top="16">
        <HorizontalRule />
      </Padding>
    </>
  ));

  return <Flex direction="column">{rows}</Flex>;
};
