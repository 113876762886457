/** @format */

import * as React from 'react';
import { Cell } from './cell';
import { map } from 'lodash';
import Text from 'components/text';

type Props = {
  dataRows: any[];
  children: (rowData: any) => React.ReactElement<any>[];
  getKey: (rowData: any) => string;
  noDataMessage?: string;
};

export const Table: React.FunctionComponent<Props> = ({
  dataRows,
  children,
  getKey,
  noDataMessage,
}) => {
  const rowGenerator = children;
  const rows = dataRows.map(row => (
    <tr className="active-list-row" key={getKey(row)}>
      {map(rowGenerator(row), (cell, idx) => cell && React.cloneElement(cell, { key: idx }))}
    </tr>
  ));

  let noDataView = null;

  if (noDataMessage && rows.length === 0) {
    noDataView = (
      <tr className="active-list-row">
        <Cell verticalXL spaceHorizontal>
          <Text alignment="center">
            <strong>{noDataMessage}</strong>
          </Text>
        </Cell>
      </tr>
    );
  }

  return (
    <table className="active-list">
      <tbody className="active-list__body">{noDataView || rows}</tbody>
    </table>
  );
};
