/** @format **/

import * as React from 'react';

import { Icon, IconBefore } from 'components/icon';
import { DropdownSection, DropdownTitle, DropdownOption } from 'components/dropdown';

import { ContainerChildProps } from '../containers/userContent';
import Plans from './userPlans';

const Content: React.FunctionComponent<ContainerChildProps> = props => {

  return (
    <div>
      <DropdownSection>
        <DropdownTitle>Connected plan{props.plans.length > 1 ? 's' : ''}:</DropdownTitle>
        <Plans plans={props.plans} />
      </DropdownSection>
      <DropdownSection>
        <DropdownTitle>My options:</DropdownTitle>
        <DropdownOption href="/auditlog">
          <IconBefore icon={<Icon type="auditlog" />}>Audit log</IconBefore>
        </DropdownOption>
        <DropdownOption href="/user/notifications">
          <IconBefore icon={<Icon set={'icomoon'} type="email" />}>My notifications</IconBefore>
        </DropdownOption>
        <DropdownOption href="/user">
          <IconBefore icon={<Icon type="cog" />}>My settings</IconBefore>
        </DropdownOption>
      </DropdownSection>
      <DropdownSection>
        <DropdownOption href="/signout">
          <IconBefore icon={<Icon type="logout" />}>Signout</IconBefore>
        </DropdownOption>
      </DropdownSection>
    </div>
  );
};

export default Content;
