/** @format **/

import React from 'react';
import { isNil } from 'lodash';

import { useHover } from 'components/ds/hooks/useHover';
import { Fade } from 'components/animated/fade';
import { trackLinkClick } from 'components/snowplow';

import {
  HelptipArrow,
  HelptipContainer,
  HelptipLabel,
  HelptipLink,
  HelptipWrapper,
} from './components';

export type HelptipProps = {
  label: React.ReactNode;
  link?: string;
  big?: boolean;
  small?: boolean;
  alignCenter?: boolean;
  alignRight?: boolean;
  positionRight?: boolean;
  arrowPosition?: 15 | 18;
  openLinkInNewTab?: boolean;
  linkText?: React.ReactNode;
  snowplowTrackingId?: string;
  hideArrow?: boolean;
  block?: boolean;
  showOnRight?: boolean;
  fullWidthTrigger?: boolean;
  disableAnimation?: boolean;
  itemBlock?: boolean;
  paddingRight?: boolean;
};
export const Helptip: React.FunctionComponent<HelptipProps> = ({
  children,
  label,
  link,
  big,
  small,
  alignCenter,
  alignRight,
  positionRight,
  openLinkInNewTab,
  linkText,
  arrowPosition,
  snowplowTrackingId,
  hideArrow,
  block,
  showOnRight,
  fullWidthTrigger,
  disableAnimation,
  itemBlock,
  paddingRight,
}) => {
  const { hovered, ...eventProps } = useHover({ delayUnhover: disableAnimation ? 0 : 200 });
  const onClick = () => {
    if (!isNil(snowplowTrackingId)) {
      trackLinkClick(snowplowTrackingId);
    }
  };

  return (
    <HelptipWrapper
      {...eventProps}
      fullWidthTrigger={fullWidthTrigger}
      showOnRight={showOnRight}
      centered={alignCenter}
      block={itemBlock}
      paddingRight={paddingRight}
    >
      {children}
      <Fade visible={hovered} fadeIn={!disableAnimation} duration={hovered ? 400 : 200}>
        <HelptipContainer
          big={big}
          small={small}
          hasLink={!isNil(link)}
          hovered={hovered}
          centered={alignCenter}
          alignRight={alignRight}
          positionRight={positionRight}
          block={block}
          showOnRight={showOnRight}
          disableAnimation={disableAnimation}
        >
          {!hideArrow && (
            <HelptipArrow
              arrowPosition={arrowPosition}
              showOnRight={showOnRight || positionRight}
            />
          )}

          <HelptipLabel>{label}</HelptipLabel>

          {!isNil(link) && (
            <HelptipLink onClick={onClick} href={link} target={openLinkInNewTab && '_blank'}>
              {linkText}
            </HelptipLink>
          )}
        </HelptipContainer>
      </Fade>
    </HelptipWrapper>
  );
};
Helptip.defaultProps = {
  big: false,
  linkText: 'Learn more',
  showOnRight: false,
  fullWidthTrigger: false,
  disableAnimation: false,
};
