/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type ModalMessageProps = {
  color: 'yellow' | 'grey' | 'blue';
};

const StyledModalMessageProps = (props: ModalMessageProps & ThemeType): IStyle =>
  applyModifiers(
    [
      props.color === 'yellow',
      {
        backgroundColor: props.theme.colors.lightning.highlight,
      },
    ],
    [
      props.color === 'blue',
      {
        backgroundColor: props.theme.colors.standard.azureHighlight,
      },
    ],
    [
      props.color === 'grey',
      {
        backgroundColor: props.theme.colors.grey.light,
      },
    ],
  )({
    padding: '12px 24px',
    borderBottom: `1px solid ${props.theme.colors.border.standard}`,
  });

export const ModalMessage = createComponent<ModalMessageProps>(StyledModalMessageProps);