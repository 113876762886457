/** @format */

// Jist for generating a UUID from JED
//
// Free to use:
// https://gist.github.com/jed/982883

export function uuid(a?: any): string {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
}
