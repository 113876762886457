/** @format */

import React from 'react';
import { Column, Table } from 'components/table';
import { SkeletonCell, SkeletonTableData } from 'components/table/cells';

export const LoadingSlowestTraces: React.FunctionComponent<{}> = () => {
  const columns: Column[] = [
    {
      header: 'Date',
      width: 180,
      cell: new SkeletonCell(),
    },
    {
      header: 'Name',
      cell: new SkeletonCell(),
    },
    {
      header: 'Duration',
      width: 140,
      cell: new SkeletonCell(),
    },
  ];

  return <Table data={SkeletonTableData} columns={columns} />;
};
