/** @format */

import * as React from 'react';
import { Checkbox, Props as CheckboxProps } from './checkbox';

type Props = CheckboxProps;

export const RadioButton: React.FunctionComponent<Props> = props => (
  <Checkbox {...props} isRadioButton />
);
