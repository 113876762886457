/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { clearfix } from 'fela-rules/layout';
import { applyModifiers } from 'fela-rules/modifiers';
import { screenMediumAndAbove } from 'fela-rules/breakpoints';

type ContainerProps = {
  gutter?: number;
  width?: number;
  gapRight?: boolean;
  gapLeft?: boolean;
};

type ChildProps = {
  firstChild: boolean;
  width: number;
  verticalGap?: number;
  gutter?: number;
  marginRight?: boolean;
  marginLeft?: boolean;
};

type Props = {
  verticalGap?: number;
} & ContainerProps;

const StyledContainer = (props: ContainerProps): IStyle =>
  applyModifiers(
    [
      props.width !== null,
      {
        ...screenMediumAndAbove({
          width: `${props.width}px`,
        }),
      },
    ],
    [
      !props.gapLeft,
      {
        ...screenMediumAndAbove({
          paddingLeft: '0',
        }),
      },
    ],
    [
      !props.gapRight,
      {
        ...screenMediumAndAbove({
          paddingRight: '0',
        }),
      },
    ],
  )({
    ...screenMediumAndAbove({
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      paddingLeft: `${props.gutter / 2}px`,
      paddingRight: `${props.gutter / 2}px`,
    }),
  });

const Container = createComponent<ContainerProps>(StyledContainer);

Container.defaultProps = {
  gutter: 16,
};

const StyledChild = (props: ChildProps): IStyle =>
  applyModifiers(
    [
      props.firstChild,
      {
        marginTop: '0',
      },
    ],
    [
      !props.marginLeft,
      {
        ...screenMediumAndAbove({
          marginLeft: '0',
        }),
      },
    ],
    [
      !props.marginRight,
      {
        ...screenMediumAndAbove({
          marginRight: '0',
        }),
      },
    ],
  )({
    marginTop: `${props.verticalGap}px`,
    ...screenMediumAndAbove({
      marginTop: '0',
      flex: `1 1 ${props.width}%`,
      marginLeft: `${props.gutter / 2}px`,
      marginRight: `${props.gutter / 2}px`,
    }),
  });

const Child = createComponent<ChildProps>(StyledChild);

Child.defaultProps = {
  verticalGap: 16,
  gutter: 16,
};

export const EqualWidthLayout: React.FunctionComponent<Props> = props => {
  const childCount = React.Children.count(props.children);
  const childWidth = Math.round(100 / childCount);

  const children = React.Children.map(props.children, (child, index) => {
    const firstChild = index === 0;
    const lastChild = index === childCount - 1;

    return (
      <Child
        key={index}
        width={childWidth}
        firstChild={firstChild}
        marginRight={props.gapRight || !lastChild}
        marginLeft={props.gapLeft || !firstChild}
        gutter={props.gutter}
        verticalGap={props.verticalGap}
      >
        {child}
      </Child>
    );
  });

  return <Container {...props}>{children}</Container>;
};

EqualWidthLayout.defaultProps = {
  gapLeft: true,
  gapRight: true,
};
