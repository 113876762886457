/** @format */

import * as React from 'react';
import { get, reduce } from 'lodash';

import { CustomChart } from 'components/ds/charts';
import * as charting from 'utils/echarts';
import { isMobileDevice } from 'utils/mobile';

import { SessionCountChartPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';

const Chart: React.FunctionComponent<ProviderProps> = props => {
  const data: SessionCountChartPayload['Data'] = get(props, 'data.Data', []);
  const interval: SessionCountChartPayload['Interval'] = get(props, 'data.Interval', '1d');

  const chartData = reduce(
    data,
    (acc, dataPoint) => ({
      sessionCounts: [...acc.sessionCounts, dataPoint.Count],
      periods: [...acc.periods, dataPoint.Period],
    }),
    { sessionCounts: [], periods: [] },
  );

  const options = (chart: any) => {
    return charting.extendOptions({
      color: ['#158FEF'],
      tooltip: {
        show: true,
        confine: true,
        trigger: 'axis',
        formatter: (params: any[]) => {
          return charting.renderSeriesTooltip({
            date: charting.formatUtcTooltipDate(params[0].name, interval),
            series: [
              {
                name: 'Session',
                value: params[0].data,
                colorcode: params[0].color,
              },
            ],
          });
        },
        axisPointer: charting.rum.AxisPointerLine,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          data: chartData.periods,
          axisLabel: {
            formatter: charting.getDateTimeLabelFormatter(
              chartData.periods,
              interval,
              chart ? chart.getEchartsInstance() : undefined,
            ),
            rotate: isMobileDevice() ? 90 : 0,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: charting.getIntegerLabelFormatter(
              chart ? chart.getEchartsInstance() : undefined,
            ),
          },
        },
      ],
      series: [
        {
          symbol: 'none',
          type: 'line',
          colorStyle: 'blue-dark',
          data: chartData.sessionCounts,
        },
      ],
    });
  };

  return <CustomChart height={'100%'} options={options} />;
};

const ConnectedTile = TileDataProvider(Chart, ChartTileLoading);

export { ConnectedTile as SessionCountChart };
