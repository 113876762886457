/** @format */

import * as Colors from 'app/theme/materialDS';

export const defaultOptions = Object.freeze({
  animation: false,
  noDataLoadingOption: {
    effect: function() {
      return {
        start: function(): any {
          return undefined;
        },
        stop: function(): any {
          return undefined;
        },
      };
    },
  },
  tooltip: {
    show: false,
    confine: true,
    z: 0,
    padding: 0,
    borderWidth: 0,
    borderRadius: 0,
    showDelay: 0, // ms
    hideDelay: 0, // ms
    transitionDuration: 0, // s
  },
  grid: {
    show: false,
    top: 8,
    right: 0,
    bottom: 8,
    left: 48,
  },
  xAxis: [
    {
      axisLabel: {
        textStyle: {
          fontSize: 13,
          color: Colors.BLUE_GREY.blueGrey500,
          fontFamily: '"Open Sans", arial, helvetica, serif',
        },
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
  ],
  yAxis: [
    {
      axisLabel: {
        textStyle: {
          fontSize: 13,
          color: Colors.BLUE_GREY.blueGrey500,
          fontFamily: '"Open Sans", arial, helvetica, serif',
        },
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: Colors.BLUE_GREY.blueGrey75,
          type: 'solid',
          width: 1,
        },
      },
      splitArea: {
        show: false,
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: Colors.BLUE_GREY.blueGrey75,
          type: 'solid',
          width: 1,
        },
      },
    },
  ],
});

export const tooltipOptions = Object.freeze({
  show: true,
  confine: true,
  trigger: 'axis',
  borderColor: Colors.BLUE_GREY.blueGrey200,
  borderWidth: 1,
  backgroundColor: Colors.SPECIAL.white,
  padding: 8,
  textStyle: {
    color: Colors.GREY.grey800,
    fontSize: 13,
  },
  extraCssText: 'min-width: 90px; max-width: 280px',
});

export const legendOptions = Object.freeze({
  grid: {
    bottom: 36,
  },
  legend: {
    // Visually hides the legend when it's replaced by a custom legend
    // This is so that the functionality remains on the chart but is not visible on the chart
    top: '100%',
    icon: 'none',
    inactiveColor: 'transparent',
    textStyle: {
      color: 'transparent',
    },
  },
});

export const echartsLegendOptions = Object.freeze({
  grid: {
    bottom: 56,
  },
  legend: {
    icon: 'circle',
    orient: 'horizontal',
    x: 'center',
    y: 'bottom',
    padding: [4, 16],
    textStyle: {
      fontFamily: '"Open Sans", arial, helvetica, serif',
      fontSize: 14,
      color: '#404040',
    },
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 20,
  },
});

export const visualMapOptions = Object.freeze({
  dimension: 0,
  inRange: {
    symbol: 'circle',
  },
});
