/** @format **/
/* tslint:disable:max-file-line-count */
/* tslint:disable:max-classes-per-file */

import React from 'react';
import { omit, isEmpty } from 'lodash';

import { Omit, PropsOfWithDefaults } from 'utils/types';

import { TextButton, EmphasisButton, Button, UtilityIconButton } from 'components/ds/button';
import { FilterText } from 'components/ds/filters';
import { IconProps } from 'components/icon';
import { PlanAvatar } from 'components/ds/planAvatar';
import { Labeltip } from 'components/ds/tooltip';

import { BaseTrigger, Props as BaseProps, DefaultProps } from './base';
import { InputTrigger } from '../../inputs/triggers';
import { SubtleDropdownButton } from './subtle';

type SharedProps = Omit<BaseProps, 'Trigger' | 'triggerProps' | 'hasLabel'>;

type TextDropdownProps = SharedProps & {
  triggerProps: PropsOfWithDefaults<typeof TextButton, typeof TextButton.defaultProps>;
};
export class TextDropdown extends React.Component<TextDropdownProps> {
  static defaultProps = {
    ...DefaultProps,
    triggerProps: TextButton.defaultProps,
  };

  render() {
    return <BaseTrigger Trigger={TextButton} {...this.props} />;
  }
}

type IconDropdownProps = SharedProps & {
  triggerProps: PropsOfWithDefaults<
    typeof UtilityIconButton,
    typeof UtilityIconButton.defaultProps
  >;
};
const UtilityIconButtonWrapper: React.FunctionComponent<
  IconDropdownProps['triggerProps']
> = props => {
  return (
    <Labeltip label={props.children as string} position="bottom">
      <UtilityIconButton {...props} />
    </Labeltip>
  );
};
export class IconDropdown extends React.Component<IconDropdownProps> {
  static defaultProps = {
    ...DefaultProps,
    triggerProps: UtilityIconButton.defaultProps,
  };

  render() {
    return <BaseTrigger Trigger={UtilityIconButtonWrapper} {...this.props} />;
  }
}

type DropdownProps = SharedProps & {
  triggerProps: PropsOfWithDefaults<typeof Button, typeof Button.defaultProps>;
};
export class Dropdown extends React.Component<DropdownProps> {
  static defaultProps = {
    ...DefaultProps,
    triggerProps: Button.defaultProps,
  };

  render() {
    return <BaseTrigger Trigger={Button} {...this.props} />;
  }
}

type EmphasisDropdownProps = SharedProps & {
  triggerProps: PropsOfWithDefaults<typeof EmphasisButton, typeof EmphasisButton.defaultProps>;
};
export class EmphasisDropdown extends React.Component<EmphasisDropdownProps> {
  static defaultProps = {
    ...DefaultProps,
    triggerProps: EmphasisButton.defaultProps,
  };

  render() {
    return <BaseTrigger Trigger={EmphasisButton} {...this.props} />;
  }
}

type FilterTextDropdownProps = SharedProps & {
  triggerProps: PropsOfWithDefaults<typeof FilterText, typeof FilterText.defaultProps>;
} & PropsOfWithDefaults<typeof FilterText, typeof FilterText.defaultProps>;

export class FilterTextDropdown extends React.Component<FilterTextDropdownProps> {
  static defaultProps = {
    ...DefaultProps,
    triggerProps: FilterText.defaultProps,
    height: 'small',
  };

  render() {
    const triggerProps = {
      ...this.props.triggerProps,
      disabled: this.props.disabled,
      roundLeftCorners: this.props.roundLeftCorners,
      roundRightCorners: this.props.roundRightCorners,
    };
    return <BaseTrigger Trigger={FilterText} {...this.props} triggerProps={triggerProps} />;
  }
}

type SelectDropdownProps = {
  value?: string;
  children: any;
  onValueChange?: (value: any, item?: any) => void;
  onToggle?: (toggled: any) => void;
  label?: string | JSX.Element;
  subtext?: string;
  error?: string;
  absoluteError?: boolean;
  placeholder?: string;
  iconLeft?: React.ReactElement<IconProps>;
  inputId?: string;
  placeholderTextColor?: string;
  removePadding?: boolean;
  multiSelect?: boolean;
  clearableSearch?: boolean;
} & SharedProps &
  React.InputHTMLAttributes<any>;
export class SelectDropdown extends React.Component<SelectDropdownProps> {
  static defaultProps = {
    overflowCount: 5,
    overflow: true,
    position: 'left',
    height: 'normal',
    block: false,
    small: false,
    disabled: false,
    triggerText: '',
    label: '',
    selection: true,
    fullWidthOnMobile: false,
    placeholderTextColor: 'grey800',
    clearableSearch: false,
  };

  render() {
    const triggerProps = omit(this.props, [
      'children',
      'forDropdownTrigger',
      'triggerText',
      'overflow',
      'overflowCount',
      'onValueChange',
      'onToggle',
      'onVisibilityChange',
      'fullWidth',
      'hasLabel',
      'position',
      'height',
      'id',
    ]);

    return (
      <BaseTrigger
        hasLabel={!isEmpty(this.props.label) || this.props.required}
        triggerText={this.props.placeholder}
        triggerProps={triggerProps}
        Trigger={InputTrigger}
        {...this.props}
      />
    );
  }
}

type PlanAvatarDropdownProps = SharedProps & {
  triggerProps: PropsOfWithDefaults<typeof PlanAvatar, typeof PlanAvatar.defaultProps>;
};

export class PlanAvatarDropdown extends React.Component<PlanAvatarDropdownProps> {
  static defaultProps = {
    ...DefaultProps,
    triggerProps: PlanAvatar.defaultProps,
  };

  render() {
    return <BaseTrigger Trigger={PlanAvatar} {...this.props} />;
  }
}

type SubtleDropdownProps = SharedProps & {
  triggerProps: PropsOfWithDefaults<
    typeof SubtleDropdownButton,
    typeof SubtleDropdownButton.defaultProps
  >;
};

export class SubtleDropdown extends React.Component<SubtleDropdownProps> {
  static defaultProps = {
    ...DefaultProps,
    triggerProps: SubtleDropdownButton.defaultProps,
  };

  render() {
    return <BaseTrigger Trigger={SubtleDropdownButton} {...this.props} forSubtle />;
  }
}
