/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */
/* ignore file coverage */

import React from 'react';
import { omit } from 'lodash';

import {
  BaseIconButton,
  BaseDefaultProps,
  DefaultProps,
  PublicBaseIconButtonProps,
} from './component';

type IconButtonProps = {
  hoverInteractiveOpacity?: number;
  focusInteractiveOpacity?: number;
} & PublicBaseIconButtonProps;

export class IconButton extends React.Component<IconButtonProps> {
  static defaultProps: Pick<
    IconButtonProps,
    | 'textColor'
    | 'disabledTextColor'
    | 'hoverHighlightColor'
    | 'pressedHighlightColor'
    | keyof BaseDefaultProps
  > = {
    textColor: 'blue1000',
    disabledTextColor: 'grey300',
    hoverHighlightColor: 'blue100',
    pressedHighlightColor: 'blue200',
    ...DefaultProps,
  };

  render() {
    return <BaseIconButton {...omit(this.props, ['forDropdownTrigger', 'fullWidth'])} />;
  }
}

type UtilityIconButtonProps = PublicBaseIconButtonProps;
export class UtilityIconButton extends React.Component<UtilityIconButtonProps> {
  static defaultProps: Pick<
    UtilityIconButtonProps,
    | 'textColor'
    | 'disabledTextColor'
    | 'hoverHighlightColor'
    | 'pressedHighlightColor'
    | keyof BaseDefaultProps
  > = {
    textColor: 'blueGrey600',
    disabledTextColor: 'grey400',
    hoverHighlightColor: 'blueGrey50',
    pressedHighlightColor: 'blueGrey100',
    ...DefaultProps,
  };

  render() {
    return <BaseIconButton {...omit(this.props, ['forDropdownTrigger', 'fullWidth'])} />;
  }
}
