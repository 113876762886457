/**
 * @prettier
 */
import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { HEADING_CLASSES } from 'components/text';

type ModuleHeaderTabsProps = {
  title: string;
  tabs: string[];
  selected: string;
  children: (tab: string, isSelected: boolean) => React.ReactNode;
};

type TabProps = {
  isSelected: boolean;
};

const StyledTab = (props: TabProps & ThemeType): IStyle =>
  applyModifiers([
    props.isSelected,
    {
      '::after': {
        backgroundColor: props.theme.colors.material.blue600,
        visibility: 'visible',
      },
    },
  ])({
    marginRight: '24px',
    paddingTop: '8px',
    paddingBottom: '4px',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    '::after': {
      content: '" "',
      display: 'block',
      width: '100%',
      height: '3px',
      position: 'absolute',
      bottom: '-12px',
      left: '0',
      visibility: 'hidden',
      backgroundColor: props.theme.colors.grey.dark,
    },
    [':hover::after' as any]: {
      visibility: 'visible',
    },
  });

const Tab = createComponent<TabProps>(StyledTab, 'li');

const StyledTabContainer = (): IStyle => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignContent: 'flex-end',
});

const TabContainer = createComponent(StyledTabContainer, 'ul');

export const ModuleHeaderTabs: React.FunctionComponent<ModuleHeaderTabsProps> = props => {
  return (
    <>
      <div className="line-height-24">
        <h4 className={HEADING_CLASSES.FIVE}>{props.title}</h4>
      </div>
      <TabContainer>
        {props.tabs.map(t => (
          <Tab key={t} isSelected={props.selected === t}>
            {props.children(t, props.selected === t)}
          </Tab>
        ))}
      </TabContainer>
    </>
  );
};
