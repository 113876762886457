/** @format */

import { combineReducers } from 'redux';

import { DashboardDataReducer } from './tiles';
import { DashboardConfigReducer } from './config';
import { DashboardTileSettingReducer } from './tileSetting';

export const DashboardReducer = combineReducers({
  layout: DashboardConfigReducer,
  tiles: DashboardDataReducer,
  activeTileSettings: DashboardTileSettingReducer,
});
