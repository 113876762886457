/** @format */

import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type FooterProps = {
  noBorder?: boolean;
  smallPadding?: boolean;
};

const StyledModalFooter = (props: FooterProps & ThemeType): IStyle => {
  const styles = {
    padding: '24px',
    borderTop: `1px solid ${props.theme.colors.border.standard}`,
  };

  return applyModifiers(
    [
      props.noBorder,
      {
        border: 'none',
      },
    ],
    [
      props.smallPadding,
      {
        padding: '12px',
      },
    ],
  )(styles);
};

export const ModalFooter = createComponent<FooterProps>(StyledModalFooter);
