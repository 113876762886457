/**
 * @prettier
 */

import * as React from 'react';
import { connect } from 'react-redux';

import { DESCENDING } from 'constants/sorting';
import { formatMillisecondsAsMultiPartDuration } from 'utils/durationFormatting';

import { Column, SortedTable, Table } from 'components/table';
import { DateCell, NumberCell } from 'components/table/cells';
import Text from 'components/text';
import { Padding } from 'components/layout';

import { DashboardModuleStateAtom } from '../../../state';
import { SlowestTracesPayload } from '../../../actions';

import { SortingHoCProps, SortingHoC } from '../../';

import { LoadingSlowestTraces } from '../';
import { Props as TileDataProviderProps, TileDataProvider } from '../dataProvider';
import { getApplicationsById } from 'app/selectors/application';

type MessageCellSuppliedProps = SlowestTracesPayload;
type MessageCellStateProps = { applicationName: string };
type MessageCellProps = MessageCellStateProps & MessageCellSuppliedProps;
const UnconnectedMessageCell: React.FunctionComponent<MessageCellProps> = props => {
  const link = `/apm/${props.applicationId.toString(36)}/traces/${props.identifier}`;

  return (
    <>
      <Text size="medium" ellipsis>
        <a href={link} title={props.name}>
          {props.name}
        </a>
      </Text>
      <Padding top="4">
        <Text size="small" color="grey">
          {props.applicationName}
        </Text>
      </Padding>
    </>
  );
};
const MessageCell = connect<MessageCellStateProps, {}, MessageCellSuppliedProps>(
  (state: DashboardModuleStateAtom, ownProps: MessageCellSuppliedProps) => ({
    applicationName: getApplicationsById(state)[ownProps.applicationId].name,
  }),
)(UnconnectedMessageCell);

type Props = TileDataProviderProps & SortingHoCProps;
const SlowestTraces: React.FunctionComponent<Props> = props => {
  const columns: Column[] = [
    {
      header: 'Date',
      width: 180,
      sortOptions: { sortField: 'date', defaultSortDirection: DESCENDING },
      cell: new DateCell({
        accessor: 'occurredOn',
      }),
      spaceLeft: true,
      headerSpaceRight: true,
    },
    {
      header: 'Name',
      sortOptions: { sortField: 'name', defaultSortDirection: DESCENDING },
      cell: MessageCell,
    },
    {
      header: 'Duration',
      sortOptions: { sortField: 'duration', defaultSortDirection: DESCENDING },
      width: 140,
      cell: new NumberCell({
        accessor: rowItem => Math.round(rowItem.duration / 1000),
        formatter: formatMillisecondsAsMultiPartDuration,
      }),
    },
  ];

  return (
    <SortedTable
      data={props.data as any}
      columns={columns}
      onSortChange={props.updateSorting}
      sortField={props.sortField}
      sortDirection={props.sortDirection}
      noBorderOnLastRow
    />
  );
};

const ConnectedSlowestTraces = TileDataProvider(SortingHoC(SlowestTraces), LoadingSlowestTraces);

ConnectedSlowestTraces.moduleProps = {
  noPadding: true,
};

export { ConnectedSlowestTraces as SlowestTraces };
