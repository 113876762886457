/** @format */
/* tslint:disable:max-file-line-count */

import React from 'react';
import { merge, isFunction, throttle, isNil, get } from 'lodash';

import { trackLinkClick } from 'components/snowplow';
import { isMobileDevice } from 'utils/mobile';
import * as charting from 'utils/echarts';
import { SessionStorageUtil } from 'utils/storage';

import {
  defaultOptions,
  DEFAULT_GROUP_ID,
  addChartToGroup,
  getDataPointIndexFromEvent,
  legendOptions,
} from './utils';

import { Legend, LegendItem, EChartsActionCreator } from './legend';
import * as Models from './models';

const ReactEchartsCore = require('echarts-for-react/lib/core').default;

const echarts = require('echarts/lib/echarts');
require('echarts/lib/chart/bar');
require('echarts/lib/chart/pie');
require('echarts/lib/chart/line');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/markArea');
require('echarts/lib/component/markLine');
require('echarts/lib/component/legend');
require('echarts/lib/component/tooltip');

/**
 * Base chart provides common functionality across our charts and
 * should be used when wanting to render any sort of eChart. Even if the
 * functionality is not desired on the type of chart being created.
 *
 * Why? This allows us to split out files rendered by out charting library
 * enabling smaller bundle sizes in the future.
 *
 * Guidelines for creating new chart types:
 * - Do not render directly `ReactEchartsCore`, instead opt to pass props to this `<Base />`
 * - types should extend the `PublicProps` as these are the minimum properties required by the base
 * - If a chart can support zooming your props should also extend the `ZoomProps` type
 * - If a chart can support grouping your props should also extend the `GroupingProps` type
 */

export type PublicProps = {
  height: string;
  width?: string;
  className?: string;
  onClick?: (e: any) => void;
  /**
   * "snowplowIdPrefix" is used for sending graph interactions to snowplow so we can track engagement.
   * If an "snowplowIdPrefix" is not specifed, feature tracking is not enabled. By default it's not enabled.
   */
  snowplowIdPrefix?: string;
  hasMarkLineKey?: boolean;
};

export type GroupingProps = Partial<{
  group: boolean;
  groupingId: string;
}>;

export type ZoomProps = Partial<{
  periods: string[];
  zoomOnDateRange: boolean;
  onDateRangeChange: (dateFrom: string, dateTo: string) => void;
}>;

export type LegendProps = Partial<{
  legend: boolean;
  legendItems?: LegendItem[];
  chartStorageKey?: string;
  createEChartsActionForLegendToggle?: EChartsActionCreator;
}>;

type Props = {
  options: any;
  series: any;
  applyDefaultStyling?: boolean;
} & PublicProps &
  ZoomProps &
  GroupingProps &
  LegendProps;

type State = {
  parsedItemsFromStorage: number[];
};

export class Base extends React.Component<Props, State> {
  chart: any;
  disposeEvents: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      parsedItemsFromStorage: [],
    };

    this.getChartRef = this.getChartRef.bind(this);
    this.generateOptions = this.generateOptions.bind(this);
    this.addZoomMouseEvents = this.addZoomMouseEvents.bind(this);
    this.dispatchAction = this.dispatchAction.bind(this);
    this.updateDeselectedItems = this.updateDeselectedItems.bind(this);
  }

  componentDidMount() {
    if (!!this.props.chartStorageKey) {
      const parsedItemsFromStorage = JSON.parse(
        SessionStorageUtil.getItem(this.props.chartStorageKey),
      );

      if (!isNil(parsedItemsFromStorage) && parsedItemsFromStorage.length > 0) {
        parsedItemsFromStorage.forEach((i: number) => {
          const legendItem = this.props.legendItems[i];
          // Different charts may have varying amounts of legend items, so when trying to apply a list of disabled
          // legend items (cached by index) to a new chart, we may exceed the bounds of the new chart's legend array.
          // So just make best effort, worst case is some items aren't disabled
          if(legendItem === undefined){
            return;
          }
          const iName = this.props.legendItems[i].name;
          const action = this.createLegendAction(iName);
          this.dispatchAction(action);
        });
        this.setState({ parsedItemsFromStorage: parsedItemsFromStorage });
      }
    }
  }

  static defaultProps = {
    zoomOnDateRange: false,
    group: false,
    groupingId: DEFAULT_GROUP_ID,
    applyDefaultStyling: true,
  };

  createLegendAction(name: string): Models.eChartsLegendAction {
    return {
      type: 'legendUnSelect',
      name,
    };
  }

  getChartRef(chart: any) {
    if (!chart) {
      return;
    }

    this.chart = chart;

    if (this.props.group) {
      addChartToGroup(chart, this.props.groupingId);
    }

    if (this.props.zoomOnDateRange && this.props.periods && !isMobileDevice()) {
      this.addZoomMouseEvents();
    }

    if (isFunction(this.props.options)) {
      const instance = this.chart.getEchartsInstance();
      instance.setOption(this.generateOptions());
    }
  }

  dispatchAction(action: Models.eChartsAction) {
    const instance = this.chart.getEchartsInstance();
    instance.dispatchAction(action);

    if (
      action.type === 'legendSelect' ||
      action.type === 'legendUnSelect' ||
      action.type === 'selectDataRange'
    ) {
      this.trackFeature('legendInteraction');
    }
  }

  trackFeature(event: string) {
    if (!isNil(this.props.snowplowIdPrefix)) {
      trackLinkClick(`${this.props.snowplowIdPrefix}-${event}`);
    }
  }

  addZoomMouseEvents() {
    const instance = this.chart.getEchartsInstance();
    const zr = instance.getZr();

    let down = false;
    let startIndex: number;
    let endIndex: number;

    const renderMarkArea = () => {
      const markArea = charting.egcMarkArea(down, startIndex, endIndex);

      instance.setOption({
        series: [
          {
            markArea: markArea,
          },
        ],
      });
    };

    const onDown = (event: any) => {
      down = true;
      startIndex = getDataPointIndexFromEvent(this.chart, event, this.props.periods.length - 1);
      renderMarkArea();
    };

    const onUp = (event: any) => {
      endIndex = getDataPointIndexFromEvent(this.chart, event, this.props.periods.length - 1);
      renderMarkArea();
      onExit();
    };

    const onMove = (event: any) => {
      if (down && !isNil(startIndex)) {
        endIndex = getDataPointIndexFromEvent(this.chart, event, this.props.periods.length - 1);
        renderMarkArea();
      }
    };

    const onExit = () => {
      if (down) {
        const startDate = get(this.props.periods, Math.min(startIndex, endIndex), null);

        const endDate = get(this.props.periods, Math.max(endIndex, startIndex), null);

        startIndex = null;
        endIndex = null;
        down = false;

        renderMarkArea();

        if (isNil(startDate) || isNil(endDate) || startDate === endDate) {
          return;
        }

        this.props.onDateRangeChange(startDate, endDate);
      }
    };

    window.addEventListener('mouseleave', onExit);
    window.addEventListener('mouseup', onExit);

    zr.on('mousedown', onDown);
    zr.on('mouseup', onUp);
    zr.on('mousemove', throttle(onMove, 100));

    this.disposeEvents = () => {
      window.removeEventListener('mouseleave', onExit);
      window.removeEventListener('mouseup', onExit);
    };
  }

  componentWillUnmount() {
    if (this.disposeEvents) {
      this.disposeEvents();
    }
  }

  generateOptions() {
    // options for generating the chart get loaded in here
    const generatedOptions = isFunction(this.props.options)
      ? this.props.options(this.chart)
      : this.props.options;

    return merge({}, this.props.applyDefaultStyling ? defaultOptions : {}, generatedOptions, {
      series: this.props.series,
      ...(this.props.legend ? legendOptions : {}),
    });
  }

  updateDeselectedItems(items: number[]) {
    if (!!this.props.chartStorageKey) {
      SessionStorageUtil.setItem(this.props.chartStorageKey, JSON.stringify(items));
    }
    this.setState({ parsedItemsFromStorage: items });
  }

  render() {
    const { height, className, onClick, width } = this.props;
    const onEvents = { click: onClick };

    const chart = (
      <ReactEchartsCore
        style={{ height, width }}
        ref={this.getChartRef}
        echarts={echarts}
        option={this.generateOptions()}
        className={className}
        onEvents={onClick ? onEvents : null}
      />
    );

    return !!this.props.legend ? (
      <Legend
        items={this.props.legendItems}
        dispatchAction={this.dispatchAction}
        createEChartsAction={this.props.createEChartsActionForLegendToggle}
        alignLeftForMediumScreen={this.props.hasMarkLineKey}
        deselectedItems={this.state.parsedItemsFromStorage}
        setDeselectedItems={this.updateDeselectedItems}
      >
        {chart}
      </Legend>
    ) : (
      chart
    );
  }
}
