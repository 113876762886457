/** @format */

import { ThunkAction } from 'redux-thunk';
import createActionFactory from 'typescript-fsa';
import { isNil, isArray, isString } from 'lodash';

import { Message } from 'interfaces/messages';
import { AppState } from 'interfaces/appState';

import { apiKey, applicationIdentifier } from '../selectors/application';
import { fetchFromApp, postToApp } from 'utils/fetching';
import { Actions, Models } from 'app/notifications';

const createAction = createActionFactory('app/messages');

export const addMessages = createAction<Message[]>('ADD_MESSAGE');

export const removeMessage = createAction<number>('REMOVE_MESSAGE');

export const fetchMessages = (): ThunkAction<any, AppState, null, any> => (dispatch, getState) => {
  const state = getState();

  const applicationId = applicationIdentifier(state);

  if (isNil(applicationId) || !applicationId.length) {
    return;
  }

  return fetchFromApp(`messages/${applicationIdentifier(state)}/loadall`, apiKey(state))
    .then((data: string) => {
      if (isString(data)) {
        const parsedData: Message[] = JSON.parse(data);
        if (isArray(parsedData)) {
          dispatch(addMessages(parsedData));
        }
      }
    })
    .catch((err: Error) => {
      throw err;
    });
};

type DismissMessage = {
  MessageId: number;
};

export const dismissMessage = (data: DismissMessage): ThunkAction<any, AppState, null, any> => (
  dispatch,
  getState,
) => {
  const state = getState();

  return postToApp(
    `messages/${applicationIdentifier(state)}/${data.MessageId}/dismiss`,
    apiKey(state),
  )
    .then(() => {
      dispatch(removeMessage(data.MessageId));
    })
    .catch((err: Error) => {
      dispatch(
        Actions.show(
          Actions.createPayload(
            Models.TYPES.ERROR,
            'Failed to this dismiss message. Please try again or get in touch.',
          ),
        ),
      );
      throw err;
    });
};
