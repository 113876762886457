/**
 * @prettier
 */
import * as React from 'react';

import { CreateDashboardModule } from '../createDashboardModule';
import { Modal, ModalBodyContent, ModalProps } from 'components/modal';

export const CreateDashboardModal: React.FunctionComponent<ModalProps> = props => {
  return (
    <Modal width={704} {...props}>
      <ModalBodyContent paddingVertical={24} paddingHorizontal={60}>
        <CreateDashboardModule />
      </ModalBodyContent>
    </Modal>
  );
};
