/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { isNil } from 'lodash';

import { screenMediumAndAbove } from 'fela-rules/breakpoints';

import { HelptipIcon } from 'components/ds/tooltip/helptip/helptipIcon';
import { Helptip } from 'components/ds/tooltip';

const StyledMarkLineKeyContainer = (): IStyle => {
  return {
    position: 'relative',
  };
};

export const MarkLineKeyContainer = createComponent(StyledMarkLineKeyContainer);

const StyledMarkLineKeyWrapper = (): IStyle => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px',
    ...screenMediumAndAbove({
      position: 'absolute',
      right: 0,
      bottom: '4px',
      marginTop: 0,
    }),
  };
};

const MarkLineKeyWrapper = createComponent(StyledMarkLineKeyWrapper);

type MarkLineKeyLineProps = {
  lineColor: string;
};

const StyledMarkLineKeyLine = (props: MarkLineKeyLineProps): IStyle => {
  return {
    width: '32px',
    height: '2px',
    borderTop: `2px dashed ${props.lineColor}`,
    display: 'inline-block',
  };
};

const MarkLineKeyLine = createComponent(StyledMarkLineKeyLine, 'span');

const StyledMarkLineKeyLabel = (): IStyle => {
  return {
    fontSize: '1.4rem',
    display: 'block',
    marginLeft: '8px',
  };
};

const MarkLineKeyLabel = createComponent(StyledMarkLineKeyLabel, 'span');

const StyledMarkLineKeyTooltip = (): IStyle => {
  return {
    display: 'block',
    marginLeft: '8px',
    width: '16px',
    height: '16px',
  };
};

const MarkLineKeyHelptip = createComponent(StyledMarkLineKeyTooltip, 'span');

type Props = {
  lineColor: string;
  labelText: string;
  helpTipLabel?: React.ReactNode;
};

export const MarkLineKey: React.FunctionComponent<Props> = ({
  lineColor,
  labelText,
  helpTipLabel,
}) => (
  <MarkLineKeyWrapper>
    <MarkLineKeyLine lineColor={lineColor} />
    <MarkLineKeyLabel>{labelText}</MarkLineKeyLabel>
    {!isNil(helpTipLabel) && (
      <MarkLineKeyHelptip>
        <Helptip label={helpTipLabel} alignRight hideArrow>
          <HelptipIcon />
        </Helptip>
      </MarkLineKeyHelptip>
    )}
  </MarkLineKeyWrapper>
);
