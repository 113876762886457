/** @format */

import * as React from 'react';
import { IStyle, IRenderer } from 'fela';
import { ThemeType } from 'app/theme';
import { createComponent } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

type Props = {
  closing: boolean;
  backdropColor?: 'white' | 'black';
};

const StyledBackdrop = (props: Props & ThemeType, renderer: IRenderer): IStyle => {
  const animateIn = () => ({
    ['0%' as any]: {
      opacity: '0',
    },
    ['100%' as any]: {
      opacity: '1',
    },
  });

  const animateOut = () => ({
    ['0%' as any]: {
      opacity: '1',
    },
    ['100%' as any]: {
      opacity: '0',
    },
  });

  const style: IStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: '48px 12px 12px',
    backgroundColor: 'rgba(48,56,71,0.8)',
    ['-webkit-overflow-scrolling' as any]: 'touch',
    zIndex: 300,
  };

  return applyModifiers(
    [
      !props.closing,
      {
        animation: `${renderer.renderKeyframe(animateIn, props)} 0.2s ease-out`,
        opacity: 1,
      },
    ],
    [
      props.closing,
      {
        animation: `${renderer.renderKeyframe(animateOut, props)} 0.2s ease-out`,
        opacity: 0,
      },
    ],
    [
      props.backdropColor === 'white',
      {
        backgroundColor: `rgba(255,255,255,0.8)`,
      },
    ],
  )(style);
};

export const Backdrop = createComponent<Props>(StyledBackdrop, 'div', ['onClick']);

Backdrop.defaultProps = {
  backdropColor: 'black',
};
