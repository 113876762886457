/** @format */
/* tslint:disable:max-file-line-count */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

import { ButtonInnerIcon, ButtonInnerText, ButtonInnerWrapper } from './icon';

export type BUTTON_TYPES = 'white' | 'green' | 'red' | 'blue' | 'grey';

export type Props = {
  size: 24 | 32 | 36 | 40;
  title?: string;
  type?: BUTTON_TYPES;
  block?: boolean;
  icon?: boolean;
  fullWidth?: boolean;
  flatLeft?: boolean;
  flatRight?: boolean;
  noBorderLeft?: boolean;
  noBorderRight?: boolean;
  equalPadding?: boolean;
  disabled?: boolean;
  tight?: boolean;
  shadow?: boolean;
  fontWeightNormal?: boolean;
  text?: 'left' | 'center' | 'right';

  iconAfter?: React.ReactNode;
  iconBefore?: React.ReactNode;

  onClick?: () => void;
  href?: string;
  className?: string;
  id?: string;

  // This is to apply specific styles for when used in the multiselect dropdown
  // This is a temporary hack until we build the DS dropdown
  multiSelectButton?: boolean;
};

const StyledButtonWrapperComponent = (props: ThemeType & Props): IStyle => {
  const disabled: IStyle = {
    cursor: 'not-allowed',
    backgroundColor: props.theme.colors.material.grey300,
    borderColor: props.theme.colors.material.grey400,
    color: props.theme.colors.material.grey600,
    ':hover': {
      backgroundColor: props.theme.colors.material.grey300,
      borderColor: props.theme.colors.material.grey400,
      color: props.theme.colors.material.grey600,
    },
    ':active': {
      backgroundColor: props.theme.colors.material.grey300,
      borderColor: props.theme.colors.material.grey400,
      color: props.theme.colors.material.grey600,
    },
    ':focus': {
      backgroundColor: props.theme.colors.material.grey300,
      borderColor: props.theme.colors.material.grey400,
      color: props.theme.colors.material.grey600,
    },
  };

  const lineHeight = 14;
  const padding = (props.size - lineHeight) / 2;

  const style: IStyle = {
    whiteSpace: 'nowrap',
    fontFamily: props.theme.fonts.standard,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: '24px',
    paddingLeft: '24px',
    verticalAlign: 'middle',
    borderRight: '3px',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: '3px',
    fontWeight: 600,
    fontSize: '1.4rem',
    textAlign: 'center',
    display: 'inline-block',
    height: `${props.size}px`,
    paddingTop: `${padding - 1}px`,
    paddingBottom: `${padding}px`,
    lineHeight: `${lineHeight}px`,
    outline: 'none',
    userSelect: 'none',
    // Default style
    backgroundColor: props.theme.colors.grey.lightest,
    borderColor: props.theme.colors.material.grey400,
    color: props.theme.colors.material.grey700,
    ':hover': {
      backgroundColor: 'white',
      color: props.theme.colors.material.grey700,
    },
    ':active': {
      backgroundColor: props.theme.colors.grey.lighter,
    },
    [':disabled' as any]: disabled,
  };

  return applyModifiers(
    [
      props.block,
      {
        display: 'block',
      },
    ],
    [
      props.fullWidth,
      {
        display: 'block',
        width: '100%',
      },
    ],
    [
      props.flatLeft,
      {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
      },
    ],
    [
      props.flatRight,
      {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
      },
    ],
    [
      props.noBorderLeft,
      {
        borderLeft: 'none',
      },
    ],
    [
      props.noBorderRight,
      {
        borderRight: 'none',
      },
    ],
    [
      props.equalPadding,
      {
        paddingLeft: `${padding}px`,
        paddingRight: `${padding}px`,
      },
    ],
    [
      props.icon,
      {
        paddingLeft: `${padding}px`,
        paddingRight: `${padding}px`,
        paddingTop: `${padding - 2}px`,
      },
    ],
    // Styles
    [
      props.type === 'white' && !props.disabled,
      {
        backgroundColor: 'white',
        borderColor: '#CED0D3',
        color: props.theme.colors.material.grey700,
        ':hover': {
          backgroundColor: 'white',
          color: props.theme.colors.material.grey700,
        },
        ':active': {
          backgroundColor: '#f9f9f9',
        },
      },
    ],
    [
      props.type === 'green' && !props.disabled,
      {
        backgroundColor: props.theme.colors.material.green500,
        borderColor: '#43A047',
        color: 'white',
        ':hover': {
          backgroundColor: '#5cb860',
          color: 'white',
        },
        ':active': {
          backgroundColor: '#48a64c',
        },
      },
    ],
    [
      props.type === 'blue' && !props.disabled,
      {
        backgroundColor: '#348AC8',
        borderColor: '#1B7BC9',
        color: 'white',
        ':hover': {
          backgroundColor: '#4696cf',
          color: 'white',
        },
        ':active': {
          backgroundColor: '#3183be',
        },
      },
    ],
    [
      props.type === 'red' && !props.disabled,
      {
        backgroundColor: props.theme.colors.material.red600,
        borderColor: '#C62828',
        color: 'white',
        ':hover': {
          backgroundColor: '#e84f4c',
          color: 'white',
        },
        ':active': {
          backgroundColor: '#e42e2a',
        },
      },
    ],
    [
      props.type === 'grey' && !props.disabled,
      {
        backgroundColor: props.theme.colors.material.grey500,
        borderColor: props.theme.colors.material.grey500,
        color: props.theme.colors.text.black,
        ':hover': {
          backgroundColor: '#e0e0e0',
          color: props.theme.colors.text.black,
        },
        ':active': {
          backgroundColor: '#cdcdcd',
        },
      },
    ],
    [
      !!props.tight,
      {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    ],
    [
      props.shadow,
      {
        boxShadow: '0 3px 6px 0 rgba(202,202,202,0.32)',
      },
    ],
    [props.disabled, disabled],
    [props.text === 'left', { textAlign: 'left', paddingLeft: '8px' }],
    [props.text === 'right', { textAlign: 'right', paddingRight: '8px' }],
    [
      props.fontWeightNormal,
      {
        fontWeight: 500,
      },
    ],
    [
      props.size === 32,
      {
        fontSize: '1.2rem',
      },
    ],
    [
      props.multiSelectButton,
      {
        paddingLeft: '16px',
        paddingRight: '24px',
        paddingBottom: '12px',
        paddingTop: '12px',
      },
    ],
  )(style);
};

const ButtonWrapper = createComponent(StyledButtonWrapperComponent, 'button', [
  'onClick',
  'onKeyDown',
  'title',
  'disabled',
  'className',
  'id',
  'role',
  'aria-label',
  'aria-expanded',
  'aria-haspopup',
]);

const ButtonWrapperLink = createComponent(StyledButtonWrapperComponent, 'a', [
  'onClick',
  'title',
  'disabled',
  'className',
  'href',
  'id',
]);

export const Button: React.FunctionComponent<Props> = props => {
  const iconAfter = !!props.iconAfter ? (
    <ButtonInnerIcon beforeIcon={false}>{props.iconAfter}</ButtonInnerIcon>
  ) : null;

  const iconBefore = !!props.iconBefore ? (
    <ButtonInnerIcon beforeIcon>{props.iconAfter}</ButtonInnerIcon>
  ) : null;

  const Wrapper = props.href ? ButtonWrapperLink : ButtonWrapper;

  return (
    <Wrapper {...props}>
      <ButtonInnerWrapper iconAfter={!!props.iconAfter} iconBefore={!!props.iconBefore}>
        {iconAfter}
        <ButtonInnerText>{props.children}</ButtonInnerText>
        {iconBefore}
      </ButtonInnerWrapper>
    </Wrapper>
  );
};

Button.defaultProps = {
  size: 32,
  block: false,
  disabled: false,
  icon: false,
  fullWidth: false,
  flatLeft: false,
  flatRight: false,
  noBorderLeft: false,
  noBorderRight: false,
  equalPadding: false,
  href: null,
};

export default Button;
