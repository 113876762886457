/** @format **/
/* tslint:disable:max-file-line-count */

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { cloneDeep, get, has, isFinite, isNil, keyBy, map, omit, some, values } from 'lodash';
import { produce } from 'immer';

import { SORTING_DIRECTION } from 'interfaces/sorting';

import * as Actions from '../actions';
import { ResourceId, ResourceObject } from '../models';
import { ResourceContainer, ResourcesState, StoredResource } from '../state';

export const defaultState: ResourcesState = Object.freeze({});

export const defaultContainer: ResourceContainer = Object.freeze({
  isCreating: false,
  isFetching: false,
  isPerformingAction: {},
  errors: null,
  resources: Object.freeze({}),
  pagination: Object.freeze({
    enabled: false,
    batchSize: 10,
    prevCursor: undefined,
    nextCursor: undefined,
    totalRecords: undefined,
  }),
  sorting: Object.freeze({
    enabled: false,
    direction: undefined,
    attributeName: undefined,
  }),
  filtering: Object.freeze({
    enabled: false,
    filters: [],
  }),
});
const getDefaultContainer = (): ResourceContainer => cloneDeep(defaultContainer);

export const defaultResource: StoredResource = Object.freeze({
  isDeleting: false,
  isFetching: false,
  isPatching: false,
  isCreating: false,
  lastFetchedAt: null,
  object: null,
  errors: null,
});

function getContainer(state: ResourcesState, { resourceName }: { resourceName: string }) {
  return state[resourceName];
}

function getResource(
  state: ResourcesState,
  { resourceName, resourceId }: { resourceName: string; resourceId: ResourceId },
): StoredResource {
  return get(getContainer(state, { resourceName }), `resources[${resourceId}]`, null);
}

function setResource(
  state: ResourcesState,
  resource: StoredResource,
  { resourceName, resourceId }: { resourceName: string; resourceId: ResourceId },
) {
  state[resourceName].resources[resourceId] = resource;
}

function deleteResource(
  state: ResourcesState,
  { resourceName, resourceId }: { resourceName: string; resourceId: ResourceId },
) {
  state[resourceName].resources = omit(state[resourceName].resources, [resourceId]);
}

function populateResources(
  container: ResourceContainer,
  response: ResourceObject[],
  removeExistingResources: boolean,
) {
  if (some(response, resource => !has(resource, 'id'))) {
    throw new Error(`Property "id" is required for all resources sent back from the server`);
  }

  const existingResources = values(container.resources);
  const fullResponseResources = map(response, r => ({
    ...defaultResource,
    object: r,
    lastFetchedAt: new Date(),
  }));

  const mergedResources = [
    ...(removeExistingResources ? [] : existingResources),
    ...fullResponseResources,
  ];

  container.resources = keyBy(mergedResources, r => r.object.id) as any;
}

export const ResourcesReducer = reducerWithInitialState(defaultState)
  .case(Actions.fetchingResourceStarted, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const resource = getResource(draft, payload) || { ...defaultResource };
      resource.isFetching = true;

      setResource(draft, resource, payload);
    }),
  )
  .case(Actions.fetchingResourceSucceeded, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const resource = getResource(draft, payload);

      resource.lastFetchedAt = new Date();
      resource.object = payload.response[0];
      resource.isFetching = false;
      resource.errors = null;
    }),
  )
  .case(Actions.fetchingResourceFailed, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const resource = getResource(draft, payload);

      resource.lastFetchedAt = new Date();
      resource.errors = payload.errors;
      resource.isFetching = false;
    }),
  )
  .case(Actions.postingResourceStarted, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.isCreating = true;
    }),
  )
  .case(Actions.postingResourceSucceeded, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.isCreating = false;
      container.errors = null;

      populateResources(container, payload.response, false);
    }),
  )
  .case(Actions.postingResourceFailed, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.isCreating = false;
      container.errors = payload.errors;
    }),
  )
  .case(Actions.resourceActionStarted, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.isPerformingAction[payload.type] = true;
    }),
  )
  .case(Actions.resourceActionSucceeded, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.isPerformingAction[payload.type] = false;
      container.errors = null;
    }),
  )
  .case(Actions.resourceActionFailed, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.isPerformingAction[payload.type] = false;
      container.errors = payload.errors;
    }),
  )
  .case(Actions.patchingResourceStarted, (state, payload) => {
    if (isNil(getResource(state, payload))) {
      throw new Error(
        'The resource you are attempting to patch has not been fetched yet. Please fetch it first',
      );
    }

    return produce<ResourcesState, ResourcesState>(state, draft => {
      const existingResource = getResource(draft, payload);

      existingResource.isPatching = true;
    });
  })
  .case(Actions.patchingResourceSucceeded, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const existingResource = getResource(draft, payload);

      existingResource.isPatching = false;
      existingResource.errors = null;
      existingResource.object = {
        ...existingResource.object,
        ...payload.response[0],
      };
    }),
  )
  .case(Actions.patchingResourceFailed, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const existingResource = getResource(draft, payload);

      existingResource.errors = payload.errors;
      existingResource.isPatching = false;
    }),
  )
  .case(Actions.deletingResourceStarted, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const existingResource = getResource(draft, payload);

      if (!isNil(existingResource)) {
        existingResource.isDeleting = true;
      } else {
        const container = getContainer(draft, payload);
        container.resources[payload.resourceId] = cloneDeep(defaultResource);

        const createdResource = getResource(draft, payload);
        createdResource.isDeleting = true;
      }
    }),
  )
  .case(Actions.deletingResourceSucceeded, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      deleteResource(draft, payload);
    }),
  )
  .case(Actions.deletingResourceFailed, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const existingResources = getResource(draft, payload);

      existingResources.isDeleting = false;
      existingResources.errors = payload.errors;
    }),
  )
  .case(Actions.fetchingListResourceStarted, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.isFetching = true;
    }),
  )
  .case(Actions.fetchingListResourceFailed, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.errors = payload.errors;
      container.isFetching = false;
    }),
  )
  .case(Actions.fetchingListResourceSucceeded, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);
      const response = get(payload, 'response', []);

      container.isFetching = false;
      container.errors = null;

      populateResources(container, response, payload.replaceResources);
    }),
  )
  .case(Actions.initializeResource, (state, { resourceName, paginated, sortable, filterable }) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      if (isNil(draft[resourceName])) {
        draft[resourceName] = getDefaultContainer();
        draft[resourceName].pagination.enabled = paginated;
        draft[resourceName].sorting.enabled = sortable;
        draft[resourceName].filtering.enabled = filterable;
      }
    }),
  )
  .case(Actions.updateSorting, (state, { attribute, sortDirection, ...payload }) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.sorting.attributeName = attribute;
      container.sorting.direction = sortDirection || SORTING_DIRECTION.Descending;
    }),
  )
  .case(Actions.updatePaginationBatchSize, (state, { batchSize, ...payload }) => {
    if (isNil(batchSize) || !isFinite(batchSize)) {
      throw new Error(`batchSize is nil or a non-finite number! ${batchSize}`);
    }

    return produce<ResourcesState, ResourcesState>(state, draft => {
      getContainer(draft, payload).pagination.batchSize = batchSize;
    });
  })
  .case(Actions.updatePagination, (state, { nextCursor, prevCursor, totalRecords, ...payload }) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.pagination.nextCursor = nextCursor;
      container.pagination.prevCursor = prevCursor;
      container.pagination.totalRecords = totalRecords;
    }),
  )
  .case(Actions.clearResources, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.resources = {};
    }),
  )
  .case(Actions.resetPagination, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      if (payload.resetBatchSize) {
        container.pagination.batchSize = 10;
      }

      container.pagination.nextCursor = undefined;
      container.pagination.prevCursor = undefined;
    }),
  )
  .case(Actions.updateFilters, (state, { filters, ...payload }) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);
      container.filtering.filters = filters;
    }),
  )
  .case(Actions.storeResource, (state, payload) =>
    produce<ResourcesState, ResourcesState>(state, draft => {
      const container = getContainer(draft, payload);

      container.resources[payload.object.id] = { ...defaultResource, object: payload.object };
    }),
  );
