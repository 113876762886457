/** @format **/

import React, { useEffect } from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

import { getPathname } from 'selectors/router';

import { toIdentifier } from 'utils/linking';

import { ThemeType } from 'app/theme';
import { getCurrentApplication } from 'app/selectors/application';

import { ErrorBoundary } from 'components/errorBoundary';

import { SnowplowLink } from 'components/snowplow';

import * as WhatsNewActions from 'modules/whatsnew/actions';

import { ProductPagesState } from 'sections/productpages';

import * as Selectors from '../selectors';
import { SlopeHover } from '../hoc/slopeHover';

type StateProps = {
  unreadCount: number;
  applicationIdentifier: string;
  pathname: string;
};

type DispatchProps = {
  fetchWhatsNewItems: () => void;
};

type SuppliedProps = {};
type Props = StateProps & DispatchProps & SuppliedProps;

const StyledUnreadCount = (props: ThemeType): IStyle => ({
  backgroundColor: props.theme.colors.green.success,
  padding: '0.4rem 0.5rem',
  marginRight: '1.2rem',
  borderRadius: '0.3rem',
  color: '#fff',
  fontSize: '1rem',
  fontWeight: 700,
});
const UnreadCount = createComponent(StyledUnreadCount, 'span');

const StyledNavItem = (): IStyle => ({ position: 'relative' });
const NavItem = createComponent(StyledNavItem, 'li', ['onMouseOver', 'onMouseLeave']);

export const UnconnectedMenuItem: React.FunctionComponent<Props> = ({
  applicationIdentifier,
  fetchWhatsNewItems,
  unreadCount,
  pathname,
}) => {
  useEffect(() => {
    fetchWhatsNewItems();
  }, []);

  if (isNil(applicationIdentifier)) {
    return null;
  }

  const NavItemLinkClass =
    pathname === '/whats-new' ? 'sidebar-button is-active' : 'sidebar-button';

  return (
    <ErrorBoundary>
      <SlopeHover>
        {(onHover, onLeave) => (
          <NavItem onMouseOver={onHover} onMouseLeave={onLeave}>
            <SnowplowLink interactionId={'sp-app-chrome-sidebar-clickWhatsNewLink'}>
              <a
                href={`/whats-new?applicationIdentifier=${applicationIdentifier}`}
                className={NavItemLinkClass}
              >
                <span className="sidebar-button-content">
                  <svg className="sidebar-icon">
                    <use xlinkHref="/Content/Images/sidebar/sidebar-icons.svg#sidebar-icon--blue-whats-new" />
                  </svg>
                  <span className="sidebar-label">What's New</span>
                </span>
                {unreadCount > 0 && <UnreadCount>{unreadCount}</UnreadCount>}
              </a>
            </SnowplowLink>
          </NavItem>
        )}
      </SlopeHover>
    </ErrorBoundary>
  );
};

const ConnectedMenuItem = connect<StateProps, DispatchProps, SuppliedProps, ProductPagesState>(
  state => {
    const currentApplication = getCurrentApplication(state);

    return {
      unreadCount: Selectors.unreadItemCount(state),
      applicationIdentifier: !isNil(currentApplication)
        ? toIdentifier(currentApplication.id)
        : null,
      pathname: getPathname(state),
    };
  },
  dispatch => ({
    fetchWhatsNewItems: () => dispatch(WhatsNewActions.fetchWhatsNewItems()),
  }),
)(UnconnectedMenuItem);

export { ConnectedMenuItem as MenuItem };
