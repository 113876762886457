/**
 * @prettier
 */

import React from 'react';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';

type AlignTextProps = {
  textAlign: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
};
export const AlignText = createComponent((props: AlignTextProps): IStyle => ({ ...props }));

AlignText.defaultProps = {
  textAlign: 'left',
};
