/**
 * @prettier
 */

import { createSelector } from 'reselect';
import moment, { Moment } from 'moment';
import { get } from 'lodash';

import { AppState } from 'interfaces/appState';
import {
  DateTimeFilterPredefinedRange,
  DEFAULT_DATE_TIME_FILTER_RANGE,
  ONE_DAY,
  SEVEN_DAYS,
  THIRTY_DAYS,
} from 'constants/dateTimeFilter';

const locationSelector = (state: AppState) => state.location;
const screensSelector = (state: AppState) => state.screens;

export function selectorForQuery<T = string>(query: string, fallback: string = undefined) {
  return createSelector(
    locationSelector,
    location => get(location, `query.${query}`, fallback) as T,
  );
}

export const statusForCurrentRoute = selectorForQuery('status', 'active');

export const getPathname = createSelector(
  locationSelector,
  location => location.pathname,
);

export const getLocation = createSelector(
  locationSelector,
  location => location.type,
);

export const getPayload = createSelector(
  locationSelector,
  location => location.payload as any,
);

export const getQuery = createSelector(
  locationSelector,
  location => location.query,
);

export const getCurrentRouteData = createSelector(
  getLocation,
  getPayload,
  getQuery,
  (location, payload, query) => ({ location, payload, query }),
);

export const getSearch = createSelector(
  locationSelector,
  location => location.search,
);

export const getPrev = createSelector(
  locationSelector,
  location => location.prev,
);

export const getKind = createSelector(
  locationSelector,
  location => location.kind,
);

export const getPrevLocation = createSelector(
  getPrev,
  prev => prev.type,
);

export const getPrevPayload = createSelector(
  getPrev,
  prev => prev.payload,
);

export const getPrevPathname = createSelector(
  getPrev,
  prev => prev.pathname,
);

const getRangeQuery = selectorForQuery('range');
const getDateFromQuery = selectorForQuery('dateFrom');
const getDateToQuery = selectorForQuery('dateTo');

export const getRange = createSelector(
  getRangeQuery,
  (range: DateTimeFilterPredefinedRange) => range,
);

export type DateTimeFilterProps = {
  range: DateTimeFilterPredefinedRange;
  dateFrom: string;
  dateTo: string;
};

export const getDateTimeRaw = createSelector(
  getRangeQuery,
  getDateFromQuery,
  getDateToQuery,
  (range: DateTimeFilterPredefinedRange, dateFrom, dateTo): DateTimeFilterProps => ({
    range,
    dateFrom,
    dateTo,
  }),
);

export const isCustomDateTime = createSelector(
  getRangeQuery,
  getDateFromQuery,
  getDateToQuery,
  (range: DateTimeFilterPredefinedRange, dateFrom, dateTo): boolean => {
    if (range) {
      return false;
    } else {
      return !!(dateFrom || dateTo);
    }
  },
);

export const getDateTimeFilter = (state: AppState) => {
  const { range: rawRange, ...dateTimeQueries } = getDateTimeRaw(state);

  let range: DateTimeFilterPredefinedRange;

  switch (rawRange) {
    case ONE_DAY:
    case SEVEN_DAYS:
    case THIRTY_DAYS:
      range = rawRange;
      break;
    default:
      range = DEFAULT_DATE_TIME_FILTER_RANGE;
      break;
  }

  let dateTo: Moment;
  let dateFrom: Moment;

  if (!dateTimeQueries.dateFrom && !dateTimeQueries.dateTo) {
    dateTo = moment.utc(new Date());
    if (range === ONE_DAY) {
      dateFrom = dateTo.clone().subtract(1, 'days');
    } else if (range === SEVEN_DAYS) {
      dateFrom = dateTo.clone().subtract(7, 'days');
    } else if (range === THIRTY_DAYS) {
      dateFrom = dateTo.clone().subtract(30, 'days');
    }
  } else {
    dateTo = moment(dateTimeQueries.dateTo)
      .clone()
      .utc();
    dateFrom = moment(dateTimeQueries.dateFrom)
      .clone()
      .utc();
  }

  return { range, dateFrom, dateTo };
};

export const getDateTimeFilterFormatted = createSelector(
  getDateTimeFilter,
  ({ dateFrom, dateTo, range }) => ({
    dateFrom: dateFrom.format(),
    dateTo: dateTo.format(),
    range,
  }),
);

export const getActiveRouteType = createSelector(
  screensSelector,
  screen => screen.type,
);
export const getActiveScreen = createSelector(
  screensSelector,
  screens => screens.component,
);
export const getActiveLayout = createSelector(
  screensSelector,
  screens => screens.layout,
);
