/** @format **/

import { put, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { NavigationAction } from 'redux-first-router';
import { get, isNil } from 'lodash';
import moment from 'moment';

import { takeLatestNavAction, anyRoutingAction } from 'utils/sagas';

import { unpackRange, DateRangeParam } from '../models';
import * as Actions from '../actions';

import { updateDateRangeFromMarionette } from './marionetteDateChange';

export function* updateDateRangeFromRouteChange(action: NavigationAction): SagaIterator {
  let dateRange = get(action, 'meta.query.range', null) as DateRangeParam;
  const dateFrom = get(action, 'meta.query.dateFrom', null);
  const dateTo = get(action, 'meta.query.dateTo', null);

  if (isNil(dateRange) && isNil(dateFrom)) {
    dateRange = '7d';
  }

  if (!isNil(dateRange)) {
    const { from, to } = unpackRange(dateRange);

    yield put(
      Actions.updateTimeRange({
        from,
        to,
      }),
    );
  } else {
    yield put(
      Actions.updateTimeRange({
        from: moment(dateFrom),
        to: moment(dateTo),
      }),
    );
  }
}

export function* TimeSaga(): SagaIterator {
  yield takeLatestNavAction(anyRoutingAction, updateDateRangeFromRouteChange);
  yield fork(updateDateRangeFromMarionette);
}
