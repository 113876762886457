/** @format **/
import * as Models from './models';
import * as Links from 'utils/links/planSettings';

export type LinkDefinitionsParameters = {
  planIdentifier: string;
  showApplicationSection: boolean;
  showInvoicesSection: boolean;
  showConsumersSection: boolean;
};

export const PlanSettingsTabsDefinition = ({
  planIdentifier,
  showApplicationSection,
  showConsumersSection,
  showInvoicesSection,
}: LinkDefinitionsParameters): Models.TabLink[] => {
  const {
    summary,
    applications,
    consumers,
    usage,
    invoices,
    privacy,
    teams,
  } = Links.getPlanSettingsUrls(planIdentifier);

  return [
    {
      label: 'Summary',
      to: summary,
      isActive: Links.onSummaryPage(),
    },
    {
      label: 'Usage',
      to: usage,
      isActive: Links.onUsagePage(),
      hide: !showApplicationSection,
    },
    {
      label: 'Applications',
      to: applications,
      isActive: Links.onApplicationsPage(),
      hide: !showApplicationSection,
    },
    {
      label: 'Teams',
      to: teams,
      isActive: Links.onTeamsPage(),
    },
    {
      label: 'Invoices',
      to: invoices,
      isActive: Links.onInvoicesPage(),
      hide: !showInvoicesSection,
    },
    {
      label: 'Consumers',
      to: consumers,
      isActive: Links.onConsumersPage(),
      hide: !showConsumersSection,
    },
    {
      label: 'Privacy and Compliance',
      to: privacy,
      isActive: Links.onPrivacyPage(),
    },
  ];
};
