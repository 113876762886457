/** @format **/

import React, { useState, useCallback, useRef } from 'react';
import { isNil } from 'lodash';

type UseHoverArguments = {
  onMouseEnter?: React.MouseEventHandler<HTMLElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
  delayUnhover?: number;
};
export function useHover(args: UseHoverArguments = {}) {
  const [hovered, setHovered] = useState(false);
  const timerRef = useRef<number>();

  const onMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setHovered(true);

      if (!isNil(timerRef.current)) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }

      const _ignored = args.onMouseEnter && args.onMouseEnter(e);
    },
    [args.onMouseEnter, hovered],
  );
  const onMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!isNil(args.delayUnhover)) {
        timerRef.current = window.setTimeout(() => setHovered(false), args.delayUnhover);
      } else {
        setHovered(false);
      }

      const _ignored = args.onMouseLeave && args.onMouseLeave(e);
    },
    [args.onMouseLeave, hovered],
  );

  return {
    hovered,
    onMouseEnter,
    onMouseLeave,
  };
}
