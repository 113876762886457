/**
 * @prettier
 */
import * as React from 'react';
import { HEADING_CLASSES, getTextAlignmentClass } from 'components/text';

import { Container, FeatureList, FeatureListItem, Image } from './styles';
import { Padding } from 'components/layout';
import { EmphasisButton } from 'components/ds/button';
import { withHover, WithHoverSuppliedProps } from 'components/hoc/withHover';

type BaseProps = {
  title: string;
  features: string[];
  comingSoon: boolean;
  createUrl: string;
  greyscaleGraphicUrl: string;
  colorGraphicUrl: string;
};

type Props = BaseProps & WithHoverSuppliedProps;

const DashboardSelectionBase: React.FunctionComponent<Props> = props => {
  const imageUrl = `/Content/Images/illustrations/dashboards/create/${
    props.hovered ? props.colorGraphicUrl : props.greyscaleGraphicUrl
  }`;

  return (
    <Container
      href={!props.comingSoon ? props.createUrl : '#'}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      hovered={props.hovered}
    >
      <Image src={imageUrl} hovered={props.hovered} />
      <Padding top="24" bottom="24" left="24" right="24">
        <Padding bottom="20">
          <h3 className={`${HEADING_CLASSES.THREE} ${getTextAlignmentClass('center')}`}>
            {props.title}
          </h3>
        </Padding>
        <FeatureList>
          {props.features.map((f, i) => (
            <FeatureListItem key={i}>{f}</FeatureListItem>
          ))}
        </FeatureList>
        <EmphasisButton block disabled={props.comingSoon}>
          {!props.comingSoon && `Create`}
          {props.comingSoon && `Coming soon`}
        </EmphasisButton>
      </Padding>
    </Container>
  );
};

export const DashboardSelection = withHover<BaseProps, Props>(DashboardSelectionBase);
