/**
 * @prettier
 */

import createActionFactory from 'typescript-fsa';
import { NetworkWhatsNewItem, WhatsNewCategory, WhatsNewBanner } from './models';

const createAction = createActionFactory('whats-new');

export const updateLastViewedDate = createAction<Date>('UPDATE_LAST_VIEWED_DATE');

export const forceFetchWhatsNewItems = createAction('FORCE_FETCH_WHATS_NEW_ITEMS');
export const fetchWhatsNewItems = createAction('FETCH_WHATS_NEW_ITEMS');

export const updateWhatsNewItems = createAction<NetworkWhatsNewItem[]>('UPDATE_WHATS_NEW_ITEMS');

export const whatsNewItemFetchingStarted = createAction('WHATS_NEW_ITEM_FETCHING_STARTED');
export const whatsNewItemFetchingSucceeded = createAction<NetworkWhatsNewItem[]>(
  'WHATS_NEW_ITEM_FETCHING_SUCCEEDED',
);
export const whatsNewItemFetchingFailed = createAction<Error>('WHATS_NEW_ITEM_FETCHING_FAILED');

export type ToggleCategoryFilterPayload = {
  category: WhatsNewCategory;
  toggled: boolean;
};
export const toggleCategoryFilter = createAction<ToggleCategoryFilterPayload>(
  'TOGGLE_CATEGORY_FILTER',
);

export const addTagToFilters = createAction<string>('ADD_TAG_TO_FILTERS');
export const removeTagFromFilters = createAction<string>('REMOVE_TAG_FROM_FILTERS');

export const fetchWhatsNewBanner = createAction('FETCH_WHATS_NEW_BANNER');

export const updateWhatsNewBanner = createAction<NetworkWhatsNewItem[]>('UPDATE_WHATS_NEW_BANNER');

export const whatsNewBannerFetchingStarted = createAction('WHATS_NEW_BANNER_FETCHING_STARTED');
export const whatsNewBannerFetchingSucceeded = createAction<WhatsNewBanner>(
  'WHATS_NEW_BANNER_FETCHING_SUCCEEDED',
);
export const whatsNewBannerFetchingFailed = createAction<Error>('WHATS_NEW_BANNER_FETCHING_FAILED');
