/**
 * @prettier
 */
import { put, call, select, all } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { map, groupBy } from 'lodash';

import { DashboardState } from 'sections/dashboard';
import { API_STATUS, STATUS, RESOLVE_IN_VERSION } from 'constants/status';
import { statusToApiEnum } from 'utils/status';
import { postToApp, postToAPI } from 'utils/fetching';
import { apiKey } from 'app/selectors/application';
import { getRequestVerificationTokenObject } from 'app/selectors/configuration';

import { Actions as NotificationActions, Models as NotificationModels } from 'app/notifications';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import { IgnoredType } from "modules/dashboard/models";

export function* deleteErrorGroups(action: Action<string>) {
  const state: DashboardState = yield select();
  const tileId = action.payload;

  try {
    const selectedErrors = Selectors.getSelectedErrorGroups(state)[tileId];
    const errorsForApps = groupBy(selectedErrors, errorGroup =>
      errorGroup.applicationId.toString(36),
    );

    yield all(
      map(errorsForApps, (errors, applicationId) => {
        return call(
          postToApp,
          `crashreporting/${applicationId}/errors/delete`,
          apiKey(state),
          {
            errorIds: errors.map(e => e.errorGroupId),
            ...getRequestVerificationTokenObject(state),
          },
          true,
        );
      }),
    );

    const payload = NotificationActions.createPayload(
      NotificationModels.TYPES.SUCCESS,
      'Errors flagged for deletion',
    );
    yield put(NotificationActions.show(payload));
    yield put(Actions.deselectAllErrorGroups(tileId));
    yield put(Actions.fetchTileData({ instanceId: tileId }));
  } catch (e) {
    const payload = NotificationActions.createPayload(
      NotificationModels.TYPES.ERROR,
      'Error deletion failed. Please try again or if the problem persists please get in touch.',
    );
    yield put(NotificationActions.show(payload));
  }
}

type PostErrorStatusToApi = {
  errorId: number;
  errorStatus: API_STATUS;
  drop?: boolean;
  version?: string;
  ignoredType?: IgnoredType;
  ignoredValue?: string;
};

function getAdditionalUrlForStatus(status: string): string {
  switch (status) {
    case STATUS.PERM_IGNORED:
      return `errors/permignore`;
    case STATUS.IGNORED:
      return `errors/ignoredfor`;
    case RESOLVE_IN_VERSION:
      return `errors/resolvedversion`;
  }
  return null;
}

export function* setErrorGroupStatus(action: Action<Actions.SetErrorGroupStatusPayload>) {
  const state: DashboardState = yield select();
  const { tileId, status, drop, version, ignoredType, ignoredValue } = action.payload;
  const errorStatus = statusToApiEnum(status);
  const key = apiKey(state);

  try {
    const errorStatusPayload = Selectors.getSelectedErrorGroups(state)[tileId].map(errorGroup => ({
      errorId: errorGroup.errorGroupId,
      errorStatus,
      drop,
      version,
      ignoredType,
      ignoredValue
    })) as PostErrorStatusToApi[];

    const additionalCallUrl = getAdditionalUrlForStatus(status);

    yield all([
      call(postToAPI, 'errors/status', key, errorStatusPayload),
      additionalCallUrl ? call(postToAPI, additionalCallUrl, key, errorStatusPayload) : null,
    ]);

    const payload = NotificationActions.createPayload(
      NotificationModels.TYPES.SUCCESS,
      'Status change successful',
    );
    yield put(NotificationActions.show(payload));
    yield put(Actions.deselectAllErrorGroups(tileId));
    yield put(Actions.fetchTileData({ instanceId: tileId }));
  } catch (e) {
    const payload = NotificationActions.createPayload(
      NotificationModels.TYPES.ERROR,
      'Status change failed. Please try again or if the problem persists please get in touch.',
    );
    yield put(NotificationActions.show(payload));
  }
}