/**
 * @prettier
 */

import * as React from 'react';
import classnames from 'classnames';

type Props = {
  label: string;
  block?: boolean;
  textTransform?: 'all-caps' | 'capitalize' | null;
  fit?: boolean;
  dock?: 'left' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'top';
  fixedWidth?: boolean;
  width200?: boolean;
  hide?: boolean;
  show?: boolean;
  id?: string;
};

export const Tooltip: React.FunctionComponent<Props> = props => {
  if (!props.show) {
    return <>{props.children}</>;
  }

  const className = classnames('simple-tooltip', {
    'simple-tooltip--block': props.block,
    'simple-tooltip--all-caps': props.textTransform === 'all-caps',
    'simple-tooltip--capitalize': props.textTransform === 'capitalize',
    'simple-tooltip--fit': props.fit,
    'simple-tooltip--left': props.dock === 'left',
    'simple-tooltip--right': props.dock === 'right',
    'simple-tooltip--bottom-right': props.dock === 'bottom-right',
    'simple-tooltip--bottom-left': props.dock === 'bottom-left',
    'simple-tooltip--top': props.dock === 'top',
    'simple-tooltip--fixed-width': props.fixedWidth,
    'simple-tooltip--width-200': props.width200,
  });

  return (
    <div id={props.id} className={props.hide ? 'inline-block' : className} aria-label={props.label}>
      {props.children}
    </div>
  );
};

Tooltip.defaultProps = {
  block: false,
  textTransform: null,
  fit: false,
  dock: 'bottom',
  hide: false,
  show: true,
};

export default Tooltip;
