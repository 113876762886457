/** @format **/

import * as React from 'react';
import { isNil, Dictionary } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  getActivePlanForSwitcher,
  getApplicationsGroupedByPlanId,
  getDashboardsGroupedByPlanId,
  isCurrentlyViewingAnApplication,
  isDashboardPaywallActive,
} from 'app/selectors/chrome';
import { getPlans, isFeatureGated, crashOnTrial } from 'app/selectors/configuration';
import { Fade } from 'components/animated/fade';
import { getCurrentApplication } from 'app/selectors/application';

import { Plan } from 'interfaces/plan';
import { size } from 'utils/gravatar';
import { AppState } from 'interfaces/appState';
import { Application } from 'interfaces/application';
import { Dashboard } from 'interfaces/dashboard';

import { Create } from './context/create';

import { PlanSwitcher } from './planSwitcher';
import { Applications } from './applications';
import { Dashboards } from './dashboards';
import { ContextSwitcher } from './context';

export enum SWITCHER_TYPE {
  None,
  Applications,
  Dashboards,
}

export type SwitcherPassedStateProps = {
  title: string;
  type: SWITCHER_TYPE;
  isSwitcherOpen: boolean;
};

export type SwitcherPassedDispatchProps = {
  onClose: () => void;
  openFeatureGatingModal?: () => void;
};

type StateProps = {
  plans: Plan[];
  activePlan: Plan;
  applications: Dictionary<Application[]>;
  dashboards: Dictionary<Dashboard[]>;
  currentApplication: Application;
  isViewingApplication: boolean;
  isPaywallActive: boolean;
  isFeatureGated: boolean;
  isOnTrial: boolean;
};

type Props = SwitcherPassedStateProps & SwitcherPassedDispatchProps & StateProps;

export class UnconnectedSwitcher extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.close = this.close.bind(this);
    this.preventClose = this.preventClose.bind(this);
  }

  stopClose: boolean = true;

  preventClose() {
    this.stopClose = true;
  }

  addListener() {
    // Stops the app switcher from initially hiding due to the click event binding at the start
    this.preventClose();
    window.addEventListener('click', this.close);
  }

  removeListener() {
    window.removeEventListener('click', this.close);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!this.props.isSwitcherOpen && this.props.isSwitcherOpen != prevProps.isSwitcherOpen) {
      this.removeListener();
    }

    if (this.props.isSwitcherOpen && this.props.isSwitcherOpen != prevProps.isSwitcherOpen) {
      this.addListener();
    }
  }

  close() {
    if (!this.stopClose) {
      this.props.onClose();
    }
    this.stopClose = false;
  }

  render() {
    const planClasses = classnames('plan-switcher', {
      'plan-switcher--single-plan': this.props.plans.length <= 1,
    });
    const isApplicationSwitcher = this.props.type === SWITCHER_TYPE.Applications;
    const showApplicationTitle = !isNil(this.props.currentApplication) && isApplicationSwitcher;

    return (
      <Fade visible={this.props.isSwitcherOpen} duration={200} mountOnEnter unmountOnExit fadeOut>
        <div className={planClasses} onClick={this.preventClose}>
          <section className="plan-switcher__plans">
            <PlanSwitcher plans={this.props.plans} />
          </section>

          {showApplicationTitle && (
            <section className="current-application-mobile">
              <div className="current-application-mobile__content">
                <img
                  src={size(this.props.currentApplication.avatarUrl, 24)}
                  alt={`Avatar for the ${this.props.currentApplication.name} application`}
                  className="current-application-mobile-image"
                />
                <span className="current-application-mobile-name">
                  {this.props.currentApplication.name}
                </span>
              </div>
            </section>
          )}

          <section className="plan-switcher__plan-contents">
            <ContextSwitcher
              data={isApplicationSwitcher ? this.props.applications : this.props.dashboards}
              activePlan={this.props.activePlan}
              title={this.props.title}
              createComponent={<Create type={this.props.type} />}
              paywallActive={this.props.isPaywallActive}
            >
              {(filteredData: any[]) => {
                return isApplicationSwitcher ? (
                  <Applications apps={filteredData} />
                ) : (
                  <Dashboards
                    dashboards={filteredData}
                    isFeatureGated={this.props.isFeatureGated}
                    isOnTrial={this.props.isOnTrial}
                    openFeatureGatingModal={() => this.props.openFeatureGatingModal()}
                  />
                );
              }}
            </ContextSwitcher>
          </section>
        </div>
      </Fade>
    );
  }
}

export const ConnectedSwitcher = connect<StateProps, {}, SwitcherPassedStateProps>(
  (state: AppState, ownProps: SwitcherPassedStateProps) => ({
    plans: getPlans(state),
    activePlan: getActivePlanForSwitcher(state),
    applications: getApplicationsGroupedByPlanId(state),
    currentApplication: getCurrentApplication(state),
    dashboards: getDashboardsGroupedByPlanId(state),
    isViewingApplication: isCurrentlyViewingAnApplication(state),
    isPaywallActive: isDashboardPaywallActive(state, ownProps.type),
    isFeatureGated: isFeatureGated(state),
    isOnTrial: crashOnTrial(state),
  }),
)(UnconnectedSwitcher);
