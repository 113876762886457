/** @format **/

import * as React from 'react';

import { Avatar } from 'components/avatar';
import { Icon } from 'components/icon';

import { size } from 'utils/gravatar';

import { ContainerChildProps } from '../containers/userButton';

const Button: React.FunctionComponent<ContainerChildProps> = props => {
  let button;

  if (props.isImpersonated) {
    const title = 'Impersonated by ' + props.impersonatedBy;

    button = (
      <span>
        <strong>imp </strong>
        <strong title={title}>{props.username}</strong>
      </span>
    );
  } else {
    button = <strong>{props.username}</strong>;
  }

  return (
    <div className="user-button">
      <Avatar url={size(props.avatarurl, 24)} alt="Avatar" />
      <div className="user-button__username">{button}</div>
      <div className="user-button__arrow">
        <Icon type="dropdown" size={16} />
      </div>
    </div>
  );
};

export default Button;