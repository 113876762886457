import createActionFactory from 'typescript-fsa';

import { TileSettings, TileDefinition } from '../models';

const createAction = createActionFactory('dashboard/tileSetting');

type UpdateSettingPayload<K extends keyof TileSettings> = {
  key: K,
  value: TileSettings[K]
};
export const updateSetting = createAction<UpdateSettingPayload<keyof TileSettings>>('UPDATE_SETTING');
export const updateMultipleSettings = createAction<Partial<TileSettings>>('UPDATE_MULTI_SETTINGS');

export const resetSettings = createAction('RESET_SETTINGS');
export const loadSettings = createAction<TileSettings>('LOAD_SETTINGS');

export const createTile = createAction<TileDefinition>('CREATE_TILE');
export const createTileWithDefaults = createAction<TileDefinition>('CREATE_TILE_WITH_DEFAULTS');

export type UpdateTilePayload = {
  tileInstanceId: string
};
export const updateTile = createAction<UpdateTilePayload>('UPDATE_TILE');
export const setTileId = createAction<string>("SET_SETTINGS_TILE_ID");