/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { trackLinkClick } from '../utils';

type Props = {
  interactionId: string;
};

const StyledGhostElement = ({}): IStyle => ({
  display: 'contents',
});

const GhostElement = createComponentWithProxy(StyledGhostElement, 'span', ['onClick']);

export const SnowplowLink: React.FunctionComponent<Props> = ({ interactionId, children }) => (
  <GhostElement onClick={() => trackLinkClick(interactionId)}>{children}</GhostElement>
);
