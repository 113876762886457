/**
 * @prettier
 */

import { createRoutingAction } from 'utils/routing';
import { Action, ActionCreator, AnyAction } from 'typescript-fsa';
import { assign } from 'lodash';

function wrapActionWithType<P extends object>(
  actionCreator: ActionCreator<P>,
  type: string,
): ActionCreator<P> {
  return assign(
    (payload: P, meta?: { [key: string]: any }) => {
      return actionCreator(assign(payload, { type: type }), meta);
    },
    {
      type: actionCreator.type,
      match: (action: AnyAction): action is Action<P> => action.type === actionCreator.type,
      toString: () => actionCreator.type,
    },
  );
}

export const GoToCallQuery = wrapActionWithType(
  createRoutingAction<{ callId: string }>('GO_TO_DISCOVER_QUERY'),
  'queries',
);
export const GoToCallExternalApi = wrapActionWithType(
  createRoutingAction<{ callId: string }>('GO_TO_DISCOVER_API'),
  'externalapis',
);
export const GoToCallMethod = wrapActionWithType(
  createRoutingAction<{ callId: string }>('GO_TO_DISCOVER_METHOD'),
  'methods',
);
export const GoToCallPage = wrapActionWithType(
  createRoutingAction<{ callId: string }>('GO_TO_DISCOVER_PAGE'),
  'requests',
);

export const GoToTraceForCall = createRoutingAction<{
  type: string;
  callId: string;
  traceId: string;
}>('GO_TO_TRACE_FOR_CALL');
