/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isBoolean, isNil } from 'lodash';

import { AppState } from 'interfaces/appState';

type ComponentType<Props> = React.FunctionComponent<Props> | React.ComponentClass<Props>;

type State = {
  firstTime: boolean;
  isLoading: boolean;
  hasErrored: boolean;
};

export function loader<Props>(
  loading: (props: Props) => boolean,
  error: (props: Props) => boolean,
  onMount?: (props: Props) => void,
): (
  Complete: ComponentType<Props>,
  Loading: ComponentType<Props>,
  Error: ComponentType<Props>,
  SubsequentLoad?: ComponentType<Props>,
) => React.ComponentClass<Props> {
  return (
    Complete: ComponentType<Props>,
    Loading: ComponentType<Props>,
    Error: ComponentType<Props>,
    SubsequentLoad: ComponentType<Props>,
  ) => {
    class StateComp extends React.Component<Props, State> {
      state: State = {
        firstTime: true,
        isLoading: true,
        hasErrored: false,
      };

      componentDidMount() {
        if (onMount) {
          onMount(this.props);
        }
        const isLoading = loading(this.props);
        this.setState({
          firstTime: isLoading,
          isLoading: isLoading,
          hasErrored: error(this.props),
        });
      }

      componentWillReceiveProps(nextProps: Props) {
        const hasErrored = error(nextProps);
        const isLoading = loading(nextProps);

        this.setState({
          firstTime: hasErrored || (this.state.firstTime && isLoading === this.state.isLoading),
          isLoading,
          hasErrored,
        });
      }

      render() {
        if (this.state.hasErrored) {
          return <Error {...this.props} />;
        }

        if (this.state.isLoading) {
          if (this.state.firstTime) {
            return <Loading {...this.props} />;
          }

          if (SubsequentLoad) {
            return <SubsequentLoad {...this.props} />;
          }
        }

        return <Complete {...this.props} />;
      }
    }

    return StateComp;
  };
}
