/** @format **/

type AuthorInfo = {
  title: string;
  avatarUrl: string;
};

type AuthorsMap = {
  [name: string]: AuthorInfo;
};

const defaultAuthor = 'Zheng Li';

export const authorsMap: AuthorsMap = {
  'Zheng Li': {
    title: 'VP of Product',
    avatarUrl: 'https://www.gravatar.com/avatar/9ec5ab526b1f84ccb654f5f2aa92a6b9.png?s=32',
  },
  'Freyja Spaven': {
    title: 'Marketing Specialist',
    avatarUrl: 'https://www.gravatar.com/avatar/efa70f010e323f54f1cf811389b25a3f.png?s=32',
  },
  'Mitchell Duncan': {
    title: 'Software Developer',
    avatarUrl: 'https://www.gravatar.com/avatar/c8c4db2c8fe871704d963df3bd80f132.png?s=32',
  },
  'Jamie Birss': {
    title: 'Marketing Specialist',
    avatarUrl: 'https://www.gravatar.com/avatar/2016294938bba9246e5bd813753c257b?s=32',
  },
  'Laura Marwick': {
    title: 'Marketing Specialist',
    avatarUrl: 'https://www.gravatar.com/avatar/20e0613cdbc676c8c693829bb0a4e07b?s=32',
  },
  'Madison Daysh': {
    title: 'Marketing Specialist',
    avatarUrl: 'https://www.gravatar.com/avatar/ca957f7725c23112d9ea605497868da3?s=32',
  },
  'Nick Harley': {
    title: 'VP of Growth',
    avatarUrl: 'https://www.gravatar.com/avatar/f5d1869f978d56959cb2f5ca0dee02f2?s=32',
  },
  'Nicholas Lane': {
    title: 'Product Manager',
    avatarUrl: 'https://www.gravatar.com/avatar/3f616a068c27136f903967185f969c33?s=32',
  },
  'Jeremy Norman': {
    title: 'CTO',
    avatarUrl: 'https://s.gravatar.com/avatar/b097d0f1bc34137547cd9965123d9d9b?s=32',
  },
  'Helen Anderson': {
    title: 'Product Manager',
    avatarUrl: 'https://s.gravatar.com/avatar/6cd3c627d7c3a4df7b2e3a83dd22b3ef?s=32',
  },
  'Bianca Grizhar': {
    title: 'Product Lead',
    avatarUrl: 'https://s.gravatar.com/avatar/92d9fd1b466942a9e2bdc2fe1d8e1a3a?s=32',
  },
  'Arisha Singh': {
    title: 'Customer Success Manager',
    avatarUrl: 'https://www.gravatar.com/avatar/9ec5ab526b1f84ccb654f5f2aa92a6b9.png?s=32',
  },
  'Ollie Bannister': {
    title: 'Software Engineer',
    avatarUrl: 'https://s.gravatar.com/avatar/3dd76d9d38693b6dbf2a232743647553f246c06ac864f64b6160eaa7c46e5a9c?s=32',
  },
  'John-Daniel Trask': {
    title: 'CEO/Co-Founder',
    avatarUrl: 'https://www.gravatar.com/avatar/bfe3d494878a574d4472e7be6b1fa97c?s=32',
  },
  'Sumitra Manga': {
    title: 'Software Engineer',
    avatarUrl: 'https://s.gravatar.com/avatar/3dd76d9d38693b6dbf2a232743647553f246c06ac864f64b6160eaa7c46e5a9c?s=32',
  },
  'Reilly Oldham': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/55a007e4c5bb2bac8f89c050aaed10bd?s=32',
  },
  'Wren Atlas': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/008855721a6e868135bfc6b21ed26b73?s=32',
  },
  'Magenizr': {
    title: 'Guest post',
    avatarUrl: 'https://www.gravatar.com/avatar/95bdcda92ede7c0f0a120cfc50996527?s=32',
  },
  'Sean Chapman': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/6f50ed19c1315b091b8e184d9cfe14d5?s=32',
  },
  'Jason Fauchelle': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/0d8ff1fdc463addbf37daf3d45f4a99b?s=32',
  },
  'Panos Patros': {
    title: 'VP of Engineering',
    avatarUrl: 'https://www.gravatar.com/avatar/bfe3d494878a574d4472e7be6b1fa97c?s=32',
  },
  'Hamish Taylor': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/bfe3d494878a574d4472e7be6b1fa97c?s=32',
  },
  'Jasen Palmer': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/01316765fd5599e4ef115bc4c554c268?s=32',
  },
  'Phillip Haydon': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/b50b50a448787a6d94082b5864194129?s=32',
  },
  'Vishakh Nair': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/b50b50a448787a6d94082b5864194129?s=32',
  },
  'Jared Scholz': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/34f46313743e3a36d8536e782536adba?s=32',
  },
  'Matt Byers': {
    title: 'Software Engineer',
    avatarUrl: 'https://www.gravatar.com/avatar/9ec5ab526b1f84ccb654f5f2aa92a6b9.png?s=32',
  },

};

export function getAuthorInfo(authorName: string): AuthorInfo {
  if (authorsMap.hasOwnProperty(authorName)) {
    return authorsMap[authorName];
  }

  return authorsMap[defaultAuthor];
}