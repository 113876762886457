/** @format **/

import React from 'react';
import { nth, get, isNil } from 'lodash';

import { PALETTE_TYPES, getGraphColors } from 'app/theme/graphColors';
import * as Colors from 'app/theme/materialDS';

import * as NumberUtils from 'utils/numberFormatting';
import { Helptip } from "components/ds/tooltip";

import { Base, PublicProps } from '../base';
import * as Legend from '../legend';
import * as Models from './models';
import * as Utils from '../utils';
import { Underline } from "components/ds/text";
import { Margin } from "components/layout";

type Piece = {
  min: number;
  max?: number;
  color: string;
  label: string;
};

type LinePoint = {
  coord?: number[];
  xAxis?: number;
  yAxis?: number;
  x?: number;
  y?: number;
  value: string;
};

export { QuantilesMarkLine } from './models';

export type HistogramChartProps = Models.HistogramChartResource & {
  paletteType?: PALETTE_TYPES;
  useLogarithmicScale?: boolean;
  onClick?: (e: Models.EchartEvent) => void;
  isTimeMetric?: boolean;
} & PublicProps;

export const HistogramChart: React.FunctionComponent<HistogramChartProps> = ({
  data,
  intervals,
  totalRequests,
  paletteType,
  quantiles,
  useLogarithmicScale,
  onClick,
  isTimeMetric,
  ...props
}) => {
  /** Some background into the bucketing as this has caused some confusion in the past...
   * Druid, being a powerful real-time analytics system, uses approximate histograms to boost performance. 
   * The returned data may not have evenly spaced buckets, as Druid uses a distribution function (like gamma, T-distribution, etc.) to optimally space the buckets. 
   * This means that it works on the principle of biggest-bang-for-the-buck where data is bucketed to yield the most useful insights instead of being evenly distributed. 
   * 
   * Sometimes the delta between the buckets can be large, and sometimes it can be small. 
   * Problems arise when we try to plot this data on a graph, as the graphing library (echarts) expects evenly spaced buckets.
   * If the delta between the buckets is large, the graph will look like a bar chart, and if the delta is small, the graph will look like a line chart.
   * The smaller the delta, for example 0.0001, the graph will turn the bars in the chart to become 1px wide, which is the minimum.
   * 
   * Trying to mitigate this using barMinWidth can result in broken behaviour as the bars will overlap.
   * **/

  /**
   * data represents a 2d array that contains timing and count data for each bucket.
   */

  const colors = getGraphColors(paletteType, intervals.length);
  const units = isTimeMetric ? 'ms' : '';
  const maxValueModifier = isTimeMetric ? 1 : 0.001;

  const pieces: Piece[] = intervals.map((interval, index) =>
    intervals[index + 1]
      ? {
          min: interval,
          max: intervals[index + 1] - maxValueModifier,
          color: colors[index],
          label: `${interval} - ${intervals[index + 1] - maxValueModifier}${units} `,
        }
      : {
          min: interval,
          color: colors[index],
          label: `${interval}${units}+`,
        },
  );

  const legendItems: Legend.LegendItem[] = pieces.map(piece => ({
    name: piece.label,
    color: piece.color,
  }));

  const maxXAxisDataPoint = data[data.length - 1][0];
  const maxYAxisValue = Math.max(...data.map(d => d[1]));
  const minYAxisValue = Math.min(...data.filter(d => d[1] != 0).map(d => d[1]));
  
  const quantileCountSum = data.reduce((accumulator, currentValue) => accumulator + currentValue[1], 0);
  const hasAggregatedData = quantileCountSum < totalRequests;

  const quantileFlagMargin = 8;
  const quantileFlagHeight = 28;
  const quantilePositionEndDataPoint = get(nth(data, 5), '[0]', get(data, '[0]', 0));

  const quantilesMarkLines: LinePoint[][] = quantiles.map((qnt, idx) => {
    const isOutOfBounds = qnt.xAxisPoint > maxXAxisDataPoint;

    return [
      {
        value: qnt.lineLabelValue,
        xAxis: isOutOfBounds ? maxXAxisDataPoint : qnt.xAxisPoint,
        y: 12 + (quantileFlagHeight + quantileFlagMargin) * idx,
        label: {
          align: quantilePositionEndDataPoint > qnt.xAxisPoint ? 'left' : 'right',
        },
      },
      {
        coord: [
          isOutOfBounds ? maxXAxisDataPoint : qnt.xAxisPoint,
          useLogarithmicScale ? minYAxisValue : 0,
        ],
        value: null,
      },
    ];
  });

  const series = {
    type: 'bar',
    cursor: isNil(onClick) ? 'default' : undefined,
    data: data.map(d => [d[0], d[1] === 0 ? null : d[1]]),
    markLine: !!quantiles
      ? {
          label: {
            padding: 8,
            margin: 10,
            position: 'start',
            color: Colors.GREY.grey50,
            backgroundColor: 'rgba(55,71,79,0.9)',
            borderRadius: 2,
            align: 'right',
            verticalAlign: 'top',
          },
          lineStyle: {
            color: Colors.BLUE_GREY.blueGrey400,
            emphasis: {
              width: 1,
            },
          },
          symbol: 'none',
          data: quantilesMarkLines,
        }
      : null,
  };

  const options = {
    itemStyle: {
      barBorderRadius: [2, 2, 0, 0],
    },
    barCategoryGap: '1',
    xAxis: [
      {
        type: 'value',
        splitNumber: 50,
        max: Math.max(...data.map(x => x[0])),
        min: Math.min(...data.map(x => x[0])),
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: false,
          formatter: Utils.getHistogramLabelFormatter(units),
        },
      },
    ],
    yAxis: useLogarithmicScale
      ? [
          {
            type: 'log',
            logBase: Math.max(Math.round(Math.log(maxYAxisValue)), 2),
            min: minYAxisValue,
            axisLabel: {
              margin: 30,
              formatter: NumberUtils.formatAsHistogramShorthand,
            },
            position: 'left',
          },
        ]
      : [
          {
            type: 'value',
            position: 'left',
            axisLabel: {
              margin: 30,
              formatter: NumberUtils.formatAsShorthand,
            },
          },
        ],
    grid: {
      containLabel: true,
      left: '16px',
      right: '16px',
      ...Utils.legendOptions.grid,
    },
    visualMap: {
      ...Utils.visualMapOptions,
      ...Utils.legendOptions.legend,
      pieces,
    },
    tooltip: {
      ...Utils.tooltipOptions,
      formatter: Utils.histogramTooltip(data, isTimeMetric),
    },
  };

  return (
    <>
      <Base
        {...props}
        onClick={onClick}
        options={options}
        series={series}
        legend
        legendItems={legendItems}
        createEChartsActionForLegendToggle={Legend.createSelectDataRangeAction}
      />
      { hasAggregatedData &&
        <Margin top={8}>
          <Helptip
            positionRight
            label="An aggregation was identified in the chart. This is the total number of individual requests."
            link="https://raygun.com/documentation/product-guides/real-user-monitoring/for-web/page-performance/#virtual-pages"
            linkText="Learn more"
          >
            <strong>Total requests: </strong><Underline>{totalRequests}</Underline>
          </Helptip>
        </Margin>  
      }
    </>
  );
};

HistogramChart.defaultProps = {
  paletteType: PALETTE_TYPES.TrafficLight,
  quantiles: null,
  useLogarithmicScale: false,
  onClick: null,
};