/** @format **/
import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import { User } from 'interfaces/user';
import { getUser } from 'app/selectors/configuration';
import { UserAvatar } from 'components/ds/userAvatar';

type StateProps = {
  user: User;
};

type Props = StateProps;

export const UnconnectedCurrentUserAvatar: React.FunctionComponent<Props> = ({ user }) => (
  <UserAvatar user={user} />
);

const ConnectedCurrentUserAvatar = connect<StateProps, {}, {}, AppState>(state => ({
  user: getUser(state),
}))(UnconnectedCurrentUserAvatar);

export { ConnectedCurrentUserAvatar as CurrentUserAvatar };
