/** @format **/

import { drop, isEmpty, isNil, trim } from 'lodash';

export * from './toNumber';

export function uppercaseFirstCharacter(word: string) {
  return `${word.substr(0, 1).toUpperCase()}${word.substr(1)}`;
}

export function currentWordIndexes(
  value: string,
  searchIndex: number,
): {
  start: number;
  end: number;
} {
  let start = -1;
  let end = searchIndex;

  const letters = value.split('');
  const startSearchAt = searchIndex - 1;

  for (let i = startSearchAt; i >= 0; i--) {
    if (letters[i] === ' ' || letters[i] === '\n') {
      start = startSearchAt - (startSearchAt - i);
      end = startSearchAt;
      break;
    }
  }

  return {
    start: start,
    end: end,
  };
}

export function replaceWord(
  currentValue: string,
  newWord: string,
  cursorIndex: number,
): {
  value: string;
  newIndexStart: number;
  newIndexEnd: number;
} {
  const indexToReplace = currentWordIndexes(currentValue, cursorIndex);
  const newValue = `${currentValue.substr(
    0,
    indexToReplace.start + 1,
  )}${newWord} ${currentValue.substr(indexToReplace.end + 1)}`;

  return {
    value: newValue,
    newIndexStart: indexToReplace.start,
    newIndexEnd: indexToReplace.start + newValue.length,
  };
}

export function findPreviousWord(value: string, index: number) {
  const letters = value.split('');
  const startSearchAt = index - 1;
  let word = '';

  for (let i = startSearchAt; i >= 0; i--) {
    if (letters[i] === ' ' || letters[i] === '\n') {
      break;
    }

    word = letters[i] + word;
  }

  return word;
}

export function ellipseCallsFromStart(value: string, depth: number = 3): string {
  const split = value.split('.');

  if (split.length <= depth) {
    return value;
  }

  return `...${drop(split, split.length - depth).join('.')}`;
}

export function convertCommaSeparatedListToArray(str: string): string[] {
  if (str.indexOf(',') === -1) {
    return [str];
  }

  const arr = str.split(',');

  return arr.filter(a => !isNil(a) && trim(a).length > 0);
}

export function isUnknown(value: string): boolean {
  return value.trim().toLowerCase() === 'unknown';
}

export function pluralize(
  descriptor: string,
  amount: number | string,
  suffix: string = 's',
): string {
  let result = descriptor;

  if (amount !== 1) {
    result += suffix;
  }

  return result;
}

export const isNotNilOrEmpty = (value: any) =>
  !isNil(value) && !isEmpty(value.toString()) && value.toString().trim().length > 0;

export const unescapeCSharpString = (value: string) => {
  return value
    .replace(/\\n/g, '\n')
    .replace(/\\r/g, '\r')
    .replace(/\\t/g, '\t')
    .replace(/\\b/g, '\b')
    .replace(/\\f/g, '\f')
    .replace(/\\'/g, '\'')
    .replace(/\\"/g, '\"')
    .replace(/\\\\/g, '\\')
    .replace(/\\\//g, '/');
};