/**
 * @prettier
 */

import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { CrashReporting } from 'utils/linking';
import { DateInput } from 'utils/types';

import { Flex } from 'components/flex';
import Text from 'components/text/index';
import HorizontalRule from 'components/horizontalRule';
import { Margin, Padding } from 'components/layout/index';
import { Components } from 'modules/time';

import { ErrorGroupListData } from '../../../../actions';
import { DashboardModuleStateAtom } from '../../../../state';
import * as Selectors from '../../../../selectors';
import { ActionBar, CheckboxHeader } from './actionBar';
import { SelectedCellFactory } from './selectedCell';

type ErrorListRowProps = {
  name: string;
  errorGroupId: number;
  count: number;
  userCount: number;
  applicationName: string;
  applicationId: number;
  lastSeen: DateInput;
  checkboxSelector: JSX.Element;
};

const ErrorListRow: React.FunctionComponent<ErrorListRowProps> = ({
  name,
  applicationName,
  applicationId,
  count,
  errorGroupId,
  lastSeen,
  userCount,
  checkboxSelector,
}) => (
  <Flex>
    {checkboxSelector}

    <div className="text-ellipsis">
      <Margin bottom="8" applyToChildren>
        <Text size="large" ellipsis>
          <a href={CrashReporting.errorGroupById(applicationId, errorGroupId)}>{name}</a>
        </Text>
        <div>
          Count:{' '}
          <Text weight="bold" layout="inline">
            {count}
          </Text>{' '}
          | Users:{' '}
          <Text weight="bold" layout="inline">
            {userCount}
          </Text>
        </div>
        <Text size="small" color="grey">
          {applicationName} -{' '}
          <Components.DateFormatter format="time-ago">{lastSeen}</Components.DateFormatter>
        </Text>
      </Margin>
    </div>
  </Flex>
);

type StateProps = {
  hasSelection: boolean;
};

type PassedProps = {
  data: ErrorGroupListData[];
  drilldown?: boolean;
  id?: string;
  appId?: number;
};

type Props = StateProps & PassedProps;

const UnconnectedMobileList: React.FunctionComponent<Props> = props => {
  const Checkbox = SelectedCellFactory(props.id);
  const selectedHeader = (
    <ActionBar id={props.id} drilldown={props.drilldown} appId={props.appId} hasSelectAllOption />
  );

  const noSelectionHeader = (
    <>
      <Margin top="12">
        <Padding left="16" bottom="20">
          <CheckboxHeader id={props.id} drilldown={props.drilldown} appId={props.appId} />
          <span>Message</span>
        </Padding>
      </Margin>

      <HorizontalRule />
    </>
  );
  const rows = props.data.map(d => (
    <>
      <Padding left="16">
        <Flex align="flex-start" justify="flex-start" direction="column">
          <Padding top="20" bottom="20">
            <ErrorListRow
              applicationName={d.ApplicationName}
              applicationId={d.ApplicationId}
              count={d.ErrorCount}
              errorGroupId={d.Id}
              lastSeen={d.LastOccurredOn}
              name={d.Message}
              userCount={d.UserCount}
              checkboxSelector={<Checkbox {...d} />}
            />
          </Padding>
        </Flex>
      </Padding>

      <HorizontalRule />
    </>
  ));

  return (
    <>
      <div>{props.hasSelection ? selectedHeader : noSelectionHeader}</div>

      {rows}
    </>
  );
};

const ConnectedMobileList = connect<StateProps, {}, PassedProps>(
  (state: DashboardModuleStateAtom, ownProps: PassedProps) => ({
    hasSelection: get(Selectors.getSelectedErrorGroups(state), ownProps.id, []).length > 0,
  }),
)(UnconnectedMobileList);

export { ConnectedMobileList as ErrorGroupListMobile };
