/** @format */

import { isNil } from 'lodash';

declare const rg4js: any;

function hasRaygun() {
  return typeof rg4js !== 'undefined' && !isNil(rg4js);
}

export function trackPage() {
  if (hasRaygun()) {
    rg4js('trackEvent', {
      type: 'pageView',
      path: `${window.location.pathname}`,
    });
  }
}

type RaygunCustomData = {
  [key: string]: string;
};

type RaygunErrorPayload = {
  error: Error;
  customData?: RaygunCustomData;
};

export function logError(e: Error, customData: RaygunCustomData = null) {
  if (hasRaygun()) {
    const payload: RaygunErrorPayload = {
      error: e,
    };

    if (!isNil(customData)) {
      payload.customData = customData;
    }

    rg4js('send', payload);
  }
}

export function trackCustomTiming(name: string, duration: number) {
  if (hasRaygun()) {
    rg4js('trackEvent', {
      type: 'customTiming',
      name: `${name}`,
      duration: duration,
    });
  }
}

export type BreadcrumbLevels = 'debug' | 'info' | 'warning' | 'error';

export type BreadcrumbMetaData = {
  [key: string]: number | string;
};

export function trackBreadcrumb(
  message: string,
  level: BreadcrumbLevels,
  metadata: BreadcrumbMetaData,
) {
  if (hasRaygun()) {
    rg4js('recordBreadcrumb', {
      message,
      level,
      metadata,
    });
  }
}
