/** @format **/

import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { merge, keys } from 'lodash';

import { AppState } from 'interfaces/appState';

import * as charting from 'utils/echarts';
import { formatAsShorthand, formatNumber, formatPercentage } from 'utils/numberFormatting';
import { getCurrentRouteData } from 'selectors/router';

import { CustomChart } from 'components/ds/charts';
import { Width } from 'components/width';
import { Container, Graph, Legend, LegendItem } from 'components/echarts/legend';

import { getSeries } from './utils';
import { ColorMap, GroupedSeriesData } from './models';

type DispatchProps = {};

type SuppliedProps = {
  currentData: GroupedSeriesData;
  colorMap: ColorMap;
  height: string;
  customOptions?: any;
  className?: string;
  legendKeys?: string[];
  legendItems?: LegendItem[];
  legendColorMap?: ColorMap;
  onDashboard?: boolean;
  isStacked?: boolean;
  barWidth?: number;
  isProportional?: boolean;
  allowDateRangeSelect?: boolean;
  onDateRangeChange?: (dateFrom: string, dateTo: string) => void;
  logBase?: number;
};

type Props = DispatchProps & SuppliedProps;

export const UnconnectedStackedBar: React.FunctionComponent<Props> = ({
  currentData,
  customOptions,
  className,
  height,
  onDashboard,
  colorMap,
  isStacked,
  barWidth,
  isProportional,
  legendKeys,
  legendColorMap,
  onDateRangeChange,
  logBase,
}) => {
  const dataKeys = keys(currentData.groupedData);

  const getChartOptions = (currentData: GroupedSeriesData) => {
    return (chart: any) => {
      const { groupedData, periods, totals, interval } = currentData;

      const series = getSeries(groupedData, totals, colorMap, isStacked, isProportional);

      const options = charting.extendOptions({
        grid: {
          containLabel: false,
        },
        tooltip: {
          show: true,
          confine: true,
          trigger: 'axis',
          axisPointer: charting.rum.AxisPointerLine,
          padding: 0,
          borderWidth: 0,
          borderRadius: 0,
          showDelay: 0, // ms
          hideDelay: 0, // ms
          transitionDuration: 0, // s
          formatter: (params: any[]) => {
            return charting.renderSeriesTooltip({
              date: charting.formatUtcTooltipDate(params[0].name, interval),
              series: params.map((s: any) => {
                const value = isProportional ? formatPercentage(s.value) : formatNumber(s.value, 2);

                return {
                  name: s.seriesName,
                  colorcode: s.color,
                  value,
                };
              }),
            });
          },
        },
        xAxis: [
          {
            type: 'category',
            data: periods,
            boundaryGap: true,
            axisLabel: {
              formatter: charting.getDateTimeLabelFormatter(
                periods,
                interval,
                chart ? chart.getEchartsInstance() : undefined,
              ),
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            max: isProportional ? 100 : null,
            axisLabel: {
              formatter: (value: number) => {
                return isProportional ? value : formatAsShorthand(value, 1);
              },
            },
            logBase,
          },
        ],
        barMaxWidth: barWidth,
        series,
      });

      return customOptions ? merge({}, options, customOptions) : options;
    };
  };

  const getLegendItems = useCallback(() => {
    const _colorMap = legendColorMap || colorMap;

    const _legendKeys = legendKeys || dataKeys;

    return _legendKeys.map(key => ({
      label: key,
      colorCode: _colorMap[key],
    }));
  }, [colorMap, legendColorMap]);

  return (
    <Container>
      <Graph hasLegend>
        <Width width={100} units="%">
          <CustomChart
            height={height}
            options={getChartOptions(currentData)}
            className={className}
            zoomOnDateRange
            periods={currentData.periods}
            onDateRangeChange={onDateRangeChange}
          />
        </Width>
      </Graph>
      <Legend positionInside={onDashboard} legendItems={getLegendItems()} />
    </Container>
  );
};

UnconnectedStackedBar.defaultProps = {
  isStacked: true,
  barWidth: 40,
  logBase: 10,
};

export const StackedBar = connect<{}, DispatchProps, SuppliedProps>((state: AppState) => ({
  routeData: getCurrentRouteData(state),
}))(UnconnectedStackedBar);
