/** @format */

import * as React from 'react';
import { ComponentType } from 'react';
import { Action } from 'redux-first-router';
import { startsWith, isNil } from 'lodash';
import { MomentInput } from 'moment';

import { ROUTE_ACTION_BASE } from 'utils/routing';

export type ReactComponent<P> = React.ComponentClass<P> | React.FunctionComponent<P>;

export type DateInput = Omit<MomentInput, 'void'>;

export function isComponent<T>(arg: any): arg is ComponentType<T> {
  if (isNil(arg)) {
    return false;
  }

  return typeof arg === 'function' || (arg.prototype && arg.prototype.isReactComponent);
}

export function isFunction(arg: any): arg is Function {
  if (isNil(arg)) {
    return false;
  }

  return (<Function>arg).apply !== undefined;
}

export function isString(arg: any): arg is string {
  return (<string>arg).toLowerCase !== undefined;
}

export function isNavigationAction(arg: any): arg is Action {
  return startsWith((<Action>arg).type, ROUTE_ACTION_BASE);
}

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export type Subtract<T, U> = Omit<T, keyof U>;

/**
 * Acquire the props for a Component {T}
 */
export type PropsOf<T> = T extends (props: infer P) => React.ReactElement<any> | null // Try to infer for SFCs
  ? P
  : T extends new (props: infer P) => React.Component // Otherwise try to infer for classes
  ? P
  : never;

export type PropsOfWithDefaults<T, D, P = PropsOf<T>> = P extends any
  ? string extends keyof P
    ? P
    : Pick<P, Exclude<keyof P, keyof D>> &
        Partial<Pick<P, Extract<keyof P, keyof D>>> &
        Partial<Pick<D, Exclude<keyof D, keyof P>>>
  : never;

export type PartialRecursive<T> = T extends object
  ? { [K in keyof T]?: PartialRecursive<T[K]> }
  : T;
