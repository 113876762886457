/** @format **/
import { urlContains } from './utils';
import { memoize, every } from 'lodash';

const applicationsUrl = '/applications';
const consumersUrl = '/consumers';
const allocationUrl = '/allocation';
const usageUrl = '/usage';
const invoicesUrl = '/invoices';
const privacyUrl = '/gdpr';
const teamsUrl = '/teams';

type PlanSettingsUrl = {
  summary: string;
  eventAllocation: string;
  usage: string;
  applications: string;
  teams: string;
  invoices: string;
  consumers: string;
  privacy: string;
};

export const getPlanSettingsUrls = memoize(function(planIdentifier: string): PlanSettingsUrl {
  const planSettingsBaseUrl = `/plan/${planIdentifier}`;

  return {
    summary: planSettingsBaseUrl,
    applications: `${planSettingsBaseUrl}${applicationsUrl}`,
    consumers: `${planSettingsBaseUrl}${consumersUrl}`,
    eventAllocation: `${planSettingsBaseUrl}${allocationUrl}`,
    usage: `${planSettingsBaseUrl}${usageUrl}`,
    invoices: `${planSettingsBaseUrl}${invoicesUrl}`,
    privacy: `${planSettingsBaseUrl}${privacyUrl}`,
    teams: `${planSettingsBaseUrl}${teamsUrl}`,
  };
});

export function onApplicationsPage() {
  return urlContains(applicationsUrl);
}

export function onConsumersPage() {
  return urlContains(consumersUrl);
}

export function onAllocationPage() {
  return urlContains(allocationUrl);
}

export function onUsagePage() {
  return urlContains(usageUrl);
}

export function onInvoicesPage() {
  return urlContains(invoicesUrl);
}

export function onPrivacyPage() {
  return urlContains(privacyUrl);
}

export function onTeamsPage() {
  return urlContains(teamsUrl);
}

export function onSummaryPage() {
  return every(
    [applicationsUrl, consumersUrl, allocationUrl, usageUrl, invoicesUrl, privacyUrl, teamsUrl],
    url => !urlContains(url),
  );
}
