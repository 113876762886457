/** @format */

import * as React from 'react';
import { assign } from 'lodash';

import { CellBase } from '../tableModel';

export class NumberCell extends CellBase {
  formatter?: (value: number) => string;
  titleFormatter?: (value: number) => string;
  title?: (rowItem: any) => string;

  default: string = '-';

  constructor(init?: Partial<NumberCell>) {
    super(init);
    assign(this, init);
  }

  render(rowItem: any) {
    let value = this.getValue(rowItem);
    var title = null;

    if (this.title) {
      title = this.title(rowItem);
    } else if (this.titleFormatter) {
      title = this.titleFormatter(value);
    }

    if (this.formatter) {
      value = this.formatter(value);
    }

    if (!value) {
      value = this.default;
    }

    if (title) {
      return (
        <span className="text-ellipsis line-height-24" title={title}>
          {value}
        </span>
      );
    }

    return <span className="text-ellipsis line-height-24">{value}</span>;
  }
}

export default NumberCell;
