/** @format */

import { Sortable, Pageable, Fetchable } from 'interfaces/network';

export const getPagination = (state: Pageable) => ({ page: state.page, pageSize: state.pageSize });
export const getSorting = (state: Sortable) => ({
  sortField: state.sortField,
  sortDirection: state.sortDirection,
});
export const getFetchStatus = (state: Fetchable):Fetchable => ({
  isFetching: state.isFetching,
  hasErrored: state.hasErrored,
  error: state.error,
});

export const getFetchStatusWhenUndefined = (
  state: Fetchable = { isFetching: true, hasErrored: true, error: null },
) => {
  return getFetchStatus(state);
};
