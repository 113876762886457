/** @format */

import * as React from 'react';
const echarts = require('echarts/lib/echarts');

import { take, drop, sortBy } from 'lodash';
import { Container, Graph, Legend } from 'components/echarts/legend';
import * as charting from 'utils/echarts/index';
import { LiveSessionDistributionPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';
import { WorldMap } from './worldMap';

class Chart extends React.Component<ProviderProps, {}> {
  constructor(props: ProviderProps) {
    super(props);
    this.onChartReady = this.onChartReady.bind(this);
  }

  onChartReady() {
    this.forceUpdate();
  }

  render() {
    const data = this.props.data as LiveSessionDistributionPayload[];
    const mapData = data.reduce(
      ({ countries, max }, { Count, Location }) => {
        const [X, Y] = Location.split(',');

        return {
          countries: [...countries, { name: Location, value: [X, Y, Count] }],
          min: 0,
          middle: 0,
          max: Math.max(max, Count),
        };
      },
      { countries: [] as { name: string; value: any[] }[], min: 0, middle: 0, max: -9999999 },
    );

    const digits = Math.max(2, ('' + mapData.max).length);
    const metric = Math.pow(10, digits - 1);
    mapData.max = Math.max(10, Math.round(mapData.max / metric) * metric);
    mapData.min = 1;
    mapData.middle = Math.round(mapData.max / 2);

    const sortedCountries = sortBy(mapData.countries, item => item.value[2]).reverse();

    const options = {
      backgroundColor: '#fff',
      visualMap: {
        show: false,
        min: 0,
        max: mapData.max,
        calculable: false,
        color: ['#1CC526', '#F4E216', '#F73415'],
      },
      tooltip: {
        show: true,
        showDelay: 0, // ms
        hideDelay: 0, // ms
        transitionDuration: 0, // s
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        enterable: false,
        formatter: (params: any) => {
          const userCount = params.value[2];

          return charting.renderTooltip(userCount === 1 ? '1 session' : `${userCount} sessions`);
        },
      },
      geo: {
        roam: false,
        clickable: false,
        hoverable: true,
        map: 'world',
        zoom: 1.2,
        label: {
          normal: {
            show: false,
          },
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          normal: {
            borderColor: '#90C0DE',
            borderWidth: 1,
            areaStyle: {
              color: 'rgba(223,234,241,0.8)',
            },
            color: 'rgba(223,234,241,0.8)',
          },
          emphasis: {
            borderColor: '#90C0DE',
            borderWidth: 1,
            areaStyle: {
              color: '#BBD9EC',
            },
            color: '#BBD9EC',
          },
        },
      },
      series: [
        {
          coordinateSystem: 'geo',
          name: 'scatter',
          type: 'effectScatter',
          symbolSize: 6,
          hoverAnimation: false,
          effectType: 'ripple',
          showEffectOn: 'render',
          rippleEffect: {
            brushType: 'fill',
            period: 6,
            scale: 6,
          },
          data: take(sortedCountries, 5),
        },
        {
          coordinateSystem: 'geo',
          name: 'scatter',
          type: 'effectScatter',
          symbolSize: 6,
          hoverAnimation: false,
          effectType: 'ripple',
          showEffectOn: 'emphasis',
          data: drop(sortedCountries, 5),
        },
      ],
    };

    const { min, middle, max } = mapData;
    return (
      <Container>
        <Graph positionInside hasLegend>
          <WorldMap
            style={{ height: '100%' }}
            echarts={echarts}
            option={options}
            onChartReady={this.onChartReady}
          />

          <Legend
            legendItems={[
              { label: `${min} - ${middle} sessions`, colorCode: charting.distributionColor10 },
              { label: `${middle} - ${max} sessions`, colorCode: charting.distributionColor5 },
              { label: `${max}+ sessions`, colorCode: charting.distributionColor1 },
            ]}
          />
        </Graph>
      </Container>
    );
  }
}

const LiveSessionDistributionMap: React.FunctionComponent<ProviderProps> = props => (
  <Chart {...props} />
);

const ConnectedTile = TileDataProvider(LiveSessionDistributionMap, ChartTileLoading);

export { ConnectedTile as LiveSessionDistributionMap };
