/** @format */

import * as React from 'react';

import ModuleHeader from './header';
import { HEADING_CLASSES } from 'components/text';

interface Props {
  bold?: boolean;
}

export const ModuleTitle: React.FunctionComponent<Props> = props => {
  if (props.bold) {
    return (
      <ModuleHeader color="white">
        <h3 className={HEADING_CLASSES.FIVE}>{props.children}</h3>
      </ModuleHeader>
    );
  } else {
    return (
      <ModuleHeader color="white">
        <p className={HEADING_CLASSES.FIVE}>{props.children}</p>
      </ModuleHeader>
    );
  }
};

ModuleTitle.defaultProps = {
  bold: true,
};

export default ModuleTitle;
