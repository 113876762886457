/** @format **/

import React, { useState } from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { reduce, every, isNil } from 'lodash';

import { Theme, ThemeType } from 'app/theme';
import { scrollbar } from 'fela-rules/scrollbar';

import { Helptip } from 'components/ds/tooltip';
import { SearchInput } from 'components/ds/inputs';
import { MenuItemCheckbox } from 'components/ds/dropdown/menu/items';
import { EmphasisButton, TextButton } from 'components/ds/button';
import { Flex } from 'components/flex';

import { Padding } from '../../../layout';

export type ChildItem<T = {}> = {
  label: string;
  checked: boolean;
  item?: T;
  disabled?: boolean;
  helptipLabel?: string;
  rightIcon?: boolean;
};

type PassedProps<T = {}> = {
  allSelected: boolean;
  onSelectAll: (searchQuery?: string) => void;
  onChangeOfSearch?: (searchQuery?: string) => void;
  onSubmit?: (searchQuery?: string) => void;
  onCancel?: () => void;
  childItems: ChildItem<T>[];
  onChange: (item: T, searchQuery: string) => void;
  sortFunc?: (items: ChildItem<T>[]) => ChildItem<T>[];
  clearableSearch?: boolean;
  includeFooter?: boolean;
};
type StateProps = {};
type DispatchProps = {};

type Props<T = {}> = PassedProps<T> & StateProps & DispatchProps & ThemeType;

export function SearchableDropdownMenu<T>({ onChange, sortFunc, ...props }: Props<T>) {
  const [searchQuery, setSearchQuery] = useState('');

  const searchOnChange = (value: string) => {
    setSearchQuery(value);

    if (!isNil(props.onChangeOfSearch)) {
      props.onChangeOfSearch(value);
    }
  };

  let childItems = props.childItems;

  if (sortFunc) {
    childItems = sortFunc(childItems);
  }

  const menuItems = childItems.map((c, idx) => {
    return c.label.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ? (
      !isNil(c.helptipLabel) ? (
        <Helptip label={c.helptipLabel} showOnRight itemBlock block>
          <MenuItemCheckbox
            key={idx}
            label={c.label}
            checked={c.checked}
            onChange={() => onChange(c.item, searchQuery)}
            textEllipsis
            selected={c.checked}
            spacing
            disabled={c.disabled}
            rightIcon={c.rightIcon}
          />
        </Helptip>
      ) : (
        <MenuItemCheckbox
          key={idx}
          label={c.label}
          checked={c.checked}
          onChange={() => onChange(c.item, searchQuery)}
          textEllipsis
          selected={c.checked}
          spacing
          disabled={c.disabled}
          rightIcon={c.rightIcon}
        />
      )
    ) : null;
  });

  return (
    <SearchableDropdownMenuContainer>
      <SearchInput
        placeholder="Search"
        value={searchQuery}
        onChange={e => searchOnChange(e.target.value)}
        borderColor="transparent"
        autoFocus
        clearable={props.clearableSearch}
      />

      <SearchableDropdownDivider />

      <SearchableDropdownItemsWrapper>
        {!every(menuItems, i => i == null) ? (
          <>
            <MenuItemCheckbox
              label="Select all"
              labelFontWeight={600}
              checked={props.allSelected}
              onChange={() => props.onSelectAll(searchQuery)}
              selected={props.allSelected}
              spacing
            />
            {menuItems}
          </>
        ) : (
          <SearchableDropdownNoResultsText>No matches found</SearchableDropdownNoResultsText>
        )}
      </SearchableDropdownItemsWrapper>

      {props.includeFooter && (
        <SearchableDropdownControls>
          <Flex justify="flex-end">
            {props.onCancel && (
              <Padding right={8}>
                <TextButton onClick={props.onCancel}>Cancel</TextButton>
              </Padding>
            )}
            <EmphasisButton onClick={() => props.onSubmit(searchQuery)}>
              Select ({reduce(props.childItems, (acc, { checked }) => (checked ? acc + 1 : acc), 0)}
              )
            </EmphasisButton>
          </Flex>
        </SearchableDropdownControls>
      )}
    </SearchableDropdownMenuContainer>
  );
}

const StyledSearchableDropdownNoResultsText = (): IStyle => ({
  textAlign: 'center',
  minHeight: '40px',
  paddingTop: '1rem',
  paddingBottom: '1rem',
});
export const SearchableDropdownNoResultsText = createComponent(
  StyledSearchableDropdownNoResultsText,
);

type SearchableDropdownDivider = {};
const StyledSearchableDropdownDivider = (
  _props: SearchableDropdownDivider & ThemeType,
): IStyle => ({
  width: '100%',
  height: '1px',
  backgroundColor: Theme.ds.colors.blueGrey.blueGrey100,
});
export const SearchableDropdownDivider = createComponent(StyledSearchableDropdownDivider);

type SearchableDropdownMenuContainer = {};
const StyledSearchableDropdownMenuContainer = (
  _props: SearchableDropdownMenuContainer & ThemeType,
): IStyle => ({
  width: '100%',
  maxHeight: '408px',
  display: 'block',
  backgroundColor: Theme.colors.standard.white,
});
const SearchableDropdownMenuContainer = createComponent(StyledSearchableDropdownMenuContainer);

type SearchableDropdownControls = {};
const StyledSearchableDropdownControls = (
  _props: SearchableDropdownControls & ThemeType,
): IStyle => ({
  borderTop: '1px solid',
  borderColor: Theme.ds.colors.blueGrey.blueGrey100,
  padding: '16px',
});
const SearchableDropdownControls = createComponent(StyledSearchableDropdownControls);

type SearchableDropdownItemsWrapper = {};
const StyledSearchableDropdownItemsWrapper = (
  _props: SearchableDropdownItemsWrapper & ThemeType,
): IStyle =>
  scrollbar({
    maxHeight: '294px',
    overflowY: 'auto',
    paddingTop: '8px',
    paddingBottom: '8px',
  });
const SearchableDropdownItemsWrapper = createComponent(StyledSearchableDropdownItemsWrapper);
