/** @format */

// Basic sanitize script ported from `vendor/Markdown.Sanitizer` to minic functionality

// (tags that can be opened/closed) | (tags that stand alone)
const basic_tag_whitelist = /^(<\/?(b|blockquote|code|del|dd|dl|dt|em|h1|h2|h3|i|kbd|li|ol|p|pre|s|sup|sub|strong|strike|ul|span)>|<(br|hr)\s?\/?>)$/i;

// <a href="url..." optional title>|</a>
const a_white = /^(<a\shref="((https?|ftp):\/\/|\/)[-A-Za-z0-9+&@#\/%?=~_|!:,.;\(\)]+"(\stitle="[^"<>]+")?\s?>|<\/a>)$/i;

// <img src="url..." optional width  optional height  optional alt  optional title
const img_white = /^(<img\ssrc="(https?:\/\/|\/)[-A-Za-z0-9+&@#\/%?=~_|!:,.;\(\)]+"(\swidth="\d{1,3}")?(\sheight="\d{1,3}")?(\salt="[^"<>]*")?(\stitle="[^"<>]*")?(\sstyle="[^"<>]*")?\s?\/?>)$/i;

// <span class="text-highlight"> special use cases
const special_case = /^<span class="text-highlight">$/i;

function sanitizeTag(tag: string) {
  if (
    tag.match(basic_tag_whitelist) ||
    tag.match(a_white) ||
    tag.match(img_white) ||
    tag.match(special_case)
  ) {
    return tag;
  } else {
    return '';
  }
}

export function sanitizeHtml(html: string) {
  return html.replace(/<[^>]*>?/gi, sanitizeTag);
}
