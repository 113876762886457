/** @format **/

import { PermissionTypes } from 'modules/permissions';
import { GenericResourceErrors, ResourceError } from 'modules/resources/models';

export { SystemTeams } from 'interfaces/teams';

/**
 * Metadata returned when deleting team member with flags to handle various edge-cases
 */
export type DeletedSelf = {
  fromOwnersTeam: boolean;
  fromUsersTeam: boolean;
  fromCurrentTeam: boolean;
};

/**
 * Representation of a team member
 */
export type TeamMember = {
  id: number;
  name: string;
  emailAddress: string;
  avatarUri: string;
  twoFactorAuthEnabled: boolean;
  canBeRemoved: boolean;
  invitePending: boolean;
  inCurrentTeam: boolean;
  deletedSelf: DeletedSelf;
};

/**
 * Representation of a team containing multiple members
 */
export type Team = {
  id: string;
  name: string;
  teamMembers: TeamMember[];
};

/**
 * An application which is associated with a team
 */
export type TeamApplication = {
  applicationName: string;
  id: number;
  avatarUrl: string;
  canRemoveApplication: boolean;
};

/**
 * The representation of a user in the store to toggle the selected state
 */
export type PlanUserState = {
  id: number;
  emailAddress: string;
  selected: boolean;
};

/**
 * An object containing all states of the users in the list of users to invite
 */
export type SelectedUsers = { [userId: string]: PlanUserState };

/**
 * A user to be sent to the server, the Id can be set to null to indicate that this user does not
 * currently exist and so should be invited
 */
export type InviteUserPayload = {
  id: number;
  emailAddress: string;
};

/**
 * The payload to send to the server with all new users to try to add to the team
 */
export type InviteUsersSchema = {
  id: number;
  teamMembers: InviteUserPayload[];
};

/**
 * TeamMemberErrorCode maps directly to Enum in TeamMemberModel
 * See \Areas\PlanSettings\Models\Teams\TeamMemberModel.cs
 */
export enum TeamMemberErrorCode {
  NoError = 0,
  TeamMemberListEmpty = 1,
  UserDoesNotHavePrivileges = 2,
  EmailAddressBlocked = 3,
  FailedToSendEmail = 4,
  InvalidEmailAddress = 5,
  CannotModifyTeam = 6,
  CannotRemoveOwnerFromOwnersTeam = 7,
  CannotRemoveOwnerFromUsersTeam = 8,
  ResourceNotFound = 9,
  FailedToRemoveInvitation = 10,
  CannotAddTeamMembers = 11,
  TeamDoesNotExist = 12,
}

export type TeamPermission = {
  id: PermissionTypes;
  enabled: boolean;
};

/**
 * Format of error from the server
 */

export type TeamMemberError = {
  meta: {
    emailAddress: string;
    isListError?: boolean;
  };
} & ResourceError;

export type SelectedApplications = {
  [appId: number]: boolean;
};

export type PlanApplication = {
  id: number;
  name: string;
  avatarUrl: string;
};

export type TeamAddApplicationModel = {
  Applications: number[];
};
