/**
 * @prettier
 */

import React from 'react';

import { isComponent } from 'utils/types';

import { SortDirection } from 'constants/sorting';

import { HeaderToggleCell } from './groupedTable/components';

import TableHeaderItem from './tableHeaderItem';
import { applyGutterCells } from './tableUtils';
import { Column } from './tableModel';

interface Props {
  columns: Column[];
  sortField?: string;
  sortDirection?: SortDirection;
  gutters?: boolean;
  onSortChanged?: (sortField: string, sortDirection: SortDirection) => void;
  isGroupedTable?: boolean;
  toggleAllGroupedData?: () => void;
  showAllGroupedData?: boolean;
}

const constructTableData = (props: Props): React.ReactNode[] => {
  return props.columns.map((column, index) => {
    let sortDirection: SortDirection = undefined;

    if (
      column.sortOptions &&
      (props.sortField === column.sortOptions.sortField ||
        (!isComponent(column.cell) &&
          column.cell !== undefined &&
          props.sortField === column.cell.accessor))
    ) {
      sortDirection = props.sortDirection;
    }

    return (
      <TableHeaderItem
        key={index}
        {...column}
        sortDirection={sortDirection}
        onSortChanged={props.onSortChanged}
      />
    );
  });
};

const TableHeader: React.FunctionComponent<Props> = props => {
  const headerItems = applyGutterCells(constructTableData(props), props.gutters);

  // Add an arrow icon button to toggle all grouped table rows
  if(props.isGroupedTable) {
    headerItems.unshift((
      <HeaderToggleCell
        key={-1}
        toggleAllGroupedData={props.toggleAllGroupedData}
        showAllGroupedData={props.showAllGroupedData}
      />
    ));
  }

  return (
    <thead>
      <tr className="table-controls hide-mob">{headerItems}</tr>
    </thead>
  );
};

TableHeader.defaultProps = {
  sortField: '',
  gutters: false,
};

export default TableHeader;
