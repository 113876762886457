/**
 * @prettier
 */

import * as React from 'react';
import { isNil } from 'lodash';

import { Small, Medium } from 'components/breakpoints';

import { ErrorGroupsPayload } from '../../../../actions';
import { ErrorGroupListLoading } from '../../loading/errorGroupList';
import { TileDataProvider, Props as ProviderProps } from '../../dataProvider';
import { SortingHoC, SortingHoCProps } from '../../../sortingHoC';
import { NoData } from '../../noData';

import { ErrorGroupTable } from './table';
import { ErrorGroupListMobile } from './errorGroupListMobile';
import { PageNavigation } from './pageNavigation';

export const UnconnectedErrorGroupListTile: React.FunctionComponent<
  ProviderProps & SortingHoCProps & { drilldown?: boolean }
> = props => {
  const response = props.data as ErrorGroupsPayload;

  if (props.loading) {
    return <ErrorGroupListLoading />;
  }

  if (isNil(response)) {
    return <NoData />;
  }

  const data = response.Data;

  if (data.length === 0) {
    return <NoData />;
  }

  return (
    <>
      <Small>
        <ErrorGroupListMobile {...props} data={data} />
      </Small>

      <Medium andAbove>
        <ErrorGroupTable {...props} id={props.instanceId} data={data} />
        <PageNavigation {...props} errorGroupCount={response.ErrorGroupCount} />
      </Medium>
    </>
  );
};

const ConnectedTile = TileDataProvider(
  SortingHoC(UnconnectedErrorGroupListTile),
  ErrorGroupListLoading,
);

export { ConnectedTile as ErrorGroupListTile };
