/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { clearfix } from 'fela-rules/layout';
import { screenSmallOnly } from 'fela-rules/breakpoints';

type TableControlsProps = {
  includesCheckbox?: boolean;
  border?: boolean;
};

const StyledTableControls = (props: TableControlsProps & ThemeType): IStyle =>
  applyModifiers(
    [
      !props.includesCheckbox,
      {
        paddingLeft: '4px',
      },
    ],
    [
      !props.border,
      {
        borderBottom: 'none',
      },
    ],
  )(
    clearfix({
      position: 'relative',
      padding: '4px 4px 4px 52px',
      ...screenSmallOnly({ paddingBottom: '15px', padding: '' }),
      borderBottom: `1px solid ${props.theme.colors.border.light}`,
    }),
  );

export const TableControls = createComponent<TableControlsProps>(StyledTableControls, 'div');

TableControls.defaultProps = {
  includesCheckbox: true,
  border: true,
};

type TableControlsChildProps = {
  rightAlign?: boolean;
};

const StyledTableControlsText = (props: TableControlsChildProps) => {
  const style: IStyle = {
    float: 'left',
    lineHeight: '32px',
    marginRight: `4px`,
  };

  return applyModifiers([
    props.rightAlign,
    {
      float: 'right',
    },
  ])(style);
};

export const TableControlsText = createComponent<TableControlsChildProps>(
  StyledTableControlsText,
  'div',
);

const StyledTableControlsButton = (props: TableControlsChildProps) => {
  const style: IStyle = {
    float: 'left',
    margin: '0 4px 0 0',
  };

  return applyModifiers([
    props.rightAlign,
    {
      float: 'right',
    },
  ])(style);
};

export const TableControlsButton = createComponent<TableControlsChildProps>(
  StyledTableControlsButton,
  'div',
);

const StyledTableControlsCheckbox = (): IStyle => ({
  position: 'absolute',
  left: '16px',
  top: '12px',
});

export const TableControlsCheckbox = createComponent(StyledTableControlsCheckbox, 'div');
