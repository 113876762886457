/** @format */

import {
  checkStatus,
  convertObjectToFormDataString,
  delayRequest,
  expandParameters,
  getWebApiUri,
  getWebAppUri,
  NetworkFunction,
  parseAsJsonOrText,
  QueryParameters,
  logRequestAsBreadcrumb,
} from './utils';

export const fetchFromApp: NetworkFunction = (
  path: string,
  apiKey: string,
  parameters: QueryParameters = {},
): Promise<any> => {
  parameters = { k: apiKey, ...parameters };
  return fetch(`${getWebAppUri()}/${path}${expandParameters(parameters)}`, {
    headers: {},
    method: 'GET',
    credentials: 'include',
  })
    .then(logRequestAsBreadcrumb('GET'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};

export const putToApp = (path: string, apiKey: string, parameters: any = {}): Promise<any> => {
  const queryParameters = { k: apiKey };
  return fetch(`${getWebAppUri()}/${path}${expandParameters(queryParameters)}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/plain, */*;',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(parameters),
  })
    .then(logRequestAsBreadcrumb('PUT'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};

export const postToApp = (
  path: string,
  apiKey: string,
  parameters: any = {},
  isFormData = false,
): Promise<any> => {
  const queryParameters = { k: apiKey };

  return fetch(`${getWebAppUri()}/${path}${expandParameters(queryParameters)}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/plain, */*;',
      'Cache-Control': 'no-cache',
      'Content-Type': isFormData ? 'application/x-www-form-urlencoded' : 'application/json',
    },
    body: isFormData ? convertObjectToFormDataString(parameters) : JSON.stringify(parameters),
  })
    .then(logRequestAsBreadcrumb('POST'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};

export const deleteOnApp = (path: string, apiKey: string, parameters: any = {}): Promise<any> => {
  const queryParameters = { k: apiKey };
  return fetch(`${getWebAppUri()}/${path}${expandParameters(queryParameters)}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/plain, */*;',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(parameters),
  })
    .then(logRequestAsBreadcrumb('DELETE'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};

export const fetchFromAPI = (
  path: string,
  apiKey: string,
  parameters: QueryParameters = {},
): Promise<any> => {
  parameters = { k: apiKey, ...parameters };
  return fetch(`${getWebApiUri()}${path}${expandParameters(parameters)}`, {
    headers: {},
    mode: 'cors',
    method: 'GET',
  })
    .then(logRequestAsBreadcrumb('GET'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};

export const putToAPI = (path: string, apiKey: string, parameters: any = {}): Promise<any> => {
  const queryParameters = { k: apiKey };
  return fetch(`${getWebApiUri()}${path}${expandParameters(queryParameters)}`, {
    mode: 'cors',
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*;',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(parameters),
  })
    .then(logRequestAsBreadcrumb('PUT'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};

export const putToAPIWithBody = (
  path: string,
  apiKey: string,
  body: any,
  parameters: QueryParameters = {},
): Promise<any> => {
  const queryParameters = { k: apiKey, ...parameters };
  return fetch(`${getWebApiUri()}${path}${expandParameters(queryParameters)}`, {
    mode: 'cors',
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*;',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then(logRequestAsBreadcrumb('PUT'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};

export const postToAPI = (path: string, apiKey: string, parameters: any = {}): Promise<any> => {
  const queryParameters = { k: apiKey };
  return fetch(`${getWebApiUri()}${path}${expandParameters(queryParameters)}`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*;',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(parameters),
  })
    .then(logRequestAsBreadcrumb('POST'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};

export const deleteFromAPI = (path: string, apiKey: string, parameters: any = {}): Promise<any> => {
  const queryParameters = { k: apiKey };
  return fetch(`${getWebApiUri()}${path}${expandParameters(queryParameters)}`, {
    mode: 'cors',
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*;',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(parameters),
  })
    .then(logRequestAsBreadcrumb('DELETE'))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
};
