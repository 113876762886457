/**
 * @prettier
 */

import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme/index';
import { applyModifiers } from 'fela-rules/modifiers';

type ContentWrapperProps = {
  forBreadcrumbs?: boolean;
  forActionBar?: boolean;
  positionAbove?: boolean;
};

const StyledContentWrapper = (props: ThemeType & ContentWrapperProps): IStyle =>
  applyModifiers(
    [
      // Used to position breadcrumbs above no-data states on dashboards
      props.positionAbove, 
      {
        position: 'relative',
        zIndex: 2
      }
    ],
    [
      props.forBreadcrumbs,
      {
        padding: '12px 28px',
        borderBottom: `1px solid ${props.theme.colors.border.standard}`,
      },
    ],
    [
      props.forActionBar,
      {
        borderBottom: `1px solid ${props.theme.colors.border.standard}`,
      },
    ],
  )({
    padding: '12px',
  });

export const ContentWrapper = createComponent<ContentWrapperProps>(StyledContentWrapper);
