/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { isEmpty, get } from 'lodash';

import { User } from 'interfaces/user';
import { stringToNumber } from 'utils/string';
import { COLORS } from 'app/theme/materialDS';
import { Theme } from 'app/theme';

import { Icon } from 'components/icon';

const userAvatarIds = [
  'user-avatar-1',
  'user-avatar-2',
  'user-avatar-3',
  'user-avatar-4',
  'user-avatar-5',
];

/**
 * The color arrays have an extra purple color inserted at the end. This is on purpose.
 * The reason for this is because the avatar/color pair is chosen by using the modulo operator
 * on the users identifier converted to a number against the array length. If both the avatar
 * and color arrays had the same length the same avatar would always have the same color.
 *
 * With them having different lengths (5 vs 6) it ensures that the colors are slightly offset
 * from the avatars resulting in a spread of colors for a given avatar. Purple is represented more
 * often but it should not be really noticeable
 */
const backgroundColors: COLORS[] = [
  'amber800',
  'cyan700',
  'purple500',
  'green600',
  'blue1000',
  'purple500',
];
const iconColors: COLORS[] = [
  'amber100',
  'cyan100',
  'purple100',
  'green100',
  'blue100',
  'purple100',
];

type AvatarBackgroundProps = {
  big: boolean;
  color: COLORS;
};
function StyledAvatarBackground(props: AvatarBackgroundProps): IStyle {
  return {
    height: props.big ? '40px' : '24px',
    width: props.big ? '40px' : '24px',
    backgroundColor: Theme.ds.getColorByName(props.color),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
}

const AvatarBackground = createComponentWithProxy(StyledAvatarBackground);

type AvatarImageProps = {
  big: boolean;
};
function StyledAvatarImage(props: AvatarImageProps): IStyle {
  return {
    width: props.big ? '40px' : '24px',
    height: props.big ? '40px' : '24px',
    borderRadius: '50%',
    verticalAlign: 'top',
  };
}

const AvatarImage = createComponentWithProxy(StyledAvatarImage, 'img');

export type UserAvatarProps = {
  user: User;
  big?: boolean;
};

const UserAvatarIconSet: React.FunctionComponent<UserAvatarProps> = ({ big, user }) => {
  const userNumber = stringToNumber(get(user, 'identifier', 'unknown'));
  const icon = userAvatarIds[userNumber % userAvatarIds.length];

  const colorIndex = userNumber % iconColors.length;
  const iconColor = iconColors[colorIndex];
  const backgroundColor = backgroundColors[colorIndex];

  return (
    <AvatarBackground big={big} color={backgroundColor}>
      <Icon set="icomoon" size={big ? 24 : 16} dsColor={iconColor} type={icon} />
    </AvatarBackground>
  );
};

const GenerateUserAvatarIconUrl = (size = 24) => {
  const supportedSizes = [24, 40];
  if (supportedSizes.indexOf(size) == -1) {
    size = 24;
  }

  const userKeys = ['user1', 'user2', 'user3', 'user4', 'user5'];
  const colorKeys = [
    'amber--800',
    'cyan--700',
    'deep-purple--500',
    'green--600',
    'raygun-blue--1000',
  ];

  return `/Content/Images/icons/avatars/ds/png/${size}-${
    userKeys[Math.floor(Math.random() * userKeys.length)]
  }-${colorKeys[Math.floor(Math.random() * colorKeys.length)]}-min.png`;
};

const UserAvatarImage: React.FunctionComponent<UserAvatarProps> = ({ big, user }) => (
  <AvatarImage
    src={`${user.avatarUrl}&d=https%3A%2F%2Fapp.raygun.com${GenerateUserAvatarIconUrl(40)}`}
    alt="Avatar for user"
    big={big}
  />
);

export const UserAvatar: React.FunctionComponent<UserAvatarProps> = props => {
  if (isEmpty(get(props, 'user.avatarUrl'))) {
    return <UserAvatarIconSet {...props} />;
  }

  return <UserAvatarImage {...props} />;
};

UserAvatar.defaultProps = {
  big: false,
};
