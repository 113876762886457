import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { applyModifiers } from 'fela-rules/modifiers';
import { ThemeType } from 'app/theme';


const StyledMenuDesktopContainer = (props: ThemeType): IStyle => ({
  width: '240px',
  border: `1px solid ${props.theme.ds.getColorByName('blueGrey100')}`,
  boxShadow: props.theme.ds.standardBoxShadow,
  borderRadius: '2px',
  backgroundColor: props.theme.ds.getColorByName('white'),
  paddingBottom: '10px'
});

export const DesktopMenuContainer = createComponentWithProxy(StyledMenuDesktopContainer);

/**
 * MenuContainer and MenuSection elements are designed to work together to function nicely
 * 
 * - MenuContainer is the parent container for each MenuSection
 * - The non 'isPlanSwitcher' MenuSection is positioned 'relatively' so that it's height is given to the whole menu
 * - The 'isPlanSwitcher' MenuSection has absolute positioning to hide it off-screen
 */

const StyledMenuContainer = (): IStyle => ({
  display: 'block',
  width: '100%',
  position: 'relative',
  top: '0',
  left: '0',
  overflow: 'hidden'
});

export const MenuContainer = createComponentWithProxy(StyledMenuContainer);


type MenuSectionProps = {
  isSubMenu?: boolean;
  hidden: boolean;
};

const StyledMenuSection = ({ isSubMenu, hidden, ...props }: MenuSectionProps & ThemeType): IStyle => applyModifiers(
  [!isSubMenu, {
    position: 'relative',
    boxShadow: `1px 0 0 ${props.theme.ds.getColorByName('blueGrey100')}`,
  }],
  [!isSubMenu && hidden, {
    transform: 'translateX(calc(-100% - 1px))'
  }],
  [isSubMenu && hidden, {
    transform: 'translateX(100%)'
  }],
)({
  display: 'block',
  width: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  overflow: 'auto',
  transition: 'transform 0.4s cubic-bezier(0.26, 0.91, 0.79, 1)',
  transform: 'translateX(0)'
});

export const MenuSection = createComponentWithProxy<MenuSectionProps>(StyledMenuSection);