/**
 * @prettier
 */

export * from './payloads';

import createActionFactory from 'typescript-fsa';

import { FetchActionPayload } from '../../models';
import {
  SingleValuePayload,
  ChartPayload,
  MapPayloads,
  ListPayload,
  ErrorGroupsPayload,
} from './payloads';

const createAction = createActionFactory('dashboard/data');

export const fetchTileData = createAction<FetchActionPayload>('FETCH_TILE_DATA');

export const fetchTileDataStarted = createAction<{ instanceId: string }>('FETCH_TILE_DATA_STARTED');
export const fetchTileDataFailed = createAction<{ instanceId: string; error: Error }>(
  'FETCH_TILE_DATA_FAILED',
);

export type FetchDataPayload = SingleValuePayload | ListPayload | ChartPayload | MapPayloads | ErrorGroupsPayload | {};
export const fetchTileDataSucceeded = createAction<{
  instanceId: string;
  payload: FetchDataPayload;
}>('FETCH_TILE_DATA_SUCCESS');

export type FetchDrilldownDataBase = {
  appId: string;
  parentTileId: string;
};
export const fetchDrilldownData = {
  start: createAction<FetchDrilldownDataBase>('DRILLDOWN_FETCH_STARTED'),
  done: createAction<FetchDrilldownDataBase & { data: ErrorGroupsPayload }>(
    'DRILLDOWN_FETCH_SUCCEEDED',
  ),
  error: createAction<FetchDrilldownDataBase & { error: Error }>('DRILLDOWN_FETCH_FAILED'),
};
