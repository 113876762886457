/**
 * @prettier
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classnames from 'classnames';
import { reduce, isEmpty, has, isNil } from 'lodash';

import { Icon } from 'components/icon';
import { ErrorBoundary } from 'components/errorBoundary';

import { ActiveFilters } from './activeFilters/index';

import { ToplevelFiltersState } from './state';
import * as Actions from './actions';
import * as Models from './models';
import * as Selectors from './selectors';

type DispatchProps = {
  updateExternalConfiguration: (newOptions: Actions.UpdateExternalConfigurationPayload) => void;
  updateComponentState: (state: Actions.UpdateComponentStatePayload) => void;
  resetFilters: () => void;
};
type StateProps = {
  numberOfFilters: number;
  collapse: boolean;
  showToggleButton: boolean;
  advancedSearchActive: boolean;
};
type SuppliedProps = {
  containerHeight?: 32 | 48;
  theme?: 'border';
  addFilterText?: string;
  noResultsText?: string;
  applicationIdentifier?: string;
  endpoint?: string;
  fromDate?: string;
  toDate?: string;
  apiKey?: string;
  transformValue?: (value: Models.FilterValue, dimension?: Models.Dimension) => Models.FilterValue;
  noMargin?: boolean;
  supportAdvancedSearch?: boolean;
  showNoResultsText?: boolean;
};
type Props = DispatchProps & StateProps & SuppliedProps;

type State = {
  advancedSearchActive: boolean;
};

class UnconnectedTopLevelFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.checkFilterListWrapping = this.checkFilterListWrapping.bind(this);
    this.handleContainerToggle = this.handleContainerToggle.bind(this);
    this.handleAdvancedSearch = this.handleAdvancedSearch.bind(this);
    this.onReload = this.onReload.bind(this);
  }

  static externalConfigurationProps = [
    'applicationIdentifier',
    'endpoint',
    'fromDate',
    'toDate',
    'apiKey',
    'addFilterText',
    'noResultsText',
    'theme',
    'containerHeight',
    'supportAdvancedSearch',
  ];

  static defaultProps: Partial<Props> = {
    containerHeight: 32,
    supportAdvancedSearch: false,
    transformValue: (value: Models.FilterValue) => value,
  };

  state: State = {
    advancedSearchActive: false,
  };

  checkFilterListWrapping(listElement: Element, listWrapperElement: Element) {
    if (listElement && listWrapperElement) {
      if (isNil(listElement) || isNil(listWrapperElement)) {
        return false;
      }
      // Show the toggle button if the list element is taller than the set container height
      // OR the list element's width is greater than the wrapper's width
      const wrap =
        listElement.clientHeight > this.props.containerHeight ||
        listElement.clientWidth > listWrapperElement.clientWidth;

      this.props.updateComponentState({
        showToggleButton: wrap,
      });
    }
  }

  // Lifecycle

  componentDidMount() {
    const thisPropsAny = this.props as any;

    const suppliedConfigurationOptions = reduce(
      UnconnectedTopLevelFilters.externalConfigurationProps,
      (payload, prop) => {
        if (has(thisPropsAny, prop)) {
          return { ...payload, [prop]: thisPropsAny[prop] };
        }

        return payload;
      },
      {},
    );

    if (!isEmpty(suppliedConfigurationOptions)) {
      this.props.updateExternalConfiguration(suppliedConfigurationOptions);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const nextPropsAny = nextProps as any;
    const thisPropsAny = this.props as any;

    const changedConfigurationOptions = reduce(
      UnconnectedTopLevelFilters.externalConfigurationProps,
      (payload, prop) => {
        if (nextPropsAny[prop] !== thisPropsAny[prop]) {
          return { ...payload, [prop]: nextPropsAny[prop] };
        }

        return payload;
      },
      {},
    );

    if (!isEmpty(changedConfigurationOptions)) {
      this.props.updateExternalConfiguration(changedConfigurationOptions);
    }

    // Expand the component manually if there are no filters (e.g. Proton tab change)
    if (nextProps.numberOfFilters === 0) {
      this.props.updateComponentState({
        collapse: false,
      });
    }
  }

  handleContainerToggle() {
    this.props.updateComponentState({
      collapse: !this.props.collapse,
    });
  }

  handleAdvancedSearch(_query: string) {
    if (this.props.supportAdvancedSearch) {
      this.props.updateComponentState({
        advancedSearchActive: !this.props.advancedSearchActive,
      });
    }
  }

  onReload(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    this.props.resetFilters();
  }

  render() {
    const containerClassNames = classnames('top-level-filters-container', {
      'top-level-filters-container--scroll': this.props.collapse,
    });

    const containerTitleText: string = this.props.collapse
      ? 'Click to expand'
      : 'Click to collapse';

    const toggleButtonClassNames = classnames(
      'active-filter-arrow-button active-filter-arrow-button--active active-filter-arrow-button--next',
      {
        hidden: !this.props.showToggleButton,
      },
    );

    const toggleButtonIconClassNames = classnames('icon-16', {
      'raygun-icon icon--chevron-right': this.props.collapse,
      'flat-icon arrowup-icon': !this.props.collapse,
    });

    const helpIconClassNames = classnames(
      'advanced-search-help-icon simple-tooltip simple-tooltip--width-200',
      {
        'advanced-search-help-icon--offset': this.props.showToggleButton,
        'advanced-search-help-icon--active': this.props.supportAdvancedSearch,
      },
    );

    const rootClassNames = classnames(
      'top-level-filters-root',
      `top-level-filters-root--height-${this.props.containerHeight}`,
      {
        'top-level-filters-root--no-margin-bottom': this.props.noMargin,
        'top-level-filters-root--border-theme': this.props.theme === 'border',
      },
    );

    return (
      <div className={rootClassNames}>
        <div className={containerClassNames}>
          <ErrorBoundary reload={this.onReload} padding={8} reloadText="Reset" singleLine>
            <div className="top-level-filters-container__active-filters">
              <ActiveFilters
                transformValue={this.props.transformValue}
                checkFilterListWrapping={this.checkFilterListWrapping}
                handleAdvancedSearch={this.handleAdvancedSearch}
                supportAdvancedSearch={this.props.supportAdvancedSearch}
                showNoResultsText
              />
            </div>
            <a
              href="https://raygun.com/documentation/product-guides/crash-reporting/searching-error-groups"
              target="_blank"
              className={helpIconClassNames}
              aria-label="View advanced search options in our documentation"
            >
              <Icon type="question" />
            </a>
            <a
              className={toggleButtonClassNames}
              onClick={this.handleContainerToggle}
              title={containerTitleText}
            >
              <span className={toggleButtonIconClassNames} />
            </a>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

export const TopLevelFilters = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: ToplevelFiltersState) => ({
    numberOfFilters: Selectors.getFilters(state).length,
    collapse: Selectors.getComponentState(state).collapse,
    showToggleButton: Selectors.getComponentState(state).showToggleButton,
    advancedSearchActive: Selectors.getComponentState(state).advancedSearchActive,
  }),
  (dispatch: Dispatch) => ({
    updateExternalConfiguration: (newOptions: Actions.UpdateExternalConfigurationPayload) =>
      dispatch(Actions.updateExternalConfiguration(newOptions)),
    updateComponentState: (newState: Actions.UpdateComponentStatePayload) =>
      dispatch(Actions.updateComponentState(newState)),
    resetFilters: () => dispatch(Actions.resetFilters()),
  }),
)(UnconnectedTopLevelFilters);
