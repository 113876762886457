/** @format **/

import * as React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';

import { Theme } from 'app/theme';

import { SnowplowLink } from 'components/snowplow';

import { Pill } from './sidebarPill';

export interface SecondaryLink {
  text: string;
  url: string;
  active?: boolean;
  featureFlag?: string;
  snowplowId: string;
}

export interface LinkProperties {
  url: string;
  text: string;
  svgSymbol: string;
  featureFlags: string[];
  active?: boolean;
  secondaryLinks?: SecondaryLink[];
  listItemClasses?: string;
  id?: string;
  zendeskClass?: string;
  pillText?: string;
  pillColor?: string;
  openInNewTab?: boolean;
  snowplowId: string;
}

const Link: React.FunctionComponent<LinkProperties> = props => {
  const className = classnames(`sidebar-button`, {
    'is-active': props.active,
    [props.zendeskClass]: props.zendeskClass,
  });
  return (
    <li className={props.listItemClasses}>
      <SnowplowLink interactionId={props.snowplowId}>
        <a
          className={className}
          href={props.url}
          id={props.id}
          target={props.openInNewTab ? '_blank' : '_self'}
        >
          <span className="sidebar-button-content">
            <svg className="sidebar-icon">
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref={`/Content/Images/sidebar/sidebar-icons.svg#sidebar-icon--blue-${props.svgSymbol}`}
              />
            </svg>
            <span className="sidebar-label">{props.text}</span>
          </span>
          {!isNil(props.pillText) && (
            <Pill backgroundColor={props.pillColor}>{props.pillText}</Pill>
          )}
        </a>
      </SnowplowLink>
      {secondaryList(props)}
    </li>
  );
};

Link.defaultProps = {
  active: false,
  secondaryLinks: [],
  pillColor: Theme.colors.ember.dark,
};

const secondaryList = (props: LinkProperties) => {
  if (!props.active || props.secondaryLinks.length <= 0) {
    return null;
  }

  const links = props.secondaryLinks
    .filter(link => {
      return !link.featureFlag || props.featureFlags.some(f => f === link.featureFlag);
    })
    .map((item, index) => {
      const classes = classnames({ 'is-active': item.active });

      return (
        <li key={index}>
          <SnowplowLink interactionId={item.snowplowId}>
            <a href={item.url} className={classes}>
              {item.text}
            </a>
          </SnowplowLink>
        </li>
      );
    });

  return <ul className="sidebar-sublist">{links}</ul>;
};

export default Link;
