/**
 * @prettier
 */

import * as React from 'react';
import { sortBy, reverse, toUpper, filter, get } from 'lodash';
import { SortDirection, ASCENDING } from 'constants/sorting';
import { Column, DefaultTableProps } from './tableModel';
import { Table } from './table';

export { Column };

export type Props = DefaultTableProps & {
  dataFilter?: string;
  dataFilterField?: string;
  header?: React.ReactNode;
  sortField: string;
  sortDirection: SortDirection;
  getSortField?: (item: any, sortField: string) => any;
  onSortChange?: (sortField: string, sortDirection: SortDirection) => void;
  noBorderOnLastRow?: boolean;
};
type State = {
  sortedData: any[];
};

export class SortedTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sortedData: this.props.data,
    };

    this.updateSorting = this.updateSorting.bind(this);
    this.updateSortingWrapper = this.updateSortingWrapper.bind(this);
  }

  static defaultProps: Partial<Props> = {
    onSortChange: () => null,
    dataFilter: '',
    dataFilterField: '',
    noBorderOnLastRow: true,
    getSortField: (item, field) => item[field],
  };

  filteredData(data: any[]): any[] {
    const { dataFilter, dataFilterField } = this.props;
    if (dataFilter.length === 0 || dataFilterField.length === 0) {
      return data;
    }
    return filter(data, t => {
      return toUpper(get(t, dataFilterField, '')).indexOf(toUpper(dataFilter)) > -1;
    });
  }

  updateSortingWrapper(
    sortField: string,
    sortDirection: SortDirection,
    data: any[] = this.props.data,
  ) {
    this.updateSorting(sortField, sortDirection, data);
    this.props.onSortChange(sortField, sortDirection);
  }

  updateSorting(sortField: string, sortDirection: SortDirection, data: any[] = this.props.data) {
    const sortedData = sortBy(data, item => this.props.getSortField(item, sortField));

    this.setState({
      sortedData: sortDirection === ASCENDING ? sortedData : reverse(sortedData),
    });
  }

  componentDidMount() {
    this.updateSorting(this.props.sortField, this.props.sortDirection);
  }

  render() {
    return (
      <Table
        {...this.props}
        sortDirection={this.props.sortDirection}
        sortField={this.props.sortField}
        data={this.filteredData(this.state.sortedData)}
        onSortChanged={this.updateSortingWrapper}
        noBorderOnLastRow={this.props.noBorderOnLastRow}
      />
    );
  }
}
