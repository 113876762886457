/** @format */

const RESOLVED = 'resolved';
const ACTIVE = 'active';
const IGNORED = 'ignored';
const PERM_IGNORED = 'permanentlyignored';
export const RESOLVE_IN_VERSION = 'resolveinversion';

export const MY_ISSUES = 'myissues';

export const STATUS = {
  ACTIVE,
  RESOLVED,
  IGNORED,
  PERM_IGNORED,
};

export const STATUS_TITLE: { [key: string]: string } = {
  [STATUS.ACTIVE]: STATUS.ACTIVE,
  [STATUS.RESOLVED]: STATUS.RESOLVED,
  [STATUS.IGNORED]: STATUS.IGNORED,
  [STATUS.PERM_IGNORED]: 'permanently ignored',
};

export enum API_STATUS {
  NOT_SET = 'NotSet',
  RESOLVED = 'Resolved',
  ARCHIVED = 'Archived',
  MUTED = 'Muted',
  ACTIVE = 'Active',
  IGNORED = 'Ignored',
  PERMANENTLY_IGNORED = 'PermanentlyIgnored',
}
