/** @format */

import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Message } from 'interfaces/messages';
import { addMessages, removeMessage } from '../actions/messages';

export type MessagesState = Message[];

export const MessagesReducer = reducerWithInitialState<MessagesState>([])
  .case(addMessages, (state, messages) => {
    return [...state, ...messages];
  })
  .case(removeMessage, (state, messageId) => {
    return state.filter(m => m.MessageId !== messageId);
  });
