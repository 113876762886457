/**
 * @prettier
 */

import React from 'react';
import classnames from 'classnames';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';
import { applyModifiers } from 'fela-rules/modifiers';
import { isNil } from 'lodash';

const ButtonPatternClose: React.FunctionComponent<{}> = props => {
  return (
    <div className="button-pattern button-pattern--close">
      <div className="button-pattern__close">{props.children}</div>
    </div>
  );
};

const ButtonPatternSubmit: React.FunctionComponent<{}> = props => {
  return (
    <div className="button-pattern button-pattern--submit">
      <div className="button-pattern__submit">{props.children}</div>
    </div>
  );
};

interface CancelSubmitProps {
  cancel: React.ReactNode;
  submit: React.ReactNode;
  childrenAreText?: boolean;
}

const renderChildren = (children: React.ReactNode, childrenAreText = false): React.ReactNode => {
  if (!children) {
    return null;
  }

  const classNames = classnames('button-pattern__left', {
    'button-pattern__left--text': childrenAreText,
  });

  return <div className={classNames}>{children}</div>;
};

const ButtonPatternCancelSubmit: React.FunctionComponent<CancelSubmitProps> = props => {
  return (
    <div className="button-pattern button-pattern--cancel-submit">
      <div className="button-pattern__submit">{props.submit}</div>
      <div className="button-pattern__cancel">{props.cancel}</div>
      {renderChildren(props.children, props.childrenAreText)}
    </div>
  );
};

ButtonPatternCancelSubmit.defaultProps = {
  childrenAreText: false,
};

interface CancelPreviewSubmitProps {
  cancel: React.ReactNode;
  preview: React.ReactNode;
  submit: React.ReactNode;
  childrenAreText?: boolean;
}

const ButtonPatternCancelPreviewSubmit: React.FunctionComponent<
  CancelPreviewSubmitProps
> = props => {
  return (
    <div className="button-pattern button-pattern--cancel-submit">
      <div className="button-pattern__submit">{props.submit}</div>
      <div className="button-pattern__cancel">{props.preview}</div>
      <div className="button-pattern__cancel">{props.cancel}</div>
      {renderChildren(props.children, props.childrenAreText)}
    </div>
  );
};

ButtonPatternCancelPreviewSubmit.defaultProps = {
  childrenAreText: false,
};

interface CancelUpdateProps {
  cancel: React.ReactNode;
  update: React.ReactNode;
}

const ButtonPatternCancelUpdate: React.FunctionComponent<CancelUpdateProps> = props => {
  return (
    <div className="button-pattern button-pattern--cancel-update">
      <div className="button-pattern__update">{props.update}</div>
      <div className="button-pattern__cancel">{props.cancel}</div>
    </div>
  );
};

interface LeftRightProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

const ButtonPatternLeftRight: React.FunctionComponent<LeftRightProps> = props => {
  return (
    <div className="button-pattern button-pattern--left-right">
      <div className="button-pattern__left">{props.left}</div>
      <div className="button-pattern__right">{props.right}</div>
    </div>
  );
};

interface TextSubmitProps {
  text: React.ReactNode;
  submit: React.ReactNode;
}

const ButtonPatternTextSubmit: React.FunctionComponent<TextSubmitProps> = props => {
  return (
    <div className="button-pattern button-pattern--text-submit">
      <div className="button-pattern__text">{props.text}</div>
      <div className="button-pattern__submit">{props.submit}</div>
    </div>
  );
};

interface ContentLeft {
  button: React.ReactNode;
}

const ButtonPatternContentLeft: React.FunctionComponent<ContentLeft> = props => {
  return (
    <div className="button-pattern button-pattern--left-content">
      <div className="button-pattern__button">{props.button}</div>
      <div className="button-pattern__content">{props.children}</div>
    </div>
  );
};

interface ButtonPatternFlexProps {
  button: React.ReactNode;
}

const StyledButtonPatternFlexContainer = (): IStyle => ({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledButtonPatternFlexContent = (): IStyle => ({
  display: 'flex',
  minWidth: 0,
});

const ButtonPatternFlexContainer = createComponent(StyledButtonPatternFlexContainer);
const ButtonPatternFlexContent = createComponent(StyledButtonPatternFlexContent);

const ButtonPatternFlex: React.FunctionComponent<ButtonPatternFlexProps> = props => {
  return (
    <ButtonPatternFlexContainer>
      <ButtonPatternFlexContent>{props.children}</ButtonPatternFlexContent>
      {props.button}
    </ButtonPatternFlexContainer>
  );
};

export {
  ButtonPatternClose,
  ButtonPatternSubmit,
  ButtonPatternCancelSubmit,
  ButtonPatternCancelPreviewSubmit,
  ButtonPatternCancelUpdate,
  ButtonPatternLeftRight,
  ButtonPatternTextSubmit,
  ButtonPatternContentLeft,
  ButtonPatternFlex,
};
