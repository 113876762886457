/** @format **/
/* ignore file coverage */
// I've ignored the coverage for this file as I don't think it is worth it
// If we revist this in the future we should unignore it from coverage
// None of this functionality is critical, it's unlikely to change much in the future
// and it's non trivial to test

import { SagaIterator } from 'redux-saga';
import { fork, put, take, takeEvery } from 'redux-saga/effects';
import { Action, ActionCreator } from 'typescript-fsa';
import { get } from 'lodash';

import { SavingMessages, savingNotification } from 'app/notifications/sagas';

import { Position } from 'app/notifications/models';
import { showNotification, hasNotificationCondition } from './utils';

import * as Models from '../models';
import * as Actions from '../actions';

type NotificationActions = {
  started: ActionCreator<any>;
  failed: ActionCreator<any>;
  succeeded: ActionCreator<any>;
};
function* baseNotificationSaga(
  resource: Models.Resource<any, any>,
  messages: SavingMessages,
  actions: NotificationActions,
  notificationType?: string,
) {
  while (true) {
    yield take(
      (action: Action<any>) =>
        action.type === actions.started.type && action.payload.resourceName === resource.name,
    );

    const notification = savingNotification(messages, Position.CENTRE_TOP);

    yield put(notification.next().value);

    const action = yield take([
      (action: Action<any>) =>
        action.type === actions.failed.type && action.payload.resourceName === resource.name,
      (action: Action<any>) =>
        action.type === actions.succeeded.type && action.payload.resourceName === resource.name,
    ]);

    const success = action.type === actions.succeeded.type;

    if (showNotification(resource, action.payload.response, notificationType)) {
      yield put(notification.next(success).value);
    }
  }
}

function* createNotificationSagaForResource(
  action: Action<Actions.CreateSavingSagasForResourcePayload>,
): SagaIterator {
  const { resource } = action.payload;

  if (hasNotificationCondition(resource, 'fetching')) {
    yield fork(
      baseNotificationSaga,
      resource,
      {
        saving: get(resource, 'meta.notifications.fetchingMessages.started', 'Fetching...'),
        done: get(resource, 'meta.notifications.fetchingMessages.successful', 'Fetched'),
        error: get(resource, 'meta.notifications.fetchingMessages.failed', 'Fetching failed'),
      },
      {
        started: Actions.fetchingResourceStarted,
        failed: Actions.fetchingResourceFailed,
        succeeded: Actions.fetchingResourceSucceeded,
      },
      'fetching',
    );

    yield fork(
      baseNotificationSaga,
      resource,
      {
        saving: get(resource, 'meta.notifications.fetchingMessages.started', 'Fetching...'),
        done: get(resource, 'meta.notifications.fetchingMessages.successful', 'Fetched'),
        error: get(resource, 'meta.notifications.fetchingMessages.failed', 'Fetching failed'),
      },
      {
        started: Actions.fetchingListResourceStarted,
        failed: Actions.fetchingListResourceFailed,
        succeeded: Actions.fetchingListResourceSucceeded,
      },
      'fetching',
    );
  }

  if (hasNotificationCondition(resource, 'patching')) {
    yield fork(
      baseNotificationSaga,
      resource,
      {
        saving: get(resource, 'meta.notifications.patchingMessages.started', 'Saving...'),
        done: get(resource, 'meta.notifications.patchingMessages.successful', 'Saved'),
        error: get(resource, 'meta.notifications.patchingMessages.failed', 'Saving failed'),
      },
      {
        started: Actions.patchingResourceStarted,
        failed: Actions.patchingResourceFailed,
        succeeded: Actions.patchingResourceSucceeded,
      },
      'patching',
    );
  }

  if (hasNotificationCondition(resource, 'deleting')) {
    yield fork(
      baseNotificationSaga,
      resource,
      {
        saving: get(resource, 'meta.notifications.deletingMessages.started', 'Deleting...'),
        done: get(resource, 'meta.notifications.deletingMessages.successful', 'Deleted'),
        error: get(resource, 'meta.notifications.deletingMessages.failed', 'Deleting failed'),
      },
      {
        started: Actions.deletingResourceStarted,
        failed: Actions.deletingResourceFailed,
        succeeded: Actions.deletingResourceSucceeded,
      },
      'deleting',
    );
  }

  if (hasNotificationCondition(resource, 'creating')) {
    yield fork(
      baseNotificationSaga,
      resource,
      {
        saving: get(resource, 'meta.notifications.creatingMessages.started', 'Creating...'),
        done: get(resource, 'meta.notifications.creatingMessages.successful', 'Created'),
        error: get(resource, 'meta.notifications.creatingMessages.failed', 'Creating failed'),
      },
      {
        started: Actions.postingResourceStarted,
        failed: Actions.postingResourceFailed,
        succeeded: Actions.postingResourceSucceeded,
      },
      'creating',
    );
  }

  if (get(resource, 'meta.notifications.actions', false)) {
    yield fork(
      baseNotificationSaga,
      resource,
      {
        saving: get(resource, 'meta.notifications.actionMessages.started', 'Performing action...'),
        done: get(resource, 'meta.notifications.actionMessages.successful', 'Action succeeded'),
        error: get(resource, 'meta.notifications.actionMessages.failed', 'Action failed'),
      },
      {
        started: Actions.resourceActionStarted,
        failed: Actions.resourceActionFailed,
        succeeded: Actions.resourceActionSucceeded,
      },
    );
  }
}

export function* ResourcesNotificationSaga(): SagaIterator {
  yield takeEvery(Actions.createNotificationSagaForResource, createNotificationSagaForResource);
}
