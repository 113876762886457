/** @format **/

import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { ProductType } from "interfaces/plan";
import { ProductPagesState } from 'sections/productpages';
import {
  apmOnTrial,
  crashOnTrial,
  getPlanIdentifier,
  rumOnTrial
} from 'app/selectors/configuration';

import { SnowplowLink } from 'components/snowplow';
import { Icon } from "components/icon";

import { BannerWrapper, IconWrapper, LinkWrapper } from './styles';

type StateProps = {
  isOnTrial: boolean;
  planIdentifier: string;
};

type PassedProps = {
  text: string;
  isCondensed?: boolean;
  alignLeft?: boolean;
  snowplowId: string;
  overlay?: boolean;
  padding?: string;
  productType?: ProductType;
};

type Props = StateProps & PassedProps;

const UnconnectedTrialBanner: FunctionComponent<Props> = props => {
  if (!props.isOnTrial) {
    return null;
  }

  return (
    <BannerWrapper isCondensed={props.isCondensed} alignLeft={props.alignLeft} overlay={props.overlay} padding={props.padding}>
      <IconWrapper>
        <Icon size={16} set={'icomoon'} type={'crown'} dsColor={'yellow700'} inline/>
      </IconWrapper>

      <div>
        {props.text} {' '}
        <SnowplowLink interactionId={props.snowplowId}>
          <LinkWrapper isCondensed={props.isCondensed}>
            <a href={`/plan/${props.planIdentifier}?editing=true`}>See upgrade options</a>
          </LinkWrapper>
        </SnowplowLink>
      </div>
    </BannerWrapper>
  );
};

const ConnectedTrialBanner = connect<StateProps, {}, PassedProps>(
  (state: ProductPagesState, passedProps) => {

    const isOnTrial = () => {
      if (!!passedProps.productType) {
        switch (passedProps.productType) {
          case ProductType.CRASH_REPORTING:
            return crashOnTrial(state);
          case ProductType.RUM:
            return rumOnTrial(state);
          case ProductType.APM:
            return apmOnTrial(state);
          default:
            return crashOnTrial(state);
        }
      } else {
        // When on a Platform Trial, crashOnTrial will always be true no matter which area of the app we're in,
        // whereas rumOnTrial and apmOnTrial will only show as true when we are in their respective areas
        return crashOnTrial(state);
      }
    };

    return ({
      isOnTrial: isOnTrial(),
      planIdentifier: getPlanIdentifier(state),
    });
  },
)(UnconnectedTrialBanner);

export { ConnectedTrialBanner as TrialBanner };