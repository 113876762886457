/** @format */
import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import { Flex } from 'components/flex';
import { Medium, Large } from 'components/breakpoints';
import { Padding } from 'components/layout';

import * as Selectors from '../../../selectors';
import { HasMultiplePlans } from '../../multiplePlans';
import { DropdownPlanSelector } from '../planSelector';
import { HeaderTitle } from './title';
import { CreateApplicationButton, CreateDashboardButton } from './components';

type StateProps = {
  forApplications: boolean;
  createApplicationLink: string;
  createDashboardLink: string;
  tooltipLabelForDisabledButton: string;
  canCreateApplicationsForPlan: boolean;
};

type DispatchProps = {};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedHeader: React.FunctionComponent<Props> = ({
  forApplications,
  createApplicationLink,
  createDashboardLink,
  tooltipLabelForDisabledButton,
  canCreateApplicationsForPlan,
}) => (
  <Padding bottom="16" largeScreens={{ bottom: '24' }} as="header">
    <Flex justify="space-between" direction="row-reverse">
      {forApplications && (
        <CreateApplicationButton
          createApplicationLink={createApplicationLink}
          tooltipLabelForDisabledButton={tooltipLabelForDisabledButton}
          enableCreateAppButton={canCreateApplicationsForPlan}
        />
      )}
      {!forApplications && <CreateDashboardButton createDashboardLink={createDashboardLink} />}
      <HasMultiplePlans>
        <Medium andBelow>
          <DropdownPlanSelector />
        </Medium>
      </HasMultiplePlans>
      <Large andAbove>
        <HeaderTitle>
          {forApplications && `Applications`}
          {!forApplications && `Dashboards`}
        </HeaderTitle>
      </Large>
    </Flex>
  </Padding>
);

const ConnectedHeader = connect<StateProps, DispatchProps, SuppliedProps>((state: AppState) => ({
  forApplications: Selectors.isApplicationSwitcher(state),
  // TODO: Move these selectors to their respective components once the revamped chrome is deployed
  // For createDashboardButton:
  createDashboardLink: Selectors.getCreateDashboardLink(state),
  // For createApplicationButton:
  createApplicationLink: Selectors.getCreateApplicationLink(state),
  canCreateApplicationsForPlan: Selectors.canCreateApplicationsForPlan(state),
  tooltipLabelForDisabledButton: Selectors.getTooltipLabelForDisabledButton(state),
}))(UnconnectedHeader);

export { ConnectedHeader as Header };
