/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { isNil } from 'lodash';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

const StyledModalClose = (): IStyle => ({
  position: 'absolute',
  right: '0',
  top: '0',
  width: '40px',
  height: '40px',
  padding: '4px',
});

export const ModalClose = createComponent(StyledModalClose);

const StyledModalHeader = (props: ThemeType): IStyle => ({
  padding: '12px 24px',
  borderBottom: `1px solid ${props.theme.colors.border.standard}`,
});

export const ModalHeader = createComponent(StyledModalHeader);

type FooterProps = {
  noBorder?: boolean;
};

const StyledModalFooter = (props: FooterProps & ThemeType): IStyle => {
  const styles = {
    padding: '24px',
    borderTop: `1px solid ${props.theme.colors.border.standard}`,
  };

  return applyModifiers([
    props.noBorder,
    {
      border: 'none',
    },
  ])(styles);
};

export const ModalFooter = createComponent<FooterProps>(StyledModalFooter);

export type ModalBodyProps = {
  noPadding?: boolean;
  noFooter?: boolean;
};

const StyledModalBody = (props: ModalBodyProps & ThemeType): IStyle => {
  const styles = {
    padding: '12px 0',
  };

  return applyModifiers([
    props.noPadding,
    {
      padding: '0',
    },
  ])(styles);
};

export const ModalBody = createComponent<ModalBodyProps>(StyledModalBody);

type BodyContentProps = {
  borderTop?: boolean;
  paddingVertical?: number;
  paddingHorizontal?: number;
  maxHeight?: number;
};

const StyledModalBodyContent = (props: BodyContentProps & ThemeType): IStyle => {
  const styles = {
    padding: `${props.paddingVertical}px ${props.paddingHorizontal}px`,
  };

  return applyModifiers(
    [
      props.borderTop,
      {
        borderTop: `1px solid ${props.theme.colors.border.light}`,
      },
    ],
    [
      !isNil(props.maxHeight),
      {
        maxHeight: `${props.maxHeight}px`,
        overflow: 'auto',
      },
    ],
  )(styles);
};

export const ModalBodyContent = createComponent<BodyContentProps>(StyledModalBodyContent);

ModalBodyContent.defaultProps = {
  paddingVertical: 12,
  paddingHorizontal: 24,
};
