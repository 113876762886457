/** @format */

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as Models from './models';
import * as Actions from './actions';
import { reducerUpdate, nestedReducerUpdate } from 'utils/reducerUpdate';
import { reject, isNil, map, sortBy } from 'lodash';
import { uuid } from 'utils/generators';
import { decodeActiveArea } from 'utils/links';
import { trackLinkClick } from 'components/snowplow';

export type ToplevelFiltersState = {
  filters: Models.Filter[];
  dimensions: Models.DimensionWithComparators[];
  values: {
    [dimensionId: string]: {
      loading: boolean;
      error: Error;
      values: Models.FilterValue[];
    };
  };
  addFilter: {
    selectedDimensionId: string;
    selectedComparatorId: string;
    currentQuery: string;
    currentMode: Models.Mode;
  };
  editFilter: {
    selectedFilterId: string;
    selectedMode: Models.Mode;
  };
  component: {
    collapse: boolean;
    showToggleButton: boolean;
    advancedSearchActive: boolean;
  };
  externalConfiguration: {
    endpoint: string;
    fromDate: string;
    toDate: string;
    applicationIdentifier: string;
    apiKey: string;
    addFilterText: string;
    noResultsText: string;
    theme: string;
    containerHeight: 32 | 48;
    supportAdvancedSearch: boolean;
  };
};

export const defaultState: ToplevelFiltersState = {
  filters: [],
  dimensions: [],
  values: {},
  addFilter: {
    currentMode: Models.Mode.Inactive,
    selectedComparatorId: null,
    selectedDimensionId: null,
    currentQuery: '',
  },
  editFilter: {
    selectedFilterId: null,
    selectedMode: null,
  },
  component: {
    collapse: false,
    showToggleButton: false,
    advancedSearchActive: false,
  },
  externalConfiguration: {
    applicationIdentifier: null,
    endpoint: null,
    fromDate: null,
    toDate: null,
    apiKey: null,
    addFilterText: 'Add filter',
    noResultsText: 'No search results',
    theme: null,
    containerHeight: 32,
    supportAdvancedSearch: false,
  },
};

const buildSnowplowTrackingId = (filter: Models.Filter) => {
  let activeArea = decodeActiveArea((window as any).location.pathname);
  activeArea = activeArea === 'crash' ? 'cr' : activeArea;

  return `sp-app-${activeArea}-toplevelfilters-${filter.dimension.id}-${filter.comparator.id}`;
};

export const ToplevelFiltersReducer = reducerWithInitialState(defaultState)
  .case(Actions.addFilter, (state, filter) => {
    trackLinkClick(buildSnowplowTrackingId(filter));
    return reducerUpdate(state, {
      filters: [...state.filters, { ...filter, id: uuid() }],
    });
  })
  .case(Actions.editFilter, (state, filter) =>
    reducerUpdate(state, {
      filters: map(state.filters, item => {
        if (item.id !== filter.id) {
          return item;
        }

        return {
          ...item,
          filter,
        };
      }),
    }),
  )
  .case(Actions.removeFilter, (state, filterId) =>
    reducerUpdate(state, { filters: reject(state.filters, filter => filter.id === filterId) }),
  )
  .case(Actions.setFilters, (state, filters) => reducerUpdate(state, { filters }))
  .case(Actions.resetFilters, state =>
    reducerUpdate(state, {
      filters: [],
      addFilter: defaultState.addFilter,
      editFilter: defaultState.editFilter,
    }),
  )
  .case(Actions.setDimensions, (state, dimensions) =>
    reducerUpdate(state, { dimensions: isNil(dimensions) ? [] : dimensions }),
  )
  .case(Actions.fetchValuesForDimensionStarted, (state, { dimension }) =>
    nestedReducerUpdate(state, 'values', {
      [dimension]: {
        values: [],
        ...state.values[dimension],
        loading: true,
        error: null,
      },
    }),
  )
  .case(Actions.fetchValuesForDimensionFailed, (state, { dimension, error }) =>
    nestedReducerUpdate(state, 'values', {
      [dimension]: {
        ...state.values[dimension],
        loading: false,
        error,
        values: [],
      },
    }),
  )
  .case(Actions.fetchValuesForDimensionSucceeded, (state, { dimension, values }) =>
    nestedReducerUpdate(state, 'values', {
      [dimension]: {
        ...state.values[dimension],
        loading: false,
        error: null,
        values: sortBy(reject(values, v => !v), [(w: Models.FilterValue) => w.displayName]),
      },
    }),
  )
  .case(Actions.addValuesForDimension, (state, { dimension, values }) =>
    nestedReducerUpdate(state, 'values', {
      [dimension]: {
        ...state.values[dimension],
        loading: false,
        error: null,
        values: sortBy(reject(values, v => !v), [(w: Models.FilterValue) => w.displayName]),
      },
    }),
  )
  .case(Actions.updateAddFilterState, (state, payload) =>
    nestedReducerUpdate(state, 'addFilter', payload),
  )
  .case(Actions.updateEditFilterState, (state, payload) =>
    nestedReducerUpdate(state, 'editFilter', payload),
  )
  .case(Actions.updateComponentState, (state, payload) =>
    nestedReducerUpdate(state, 'component', payload),
  )
  .case(Actions.updateExternalConfiguration, (state, payload) =>
    nestedReducerUpdate(state, 'externalConfiguration', payload),
  );
