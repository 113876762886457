/** @format */

import * as React from 'react';

import { Module, ModuleContent } from 'components/module';
import { CustomError } from 'components/error';

const RESOLUTIONS: React.ReactNode[] = ['Double check the URL path', 'Update your bookmark'];

export const NotFound: React.FunctionComponent<{}> = () => (
  <Module>
    <ModuleContent>
      <CustomError headline="404 not found" resolutions={RESOLUTIONS} />
    </ModuleContent>
  </Module>
);
