/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

import { Flex } from 'components/flex';
import { Models } from 'modules/dashboard';

type Props = Partial<Models.TileProps>;

const TextHeader: React.FunctionComponent<Props> = props => {
  return (
    <Flex align="center" justify="center" style={{ height: '100%' }}>
      <Text {...props.settings}>{props.settings.Title}</Text>
    </Flex>
  );
};

const StyledText = (props: Models.TextSettings): IStyle =>
  applyModifiers(
    [
      Models.isTextBold(props.TextBold),
      {
        fontWeight: 600,
      },
    ],
    [
      Models.isTextItalic(props.TextItalic),
      {
        fontStyle: 'italic',
      },
    ],
    [
      Models.isTextUnderline(props.TextUnderline),
      {
        textDecoration: 'underline',
      },
    ],
  )({
    textAlign: props.TextAlignment,
    fontSize: `${props.TextSize}`,
    color: props.TextColor,
    display: 'block',
    width: '100%',
  });

const Text = createComponent<Models.TextSettings>(StyledText, 'h2');

const TextHeaderTile: Models.TileComponent = props => <TextHeader {...props} />;

TextHeaderTile.moduleProps = {
  noPadding: false,
};

export { TextHeaderTile as TextHeader };
