/**
 * @prettier
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DashboardState } from 'sections/dashboard/index';

import { Checkbox } from 'components/checkbox/index';

import * as Actions from '../../../../actions';
import * as Selectors from '../../../../selectors';

type StateProps = {
  selected: boolean;
};

type DispatchProps = {
  toggleSelected: () => void;
};

type PassedProps = Actions.ErrorGroupListData;

type Props = StateProps & DispatchProps & PassedProps;

const UnconnectedSelectedCell: React.FunctionComponent<Props> = ({ selected, toggleSelected }) => (
  <Checkbox onToggled={toggleSelected} isChecked={selected} noPadding />
);

export const SelectedCellFactory = (tileId: string) =>
  connect<StateProps, DispatchProps, PassedProps>(
    (state: DashboardState, ownProps: PassedProps) => {
      const isSelectedSelector = Selectors.isErrorGroupSelectedFactory(tileId, ownProps.Id);
      return {
        selected: isSelectedSelector(state),
      };
    },
    (dispatch: Dispatch, ownProps: PassedProps) => ({
      toggleSelected: () =>
        dispatch(
          Actions.toggleSelectErrorGroup({
            tileId,
            errorGroupId: ownProps.Id,
            applicationId: ownProps.ApplicationId,
          }),
        ),
    }),
  )(UnconnectedSelectedCell);
