import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';

const StyledNavigationContainer = (): IStyle => ({
  display: 'block',
  width: '100%',
  height: '100%',
  position: 'relative',
  top: '0',
  left: '0',
  overflow: 'hidden'
});

export const NavigationContainer = createComponentWithProxy(StyledNavigationContainer);


type NavigationLevelProps = {
  level: number,
  currentLevel: number,
};

const StyledNavigationLevel = ({ level, currentLevel, theme }: NavigationLevelProps & ThemeType): IStyle => {
  const levelDiff = level - currentLevel;
  const transitionAmount = levelDiff * 100;
  const transform = levelDiff === -1 ? `translateX(calc(${transitionAmount}% - 1px))` : `translateX(${transitionAmount}%)`;
    
  return ({
    display: 'block',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    overflow: 'auto',
    transition: 'transform 0.4s cubic-bezier(0.26, 0.91, 0.79, 1)',
    transform: transform,
    boxShadow: `1px 0 0 ${theme.ds.getColorByName('blueGrey100')}`
  });
};

export const NavigationLevel = createComponentWithProxy<NavigationLevelProps>(StyledNavigationLevel);