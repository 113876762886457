/** @format */

import { IStyle } from 'fela';
import { createComponent, createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';

const StyledTitle = (props: ThemeType): IStyle => ({
  fontSize: '1.3rem',
  fontWeight: 600,
  lineHeight: '1.6',
  marginBottom: '4px',
  color: props.theme.ds.getColorByName('grey800'),
});
export const Title = createComponent(StyledTitle, 'h4');

const StyledLink = (props: ThemeType): IStyle => ({
  fontSize: '1.3rem',
  fontWeight: 600,
  lineHeight: '1.6',
  color: props.theme.ds.getColorByName('blue1000'),
});
export const Link = createComponent(StyledLink, 'a', ['href']);

const StyledFormSubmit = (props: ThemeType): IStyle => ({
  fontSize: '1.3rem',
  fontWeight: 600,
  lineHeight: '1.6',
  color: props.theme.ds.getColorByName('blue1000'),
  border: 'none',
  background: 'transparent',
  padding: 0,
  cursor: 'pointer',
  ':hover': {
    color: props.theme.colors.red.standard,
  },
});
export const FormSubmit = createComponentWithProxy(StyledFormSubmit, 'input');
