import React from 'react';
import { isNil } from 'lodash';
import { useEventListener } from "../useEventListener";

export function useEscapeClose(closeHandler: () => void, isOpen: boolean = true): void {
  const keydownHandler = (event: KeyboardEvent) => {
    if(isOpen && event.code === "Escape") {
      closeHandler();
    }
  };

  useEventListener("keydown", keydownHandler);
}
