/** @format **/

import React from 'react';
import { IStyle } from "fela";
import { createComponent } from 'react-fela';

type StyledDiscProps = {
  color: string;
};

const StyledKeyDisc = (props: StyledDiscProps): IStyle => ({
  borderRadius: '50%',
  width: `8px`,
  height: `8px`,
  display: 'inline-block',
  backgroundColor: props.color,
  margin: `8px 8px 8px 0`,
  float: 'left',
});

export const KeyDisc = createComponent(StyledKeyDisc);
