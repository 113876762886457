/** @format */

import * as React from 'react';
import classnames from 'classnames';
import { isNil, set } from 'lodash';

interface Props {
  button: JSX.Element;
  dockOption?: 'left' | 'right' | 'center' | 'right-mobile';
  block?: boolean;
  fullWidth?: boolean;
  closeOnClick?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
  closeOnExternalClick?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
  id?: string;
  maxWidth?: number | 'none';
  overflow?: boolean;
  autoApplyValue?: boolean;
}

interface State {
  open: boolean;
}

export class Dropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.preventClose = this.preventClose.bind(this);
  }

  state: State = {
    open: false,
  };

  static defaultProps: Partial<Props> = {
    dockOption: 'center',
    block: false,
    closeOnClick: false,
    disabled: false,
    fullWidth: false,
    closeOnExternalClick: true,
    handleOpen: () => null,
    handleClose: () => null,
  };

  stopClose: boolean = false;

  onClick(e: MouseEvent) {
    if (e.which === 1) {
      this.closeDropdown();
    }
  }

  componentDidMount() {
    if (this.props.closeOnExternalClick) {
      window.addEventListener('click', this.onClick);
    }
    if (this.props.isOpen) {
      this.setState({ open: this.props.isOpen });
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnExternalClick) {
      window.removeEventListener('click', this.onClick);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.isOpen !== this.props.isOpen) {
      this.setState({
        open: nextProps.isOpen,
      });
    }
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    if (!prevState.open && this.state.open) {
      this.props.handleOpen();
    }
  }

  preventClose() {
    if (!this.props.closeOnClick) {
      this.stopClose = true;
    }
  }

  closeDropdown() {
    if (!this.stopClose) {
      this.setState(() => {
        return {
          open: false,
        };
      });
      this.props.handleClose();
    }
    this.stopClose = false;
  }

  toggleDropdown(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    this.stopClose = true;

    if (this.props.disabled) {
      return;
    }

    this.setState(prevState => {
      return {
        open: !prevState.open,
      };
    });
  }

  render() {
    const dropdownClasses = classnames('dropdown2', {
      'dropdown2--block': this.props.block,
    });

    const contentClasses = classnames('dropdown2__content', {
      'dropdown2__content--overflow': this.props.overflow,
      'dropdown2__content--dock-left': this.props.dockOption === 'left',
      'dropdown2__content--dock-right': this.props.dockOption === 'right',
      'dropdown2__content--dock-right-mobile': this.props.dockOption === 'right-mobile',
      'dropdown2__content--open': this.state.open,
      'dropdown2__content--full-width': this.props.fullWidth,
    });

    const contentStyles = {};

    if (!isNil(this.props.maxWidth)) {
      set(contentStyles, 'maxWidth', this.props.maxWidth);
    }
    
    // Disable the dropdown from appearing when auto apply mode is enabled.
    if (this.props.autoApplyValue) {
      return (
        <div className={dropdownClasses}>
          <div className="dropdown2__button" onClick={this.toggleDropdown} id={this.props.id}>
            {this.props.button}
          </div>
        </div>
      );
    }

    return (
      <div className={dropdownClasses}>
        <div className="dropdown2__button" onClick={this.toggleDropdown} id={this.props.id}>
          {this.props.button}
        </div>
        <div className={contentClasses} onClick={this.preventClose} style={contentStyles}>
          {this.props.children}
        </div>
      </div>
    );
  }
}