/** @format */

import * as React from 'react';
import { assign } from 'lodash';
import { To } from 'redux-first-router-link';
import cx from 'classnames';

import { CellBase } from '../../tableModel';
import { LinkNode } from './linkNode';

export class LinkCell extends CellBase {
  path: (rowItem: any) => To;

  renderAsLink: (rowItem: any) => boolean;

  openNewTab: boolean;

  className: string;

  manualTitle: (rowItem: any) => string;

  constructor(init?: Partial<LinkCell>) {
    super(init);
    this.openNewTab = false;
    this.renderAsLink = () => true;
    this.manualTitle = () => null;
    assign(this, init);
  }

  render(rowItem: any) {
    const text = this.getValue(rowItem);
    const title = this.manualTitle(rowItem) || text;

    const classes = cx('text-ellipsis line-height-24');

    if (!this.renderAsLink(rowItem)) {
      return (
        <div className={classes} title={title}>
          <span className={this.className}>{text}</span>
        </div>
      );
    } else {
      return (
        <div className={classes} title={title}>
          <LinkNode
            className={this.className}
            {...{ to: this.path(rowItem), text, openNewTab: this.openNewTab }}
          />
        </div>
      );
    }
  }
}

export default LinkCell;
