/** @format */

import * as React from 'react';
import classnames from 'classnames';

export type WIDTHS =
  | 1400
  | 1920
  | 1640
  | 1240
  | 1120
  | 960
  | 916
  | 800
  | 780
  | 704
  | 640
  | 624
  | 592
  | 576
  | 440
  | 360
  | 'full'
  | 'max'
  | 'none'
  | 'full-90'
  | '100%';

interface Props {
  width?: WIDTHS;
}

export const Center: React.FunctionComponent<Props> = props => {
  const classes = classnames(`center`, {
    'center-full': props.width === 'full' || props.width === 'none',
    'center--max': props.width === 'max',
    'center--full-90': props.width === 'full-90',
    'center--max-100': props.width === '100%',
    [`center--${props.width}`]: typeof props.width === 'number',
  });

  return <div className={classes}>{props.children}</div>;
};

Center.defaultProps = {
  width: 'max',
};
