/** @format **/

export enum DateRange {
  OneDay,
  SevenDays,
  ThirtyDays,
}

export enum Granularities {
  Minute = 'Minute',
  TenMinute = 'TenMinute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
}

export type DateFormat = 'time-ago' | 'date' | 'date-time' | 'custom' | 'duration';
export type Intervals = '1m' | '10m' | '1h' | '1d' | '1w';
export type DateRangeParam = '1d' | '7d' | '30d';

export function isDateRange(input: any): input is DateRange {
  if (typeof input === 'number') {
    return true;
  }

  return false;
}
