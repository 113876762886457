/** @format **/

import React from 'react';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';

import { screenMediumAndAbove, screenSmallOnly } from 'fela-rules/breakpoints';

const StyledPaywallContainer = (): IStyle => ({
  boxShadow: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.25)',
  backgroundColor: 'white',
  padding: '7rem 10.4rem',
  borderRadius: '4px',
  width: '572px',
  marginLeft: 'auto',
  marginRight: 'auto',

  ...screenSmallOnly({
    width: '100%',
    minWidth: '296px',
    paddingLeft: '2.4rem',
    paddingRight: '2.4rem',
  }),
});

export const PaywallContainer = createComponent(StyledPaywallContainer);

export const PaywallWrapper: React.FunctionComponent<{}> = ({ children }) => (
  <PaywallContainer>{children}</PaywallContainer>
);
