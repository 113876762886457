/** @format */
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'interfaces/appState';
import { UtilityIconButton } from 'components/ds/button';

import { closeSwitcher } from '../../../actions';

type StateProps = {};

type DispatchProps = {
  closeSwitcher: () => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedCloseSwitcher: React.FunctionComponent<Props> = ({ closeSwitcher }) => (
  <UtilityIconButton icon="cross" onClick={closeSwitcher} aria-label="Close switcher" />
);

const ConnectedCloseSwitcher = connect<StateProps, DispatchProps, SuppliedProps, AppState>(
  null,
  (dispatch) => ({
    closeSwitcher: () => dispatch(closeSwitcher())
  })
)(UnconnectedCloseSwitcher);

export { ConnectedCloseSwitcher as CloseSwitcher };
