/** @format **/

import React, { Component } from 'react';
import { createComponent, createComponentWithProxy } from 'react-fela';
import { IStyle } from 'fela';
import { omit } from 'lodash';

import { applyModifiers } from 'fela-rules/modifiers';
import { ThemeType } from 'app/theme';
import { COLORS } from 'app/theme/materialDS';

import { Icon } from 'components/icon';
import { Flex } from 'components/flex';

import { InteractionCircleAfterStyles } from '../shared/interactionCircle';

export type PublicProps = {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  disabled: boolean;

  isRadioButton: boolean;
  forMenuItem: boolean;
  uncheckedIcon: string;
  checkedIcon: string;

  enabledColor: COLORS;
  enabledSelectedColor: COLORS;
  disabledColor: COLORS;
  disabledSelectedColor: COLORS;
  controlId?: string;
  checkboxId?: string;
  forForm?: boolean;
  tabIndex?: number;
  adjustLeft?: boolean;
  alignTop?: boolean;
};

type SelectionWrapperProps = Pick<
  PublicProps,
  | 'checked'
  | 'disabled'
  | 'forMenuItem'
  | 'enabledColor'
  | 'enabledSelectedColor'
  | 'disabledColor'
  | 'disabledSelectedColor'
  | 'adjustLeft'
  | 'alignTop'
>;

const StyledSelectionWrapper = (props: SelectionWrapperProps & ThemeType): IStyle => {
  return applyModifiers(
    [
      props.disabled,
      {
        cursor: 'not-allowed',
      },
    ],
    [
      props.adjustLeft,
      {
        left: '-10px',
      },
    ],
  )({
    display: 'block',
    cursor: 'pointer',

    position: 'relative',
    zIndex: 2,
  });
};

const SelectionWrapper = createComponentWithProxy(StyledSelectionWrapper, 'label');

type IconContainerProps = SelectionWrapperProps & ThemeType;
function StyledIconContainer(props: IconContainerProps) {
  return applyModifiers([
    !props.forMenuItem,
    InteractionCircleAfterStyles({
      theme: props.theme,
      disabled: props.disabled,
      hoverHighlightColor: 'blue1700',
      pressedHighlightColor: 'blue1700',
      hoverInteractiveOpacity: 0.14,
      activeInteractiveOpacity: 0.2,
    }),
  ])({
    position: 'relative',
    height: '40px',
    width: '40px',
    padding: '10px',
    ['> div > .raygun-icon' as any]: applyModifiers(
      [
        props.disabled && props.checked,
        {
          color: props.theme.ds.getColorByName(props.disabledSelectedColor),
        },
      ],
      [
        props.disabled && !props.checked,
        {
          color: props.theme.ds.getColorByName(props.disabledColor),
        },
      ],
      [
        !props.disabled && props.checked,
        {
          color: props.theme.ds.getColorByName(props.enabledSelectedColor),
        },
      ],
      [
        !props.disabled && !props.checked,
        {
          color: props.theme.ds.getColorByName(props.enabledColor),
        },
      ],
    )({}),
  });
}
const IconContainer = createComponent(StyledIconContainer);

const IconBoundingBox = createComponent({
  height: '20px',
  width: '20px',
  padding: '2px',
  position: 'relative',
  zIndex: 2,
});

export type BaseDefaultPropsType = Pick<PublicProps, 'isRadioButton' | 'disabled' | 'forMenuItem'>;

export const BaseDefaultProps: BaseDefaultPropsType = {
  isRadioButton: false,
  disabled: false,
  forMenuItem: false,
};

class BaseSelectionControl extends Component<PublicProps, {}> {
  checkboxInput: HTMLInputElement;

  static defaultProps: BaseDefaultPropsType = BaseDefaultProps;

  render() {
    const {
      checked,
      onChange,
      isRadioButton,
      uncheckedIcon,
      checkedIcon,
      forMenuItem,
      forForm,
      alignTop,
      ...props
    } = this.props;

    return (
      <SelectionWrapper
        {...(omit(props, [
          'checkboxId',
          'controlId',
          'enabledColor',
          'enabledSelectedColor',
          'disabledColor',
          'disabledSelectedColor',
        ]) as any)}
        checked={checked}
        forMenuItem={forMenuItem}
        as={forMenuItem ? 'div' : 'label'}
        id={props.controlId}
      >
        <Flex align={alignTop ? 'top' : 'center'}>
          <IconContainer {...this.props}>
            <IconBoundingBox>
              <Icon set="icomoon" type={checked ? checkedIcon : uncheckedIcon} />
            </IconBoundingBox>
          </IconContainer>

          <input
            type={isRadioButton ? 'radio' : 'checkbox'}
            checked={checked}
            onChange={onChange}
            disabled={props.disabled}
            ref={r => (this.checkboxInput = r)}
            style={{ display: 'none', userSelect: 'none' }}
            id={props.checkboxId}
            name={forForm ? props.name : null}
            value={forForm ? checked.toString() : null}
            tabIndex={props.tabIndex}
          />

          {alignTop ? (
            <div
              style={{
                position: 'relative',
                top: '8px',
              }}
            >
              {this.props.children}
            </div>
          ) : (
            this.props.children
          )}
        </Flex>
      </SelectionWrapper>
    );
  }
}

export { BaseSelectionControl };
