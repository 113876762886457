/**
 * @prettier
 */

import React, { FunctionComponent } from 'react';
import { TextIconText, TextIconContainer } from './components';
import { Theme } from 'app/theme';
import { Flex } from 'components/flex';
import { Margin } from 'components/layout';
import { Icon } from 'components/icon';
import { FelaInjectedProps } from 'react-fela';

/**
 * Generates a 2 character text based on a series of rules.
 * @param planName, name of the plan that needs to be reduced
 * @param defaultValue, default value to be returned. Used in the case where foreign language is used as a plan name.
 * @return reduced 2 char text. English plan name (Test .Co) output = TC. Foreign output = P + planNumber i.e P1
 */
export function generateTwoCharacterIconText(planName: string, defaultValue: string): string {
  // Replace all symbols with spaces, this also accounts for non-english characters
  planName = planName.replace(/[^a-zA-Z0-9\s]/g, '');

  if(planName.trim().length === 0) {
    return defaultValue;
  }

  const sections = planName.trim().split(' ');
  let iconText = '';

  // Single section = first 2 chars or single letter if only 1 char in section
  if(sections.length === 1) {
    iconText = sections[0].substr(0, 2);
  } else {
    // 2+ sections = First char of the first and last section
    iconText = sections[0].charAt(0) + sections[sections.length - 1].charAt(0);
  }

  return iconText.toUpperCase();
}

const avatarColors = [
  Theme.ds.colors.amber.amber700,
  Theme.ds.colors.amber.amber900,
  Theme.ds.colors.blueGrey.blueGrey700,
  Theme.ds.colors.purple.purple400,
  Theme.ds.colors.blue.blue1000,
  Theme.ds.colors.blue.blue1200,
  Theme.ds.colors.teal.teal500,
];

export function getPlanColor(id: number): string {
  const index = id % avatarColors.length;
  return avatarColors[index];
}

type Props = {
  name: string;
  identifier: number;
  big?: boolean;
  clickable?: boolean;
  planNumber?: number;
  forDropdownTrigger?: boolean;
};

type PlanAvatarProps = Props & FelaInjectedProps<Props> & React.HTMLProps<HTMLDivElement>;

export const PlanAvatar: FunctionComponent<PlanAvatarProps> = ({ name, identifier, planNumber, big, clickable, forDropdownTrigger, ...otherProps }) => {
  const iconText = generateTwoCharacterIconText(name, `P${planNumber}`);

  return (
    <TextIconContainer big={big} clickable={clickable} forDropdownTrigger={forDropdownTrigger} {...otherProps}>
      <TextIconText title={name} big={big} backgroundColor={getPlanColor(identifier)}>
        {iconText}
      </TextIconText>
      {forDropdownTrigger && <Icon set="icomoon" type="dropdown" />}
    </TextIconContainer>
  );
};

PlanAvatar.defaultProps = {
  big: true,
  clickable: false,
  forDropdownTrigger: false,
  planNumber: 1
};
