/** @format **/

import { createSelector } from 'reselect';

import { Filter, ServerTopLevelFilter } from 'legacy/components/topLevelFilters/models';
import { getFilters } from 'legacy/components/topLevelFilters/selectors';

export const getTopLevelFilterQueryString = createSelector(
  getFilters,
  (topLevelFilters: Filter[]): { topLevelFilters?: string } => {
    if (!topLevelFilters) {
      return {};
    }

    const filtered: ServerTopLevelFilter[] = topLevelFilters.map(t => ({
      Dimension: {
        Id: t.dimension.id,
        DisplayName: t.dimension.displayName,
        IsBoolean: t.dimension.isBoolean,
      },
      Comparator: {
        Id: t.comparator.id,
        DisplayName: t.comparator.displayName,
        MultiSelect: t.comparator.multiSelect,
        AllowFreeText: t.comparator.allowFreeText,
      },
      Values: t.values.map(v => v.id),
    }));

    const json = JSON.stringify(filtered);

    return {
      topLevelFilters: json,
    };
  },
);
