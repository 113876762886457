/** @format */

import { isIntegrationTest, isLocal } from './host';

const isEndToEndEnviroment = isIntegrationTest || isLocal;

export function addE2EIdentifier(
  classes: string,
  displayCondition: boolean = isEndToEndEnviroment,
): string {
  return displayCondition ? classes : '';
}
