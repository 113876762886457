/** @format */

import * as React from 'react';
import classnames from 'classnames';

interface Props {
  border?: boolean;
  color?: 'grey' | null;
  padding?: '12' | 'top-8' | null;
}

export const ModuleFooter: React.FunctionComponent<Props> = props => {
  const classes = classnames('module__footer clearfix', {
    'module__footer--border': props.border,
    'module__footer--tight': props.padding === '12',
    'module__footer--padding-top-8': props.padding === 'top-8',
    'module__footer--grey': props.color === 'grey',
  });

  return <div className={classes}>{props.children}</div>;
};

ModuleFooter.defaultProps = {
  border: false,
  color: null,
  padding: null,
};

export default ModuleFooter;
