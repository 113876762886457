/**
 * @prettier
 */

import * as React from 'react';

import { Padding } from 'components/layout';
import { Text } from 'components/text';
import { Flex } from 'components/flex';

export const renderTitle = (title: string) => {
  if (!title) {
    return null;
  }

  return (
    <Padding bottom="8">
      <Text height="24">
        <strong>{title}</strong>
      </Text>
    </Padding>
  );
};

type Props = {
  title: string;
};

export const TitleWithButton: React.FunctionComponent<Props> = props => (
  <Padding bottom="8">
    <Flex align="center" justify="space-between">
      <Text height="24">
        <strong>{props.title}</strong>
      </Text>
      <div>{props.children}</div>
    </Flex>
  </Padding>
);
