/** @format */

import * as React from 'react';
import classnames from 'classnames';

interface Props {
  color: 'blue' | 'green' | 'yellow' | 'red' | 'grey';
  smallPadding?: boolean;
}

export const ModuleMessage: React.FunctionComponent<Props> = props => {
  const classes = classnames('module__message', {
    'module__message--padding-vertical-8': props.smallPadding,
    'module__message--green': props.color === 'green',
    'module__message--yellow': props.color === 'yellow',
    'module__message--blue': props.color === 'blue',
    'module__message--red': props.color === 'red',
    'module__message--grey': props.color === 'grey',
  });

  return <div className={classes}>{props.children}</div>;
};

export default ModuleMessage;
