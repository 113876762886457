/** @format */

import * as React from 'react';
import { assign, isString } from 'lodash';

import { Formatting } from 'utils/date';
import { Components } from 'modules/time';

import { CellBase } from '../tableModel';

export class DateCell extends CellBase {
  constructor(init?: Partial<DateCell>) {
    super(init);
    assign(this, init);
  }

  render(rowItem: any) {
    const value = this.getValue(rowItem) as Date;
    const utcTime = Formatting.legacyFormatLongDateTime(value);

    return (
      <time
        className="text-ellipsis line-height-24"
        title={utcTime}
        dateTime={isString(value) ? value : value.toISOString()}
      >
        <Components.DateFormatter format={'date-time'}>{value}</Components.DateFormatter>
      </time>
    );
  }
}
