/** @format **/

import React from 'react';
import { get } from 'lodash';

import { Omit } from 'utils/types';

import {
  apmUrl,
  crashUrl,
  dashboardUrl,
  deploymentsUrl,
  integrationsUrl,
  inviteTeamUrl,
  parseRgUserdata,
  rumUrl,
  usersUrl,
  whatsNewUrl,
  decodeActiveArea,
  findApplicationId,
  getLastViewedApplicationIdentifier,
} from 'utils/links';
import { getDefaultPlanId } from 'utils/plans';

import { Link, MobileLink, MobileLinkProps, Icon } from '../link';

import { ActiveUser } from './activeUser';
import { InviteTeam } from './inviteTeam';

type LinkItem = () => React.ReactElement;

const LastViewedAppIdentifier = getLastViewedApplicationIdentifier();
const planIdentifier = getDefaultPlanId().toString(36);
const applicationIdentifier = findApplicationId(
  (window as any).RaygunConfiguration,
  LastViewedAppIdentifier,
);
const activeProduct = decodeActiveArea(window.location.pathname);

export const TopListLinks: LinkItem[] = [
  () => (
    <Link
      label="Dashboards"
      active={activeProduct === 'dashboards'}
      svgSymbol="dashboards"
      url={dashboardUrl(planIdentifier)}
    />
  ),
  () => (
    <Link
      label="Crash Reporting"
      active={activeProduct === 'crash'}
      svgSymbol="crash"
      url={crashUrl(applicationIdentifier)}
    />
  ),
  () => (
    <Link
      label="Real User Monitoring"
      active={activeProduct === 'rum'}
      svgSymbol="rum"
      url={rumUrl(applicationIdentifier)}
    />
  ),
  () => (
    <Link
      label="APM"
      active={activeProduct === 'apm'}
      svgSymbol="apm"
      url={apmUrl(applicationIdentifier)}
    />
  ),
  () => (
    <Link
      label="Deployments"
      active={activeProduct === 'deployments'}
      svgSymbol="deployment"
      url={deploymentsUrl(applicationIdentifier)}
    />
  ),
  () => (
    <Link
      label="Customers"
      active={activeProduct === 'users'}
      svgSymbol="users"
      url={usersUrl(applicationIdentifier)}
    />
  ),
  () => (
    <Link
      label="Integrations"
      active={activeProduct === 'integrations'}
      svgSymbol="integrations"
      url={integrationsUrl(applicationIdentifier)}
    />
  ),
];

export const BottomListLinks: LinkItem[] = [
  () => <InviteTeam />,
  () => (
    <Link
      label="What's new"
      active={activeProduct === 'whatsNew'}
      svgSymbol="whatsnew"
      url={whatsNewUrl(applicationIdentifier)}
    />
  ),
  () => <ActiveUser />,
];

type MLinkProps = Omit<MobileLinkProps, 'icon'> & {
  svgSymbol: string;
  href: string;
};

const MLink: React.FunctionComponent<MLinkProps> = ({ svgSymbol, ...props }) => (
  <MobileLink icon={<Icon iconName={svgSymbol} big={false} active={props.active} />} {...props} />
);

export const MobileListLinks = [
  () => (
    <MLink
      label="Dashboards"
      active={activeProduct === 'dashboards'}
      svgSymbol="dashboards"
      href={dashboardUrl(planIdentifier)}
    />
  ),
  () => (
    <MLink
      label="Crash Reporting"
      active={activeProduct === 'crash'}
      svgSymbol="crash"
      href={crashUrl(applicationIdentifier)}
    />
  ),
  () => (
    <MLink
      label="Real User Monitoring"
      active={activeProduct === 'rum'}
      svgSymbol="rum"
      href={rumUrl(applicationIdentifier)}
    />
  ),
  () => (
    <MLink
      label="APM"
      active={activeProduct === 'apm'}
      svgSymbol="apm"
      href={apmUrl(applicationIdentifier)}
    />
  ),
  () => (
    <MLink
      label="Deployments"
      active={activeProduct === 'deployments'}
      svgSymbol="deployment"
      href={deploymentsUrl(applicationIdentifier)}
    />
  ),
  () => (
    <MLink
      label="Users"
      active={activeProduct === 'users'}
      svgSymbol="users"
      href={usersUrl(applicationIdentifier)}
    />
  ),
  () => (
    <MLink
      label="Integrations"
      active={activeProduct === 'integrations'}
      svgSymbol="integrations"
      href={integrationsUrl(applicationIdentifier)}
    />
  ),
  () => (
    <MLink
      label="Create team"
      active={activeProduct === 'teams'}
      svgSymbol="teams"
      href={inviteTeamUrl(applicationIdentifier)}
    />
  ),
  () => (
    <MLink
      label="What's new"
      active={activeProduct === 'whatsNew'}
      svgSymbol="whatsnew"
      href={whatsNewUrl(applicationIdentifier)}
    />
  ),
];

const LinkItemHeight = 40;

export const TotalListHeight = (1 + TopListLinks.length + BottomListLinks.length) * LinkItemHeight;
