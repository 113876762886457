/**
 * @prettier
 */

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { isNil } from 'lodash';

type Props = {
  children?: string;
  splitOn?: string;
};

const StyledContainer = (): IStyle => ({
  display: 'flex',
});

const StyledEllipseText = (): IStyle => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledTruncatedText = (): IStyle => ({
  minWidth: '0',
  display: 'flex',
});

const StyledPreservedText = (): IStyle => ({
  whiteSpace: 'nowrap',
});

const Container = createComponent(StyledContainer, 'span');
const EllipseText = createComponent(StyledEllipseText, 'span');
const TruncatedText = createComponent(StyledTruncatedText, 'span');
const PreservedText = createComponent(StyledPreservedText, 'span');

export const FlexEllipse: React.FunctionComponent<Props> = (props: Props) => {
  if (isNil(props.children) || props.children.indexOf(props.splitOn) === -1) {
    return <span>{props.children}</span>;
  }

  const parts = props.children.split(props.splitOn);

  const preservedText = parts.pop();

  return (
    <Container>
      <TruncatedText>
        <EllipseText>
          {parts.join(props.splitOn)}
          {props.splitOn}
        </EllipseText>
      </TruncatedText>
      <PreservedText>{preservedText}</PreservedText>
    </Container>
  );
};

FlexEllipse.defaultProps = {
  splitOn: '.',
};
