/** @format **/

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { map, filter, some, every } from 'lodash';

import { openModal } from 'components/modal';

import { getTilesByTileCategory } from '../../constants';
import { TileDefinition } from '../../models';
import { tileSetting } from '../../actions';

import { AddTileModal } from '../addTileModal';
import { AddTile, Props as AddTileProps } from './component';
import { ProductPagesState } from 'sections/productpages';
import { getApplications, getActivePlan } from 'app/selectors/application';

type StateProps = {
  tiles: AddTileProps['tiles'];
};
type DispatchProps = {
  onTileAdded: AddTileProps['onTileAdded'];
};
type SuppliedProps = {
  type: AddTileProps['type'];
};

function mapTilesToExpectedSubset(tiles: TileDefinition[]): AddTileProps['tiles'] {
  return map(tiles, t => ({
    name: t.name,
    id: t.id,
    icon: t.icon,
    products: t.products,
  }));
}

const ConnectedAddTile = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: ProductPagesState, ownProps: SuppliedProps) => {
    const applications = getApplications(state);
    const plan = getActivePlan(state);
    const tilesForType = getTilesByTileCategory(ownProps.type);
    const tilesFilteredByRequirements = filter(tilesForType, tile =>
      some(applications, app => every(tile.requirements, req => req(app, plan))),
    );

    return {
      tiles: mapTilesToExpectedSubset(tilesFilteredByRequirements),
    };
  },
  (dispatch: Dispatch) => ({
    onTileAdded: tileId => {
      dispatch(tileSetting.setTileId(tileId));
      dispatch(openModal(AddTileModal));
    },
  }),
)(AddTile);

export { ConnectedAddTile as AddTileButton };
