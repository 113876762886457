/** @format **/

interface SortById {
  id: number;
}

export const sortById = (a: SortById, b: SortById) => {
  return a.id < b.id ? 1 : -1;
};

interface SortByName {
  name: string;
}

export const sortByName = (a: SortByName, b: SortByName) => {
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
};
