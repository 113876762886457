/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isNil, omit } from 'lodash';
import RouterLink, { Props as LinkProps } from 'redux-first-router-link';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { Theme } from 'app/theme';
import { DateTimeFilterProps, getDateTimeRaw } from 'selectors/router';
import { AppState } from 'interfaces/appState';

type StateProps = DateTimeFilterProps;

type SuppliedProps = {
  includeDateTimeFilter?: boolean;
  linkBlock?: boolean;
  title?: string;
} & LinkProps;

type Props = StateProps & SuppliedProps;

type StyledRouterLinkWrapperProps = {
  linkBlock?: boolean;
};

const StyledRouterLinkWrapper = (props: StyledRouterLinkWrapperProps): IStyle => ({
  ['> a' as any]: {
    display: props.linkBlock && 'block',
    ':hover': {
      color: `${Theme.ds.getColorByName('blue1500')}`,
    },
  },
});
export const RouterLinkWrapper = createComponent(StyledRouterLinkWrapper);

// TODO: Crazy errors when props isn't any, something to do with Refs
const UnconnectedLink: React.FunctionComponent<Props> = props => {
  const passableProps: any = omit(props, 'range', 'dateFrom', 'dateTo', 'includeDateTimeFilter');

  if (props.includeDateTimeFilter) {
    const to: any = cloneDeep(props.to);

    if (typeof to === 'object') {
      const meta = to.meta || {};
      const query = meta.query || {};

      to.meta = {
        ...meta,
        query: {
          range: props.range,
          dateFrom: props.dateFrom,
          dateTo: props.dateTo,
          ...query,
        },
      };
    }

    passableProps.to = to;
  }

  return (
    <RouterLinkWrapper linkBlock={props.linkBlock}>
      <RouterLink {...passableProps} />
    </RouterLinkWrapper>
  );
};

const ConnectedLink = connect<StateProps, {}, SuppliedProps>((state: AppState) => ({
  ...getDateTimeRaw(state),
}))(UnconnectedLink);

export class Link extends React.Component<SuppliedProps, {}> {
  static defaultProps: Partial<SuppliedProps> = {
    includeDateTimeFilter: true,
  };
  static contextTypes = {
    store: (): any => null,
  };

  render() {
    // The latest @types/react don't seem to support this type of context using
    // But as far as I know it's the only way to consume the redux context so we need to use it
    if (isNil((this as any).context.store)) {
      return <a href="javascript: void(0);">{this.props.children}</a>;
    }

    return <ConnectedLink {...(this.props as any)}>{this.props.children}</ConnectedLink>;
  }
}
