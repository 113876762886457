/** @format **/

import { IStyle } from 'fela';
import { isNil } from 'lodash';
import { createComponentWithProxy } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

export type TrialBannerProps = {
  isCondensed?: boolean;
  alignLeft?: boolean;
  overlay?: boolean;
  padding?: string;
};

const StyledBannerWrapper = (props: TrialBannerProps): IStyle => applyModifiers(
  [
    props.alignLeft,
    {
      justifyContent: 'left'
    }
  ],
  [
    props.overlay,
    {
      position: 'relative',
      zIndex: 4,
    }
  ],
)({
  backgroundColor: '#E8F5FF',
  padding: !isNil(props.padding) ? props.padding : '9px 24px',
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
  lineHeight: 1.7143
});
export const BannerWrapper = createComponentWithProxy(StyledBannerWrapper);

const StyledIconWrapper = (): IStyle => ({
  display: 'inline-block',
});
export const IconWrapper = createComponentWithProxy(StyledIconWrapper);

const StyledLinkWrapper= (props: TrialBannerProps): IStyle =>
  applyModifiers(
    [
      props.isCondensed,
      {
        paddingTop: '4px'
      }
    ],
  )({
  display: 'inline-block'
});
export const LinkWrapper = createComponentWithProxy(StyledLinkWrapper);