/** @format */

import * as React from 'react';
import { get } from 'lodash';

import * as charting from 'utils/echarts/index';
import { translateCountryCodeToName } from 'utils/translateCountryCode';
import * as Bucketing from 'utils/realusermonitoring/mapBucketing';
import { formatMillisecondsAsMultiPartDuration } from 'utils/durationFormatting';
import { LoadTimeDistributionPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { GeoDistributionTile } from './geoDistributionTile';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';

class Chart extends React.Component<ProviderProps, {}> {
  tooltipFormatter(params: any) {
    return charting.renderSeriesTooltip({
      series: [
        {
          name: params.name as string,
          value: !params.value
            ? 'No data available'
            : formatMillisecondsAsMultiPartDuration(params.value),
          colorcode: Bucketing.timeToolTipColorFunction(params.value),
        },
      ],
    });
  }

  render() {
    const data = get(this.props, 'data', []) as LoadTimeDistributionPayload[];
    const countries = data.reduce(
      (acc, { AverageTime, CountryCode }) => [
        ...acc,
        { name: translateCountryCodeToName(CountryCode), value: AverageTime },
      ],
      [] as { name: string; value: number }[],
    );

    return (
      <GeoDistributionTile
        {...this.props}
        countries={countries}
        bucketingPieces={Bucketing.timePieceBucketing()}
        legendLabels={{ higher: 'Fast', lower: 'Slow' }}
        tooltipFormatter={this.tooltipFormatter}
      />
    );
  }
}
const LoadTimeDistributionMap: React.FunctionComponent<ProviderProps> = props => (
  <Chart {...props} />
);

const ConnectedTile = TileDataProvider(LoadTimeDistributionMap, ChartTileLoading);

export { ConnectedTile as LoadTimeDistributionMap };
