/** @format */

import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';

export type SeriesTooltipProps = {
  date?: string;
  series: {
    name: string;
    value: string;
    color?: string;
    colorcode?: string;
    icon?: boolean;
  }[];
};
const SeriesTooltip: React.FunctionComponent<SeriesTooltipProps> = ({ date, series }) => {
  const rows = series.map((s, idx) => (
    <tr className="latency-info__table__row" key={idx}>
      <td className="latency-info__table__cell">
        <div className="latency-info-tag-wrapper latency-info-tag-wrapper--no-wrap">
          {s.color ? <span className={`chart-tag tag-${s.color}`} /> : null}
          {s.colorcode ? <span className="chart-tag" style={{ background: s.colorcode }} /> : null}
          {s.icon ? 'icon' : null}
          {s.name}
        </div>
      </td>
      <td className="latency-info__table__cell text-right">{s.value}</td>
    </tr>
  ));

  return (
    <div className="latency-info modal-effect">
      {date && <div style={{ marginBottom: '4px' }}>{date}</div>}
      <table className="latency-info__table">
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export function renderSeriesTooltip(opts: SeriesTooltipProps) {
  return ReactDOMServer.renderToStaticMarkup(<SeriesTooltip {...opts} />);
}

type GenericTooltipProps = {
  value: string | number;
};
const GenericTooltip: React.FunctionComponent<GenericTooltipProps> = ({ value }) => (
  <div className="generic-tooltip modal-effect">
    <div>{value}</div>
  </div>
);

export function renderTooltip(value: string | number) {
  return ReactDOMServer.renderToStaticMarkup(<GenericTooltip value={value} />);
}
