/** @format **/

import React, { useEffect, useRef } from 'react';

import { EventStyle } from './models';

type AddRemoveHandler = {
  addEventListener: DocumentAndElementEventHandlers['addEventListener'];
  removeEventListener: DocumentAndElementEventHandlers['removeEventListener'];
};

type OnOffHandler = {
  on: (event: any) => any;
  off: (event: any) => any;
};

type Listenable = AddRemoveHandler | OnOffHandler;

export function useEventListener(
  eventName: string,
  handler: (e: any) => void,
  eventStyle = EventStyle.EventListener,
  element: Listenable = document,
) {
  // Create a ref that stores handler
  const savedHandler = useRef<typeof handler>();
  const addMethod = eventStyle === EventStyle.EventListener ? 'addEventListener' : 'on';
  const removeMethod = eventStyle === EventStyle.EventListener ? 'removeEventListener' : 'off';

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Make sure element supports the addMethod
    const isSupported = element && (element as any)[addMethod];
    if (!isSupported) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: any) => savedHandler.current(event);

    // Add event listener
    (element as any)[addMethod](eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      (element as any)[removeMethod](eventName, eventListener);
    };
  }, [eventName, element]); // Re-run if eventName or element changes
}
