/**
 * @prettier
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from 'interfaces/appState';

import { Large } from 'components/breakpoints';

import * as Actions from 'modules/whatsnew/actions';
import * as Selectors from 'modules/whatsnew/selectors';

import { WhatsNewAdvert } from './whatsNewAd';

type StateProps = {
  bannerImgSrc: string;
  bannerImgAlt: string;
  bannerLink: string;
  bannerEnabled: boolean;
};

type DispatchProps = {
  fetchWhatsNewBanner: () => void;
};

type Props = StateProps & DispatchProps;

const UnconnectedWhatsNewAdContainer: React.FunctionComponent<Props> = ({
  fetchWhatsNewBanner,
  ...props
}) => {
  React.useEffect(() => fetchWhatsNewBanner(), []);

  return <WhatsNewAdvert {...props} />;
};

const ConnectedWhatsNewBannerContainer = connect<StateProps, DispatchProps, {}, AppState>(
  state => ({
    bannerImgSrc: Selectors.getWhatsNewAdImgSrc(state),
    bannerImgAlt: Selectors.getWhatsNewAdImgAlt(state),
    bannerLink: Selectors.getWhatsNewAdLink(state),
    bannerEnabled: Selectors.getWhatsNewAdEnabled(state),
  }),
  (dispatch: Dispatch) => ({
    fetchWhatsNewBanner: () => dispatch(Actions.fetchWhatsNewBanner()),
  }),
)(UnconnectedWhatsNewAdContainer);

export { ConnectedWhatsNewBannerContainer as WhatsNewAd };
