/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent, createComponentWithProxy } from 'react-fela';
import { isNil } from 'lodash';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { Switch } from 'components/ds/selection';
import { Icon } from 'components/icon';

import { Helptip, HelptipProps } from '../../../tooltip';

type ContainerProps = {
  checkbox?: boolean;
  icon?: boolean;
  withSwitch?: boolean;
  selected?: boolean;
  disabled?: boolean;
  spacing?: boolean;
};

const StyledMenuItemContainer = (props: ContainerProps & ThemeType): IStyle =>
  applyModifiers(
    [
      props.spacing && !props.checkbox && !props.icon,
      {
        paddingLeft: '16px',
      },
    ],
    [
      props.icon,
      {
        paddingLeft: 0,
      },
    ],
    [
      props.selected,
      {
        backgroundColor: props.theme.ds.getColorByName('blue100'),
      },
    ],
    [
      props.withSwitch,
      {
        paddingRight: '48px',
        position: 'relative',
      },
    ],
    [
      props.disabled,
      {
        color: props.theme.ds.getColorByName('grey400'),
        cursor: 'not-allowed',
        ':hover': {
          backgroundColor: 'none',
        },
        ':focus': {
          backgroundColor: 'none',
        },
        ':active': {
          backgroundColor: 'none',
        },
      },
    ],
  )({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: '8px',
    paddingRight: '16px',
    minHeight: '40px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#E8F5FF',
    },
    ':focus': {
      backgroundColor: props.theme.ds.getColorByName('blueGrey100'),
    },
  });

const MenuItemContainer = createComponentWithProxy(StyledMenuItemContainer, 'div');

type MenuItemLabel = {
  fontWeight?: number;
  textEllipsis?: boolean;
  disabled?: boolean;
};

const StyledMenuItemLabel = (props: MenuItemLabel & ThemeType): IStyle =>
  applyModifiers(
    [
      !!props.fontWeight,
      {
        fontWeight: props.fontWeight,
      },
    ],
    [
      !!props.textEllipsis,
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    ],
    [
      props.disabled,
      {
        color: props.theme.ds.getColorByName('grey400'),
        cursor: 'not-allowed',
        ':hover': {
          color: 'none',
        },
        ':active': {
          color: 'none',
        },
      },
    ],
  )({
    lineHeight: '20px',
    padding: '10px 0',
    display: 'block',
    color: '#333',
    width: '100%',
    ':hover': {
      color: props.theme.ds.getColorByName('grey800'),
    },
    ':active': {
      color: props.theme.ds.getColorByName('grey800'),
    },
  });

const MenuItemLabel = createComponent(StyledMenuItemLabel, 'span');

type MenuItemProps = {
  label: string;
  labelFontWeight?: number;
  value?: any;
  checkbox?: boolean;
  icon?: boolean;
  textEllipsis?: boolean;
  disabled?: boolean;
  helpTipOptions?: HelptipProps;
  rightIcon?: boolean;
  selected?: boolean;
  spacing?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  label,
  labelFontWeight,
  value,
  onClick,
  textEllipsis,
  children,
  disabled,
  helpTipOptions,
  rightIcon,
  checkbox,
  selected,
  spacing,
  ...baseProps
}) => {
  const menuItem = (
    <>
      {children}
      <MenuItemLabel disabled={disabled} fontWeight={labelFontWeight} textEllipsis={textEllipsis}>
        {label}
      </MenuItemLabel>
    </>
  );

  return (
    <MenuItemContainer
      onClick={disabled ? null : onClick}
      disabled={disabled}
      selected={selected}
      checkbox={checkbox}
      spacing={spacing}
      {...baseProps}
    >
      {/*
        We want to display the helptip if - The helptip exists and the menu item is disabled.
        Otherwise, if only the right icon exists we want to display just the right icon.
        Otherwise, just return a regular menu item look.

        TODO: Be able to pass any icon instead of hard coding the icon (for now, we can safely assume that the crown
        icon is only ever render on the right.
      */}

      {disabled && !isNil(helpTipOptions) ? (
        <Helptip {...helpTipOptions} showOnRight alignCenter fullWidthTrigger>
          {menuItem}
        </Helptip>
      ) : (!!rightIcon) ? (
        <>{menuItem} <Icon size={16} set={'icomoon'} type={'crown'} dsColor={'yellow700'} inline /></>
      ) : (menuItem
      )}
    </MenuItemContainer>
  );
};
MenuItem.defaultProps = {
  labelFontWeight: 400,
};
export { MenuItem, MenuItemProps };

const StyledSwitchWrapper = (): IStyle => ({
  position: 'absolute',
  top: '0px',
  right: '0',
});

const SwitchWrapper = createComponent(StyledSwitchWrapper);

type MenuItemWithSwitchProps = MenuItemProps & {
  onChange: () => void;
  checked: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const MenuItemWithSwitch: React.FunctionComponent<MenuItemWithSwitchProps> = ({
  label,
  checked,
  onClick,
  onChange,
  ...baseProps
}) => (
  <MenuItemContainer
    as="label"
    withSwitch
    onClick={e => {
      e.stopPropagation();
      const _ignored = onClick && onClick(e);
    }}
    {...baseProps}
  >
    <MenuItemLabel>{label}</MenuItemLabel>
    <SwitchWrapper>
      <Switch checked={checked} onChange={onChange} small forMenuItem />
    </SwitchWrapper>
  </MenuItemContainer>
);
