/** @format **/

import { isNil } from 'lodash';

import { Omit } from 'utils/types';
import {
  getWebAppUri,
  delayRequest,
  parseAsJsonOrText,
  QueryParameters,
  expandParameters,
  checkStatus,
  HTTP_METHODS,
  getWebApiUri,
  logRequestAsBreadcrumb,
} from 'utils/fetching/utils';

import * as NetworkDummy from 'modules/networkDummy';

type Options = {
  path: string;
  apiKey: string;
  method: HTTP_METHODS;
  parameters?: QueryParameters;
  body?: any;
  webApp?: boolean;
  credentials?: boolean;
};
type PublicOptions = Omit<Options, 'credentials' | 'webApp'>;

export type NetworkFunction = (opts: PublicOptions) => Promise<any>;

export function decorateWithNetworkDummy(func: NetworkFunction): NetworkFunction {
  return opts => {
    const { parameters, body, path, method } = opts;
    if (NetworkDummy.hasMatcherForPath(path, method)) {
      const [resources, status, errors, pagination] = NetworkDummy.executeMatcherForPath(
        path,
        body || parameters,
        method,
      );

      // Success is 0. Not importing because of circular reference problems
      if (status === 0) {
        return new Promise(resolve =>
          resolve({
            type: status,
            resources,
          }),
        );
        // Pagination is 3
      } else if (status === 3) {
        return new Promise(resolve =>
          resolve({
            type: status,
            resources,
            pagination,
          }),
        );
      } else {
        return new Promise(resolve =>
          resolve({
            type: status,
            resources,
            errors,
          }),
        );
      }
    }

    return func(opts);
  };
}

export const fetch = decorateWithNetworkDummy((opts: PublicOptions) =>
  internalFetch({ ...opts, webApp: false }),
);
export const fetchFromApp = decorateWithNetworkDummy((opts: PublicOptions) =>
  internalFetch({ ...opts, webApp: true, credentials: true }),
);

function internalFetch({
  parameters = {},
  body = undefined,
  apiKey,
  method,
  path,
  webApp = false,
  credentials = false,
}: Options) {
  const params = { k: apiKey, ...parameters };

  return window
    .fetch(`${webApp ? getWebAppUri() : getWebApiUri(false)}/${path}${expandParameters(params)}`, {
      mode: 'cors',
      method,
      credentials: credentials ? 'include' : undefined,
      headers: {
        Accept: 'application/json, text/plain, */*;',
        'Content-Type': 'application/json',
      },
      body: isNil(body) ? undefined : JSON.stringify(body),
    })
    .then(logRequestAsBreadcrumb(method))
    .then(checkStatus)
    .then(delayRequest(1000))
    .then(parseAsJsonOrText);
}
