/**
 * @prettier
 */

import * as React from 'react';
import { FormLayout, FormLayoutLabel, FormLayoutInput } from 'components/input/layout';
import { connect } from 'react-redux';
import { DashboardState } from 'sections/dashboard';
import { Models } from 'modules/dashboard';
import { groupBy, every, isNil, isEmpty } from 'lodash';
import { Icon } from 'components/icon';
import { Button } from 'components/button/ui';
import { Application } from 'interfaces/application';
import { Padding } from 'components/layout';
import { Table, Cell } from 'components/table/active';
import { Dropdown } from 'components/ds/dropdown/triggers';
import { OPTION, Select } from '../../../../../components/select';
import { getValidApplications } from '../../../selectors/tileSetting';

type DispatchProps = {};
type StateProps = {
  validApplications: Application[];
};
type SuppliedProps = {
  tile: Models.TileDefinition;
  updateSelectedApplication: (selectedApplication: string) => void;
  selectedApplication: string;
};

type Props = DispatchProps & StateProps & SuppliedProps;

type Item = {
  value: any;
  display: string;
};

/* tslint:disable:jsx-key */
const UnconnectedSelectSingularSource: React.FunctionComponent<Props> = props => {
  const { validApplications, updateSelectedApplication, selectedApplication } = props;

  const validApplicationsDropdown: OPTION[] = [];
  validApplications.forEach(app => {
    validApplicationsDropdown.push(new Option(app.name, app.id.toString()));
  });

  return (
    <>
      <FormLayout marginBottom>
        <FormLayoutLabel>
          <label htmlFor="sourceApplication">Select source:</label>
        </FormLayoutLabel>
        <FormLayoutInput>
          <Select
            id="sourceApplication"
            options={validApplicationsDropdown}
            placeholder={'Select an application'}
            value={selectedApplication}
            onChange={value => {
              updateSelectedApplication(value);
            }}
          />
        </FormLayoutInput>
      </FormLayout>
    </>
  );
};

export const SelectSingularSource = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: DashboardState, ownProps: SuppliedProps) => ({
    validApplications: getValidApplications(state, ownProps.tile),
  }),
)(UnconnectedSelectSingularSource);
