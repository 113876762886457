/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { map, isNil } from 'lodash';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { scrollbar } from 'fela-rules/scrollbar';
import { Products } from 'interfaces/products';

import HorizontalRule from 'components/horizontalRule';
import { Icon } from 'components/icon';
import { Padding } from 'components/layout';
import { Text } from 'components/text';

import { withHover, WithHoverSuppliedProps } from 'components/hoc/withHover';
import { MultiSelect, MultiSelectItem, Search } from 'components/input';

export type Tile = {
  name: string;
  id: string;
  icon: string;
  products: (keyof typeof Products)[];
};
type Props = {
  title: string;
  tileAddedCb: (tileId: string) => void;
  updateSearchFilter: (text: string) => void;
  updateProductFilter: (selectedProducts: MultiSelectItem[]) => void;
  searchFilter?: string;
  selectedProducts?: MultiSelectItem[];
  tiles: Tile[];
};

const productSelectItems: MultiSelectItem[] = [
  {
    display: Products.CrashReporting,
    value: Products.CrashReporting,
  },
  {
    display: Products.RealUserMonitoring,
    value: Products.RealUserMonitoring,
  },
  {
    display: Products.APM,
    value: Products.APM,
  },
];
export const AddTileMenu: React.FunctionComponent<Props> = ({
  title,
  tiles,
  tileAddedCb,
  selectedProducts,
  searchFilter,
  updateSearchFilter,
  updateProductFilter,
}) => {
  return (
    <Menu>
      <Spacer>
        <Text weight="semi-bold">{title}</Text>
      </Spacer>

      <HorizontalRule />

      <Spacer>
        <Search placeholder="Tile name" onInput={updateSearchFilter} text={searchFilter} />
      </Spacer>

      <HorizontalRule />

      <Spacer>
        <MultiSelect
          selectText="Select product"
          onChange={updateProductFilter}
          items={productSelectItems}
          selectedItems={selectedProducts}
        />
      </Spacer>

      <HorizontalRule />

      <Spacer maxHeight={32}>
        {map(tiles, t => (
          <Tile {...t} onAdd={tileAddedCb} key={t.id} />
        ))}
      </Spacer>
    </Menu>
  );
};

const StyledMenu = (props: ThemeType): IStyle => ({
  backgroundColor: props.theme.ds.colors.special.white,
  width: '400px',
  zIndex: 3,
  borderRadius: '5px',
  boxShadow: '0 2px 12px rgba(38, 45, 58, 0.2)',
  border: '1px solid #D3D3D3',
  color: '#4E4E4E',
});

const Menu = createComponent(StyledMenu);

type TileWrapperProps = {
  hovered: boolean;
};
const StyledTile = (props: TileWrapperProps): IStyle =>
  applyModifiers([
    props.hovered,
    {
      borderLeft: '2px solid #1E88E5',
      backgroundColor: '#F8F8F8',
      // Don't move content when border size increases
      paddingLeft: '15px',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      cursor: 'pointer',
    },
  ])({
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    padding: '8px',
    paddingLeft: '16px',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ':last-child': {
      marginBottom: '0px',
    },
  });

const TileWrapper = createComponent(StyledTile, 'div', ['onClick', 'onMouseEnter', 'onMouseLeave']);

const StyledGrabber = (): IStyle => ({
  display: 'inline-block',
  height: '16px',
  width: '16px',
  float: 'right',
  marginRight: '8px',
  marginTop: '2px',
});
const Grabber = createComponent(StyledGrabber, 'div');

type TilePublicProps = Tile & {
  onAdd: (tileId: string) => void;
};
type TileProps = TilePublicProps & WithHoverSuppliedProps;
const TileWithoutHover: React.FunctionComponent<TileProps> = ({
  icon,
  name,
  onAdd,
  id,
  ...withHoverProps
}) => (
  <TileWrapper onClick={() => onAdd(id)} {...withHoverProps}>
    <Icon
      set="icomoon"
      type={icon}
      size={16}
      inline
      color={withHoverProps.hovered ? 'blue' : 'grey'}
    />

    <Padding inline left="8">
      {name}
    </Padding>

    {false && (
      <Grabber>
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(0 2)" fill="#D3D3D3" fillRule="nonzero">
            <rect width="16" height="2" rx=".5" />
            <rect y="5" width="16" height="2" rx=".5" />
            <rect y="10" width="16" height="2" rx=".5" />
          </g>
        </svg>
      </Grabber>
    )}
  </TileWrapper>
);

const Tile = withHover<TilePublicProps, TileProps>(TileWithoutHover);

type SpacerProps = {
  maxHeight?: IStyle['maxHeight'];
};

const StyledSpacer = (props: SpacerProps): IStyle =>
  scrollbar(
    applyModifiers([
      !isNil(props.maxHeight),
      {
        maxHeight: props.maxHeight,
        overflowY: 'auto',
      },
    ])({
      width: '100%',
      padding: '12px 16px 12px 16px',
    }),
  );

const Spacer = createComponent(StyledSpacer);
