/** @format **/

import * as React from 'react';
import { IStyle } from 'fela';
import { isNil } from 'lodash';
import { createComponent } from 'react-fela';
import { connect } from 'react-redux';

import { applyModifiers } from 'fela-rules/modifiers';

import { AppState } from 'interfaces/appState';

import { isAppSwitcherOpen, isDashboardSwitcherOpen } from '../../../selectors/chrome';

type StateProps = {
  isSwitcherOpen: boolean;
};

type Props = StateProps;

type State = {
  animating: boolean;
};

class UnconnectedAppSwitcherBackdrop extends React.Component<Props, State> {
  state: State = {
    animating: false,
  };
  timeoutId: number;

  componentWillReceiveProps(nextProps: StateProps) {
    if (nextProps.isSwitcherOpen !== this.props.isSwitcherOpen) {
      this.setState({
        animating: true,
      });

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = window.setTimeout(() => {
        this.setState({
          animating: false,
        });
        this.timeoutId = null;
      }, 250);
    }
  }

  render() {
    return (
      <Backdrop
        hidden={!this.props.isSwitcherOpen && !this.state.animating}
        visible={this.props.isSwitcherOpen}
      />
    );
  }
}

type ModalProps = {
  visible: boolean;
  hidden: boolean;
};

const StyledBackdropComponent = (props: ModalProps) => {
  const styles: IStyle = {
    opacity: 0,
    backgroundColor: `rgba(48, 56, 71, 0.8)`,
    zIndex: 150,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    ['-webkit-overflow-scrolling' as any]: 'touch',
    display: 'none',
    transition: 'opacity 0.2s ease-out',
  };

  return applyModifiers(
    [
      props.visible,
      {
        opacity: 1,
      },
    ],
    [
      !props.hidden,
      {
        display: 'block',
      },
    ],
  )(styles);
};

const Backdrop = createComponent(StyledBackdropComponent, 'div', ['onTransitionEnd', 'onClick']);

export const AppSwitcherBackdrop = connect<StateProps>((state: AppState) => ({
  isSwitcherOpen: isAppSwitcherOpen(state) || isDashboardSwitcherOpen(state),
}))(UnconnectedAppSwitcherBackdrop);
