/** @format */

import createActionFactory from 'typescript-fsa';

import { createFetchActions } from 'utils/actions';
import * as Models from '../models';

const createAction = createActionFactory('dashboard');

// Tile related actions
export type AddTilePayload = {
  Settings: Models.TileSettings;
  Metadata: Models.TileMetadata;
  Type: string;
};
export const addTile = createAction<AddTilePayload>('ADD_TILE');

export const removeTile = createAction<string>('REMOVE_TILE');

export type UpdateTilePayload = {
  instanceId: string;
  settings: Models.TileSettings;
};
export const updateTile = createAction<UpdateTilePayload>('UPDATE_TILE');

// Dashboard actions
export const updateEditStatus = createAction<boolean>('UPDATE_EDIT_STATUS');
export const updateTitle = createAction<string>('UPDATE_TITLE');
export const updateDashboardIdentifier = createAction<string>('UPDATE_DASHBOARD_IDENTIFIER');
export const updatePrimaryStatus = createAction<boolean>('UPDATE_PRIMARY_DASHBOARD');
export const deleteDashboard = createFetchActions(createAction, 'DELETE_DASHBOARD');
export const toggleFullscreen = createAction<boolean>('TOGGLE_FULLSCREEN');

type UpdateColorPayload = {
  appId: number;
  color: string;
};
export const updateAppColor = createAction<UpdateColorPayload>('UPDATE_APP_COLOR');

// Layout actions
export const updateLayout = createAction<Models.Tile[]>('UPDATE_LAYOUT');
export const revertLayout = createAction('REVERT_LAYOUT');
export const clearLayout = createAction('CLEAR_LAYOUT');
export const resetLayout = createFetchActions<{}, Models.DashboardDefinition>(
  createAction,
  'RESET_LAYOUT',
);
export const saveLayout = createFetchActions(createAction, 'SAVE_LAYOUT');

export type FetchLayoutSuccessPayload = {
  DashboardIdentifier: string;
  DashboardDefinition: Models.DashboardDefinition;
  IsPrimary?: boolean;
};
export const fetchLayout = createFetchActions<{}, FetchLayoutSuccessPayload>(
  createAction,
  'FETCH_LAYOUT',
);

export const setFetchLayoutRoute = createAction<string>('SET_FETCH_LAYOUT_ROUTE');
export const disableTileSettings = createAction('DISABLE_TILE_SETTINGS');
export const groupCharts = createAction('GROUP_CHARTS');
export const allowDragToZoom = createAction('ALLOW_DRAG_TO_ZOOM');
export const silentUpdateGlobalDateRange = createAction<
  Models.TimeboardTimeRanges | { dateFrom: string; dateTo: string }
>('SILENT_UPDATE_GLOBAL_DATE_RANGE');
export const silentUpdateGlobalApplications = createAction<number[]>(
  'SILENT_UPDATE_GLOBAL_APPLICATIONS',
);

export const setCurrentDateRange = createAction<Models.TimeboardTimeRanges>(
  'SET_CURRENT_DATE_RANGE',
);

export const setCurrentApplications = createAction<string[]>('SETUP_CURRENT_APPLICATIONS');

export const toggleGlobalApplication = createAction<number>('TOGGLE_GLOBAL_APPLICATION');
export const updateGlobalDateRange = createAction<Models.TimeboardTimeRanges>(
  'UPDATE_GLOBAL_DATE_RANGE',
);

export const setDashboardTitle = createAction<string>('SET_DASHBOARD_TITLE');
