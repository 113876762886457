/**
 * @prettier
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { get } from 'lodash';

import { SortDirection } from 'constants/sorting';

import { Breadcrumbs, BreadcrumbModel } from 'components/breadcrumbs';

import { DashboardModuleStateAtom, GlobalDashboardSettings } from '../../../../state';

import { TileData, TileState } from '../../../../models';
import { getDrilldownData } from '../../../../selectors';
import { ErrorGroupsPayload, fetchDrilldownData } from '../../../../actions';
import { getGlobalSettings } from '../../../../selectors';
import { getTileState } from '../../../../selectors';

import { UnconnectedErrorGroupListTile } from '../errorGroupList';
import { ContentWrapper } from './contentWrapper';

type StateProps = TileData & GlobalDashboardSettings & Partial<{ tileState: TileState; }>;
type DispatchProps = {
  fetchData: () => void;
};
type SuppliedProps = {
  parentTileId: string;
  appId: number;
  backToRoot: () => void;
  sortField: string;
  sortDirection: SortDirection;
  updateSorting: (sortField: string, sortDirection: SortDirection) => void;
  updatePageIndex: (index: number) => void;
};
type Props = StateProps & DispatchProps & SuppliedProps;

class ErrorGroupDrilldown extends React.Component<Props, {}> {
  componentWillMount() {
    this.props.fetchData();
  }

  render() {
    const breadcrumbs: BreadcrumbModel[] = [
      {
        onClick: this.props.backToRoot,
        text: 'All',
      },
      {
        text: get(this.props.data, 'Data[0].ApplicationName'),
      },
    ];

    return (
      <>
        <ContentWrapper forBreadcrumbs positionAbove>
          <Breadcrumbs items={breadcrumbs} />
        </ContentWrapper>
        <ContentWrapper>
          <UnconnectedErrorGroupListTile
            sortField={this.props.sortField}
            sortDirection={this.props.sortDirection}
            updateSorting={this.props.updateSorting}
            updatePageIndex={this.props.updatePageIndex}
            {...this.props}
            instanceId={this.props.parentTileId}
            drilldown
          />
        </ContentWrapper>
      </>
    );
  }
}

const ConnectedDrilldown = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: DashboardModuleStateAtom, ownProps: SuppliedProps) => ({
    ...getDrilldownData(state)[ownProps.appId],
    ...getGlobalSettings(state),
    tileState: getTileState(state)[ownProps.parentTileId],
  }),
  (dispatch: Dispatch, ownProps: SuppliedProps) => ({
    fetchData: () => {
      dispatch(
        fetchDrilldownData.start({
          appId: ownProps.appId.toString(),
          parentTileId: ownProps.parentTileId,
        }),
      );
    },
  }),
)(ErrorGroupDrilldown);

export { ConnectedDrilldown as ErrorGroupDrilldown };
