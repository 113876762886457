/** @format */

import * as React from 'react';
import classNames from 'classnames';

import { IconAfter } from 'components/icon/iconAfter';
import { Icon } from 'components/icon';
import { Text } from 'components/text';

interface Props {
  size?: '16' | '24' | '32' | '40';
  text: string;
  allowEmptyValues?: boolean;
  onTextEdit: (newText: string) => void;
  overflow?: boolean;
}

interface State {
  text: string;
  isEditing: boolean;
}

export class InlineEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.getTextRef = this.getTextRef.bind(this);

    this.state = {
      text: props.text,
      isEditing: false,
    };
  }

  static defaultProps: Partial<Props> = {
    size: '32',
    allowEmptyValues: false,
    overflow: true,
  };

  span: HTMLSpanElement;

  getTextRef(el: HTMLSpanElement) {
    this.span = el;
  }

  startEditing() {
    this.setState(prevState => {
      if (prevState.isEditing === false) {
        return { isEditing: true };
      }
    });
  }

  finishEditing() {
    const text = this.span.innerText.trim();

    this.setState(prevState => {
      if (prevState.isEditing) {
        const finalText =
          !this.props.allowEmptyValues && text.length === 0 ? this.state.text : text;
        this.span.innerText = finalText;
        if (this.state.text !== finalText) {
          this.props.onTextEdit(finalText);
        }
        return { isEditing: false, text: finalText };
      }
    });
  }

  selectContent() {
    const range = document.createRange();
    range.selectNodeContents(this.span);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  onClick() {
    this.startEditing();
  }

  onBlur() {
    this.finishEditing();
  }

  onKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
    if (e.key === 'Enter') {
      this.finishEditing();
    }
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.span.focus();
      this.selectContent();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.text !== this.state.text) {
      this.setState({
        text: nextProps.text,
      });
    }
  }

  render() {
    const icon = (
      <Icon
        size={16}
        type="edit"
        color="light"
        onClick={this.onClick}
        extraClasses="it-inline-edit-icon"
      />
    );
    const classes = classNames('inline-edit', 'it-inline-edit-area', {
      'inline-edit--overflow': this.props.overflow,
    });

    return (
      <IconAfter size={this.props.size} icon={this.state.isEditing === false ? icon : null}>
        <Text height={this.props.size}>
          <span
            contentEditable={this.state.isEditing}
            className={classes}
            ref={this.getTextRef}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
          >
            {this.state.text}
          </span>
        </Text>
      </IconAfter>
    );
  }
}

export default InlineEdit;
