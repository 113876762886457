/** @format **/

import { isNil, isEmpty } from 'lodash';
import * as querySerializer from 'qs';

import * as Host from 'utils/host';
import { ERRORS } from 'constants/errors';
import { trackBreadcrumb } from 'utils/raygun';
import { FormDataObject } from './types';

export type HTTP_METHODS = 'GET' | 'POST' | 'DELETE' | 'PUT';

export function externalRequestFactory(basename: string, method: 'GET' | 'POST' | 'PUT') {
  return (path: string, _apiKey: string, parameters: QueryParameters = {}): Promise<any> =>
    fetch(`${basename}/${path}${expandParameters(parameters)}`, {
      headers: {},
      mode: 'cors',
      method,
    })
      .then(logRequestAsBreadcrumb(method))
      .then(checkStatus)
      .then(parseAsJsonOrText);
}

export function checkStatus(response: Response): Response {
  switch (response.status) {
    case 500:
      throw new Error(ERRORS.SERVER_FAILURE);
    case 400:
      throw new Error(ERRORS.ALREADY_EXISTS);
    case 401:
    case 403:
      throw new Error(ERRORS.UNAUTHORIZED);
    case 402:
      throw new Error(ERRORS.PAYMENT_REQUIRED);
    case 404:
      throw new Error(ERRORS.WRONG_PATH_404);
    case 409:
      throw new Error(ERRORS.CONFLICT);
  }

  return response;
}

type ArrayQueryParameter = { name: string; value: QueryParameter };
type QueryParameter =
  | QueryParameters
  | ArrayQueryParameter[]
  | string[]
  | number[]
  | string
  | number
  | boolean;
export type QueryParameters = { [key: string]: any };

export function expandParameters(parameters: QueryParameters): string {
  if (isEmpty(parameters)) {
    return '';
  }

  return querySerializer.stringify(parameters, { addQueryPrefix: true, strictNullHandling: true });
}

export type NetworkFunction = (
  path: string,
  apiKey: string,
  parameters?: QueryParameters,
) => Promise<any>;

export function getWebAppUri() {
  const host = window.document.location.host;

  // Make it work in Jest
  if (isNil(host) || host === 'localhost' || host === '') {
    return '//raygun.com';
  }

  return host ? `//${host}` : `//raygun.com`;
}

export function getWebApiUri(withTrailingSlash = true) {
  let subdomain;
  const trailingSlash = withTrailingSlash ? '/' : '';

  if (Host.isBeta) {
    subdomain = 'webapi.beta';
  } else if (Host.isOffice1) {
    subdomain = 'webapi1.o';
  } else if (Host.isOffice2) {
    subdomain = 'webapi2.o';
  } else if (Host.isOffice3) {
    subdomain = 'webapi3.o';
  } else if (Host.isIntegrationTest) {
    subdomain = 'webapi.test';
  } else {
    subdomain = 'webapi';
  }

  return `//${subdomain}.raygun.${getDomain()}${trailingSlash}`;
}

export function getPublicApiUri(withTrailingSlash = true) {
  const trailingSlash = withTrailingSlash ? '/' : '';

  if (Host.isBeta) {
    return `//publicapi.beta.raygun.io${trailingSlash}`;
  }
  if (Host.isLocal) {
    return `//localhost:5010${trailingSlash}`;
  }

  return `//publicapi.raygun.com${trailingSlash}`;
}

function getDomain(defaultVal: string = 'com') {
  if (window.document.domain) {
    return window.document.domain.split('.').pop();
  }
  return defaultVal;
}

export function parseAsJsonOrText(response: Response) {
  const contentType = response.headers.get('content-type');

  if (contentType && contentType.indexOf('application/json') === -1) {
    return response.text();
  }

  return new Promise<any>(res => {
    response.text().then(text => {
      if (text.length > 0) {
        res(JSON.parse(text));
      } else {
        res(text);
      }
    });
  });
}

export function delayRequest(milliseconds: number) {
  return (response: Response) =>
    new Promise(success => {
      if (Host.isLocal) {
        setTimeout(() => success(response), milliseconds);
      } else {
        success(response);
      }
    });
}

export function convertObjectToFormDataString(obj: FormDataObject): string {
  return querySerializer.stringify(obj, { arrayFormat: 'repeat' });
}

export function logRequestAsBreadcrumb(method: string): (response: Response) => Response {
  return (response: Response): Response => {
    const { status, url } = response;

    const is2XXStatus = status.toString()[0] === '2';

    trackBreadcrumb(`Request to ${url}`, is2XXStatus ? 'info' : 'error', {
      status: status,
      url: url,
      method: method,
    });

    return response;
  };
}
