/** @format */

import * as React from 'react';
import { assign } from 'lodash';
import { To } from 'redux-first-router-link';

import { CellBase } from '../tableModel';
import { LinkNode } from './linkCell/linkNode';
import { Icon } from 'components/icon';

export class HasCommentsCell extends CellBase {
  constructor(init?: Partial<HasCommentsCell>) {
    super(init);
    assign(this, init);
  }

  render(rowItem: any) {
    const value: To = this.getValue(rowItem);

    if (value) {
      return (
        <LinkNode
          to={value}
          text={<Icon type="comment" size={16} color="blue" />}
          openNewTab={false}
        />
      );
    }

    return <Icon type="comment" size={16} color="light" />;
  }
}

export default HasCommentsCell;
