/** @format */

import * as React from 'react';
import { connect } from 'react-redux';

import { Column, Table } from 'components/table';
import { NumberCell } from 'components/table/cells';
import Text from 'components/text';
import { Padding } from 'components/layout';

import { DashboardModuleStateAtom } from '../../../state';
import { TopActiveIssuesPayload } from '../../../actions';

import { Props as TileDataProviderProps, TileDataProvider } from '../dataProvider';

import { TypeCell } from 'apm/components/typeCell';
import { getApplicationsById } from 'app/selectors/application';
import { LoadingTopActiveIssues } from '../loading';

type MessageCellSuppliedProps = TopActiveIssuesPayload;
type MessageCellStateProps = { applicationName: string };
type MessageCellProps = MessageCellStateProps & MessageCellSuppliedProps;
const UnconnectedMessageCell: React.FunctionComponent<MessageCellProps> = props => {
  const link = `/apm/${props.applicationId.toString(36)}/issues/${props.identifier}`;

  return (
    <>
      <Text size="medium" ellipsis>
        <a href={link} title={props.name}>
          {props.name}
        </a>
      </Text>
      <Padding top="4">
        <Text size="small" color="grey">
          {props.applicationName}
        </Text>
      </Padding>
    </>
  );
};

const MessageCell = connect<MessageCellStateProps, {}, MessageCellSuppliedProps>(
  (state: DashboardModuleStateAtom, ownProps: MessageCellSuppliedProps) => ({
    applicationName: getApplicationsById(state)[ownProps.applicationId].name,
  }),
)(UnconnectedMessageCell);

type Props = TileDataProviderProps;

const TopActiveIssues: React.FunctionComponent<Props> = props => {
  const columns: Column[] = [
    {
      header: 'Type',
      width: 80,
      cell: new TypeCell({
        accessor: 'type',
      }),
      spaceLeft: true,
      headerSpaceRight: true,
    },
    {
      header: 'Issue name',
      cell: MessageCell,
    },
    {
      header: 'Count',
      width: 60,
      cell: new NumberCell({
        accessor: 'traceCount',
        formatter: val => val.toLocaleString(),
      }),
    },
  ];

  return <Table data={props.data as any} columns={columns} noBorderOnLastRow />;
};

const ConnectedTopActiveIssues = TileDataProvider(TopActiveIssues, LoadingTopActiveIssues);

ConnectedTopActiveIssues.moduleProps = {
  noPadding: true,
};

export { ConnectedTopActiveIssues as TopActiveIssues };
