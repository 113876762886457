/**
 * @prettier
 */
import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type MenuProps = {
  dockLeft?: boolean;
  dockRight?: boolean;
  noMargin?: boolean;
  fullWidth?: boolean;
};

const StyledMenu = (props: MenuProps & ThemeType): IStyle => {
  const styles: IStyle = {
    overflow: 'hidden',
    marginTop: '-1px',
    position: 'absolute',
    zIndex: 1,
    top: '100%',
    left: 0,
    minWidth: '100%',
    backgroundColor: props.theme.colors.standard.white,
    border: `1px solid ${props.theme.colors.border.transparent}`,
    backgroundClip: 'padding-box',
    transformOrigin: '50% 0',
    animation: 'dropdown-anime 80ms ease-out',
    animationFillMode: 'forwards',
    boxShadow: '0 2px 12px rgba(38, 45, 58, 0.2)',
  };

  return applyModifiers(
    [
      props.dockLeft,
      {
        left: 0,
        right: 'auto',
        transformOrigin: '0 0',
      },
    ],
    [
      props.dockRight,
      {
        left: 'auto',
        right: 0,
        transformOrigin: '100% 0',
      },
    ],
    [props.noMargin, { marginTop: 0 }],
    [props.fullWidth, { width: '100%' }],
  )(styles);
};

export const DropdownMenu = createComponent(StyledMenu, 'div');

type SectionProps = {
  searchResults?: boolean;
  searchInput?: boolean;
  large?: boolean;
  checkboxList?: boolean;
};

const StyledSection = (props: SectionProps & ThemeType): IStyle => {
  const styles: IStyle = {
    padding: '6px 0',
    borderBottom: `1px solid ${props.theme.colors.border.light}`,
    ['&:last-child' as any]: {
      border: 'none',
    },
  };

  return applyModifiers(
    [
      props.searchResults,
      {
        maxHeight: '156px',
        overflowY: 'auto',
      },
    ],
    [props.searchInput, { padding: '8px' }],
    [props.large, { maxHeight: '320px' }],
    [
      props.checkboxList,
      {
        padding: '8px 16px',
        overflowY: 'auto',
        maxHeight: '224px',
      },
    ],
  )(styles);
};

export const DropdownSection = createComponent(StyledSection, 'div');

const StyledItem = () => ({});

export const DropdownItem = createComponent(StyledItem, 'div');
