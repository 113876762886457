/** @format **/

import { createRoutingAction } from 'utils/routing';

export const GoToAlerts = createRoutingAction<{ planIdentifier: string }>('GO_TO_ALERTS');
export const GoToCreateAlert = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_CREATE_ALERT',
);
export const GoToEditAlert = createRoutingAction<{ planIdentifier: string; alertId: string }>(
  'GO_TO_EDIT_ALERT',
);
export const GoToViewAlert = createRoutingAction<{ planIdentifier: string; alertId: string }>(
  'GO_TO_VIEW_ALERT',
);
