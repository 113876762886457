/**
 * @prettier
 */

import React from 'react';

import { FormLayout, FormLayoutInput, FormLayoutLabel, Text as TextInput } from 'components/input';

type Props = {
  text: string;
  updateText: (text: string) => void;
};

export const Text: React.FunctionComponent<Props> = ({ text, updateText }) => {
  return (
    <FormLayout marginBottom>
      <FormLayoutLabel>
        <label htmlFor="text">Text</label>
      </FormLayoutLabel>
      <FormLayoutInput>
        <TextInput
          type="textarea"
          isBlock
          isFullWidth
          value={text}
          onChange={e => updateText(e.target.value)}
        />
      </FormLayoutInput>
    </FormLayout>
  );
};
