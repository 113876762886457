/** @format */

import * as React from 'react';
import { IStyle, IRenderer } from 'fela';
import { ThemeType } from 'app/theme';
import { createComponent } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

type Props = {
  closing: boolean;
};

const StyledContainer = (props: Props & ThemeType, renderer: IRenderer): IStyle => {
  const animateIn = () => ({
    ['0%' as any]: {
      opacity: 0,
      transform: 'scale(0.8)',
    },
    ['100%' as any]: {
      opacity: 1,
      transform: 'scale(1)',
    },
  });

  const animateOut = () => ({
    ['0%' as any]: {
      opacity: 1,
      transform: 'scale(1)',
    },
    ['100%' as any]: {
      opacity: 0,
      transform: 'scale(0.8)',
    },
  });

  const style: IStyle = {
    position: 'relative',
    borderRadius: '3px',
    margin: '56px auto',
    background: '#fff',
    ['-webkit-background-clip' as any]: 'padding',
    backgroundClip: 'padding-box',
    boxShadow: props.theme.shadows.modal,
  };

  return applyModifiers(
    [
      !props.closing,
      {
        animation: `${renderer.renderKeyframe(animateIn, props)} 0.2s ease-in-out`,
        opacity: 1,
      },
    ],
    [
      props.closing,
      {
        animation: `${renderer.renderKeyframe(animateOut, props)} 0.2s ease-in-out`,
        opacity: 0,
      },
    ],
  )(style);
};

// TODO: Remove this cast when this bug is fixed https://github.com/rofrischmann/fela/issues/625
export const Container = createComponent<Props>(StyledContainer as () => IStyle, 'div', [
  'onClick',
]);
