/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { clearfix } from 'fela-rules/layout';
import { screenLargeAndAbove } from 'fela-rules/breakpoints';

type PassedProps = {
  width: number;
  gutter?: number;
};

type Props = {
  sidebar: React.ReactNode;
} & PassedProps;

const StyledContainer = (props: PassedProps): IStyle =>
  clearfix({
    display: 'block',
    ...screenLargeAndAbove({
      position: 'relative',
      paddingRight: `${props.width}px`,
    }),
  });

const Container = createComponent<PassedProps>(StyledContainer);

const StyledSidebar = (props: PassedProps): IStyle => ({
  display: 'block',
  ...screenLargeAndAbove({
    position: 'absolute',
    right: '0',
    top: '0',
    width: `${props.width}px`,
    paddingLeft: `${props.gutter}px`,
    height: '100%',
  }),
});

const Sidebar = createComponent<PassedProps>(StyledSidebar);

export const SidebarLayout: React.FunctionComponent<Props> = props => (
  <Container {...props}>
    {props.children}
    <Sidebar {...props}>{props.sidebar}</Sidebar>
  </Container>
);

SidebarLayout.defaultProps = {
  gutter: 16,
};
