/**
 * @prettier
 */
import * as React from 'react';
import classnames from 'classnames';

interface Props {
  icon: React.ReactNode;
  divElement?: boolean;
  asContent?: boolean;
  size?: '16' | '24' | '32';
  extraClasses?: string;
  center?: boolean;
}

export const IconBefore: React.FunctionComponent<Props> = props => {
  const textClasses = classnames(`text-icon text-icon--${props.size} ${props.extraClasses}`, {
    'text-icon--center': props.center,
  });

  if (!props.divElement) {
    const content = props.asContent ? (
      <span className="text-icon__content">{props.children}</span>
    ) : (
      <span className="text-icon__text">{props.children}</span>
    );

    return (
      <span className={textClasses}>
        <span className="text-icon__icon">{props.icon}</span>
        {content}
      </span>
    );
  }

  const content = props.asContent ? (
    <div className="text-icon__content">{props.children}</div>
  ) : (
    <div className="text-icon__text">{props.children}</div>
  );

  return (
    <div className={textClasses}>
      <div className="text-icon__icon">{props.icon}</div>
      {content}
    </div>
  );
};

IconBefore.defaultProps = {
  size: '16',
  divElement: false,
  asContent: false,
  extraClasses: '',
};

export default IconBefore;
