/** @format */

import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { isComponent } from 'utils/types';
import * as Models from '../models';

import { HEADING_CLASSES } from 'components/text';

type ModuleProps = {
  overflow?: boolean;
  color?: string;
};

function getColorStyle(color: string) {
  if (!color) {
    return null;
  }

  if (color === Models.TileTransparent) {
    return {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: 'none',
    };
  }

  return {
    backgroundColor: color,
    border: 'none',
    boxShadow: '0 2px 3px -1px rgba(38, 45, 58, 0.1), inset 0 0 0px 1px rgba(0,0,0,0.2)',
  };
}

export const Module: React.FunctionComponent<ModuleProps> = props => {
  const moduleClasses = classnames('dashboard-module', {
    'dashboard-module--overflow-init': props.overflow,
  });

  return (
    <div className={moduleClasses} style={getColorStyle(props.color)}>
      {props.children}
    </div>
  );
};

type ModuleHeaderProps = {
  transparent: boolean;
};

export const ModuleHeader: React.FunctionComponent<ModuleHeaderProps> = ({
  children,
  transparent,
}) => {
  const classes = classnames('dashboard-module__header', {
    'dashboard-module__header--hidden': transparent,
  });

  return React.Children.count(children) > 0 ? (
    <div className={classes}>
      <h5 className={`${HEADING_CLASSES.FIVE} line-height-24`}>{children}</h5>
    </div>
  ) : null;
};

type ModuleStateProps = {
  shouldRenderFooter: boolean;
};

type ModulePassedProps = {
  id?: string;
  footer?: Models.FooterTypes;
  renderFooter?: Models.RenderFooterCallback;
};

type ModuleContentedProps = ModuleStateProps & ModulePassedProps;

const moduleState = connect<ModuleStateProps, {}, ModulePassedProps>(
  (state: any, ownProps: ModuleFooterProps) => ({
    shouldRenderFooter: ownProps.renderFooter
      ? ownProps.renderFooter(state, ownProps.id)
      : !!ownProps.footer,
  }),
);

export type ModuleContentProps = {
  noScrolling?: boolean;
  contentAbove?: boolean;
  overflow?: boolean;
  noPadding?: boolean;
  noHeader?: boolean;
} & ModulePassedProps;

const UnconnectedModuleContent: React.FunctionComponent<
  ModuleContentProps & ModuleContentedProps
> = props => {
  const contentClasses = classnames('dashboard-module__content', {
    'dashboard-module__content--footer': !!props.shouldRenderFooter,
    'dashboard-module__content--above': props.contentAbove,
    'dashboard-module__content--no-header': props.noHeader,
  });

  const contentItemClasses = classnames('dashboard-module-content', {
    'dashboard-module-content--no-scroll': props.noScrolling,
    'dashboard-module-content--overflow-init': props.overflow,
    'dashboard-module-content--no-padding': props.noPadding,
  });

  return (
    <div className={contentClasses}>
      <div className={contentItemClasses}>{props.children}</div>
    </div>
  );
};

export const ModuleContent = moduleState(UnconnectedModuleContent);

type ModuleFooterProps = ModulePassedProps;

const UnconnectedModuleFooter: React.FunctionComponent<
  ModuleFooterProps & ModuleContentedProps
> = ({ shouldRenderFooter, id, footer: Footer }) => {
  if (!shouldRenderFooter) {
    return null;
  }

  return (
    <div className="dashboard-module__footer">
      {isComponent<{ id: string }>(Footer) ? <Footer id={id} /> : Footer}
    </div>
  );
};

export const ModuleFooter = moduleState(UnconnectedModuleFooter);
