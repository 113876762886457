/** @format */

import { TileDefinition } from '../../models';

import {
  ApdexChart,
  AveragePageBreakdownChart,
  RequestCountChart,
  RequestExecutionSpeedChart,
  SingleValueTile,
} from '../../components/tiles';

import { apmRequired, DEFAULT_DATE_RANGE, fetchAction } from '../shared';

export const SharedApmTileMap: TileDefinition[] = [
  {
    id: 'apdex',
    name: 'Apdex',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'apdex',
    requirements: [apmRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 4,
      SupportsSettings: true,
      SupportsAppColors: true,
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: ApdexChart,
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'requestsPerMinute',
    name: 'Requests per minute',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'calls/countperminuteovertime',
    requirements: [apmRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 4,
      SupportsSettings: true,
      QueryString: {
        callType: 'trace',
      },
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: RequestCountChart,
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'requestBreakdown',
    name: 'Average request breakdown',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'calls/tracebreakdownovertime',
    requirements: [apmRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 4,
      SupportsSettings: true,
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: AveragePageBreakdownChart,
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'requestExecutionSpeed',
    name: 'Request execution speeds',
    category: 'chart',
    fetchAction,
    apiEndpoint: 'calls/tracedurationovertime',
    requirements: [apmRequired],
    metadata: {
      DefaultWidth: 8,
      DefaultHeight: 4,
      SupportsSettings: true,
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: RequestExecutionSpeedChart,
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'externalApiCallsPerMinute',
    name: 'External API calls per minute',
    category: 'value',
    fetchAction,
    apiEndpoint: 'calls/countperminute',
    requirements: [apmRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
      Live: true,
      QueryString: {
        callType: 'externalapi',
      },
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'requestsPerMinuteSvt',
    name: 'Requests per minute',
    category: 'value',
    fetchAction,
    apiEndpoint: 'calls/countperminute',
    requirements: [apmRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
      Live: true,
      QueryString: {
        callType: 'trace',
      },
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
  {
    id: 'queriesPerMinute',
    name: 'Queries per minute',
    category: 'value',
    fetchAction,
    apiEndpoint: 'calls/countperminute',
    requirements: [apmRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'number',
      Live: true,
      QueryString: {
        callType: 'query',
      },
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-apm',
    products: ['APM'],
  },
];
