/**
 * @prettier
 */
import React from 'react';
import { Cell, Props as ListCellProps } from './cell';

import { Icon } from 'components/icon';

type Props = ListCellProps & {
  visible?: boolean;
  onClick: () => void;
};

export const ListCellCross: React.FunctionComponent<Props> = props => (
  <Cell {...props}>
    {props.visible && (
      <div className="active-list-remove">
        <Icon color="grey" type="cross" size={16} onClick={props.onClick} />
      </div>
    )}
  </Cell>
);

ListCellCross.defaultProps = {
  visible: true,
};
