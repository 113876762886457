/** @format */

import * as React from 'react';

import * as charting from 'utils/echarts/index';
import { CustomChart } from 'components/ds/charts';

import { UserCountChartPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';

const Chart: React.FunctionComponent<ProviderProps> = props => {
  const data = props.data as UserCountChartPayload;
  const chartData = data.data.reduce(
    (acc, dataPoint) => ({
      returningUsers: [...acc.returningUsers, dataPoint.totalUsers - dataPoint.newUsers],
      newUsers: [...acc.newUsers, dataPoint.newUsers],
      periods: [...acc.periods, dataPoint.period],
    }),
    { returningUsers: [], newUsers: [], periods: [] },
  );

  const options = (chart: any) => {
    return charting.extendOptions({
      tooltip: {
        show: true,
        trigger: 'axis',
        formatter: (params: any[]) => {
          return charting.renderSeriesTooltip({
            date: charting.formatUtcTooltipDate(params[0].name, data.interval as any),
            series: params.map((s: any) => ({
              name: s.seriesName,
              value: s.value,
              colorcode: s.color,
            })),
          });
        },
        axisPointer: charting.rum.AxisPointerShadow,
      },
      xAxis: [
        {
          type: 'category',
          data: chartData.periods,
          axisLabel: {
            formatter: charting.getDateTimeLabelFormatter(
              chartData.periods,
              data.interval as any,
              chart ? chart.getEchartsInstance() : undefined,
            ),
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: charting.getIntegerLabelFormatter(
              chart ? chart.getEchartsInstance() : undefined,
            ),
          },
        },
      ],
      series: [
        {
          name: 'New Users',
          type: 'bar',
          data: chartData.newUsers,
          stack: 'users',
          itemStyle: {
            normal: {
              barBorderRadius: [0, 0, 0, 0],
              color: charting.newUsersColor,
            },
            emphasis: {
              barBorderRadius: [0, 0, 0, 0],
              color: charting.newUsersColor,
            },
          },
        },
        {
          name: 'Returning Users',
          type: 'bar',
          data: chartData.returningUsers,
          stack: 'users',
          itemStyle: {
            normal: {
              barBorderRadius: [4, 4, 0, 0],
              color: charting.returningUsersColor,
            },
            emphasis: {
              barBorderRadius: [4, 4, 0, 0],
              color: charting.returningUsersColor,
            },
          },
        },
      ],
    });
  };

  return <CustomChart height={'100%'} options={options} />;
};

const ConnectedTile = TileDataProvider(Chart, ChartTileLoading);

export { ConnectedTile as UserCountChart };
