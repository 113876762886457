/** @format **/

import React from 'react';

import { useHubSpotLoaded } from "hooks/useHubSpotLoaded";

import { CONTACT_EMAIL, CONTACT_US_URL, SALES_EMAIL, SUPPORT_CLASS } from 'utils/constants';

import { SupportLinkWrapper } from './styles';

type SupportLinkProps = {
  key?: string;
  forSales?: boolean;
};

export const SupportLink: React.FunctionComponent<SupportLinkProps> = props => {
  const isHubSpotLoaded = useHubSpotLoaded();

  const link = isHubSpotLoaded
    ? `mailto:${props.forSales ? SALES_EMAIL : CONTACT_EMAIL}`
    : CONTACT_US_URL;

  return (
    <SupportLinkWrapper
      key={props.key}
      href={link}
      className={isHubSpotLoaded ? SUPPORT_CLASS : ''}
      target={'_blank'}
    >
      {props.children}
    </SupportLinkWrapper>
  );
};
