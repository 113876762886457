/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { round, find, isNil, merge, max, flatten, map } from 'lodash';

import { AppState } from 'interfaces/appState';
import * as charting from 'utils/echarts';
import { getCurrentRouteData } from 'selectors/router';
import { createActionForDate, CurrentRouteProps } from 'utils/actions';
import { Container, Graph, Legend, LegendItem } from 'components/echarts/legend';
import { CustomChart } from 'components/ds/charts';
import { DEFAULT_GROUP_ID } from 'components/ds/charts/utils';

import { renderTitle } from '../../title';

export enum DurationChartTypes {
  LINE,
  STACK,
}

export type DurationSeriesData = {
  time: number[];
  color?: string;
  name?: string;
  keyName?: string;
};

export type PassedProps = {
  data: DurationSeriesData[];
  periods: string[];
  height: string;
  chartType?: DurationChartTypes;
  title?: string;
  interval?: charting.Intervals;
  chartGroupId?: string;
  groupChart?: boolean;
  allowDateRangeSelect?: boolean;
  className?: string;
  useEchartLegend?: boolean;
  legendItems?: LegendItem[];
  onDashboard?: boolean;
  customOptions?: any;
};

type StateProps = {
  routeData: CurrentRouteProps;
};

type DispatchProps = {
  updateDateRange: (dateFrom: string, dateTo: string) => void;
};

type Props = StateProps & DispatchProps & PassedProps;

function getAreaChartStyle(chartType: DurationChartTypes, color: string) {
  if (chartType === DurationChartTypes.STACK) {
    return {
      stack: 'stack',
      areaStyle: {
        normal: {
          opacity: 0.3,
          color,
        },
        emphasis: {
          color,
        },
      },
    };
  }
  return {};
}

const UnconnectedDurationOverTime: React.FunctionComponent<Props> = props => {
  const { data, periods, interval } = props;

  const placeholderData = {
    symbol: 'none',
    z: 2,
    type: 'line',
    lineStyle: {
      normal: { color: '#000' },
      emphasis: { color: '#000' },
    },
    name,
    id: name,
    data: periods.map(_d => 0),
  };

  const series = data.map(d => {
    const name = d.name;
    const color = d.color;

    return {
      symbol: 'none',
      type: 'line',
      lineStyle: {
        normal: { color },
        emphasis: { color },
      },
      ...getAreaChartStyle(props.chartType, color),
      name,
      id: name,
      data: d.time,
    };
  });

  const hasData = series.length > 0;
  const maxUnit = max(flatten(data.map(d => d.time)));

  const metricColors = map(data, d => d.color);

  const options = (chart: any) => {
    const rawOptions = charting.extendOptions({
      tooltip: {
        show: true,
        confine: true,
        trigger: 'axis',
        formatter: (params: any[]) => {
          return !hasData
            ? null
            : charting.renderSeriesTooltip({
                date: charting.formatUtcTooltipDate(params[0].name, interval),
                series: params.map((s: any) => {
                  const seriesItem = find(data, d => d.name == s.seriesName);
                  return {
                    name: s.seriesName,
                    value: `${round(s.value).toLocaleString()}ms`,
                    colorcode: seriesItem ? seriesItem.color : null,
                  };
                }),
              });
        },
      },
      xAxis: [
        {
          type: 'category',
          data: periods,
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: charting.getDateTimeLabelFormatter(
              periods,
              interval,
              chart ? chart.getEchartsInstance() : undefined,
            ),
            textStyle: {
              color: '#607D8B',
            },
          },
          splitNumber: 8,
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: charting.getDurationLabelFormatter(
              chart ? chart.getEchartsInstance() : undefined,
              maxUnit,
            ),
            textStyle: {
              color: '#607D8B',
            },
          },
        },
      ],
      series: hasData ? series : placeholderData,
      ...(props.useEchartLegend && {
        height: '76%',
        color: metricColors,
        legend: {
          data: map(data, d => d.keyName || d.name),
          icon: 'circle',
          orient: 'horizontal',
          x: 'center',
          y: 'bottom',
          textStyle: {
            fontFamily: '"Open Sans", arial, helvetica, serif',
            fontSize: 14,
            color: '#404040',
          },
          itemWidth: 8,
          itemHeight: 8,
        },
      }),
    });

    return props.customOptions ? merge({}, rawOptions, props.customOptions) : rawOptions;
  };

  const legendItems: LegendItem[] = !isNil(props.legendItems)
    ? props.legendItems
    : data.map(d => ({
        label: d.keyName || d.name,
        colorCode: d.color,
      }));

  const hasCustomLegend = legendItems.length >= 1 && !props.useEchartLegend;

  return (
    <Container>
      {renderTitle(props.title)}
      <Graph hasLegend={hasCustomLegend} positionInside={props.onDashboard}>
        <CustomChart
          height={props.height}
          className={props.className}
          options={options}
          group={props.groupChart}
          groupingId={props.chartGroupId}
          zoomOnDateRange={props.allowDateRangeSelect}
          onDateRangeChange={props.updateDateRange}
          periods={periods}
        />
      </Graph>
      {hasData && hasCustomLegend && (
        <Legend positionInside={props.onDashboard} legendItems={legendItems} />
      )}
    </Container>
  );
};

UnconnectedDurationOverTime.defaultProps = {
  interval: '1d',
  chartType: DurationChartTypes.LINE,
  chartGroupId: DEFAULT_GROUP_ID,
  groupChart: true,
};

type ReduxDispatchProps = {
  updateDateRange: (routeData: CurrentRouteProps, dateFrom: string, dateTo: string) => void;
};

const ConnectedDurationOverTime = connect<
  StateProps,
  ReduxDispatchProps,
  PassedProps,
  Props,
  AppState
>(
  state => ({
    routeData: getCurrentRouteData(state),
  }),
  dispatch => ({
    updateDateRange: (routeData: CurrentRouteProps, dateFrom: string, dateTo: string) =>
      dispatch(createActionForDate(routeData, dateFrom, dateTo)),
  }),
  (stateProps: StateProps, dispatchProps: any, passedProps: PassedProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...passedProps,
    updateDateRange: (dateFrom: string, dateTo: string) =>
      dispatchProps.updateDateRange(stateProps.routeData, dateFrom, dateTo),
  }),
)(UnconnectedDurationOverTime);

export { ConnectedDurationOverTime as DurationOverTime };
