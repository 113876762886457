/** @format **/
import createActionFactory from 'typescript-fsa';

import { IgnoredType, TileState } from "../models";

const createAction = createActionFactory('dashboard/tiles');

export type ToggleSelectErrorGroupPayload = {
  tileId: string;
  errorGroupId: number;
  applicationId: number;
};

export const toggleSelectErrorGroup = createAction<ToggleSelectErrorGroupPayload>(
  'TOGGLE_SELECTED_ERROR_GROUP',
);

export type ToggleSelectAllErrorGroupsPayload = {
  tileId: string;
  isDrilldown: boolean;
  applicationId?: number;
};

export const toggleSelectAllErrorGroups = createAction<ToggleSelectAllErrorGroupsPayload>(
  'TOGGLE_SELECT_ALL_ERROR_GROUPS',
);

export const deleteErrors = createAction<string>('DELETE_SELECTED_ERRORS');

export type SetErrorGroupStatusPayload = {
  status: string;
  tileId: string;
  drop?: boolean;
  version?: string;
  ignoredType?: IgnoredType;
  ignoredValue?: string;
};

export const setErrorGroupStatus = createAction<SetErrorGroupStatusPayload>(
  'SET_ERROR_GROUP_STATUS',
);

export const deselectAllErrorGroups = createAction<string>('DESELECT_ALL_ERROR_GROUPS');

export type UpdateTileStatePayload = {
  tileId: string;
  tileState: Partial<TileState>;
};

export const updateTileState = createAction<UpdateTileStatePayload>('UPDATE_TILE_STATE');