/** @format */

import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as Actions from 'app/actions/chrome';

import { ProductTypes } from 'interfaces/products';
import { reducerUpdate } from 'utils/reducerUpdate';

export type ChromeState = {
  sidebar: boolean;
  isSidebarOpen: boolean;
  sidebarActiveLink: string;
  sidebarActiveSecondaryLink: string;
  layout: 'product' | 'platform' | 'anonymous' | 'logo-only' | 'none';
  showMessages: boolean;
  restrictMessagesByProduct: ProductTypes;
  booted: boolean;
  appSwitcherOpen: boolean;
  isDashboardSwitcherOpen: boolean;
  appSwitcherPlanId: number;
};

const defaultState: ChromeState = {
  sidebar: false,
  isSidebarOpen: false,
  sidebarActiveLink: '',
  sidebarActiveSecondaryLink: '',
  layout: 'product',
  showMessages: false,
  restrictMessagesByProduct: undefined,
  booted: false,
  appSwitcherOpen: false,
  isDashboardSwitcherOpen: false,
  appSwitcherPlanId: null,
};

export const ChromeReducer = reducerWithInitialState(defaultState)
  .case(Actions.showSidebar, (state, payload) => reducerUpdate(state, { sidebar: payload }))
  .case(Actions.toggleMobileSidebar, (state, payload) =>
    reducerUpdate(state, { isSidebarOpen: payload }),
  )
  .case(Actions.setSidebarActiveLink, (state, payload) =>
    reducerUpdate(state, { sidebarActiveLink: payload }),
  )
  .case(Actions.setSidebarSecondaryActiveLink, (state, payload) =>
    reducerUpdate(state, { sidebarActiveSecondaryLink: payload }),
  )
  .case(Actions.setLayout, (state, payload) => reducerUpdate(state, { layout: payload }))
  .case(Actions.showMessages, (state, payload) => reducerUpdate(state, { showMessages: payload }))
  .case(Actions.setMessageProductTypeRestrictions, (state, payload) =>
    reducerUpdate(state, { restrictMessagesByProduct: payload }),
  )
  .case(Actions.bootApp, state => reducerUpdate(state, { booted: true }))
  .case(Actions.showApplicationSwitcher, (state, appSwitcherOpen) =>
    reducerUpdate(state, { appSwitcherOpen }),
  )
  .case(Actions.showDashboardSwitcher, (state, isDashboardSwitcherOpen) =>
    reducerUpdate(state, { isDashboardSwitcherOpen }),
  )
  .case(Actions.setAppSwitcherPlanId, (state, id) =>
    reducerUpdate(state, { appSwitcherPlanId: id }),
  );
