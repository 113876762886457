/** @format */

import { defaults } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { put, take, fork } from 'redux-saga/effects';
import { EmptyActionCreator, ActionCreator } from 'typescript-fsa';

import * as Models from './models';
import * as Actions from './actions';
import { clearNotificationFromCookie, getNotificationFromCookie } from './utils';

export type SavingMessages = {
  saving?: string;
  done?: string;
  error?: string;
};

const savingMessageDefaults: SavingMessages = {
  saving: 'Saving...',
  done: 'Saving successful',
  error: 'Saving failed',
};

export function* savingNotification(messages: SavingMessages, position: Models.Position): any {
  messages = defaults(messages, savingMessageDefaults);

  const payload = Actions.createPayload(Models.TYPES.SUCCESS, messages.saving, true, position);

  const success = yield Actions.show(payload);

  if (success) {
    return Actions.update({
      id: payload.id,
      type: Models.TYPES.SUCCESS,
      message: messages.done,
      disableDismiss: false,
    });
  } else {
    return Actions.update({
      id: payload.id,
      type: Models.TYPES.ERROR,
      message: messages.error,
      disableDismiss: false,
    });
  }
}

export function createSavingSaga(
  actions: {
    start: EmptyActionCreator | ActionCreator<any>;
    done: EmptyActionCreator | ActionCreator<any>;
    error: EmptyActionCreator | ActionCreator<any>;
  },
  messages?: SavingMessages,
  position: Models.Position = Models.Position.CENTRE_TOP,
): any {
  return fork(createSavingSagaHelper, actions, messages, position);
}

function* createSavingSagaHelper(
  actions: {
    start: EmptyActionCreator | ActionCreator<any>;
    done: EmptyActionCreator | ActionCreator<any>;
    error: EmptyActionCreator | ActionCreator<any>;
  },
  messages: SavingMessages,
  position: Models.Position,
): any {
  while (true) {
    yield take(actions.start.type);

    const saving = savingNotification(messages, position);
    yield put(saving.next().value);

    const action = yield take([actions.done.type, actions.error.type]);

    const success = action.type === actions.done.type;
    yield put(saving.next(success).value);
  }
}

export function* HydrateNotificationsFromCookie(): SagaIterator {
  const cookieNotification = getNotificationFromCookie();

  if (cookieNotification) {
    clearNotificationFromCookie(cookieNotification.id);
    yield put(Actions.show(cookieNotification));
  }
}
