/** @format */

import * as React from 'react';
import classnames from 'classnames';

import { SIZES, HEIGHTS } from './constants';

type COLORS = 'purple' | 'grey' | 'blue' | 'green' | 'yellow' | 'red';

interface Props {
  color: COLORS;
  size?: SIZES;
  height?: HEIGHTS;
}

export const Disc: React.FunctionComponent<Props> = props => {
  const classes = classnames(`icon-disc icon-${props.size}`, {
    [`icon--height-${props.height}`]: props.height !== undefined,
    'icon-disc--grey': props.color === 'grey',
    'icon-disc--purple': props.color === 'purple',
    'icon-disc--yellow': props.color === 'yellow',
    'icon-disc--red': props.color === 'red',
    'icon-disc--blue': props.color === 'blue',
    'icon-disc--green': props.color === 'green',
  });

  return <span className={classes} />;
};

Disc.defaultProps = {
  size: 16,
};
