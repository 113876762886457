/** @format **/

export enum TYPES {
  SUCCESS,
  ERROR,
  WARNING,
}

export type Notification = {
  id: string;
  message: string;
  type: TYPES;
  created: number;
  disableDismiss?: boolean;
  position: Position;
};

export interface NotificationState {
  [key: string]: Notification;
}

export enum Position {
  CENTRE_TOP = 0,
  RIGHT_BOTTOM = 1,
}
