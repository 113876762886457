/**
 * @prettier
 */

import * as React from 'react';
import { range } from 'lodash';

import { Table, Column } from 'components/table/table';
import { SkeletonCell, SkeletonTableData } from 'components/table/cells/skeletonCell';
import { Small, Medium } from 'components/breakpoints';
import { Skeleton } from 'components/skeleton';
import { Flex } from 'components/flex';
import { Padding } from 'components/layout';

import { ContentWrapper } from '../CR/errorsPerAppList/contentWrapper';

const MobileLoadingSkeleton: React.FunctionComponent<{}> = () => (
  <Flex justify="space-between">
    <Skeleton height={32} width={10} unit="%" />

    <Flex direction="column" minWidth="60%" minHeight="35px" justify="space-between">
      <Padding bottom="8">
        <Skeleton height={16} width={100} unit="%" />
        <Skeleton height={16} width={60} unit="%" />
      </Padding>
    </Flex>

    <Skeleton height={32} width={10} unit="%" />
  </Flex>
);

export const ErrorsPerAppLoading: React.FunctionComponent<{}> = () => {
  const columns = [
    { header: 'Application', cell: new SkeletonCell() },
    {
      header: 'Groups',
      width: 80,
      cell: new SkeletonCell(),
    },
    {
      header: 'Instances',
      width: 80,
      cell: new SkeletonCell(),
    },
    { header: '', width: 40, cell: new SkeletonCell() },
  ] as Column[];
  const mobileRows = range(1, 10).map(i => <MobileLoadingSkeleton key={i} />);

  return (
    <ContentWrapper>
      <Small>{mobileRows}</Small>

      <Medium andAbove>
        <Table columns={columns} data={SkeletonTableData} />
      </Medium>
    </ContentWrapper>
  );
};
