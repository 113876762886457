/** @format */

import * as React from 'react';
import { ApmAppState } from 'sections/apm';
import { To } from 'redux-first-router-link';
import { Link } from 'components/link';
import { connect } from 'react-redux';

interface StateProps {}
interface SuppliedProps {
  to: To;
  text: any; // A string or an html element
  openNewTab?: boolean;
  className?: string;
}
interface Props extends StateProps, SuppliedProps {}
class UnconnectedLinkNode extends React.Component<Props, {}> {
  navigateTo: (e: React.MouseEvent<HTMLAnchorElement>) => void;

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { to, text, openNewTab } = this.props;
    const attributes = {
      target: openNewTab ? '_blank' : '',
    };

    return (
      <Link to={to} className={this.props.className}>
        {text}
      </Link>
    );
  }
}

export const LinkNode = connect<{}, {}, SuppliedProps>(_state => ({}))(UnconnectedLinkNode);
