/** @format */

import createActionFactory from 'typescript-fsa';

import * as Actions from 'app/popups/actions';
import * as Models from 'app/popups/models';

export const openModal = (
  component: Models.PopupComponent,
  props: Models.PopupPassableProps = {},
  id: string = Actions.getId(),
) => Actions.open({ component, props, id });
export const closeModal = Actions.close;
