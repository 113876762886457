/**
 * @prettier
 */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent, FelaInjectedProps } from 'react-fela';
import { isEmpty, isNil } from 'lodash';

import { applyModifiers } from 'fela-rules/modifiers';
import {
  screenLargeAndAbove,
  screenLargeAndBelow,
  screenMediumAndAbove,
  screenMediumOnly,
  screenSmallOnly,
  screenXLargeOnly,
} from 'fela-rules/breakpoints';
import { ReactComponent } from 'utils/types';

type BaseProps = {
  top?: string | number;
  bottom?: string | number;
  left?: string | number;
  right?: string | number;
  applyToChildren?: boolean;
  inline?: boolean;
  onlyOnSmall?: boolean;
  notOnSmall?: boolean;
  onlyLargeAndBelow?: boolean;
  all?: string | number;
  units?: string;
  removeForLastChild?: boolean;
};

type Props = BaseProps & {
  smallScreen?: BaseProps;
  mediumScreens?: BaseProps;
  mediumScreenOnly?: BaseProps;
  largeScreens?: BaseProps;
  largeScreensAndBelow?: BaseProps;
  xLargeScreensOnly?: BaseProps;
  as?: string;
};

const LayoutStyleFactory = (type: 'padding' | 'margin') => {
  const StyledSpacingComponent = (props: Props): IStyle =>
    applyModifiers(
      [!isEmpty(props.smallScreen), applyBreakpointProps(type, props.smallScreen, screenSmallOnly)],
      [
        !isEmpty(props.mediumScreenOnly),
        applyBreakpointProps(type, props.mediumScreenOnly, screenMediumOnly),
      ],
      [
        !isEmpty(props.mediumScreens),
        applyBreakpointProps(type, props.mediumScreens, screenMediumAndAbove),
      ],
      [
        !isEmpty(props.largeScreens),
        applyBreakpointProps(type, props.largeScreens, screenLargeAndAbove),
      ],
      [
        !isEmpty(props.largeScreensAndBelow),
        applyBreakpointProps(type, props.largeScreensAndBelow, screenLargeAndBelow),
      ],
      [
        !isEmpty(props.xLargeScreensOnly),
        applyBreakpointProps(type, props.xLargeScreensOnly, screenXLargeOnly),
      ],
      [props.inline, { display: 'inline-block' }],
    )(applyPaddingModifiers(type, props));

  return StyledSpacingComponent;
};

const StyledPaddingComponent = LayoutStyleFactory('padding');
const StyledMarginComponent = LayoutStyleFactory('margin');

function applyBreakpointProps(
  type: 'padding' | 'margin',
  baseProps: BaseProps,
  mediaQuery: (query: IStyle) => IStyle,
): IStyle {
  return mediaQuery(applyPaddingModifiers(type, baseProps));
}

function applyPaddingModifiers(
  type: 'padding' | 'margin',
  baseProps: BaseProps,
  style: IStyle = {},
): IStyle {
  const units = baseProps.units || 'px';
  const builtStyleRules = applyModifiers(
    [
      !isNil(baseProps.all),
      {
        [`${type}Top`]: `${baseProps.all}${units}`,
        [`${type}Bottom`]: `${baseProps.all}${units}`,
        [`${type}Left`]: `${baseProps.all}${units}`,
        [`${type}Right`]: `${baseProps.all}${units}`,
      },
    ],
    [
      !isNil(baseProps.top),
      {
        [`${type}Top`]: `${baseProps.top}${units}`,
      },
    ],
    [
      !isNil(baseProps.bottom),
      {
        [`${type}Bottom`]: `${baseProps.bottom}${units}`,
      },
    ],
    [
      !isNil(baseProps.left),
      {
        [`${type}Left`]: `${baseProps.left}${units}`,
      },
    ],
    [
      !isNil(baseProps.right),
      {
        [`${type}Right`]: `${baseProps.right}${units}`,
      },
    ],
    [
      !isNil(baseProps.removeForLastChild),
      {
        ['&:last-child' as any]: {
          [`${type}Top`]: 0,
          [`${type}Bottom`]: 0,
          [`${type}Left`]: 0,
          [`${type}Right`]: 0,
        },
      },
    ],
  )(style);

  if (baseProps.onlyOnSmall) {
    return screenSmallOnly(builtStyleRules);
  }

  if (baseProps.onlyLargeAndBelow) {
    return screenLargeAndBelow(builtStyleRules);
  }

  if (baseProps.notOnSmall) {
    return screenMediumAndAbove(builtStyleRules);
  }

  return builtStyleRules;
}

const PaddingComponent = createComponent<Props>(StyledPaddingComponent, 'div', ['aria-label']);
const MarginComponent = createComponent<Props>(StyledMarginComponent, 'div', ['aria-label']);

type LayoutComponentProps = Props & FelaInjectedProps<Props, any> & React.HTMLProps<HTMLDivElement>;

const LayoutComponentFactory = (
  LayoutComponent: ReactComponent<LayoutComponentProps>,
  displayName: string,
): React.FunctionComponent<LayoutComponentProps> => {
  const BuiltLayoutComponent: React.FunctionComponent<LayoutComponentProps> = ({
    applyToChildren,
    children,
    ...props
  }) => {
    const mappedChildren = React.Children.map(children, child => {
      if (applyToChildren) {
        return <LayoutComponent {...props}>{child}</LayoutComponent>;
      }

      return child;
    });

    if (applyToChildren) {
      return <>{mappedChildren}</>;
    }

    return <LayoutComponent {...props}>{children}</LayoutComponent>;
  };

  BuiltLayoutComponent.defaultProps = {
    top: null,
    bottom: null,
    left: null,
    right: null,
    smallScreen: {},
    mediumScreens: {},
    mediumScreenOnly: {},
    largeScreens: {},
    largeScreensAndBelow: {},
    xLargeScreensOnly: {},
    applyToChildren: false,
    units: 'px',
  };
  BuiltLayoutComponent.displayName = displayName;

  return BuiltLayoutComponent;
};

export const Padding = LayoutComponentFactory(PaddingComponent, 'Padding');
export const Margin = LayoutComponentFactory(MarginComponent, 'Margin');
