import React from 'react';
import { isNil } from 'lodash';

import { Container, Graph, Legend } from "components/echarts/legend";
import { CustomChart } from 'components/ds/charts';
import { Margin } from "components/layout";
import * as charting from "utils/echarts";
import { formatNumber } from "utils/numberFormatting";
import { DateRangeValues, getDefaultIntervalForDateRange } from 'modules/dashboard/constants';

import { AverageLoadtimeChartPayload } from "../../../actions/data/payloads";
import { TileDataProvider, Props as ProviderProps } from "../dataProvider";
import { ChartTileLoading } from "../loading";
import { NoData } from "../noData";

const Chart: React.FunctionComponent<ProviderProps> = (props) => {
  const { settings } = props;

  const data = {
    data: props.data,
    interval: getDefaultIntervalForDateRange(settings.DateRange as DateRangeValues) 
  } as AverageLoadtimeChartPayload;

  const periods: string[] = [];
  const mins: number[] = [];
  const avgs: number[] = [];
  const maxs: number[] = [];

  if (isNil(data.data) || data.data.length === 0) {
    return <NoData />;
  }

  data.data.forEach((item) => {
    periods.push(item.Period);
    mins.push(item.Timings[0]);
    avgs.push(item.Timings[1]);
    maxs.push(item.Timings[2]);
  });

  const options = (chart: any) => { 
    return charting.extendOptions({
      tooltip: {
        show: true,
        trigger: 'axis',
        formatter: (params: any[]) => {
          return charting.renderSeriesTooltip({
            date: charting.formatUtcTooltipDate(
              params[0].name,
              data.interval,
            ),
            series: params.map((s: any) => ({
              name: s.seriesName,
              value: `${formatNumber(s.value, 2)}s`,
              colorcode: s.color,
            })),
          });
        },
        axisPointer: charting.rum.AxisPointerLine
      },
      xAxis: [
        {
          type: 'category',
          splitLine: {
            show: false
          },
          boundaryGap: false,
          data: periods,
          axisLabel: {
            formatter: charting.getDateTimeLabelFormatter(
              periods,
              data.interval,
              chart ? chart.getEchartsInstance() : undefined,
            )
          },
        },
      ],
      yAxis: [{
        type: 'value',
        axisLabel: {
          formatter: charting.getIntegerLabelFormatter(
            chart ? chart.getEchartsInstance() : undefined,
            's'
          )
        }
      }],
      series: [
        {
          z: 3,
          name: "Fastest",
          symbol: 'none',
          type: "line",
          data: mins,
          color: charting.rum.highColor,
          areaStyle: {
            normal: {
              color: charting.distributionColor1,
              opacity: 1
            }
          }
        },
        {
          z: 2,
          name: "Normal",
          symbol: 'none',
          type: "line",
          data: avgs,
          color: charting.rum.mediumColor,
          areaStyle: {
            normal: {
              opacity: 1
            }
          }
        },
        {
          z: 1,
          name: "Slowest",
          symbol: 'none',
          type: "line",
          data: maxs,
          color: charting.rum.lowColor,
          areaStyle: {
            normal: {
              opacity: 1
            }
          }
        }
      ],
    });
  };

  return (
    <Container>
      <Graph positionInside>
        <CustomChart
          height={'100%'}
          options={options}
        />
        <Margin top={12}>
          <Legend
            legendItems={[
              { label: `10% Fastest`, colorCode: charting.rum.highColor },
              { label: `80% Average`, colorCode: charting.rum.mediumColor },
              { label: `10% Slowest`, colorCode: charting.rum.lowColor },
            ]}
          />
        </Margin>
      </Graph>
    </Container>
  );
};

const ConnectedTile = TileDataProvider(Chart, ChartTileLoading);

export { ConnectedTile as AverageLoadTimeChart };
