/** @format */

import * as React from 'react';

import { ThemeType } from 'app/theme';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

type Props = {
  type?: 'grey' | 'red' | 'dark' | 'azureHighlight' | 'blue' | 'success' | 'accepted' | 'yellow';
  paddingVertical?: '40' | '8' | string;
  paddingHorizontal?: string;
  paddingTopOnly?: boolean;
  shadow?: boolean;
  scrollX?: boolean;
  borderless?: boolean;
  rounded?: boolean;
  transparent?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const StyledPanel = (props: Props & ThemeType): IStyle => {
  const base: IStyle = {
    padding: '16px',
    borderRadius: '4px',
    border: `1px solid ${props.theme.colors.border.standard}`,
  };

  return applyModifiers(
    [
      props.type === 'grey',
      {
        backgroundColor: `${props.theme.colors.grey.lighter}`,
        borderColor: `${props.theme.colors.border.standard}`,
        ...applyModifiers([
          !!props.onClick,
          {
            cursor: 'pointer',
            backgroundColor: `${props.theme.colors.material.grey300}`,
            ':hover': {
              backgroundColor: `${props.theme.colors.material.grey200}`,
            },
            ':active': {
              backgroundColor: `${props.theme.colors.material.grey400}`,
            },
          },
        ])({}),
      },
    ],
    [
      props.type === 'yellow',
      {
        backgroundColor: '#FFF8E1',
        borderColor: '#FFBF00',
        borderLeftWidth: '6px',
        borderRadius: 0,
        padding: '20px 24px',
      },
    ],
    [
      props.type === 'dark',
      {
        backgroundColor: '#272822',
        borderColor: `#272822`,
      },
    ],
    [
      props.type === 'red',
      {
        backgroundColor: `${props.theme.colors.material.red50}`,
        borderColor: `${props.theme.colors.material.red500}`,
      },
    ],
    [
      props.type === 'blue',
      {
        backgroundColor: `${props.theme.colors.material.blue50}`,
        borderColor: `${props.theme.colors.material.blue500}`,
      },
    ],
    [
      props.type === 'azureHighlight',
      {
        backgroundColor: `${props.theme.colors.standard.azureHighlight}`,
        borderColor: `${props.theme.colors.standard.azureHighlight}`,
      },
    ],
    [
      props.type === 'success' || props.type === 'accepted',
      {
        borderColor: props.theme.colors.green.success,
        color: props.theme.colors.green.success,
      },
    ],
    [
      props.type === 'success',
      {
        backgroundColor: '#F5FAF5',
      },
    ],
    [
      props.type === 'accepted',
      {
        backgroundColor: `rgba(67, 160, 71, .1)`,
      },
    ],
    [
      props.borderless,
      {
        border: 'none',
      },
    ],
    [
      props.paddingTopOnly,
      {
        paddingTop: '8px',
        paddingBottom: '0',
      },
    ],
    [
      !!props.paddingVertical,
      {
        paddingTop: `${props.paddingVertical}px`,
        paddingBottom: `${props.paddingVertical}px`,
      },
    ],
    [
      !!props.paddingHorizontal,
      {
        paddingLeft: `${props.paddingHorizontal}px`,
        paddingRight: `${props.paddingHorizontal}px`,
      },
    ],
    [
      props.shadow,
      {
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      },
    ],
    [
      props.scrollX,
      {
        overflowX: 'auto',
      },
    ],
    [
      !props.rounded,
      {
        borderRadius: '0',
      },
    ],
    [
      props.transparent,
      {
        backgroundColor: 'transparent',
      },
    ],
  )(base);
};

export const Panel = createComponent<Props>(StyledPanel, 'div', ['onClick']);

Panel.defaultProps = {
  type: null,
  paddingTopOnly: false,
  paddingVertical: null,
  paddingHorizontal: null,
  borderless: false,
  shadow: false,
  scrollX: false,
  rounded: true,
  transparent: false,
};

export default Panel;
