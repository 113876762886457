/** @format **/

import * as React from 'react';
import { get } from 'lodash';

import { ReactComponent } from 'utils/types';

import { Padding } from 'components/layout';
import ContactRaygunMenuItem from "legacy/components/contactRaygunMenuItem/contactRaygunMenuItem";

import { InviteTeamSidebar } from 'modules/inviteTeamModal';
import { Components as WhatsNewComponents } from 'modules/whatsnew';
import { Features } from "modules/features";
import { isFeatureEnabled } from "modules/features/models";

import { ContainerProps } from '../index';

import * as Utils from './utils';
import SidebarLink, { LinkProperties, SecondaryLink } from './link';

interface LinkProps extends Partial<LinkProperties> {
  isDivider?: boolean;
  featureFlag?: string;
  filter?: () => boolean;
  id?: string;
  class?: string;
  component?: ReactComponent<{}>;
  openInNewTab?: boolean;
}

interface LinkStateProps {
  showAPMSecondaryLinks: boolean;
}

const isInOwnersTeam = get(window as any, 'RaygunConfiguration.IsInOwnersTeam', false);

const inviteTeamLinks = isInOwnersTeam
  ? [
      {
        component: InviteTeamSidebar,
      },
      {
        isDivider: true,
      },
    ]
  : [];

const isCRForShopify = isFeatureEnabled(Features.CrashReportingForShopify);

/**
 * These products are currently not available for Shopify, so we have to check if the plan
 * was signed up via Shopify in order to hide these tabs
**/
const productLinks = (APMSecondaryLinks: boolean) => !isCRForShopify ? [
  {
    text: 'Real User Monitoring',
    url: '/pulse/[appId]',
    svgSymbol: 'pulse',
    snowplowId: 'sp-app-chrome-sidebar-clickRumLink',
  },
  {
    text: 'APM',
    url: '/apm/[appId][uriQuery]',
    svgSymbol: 'proton',
    snowplowId: 'sp-app-chrome-sidebar-clickApmLink',
    secondaryLinks: APMSecondaryLinks
      ? [
        {
          text: 'Rules',
          url: '/settings/[appId]/apm/rules',
          snowplowId: 'sp-app-chrome-sidebar-clickApmRulesLink',
        },
        {
          text: 'Sampling',
          url: '/settings/[appId]/apm/sampling',
          snowplowId: 'sp-app-chrome-sidebar-clickApmSamplingLink',
        },
        {
          text: 'Code filtering',
          url: '/settings/[appId]/apm',
          snowplowId: 'sp-app-chrome-sidebar-clickApmCodeFilteringLink',
        },
        {
          text: 'Inbound filters',
          url: '/settings/[appId]/apm/filtering',
          snowplowId: 'sp-app-chrome-sidebar-clickApmInboundFiltersLink',
        },
      ]
      : [],
  },
] : [];

const generateLinkDefinition = (linkStateProps: LinkStateProps): LinkProps[] => [
  ...inviteTeamLinks,
  {
    text: 'Crash Reporting',
    url: '/crashreporting/[appId]',
    svgSymbol: 'cr',
    snowplowId: 'sp-app-chrome-sidebar-clickCrashReportingLink',
  },
  ...productLinks(linkStateProps.showAPMSecondaryLinks),
  {
    text: 'Customers',
    url: '/reporting/[appId]/customers',
    svgSymbol: 'users',
    snowplowId: 'sp-app-chrome-sidebar-clickCustomersLink',
  },
  {
    text: 'Deployments',
    url: '/dashboard/[appId]/deployments',
    svgSymbol: 'deployments',
    snowplowId: 'sp-app-chrome-sidebar-clickDeploymentsLink',
  },
  {
    isDivider: true,
  },
  {
    text: 'API',
    url: '/api/[appId]',
    svgSymbol: 'api',
    snowplowId: 'sp-app-chrome-sidebar-clickApiLink'
  },
  {
    text: 'Application settings',
    url: '/settings/[appId]/general',
    svgSymbol: 'settings',
    snowplowId: 'sp-app-chrome-sidebar-clickApplicationSettingsLink',
  },
  {
    text: 'Integrations',
    url: '/settings/[appId]/plugins',
    svgSymbol: 'integrations',
    snowplowId: 'sp-app-chrome-sidebar-clickIntegrationsLink',
  },
  {
    text: 'Feature requests',
    url: Utils.getFeatureRequestUrl(),
    svgSymbol: 'feature-request',
    openInNewTab: true,
    snowplowId: 'sp-app-chrome-sidebar-clickFeatureRequestsLink',
  },
  {
    component: WhatsNewComponents.MenuItem,
  },
  {
    isDivider: true,
  },
  {
    component: ContactRaygunMenuItem
  },
];

export const computeLinksToRender = (links: LinkProps[], featureFlags: string[]) => {
  return links.filter(l => {
    const featureFlag = !l.featureFlag || featureFlags.some(f => f === l.featureFlag);
    const additionalFilters = !l.filter || l.filter();

    return featureFlag && additionalFilters;
  });
};

class Sidebar extends React.Component<ContainerProps, {}> {
  public static defaultProps: Partial<ContainerProps> = {
    activeLink: '',
  };

  render() {
    const { featureFlags } = this.props;

    const linkDefinition = generateLinkDefinition({
      showAPMSecondaryLinks: true,
    });

    const linksToRender = computeLinksToRender(linkDefinition, featureFlags);

    const links = linksToRender.map((sidebar, index) => {
      if (sidebar.isDivider) {
        return (
          <li key={index} aria-hidden="true">
            <span className="nav-divider">
              <span className="nav-divider__text">Divider</span>
            </span>
          </li>
        );
      } else if (sidebar.component) {
        const Component = sidebar.component;
        return <Component key={index} />;
      } else {
        return (
          <SidebarLink
            key={index}
            text={sidebar.text}
            url={this.buildUrl(sidebar.url)}
            svgSymbol={sidebar.svgSymbol}
            secondaryLinks={this.buildSecondaryUrls(sidebar.secondaryLinks)}
            listItemClasses={sidebar.listItemClasses}
            active={this.props.activeLink === sidebar.text}
            featureFlags={this.props.featureFlags}
            id={sidebar.id}
            zendeskClass={sidebar.class}
            pillText={sidebar.pillText}
            pillColor={sidebar.pillColor}
            openInNewTab={sidebar.openInNewTab}
            snowplowId={sidebar.snowplowId}
          />
        );
      }
    });

    return (
      <>
        <div>
          <Padding top={8}>
            <ul>{links}</ul>
          </Padding>
        </div>
      </>
    );
  }

  buildSecondaryUrls(links: SecondaryLink[] = []) {
    return links.map((val: SecondaryLink) => ({
      text: val.text,
      url: this.buildUrl(val.url),
      active: this.props.activeSecondaryLink === val.text,
      featureFlag: val.featureFlag,
      snowplowId: val.snowplowId,
    }));
  }

  buildUrl(url: string) {
    return url
      .replace('[appId]', this.props.appId)
      .replace('[planId]', this.props.planId)
      .replace(
        '[uriQuery]',
        this.props.uriQuery && this.props.activeLink === 'APM' ? `?${this.props.uriQuery}` : '',
      );
  }
}

export default Sidebar;
