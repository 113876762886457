/** @format **/

import * as React from 'react';

import Panel from 'components/panel';

interface Props {
  text: string;
}

export const NoData: React.FunctionComponent<Props> = props => {
  return (
    <Panel type="grey" borderless paddingVertical="40">
      <p className="text-center">
        <strong>{props.text}</strong>
      </p>
    </Panel>
  );
};
