/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { Theme } from 'app/theme';
import { Margin } from 'components/layout';
import { applyModifiers } from 'fela-rules/modifiers';

import { SharedProps } from './base';

type PublicBaseToggleButtonGroupProps = SharedProps;

type BaseToggleButtonGroupProps = PublicBaseToggleButtonGroupProps;

const StyledFelaBaseToggleButton = (props: BaseToggleButtonGroupProps): IStyle =>
  applyModifiers([
    props.small,
    {
      height: '32px',
    },
  ])({
    backgroundColor: Theme.ds.getColorByName('blueGrey5'),
    borderRadius: '2px',
    padding: '4px',
    height: '40px',
    display: 'inline-flex',
    alignItems: 'center',
  });

const FelaToggleButtonGroup = createComponentWithProxy<BaseToggleButtonGroupProps>(
  StyledFelaBaseToggleButton,
);

class BaseToggleButtonGroup extends React.Component<PublicBaseToggleButtonGroupProps, {}> {
  render() {
    const { ...props } = this.props;
    const children = React.Children.map(this.props.children, (child: React.ReactElement<any>) => {
      return React.cloneElement(child, {
        disabled: this.props.disabled,
      });
    });

    return (
      <FelaToggleButtonGroup {...props}>
        <Margin right={8} applyToChildren removeForLastChild>
          {children}
        </Margin>
      </FelaToggleButtonGroup>
    );
  }
}

export { BaseToggleButtonGroup, PublicBaseToggleButtonGroupProps as BaseToggleButtonGroupProps };
