/** @format **/

import React from 'react';

import { SystemTeams } from 'sections/productpages/teams/models';

import { Flex, FlexBlock } from 'components/flex';
import { Padding } from 'components/layout';

import { Input } from 'components/ds/inputs';
import { Dropdown } from 'components/ds/dropdown/triggers';
import { MenuItem } from 'components/ds/dropdown/menu';

export type Props = {
  emailAddress: string;
  accessLevel: SystemTeams;
  updateAccessLevel: (accessLevel: SystemTeams) => void;
  updateEmailAddress: (emailAddress: string) => void;
  id: string;
};

export const RecipientInputRow: React.FunctionComponent<Props> = props => {
  const triggerText = props.accessLevel === 'Users' ? 'User' : 'Owner';
  return (
    <Flex>
      <FlexBlock grow={1} shrink={1}>
        <Input
          id={props.id}
          type="email"
          placeholder="Email address"
          onChange={e => props.updateEmailAddress(e.target.value)}
          value={props.emailAddress}
        />
      </FlexBlock>
      <FlexBlock grow={0} shrink={0} basis={'134px'}>
        <Padding left={8}>
          <Dropdown position={'right'} triggerText={triggerText} block fullWidth>
            <MenuItem label={'User'} onClick={() => props.updateAccessLevel(SystemTeams.Users)} />
            <MenuItem
              label={'Owner/Admin'}
              onClick={() => props.updateAccessLevel(SystemTeams.Owners)}
            />
          </Dropdown>
        </Padding>
      </FlexBlock>
    </Flex>
  );
};

RecipientInputRow.defaultProps = {
  emailAddress: '',
  accessLevel: SystemTeams.Users,
};
