/** @format */

import * as React from 'react';
import cx from 'classnames';
import { Icon, IconAfter } from 'components/icon';

interface Props {
  title: React.ReactNode;
  left?: boolean;
  ellipsis?: boolean;
  docsLink?: string;
  designSystemHeight?: boolean;
}

export const Title: React.FunctionComponent<Props> = props => {
  const classes = cx('page-header__title', {
    'page-header__title--left': props.left,
    'page-header__title--ds-height': props.designSystemHeight,
  });

  const h1Classes = cx('heading-size-3', 'text-bold', {
    'text-ellipsis': props.ellipsis,
  });

  if (!props.docsLink) {
    return (
      <div className={classes}>
        <h1 className={h1Classes}>{props.title}</h1>
      </div>
    );
  }

  return (
    <div className={classes}>
      <h1 className={h1Classes}>
        <IconAfter
          size="32"
          icon={
            <a href={props.docsLink} target="_blank">
              <Icon type="question" size={16} />
            </a>
          }
        >
          {props.title}
        </IconAfter>
      </h1>
    </div>
  );
};

Title.defaultProps = {
  ellipsis: true,
  designSystemHeight: false,
  docsLink: null,
};
