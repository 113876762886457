/** @format */

import * as React from 'react';
import { assign, isNil } from 'lodash';
import { To } from 'redux-first-router-link';

import { CellBase } from '../tableModel';
import { Link } from 'components/link';

export class IssueLinkCell extends CellBase {
  path: (rowItem: any) => To;
  className: string;
  manualTitle?: (rowItem: any) => string;

  constructor(init?: Partial<IssueLinkCell>) {
    super(init);
    assign(this, init);
  }

  render(rowItem: any) {
    const text = this.getValue(rowItem);
    const title = !isNil(this.manualTitle) ? this.manualTitle(rowItem) : text;

    return (
      <div className="text-ellipsis line-height-24" title={title}>
        <Link to={this.path(rowItem)} className={this.className}>
          {text}
        </Link>
      </div>
    );
  }
}
