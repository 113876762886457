/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';

const StyledModuleInner = (props: ThemeType): IStyle => ({
  backgroundColor: `${props.theme.colors.grey.lighter}`,
  padding: `16px`,
});

export const ModuleInner = createComponent(StyledModuleInner);
