/** @format **/

import qs from 'qs';
import { get } from 'lodash';

export function getQueryStringObject(urlSearchQuery = window.location.search) {
  return qs.parse(urlSearchQuery, { ignoreQueryPrefix: true });
}

export function getQueryStringParam(
  key: string,
  defaultValue: any = null,
  urlSearchQuery = window.location.search,
) {
  const obj = getQueryStringObject(urlSearchQuery);

  return get(obj, key, defaultValue);
}

export function sanitizeQueryString(
  key: string
) {
  const currentQueryString = window.location.search;
  const searchParams = new URLSearchParams(currentQueryString);

  searchParams.delete(key);

  const sanitizedQueryString = searchParams.toString();

  const newUrl = `${window.location.pathname}${sanitizedQueryString}`;
  window.history.replaceState(null, '', newUrl);
}