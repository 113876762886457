/** @format **/

import * as React from 'react';

import { Dropdown } from 'components/dropdown';

import ButtonContainer from '../containers/userButton';
import ContentContainer from '../containers/userContent';

const User: React.FunctionComponent<{}> = () => {
  return (
    <Dropdown dockOption="right" button={<ButtonContainer />}>
      <ContentContainer />
    </Dropdown>
  );
};

export default User;
