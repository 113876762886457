/** @format */
import React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';

import { Application } from 'interfaces/application';
import { AppState } from 'interfaces/appState';
import { Margin } from 'components/layout';

import { getFilteredApplicationsForPlan, getFilteredFavouriteApplicationsForPlan } from '../../../selectors';
import { ApplicationRow, NoData } from '../row';
import { Favourite } from './favourite';

type StateProps = {
  applications: Application[];
};

type DispatchProps = {};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedAllApplications: React.FunctionComponent<Props> = ({ applications }) => (
  <Margin top="40" as='nav' aria-label="All applications">
      {map(applications, (a) => (
        <ApplicationRow 
          key={a.id}
          {...a}
        />
      ))}
    {applications.length === 0 && (     
      <NoData>There are no applications</NoData>
    )}
  </Margin>
);

export const UnconnectedFavouriteApplications: React.FunctionComponent<Props> = ({ applications }) => (
  <>
    {applications.length > 0 && (
      <Margin bottom="40" as='nav' aria-label="Favourite applications">
        <Favourite>Favorites</Favourite>
        {map(applications, (a) => (
          <ApplicationRow 
            key={a.id}
            {...a}
          />
        ))}
      </Margin>
    )}
  </>
);

export const AllApplications = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => ({
    applications: getFilteredApplicationsForPlan(state)
  })
)(UnconnectedAllApplications);


export const FavouriteApplications = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => ({
    applications: getFilteredFavouriteApplicationsForPlan(state)
  })
)(UnconnectedFavouriteApplications);