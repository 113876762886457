/**
 * @prettier
 */

import { get, find, findIndex } from 'lodash';

import { createSelector } from 'reselect';
import { getFetchStatus } from 'selectors/shared';
import { DashboardModuleStateAtom } from '../state';
import { TileSettings, DashboardTypes } from 'modules/dashboard/models';
import { getApplicationsForCurrentPlan } from 'app/selectors/application';

const configSelector = (state: DashboardModuleStateAtom) => state.dashboard.layout;

export const getCurrent = createSelector(
  configSelector,
  config => config.current,
);

export const getCurrentLayout = createSelector(
  getCurrent,
  current => current.Tiles,
);

export const getCurrentTileCount = createSelector(
  getCurrentLayout,
  tiles => tiles.length,
);

export const getEditState = createSelector(
  configSelector,
  config => config.isEditing,
);

export const dashboardIdentifier = createSelector(
  configSelector,
  config => config.id,
);

export const getTitle = createSelector(
  configSelector,
  config => config.current.Title,
);

export const getCurrentTitle = createSelector(
  getCurrent,
  current => current.Title,
);

export const getCurrentColors = createSelector(
  getCurrent,
  current => current.Colors,
);

export const hasPendingUpdates = createSelector(
  configSelector,
  config => config.hasUpdates,
);

export const getFetchable = createSelector(
  configSelector,
  config => getFetchStatus(config),
);

export const isPrimary = createSelector(
  configSelector,
  config => config.isPrimary,
);

export const isFullscreen = createSelector(
  configSelector,
  config => config.isFullscreen,
);

export const isTimeboard = createSelector(
  configSelector,
  config => config.current.Type === DashboardTypes.Timeboard,
);
export const getGlobalSettings = createSelector(
  configSelector,
  config => config.globalSettings,
);
export const getGlobalDateRange = createSelector(
  getGlobalSettings,
  settings => settings.dateRange,
);
export const getGlobalApplicationIds = createSelector(
  getGlobalSettings,
  settings => settings.applicationIds || [],
);
export const hasGlobalApplicationIds = createSelector(
  getGlobalApplicationIds,
  ids => ids.length > 0,
);
export const getGlobalApplicationNames = createSelector(
  getGlobalApplicationIds,
  getApplicationsForCurrentPlan,
  (ids, apps) => {
    const existingIds = ids.filter(id => findIndex(apps, a => a.id == id) > -1);
    return existingIds.map(id => apps.find(a => a.id == id).name);
  },
);
export const getGlobalApplications = createSelector(
  hasGlobalApplicationIds,
  getGlobalApplicationNames,
  (hasApplications, names) => (hasApplications ? names.join(', ') : 'none'),
);

export const getFetchLayoutRoute = createSelector(
  getGlobalSettings,
  globalSettings => globalSettings.fetchLayoutRoute,
);

export const settingsAllowed = createSelector(
  getGlobalSettings,
  globalSettings => globalSettings.allowSettings,
);

export const getTileSettings = (tileType: string) =>
  createSelector(
    getCurrentLayout,
    layout => get(find(layout, { Type: tileType }), 'Settings') as TileSettings,
  );

export const showNoTileMessage = createSelector(
  getCurrentTileCount,
  getFetchable,
  (count, fetchable) => count === 0 && !fetchable.isFetching,
);
