/** @format */

import React, { useLayoutEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

export type FadeProps = {
  appear?: boolean;
  visible: boolean;
  duration: number;
  onExited?: () => void;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  fadeIn?: boolean;
  fadeOut?: boolean;
  easing?: string;
  withTimeout?: boolean;
  opacity?: number;
};

export const Fade: React.FunctionComponent<FadeProps> = props => {
  const {
    appear,
    children,
    mountOnEnter,
    onExited,
    duration,
    unmountOnExit,
    visible,
    withTimeout,
    easing,
    opacity,
  } = props;
  const [rendered, setRendered] = useState(false);

  useLayoutEffect(() => {
    if (withTimeout) {
      // This timeout is here due to the component needed to use the default styles on first render.
      setTimeout(() => setRendered(true), 0);
    } else {
      setRendered(true);
    }
  }, []);

  const defaultStyles = {
    transition: `opacity ${duration}ms ${easing}`,
    opacity: props.fadeIn ? 0 : opacity,
    visibility: props.fadeIn ? 'hidden' : 'visible',
  };
  const transitionStyles: any = {
    entering: { opacity, visibility: 'visible' },
    entered: { opacity, visibility: 'visible' },
    exiting: { opacity: props.fadeOut ? 0 : opacity, visibility: 'visible' },
    exited: { opacity: 0, visibility: 'hidden' },
  };
  const child = React.Children.only(children) as any;

  return (
    <Transition
      in={visible}
      timeout={duration}
      appear={appear}
      onExited={onExited}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
    >
      {(state: any) => {
        const style = {
          ...defaultStyles,
          ...(rendered ? transitionStyles[state] : {}),
        };
        return React.cloneElement(child, { style });
      }}
    </Transition>
  );
};
Fade.defaultProps = {
  appear: false,
  onExited: () => undefined,
  mountOnEnter: false,
  unmountOnExit: false,
  fadeIn: true,
  fadeOut: true,
  opacity: 1,
  easing: 'ease-in-out',
};
