/**
 * @prettier
 */

import * as React from 'react';
import classnames from 'classnames';

type Props = {
  removeVerticalPadding?: boolean;
  removeTopPadding?: boolean;
};

export const Container: React.FunctionComponent<Props> = props => {
  const classes = classnames('page-container', {
    ['page-container--no-padding-vertical']: props.removeVerticalPadding,
    ['page-container--no-padding-top']: props.removeTopPadding,
  });

  return <article className={classes}>{props.children}</article>;
};
