/** @format */

import { isNil, includes, some, identity, get, memoize } from 'lodash';

import { Configuration } from 'interfaces/configuration';
import { getTargetValueFromCookie } from 'utils/cookies';

// Go fishing for the active application id
export function findApplicationId(rgConfig: Configuration, LastViewedAppId: string) {
  const appId = get(rgConfig, 'ApplicationIdentifier');
  const defaultAppId = get(rgConfig, 'DefaultApplicationIdentifier');

  const appIdIsNull = isNil(appId);
  const cookieIdIsNull = isNil(LastViewedAppId);
  const defaultIdIsNull = isNil(defaultAppId);

  return !appIdIsNull
    ? appId
    : !cookieIdIsNull
    ? LastViewedAppId
    : !defaultIdIsNull
    ? defaultAppId
    : '';
}

// Get the rg.userdata from cookies
export function parseRgUserdata(cookie: string) {
  if (isNil(cookie) || cookie === '') {
    return {};
  } else {
    const rgUserdataValue = getTargetValueFromCookie(cookie, 'rg.userdata');

    if (isNil(rgUserdataValue)) {
      return {};
    } else {
      return JSON.parse(rgUserdataValue);
    }
  }
}

// Get the last viewed app ID from cookie
export function getLastViewedApplicationIdentifier(cookie: string = document.cookie) {
  return get(parseRgUserdata(cookie), 'LastViewedApplicationIdentifier');
}

// Decode the active product from a uri
export function decodeActiveArea(uri: string = window.location.pathname) {
  const splitUrl = uri.split('/');

  // various names for our products
  const dashboardNames = ['tiledashboard'];
  const crNames = [
    'crashreporting',
    'crash',
    'cr',
    'symbols',
    'jssymbols',
    'proguardsymbols',
    'breakpad',
  ];
  const rumNames = ['pulse', 'pulseinsights', 'rum'];
  const apmNames = ['apm'];
  const deploymentNames = ['deployments'];
  const usersNames = ['affectedusers', 'errorsforuser', 'customers'];
  const teamsNames = ['teams'];
  const whatsNewNames = ['whats-new'];
  const integrationsNames = ['plugins'];

  if (some(dashboardNames.map(name => includes(splitUrl, name)), identity)) {
    return 'dashboards';
  } else if (some(crNames.map(name => includes(splitUrl, name)), identity)) {
    return 'crash';
  } else if (some(rumNames.map(name => includes(splitUrl, name)), identity)) {
    return 'rum';
  } else if (some(apmNames.map(name => includes(splitUrl, name)), identity)) {
    return 'apm';
  } else if (some(deploymentNames.map(name => includes(splitUrl, name)), identity)) {
    return 'deployments';
  } else if (some(usersNames.map(name => includes(splitUrl, name)), identity)) {
    return 'users';
  } else if (some(teamsNames.map(name => includes(splitUrl, name)), identity)) {
    return 'teams';
  } else if (some(whatsNewNames.map(name => includes(splitUrl, name)), identity)) {
    return 'whatsNew';
  } else if (some(integrationsNames.map(name => includes(splitUrl, name)), identity)) {
    return 'integrations';
  } else {
    return null;
  }
}

// Check if we are on a '*/user*' page
export const isOnUserSettingsPage = memoize(function(): boolean {
  const pathname = window.location.pathname;
  return urlContains('user', pathname) && isNil(decodeActiveArea(pathname));
});

// Check if we are on a '*/auditlog*' page
export const isOnAuditLogPage = memoize(function(): boolean {
  const pathname = window.location.pathname;
  return urlContains('auditlog', pathname) && isNil(decodeActiveArea(pathname));
});

// Check URL for specific target
export function urlContains(target: string, pathname: string = window.location.pathname) {
  return pathname.indexOf(target) > -1;
}
