/**
 * @prettier
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { ASCENDING } from 'constants/sorting';
import { Formatting } from 'utils/date';
import { CrashReporting } from 'utils/linking';

import { Column, SortedTable } from 'components/table/index';
import { NumberCell } from 'components/table/cells';
import { default as Text } from 'components/text/index';
import { Padding } from 'components/layout';

import { DashboardModuleStateAtom } from '../../../../state';
import * as Actions from '../../../../actions';
import * as Selectors from '../../../../selectors';
import { SortingHoCProps } from '../../../sortingHoC';

import { SelectedCellFactory } from './selectedCell';
import { ActionBar, CheckboxHeader } from './actionBar';

type StateProps = {
  hasSelection: boolean;
};

type PassedProps = {
  data: Actions.ErrorGroupListData[];
  drilldown?: boolean;
  id?: string;
  appId?: number;
};

type DispatchProps = {};

type Props = StateProps & PassedProps & DispatchProps & SortingHoCProps;

const MessageCellFactory = (
  displayName: boolean,
): React.FunctionComponent<Actions.ErrorGroupListData> => props => {
  const errorGroupLink = CrashReporting.errorGroupById(props.ApplicationId, props.Id);

  return (
    <>
      <Text size="medium" ellipsis>
        <a href={errorGroupLink}>{props.Message}</a>
      </Text>
      {displayName && (
        <Padding top="4">
          <Text size="small" color="grey">
            {props.ApplicationName}
          </Text>
        </Padding>
      )}
    </>
  );
};

const TimeAgoCellFactory = (): React.FunctionComponent<Actions.ErrorGroupListData> => props => (
  <Text size="medium" title={Formatting.formatAsISOString(props.LastOccurredOn)}>
    {Formatting.formatAsTimeAgo(props.LastOccurredOn)}
  </Text>
);

export const UnconnectedErrorGroupTable: React.FunctionComponent<Props> = props => {
  const MessageCell = MessageCellFactory(!props.drilldown);

  const TimeAgoCell = TimeAgoCellFactory();

  const SelectedCell = SelectedCellFactory(props.id);

  const columns: Column<Actions.ErrorGroupListData>[] = [
    {
      header: <CheckboxHeader id={props.id} drilldown={props.drilldown} appId={props.appId} />,
      width: 40,
      cell: SelectedCell,
      cellName: 'Modify',
      padding: 'none',
    },
    {
      header: 'Message',
      cell: MessageCell,
      flatLeft: true,
      sortOptions: {
        defaultSortDirection: ASCENDING,
        sortField: 'Message',
      },
    },
    {
      header: 'Last seen',
      width: 120,
      cell: TimeAgoCell,
      sortOptions: {
        defaultSortDirection: ASCENDING,
        sortField: 'LastOccurredOn',
      },
    },
    {
      header: 'Count',
      width: 80,
      cell: new NumberCell({
        accessor: (rowItem: Actions.ErrorGroupListData) => rowItem.ErrorCount.toLocaleString(),
      }),
      sortOptions: {
        defaultSortDirection: ASCENDING,
        sortField: 'ErrorCount',
      },
    },
    {
      header: 'Users',
      width: 80,
      cell: new NumberCell({
        accessor: (rowItem: Actions.ErrorGroupListData) => rowItem.UserCount.toLocaleString(),
      }),
      sortOptions: {
        defaultSortDirection: ASCENDING,
        sortField: 'UserCount',
      },
    },
  ];

  const header = <ActionBar drilldown={props.drilldown} appId={props.appId} id={props.id} />;

  return (
    <>
      {props.hasSelection ? header : null}
      <SortedTable
        isHeaderHidden={props.hasSelection}
        gutters
        columns={columns}
        data={props.data}
        padding="xlarge"
        sortField={props.sortField}
        sortDirection={props.sortDirection}
        getSortField={(item: Actions.ErrorGroupListData, field: keyof Actions.ErrorGroupListData) =>
          item[field]
        }
        onSortChange={(sortField, sortDirection) => props.updateSorting(sortField, sortDirection)}
      />
    </>
  );
};

const ConnectedTable = connect<StateProps, DispatchProps, PassedProps>(
  (state: DashboardModuleStateAtom, ownProps: PassedProps) => ({
    hasSelection: get(Selectors.getSelectedErrorGroups(state), ownProps.id, []).length > 0,
  }),
)(UnconnectedErrorGroupTable);

export { ConnectedTable as ErrorGroupTable };
