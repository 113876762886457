/** @format */

import { createRoutingAction } from 'utils/routing';

export const GoToPrivacyAndCompliance = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_PRIVACY_AND_COMPLIANCE',
);
export const GoToDPASignStep = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_DPA_SIGNING_STEP',
);
export const GoToGDPRNominationStep = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_GDPR_NOMINATION_STEP',
);

export const GoToUsage = createRoutingAction<{ planIdentifier: string }>('GO_TO_USAGE');
export const GoToUsageForProduct = createRoutingAction<{
  planIdentifier: string;
  productType: string;
}>('GO_TO_USAGE_FOR_PRODUCT');

export const GoToSubscriptionPage = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_SUBSCRIPTION_PAGE',
);

export const GoToCheckoutPage = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_CHECKOUT_PAGE',
);

export const GoToPaymentDetailsPage = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_PAYMENT_DETAILS_PAGE',
);
