/** @format **/

import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Modal, ModalFooter, ModalHeader, ModalProps } from 'components/modal';
import { Flex } from 'components/flex';
import { Margin } from 'components/layout';
import { TextButton, EmphasisButton } from 'components/ds/button';

import { getDefinitionForTile } from '../constants';
import { TileDefinition, TileSettings } from '../models';
import * as Selectors from '../selectors';
import * as Actions from '../actions';
import { DashboardModuleStateAtom } from '../state';

import { TileSettingsEditor } from './settingsEditor';

type StateProps = {
  tileValid: boolean;
  tile: TileDefinition;
  tileId: string;
};
type DispatchProps = {
  updateTile: () => void;
};
type SuppliedProps = {
  tileInstanceId: string;
  tileSettings: TileSettings;
} & ModalProps;
type Props = StateProps & DispatchProps & SuppliedProps;

const EditTileModal: React.FunctionComponent<Props> = ({
  tile,
  updateTile,
  tileValid,
  tileSettings,
  ...modalProps
}) => {
  const updateOnClick = () => {
    updateTile();
    modalProps.close(modalProps.id);
  };
  const header = (
    <ModalHeader>
      <h4>Editing tile {tileSettings.Title}</h4>
    </ModalHeader>
  );
  const footer = (
    <ModalFooter>
      <Flex justify="flex-end">
        <TextButton onClick={() => modalProps.close(modalProps.id)}>Cancel</TextButton>

        <Margin left="16">
          <EmphasisButton disabled={!tileValid} onClick={updateOnClick}>
            Update tile
          </EmphasisButton>
        </Margin>
      </Flex>
    </ModalFooter>
  );

  return (
    <Modal header={header} footer={footer} closeOnBackdropClick={false} {...modalProps}>
      <TileSettingsEditor tile={tile} initialSettings={tileSettings} />
    </Modal>
  );
};

const ConnectedEditTileModal = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: DashboardModuleStateAtom) => {
    const tileId = Selectors.tileSetting.getTileId(state);
    return {
      tileId,
      tileValid: Selectors.tileSetting.isValid(state),
      tile: getDefinitionForTile(tileId),
    };
  },
  (dispatch: Dispatch, ownProps: SuppliedProps) => ({
    updateTile: () =>
      dispatch(Actions.tileSetting.updateTile({ tileInstanceId: ownProps.tileInstanceId })),
  }),
)(EditTileModal);

export { ConnectedEditTileModal as EditTileModal };
