/** @format */
/* tslint:disable:max-file-line-count */

import { createSelector } from 'reselect';
import { find, get, isNil } from 'lodash';

import { AppState } from 'interfaces/appState';
import { Environments, Environment } from 'interfaces/environments';
import { User } from 'interfaces/user';
import { PlanLevel } from "interfaces/plan";

import { getDefaultPlanId } from 'utils/plans';
import { toIdentifier } from 'utils/linking';

export { ErrorDashboardPlugin } from 'interfaces/configuration';

const appSelector = (state: AppState) => state.app;

const configurationSelector = createSelector(
  appSelector,
  app => app.configuration,
);

export const errorDashboardPlugins = createSelector(
  configurationSelector,
  config => config.ErrorDashboardPlugins,
);

export const enabledApmIntegrations = createSelector(
  configurationSelector,
  config => config.EnabledApmIntegrations,
);

export const hasSourceCodeIntegration = createSelector(
  enabledApmIntegrations,
  s =>
    s.indexOf('github') > -1 || s.indexOf('bitbucket') > -1 || s.indexOf('visualstudioonline') > -1,
);

export const getSourceCodeIntegrations = createSelector(
  enabledApmIntegrations,
  hasSourceCodeIntegration,
  s =>
   hasSourceCodeIntegration ? s : null,
);

export const getDashboardIdentifier = createSelector(
  configurationSelector,
  config => config.DashboardIdentifier,
);

export const getDashboardId = createSelector(
  configurationSelector,
  config => config.DashboardId,
);

export const getAppId = createSelector(
  configurationSelector,
  config => config.ApplicationId,
);

export const getAppIdentifier = createSelector(
  configurationSelector,
  config => config.ApplicationIdentifier,
);

export const getPlanId = createSelector(
  configurationSelector,
  config => config.PlanId,
);

export const getPlanIdentifier = createSelector(
  configurationSelector,
  config => config.PlanIdentifier,
);

export const getPlanTitle = createSelector(
  configurationSelector,
  config => config.PlanTitle,
);

export const getFeatureFlags = createSelector(
  configurationSelector,
  config => config.Features,
);

export const hasTimeboardFeatureFlag = createSelector(
  getFeatureFlags,
  ff => ff.indexOf('TimeboardDashboards') > -1,
);

export const planContainsLegacyProduct = createSelector(
  getFeatureFlags,
  ff => ff.indexOf('EnableCrossLegacyAndPricing2023Support') > -1,
);

export const hasPricing2023FeatureFlag = createSelector(
  getFeatureFlags,
  ff => ff.indexOf('Pricing2023') > -1,
);

export const hasSelfServeSSOFeatureFlag = createSelector(
  getFeatureFlags,
  ff => ff.indexOf('SelfServeSingleSignOn') > -1,
);

export const getCrashReportingHasReceivedData = createSelector(
  configurationSelector,
  config => config.ApplicationHasReceivedErrors,
);

export const getRUMHasReceivedData = createSelector(
  configurationSelector,
  config => config.ApplicationHasReceivedPulseEvents,
);

export const getAPMHasReceivedData = createSelector(
  configurationSelector,
  config => config.ApplicationHasReceivedTraces,
);

export const allProductsHaveReceivedData = createSelector(
  getCrashReportingHasReceivedData,
  getRUMHasReceivedData,
  getAPMHasReceivedData,
  (crash, rum, apm) => crash && rum && apm,
);

export const crashOnTrial = createSelector(
  configurationSelector,
  config => config.CrashIsInTrial,
);


export const rumOnTrial = createSelector(
  configurationSelector,
  config => config.PulseIsInTrial,
);


export const apmOnTrial = createSelector(
  configurationSelector,
  config => config.APMIsInTrial,
);

export const allProductsOnTrial = createSelector(
  configurationSelector,
  config => config.CrashIsInTrial && config.PulseIsInTrial && config.APMIsInTrial,
);

export const getPlatformId = createSelector(
  configurationSelector,
  config => config.PlatformId,
);

export const getEnvironment = createSelector(
  getPlatformId,
  platformId => find(Environments, (i: Environment) => i.id == platformId),
);

export const getPlans = createSelector(
  configurationSelector,
  config => config.Plans,
);

export const hasMultiplePlans = createSelector(
  getPlans,
  plans => plans.length > 1,
);

export const getPlanForPage = createSelector(
  getPlans,
  plans => {
    const planIdForPage = getDefaultPlanId();
    return find(plans, p => p.Id === planIdForPage);
  },
);

export const hasBasicPlan = createSelector(
  getPlanForPage,
  plan => {
    return plan ? plan.PlanLevelEnum === 'Basic' : false;
  },
);

export const isTrialLevelPlan = createSelector(
  getPlanForPage,
  plan => {
    return plan ? plan.PlanLevel === PlanLevel.FreeAndTrial : false; 
  }
);

export const getExternalProviderStatusForPlan = createSelector(
  getPlans,
  getPlanIdentifier,
  (plans, planIdentifier) => {
    const currentPlan = find(plans, p => p.Id === parseInt(planIdentifier, 36));
    return currentPlan && currentPlan.IsUsingExternalProvider;
  },
);

export const getHerokuStatusForPlan = createSelector(
  getPlans,
  getPlanIdentifier,
  (plans, planIdentifier) => {
    const currentPlan = find(plans, p => p.Id === parseInt(planIdentifier, 36));
    return currentPlan && currentPlan.IsHeroku;
  },
);

export const isOnPlanLevelPage = createSelector(
  getPlanForPage,
  plan => !isNil(plan),
);

export const isInOwnersTeam = createSelector(
  configurationSelector,
  config => config.IsInOwnersTeam,
);

export const hasReachedUserLimit = createSelector(
  configurationSelector,
  config => config.HasReachedUserLimit,
);

export const canInviteUsers = createSelector(
  isInOwnersTeam,
  hasReachedUserLimit,
  (isInOwnersTeam, hasReachedUserLimit) => isInOwnersTeam && !hasReachedUserLimit,
);

// TODO: Strip this out.
export const showRevampedNavigation = (_state: any) => false;

// Get the ASP.NET anti-forgery token out of the DOM
// Note: This is required in the payload for requests to endpoints with the [ValidateAntiForgeryToken] attribute
export const getAntiForgeryToken = createSelector(
  () =>
    document.querySelector('#global-anti-forgery-token input[name="__RequestVerificationToken"]'),
  input => get(input, 'value'),
);

export const getRequestVerificationTokenObject = createSelector(
  getAntiForgeryToken,
  token => ({
    __RequestVerificationToken: token,
  }),
);

export const getUser = createSelector(
  configurationSelector,
  (config): User => ({
    fullName: config.UsersName,
    id: config.UsersId,
    identifier: toIdentifier(config.UsersId),
    avatarUrl: config.AvatarUrl,
    external: false,
  }),
);

export const getPage = createSelector(
  configurationSelector,
  config => config.Page,
);

export const hasDeploymentsEnabled = createSelector(
  configurationSelector,
  config => config.DeploymentsEnabled,
);

export const getAppAvatarUrl = createSelector(
  configurationSelector,
  config => config.ApplicationAvatarUrl,
);

export const hasRumEnabled = createSelector(
  configurationSelector,
  config => config.PulseUnlocked,
);

export const hasCrEnabled = createSelector(
  configurationSelector,
  config => config.CrashReportingUnlocked,
);

export const defaultApplicationIdentifier = createSelector(
  configurationSelector,
  config => config.DefaultApplicationIdentifier,
);

export const isFeatureGated = createSelector(
    hasBasicPlan,
    planContainsLegacyProduct,
    hasPricing2023FeatureFlag,
    (hasBasicPlan, planContainsLegacyProduct, hasPricing2023FeatureFlag) => {
        if (!planContainsLegacyProduct && hasBasicPlan && hasPricing2023FeatureFlag) {
            return true;
        } else if (planContainsLegacyProduct && hasBasicPlan && hasPricing2023FeatureFlag) {
            // we want customers where they a plan with 1 or more bonus events product (which is at a basic level)
            // and one or more legacy roduct to be able to use all the features.
            return false;
        } else {
            return false;
        }
    },
);