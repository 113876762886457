/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import React from 'react';

import { Icon } from 'components/icon';

import { BaseToggleButtonGroup, BaseToggleButtonGroupProps } from './group';
import { BaseToggleButtonItem, BaseToggleButtonItemProps } from './item';

export class ToggleButtonGroup extends React.Component<BaseToggleButtonGroupProps> {
  static defaultProps: Pick<BaseToggleButtonGroupProps, 'disabled'> = {
    disabled: false,
  };

  render() {
    const { children, ...props } = this.props;
    return <BaseToggleButtonGroup {...props}>{children}</BaseToggleButtonGroup>;
  }
}

type ToggleButtonItemProps = {
  children: string;
} & BaseToggleButtonItemProps;

export class ToggleButtonItem extends React.Component<ToggleButtonItemProps> {
  static defaultProps: Pick<ToggleButtonItemProps, 'selected' | 'disabled' | 'isIcon'> = {
    selected: false,
    disabled: false,
    isIcon: false,
  };

  render() {
    const { children, ...props } = this.props;

    return <BaseToggleButtonItem {...props}>{children}</BaseToggleButtonItem>;
  }
}

type ToggleButtonIconProps = {
  icon: string;
} & BaseToggleButtonItemProps;

export class ToggleButtonIcon extends React.Component<ToggleButtonIconProps> {
  static defaultProps: Pick<ToggleButtonIconProps, 'selected' | 'disabled' | 'isIcon'> = {
    selected: false,
    disabled: false,
    isIcon: true,
  };

  render() {
    const { icon, ...props } = this.props;

    return (
      <BaseToggleButtonItem {...props}>
        <Icon set="icomoon" type={icon} />
      </BaseToggleButtonItem>
    );
  }
}
