/** @format **/

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';
import { omit, get, camelCase } from 'lodash';

import { logError } from 'utils/raygun';

import { InlineEdit } from 'components/inlineEdit';
import { Icon, IconBefore } from 'components/icon';
import { HEADING_CLASSES } from 'components/text';
import { Padding } from 'components/layout';
import { openModal } from 'components/modal';
import { Flex } from 'components/flex';
import { SnowplowLink, trackLinkClick } from 'components/snowplow';
import { ConfirmModal, ConfirmModalProps } from 'components/modal/prefabs/confirm';

import * as Models from '../models';
import * as Selectors from '../selectors';
import * as Constants from '../constants';
import * as Actions from '../actions';
import { DashboardModuleStateAtom, GlobalDashboardSettings } from '../state';

import { Module, ModuleContent, ModuleContentProps, ModuleFooter, ModuleHeader } from './module';
import * as Utilities from './tile.utilities';
import { EditTileModal } from './editTileModal';

type StateProps = GlobalDashboardSettings;
type DispatchProps = {
  refetchTileData: (actions: Action<any>[]) => void;
  editTile: () => void;
  confirmTileDeletion: (props: ConfirmModalProps) => void;
};

type SuppliedProps = {
  isEditing: boolean;
  isTimeboard?: boolean;
  showEditIcon?: boolean;
  showDeleteIcon?: boolean;
  onTitleUpdate: (id: string, newTitle: string) => void;
  shouldRenderSettings?: boolean;
} & Models.Tile;

type Props = SuppliedProps & DispatchProps & StateProps;

export function createTile({
  getComponentForTile,
  getFetchActionsForTile,
  getDefinitionForTile,
}: Models.TileMap) {
  class UnconnectedTile extends React.Component<Props, {}> {
    constructor(props: Props) {
      super(props);

      this.onTitleUpdate = this.onTitleUpdate.bind(this);
      this.onDelete = this.onDelete.bind(this);
    }

    intervalId: number;

    componentDidMount() {
      this.intervalId = window.setInterval(() => {
        this.refetchData();
      }, Constants.POLLING_TIME);
    }

    refetchData() {
      const actions = getFetchActionsForTile(this.props.Type)({
        instanceId: this.props.InstanceId,
      });

      this.props.refetchTileData(actions);
    }

    componentWillUnmount() {
      clearInterval(this.intervalId);
    }

    onTitleUpdate(t: string) {
      this.props.onTitleUpdate(this.props.InstanceId, t);
    }

    onDelete() {
      const confirmTileDeletion: ConfirmModalProps = {
        headerText: 'Delete tile?',
        confirmText: `Are you sure you wish to delete the "${this.props.Settings.Title}" tile?`,
        confirmButtonText: 'Yes, delete',
        callback: dispatch => {
          trackLinkClick(`sp-app-dashboards-${camelCase(this.props.Type)}Tile-delete`);
          dispatch(Actions.removeTile(this.props.InstanceId));
        },
      };

      this.props.confirmTileDeletion(confirmTileDeletion);
    }

    omitProps(obj: Partial<Models.ModuleProps>): ModuleContentProps {
      if (this.props.isEditing) {
        obj = omit(obj, ['contentAbove', 'overflow']);
        obj.noScrolling = true;
        return obj;
      }

      return obj;
    }

    shouldComponentUpdate(nextProps: Props): boolean {
      return (
        nextProps.isEditing !== this.props.isEditing ||
        nextProps.InstanceId !== this.props.InstanceId ||
        nextProps.Settings !== this.props.Settings ||
        nextProps.dateRange !== this.props.dateRange
      );
    }

    render() {
      try {
        const Tile = getComponentForTile(this.props.Type);
        const tileInfo = getDefinitionForTile(this.props.Type);

        const moduleProps = Tile.moduleProps || {};

        return (
          <Module
            overflow={this.props.isEditing ? false : moduleProps.overflow}
            color={this.props.Settings.TileColor}
          >
            {this.renderHeader()}
            <ModuleContent
              {...this.omitProps(moduleProps)}
              id={this.props.InstanceId}
              noHeader={tileInfo.metadata.HideHeader}
            >
              <SnowplowLink
                interactionId={`sp-app-dashboards-${camelCase(
                  this.props.Type,
                )}Tile-interaction`}
              >
                <Tile
                  settings={this.props.Settings}
                  metadata={tileInfo.metadata}
                  instanceId={this.props.InstanceId}
                  type={this.props.Type}
                />
              </SnowplowLink>
            </ModuleContent>
            <ModuleFooter
              footer={moduleProps.footer}
              renderFooter={moduleProps.renderFooter}
              id={this.props.InstanceId}
            />
          </Module>
        );
      } catch (e) {
        logError(e);

        return null;
      }
    }

    renderHeader() {
      const tileInfo = getDefinitionForTile(this.props.Type);
      const hideHeader = get(tileInfo.metadata, 'HideHeader', false);

      if (!this.props.isEditing) {
        const dateRange = Utilities.getDateRangeHeader(
          get(tileInfo, 'metadata.Live', false),
          this.props.Settings.DateRange,
          this.props.dateRange,
        );

        return hideHeader ? null : (
          <ModuleHeader transparent={hideHeader}>
            <div className="module-header-setting module-header-setting--dashboard">
              <div className="module-header-setting__title">
                <h5 className={`${HEADING_CLASSES.FIVE} line-height-24 text-ellipsis`}>
                  {this.props.Settings.Title}
                </h5>
              </div>
              <div className="module-header-setting__button">
                <Padding top="8">
                  <IconBefore icon={<Icon set="flat" type="time" />} extraClasses="text-grey-light">
                    <span>{dateRange}</span>
                  </IconBefore>
                </Padding>
              </div>
            </div>
          </ModuleHeader>
        );
      }

      return (
        <ModuleHeader transparent={hideHeader}>
          <div className="module-header-setting module-header-setting--dashboard">
            {!hideHeader && (
              <div className="module-header-setting__title">
                <span className="js-tile-stop-drag">
                  <InlineEdit
                    text={this.props.Settings.Title}
                    onTextEdit={this.onTitleUpdate}
                    size="24"
                  />
                </span>
              </div>
            )}
            <div className="module-header-setting__button">{this.renderEditOption()}</div>
          </div>
        </ModuleHeader>
      );
    }

    renderEditOption() {
      return (
        <Flex direction="row">
          {this.props.showDeleteIcon && (
            <div className="header-settings__button js-tile-stop-drag" onClick={this.onDelete}>
              <Icon type="trashcan" size={16} color="light" extraClasses="it-tile-delete-single" />
            </div>
          )}
          {this.props.showEditIcon && this.props.shouldRenderSettings && (
            <div
              className="header-settings__button header-settings__button--rotate js-tile-stop-drag"
              onClick={this.props.editTile}
            >
              <Icon type="cog" size={16} color="light" extraClasses="it-tile-settings-single" />
            </div>
          )}
        </Flex>
      );
    }
  }

  return connect<StateProps, DispatchProps, SuppliedProps>(
    (state: DashboardModuleStateAtom, ownProps: SuppliedProps) => {
      const shouldRenderSettings = Selectors.shouldRenderSettings(ownProps.Type, ownProps.Settings);

      return {
        ...Selectors.getGlobalSettings(state),
        isTimeboard: Selectors.isTimeboard(state),
        shouldRenderSettings: shouldRenderSettings(state),
      };
    },
    (dispatch: Dispatch, ownProps: SuppliedProps) => ({
      refetchTileData: (actions: Action<any>[]) => actions.forEach(dispatch),
      confirmTileDeletion: props => dispatch(openModal(ConfirmModal, props)),
      editTile: () => {
        trackLinkClick(`sp-app-dashboards-${camelCase(ownProps.Type)}Tile-clickSettings`);
        dispatch(Actions.tileSetting.setTileId(ownProps.Type));
        dispatch(
          openModal(EditTileModal, {
            tileInstanceId: ownProps.InstanceId,
            tileSettings: ownProps.Settings,
          }),
        );
      },
    }),
  )(UnconnectedTile);
}