/**
 * @prettier
 */

import React from 'react';

import { OPTION, Select } from 'components/select';
import { FormLayout, FormLayoutLabel, FormLayoutInput } from 'components/input';

type Props = {
  granularityOptions: OPTION[];
  granularityOrDefault: string;
  updateSetting: (range: string) => void;
};

export const ChartGranularity: React.FunctionComponent<Props> = (props: Props) => (
  <FormLayout marginBottom>
    <FormLayoutLabel>
      <label htmlFor="granularity">Granularity</label>
    </FormLayoutLabel>
    <FormLayoutInput>
      <Select
        id="granularity"
        options={props.granularityOptions}
        value={props.granularityOrDefault}
        onChange={range => props.updateSetting(range)}
      />
    </FormLayoutInput>
  </FormLayout>
);
