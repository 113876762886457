/** @format */

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { cloneDeep, find } from 'lodash';

import { uuid } from 'utils/generators';
import { reducerUpdate, nestedReducerUpdate } from 'utils/reducerUpdate';

import * as Actions from '../actions';
import { DashboardConfigState } from '../state';
import * as Constants from '../constants';
import * as Models from '../models';
import { ONE_DAY } from 'constants/dateTimeFilter';

export const defaultState: DashboardConfigState = {
  // Global settings
  globalSettings: {
    allowSettings: true,
    groupCharts: false,
    dragToZoom: false,
    fetchLayoutRoute: '',
    dateRange: null,
    applicationIds: null,
  },
  // Fetching
  isFetching: false,
  hasErrored: false,
  error: null,
  // Config
  isEditing: false,
  hasUpdates: false,
  isPrimary: false,
  id: '',
  isFullscreen: false,
  saved: {
    Type: Models.DashboardTypes.Screenboard,
    Title: '',
    Colors: {},
    Tiles: [],
    Applications: [],
    TimeRange: ONE_DAY,
  },
  current: {
    Type: Models.DashboardTypes.Screenboard,
    Title: '',
    Colors: {},
    Tiles: [],
    Applications: [],
    TimeRange: ONE_DAY,
  },
};

export const DashboardConfigReducer = reducerWithInitialState(defaultState)
  .case(Actions.silentUpdateGlobalDateRange, (state, dateRange) =>
    nestedReducerUpdate(state, 'globalSettings', { dateRange }),
  )
  .case(Actions.updateGlobalDateRange, (state, dateRange) => ({
    ...state,
    globalSettings: {
      ...state.globalSettings,
      dateRange,
    },
    hasUpdates: true,
  }))
  .case(Actions.allowDragToZoom, state =>
    nestedReducerUpdate(state, 'globalSettings', { dragToZoom: true }),
  )
  .case(Actions.groupCharts, state =>
    nestedReducerUpdate(state, 'globalSettings', { groupCharts: true }),
  )
  .case(Actions.disableTileSettings, state =>
    nestedReducerUpdate(state, 'globalSettings', { allowSettings: false }),
  )
  .case(Actions.setFetchLayoutRoute, (state, route) =>
    nestedReducerUpdate(state, 'globalSettings', { fetchLayoutRoute: route }),
  )
  .case(Actions.silentUpdateGlobalApplications, (state, applicationIds) =>
    nestedReducerUpdate(state, 'globalSettings', { applicationIds }),
  )
  .case(Actions.toggleGlobalApplication, (state, appId) => {
    const appIds = state.globalSettings.applicationIds;
    const appIncluded = appIds.indexOf(appId) > -1;

    return {
      ...state,
      globalSettings: {
        ...state.globalSettings,
        applicationIds: appIncluded ? appIds.filter(id => id !== appId) : [...appIds, appId],
      },
      hasUpdates: true,
    };
  })
  .case(Actions.updateEditStatus, (state, isEditing) => {
    if (state.isFullscreen) {
      throw new Error('Cannot editing while in fullscreen');
    }

    return reducerUpdate(state, { isEditing });
  })
  .case(Actions.revertLayout, state => {
    return reducerUpdate(state, {
      current: cloneDeep(state.saved),
      isEditing: false,
      hasUpdates: false,
    });
  })
  .case(Actions.saveLayout.done, state => {
    return reducerUpdate(state, {
      isFetching: false,
      hasErrored: false,
      error: null,
      saved: {
        ...state.saved,
        ...cloneDeep(state.current),
      },
      isEditing: false,
      hasUpdates: false,
    });
  })
  .case(Actions.updateLayout, (state, tiles) => {
    return reducerUpdate(state, {
      current: {
        ...state.current,
        Tiles: tiles,
      },
      hasUpdates: true,
    });
  })
  .case(Actions.clearLayout, state => {
    return reducerUpdate(state, {
      current: {
        ...state.current,
        Tiles: [],
      },
    });
  })
  .case(Actions.fetchLayout.start, state =>
    reducerUpdate(state, { isFetching: true, hasErrored: false, error: null }),
  )
  .case(Actions.fetchLayout.error, (state, payload) =>
    reducerUpdate(state, { isFetching: false, hasErrored: true, error: payload }),
  )
  .case(Actions.fetchLayout.done, (state, payload) => {
    const current: Models.DashboardDefinition = {
      Title: payload.DashboardDefinition.Title || 'Dashboard',
      Colors: payload.DashboardDefinition.Colors || {},
      Tiles: (payload.DashboardDefinition.Tiles || []).map(tile => {
        const defaultSettings = Constants.getDefinitionForTile(tile.Type).defaultSettings;

        const t: Models.Tile = {
          ...tile,
          Settings: {
            ...defaultSettings,
            ...tile.Settings,
          },
          InstanceId: uuid(),
        };

        return t;
      }),
      Type: payload.DashboardDefinition.Type || Models.DashboardTypes.Screenboard,
      Applications: payload.DashboardDefinition.Applications || [],
      TimeRange: payload.DashboardDefinition.TimeRange || ONE_DAY,
    };

    return reducerUpdate(state, {
      saved: current,
      id: payload.DashboardIdentifier,
      current: cloneDeep(current),
      isPrimary: payload.IsPrimary || false,
      isFetching: false,
      hasErrored: false,
    });
  })
  .case(Actions.resetLayout.done, (state, payload) => {
    return reducerUpdate(state, {
      current: payload,
      hasUpdates: true,
    });
  })
  .case(Actions.addTile, (state, addTile) => {
    const tile = {
      ...addTile,
      InstanceId: uuid(),
      X: 0,
      Y: 0,
      Width: addTile.Metadata.DefaultWidth || 4,
      Height: addTile.Metadata.DefaultHeight || 3,
    };

    return reducerUpdate(state, {
      current: {
        ...state.current,
        Tiles: [...state.current.Tiles, tile],
      },
      hasUpdates: true,
    });
  })
  .case(Actions.updateTile, (state, payload) => {
    const tiles = cloneDeep(state.current.Tiles).map(tile => {
      if (tile.InstanceId === payload.instanceId) {
        return {
          ...tile,
          Settings: payload.settings,
        };
      }

      return tile;
    });

    return reducerUpdate(state, {
      current: {
        ...state.current,
        Tiles: tiles,
      },
      hasUpdates: true,
    });
  })
  .case(Actions.removeTile, (state, id) => {
    const Tiles = cloneDeep(state.current.Tiles).filter(t => t.InstanceId !== id);

    return reducerUpdate(state, {
      current: {
        ...state.current,
        Tiles,
      },
      hasUpdates: true,
    });
  })
  .case(Actions.updateTitle, (state, Title) => {
    return reducerUpdate(state, {
      current: {
        ...state.current,
        Title,
      },
      hasUpdates: true,
    });
  })
  .case(Actions.updatePrimaryStatus, (state, isPrimary) => {
    return reducerUpdate(state, {
      isPrimary,
    });
  })
  .case(Actions.updateDashboardIdentifier, (state, identifier) => {
    return reducerUpdate(state, { id: identifier });
  })
  .case(Actions.toggleFullscreen, (state, isFullscreen) => {
    return reducerUpdate(state, {
      isFullscreen,
    });
  })
  .case(Actions.updateAppColor, (state, { appId, color }) => {
    return nestedReducerUpdate(state, 'current', {
      Colors: reducerUpdate(state.current.Colors, {
        [appId]: color,
      }),
    });
  })
  .case(Actions.setCurrentDateRange, (state, TimeRange) => {
    return nestedReducerUpdate(state, 'current', { TimeRange });
  })
  .case(Actions.setCurrentApplications, (state, Applications) => {
    return nestedReducerUpdate(state, 'current', { Applications });
  });
