/**
 * @prettier
 */

import * as React from 'react';
import { IStyle, IRenderer, TRule } from 'fela';
import { createComponent, createComponentWithProxy } from 'react-fela';
import { pick, omit } from 'lodash';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { COLORS } from 'app/theme/materialDS';

import {
  withInteractivity,
  WithInteractivitySuppliedProps,
} from 'components/ds/hoc/withInteractivity';

type PublicFilterPendingProps = {
  // colors
  iconColor: COLORS;
  backgroundColor: COLORS;

  hoverBackgroundColor: COLORS;
  pressedBackgroundColor: COLORS;
} & React.HTMLProps<HTMLDivElement>;

type FilterPendingProps = PublicFilterPendingProps & WithInteractivitySuppliedProps;

type PublicFilterPendingWrapperProps = Pick<
  PublicFilterPendingProps,
  'backgroundColor' | 'hoverBackgroundColor' | 'pressedBackgroundColor'
>;

type FilterPendingWrapperProps = PublicFilterPendingWrapperProps & WithInteractivitySuppliedProps;

const StyledFilterPendingWrapper = (props: FilterPendingWrapperProps & ThemeType): IStyle => {
  return applyModifiers(
    [
      props.hovered || props.focused,
      {
        backgroundColor: props.theme.ds.getColorByName(props.hoverBackgroundColor),
      },
    ],
    [
      props.pressed,
      {
        backgroundColor: props.theme.ds.getColorByName(props.pressedBackgroundColor),
      },
    ],
  )({
    backgroundColor: props.theme.ds.getColorByName(props.backgroundColor),
    width: '24px',
    height: '24px',
    padding: '8px 4px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    ':focus': {
      outline: 0,
    },
    ['-webkit-tap-highlight-color' as any]: props.theme.ds.colors.special.transparent,
  });
};

const FilterPendingWrapper = createComponentWithProxy<FilterPendingWrapperProps>(
  StyledFilterPendingWrapper,
  'div',
);

type FilterPendingIconProps = {
  animationDelay: IStyle['animationDelay'];
} & Pick<FilterPendingProps, 'iconColor'>;

const StyledFilterPendingIcon = (
  props: FilterPendingIconProps & ThemeType,
  renderer: IRenderer,
): IStyle => {
  const bobUpAndDown = renderer.renderKeyframe(
    () => ({
      ['0%' as any]: {
        transform: 'translateY(0)',
      },
      ['50%' as any]: {
        transform: 'translateY(4px)',
      },
      ['100%' as any]: {
        transform: 'translateY(0)',
      },
    }),
    props,
  );

  return {
    animation: `${bobUpAndDown} 700ms ease-in-out infinite`,
    animationDelay: props.animationDelay,
    borderRadius: '50%',
    width: '4px',
    height: '4px',
    display: 'block',
    backgroundColor: props.theme.ds.getColorByName(props.iconColor),
  };
};

const FilterPendingIcon = createComponent<FilterPendingIconProps>(
  StyledFilterPendingIcon as TRule,
  'div',
);

const FilterPendingWithoutInteractivity: React.FunctionComponent<FilterPendingProps> = props => {
  const iconProps = pick(props, 'iconColor');
  const wrapperProps = omit(props, 'iconColor');

  return (
    <FilterPendingWrapper {...wrapperProps}>
      <FilterPendingIcon {...iconProps} animationDelay={'0'} />
      <FilterPendingIcon {...iconProps} animationDelay={'100ms'} />
      <FilterPendingIcon {...iconProps} animationDelay={'200ms'} />
    </FilterPendingWrapper>
  );
};

const FilterPending = withInteractivity<PublicFilterPendingProps, FilterPendingProps>(
  FilterPendingWithoutInteractivity,
);

export { FilterPending, PublicFilterPendingProps as FilterPendingProps };
