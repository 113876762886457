/**
 * @prettier
 */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type Props = {
  size?: 40 | 32 | 24;
  iconSize?: number;
  inline?: boolean;
  marginLeft?: number;
  marginRight?: number;
  color?: 'stealth' | 'grey' | 'transparent' | 'border' | 'blue';
  border?: boolean;
  clickable?: boolean;
  className?: string;
};

const StyledIconButton = (props: Props & ThemeType): IStyle => {
  const styles: IStyle = {
    userSelect: 'none',
    width: `${props.size}px`,
    height: `${props.size}px`,
    padding: `${(props.size - props.iconSize) / 2}px`,
    textDecoration: 'none',
    outline: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: `#fff`,
    display: 'block',
    fontSize: 0,
    backgroundColor: `${props.theme.colors.material.grey500}`,
  };

  return applyModifiers(
    [
      props.clickable,
      {
        ':hover': {
          backgroundColor: `${props.theme.colors.material.grey400}`,
        },
        ':active': {
          backgroundColor: `${props.theme.colors.material.grey600}`,
        },
        ':focus': {
          backgroundColor: `${props.theme.colors.material.grey600}`,
        },
      },
    ],
    [
      props.inline,
      {
        display: 'inline-block',
        verticalAlign: 'top',
      },
    ],
    [
      !!props.marginRight,
      {
        marginRight: `${props.marginRight}px`,
      },
    ],
    [
      !!props.marginLeft,
      {
        marginLeft: `${props.marginLeft}px`,
      },
    ],
    [
      props.color === 'stealth',
      {
        backgroundColor: `${props.theme.colors.stealth.standard}`,
      },
    ],
    [
      props.color === 'grey',
      {
        backgroundColor: `${props.theme.colors.material.grey300}`,
      },
    ],
    [
      props.color === 'border',
      applyModifiers([
        props.clickable,
        {
          ':hover': {
            backgroundColor: 'transparent',
            border: `1px solid ${props.theme.colors.border.dark}`,
            color: props.theme.colors.text.grey,
          },
          ':active': {
            transform: 'translateY(1px)',
            backgroundColor: 'rgba(255,255,255,0.2)',
            border: `1px solid ${props.theme.colors.border.dark}`,
            color: props.theme.colors.text.grey,
          },
          ':focus': {
            transform: 'translateY(1px)',
            backgroundColor: 'rgba(255,255,255,0.2)',
            border: `1px solid ${props.theme.colors.border.dark}`,
            color: props.theme.colors.text.grey,
          },
        },
      ])({
        backgroundColor: 'transparent',
        border: `1px solid ${props.theme.colors.border.standard}`,
        color: props.theme.colors.text.grey,
      }),
    ],
    [
      props.color === 'transparent',
      applyModifiers([
        props.clickable,
        {
          ':hover': {
            backgroundColor: props.theme.colors.material.grey400,
            color: '#fff',
          },
          ':active': {
            backgroundColor: props.theme.colors.material.grey600,
            color: '#fff',
          },
          ':focus': {
            backgroundColor: props.theme.colors.material.grey600,
            color: '#fff',
          },
        },
      ])({
        backgroundColor: 'transparent',
        color: props.theme.colors.text.grey,
      }),
    ],
    [
      props.color === 'blue',
      applyModifiers([
        props.clickable,
        {
          ':hover': {
            backgroundColor: props.theme.colors.material.blue400,
            color: '#fff',
          },
          ':active': {
            backgroundColor: props.theme.colors.material.blue600,
            color: '#fff',
          },
          ':focus': {
            backgroundColor: props.theme.colors.material.blue600,
            color: '#fff',
          },
        },
      ])({
        backgroundColor: 'transparent',
        color: props.theme.colors.material.blue400,
      }),
    ],
  )(styles);
};

export const IconButton = createComponent<Props>(StyledIconButton, 'div', ['onClick', 'className']);

IconButton.defaultProps = {
  size: 24,
  iconSize: 16,
  marginRight: 0,
  marginLeft: 0,
  inline: false,
  clickable: true,
};

export const IconButtonAsLink = createComponent<Props>(StyledIconButton, 'a', [
  'href',
  'className',
]);

IconButtonAsLink.defaultProps = {
  size: 24,
  iconSize: 16,
  marginRight: 0,
  marginLeft: 0,
  inline: false,
  clickable: true,
};
