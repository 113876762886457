/** @format */

import { first, get, last } from 'lodash';
import moment from 'moment';

import { Formatting, convertUtcToUsersMoment } from 'utils/date';
import { formatDuration } from 'utils/durationFormatting';
import { formatNumber, formatAsShorthand, getFormatNumber } from 'utils/numberFormatting';

import { eChartsLabelFormatter, eChartsTooltipFormatter, eChartsTooltipParams } from '../models';

type DateTimeReturn = {
  formatter: (value: string, index: number) => string;
  interval: ((index: number, value: string) => boolean) | 'auto';
};

export function dateTimeLabel(periods: string[]): DateTimeReturn {
  const firstPeriod = first(periods);
  const secondPeriod = get(periods, '1');
  const intervalDiff = moment(secondPeriod).diff(firstPeriod, 'h');

  /**
   * Note: Spaces in date formating methods is intentional as
   * it adds additional whitespace which stops so many axis labels
   * from rendering
   */
  if (intervalDiff < 1) {
    // Less than 1 hour difference between two points
    return {
      formatter: (value: string) => Formatting.format(value, ` h:mma `, ` HH:mm `),
      interval: 'auto',
    };
  }

  const lastPeriod = last(periods);
  const daySpan = moment(lastPeriod).diff(firstPeriod, 'd');

  if (daySpan < 5) {
    const hourlySpan = daySpan > 3 ? 12 : 6;

    return {
      formatter: (value: string) => {
        const hour = convertUtcToUsersMoment(value).hour();
        const isTwelveHours = hour === 12;

        const formattedTime = Formatting.format(value, ` ha `, ` HH:mm `);
        const formattedDay = isTwelveHours ? Formatting.format(value, ` Do `) : '';

        return `${formattedTime}\n${formattedDay}`;
      },
      interval: (_index: number, value: string) => {
        const hour = convertUtcToUsersMoment(value).hour();
        return hour % hourlySpan === 0;
      },
    };
  }

  /**
   * Method to handle formatting the day and month.
   * The month is only rendered when a different month's label is rendered
   * so that the axis is rendered with repeditive "clutter-y" values
   */
  let lastMonth: number = convertUtcToUsersMoment(firstPeriod).month();
  const formatter = (date: string) => {
    const month = convertUtcToUsersMoment(date).month();
    const formattedDay = Formatting.format(date, ` Do `);
    const formattedMonth = month !== lastMonth ? Formatting.format(date, ' MMM ') : '';

    lastMonth = month;

    return `${formattedDay}\n${formattedMonth}`;
  };

  if (daySpan < 22) {
    return {
      formatter,
      interval: (_index: number, value: string) => {
        const hour = convertUtcToUsersMoment(value).hour();
        return hour === 12;
      },
    };
  }

  if (intervalDiff >= 23) {
    return {
      formatter,
      interval: 'auto',
    };
  }

  let dayCount: number = 0;
  let lastDay: number;

  return {
    formatter,
    interval: (_index: number, value: string) => {
      const date = convertUtcToUsersMoment(value);
      const d = date.date();

      if (d !== lastDay) {
        lastDay = d;
        dayCount++;
      }

      if (dayCount === 2) {
        dayCount = 0;
      }

      return date.hours() === 12 && dayCount === 0;
    },
  };
}

function generateMarker(color: string): string {
  return `<span style="display:inline-block;margin-right:4px;border-radius:8px;width:8px;height:8px;background-color:${color};"></span>`;
}

const tooltipSpanStyle: string = 'float:right;display:inline-block;margin-left:16px';

export function formatTooltipDate(value: number): string {
  return Formatting.format(value, 'D MMM, h:mma', 'D MMM, HH:mm');
}

function tooltip(
  formatMethod: (data: string | number) => string,
  truncateLength: number = Infinity,
) {
  return (params: eChartsTooltipParams[]) => {
    const date = formatTooltipDate(params[0].axisValue);

    return [
      date,
      ...params.map(
        data =>
          `${generateMarker(data.color)} ${truncateWithEllipses(
            data.seriesName,
            truncateLength,
          )} <span style=${tooltipSpanStyle}>${formatMethod(data.value)}</span>`,
      ),
    ].join('<br />');
  };
}

export function histogramTooltip(histogramData: number[][], isTimeMetric: boolean = true) {
  const formattingFn = isTimeMetric ? formatDuration : getFormatNumber(3);
  const timingRangeDataPointModifier = isTimeMetric ? 1 : 0.001;

  return (params: eChartsTooltipParams[]) => {
    return params
      .map(data => {
        const isLastIndex: boolean = data.dataIndex === histogramData.length - 1;
        const nextDataPoint: number[] = isLastIndex
          ? histogramData[histogramData.length - 1]
          : histogramData[data.dataIndex + 1];
        const timingRange: string = isLastIndex
          ? `${formattingFn(data.data[0])}+`
          : `${formattingFn(data.data[0])} - ${formattingFn(
              nextDataPoint[0] - timingRangeDataPointModifier,
            )}`;
        const count = formatNumber(data.data[1]);

        return `Count: ${count} <br/> ${generateMarker(
          data.color,
        )} <span style="${tooltipSpanStyle}"> ${timingRange} </span>`;
      })
      .join('<br />');
  };
}

export const truncateWithEllipses = (text: string, length: number) => {
  if (text.length > length) {
    return text.slice(0, length).concat('...');
  }
  return text;
};

export const durationLabel: eChartsLabelFormatter = (value: number) =>
  value === 0 ? '0' : `${formatDuration(value)}`;

export const numericLabel: eChartsLabelFormatter = (value: number) =>
  value === 0 ? '0' : `${formatAsShorthand(value, 3)}`;

export function histogramLabelFormatter(value: number, index: number, units: string = 'ms') {
  return index % 5 === 0 ? `${value}${units}` : null;
}

export function getHistogramLabelFormatter(
  units: string,
): (value: number, index: number) => string {
  return function histogramLabelFormatterWrapper(value: number, index: number) {
    return histogramLabelFormatter(value, index, units);
  };
}

export const dateTimeTooltip: eChartsTooltipFormatter = tooltip(formatDuration, 20);

export const numericTooltip: eChartsTooltipFormatter = tooltip(
  (value: number) => `${formatNumber(value, 4)}`,
  20,
);

export const countTooltip: eChartsTooltipFormatter = tooltip(
  value => formatNumber(value as number),
  20,
);
