/**
 * @prettier
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { DashboardState } from 'sections/dashboard/index';

import {
  ModalProps,
  Modal,
  ModalHeaderWithTitle,
  ModalMessage,
  ModalFooter,
  ModalBodyContent,
} from 'components/modal/index';
import { Button } from 'components/button/index';
import { ButtonPatternCancelSubmit } from 'components/button/pattern';
import { Typography } from 'components/text/index';
import { Checkbox } from 'components/checkbox/index';
import { STATUS, RESOLVE_IN_VERSION } from 'constants/status';
import { Text as TextInput } from 'components/input/text';

import * as Models from '../../../../models';
import * as Actions from '../../../../actions';

type DispatchProps = {
  permanentlyIgnore: (tileId: string, dropErrors: boolean, version: string) => void;
};

type PassedProps = {
  tileId: string;
} & ModalProps;

type Props = PassedProps & DispatchProps;

type State = {
  dropErrors: boolean;
  version: string;
};

export class UnconnectedResolveInVersionModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      version: '',
      dropErrors: true,
    };

    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDropErrors = this.onDropErrors.bind(this);
    this.onVersionChange = this.onVersionChange.bind(this);
  }

  onDropErrors() {
    this.setState((prev: State) => ({ dropErrors: !prev.dropErrors }));
  }

  onVersionChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      version: e.currentTarget.value,
    });
  }

  onClose() {
    this.props.close(this.props.id);
  }

  onSubmit() {
    this.onClose();
    this.props.permanentlyIgnore(this.props.tileId, this.state.dropErrors, this.state.version);
  }

  render() {
    const cancel = (
      <Button onClick={this.onClose} type="white" block size={36}>
        Abort!
      </Button>
    );

    const submit = (
      <Button onClick={this.onSubmit} type="green" block size={36}>
        Resolve
      </Button>
    );

    const footer = (
      <ModalFooter smallPadding>
        <ButtonPatternCancelSubmit submit={submit} cancel={cancel} />
      </ModalFooter>
    );

    return (
      <Modal
        header={
          <ModalHeaderWithTitle>Resolve these errors in a specific version</ModalHeaderWithTitle>
        }
        footer={footer}
        modalBody={{ noPadding: true }}
        {...this.props}
      >
        <ModalMessage color="grey">
          <Typography>
            <p>In what version was the error resolved?</p>
            <p>
              Indicate the version where the error was resolved. Occurrences received in this
              version and above will cause the error to be reactivated.{' '}
              <a href="https://raygun.com/documentation/product-guides/crash-reporting/error-statuses/#resolve-in-version">
                Learn more
              </a>
              .
            </p>
          </Typography>
        </ModalMessage>
        <ModalBodyContent>
          <TextInput
            type="text"
            value={this.state.version}
            isBlock
            isFullWidth
            hasMargin
            placeholder="Version"
            onChange={this.onVersionChange}
          />
          <Checkbox onToggled={this.onDropErrors} isChecked={this.state.dropErrors}>
            Please discard future occurrences below the resolved version.
          </Checkbox>
        </ModalBodyContent>
      </Modal>
    );
  }
}

const ConnectedResolveInVersionModal = connect<null, DispatchProps, PassedProps>(
  null,
  (dispatch: Dispatch) => ({
    permanentlyIgnore: (tileId: string, drop: boolean, version: string) =>
      dispatch(
        Actions.setErrorGroupStatus({
          tileId,
          status: RESOLVE_IN_VERSION,
          version,
          drop,
        }),
      ),
  }),
)(UnconnectedResolveInVersionModal);

export { ConnectedResolveInVersionModal as ResolveInVersionModal };
