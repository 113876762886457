/** @format */

import * as React from 'react';
import classnames from 'classnames';

type Props = {
  size?: 40 | 48 | 60 | 80 | 100 | 120 | 140 | 180 | 240;
  spaceLeft?: boolean;
  spaceHorizontal?: boolean;
  spaceRight?: boolean;
  spaceTop?: boolean;
  verticalXL?: boolean;
  verticalLarge?: boolean;
  verticalMedium?: boolean;
  verticalSmall?: boolean;
};

export const Cell: React.FunctionComponent<Props> = props => {
  const classes = classnames('active-list-cell', {
    'active-list-cell--space-left': props.spaceLeft,
    'active-list-cell--space-horizontal': props.spaceHorizontal,
    'active-list-cell--space-right': props.spaceRight,
    'active-list-cell--space-top': props.spaceTop,
    'active-list-cell--vertical-24': props.verticalXL,
    'active-list-cell--vertical-16': props.verticalLarge,
    'active-list-cell--vertical-large': props.verticalMedium,
    'active-list-cell--vertical-small': props.verticalSmall,
    [`active-list-cell--${props.size}`]: props.size !== undefined,
  });

  return <td className={classes}>{props.children}</td>;
};
