/** @format */

import * as React from 'react';
import { createComponentWithProxy, FelaInjectedProps } from 'react-fela';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type WrapperProps = {
  active: boolean;
};

export type MobileLinkProps = {
  label: string;
  icon: React.ReactNode;
  iconRight?: React.ReactNode;
} & WrapperProps;

const StyledMobileLinkWrapper = (props: WrapperProps & ThemeType) =>
  applyModifiers([
    props.active,
    {
      borderLeft: `4px solid ${props.theme.ds.colors.blue.blue900}`,
      paddingLeft: '4px',
      fontWeight: 600,
    },
  ])({
    display: 'flex',
    alignContent: 'center',
    lineHeight: '40px',
    fontSize: '1.4rem',
    fontWeight: 400,
    paddingLeft: '8px',
    paddingRight: '8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: props.theme.ds.colors.grey.grey800,
  });

const MobileLinkWrapper = createComponentWithProxy(StyledMobileLinkWrapper, 'a');

const StyledIconWrapper = () => ({
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '16px',
});

const IconWrapper = createComponentWithProxy(StyledIconWrapper);

const StyledIconRight = () => ({
  marginLeft: 'auto',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const IconRight = createComponentWithProxy(StyledIconRight);

type Props = MobileLinkProps &
  FelaInjectedProps<MobileLinkProps & ThemeType, any> &
  React.HTMLProps<HTMLAnchorElement>;

export const MobileLink: React.FunctionComponent<Props> = ({
  label,
  active,
  icon,
  iconRight,
  ...props
}) => (
  <MobileLinkWrapper active={active} {...props}>
    <IconWrapper>{icon}</IconWrapper>
    {label}
    {iconRight && <IconRight>{iconRight}</IconRight>}
  </MobileLinkWrapper>
);
