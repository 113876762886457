/** @format */

import { isNil } from 'lodash';

type ApplicationId = string | number;

export function toIdentifier(id: number): string {
  if (isNil(id)) {
    return null;
  }

  return id.toString(36);
}

export function toId(id: string): number {
  if (isNil(id)) {
    return null;
  }

  return parseInt(id, 36);
}

export const CrashReporting = {
  application(appIdentifier: string) {
    return `/crashreporting/${appIdentifier}`;
  },
  applicationById(appId: number) {
    return CrashReporting.application(toIdentifier(appId));
  },
  errorGroup(appIdentifier: string, groupId: string | number) {
    return `${CrashReporting.application(appIdentifier)}/errors/${groupId}`;
  },
  errorGroupById(appId: number, groupId: string | number) {
    return CrashReporting.errorGroup(toIdentifier(appId), groupId);
  },
  errorGroupForUser(appIdentifier: string, groupId: string | number, userIdentifier: string) {
    return `${CrashReporting.errorGroup(appIdentifier, groupId)}?search=${userIdentifier}`;
  },
};

export const RUM = {
  session(appId: ApplicationId, sessionId: string | number) {
    return `/pulse/${appId}?session=${sessionId}`;
  },
};

export const Deployments = {
  application(appIdentifier: string) {
    return `/dashboard/${appIdentifier}/deployments`;
  },
};

export const AffectedUsers = {
  application(appIdentifier: string) {
    return `/reporting/${appIdentifier}/affectedusers`;
  },
};
