/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';

const StyledModuleListItem = (props: ThemeType): IStyle => ({
  backgroundColor: `${props.theme.colors.standard.white}`,
  borderBottom: `1px solid ${props.theme.colors.grey.lighter}`,
  ':last-child': {
    borderBottom: 'none',
  },
});

export const ModuleListItem = createComponent(StyledModuleListItem);
