/** @format */

import { toArray } from 'lodash';
import { createSelector } from 'reselect';

import { AppState } from 'interfaces/appState';
import * as Models from './models';

const popupsSelector = (state: AppState) => state.app.popups;

export const popups = createSelector(
  popupsSelector,
  popups => toArray(popups),
);
