/** @format **/
import React, { useRef } from "react";
import { assign, Dictionary, filter, camelCase, debounce } from "lodash";

import { Search } from 'components/input/search';
import { trackLinkClick } from 'components/snowplow';

import { Application } from 'interfaces/application';
import { Dashboard } from 'interfaces/dashboard';
import { Plan } from 'interfaces/plan';

import { sortById, sortByName } from '../../utils';

import { Sort } from './sort';
import { UpgradePaywall } from '../upgradePaywall';

const SORT_KEY = 'contextswitcher:sort';

type Props = {
  activePlan: Plan;
  data: Dictionary<Application[]> | Dictionary<Dashboard[]>;
  title: string;
  children: (item: any) => React.ReactNode;
  createComponent: React.ReactNode;
  paywallActive?: boolean;
};

type State = {
  activeTab: string;
  sortMethod: string;
  text: string;
};

export class ContextSwitcher extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.changeSortMethod = this.changeSortMethod.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.nameMatch = this.nameMatch.bind(this);
    this.debouncedOnSearchTrack = debounce(this.debouncedOnSearchTrack, 800);

    this.state = {
      activeTab: 'Applications',
      sortMethod: 'Created on',
      text: '',
    };
  }

  componentDidMount() {
    const sortMethod = localStorage.getItem(SORT_KEY);
    if (sortMethod) {
      this.setState({ sortMethod });
    }
  }

  changeSortMethod(sortMethod: string) {
    localStorage.setItem(SORT_KEY, sortMethod);
    this.setState({ sortMethod });
    trackLinkClick(`sp-app-chrome-switcher-sort${this.props.title}-by-${camelCase(sortMethod)}`);
  }
  
  debouncedOnSearchTrack() {
    trackLinkClick(`sp-app-chrome-switcher-search${this.props.title}`);
  }

  onSearch(text: string) {
    this.setState({ text });
    this.debouncedOnSearchTrack();  
  }

  nameMatch(app: { name: string }): boolean {
    return (
      this.state.text.length === 0 ||
      app.name.toLowerCase().indexOf(this.state.text.toLowerCase()) !== -1
    );
  }

  render() {
    let favourites = null;

    const sortMethod = this.state.sortMethod === 'Alphabetical' ? sortByName : sortById;
    const data: any = this.props.data[this.props.activePlan.Id] || [];
    const filtered = data.filter(this.nameMatch);
    const context = this.props.children(filtered.sort(sortMethod));

    const favList = filtered.filter((f: Application | Dashboard) => f.isFavourite);
    if (favList.length > 0) {
      favourites = this.props.children(favList.sort(sortByName));
    }

    const favouritesComp = favourites ? (
      <div className="context-switcher-view context-switcher-view--favourites">
        <span className="context-switcher-view__title">Favourites:</span>
        <div className="context-switcher-view__content">{favourites}</div>
      </div>
    ) : null;

    return (
      <div className="context-switcher">
        <div className="context-switcher__heading hide show-mob">
          <h4>{this.props.title}</h4>
        </div>
        <div className="context-switcher__search">
          <Search onInput={this.onSearch} focus />
        </div>
        <div className="context-switcher__heading hide-mob">
          <h4>{this.props.title}</h4>
        </div>
        <div className="context-switcher__content">
          {favouritesComp}
          <div className="context-switcher-view">
            {this.props.paywallActive ? (
              <UpgradePaywall
                buttonUrl={`/plan/${this.props.activePlan.Id.toString(36)}`}
                buttonText={'Upgrade'}
                title={'Upgrade to unlock this feature'}
                subTitle={`Your current plan doesn't have access to ${this.props.title}`}
              />
            ) : (
              <>
                <div className="context-switcher-view__sort-button">
                  <Sort sortMethod={this.state.sortMethod} onSelect={this.changeSortMethod} />
                </div>
                <div className="context-switcher-view__create-button">
                  {this.props.createComponent}
                </div>
                <nav>{context}</nav>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}