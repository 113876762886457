/** @format */

import * as React from 'react';
import { connect } from 'react-redux';

import * as Actions from 'modules/dashboard/actions';
import { State as DashboardState } from 'modules/dashboard';

import { Tooltip } from 'components/tooltip';
import { Padding } from 'components/layout';
import { Button } from 'components/ds/button';
import { openModal } from 'components/modal';
import { ConfirmModal, ConfirmModalProps } from 'components/modal/prefabs';
import { trackLinkClick } from 'components/snowplow';

type StateProps = {};

type DispatchProps = {
  openConfirmModal: () => void;
};

type PassedProps = {
  canDelete: boolean;
};

type Props = StateProps & DispatchProps & PassedProps;

const DeleteModalProps: ConfirmModalProps = {
  headerText: 'Delete Dashboard',
  confirmText: 'Are you sure you wish to delete this dashboard?',
  confirmButtonText: 'Yes, delete',
  callback: dispatch => {
    trackLinkClick('sp-app-dashboards-controls-deleteDashboard');
    dispatch(Actions.deleteDashboard.base({}));
  },
};

export const UnconnectedDelete: React.FunctionComponent<Props> = props => {
  if (!props.canDelete) {
    return null;
  }

  return (
    <Tooltip label="Delete this dashboard" dock="bottom-left">
      <Padding right="4">
        <Button onClick={props.openConfirmModal}>Delete</Button>
      </Padding>
    </Tooltip>
  );
};

export const Delete = connect<StateProps, DispatchProps, PassedProps, DashboardState>(
  null,
  dispatch => ({
    openConfirmModal: () => {
      dispatch(openModal(ConfirmModal, DeleteModalProps));
    },
  }),
)(UnconnectedDelete);
