/** @format **/
/**
 * Special file which handles the checks/configuration of any polyfills required.
 */

window.RaygunReactScriptLoaded = true;

import Promise from 'promise-polyfill';
import { assign, find } from 'lodash';

require('intersection-observer');

import 'core-js/es7/object';
import 'core-js/es7/array';

if (!window.Promise) {
  window.Promise = Promise;
}

import 'whatwg-fetch';

if (typeof Object.assign === 'undefined') {
  Object.assign = assign;
}

if (typeof Array.prototype.find === 'undefined') {
  Array.prototype.find = find;
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    return this.substr(position || 0, searchString.length) === searchString;
  };
}

// CustomEvent Polyfill for IE 11
if (typeof window.CustomEvent !== 'function') {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  window.CustomEvent = CustomEvent;
}
