/** @format */
import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { isNil, filter, reduce } from 'lodash';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { screenMediumOnly } from 'fela-rules/breakpoints';

import * as Models from './models';

export type LegendItem = {
  name: string;
  color: string;
};

type LegendListProps = {
  alignLeftForMediumScreen?: boolean;
};

const StyledLegendList = (props: LegendListProps): IStyle => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  ...screenMediumOnly({
    justifyContent: props.alignLeftForMediumScreen ? 'flex-start' : 'center',
  }),
});

const LegendList = createComponent(StyledLegendList);

type LegendItemProps = {
  color: string;
  isSelected: boolean;
  isClickable: boolean;
};

const StyledLegendItem = (props: LegendItemProps & ThemeType): IStyle =>
  applyModifiers(
    [
      !isNil(props.color),
      {
        '::before': {
          content: '" "',
          display: 'block',
          marginRight: '8px',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: !props.isSelected
            ? props.theme.ds.getColorByName('blueGrey75')
            : props.color,
          position: 'absolute',
          top: '8px',
          left: '8px',
        },
        paddingLeft: '20px',
        position: 'relative',
      },
    ],
    [
      !props.isSelected,
      {
        color: props.theme.ds.getColorByName('blueGrey75'),
      },
    ],
  )({
    padding: '4px 8px',
    cursor: props.isClickable ? 'pointer' : 'default',
  });

const LegendItem = createComponent(StyledLegendItem, 'div', ['onClick']);

export type EChartsActionCreator = (
  legendItem?: LegendItem,
  selected?: boolean,
  legendItems?: LegendItem[],
  deselectedLegendItemIndexes?: number[],
) => Models.eChartsAction;

type Props = {
  items: LegendItem[];
  dispatchAction: (action: Models.eChartsAction) => void;
  createEChartsAction?: EChartsActionCreator;
  alignLeftForMediumScreen?: boolean;
  deselectedItems?: number[];
  setDeselectedItems?: (items: number[]) => void;
};

export const Legend: React.FunctionComponent<Props> = ({
  children,
  items,
  dispatchAction,
  createEChartsAction,
  alignLeftForMediumScreen,
  deselectedItems,
  setDeselectedItems,
}) => {
  function updateDeselectedItems(items: number[]) {
    setDeselectedItems(items);
  }

  function generateItems() {
    return items.map((item, index) => {
      const { color, name } = item;
      const isSelected = deselectedItems.indexOf(index) === -1;
      const isClickable = items.length > 1;

      const updatedDeselectedItems = isSelected
        ? [...deselectedItems, index]
        : filter(deselectedItems, itemIndex => itemIndex !== index);

      const generatedAction = createEChartsAction(item, isSelected, items, updatedDeselectedItems);

      const updateAction = () => {
        updateDeselectedItems(updatedDeselectedItems);
        dispatchAction(generatedAction);
      };

      return (
        <LegendItem
          key={name}
          as="li"
          color={color}
          isSelected={isSelected}
          onClick={isClickable ? updateAction : null}
          isClickable={isClickable}
        >
          {name}
        </LegendItem>
      );
    });
  }

  return (
    <section>
      {children}
      <LegendList as="ul" alignLeftForMediumScreen={alignLeftForMediumScreen}>
        {generateItems()}
      </LegendList>
    </section>
  );
};

export function createLegendSelectAction(
  { name }: LegendItem,
  selected: boolean,
): Models.eChartsLegendAction {
  return selected ? { type: 'legendUnSelect', name } : { type: 'legendSelect', name };
}

export function createSelectDataRangeAction(
  _item: LegendItem,
  _selected: boolean,
  legendItems: LegendItem[],
  deselectedItems: number[],
): Models.eChartsDataRangeAction {
  const selectedItems = reduce(
    legendItems,
    (options, _item, index) => {
      return {
        ...options,
        [index]: deselectedItems.indexOf(index) === -1,
      };
    },
    {},
  );

  return {
    type: 'selectDataRange',
    selected: selectedItems,
  };
}

Legend.defaultProps = {
  createEChartsAction: createLegendSelectAction,
};
