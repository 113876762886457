/** @format */

import * as React from 'react';
import classnames from 'classnames';

const generateBar = (props: Props): React.ReactNode => {
  if (!props.bar) {
    return null;
  }

  const classes = classnames('module-activity__bar', {
    'module-activity__bar--red': props.bar === 'red',
    'module-activity__bar--blue': props.bar === 'blue',
    'module-activity__bar--green': props.bar === 'green',
    'module-activity__bar--yellow': props.bar === 'yellow',
    'module-activity__bar--grey': props.bar === 'grey',
    'module-activity__bar--left': props.barLeft,
    'module-activity__bar--right': props.barLeft === false,
  });
  return <div className={classes} />;
};

const generateDetails = (details: React.ReactNode[]): React.ReactNode => {
  if (!details || !details.length) {
    return null;
  }

  const elements = details.map((item, index) => {
    return <li key={index}>{item}</li>;
  });

  return <ul className="module-activity__detail clearfix">{elements}</ul>;
};

interface Props {
  color?: 'grey' | null;
  bar?: 'grey' | 'red' | 'blue' | 'yellow' | 'blue' | 'green';
  barLeft?: boolean;
  avatar?: React.ReactNode;
  details?: React.ReactNode[];
  className?: string;
}
export const ModuleActivity: React.FunctionComponent<Props> = props => {
  const classes = classnames(
    'module-activity',
    {
      'module-activity--grey': props.color === 'grey',
    },
    props.className,
  );

  return (
    <div className={classes}>
      {generateBar(props)}
      <div className="module-activity__avatar">{props.avatar}</div>
      <div className="module-activity__content">{props.children}</div>
      {generateDetails(props.details)}
    </div>
  );
};

ModuleActivity.defaultProps = {
  color: null,
  bar: null,
  barLeft: true,
};

export default ModuleActivity;
