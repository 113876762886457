/**
 * @prettier
 */

import * as React from 'react';

import { ThemeType } from 'app/theme';
import { IStyle } from 'fela';
import { applyModifiers } from 'fela-rules/modifiers';
import { createComponent } from 'react-fela';
import { allTransactionsSelected } from 'apm/modules/transactions/selectors';

const StyledAnnouncementGraphic = () => ({
  display: 'block',
  maxWidth: '100%',
  margin: '0 auto',
  paddingBottom: '24px',
});

export const AnnouncementGraphic = createComponent(StyledAnnouncementGraphic, 'img', [
  'alt',
  'src',
]);
