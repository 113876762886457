/** @format */
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { findIndex, get, find } from 'lodash';

import { PageData } from 'interfaces/pageData';

import * as Actions from 'app/actions/pageData';
import { reducerUpdate } from 'utils/reducerUpdate';

export const PageDataReducer = reducerWithInitialState<PageData>(get(window, 'RaygunPageData', {}))
  .case(Actions.favouriteAppSuccess, (state, data) => {
    const Applications = state.Applications;
    const index = findIndex(Applications, app => app.id === data.id);

    Applications[index].isFavourite = data.favourite;

    return {
      ...state,
      Applications,
    };
  })
  .case(Actions.favouriteDashboardSuccess, (state, dash) => {
    const Dashboards = state.Dashboards;
    const index = findIndex(Dashboards, d => d.id === dash.dashboardId);

    Dashboards[index].isFavourite = dash.favourite;

    return {
      ...state,
      Dashboards,
    };
  })
  .case(Actions.dismissApmWelcomeModal, (state, payload) => {
    return {
      ...state,
      APMWelcomeModalSeen: payload.result,
    };
  })
  .case(Actions.updateDashboardName, (state, payload) => {
    const dashboards = state.Dashboards;
    const targetDashboard = find(dashboards, { id: payload.dashboardId });
    targetDashboard.name = payload.title;

    return reducerUpdate(state, { Dashboards: dashboards });
  });
