/**
 * @prettier
 */
import * as React from 'react';
import { range } from 'lodash';

import { Table, Column } from 'components/table/table';
import { SkeletonCell, SkeletonTableData } from 'components/table/cells/skeletonCell';
import { Flex } from 'components/flex';
import { Skeleton } from 'components/skeleton';
import { Margin, Padding } from 'components/layout';
import { Small, Medium } from 'components/breakpoints';
import HorizontalRule from 'components/horizontalRule';

const MobileLoadingSkeleton: React.FunctionComponent<{}> = () => (
  <Flex justify="space-between" direction="column" minHeight="80px">
    <Skeleton height={24} width={70} unit="%" />
    <Skeleton height={24} width={25} unit="%" />
    <Skeleton height={16} width={35} unit="%" />
  </Flex>
);

export const ErrorGroupListLoading: React.FunctionComponent<{}> = () => {
  const columns = [
    {
      header: '',
      width: 40,
      cell: new SkeletonCell(),
      cellName: 'Modify',
      padding: 'none',
    },
    {
      header: 'Message',
      cell: new SkeletonCell(),
      flatLeft: true,
    },
    { header: 'Last seen', width: 120, cell: new SkeletonCell() },
    {
      header: 'Count',
      width: 80,
      cell: new SkeletonCell(),
    },
    {
      header: 'Users',
      width: 80,
      cell: new SkeletonCell(),
    },
  ] as Column[];

  const mobileRows = range(1, 10).map(i => (
    <Margin bottom="12" key={i}>
      <MobileLoadingSkeleton />
    </Margin>
  ));

  return (
    <>
      <Small>
        <Skeleton fullWidth height={48} />

        <HorizontalRule />

        <Margin top="8">{mobileRows}</Margin>
      </Small>
      <Medium andAbove>
        <Table isHeaderHidden gutters columns={columns} data={SkeletonTableData} padding="xlarge" />
      </Medium>
    </>
  );
};
