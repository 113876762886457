/**
 * @prettier
 */

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isEmpty } from 'lodash';

import { SortDirection } from 'constants/sorting';

import { TileDataProviderProps } from '../components/tiles';
import { DashboardModuleStateAtom } from '../state';
import * as Selectors from '../selectors';
import * as Actions from '../actions';
import * as Models from '../models';
import * as Constants from '../constants';

type Options = {
  refreshOnSortingChange?: boolean;
};

type StateProps = {
  sortField: string;
  sortDirection: SortDirection;
};
type DispatchProps = {
  updateSorting: (sortField: string, sortDirection: SortDirection) => void;
  updatePageIndex?: (pageIndex: number) => void;
};
// The CR tiles do not need this one, it's not required to be supplied
// The connect call adds it. The updateSorting one does need to be passed down however
type HiddenDispatchProps = {
  refetchData: () => void;
};
type SuppliedProps = TileDataProviderProps;

type Props = StateProps & DispatchProps & SuppliedProps;
export type SortingHoCProps = StateProps & DispatchProps;

export function SortingHoC(
  TileToWrap: React.ComponentType<Props>,
  options: Options = { refreshOnSortingChange: false },
) {
  class SortingHoC extends React.Component<Props & HiddenDispatchProps, {}> {
    constructor(props: Props & HiddenDispatchProps) {
      super(props);

      this.updateSorting = this.updateSorting.bind(this);
      this.updatePageIndex = this.updatePageIndex.bind(this);
    }

    updateSorting(sortField: string, sortDirection: SortDirection) {
      this.props.updateSorting(sortField, sortDirection);

      if (options.refreshOnSortingChange) {
        this.props.refetchData();
      }
    }

    updatePageIndex(pageIndex: number) {
      this.props.updatePageIndex(pageIndex);
      this.props.refetchData();
    }

    render() {
      return <TileToWrap {...this.props} updateSorting={this.updateSorting} updatePageIndex={this.updatePageIndex} />;
    }
  }

  return connect<StateProps, DispatchProps & HiddenDispatchProps, SuppliedProps>(
    (state: DashboardModuleStateAtom, ownProps: SuppliedProps) => {
      const sortingSelector = Selectors.getListSorting(ownProps.instanceId);
      const sortingState = sortingSelector(state);

      const defaultSettingsForTile = Constants.getDefinitionForTile(ownProps.type).defaultSettings;
      const defaultSortingField = Models.getSetting(
        ownProps.settings,
        'TableSortingField',
        defaultSettingsForTile,
      ) as string;
      const defaultSortingDirection = Models.getSetting(
        ownProps.settings,
        'TableSortingDirection',
        defaultSettingsForTile,
      ) as SortDirection;

      if (isEmpty(sortingState)) {
        return {
          sortField: defaultSortingField,
          sortDirection: defaultSortingDirection,
        } as Models.SortingState;
      }

      return {
        ...sortingState,
      };
    },
    (dispatch: Dispatch, ownProps: SuppliedProps) => ({
      updateSorting: (sortField: string, sortDirection: SortDirection) =>
        dispatch(
          Actions.updateTileState({
            tileId: ownProps.instanceId,
            tileState: {
              sorting: {
                sortField,
                sortDirection,
              },
            },
          }),
        ),
      refetchData: () => dispatch(Actions.fetchTileData({ instanceId: ownProps.instanceId })),
      updatePageIndex: (pageIndex: number) =>
        dispatch(Actions.updateTileState({ tileId: ownProps.instanceId, tileState: { pageIndex } })),
    }),
  )(SortingHoC);
}
