/**
 * @prettier
 */

import React from 'react';

import { FormLayout, FormLayoutInput, FormLayoutLabel } from 'components/input';
import { OPTION, Select } from 'components/select';

import { Constants } from 'modules/dashboard';

type Props = {
  dateRangeSetting: string;
  updateDateRangeSetting: (range: string) => void;
};

export const DateRange: React.FunctionComponent<Props> = (props: Props) => {
  const dateOptions: OPTION[] = Constants.DATE_RANGES.map(d => ({
    text: d.display,
    value: d.value,
  }));

  return (
    <FormLayout marginBottom>
      <FormLayoutLabel>
        <label htmlFor="daterange">Date range</label>
      </FormLayoutLabel>
      <FormLayoutInput>
        <Select
          id="daterange"
          options={dateOptions}
          value={props.dateRangeSetting}
          onChange={range => props.updateDateRangeSetting(range)}
        />
      </FormLayoutInput>
    </FormLayout>
  );
};
