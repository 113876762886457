/** @format */
import { IStyle, IRenderer } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';
import { screenLargeAndAbove } from 'fela-rules/breakpoints';
import { scrollbar } from 'fela-rules/scrollbar';

const StyledSwitcherContainer = (props: ThemeType):IStyle => scrollbar({
    display: 'block',
    width: '100%',
    maxWidth: '912px',
    height: '100%',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: 10,
    backgroundColor: props.theme.ds.getColorByName('white'),
    overflowY: 'auto',
    ...screenLargeAndAbove({
      left: '64px',
    })
});

export const SwitcherContainer = createComponentWithProxy(StyledSwitcherContainer);


const StyledSwitcherOverlay = (props: ThemeType): IStyle => ({
  display: 'none',
  ...screenLargeAndAbove({
    display: 'block',
    position: 'fixed',
    top: '0',
    bottom: '0',
    right: '0',
    left: '64px',
    backgroundColor: props.theme.ds.getColorByName('black'),
    opacity: 0.75,
    zIndex: 9
  })
});

export const SwitcherOverlay = createComponentWithProxy(StyledSwitcherOverlay);


export const SwitcherPositionAbove = createComponentWithProxy({ 
  position: 'relative',
  zIndex: 100
}, 'div');