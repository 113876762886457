/** @format **/

import { isMobileDevice } from 'utils/mobile';

// These are custom media queries to mach the mocks for the feature gating modals

export function isTabletSize() {
  return typeof window.matchMedia !== 'undefined'
    ? window.matchMedia('only screen and (min-width: 640px) and (max-width: 843px)').matches
    : false;
}

export function isTabletSizeForBasicModal() {
  return typeof window.matchMedia !== 'undefined'
    ? window.matchMedia('only screen and (min-width: 640px) and (max-width: 1060px)').matches
    : false;
}

export function isLaptopSize() {
  return typeof window.matchMedia !== 'undefined'
    ? window.matchMedia('only screen and (min-width: 843px) and (max-width: 1181px)').matches
    : false;
}

export function isLaptopSizeForBasicModal() {
  return typeof window.matchMedia !== 'undefined'
    ? window.matchMedia('only screen and (min-width: 1060px)').matches
    : false;
}

export function isDesktopSize() {
  return typeof window.matchMedia !== 'undefined'
    ? window.matchMedia('only screen and (min-width: 1181px)').matches
    : false;
}

export function calculateMaxWidth() {
  if (isMobileDevice()) {
    return 440;
  } else if (isTabletSize()) {
    return 592;
  } else if (isLaptopSize()) {
    return 780;
  } else if (isDesktopSize()) {
    return 916;
  }
}

export function calculateMaxWidthForBasicModal() {
  if (isMobileDevice()) {
    return 440;
  } else if (isTabletSizeForBasicModal()) {
    return 592;
  } else if (isLaptopSizeForBasicModal()) {
    return 624;
  }
}