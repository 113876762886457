/** @format */

export enum PlanLevel {
  Micro = 5,
  Small = 10,
  Medium = 20,
  Large = 30,
  FreeAndTrial = 40,
  Enterprise = 50,
  Basic = 70,
  Team = 85,
  Business = 100,
}

export enum PlanLevelName {
  SideProject = 'Side Project',
  Startup = 'Startup',
  SmallBusiness = 'Small Business',
  Business = 'Business',
  FreeAndTrial = 'Trial',
  Enterprise = 'Enterprise',
  Basic = 'Basic',
  Team = 'Team',
}

export function getPlanLevelEnum(planLevelName: string): PlanLevel {
  switch (planLevelName) {
    case PlanLevelName.SideProject:
    case 'Side Project':
      return PlanLevel.Micro;
    case PlanLevelName.Startup:
    case 'Startup':
      return PlanLevel.Medium;
    case PlanLevelName.SmallBusiness:
    case 'Small Business':
      return PlanLevel.Large;
    case PlanLevelName.FreeAndTrial:
    case 'Free and Trial':
      return PlanLevel.FreeAndTrial;
    case PlanLevelName.Enterprise:
    case 'Enterprise':
      return PlanLevel.Enterprise;
    case PlanLevelName.Basic:
    case 'Basic':
      return PlanLevel.Basic;
    case PlanLevelName.Team:
    case 'Team':
      return PlanLevel.Team;
    case PlanLevelName.Business:
    case 'Business':
      return PlanLevel.Business;
    default:
      throw new Error('Plan level name does not exist.');
  }
}

export function getPlanLevelName(planLevel: PlanLevel): PlanLevelName {
  switch (planLevel) {
    case PlanLevel.Micro:
      return PlanLevelName.SideProject;
    case PlanLevel.Small:
      return PlanLevelName.Startup;
    case PlanLevel.Medium:
      return PlanLevelName.SmallBusiness;
    case PlanLevel.Large:
      return PlanLevelName.Business;
    case PlanLevel.FreeAndTrial:
      return PlanLevelName.FreeAndTrial;
    case PlanLevel.Enterprise:
      return PlanLevelName.Enterprise;
    case PlanLevel.Basic:
      return PlanLevelName.Basic;
    case PlanLevel.Team:
      return PlanLevelName.Team;
    case PlanLevel.Business:
      return PlanLevelName.Business;
    default:
      const _never: never = planLevel;
  }
}

export type Plan = {
  ApplicationCount: number;
  Avatar: string;
  Id: number;
  IsPlatformPlan: boolean;
  MemberCount: number;
  Order: number;
  PlanLevel: PlanLevel;
  PlanLevelEnum: string;
  PlanLink: string;
  PlanName: string;
  ProductCount: number;
  HasPermissionsFeatureFlag: boolean;
  HasReachedDashboardLimit: boolean;
  HasReachedAppLimit: boolean;
  IsUsingExternalProvider: boolean;
  IsHeroku: boolean;
};

export type PlanIntegrationStatus = {
  Id: number;
  PlanName: string;
  HasMicrosoftTeamsIntegration: boolean;
};

export enum PlanProductStatus {
  Operational = 0,
  TrialExpired = 2,
  Trial = 3,
  Cancelled = 4,
}

export function stringToPlanProductStatus(status: string): PlanProductStatus {
  switch (status) {
    case 'Operational':
      return PlanProductStatus.Operational;
    case 'TrialExpired':
      return PlanProductStatus.TrialExpired;
    case 'Trial':
      return PlanProductStatus.Trial;
    case 'Cancelled':
      return PlanProductStatus.Cancelled;
  }
}

export enum ProductType {
  PLATFORM = 'Platform',
  CRASH_REPORTING = 'CrashReporting',
  RUM = 'Pulse',
  APM = 'APM',
  USAGE_CAPPING = 'UsageCapping'
}

// Used to order products in cart views
export const productTypeRank = {
  CrashReporting: 0,
  Pulse: 1,
  APM: 2,
  Platform: 3,
  UsageCapping: 4,
};

export function isUsageBasedProductType(productType: ProductType): boolean {
  return productType == ProductType.CRASH_REPORTING
    || productType == ProductType.RUM
    || productType == ProductType.APM
    || productType == ProductType.PLATFORM;
}
export function isAddOnProductType(productType: ProductType): boolean {
  return productType == ProductType.USAGE_CAPPING;
}

export function getNameForProductType(productType: ProductType): string {
  switch (productType) {
    case ProductType.PLATFORM:
      return 'Full Platform';
    case ProductType.APM:
      return 'APM';
    case ProductType.CRASH_REPORTING:
      return 'Crash Reporting';
    case ProductType.RUM:
      return 'Real User Monitoring';
    case ProductType.USAGE_CAPPING:
      return 'Usage Capping';
    default:
      throw new Error('Product type does not exist.');
  }
}

export function getPricingUrlForProductType(productType: ProductType): string {
  switch (productType) {
    case ProductType.PLATFORM:
      return 'https://raygun.com/pricing#platform';
    case ProductType.APM:
      return 'https://raygun.com/pricing#apm';
    case ProductType.CRASH_REPORTING:
      return 'https://raygun.com/pricing#crash';
    case ProductType.RUM:
      return 'https://raygun.com/pricing#rum';
    case ProductType.USAGE_CAPPING:
      return 'https://raygun.com/pricing#add-ons';
    default:
      const _never: never = productType;
  }
}

export function getEventNameForProductType(type: ProductType): string {
  switch (type) {
    case ProductType.PLATFORM:
      return 'event';
    case ProductType.APM:
      return 'trace';
    case ProductType.CRASH_REPORTING:
      return 'error';
    case ProductType.RUM:
      return 'session';
    default:
      throw new Error(`${type} event name doesn't exist`);
  }
}

export function getPluralEventNameForProductType(type: ProductType): string {
  switch (type) {
    case ProductType.PLATFORM:
      return 'events';
    case ProductType.APM:
      return 'traces';
    case ProductType.CRASH_REPORTING:
      return 'errors';
    case ProductType.RUM:
      return 'sessions';
    default:
      throw new Error(`${type} plural event name doesn't exist`);
  }
}
