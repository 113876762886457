/** @format **/

import * as React from 'react';
import { isNil } from 'lodash';

import { Tabs as RumTabs } from 'legacy/sections/rum/components/productHeader/tabs';
import { Tabs as CrashTabs } from 'legacy/components/crashReportingPageHeader/tabs';
import { Tabs as PlanSettingsTabs } from 'legacy/components/planSettingsPageHeader/tabs';
import { ApplicationTriggerHeader } from 'app/chrome/revamped';
import { Container as HeaderContainer } from 'components/page/header/container';
import { Section as HeaderSection } from 'components/page/header/section';
import { H2 } from 'components/ds/text';
import { Margin } from 'components/layout';

import { HeaderTypes, SecondaryHeaderLink } from '../secondaryTabs/models';
import { SecondaryTabs } from '../secondaryTabs';

type HeaderProps = {
  headerType: HeaderTypes;
  secondaryLinks?: SecondaryHeaderLink[];
  title?: string;
};

export const Header: React.FunctionComponent<HeaderProps> = props => {
  return (
    <HeaderContainer>
      {!!props.title && (
        <Margin top="8" bottom="8">
          <H2>{props.title}</H2>
        </Margin>
      )}
      {!!!props.title && <ApplicationTriggerHeader />}
      {props.headerType !== 'base' && (
        <HeaderSection>
          {props.headerType === 'crash' && <CrashTabs />}
          {props.headerType === 'rum' && <RumTabs />}
          {props.headerType === 'planSettings' && <PlanSettingsTabs />}
        </HeaderSection>
      )}
      {!isNil(props.secondaryLinks) && (
        <HeaderSection>
          <SecondaryTabs secondaryLinks={props.secondaryLinks} />
        </HeaderSection>
      )}
    </HeaderContainer>
  );
};

Header.defaultProps = {
  secondaryLinks: [],
};
