/** @format */

import * as React from 'react';

import ListItem from './item';
import ListCell from './cell';
import { ListCellCross } from './cellCross';
import { TickList } from './tickList';

interface Props {}

const List: React.FunctionComponent<Props> = props => {
  return <ul className="active-list">{props.children}</ul>;
};

export default List;

export { List, ListItem, ListCell, ListCellCross, TickList };
