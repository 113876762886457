/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import * as React from 'react';
import { DefaultProps, BaseDefaultProps } from '../tags/base';
import { BaseWrapper, BaseWrapperProps } from '../tags/base/wrapper';
import { BaseTextProps, BaseText } from '../tags/base/text';
import { BaseIconProps, BaseIcon } from '../tags/base/icon';

import { FilterPending, FilterPendingProps } from './pending';
import { TagTrack } from '../tags';

export { DefaultProps, BaseDefaultProps };

export class Filter extends React.Component<BaseWrapperProps> {
  static defaultProps: Pick<BaseWrapperProps, 'disabled'> = {
    ...DefaultProps,
  };

  render() {
    const { children, ...props } = this.props;
    return <BaseWrapper {...props}>{children}</BaseWrapper>;
  }
}

type DefaultFilterTextPropKeys =
  | 'textColor'
  | 'disabledTextColor'
  | 'backgroundColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | 'disabledBackgroundColor'
  | keyof BaseTextProps;

export class FilterText extends React.Component<BaseTextProps> {
  static defaultProps: Pick<BaseTextProps, DefaultFilterTextPropKeys> = {
    ...DefaultProps,
    textColor: 'white',
    fontWeight: 500,
    underline: false,
    disabledTextColor: 'white',
    disabledBackgroundColor: 'grey400',
    backgroundColor: 'blue1100',
    hoverBackgroundColor: 'blue1200',
    pressedBackgroundColor: 'blue1300',
  };

  render() {
    const { children, ...props } = this.props;
    return <BaseText {...props}>{children}</BaseText>;
  }
}

type DefaultFilterIconPropKeys =
  | 'textColor'
  | 'disabledTextColor'
  | 'backgroundColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | 'disabledBackgroundColor'
  | keyof BaseIconProps;

export class FilterIcon extends React.Component<BaseIconProps> {
  static defaultProps: Pick<BaseIconProps, DefaultFilterIconPropKeys> = {
    ...DefaultProps,
    icon: 'cross',
    textColor: 'white',
    disabledTextColor: 'grey400',
    disabledBackgroundColor: 'grey300',
    backgroundColor: 'blue1000',
    hoverBackgroundColor: 'blue1100',
    pressedBackgroundColor: 'blue1200',
  };

  render() {
    return <BaseIcon {...this.props} />;
  }
}

type DefaultFilterCloseIconPropKeys =
  | 'textColor'
  | 'backgroundColor'
  | 'disabledTextColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | 'disabledBackgroundColor'
  | keyof BaseIconProps;

export class FilterCloseIcon extends React.Component<BaseIconProps> {
  static defaultProps: Pick<BaseIconProps, DefaultFilterCloseIconPropKeys> = {
    ...DefaultProps,
    icon: 'cross',
    textColor: 'white',
    disabledTextColor: 'grey400',
    disabledBackgroundColor: 'grey300',
    backgroundColor: 'blue1000',
    hoverBackgroundColor: 'blue1100',
    pressedBackgroundColor: 'blue1200',
  };

  render() {
    return <BaseIcon icon="cross" {...this.props} />;
  }
}

type DefaultFilterAddIconPropKeys =
  | 'textColor'
  | 'backgroundColor'
  | 'disabledTextColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | 'disabledBackgroundColor'
  | keyof BaseIconProps;

export class FilterAddIcon extends React.Component<BaseIconProps> {
  static defaultProps: Pick<BaseIconProps, DefaultFilterAddIconPropKeys> = {
    ...DefaultProps,
    icon: 'plus',
    textColor: 'white',
    disabledTextColor: 'grey400',
    disabledBackgroundColor: 'grey300',
    backgroundColor: 'blue1000',
    hoverBackgroundColor: 'blue1100',
    pressedBackgroundColor: 'blue1200',
  };

  render() {
    return <BaseIcon icon="plus" {...this.props} />;
  }
}

type DefaultFilterPendingPropKeys =
  | 'iconColor'
  | 'backgroundColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | keyof FilterPendingProps;

export class FilterPendingIcon extends React.Component<FilterPendingProps> {
  static defaultProps: Pick<FilterPendingProps, DefaultFilterPendingPropKeys> = {
    iconColor: 'white',
    backgroundColor: 'blue1000',
    hoverBackgroundColor: 'blue1100',
    pressedBackgroundColor: 'blue1200',
  };
  render() {
    return <FilterPending {...this.props} />;
  }
}

export const FilterTrack = TagTrack;
