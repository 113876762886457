/**
 * @prettier
 */

import { createComponent } from 'react-fela';
import { IStyle } from 'fela';

import { ThemeType } from 'app/theme';

const StyledFilterTag = (props: ThemeType): IStyle => ({
  display: 'inline-block',
  padding: '2px 5px',
  marginBottom: '4px',
  backgroundColor: props.theme.colors.blue.standard,
  borderRadius: '2px',
  marginRight: '6px',
  fontSize: '12px',
  color: props.theme.colors.text.white,
  cursor: 'pointer',
  userSelect: 'none',
  transition: 'background-color 100ms ease',
  ':hover': {
    backgroundColor: props.theme.colors.blue.light,
  },
});

export const FilterTag = createComponent(StyledFilterTag, 'span', ['onClick']);
