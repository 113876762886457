/** @format */
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'interfaces/appState';
import { showRevampedNavigation } from 'app/selectors/configuration';

type StateProps = {
  hasRevampedChrome: boolean;
};

type DispatchProps = {};

type PassedProps = {};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedHasRevampedChrome: React.FunctionComponent<Props> = ({ hasRevampedChrome, children }) => hasRevampedChrome ? (
  <>{children}</>
) : null;

const ConnectedHasRevampedChrome = connect<StateProps, DispatchProps, PassedProps, AppState>(
  (state) => ({
    hasRevampedChrome: showRevampedNavigation(state)
  })
)(UnconnectedHasRevampedChrome);

export { ConnectedHasRevampedChrome as HasRevampedChrome };