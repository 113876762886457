/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { isNil } from 'lodash';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { scrollbar } from 'fela-rules/scrollbar';

import { SearchInput } from 'components/ds/inputs';
import { SearchableDropdownDivider } from 'components/ds/dropdown/menu/search';

import { Padding } from '../../../layout';

export const ITEM_HEIGHT = 40;

type DropdownMenuProps = {
  searchOnChange?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  searchValue?: string;
  clearableSearch?: boolean;
};

type ContentContainerProps = {
  overflowCount: number;
  overflow: boolean;
  block?: boolean;
  small?: boolean;
  removePadding?: boolean;
};
export type Props = DropdownMenuProps & ContentContainerProps;

export const DropdownMenu: React.FunctionComponent<Props> = props => (
  <div>
    <DropdownMenuContainer block={props.block} small={props.small}>
      {!isNil(props.searchOnChange) && (
        <>
          <SearchInput
            placeholder="Search"
            borderColor={'transparent'}
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={props.searchOnChange}
            value={props.searchValue}
            autoFocus
            clearable={props.clearableSearch}
          />
          <SearchableDropdownDivider />
        </>
      )}
      <DropdownMenuContentContainer overflow={props.overflow} overflowCount={props.overflowCount}>
        {props.removePadding || !isNil(props.searchOnChange) ? (
          props.children
        ) : (
          <Padding top={8} bottom={8}>
            {props.children}
          </Padding>
        )}
      </DropdownMenuContentContainer>
    </DropdownMenuContainer>
  </div>
);

type DropdownMenuContainer = {
  block?: boolean;
  small?: boolean;
} & DropdownMenuProps;

const StyledDropdownMenuContainer = (props: DropdownMenuContainer & ThemeType): IStyle => ({
  width: props.block ? '100%' : props.small ? '182px' : '240px',
  border: `1px solid ${props.theme.ds.getColorByName('blueGrey100')}`,
  boxShadow: props.theme.ds.standardBoxShadow,
  borderRadius: '2px',
});

const DropdownMenuContainer = createComponent(StyledDropdownMenuContainer);

const StyledDropdownMenuContentContainer = (props: ThemeType & ContentContainerProps): IStyle =>
  scrollbar(
    applyModifiers(
      [
        props.overflow,
        {
          overflow: 'auto',
        },
      ],
      [
        !isNil(props.overflowCount),
        {
          maxHeight: `${props.overflowCount * ITEM_HEIGHT + 16}px`,
        },
      ],
    )({
      backgroundColor: 'white',
    }),
  );

const DropdownMenuContentContainer = createComponent(StyledDropdownMenuContentContainer);
