/** @format **/

import React from 'react';
import { Omit } from 'utils/types';

import { BaseButton, BaseButtonProps, BaseDefaultProps, DefaultProps } from './base';

type TextButtonProps = Omit<BaseButtonProps, 'backgroundColor'>;
export class TextButton extends React.Component<TextButtonProps> {
  static defaultProps: Pick<
    TextButtonProps,
    | 'textColor'
    | 'disabledTextColor'
    | 'disabledBackgroundColor'
    | 'hoverBackgroundColor'
    | 'pressedBackgroundColor'
    | keyof BaseDefaultProps
  > = {
    ...DefaultProps,
    textColor: 'blue1000',
    disabledBackgroundColor: 'transparent',
    disabledTextColor: 'grey400',
    hoverBackgroundColor: 'blue100',
    pressedBackgroundColor: 'blue200',
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <BaseButton {...props} backgroundColor="transparent">
        {children}
      </BaseButton>
    );
  }
}