/** @format **/
/* tslint:disable:max-file-line-count */

import React, { Component } from 'react';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';
import { isNil } from 'lodash';

import { applyModifiers } from 'fela-rules/modifiers';

import { ReactComponent } from 'utils/types';

import { BaseEventNames, BaseEventProps } from 'components/ds';
import { withToggleable, WithToggleableSuppliedProps } from 'components/hoc/withToggleable';

import { MenuItemWithIconProps } from '../menu/items/icon';
import { DropdownMenu, MenuItemProps, Props as MenuProps } from '../menu';

type POSITIONS = 'left' | 'right';
type HEIGHTS = 'small' | 'normal';

export type Props = {
  Trigger: ReactComponent<any>;
  triggerText: string;
  triggerProps: any;
  position: POSITIONS;
  height: HEIGHTS;
  block: boolean;
  disabled: boolean;
  onValueChange?: (value: any, item: MenuItemProps | MenuItemWithIconProps) => void;
  onToggle?: (isToggled: any) => void;
  hasLabel?: boolean;
  children:
  | React.ReactElement<MenuItemProps>[]
  | React.ReactElement<MenuItemProps>
  | ((toggleMenu: () => void) => React.ReactNode);
  small: boolean;
  fullWidthOnMobile?: boolean;
  fullWidth?: boolean;
  inputId?: string;
  forSubtle?: boolean;
  searchable?: boolean;
  searchOnChange?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  searchValue?: string;
  clearableSearch?: boolean;
  onVisibilityChange?: (display: boolean) => void;
  removePadding?: boolean;
  multiSelect?: boolean;
} & BaseEventProps<HTMLDivElement> &
  MenuProps;
type FullProps = Props & WithToggleableSuppliedProps;

export const DefaultProps: Pick<
  Props,
  | keyof MenuProps
  | 'position'
  | 'height'
  | 'block'
  | 'disabled'
  | 'hasLabel'
  | 'small'
  | 'fullWidthOnMobile'
  | 'fullWidth'
  | 'forSubtle'
  | 'multiSelect'
  | 'searchable'
> = {
  overflowCount: 8,
  overflow: true,
  position: 'left',
  height: 'normal',
  block: false,
  disabled: false,
  hasLabel: false,
  small: true,
  fullWidthOnMobile: false,
  fullWidth: false,
  forSubtle: false,
  removePadding: false,
  multiSelect: false,
  searchable: false,
  clearableSearch: false,
};

export class BaseTriggerWithoutHoc extends Component<FullProps> {
  container: HTMLDivElement;

  static defaultProps = DefaultProps;

  constructor(props: FullProps) {
    super(props);

    this.maybeCloseDropdown = this.maybeCloseDropdown.bind(this);
    this.triggerClicked = this.triggerClicked.bind(this);
    this.toggleInternal = this.toggleInternal.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.maybeCloseDropdown);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.maybeCloseDropdown);
  }

  maybeCloseDropdown(e: Event) {
    const target = e.target as HTMLElement;

    if (this.props.toggled && !this.container.contains(target)) {
      this.toggleInternal();
    }
  }

  attachOnClickToChildren() {
    const { children } = this.props;
    if (!isNil(this.props.onValueChange)) {
      return React.Children.map(children, i =>
        React.cloneElement(i as any, {
          onClick: () => {
            // Iterates through the dropdown list items giving each one onClick functionality
            // to update the Select component state when clicked.
            const child = i as any;
            const { value, label } = child.props;
            const iValue = !isNil(value) ? value : label;
            this.props.onValueChange(iValue, child.props);
          },
        }),
      );
    }
    return children;
  }

  triggerClicked(evt: React.MouseEvent<HTMLElement>) {
    const { triggerProps, multiSelect } = this.props;

    if (triggerProps.onClick) {
      triggerProps.onClick(evt);
    }

    const target = evt.target as HTMLElement;

    var targetIsDisabled = false;

    React.Children.map(this.props.children, i => {
      const child = i as any;
      if (child != null && child.props != null) {
        const { label, disabled } = child.props;

        if (label == target.textContent && disabled) {
          targetIsDisabled = true;
        }
      }
    });

    if (!targetIsDisabled) {
      this.toggleInternal();
    }
  }

  toggleInternal() {
    const { toggled, toggle, onToggle, onVisibilityChange } = this.props;

    toggle();

    if (!!onToggle) {
      onToggle(toggled);
    }

    if (onVisibilityChange) {
      onVisibilityChange(!toggled);
    }
  }

  render() {
    const {
      Trigger,
      triggerText,
      toggle,
      toggled,
      overflowCount,
      overflow,
      triggerProps,
      position,
      height,
      block,
      disabled,
      hasLabel,
      small,
      fullWidthOnMobile,
      onVisibilityChange,
      onToggle,
      fullWidth,
      inputId,
      forSubtle,
      searchOnChange,
      searchValue,
      clearableSearch,
      removePadding,
      multiSelect,
      searchable,
      ...baseEventProps
    } = this.props;

    const children = this.attachOnClickToChildren();

    return (
      <DropdownContainer
        innerRef={ref => (this.container = ref)}
        block={block}
        small={small}
        {...baseEventProps}
      >
        <Trigger
          id={inputId}
          {...triggerProps}
          onClick={!disabled ? this.triggerClicked : null}
          forDropdownTrigger
          fullWidth={fullWidth}
          onToggle={onToggle}
        >
          {triggerText}
        </Trigger>

        {toggled && !disabled && (
          <DropdownMenuContainer
            disabled={disabled}
            onClick={multiSelect ? null : this.triggerClicked}
            position={position}
            height={height}
            hasLabel={hasLabel}
            block={block}
            small={small}
            forSubtle={forSubtle}
            searchable={searchable}
          >
            <DropdownMenu
              {...{
                overflowCount,
                overflow,
                block,
                small,
                removePadding,
                searchOnChange,
                searchValue,
                clearableSearch,
              }}
            >
              {typeof baseEventProps.children == 'function'
                ? baseEventProps.children(toggle)
                : children}
            </DropdownMenu>
          </DropdownMenuContainer>
        )}
      </DropdownContainer>
    );
  }
}

type StyledDropdownContainerProps = {
  block: boolean;
  small: boolean;
};

const StyledDropdownContainer = (props: StyledDropdownContainerProps): IStyle => ({
  position: 'relative',
  display: props.block ? 'block' : 'inline-block',
});

const DropdownContainer = createComponent(StyledDropdownContainer);

type DropdownMenuContainerProps = {
  position: POSITIONS;
  height: HEIGHTS;
  hasLabel: boolean;
  block: boolean;
  small: boolean;
  forSubtle: boolean;
  searchable?: boolean;
};
const StyledDropdownMenuContainer = (props: DropdownMenuContainerProps): IStyle =>
  applyModifiers(
    [
      props.position === 'right',
      {
        right: 0,
      },
    ],
    [
      props.hasLabel,
      {
        top: '58px',
      },
    ],
    [
      props.block,
      {
        left: 0,
        right: 0,
      },
    ],
    [
      props.forSubtle,
      {
        marginTop: '8px',
      },
    ],
    [
      props.searchable,
      {
        top: '41px',
      },
    ],
  )({
    position: 'absolute',
    top: '100%',
    transform: 'translateY(-2px)',
    zIndex: 4,
  });

const DropdownMenuContainer = createComponent(StyledDropdownMenuContainer, 'div', [
  'disabled',
  ...BaseEventNames,
]);

const BaseTriggerWithHoc = withToggleable<Props, FullProps>(BaseTriggerWithoutHoc);

export { BaseTriggerWithHoc as BaseTrigger };
