/**
 * @prettier
 */

import * as React from 'react';
import { get } from 'lodash';
import classnames from 'classnames';

import { Icon } from 'components/icon';

interface OPTION {
  value?: string;
  text: string;
}

interface Props {
  options: OPTION[];
  id?: string;
  type?: 'grey' | 'white' | null;
  size?: '32' | '36' | '40';
  disabled?: boolean;
  inlineBlock?: boolean;
  alignMiddle?: boolean;
  value?: string;
  name?: string;
  onChange?: (newValue: string) => void;
  className?: string;
  hasError?: boolean;
  placeholder?: string;
  required?: boolean;
}

interface State {
  value: string;
}

class Select extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: get(this.props, 'value', ''),
    };

    this.onChange = this.onChange.bind(this);
  }

  static defaultProps: Partial<Props> = {
    type: 'white',
  };

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value != this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  onChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.currentTarget.value;

    this.setState(() => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
      return { value };
    });
  }

  render() {
    const classes = classnames('select-input', {
      'select-input--inline-block': this.props.inlineBlock,
      'select-input--align-middle': this.props.alignMiddle,
    });

    const selectClasses = classnames(
      'select-ui',
      {
        'select-ui--grey': this.props.type === 'grey',
        'select-ui--invalid': this.props.hasError,
        'select-ui--32': this.props.size === '32',
        'select-ui--36': this.props.size === '36',
      },
      this.props.className,
    );

    const options = this.props.options.map((item, index) => {
      return (
        <option value={item.value} key={index}>
          {item.text}
        </option>
      );
    });

    return (
      <div className={classes}>
        <div className="select-input__ui">
          <select
            id={this.props.id}
            className={selectClasses}
            disabled={this.props.disabled}
            onChange={this.onChange}
            name={this.props.name}
            value={this.state.value}
            required={this.props.required}
          >
            {!!this.props.placeholder && (
              <option value="" disabled>
                {this.props.placeholder}
              </option>
            )}
            {options}
          </select>
        </div>
        <div className="select-input__icon">
          <Icon size={16} type="dropdown" />
        </div>
      </div>
    );
  }
}

export default Select;

export { OPTION, Select };
