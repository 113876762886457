/** @format **/

import React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { AppState } from 'interfaces/appState';
import * as Selectors from 'modules/whatsnew/selectors';
import * as Actions from 'modules/whatsnew/actions';

import { Module, ModuleContent } from 'components/module';
import { getTextColorClass, HEADING_CLASSES } from 'components/text';
import { Padding } from 'components/layout';
import { Checkbox } from 'components/checkbox';

import { WhatsNewCategory } from '../models';
import { WhatsNewState } from '../state';
import { ToggleCategoryFilterPayload } from '../actions';

type StateProps = {
  filters: WhatsNewState['filters'];
};

type DispatchProps = {
  toggleCategoryFilter: (payload: ToggleCategoryFilterPayload) => void;
};

type Props = StateProps & DispatchProps;

const StyledFilterContainer = (): IStyle => ({
  display: 'flex',
  position: 'relative',
  paddingLeft: '54px',
  flexWrap: 'wrap',
  alignContent: 'center',
});

const FilterContainer = createComponent(StyledFilterContainer);

const StyledFilterHeader = (): IStyle => ({
  position: 'absolute',
  left: '0',
  top: '4px',
});

const Categories: WhatsNewCategory[] = [
  WhatsNewCategory.Platform,
  WhatsNewCategory.Providers,
  WhatsNewCategory.RealUserMonitoring,
  WhatsNewCategory.CrashReporting,
  WhatsNewCategory.APM,
  WhatsNewCategory.Integrations,
];

const FilterHeader = createComponent(StyledFilterHeader);

const FilterBar: React.FunctionComponent<Props> = props => {
  const checkBoxes = map(Categories, categoryName => (
    <Padding right="8" key={categoryName}>
      <Checkbox
        isChecked={props.filters.categories[categoryName]}
        onToggled={toggled =>
          props.toggleCategoryFilter({
            category: categoryName,
            toggled,
          })
        }
      >
        {categoryName}
      </Checkbox>
    </Padding>
  ));

  return (
    <Module>
      <ModuleContent padding="all">
        <FilterContainer>
          <FilterHeader>
            <h3 className={`${HEADING_CLASSES.FIVE} ${getTextColorClass('grey')}`}>Filter:</h3>
          </FilterHeader>
          {checkBoxes}
        </FilterContainer>
      </ModuleContent>
    </Module>
  );
};

const ConnectedFilterBar = connect<StateProps, DispatchProps, {}>(
  (state: AppState) => ({
    filters: Selectors.getFilters(state),
  }),
  (dispatch: Dispatch) => ({
    updateLastViewed: (date: Date) => dispatch(Actions.updateLastViewedDate(date)),
    toggleCategoryFilter: (payload: ToggleCategoryFilterPayload) =>
      dispatch(Actions.toggleCategoryFilter(payload)),
  }),
)(FilterBar);

export { ConnectedFilterBar as FilterBar };
