/** @format */

import * as React from 'react';
import { Icon, IconProps } from './index';

interface Props {
  spanElement?: boolean;
  size?: '16' | '20' | '24' | '32' | '40';
  icon: React.ReactNode;
}

export const IconAfter: React.FunctionComponent<Props> = props => {
  const classes = `text-icon-after text-icon-after--${props.size}`;
  const textClasses = `text-icon-after__icon text-icon-after__icon--${props.size}`;
  const iconClasses = `text-icon-after__text text-icon-after__text--${props.size}`;

  if (props.spanElement === false) {
    return (
      <span className={classes}>
        <span className={textClasses}>{props.icon}</span>
        <span className={iconClasses}>{props.children}</span>
      </span>
    );
  }

  return (
    <div className={classes}>
      <div className="text-icon__icon">{props.icon}</div>
      <div className="text-icon__text">{props.children}</div>
    </div>
  );
};

IconAfter.defaultProps = {
  size: '16',
  spanElement: false,
};

export default IconAfter;
