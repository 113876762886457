/** @prettier **/

import { createRoutingAction } from 'utils/routing';

export const GoToPlanIntegrations = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_INTEGRATIONS',
);

export const GoToMicrosoftTeamsPlanSelector = createRoutingAction(
  'GO_TO_MICROSOFTTEAMS_PLAN_SELECTOR',
);
