/** @format */

import * as React from 'react';

import { Provider as ReduxProvider } from 'react-redux';
import { RendererProvider, ThemeProvider } from 'react-fela';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Theme } from 'app/theme';

const queryClient = new QueryClient();

export type ProviderDetails = {
  store: any;
  renderer: any;
};

type Props = {} & ProviderDetails;

export const Providers: React.FunctionComponent<Props> = ({ children, store, renderer }) => {
  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <RendererProvider renderer={renderer}>
          <ThemeProvider theme={Theme}>{children}</ThemeProvider>
        </RendererProvider>

        {/* This component internally stops itself from being rendered in production environments. */}
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
      </QueryClientProvider>
    </ReduxProvider>
  );
};

export default Providers;
