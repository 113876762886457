/** @format **/

import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { Theme } from 'app/theme';

const StyledHelptipIcon = (): IStyle => ({
  background: Theme.ds.getColorByName('blueGrey75'),
  display: 'inline-block',
  width: '16px',
  height: '16px',
  textAlign: 'center',
  borderRadius: '50%',
  fontSize: '14px',
  lineHeight: '16px',
  color: Theme.ds.getColorByName('blueGrey400'),
  fontWeight: 700,
  cursor: 'default',
  '::before': {
    content: '"?"',
  },
});

export const HelptipIcon = createComponent(StyledHelptipIcon, 'span');
