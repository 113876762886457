/**
 * @prettier
 */

import * as React from 'react';

import { ThemeType } from 'app/theme';
import { IStyle } from 'fela';
import { applyModifiers } from 'fela-rules/modifiers';
import { createComponent } from 'react-fela';

type Props = {
  fullWidth?: boolean;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
};

const StyledItemDivider = (props: Props & ThemeType) => {
  const styles: IStyle = {
    borderRight: `1px solid ${props.theme.colors.grey.standard}`,
    ':last-child': {
      borderRight: '0',
    },
  };

  return applyModifiers(
    [!!props.paddingTop, { paddingTop: `${props.paddingTop}px` }],
    [!!props.paddingBottom, { paddingBottom: `${props.paddingBottom}px` }],
    [!!props.paddingLeft, { paddingLeft: `${props.paddingLeft}px` }],
    [!!props.paddingRight, { paddingRight: `${props.paddingRight}px` }],
  )(styles);
};

export const ItemDivider = createComponent(StyledItemDivider);
