/** @format */

import * as React from 'react';

import * as charting from 'utils/echarts/index';
import { translateCountryCodeToName } from 'utils/translateCountryCode';
import * as Bucketing from 'utils/realusermonitoring/mapBucketing';
import { formatNumber } from 'utils/numberFormatting';
import { SessionDistributionPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { GeoDistributionTile } from './geoDistributionTile';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';

class Chart extends React.Component<ProviderProps, {}> {
  tooltipFormatter(params: any) {
    let valText;

    if (!params.value || params.value === '-') {
      valText = '0 sessions';
    } else if (params.value === 1) {
      valText = '1 session';
    } else {
      valText = `${formatNumber(params.value)} sessions`;
    }
    return charting.renderSeriesTooltip({
      series: [
        {
          name: params.name as string,
          value: valText,
          colorcode: Bucketing.sessionToolTipColorFunction(params.value, params.color),
        },
      ],
    });
  }

  render() {
    const data = this.props.data as SessionDistributionPayload[];
    const countriesWithMinMax = data.reduce(
      (acc, { Count, CountryCode }) => {
        return {
          countries: [
            ...acc.countries,
            { name: translateCountryCodeToName(CountryCode), value: Count },
          ],
          min: Math.min(Count, acc.min),
          max: Math.max(Count, acc.max),
        };
      },
      { countries: [] as { name: string; value: number }[], min: 999999999, max: -999999999999 },
    );

    return (
      <GeoDistributionTile
        {...this.props}
        countries={countriesWithMinMax.countries}
        bucketingPieces={Bucketing.sessionPieceBucketing(
          countriesWithMinMax.min,
          countriesWithMinMax.max,
        )}
        legendLabels={{ higher: 'Most sessions', lower: 'Least sessions' }}
        tooltipFormatter={this.tooltipFormatter}
      />
    );
  }
}
const LoadTimeDistributionMap: React.FunctionComponent<ProviderProps> = props => (
  <Chart {...props} />
);

const ConnectedTile = TileDataProvider(LoadTimeDistributionMap, ChartTileLoading);

export { ConnectedTile as SessionDistributionMap };
