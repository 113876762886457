/** @format **/

import { createSelector } from 'reselect';
import { AppState } from 'interfaces/appState';

const pageConfigurationSelector = (state: AppState) => state.app.configuration;

/**
 * List of permissions
 */
export const getPermissions = createSelector(
  pageConfigurationSelector,
  pd => pd.Permissions,
);
