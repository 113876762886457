/** @format */

export type BLUE_TYPES = {
  blue50: '#E3F2FD';
  blue100: '#BBDEFB';
  blue200: '#90CAF9';
  blue300: '#E64b5F6';
  blue400: '#42A5F5';
  blue500: '#2196F3';
  blue600: '#1E88E5';
  blue700: '#1976D2';
  blue800: '#1A77BE';
  blue900: '#0D47A1';
};

export const BLUE: BLUE_TYPES = {
  blue50: '#E3F2FD',
  blue100: '#BBDEFB',
  blue200: '#90CAF9',
  blue300: '#E64b5F6',
  blue400: '#42A5F5',
  blue500: '#2196F3',
  blue600: '#1E88E5',
  blue700: '#1976D2',
  blue800: '#1A77BE',
  blue900: '#0D47A1',
};

export type GREY_TYPES = {
  grey100: '#F5F5F5';
  grey200: '#EEEEEE';
  grey300: '#E0E0E0';
  grey400: '#BDBDBD';
  grey500: '#D3D3D3';
  grey600: '#757575';
  grey700: '#616161';
  grey800: '#424242';
  grey900: '#212121';
};

export const GREY: GREY_TYPES = {
  grey100: '#F5F5F5',
  grey200: '#EEEEEE',
  grey300: '#E0E0E0',
  grey400: '#BDBDBD',
  grey500: '#D3D3D3',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#424242',
  grey900: '#212121',
};

export type RED_TYPES = {
  red50: '#FFEBEE';
  red100: '#FFCDD2';
  red200: '#EF9A9A';
  red300: '#E57373';
  red400: '#EF5350';
  red500: '#F44336';
  red600: '#E53935';
  red700: '#D32F2F';
  red800: '#C62828';
  red900: '#B71C1C';
};

export const RED: RED_TYPES = {
  red50: '#FFEBEE',
  red100: '#FFCDD2',
  red200: '#EF9A9A',
  red300: '#E57373',
  red400: '#EF5350',
  red500: '#F44336',
  red600: '#E53935',
  red700: '#D32F2F',
  red800: '#C62828',
  red900: '#B71C1C',
};

export type GREEN_TYPES = {
  green50: '#E8F5E9';
  green100: '#C8E6C9';
  green200: '#A5D6A7';
  green300: '#81C784';
  green400: '#66BB6A';
  green500: '#4CAF50';
  green600: '#43A047';
  green700: '#388E3C';
  green800: '#2E7D32';
  green900: '#1B5E20';
};

export const GREEN: GREEN_TYPES = {
  green50: '#E8F5E9',
  green100: '#C8E6C9',
  green200: '#A5D6A7',
  green300: '#81C784',
  green400: '#66BB6A',
  green500: '#4CAF50',
  green600: '#43A047',
  green700: '#388E3C',
  green800: '#2E7D32',
  green900: '#1B5E20',
};

export type GREENL_TYPES = {
  greenL500: '#8BC34A';
};

export const GREENL: GREENL_TYPES = {
  greenL500: '#8BC34A',
};

export type AMBER_TYPES = {
  amber50: '#FFF8E1';
  amber100: '#FFECB3';
  amber200: '#FFE082';
  amber300: '#FFD54F';
  amber400: '#FFCA28';
  amber500: '#FFC107';
  amber600: '#FFB300';
  amber700: '#FFA000';
  amber800: '#FF8F00';
  amber900: '#FF6F00';
};

export const AMBER: AMBER_TYPES = {
  amber50: '#FFF8E1',
  amber100: '#FFECB3',
  amber200: '#FFE082',
  amber300: '#FFD54F',
  amber400: '#FFCA28',
  amber500: '#FFC107',
  amber600: '#FFB300',
  amber700: '#FFA000',
  amber800: '#FF8F00',
  amber900: '#FF6F00',
};

export type YELLOW_TYPES = {
  yellow50: '#FFFDE7';
  yellow100: '#FFF9C4';
  yellow200: '#FFF59D';
  yellow300: '#FFF176';
  yellow400: '#FFEE58';
  yellow500: '#FFEB3B';
  yellow600: '#FDD835';
  yellow700: '#FBC02D';
  yellow800: '#F9A825';
  yellow900: '#F57F17';
};

export const YELLOW: YELLOW_TYPES = {
  yellow50: '#FFFDE7',
  yellow100: '#FFF9C4',
  yellow200: '#FFF59D',
  yellow300: '#FFF176',
  yellow400: '#FFEE58',
  yellow500: '#FFEB3B',
  yellow600: '#FDD835',
  yellow700: '#FBC02D',
  yellow800: '#F9A825',
  yellow900: '#F57F17',
};

export type SOCIAL_TYPES = {
  facebook: '#3B5998';
  github: '#353535';
  google: '#DC493C';
  twitter: '#00ACEE';
};

export const SOCIAL: SOCIAL_TYPES = {
  facebook: '#3B5998',
  github: '#353535',
  google: '#DC493C',
  twitter: '#00ACEE',
};
