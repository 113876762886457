/** @format **/

import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';

import { openModal } from "components/modal";

import { CreateApplicationButton } from '../createApplicationButton';
import { CreateDashboardButton } from '../createDashboardButton';

import * as ChromeSelectors from 'app/selectors/chrome';

import { DashboardsGatedFeatureModal } from "../dashboardsFeatureGating";
import { SWITCHER_TYPE } from '../switcher';
import {trackLinkClick} from "components/snowplow";

type StateProps = {
  createApplicationLink: string;
  createDashboardLink: string;
  canCreateApplicationsForPlan: boolean;
  tooltipLabelForDisabledButton: string;
};

type DispatchProps = {
  openFeatureGatingModal: () => void;
};

type SuppliedProps = {
  type: SWITCHER_TYPE;
};

type Props = StateProps & DispatchProps & SuppliedProps;
export const UnconnectedCreate: React.FunctionComponent<Props> = ({
  type,
  createApplicationLink,
  createDashboardLink,
  canCreateApplicationsForPlan,
  tooltipLabelForDisabledButton,
  openFeatureGatingModal
}) => {

  return (
    <>
      {type === SWITCHER_TYPE.Dashboards && (
        <CreateDashboardButton
          createDashboardLink={createDashboardLink}
          buttonHeight={32}
          openFeatureGatingModal={() => openFeatureGatingModal()}
        />
      )}
      {type === SWITCHER_TYPE.Applications && (
        <CreateApplicationButton
          createApplicationLink={createApplicationLink}
          enableCreateAppButton={canCreateApplicationsForPlan}
          tooltipLabelForDisabledButton={tooltipLabelForDisabledButton}
          buttonHeight={32}
        />
      )}
    </>
  );
};

export const Create = connect<StateProps, DispatchProps, SuppliedProps, AppState>(
  state => ({
    createApplicationLink: ChromeSelectors.getCreateApplicationLink(state),
    createDashboardLink: ChromeSelectors.getCreateDashboardLink(state),
    canCreateApplicationsForPlan: ChromeSelectors.canCreateApplicationsForPlan(state),
    tooltipLabelForDisabledButton: ChromeSelectors.getTooltipLabelForDisabledButton(state),
  }),
  dispatch => ({
    openFeatureGatingModal: () => {
      trackLinkClick('sp-app-chrome-dashboards-attemptedToCreateDashboardButton');
      dispatch(openModal(DashboardsGatedFeatureModal));
    }
  }),
)(UnconnectedCreate);
