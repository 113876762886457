/** @format **/

import createActionFactory, { ActionCreator } from 'typescript-fsa';
import { ReducerBuilder } from 'typescript-fsa-reducers';
import { mapValues, merge, get } from 'lodash';
import { Action } from 'redux-first-router';

import { reducerUpdate } from 'utils/reducerUpdate';
import { ReactComponent } from 'utils/types';

import { ScreenState } from 'app/reducers';

import { RoutePreEntryCallback, RoutePreEntryCallbackChain } from './entryCallbacks';
export * from './entryCallbacks';
export * from './anonymous';

export const ROUTE_ACTION_BASE = 'routing';

export const createRoutingAction = createActionFactory(ROUTE_ACTION_BASE);

type MetaData = { query: { [key: string]: any } };

type MakeRoutingData =
  | [ActionCreator<any>, ReactComponent<{}>]
  | [ActionCreator<any>, ReactComponent<{}>, MetaData];

export type QueryPayload = { [key: string]: any };

export function makeRoutingReducer(actions: MakeRoutingData[], layout: ReactComponent<{}> = null) {
  return function buildRoutingReducer(reducerBuilder: ReducerBuilder<ScreenState, ScreenState>) {
    return actions.reduce(
      (builder, [action, component, ...meta]) =>
        builder.case(action, state => {
          return reducerUpdate(state, {
            type: action.type,
            component,
            meta: merge.apply(undefined, meta),
            layout,
          });
        }),
      reducerBuilder,
    );
  };
}

export function makeRouteMap(
  routeMap: {
    [action: string]: string | { path: string; beforeEntryCallback: RoutePreEntryCallback };
  },
  basePath: string = '',
): {
  [action: string]: { path: string; beforeEntryCallback?: RoutePreEntryCallbackChain<any, any> };
} {
  return mapValues(routeMap, mapping => {
    if (typeof mapping === 'string') {
      return { path: `${basePath}${mapping}` };
    }

    return {
      ...mapping,
      path: `${basePath}${mapping.path}`,
    };
  }) as any;
}

export function isRedirect(action: Action) {
  return get(action, 'meta.location.kind', '') === 'redirect';
}
