/**
 * @prettier
 */
import * as React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import { ErrorBoundary } from 'components/errorBoundary';

export const Container: React.FunctionComponent<{}> = ({ children }) => (
  <ErrorBoundary>
    <div className="dashboard-graph">{children}</div>
  </ErrorBoundary>
);

type SharedProps = {
  positionInside?: boolean;
};

type GraphProps = {
  hasLegend?: boolean;
} & SharedProps;

export const Graph: React.FunctionComponent<GraphProps> = ({
  children,
  hasLegend,
  positionInside,
}) => {
  const classes = cx({
    'dashboard-graph__chart': positionInside,
    'dashboard-graph__chart--legend': hasLegend && positionInside,
  });

  return <div className={classes}>{children}</div>;
};

Graph.defaultProps = {
  hasLegend: true,
};

export type LegendItem = {
  colorCode?: string;
  blockLabel?: boolean;
  label?: string;
  hideIcon?: boolean;
  onClick?: () => void;
};

type LegendProps = {
  blockLabel?: boolean;
  lowerLegendLabel?: string;
  higherLegendLabel?: string;
  legendItems: LegendItem[];
} & SharedProps;

export const Legend: React.FunctionComponent<LegendProps> = ({
  blockLabel,
  lowerLegendLabel,
  higherLegendLabel,
  legendItems,
  positionInside,
}) => {
  const legends = legendItems.map(({ blockLabel, colorCode, label, onClick, hideIcon }) => {
    // Unicode representation of &nbsp, required to get React to render a non-breaking space
    const space = blockLabel ? '\u00a0' : null;

    return (
      <li
        key={colorCode}
        onClick={onClick}
        className={hideIcon ? 'no-tag' : ''}
        style={{ marginRight: '16px' }}
      >
        {!hideIcon && <span className="chart-tag" style={{ background: colorCode }} />}
        {isNil(onClick) && label}
        {!isNil(onClick) && <a className="clickable">{label}</a>}
        {space}
      </li>
    );
  });

  const graphClasses = cx({
    'dashboard-graph__legend': positionInside,
  });

  if (blockLabel) {
    return (
      <div className={`${graphClasses} text-center`}>
        <div className="chart-tags chart-tags--small-spacing">
          <ul className="clearfix">{legends}</ul>
          <div className="chart-tags__block-labels">
            <span className="float-left">{lowerLegendLabel}</span>
            <span className="float-right">{higherLegendLabel}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${graphClasses} text-center`}>
      <ul className="chart-tags clearfix">{legends}</ul>
    </div>
  );
};
