/** @format */

// Constants used for error messages

// Our defined errors
const SERVER_FAILURE = "Status code '500' received from fetch call";
const WRONG_PATH_404 = "Status code '404' received from fetch call";
const ALREADY_EXISTS = "Status code '400' received from fetch call";
const UNAUTHORIZED = "Status code '401' or '403' received from fetch call";
const PAYMENT_REQUIRED = "Status code '402' received from fetch call";
const CONFLICT = "Status code '409' received from network call";

// Browser thrown
const FAILED_TO_FETCH = 'Failed to fetch';

export const ERRORS = {
  SERVER_FAILURE,
  WRONG_PATH_404,
  FAILED_TO_FETCH,
  ALREADY_EXISTS,
  UNAUTHORIZED,
  PAYMENT_REQUIRED,
  CONFLICT,
};
