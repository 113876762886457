/** @format */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Filter as FilterModel, Dimension } from '../models';
import { ActiveFilter } from './activeFilter';
import { AddFilter } from '../addFilter';
import { ToplevelFiltersState } from '../state';
import * as Selectors from '../selectors';
import { connect } from 'react-redux';
import * as Models from '../models';

type DispatchProps = {};
type StateProps = {
  filterIds: string[];
  addFilterText: string;
};
type SuppliedProps = {
  transformValue?: (value: Models.FilterValue, dimension: Dimension) => Models.FilterValue;
  checkFilterListWrapping: (listElement: Element, listWrapperElement: Element) => void;
  handleAdvancedSearch: (query: string) => void;
  supportAdvancedSearch: boolean;
  showNoResultsText: boolean;
};
type Props = DispatchProps & StateProps & SuppliedProps;

type State = {};

class UnconnectedActiveFilters extends React.Component<Props, State> {
  listWrapperElement: Element;
  listElement: Element;

  constructor(props: Props) {
    super(props);
    this.handleAdvancedSearch = this.handleAdvancedSearch.bind(this);
  }

  state: State = {};

  componentDidMount() {
    this.props.checkFilterListWrapping(this.listElement, this.listWrapperElement);
  }

  componentDidUpdate(prevProps: Props) {
    // If the number of filters has changed, check whether the elements are wrapping based on the list and list wrapper elements
    if (prevProps.filterIds.length !== this.props.filterIds.length) {
      this.props.checkFilterListWrapping(this.listElement, this.listWrapperElement);
    }
  }

  handleAdvancedSearch(_query: string) {
    this.props.handleAdvancedSearch(_query);
  }

  render() {
    const filters = this.props.filterIds.map((id: string) => (
      <li key={id} className="active-filter-list__item">
        <ActiveFilter id={id} enableEditing transformValue={this.props.transformValue} />
      </li>
    ));

    return (
      <div
        className="active-filter-list-wrapper"
        ref={(el: Element) => (this.listWrapperElement = el)}
      >
        <ul className="active-filter-list" ref={(el: Element) => (this.listElement = el)}>
          {filters}
          <li className="active-filter-list__item">
            <AddFilter
              addFilterButtonText={this.props.addFilterText}
              transformValue={this.props.transformValue}
              supportAdvancedSearch={this.props.supportAdvancedSearch}
              handleAdvancedSearch={this.handleAdvancedSearch}
              showNoResultsText={this.props.showNoResultsText}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export const ActiveFilters = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: ToplevelFiltersState) => ({
    filterIds: Selectors.getFilters(state).map(filter => filter.id),
    addFilterText: Selectors.getExternalConfiguration(state).addFilterText,
  }),
)(UnconnectedActiveFilters);
