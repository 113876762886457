/** @format */

import * as React from 'react';
import { assign } from 'lodash';

import { CellBase } from '../tableModel';
import { RadioButton } from 'components/radioButton';

export class RadioButtonCell extends CellBase {
  onToggled: (rowItem: any, isChecked: boolean) => void;

  className: string;

  constructor(init?: Partial<RadioButtonCell>) {
    super(init);
    assign(this, init);
  }

  render(rowItem: any) {
    const value = this.getValue(rowItem) as boolean;

    const handleToggled = (isChecked: boolean) => {
      this.onToggled(rowItem, isChecked);
    };

    return (
      <RadioButton
        onToggled={handleToggled}
        isChecked={value}
        noPadding
        className={this.className}
      />
    );
  }
}

export default RadioButtonCell;
