/** @format */
import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import { hasMultiplePlans } from 'app/selectors/configuration';

type StateProps = {
  hasMultiplePlans: boolean;
};

type DispatchProps = {};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedHasMultiplePlans: React.FunctionComponent<Props> = ({ hasMultiplePlans, children }) => hasMultiplePlans ? (
  <>
    {children}
  </>
) : null;

export const UnconnectedHasSinglePlan: React.FunctionComponent<Props> = ({ hasMultiplePlans, children }) => hasMultiplePlans ? null : (
  <>
    {children}
  </>
);

const Connection = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => ({
    hasMultiplePlans: hasMultiplePlans(state)
  })
);

const ConnectedHasMultiplePlans = Connection(UnconnectedHasMultiplePlans);

const ConnectedHasSinglePlan = Connection(UnconnectedHasSinglePlan);

export {
  ConnectedHasMultiplePlans as HasMultiplePlans,
  ConnectedHasSinglePlan as HasSinglePlan
};
