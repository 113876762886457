/** @format **/
import createActionFactory from 'typescript-fsa';

const createAction = createActionFactory('app/chrome/sidebar');

export const setAccountMenu = createAction<boolean>('SET_ACCOUNT_MENU');

export const setSlideoutMenu = createAction<boolean>('SET_SLIDEOUT_MENU');

export const enableSecretMode = createAction('ENABLE_SECRET_MODE');
