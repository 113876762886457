/**
 * @prettier
 */

import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import classnames from 'classnames';
import { isNil, mapValues, pick } from 'lodash';

import { uuid } from 'utils/generators';

import { SortDirection } from 'constants/sorting';

import { ErrorBoundary } from 'components/errorBoundary';

import { ExpandedRows, GroupedTableProps } from './groupedTable/models';
import { getGroupedTableRows } from './groupedTable/utils';

import TableHeader from './tableHeader';
import { TableRow } from './tableRow';
import { TableNoData } from './tableNoData';
import { Column, SortOptions, DefaultTableProps } from './tableModel';

export type TableProps = DefaultTableProps & {
  sortField?: string;
  sortDirection?: SortDirection;
  onSortChanged?: (sortField: string, sortDirection: SortDirection) => void;
  noBorderOnLastRow?: boolean;
  isGroupedTable?: boolean;
  groupedTableProps?: GroupedTableProps;
};

const Table: React.FunctionComponent<TableProps> = props => {
  const [expandedRows, toggleExpandedRows]: [
    ExpandedRows,
    Dispatch<SetStateAction<ExpandedRows>>,
  ] = useState<ExpandedRows>({});
  const { data = [] } = props;
  const {
    showAllGroupedData,
    toggleAllGroupedData,
    groupedData,
    discColorMap,
    groupOnField,
    groupIdFunc,
  } = props.groupedTableProps;

  const simpleTableProps = {
    ...pick(props, ['columns', 'gutters']),
    className: props.rowClassName,
  };

  const getSimpleTableRows = () => {
    return data.map((item: any, index: number) => {
      const fieldValue = item[groupOnField];
      const groupId = !isNil(groupIdFunc) ? groupIdFunc(fieldValue) : null;

      return (
        <TableRow
          {...simpleTableProps}
          discColorMap={discColorMap}
          groupId={groupId}
          key={uuid()}
          padding={props.padding}
          item={item}
          props={props.rowProps[index]}
        />
      );
    });
  };

  const rows =
    props.isGroupedTable && !isNil(groupedData)
      ? getGroupedTableRows(props, expandedRows, toggleExpandedRows)
      : getSimpleTableRows();

  let header = null;

  if (!props.isHeaderHidden) {
    header = (
      <TableHeader
        columns={props.columns}
        sortField={props.sortField}
        sortDirection={props.sortDirection}
        onSortChanged={props.onSortChanged}
        gutters={props.gutters}
        isGroupedTable={props.isGroupedTable}
        showAllGroupedData={showAllGroupedData}
        toggleAllGroupedData={() => {
          toggleAllGroupedData(!showAllGroupedData);
          toggleExpandedRows(mapValues(expandedRows, () => !showAllGroupedData));
        }}
      />
    );
  }

  const className = classnames('table--standard table--fixed table--responsive', {
    'table--no-border-last-row': props.noBorderOnLastRow,
  });

  return (
    <ErrorBoundary>
      <table className={className}>
        {header}
        <tbody>
          {rows.length > 0
            ? rows
            : renderNoData(props.columns.length, props.gutters, props.noDataMessage)}
        </tbody>
      </table>
    </ErrorBoundary>
  );
};

Table.defaultProps = {
  padding: 'standard',
  gutters: false,
  data: [],
  noDataMessage: undefined,
  rowProps: {},
  groupedTableProps: {
    showAllGroupedData: true,
    groupCountField: 'count',
    groupedData: null,
  },
};

const renderNoData = (cols: number, gutters: boolean, message?: string): React.ReactNode => {
  const totalCols = cols + (gutters ? 2 : 0);
  return <TableNoData noDataMessage={message} cols={totalCols} />;
};

export default Table;

export { Table, Column, SortOptions, SortDirection };
