/** @format */

import { isNaN } from 'lodash';
import * as charting from 'utils/echarts';

export function sessionPieceBucketing(sessionMin: number, sessionMax: number) {
  // If you only have data for a single country, show that as the most sessions bucket
  // instead of using the least sessions bucket
  if (sessionMin === sessionMax) {
    return [{ min: -999999, max: sessionMax, color: charting.distributionColor1 }];
  }

  const sessionBucketSize = (sessionMax - sessionMin) / 10;

  const bucketOne = sessionMin + sessionBucketSize;
  const bucketTwo = bucketOne + sessionBucketSize;
  const bucketThree = bucketTwo + sessionBucketSize;
  const bucketFour = bucketThree + sessionBucketSize;
  const bucketFive = bucketFour + sessionBucketSize;
  const bucketSix = bucketFive + sessionBucketSize;
  const bucketSeven = bucketSix + sessionBucketSize;
  const bucketEight = bucketSeven + sessionBucketSize;
  const bucketNine = bucketEight + sessionBucketSize;

  return [
    { min: -999999, max: bucketOne, color: charting.distributionColor10 },
    { min: bucketOne, max: bucketTwo, color: charting.distributionColor9 },
    { min: bucketTwo, max: bucketThree, color: charting.distributionColor8 },
    { min: bucketThree, max: bucketFour, color: charting.distributionColor7 },
    { min: bucketFour, max: bucketFive, color: charting.distributionColor6 },
    { min: bucketFive, max: bucketSix, color: charting.distributionColor5 },
    { min: bucketSix, max: bucketSeven, color: charting.distributionColor4 },
    { min: bucketSeven, max: bucketEight, color: charting.distributionColor3 },
    { min: bucketEight, max: bucketNine, color: charting.distributionColor2 },
    { min: bucketNine, max: 9999999999, color: charting.distributionColor1 },
  ];
}

export function timePieceBucketing() {
  return [
    { min: -999999, max: 300, color: charting.distributionColor1 },
    { min: 300, max: 600, color: charting.distributionColor2 },
    { min: 600, max: 1000, color: charting.distributionColor3 },
    { min: 1000, max: 1500, color: charting.distributionColor4 },
    { min: 1500, max: 2000, color: charting.distributionColor5 },
    { min: 2000, max: 3000, color: charting.distributionColor6 },
    { min: 3000, max: 4000, color: charting.distributionColor7 },
    { min: 4000, max: 5000, color: charting.distributionColor8 },
    { min: 5000, max: 6000, color: charting.distributionColor9 },
    { min: 6000, max: 9999999, color: charting.distributionColor10 },
  ];
}

export function sessionToolTipColorFunction(val: number, color: string) {
  if (isNaN(val) || val === 0) {
    return '#b9b9b9';
  } else {
    return color;
  }
}

export function timeToolTipColorFunction(val: number) {
  switch (true) {
    case val < 300:
      return charting.distributionColor1;
    case val >= 300 && val < 600:
      return charting.distributionColor2;
    case val >= 600 && val < 1000:
      return charting.distributionColor3;
    case val >= 1000 && val < 1500:
      return charting.distributionColor4;
    case val >= 1500 && val < 2000:
      return charting.distributionColor5;
    case val >= 2000 && val < 3000:
      return charting.distributionColor6;
    case val >= 3000 && val < 4000:
      return charting.distributionColor7;
    case val >= 4000 && val < 5000:
      return charting.distributionColor8;
    case val >= 5000 && val < 6000:
      return charting.distributionColor9;
    case val >= 6000:
      return charting.distributionColor10;
  }
}
