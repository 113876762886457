/**
 * @prettier
 */

import React from 'react';

import { ASCENDING, DESCENDING, SortDirection } from 'constants/sorting';

import { Row, Column } from 'components/grid';
import { FormLayout, FormLayoutLabel, FormLayoutInput } from 'components/input/layout';
import { Checkbox } from 'components/checkbox';
import { Select, OPTION } from 'components/select';

type Props = {
  sortFieldOptions: OPTION[];
  tableSortingField: string;
  tableSortingDirection: SortDirection;
  updateSortingField: (field: string) => void;
  handleSortDirectionChange: (sortDirection: SortDirection) => void;
};

export const TableSorting: React.FunctionComponent<Props> = (props: Props) => {
  const ascendingChecked = props.tableSortingDirection === ASCENDING;

  return (
    <Row collapseOnAll>
      <Column>
        <Row>
          <Column medium={6}>
            <FormLayout marginBottom>
              <FormLayoutLabel>
                <label htmlFor="default-sort-field">Default Sort Field</label>
              </FormLayoutLabel>
              <FormLayoutInput>
                <Select
                  id="default-sort-field"
                  options={props.sortFieldOptions}
                  value={props.tableSortingField}
                  onChange={field => props.updateSortingField(field)}
                />
              </FormLayoutInput>
            </FormLayout>
          </Column>
          <Column medium={6} isLast>
            <FormLayout marginBottom>
              <FormLayoutLabel>
                <label>Default Sort Direction</label>
              </FormLayoutLabel>
              <FormLayoutInput>
                <ul className="form-list">
                  <li>
                    <Checkbox
                      isRadioButton
                      onToggled={() => props.handleSortDirectionChange(ASCENDING)}
                      isChecked={ascendingChecked}
                    >
                      Ascending
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox
                      isRadioButton
                      onToggled={() => props.handleSortDirectionChange(DESCENDING)}
                      isChecked={!ascendingChecked}
                    >
                      Descending
                    </Checkbox>
                  </li>
                </ul>
              </FormLayoutInput>
            </FormLayout>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};
