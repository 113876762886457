/**
 * @prettier
 */

import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { has, get, findIndex, isNil } from 'lodash';

import { nestedReducerUpdate } from 'utils/reducerUpdate';

import * as Actions from '../actions';
import * as Models from '../models';
import * as Selectors from '../selectors';
import { DashboardDataState } from '../state';

export const defaultState: DashboardDataState = {
  tileData: {},
  errorsPerAppDrilldownData: {},
  selectedErrorGroupsData: {},
  tileState: {},
};

export const DashboardDataReducer = reducerWithInitialState<DashboardDataState>(defaultState)
  .case(Actions.fetchTileDataStarted, (state, { instanceId }) => {
    if (has(state.tileData, instanceId)) {
      return nestedReducerUpdate(
        state,
        'tileData',
        nestedReducerUpdate(state.tileData, instanceId, { loading: true, error: null }),
      );
    }

    return nestedReducerUpdate(
      state,
      'tileData',
      nestedReducerUpdate(state.tileData, instanceId, { loading: true, error: null, data: {} }),
    );
  })
  .case(Actions.fetchTileDataFailed, (state, { instanceId, error }) =>
    nestedReducerUpdate(
      state,
      'tileData',
      nestedReducerUpdate(state.tileData, instanceId, { loading: false, error }),
    ),
  )
  .case(Actions.fetchTileDataSucceeded, (state, { instanceId, payload }) =>
    nestedReducerUpdate(
      state,
      'tileData',
      nestedReducerUpdate(state.tileData, instanceId, { data: payload, loading: false }),
    ),
  )
  .case(Actions.fetchDrilldownData.start, (state, payload) => {
    if (has(state.errorsPerAppDrilldownData, payload.appId)) {
      return nestedReducerUpdate(
        state,
        'errorsPerAppDrilldownData',
        nestedReducerUpdate(state.errorsPerAppDrilldownData, payload.appId, {
          loading: true,
          error: null,
        }),
      );
    }

    return nestedReducerUpdate(
      state,
      'errorsPerAppDrilldownData',
      nestedReducerUpdate(state.errorsPerAppDrilldownData, payload.appId, {
        loading: true,
        error: null,
        data: null,
      }),
    );
  })
  .case(Actions.fetchDrilldownData.error, (state, payload) =>
    nestedReducerUpdate(
      state,
      'errorsPerAppDrilldownData',
      nestedReducerUpdate(state.errorsPerAppDrilldownData, payload.appId, {
        loading: false,
        error: payload.error,
      }),
    ),
  )
  .case(Actions.fetchDrilldownData.done, (state, payload) =>
    nestedReducerUpdate(
      state,
      'errorsPerAppDrilldownData',
      nestedReducerUpdate(state.errorsPerAppDrilldownData, payload.appId, {
        loading: false,
        error: null,
        data: payload.data,
      }),
    ),
  )
  .case(Actions.toggleSelectErrorGroup, (state, payload) => {
    const errorGroups = get(
      state.selectedErrorGroupsData,
      payload.tileId,
      [],
    ) as Models.SelectedErrorGroup[];
    const index = findIndex(errorGroups, t => t.errorGroupId === payload.errorGroupId);

    if (index > -1) {
      errorGroups.splice(index, 1);
    } else {
      errorGroups.push({
        errorGroupId: payload.errorGroupId,
        applicationId: payload.applicationId,
      });
    }

    return {
      ...state,
      selectedErrorGroupsData: {
        ...state.selectedErrorGroupsData,
        [payload.tileId]: errorGroups,
      },
    };
  })
  .case(Actions.toggleSelectAllErrorGroups, (state, payload) => {
    let errorGroups: Models.SelectedErrorGroup[];
    const isAllSelected = Selectors.allErrorGroupsSelectedFactory(
      payload.tileId,
      payload.isDrilldown,
      payload.applicationId,
    ).resultFunc(
      state.selectedErrorGroupsData,
      payload.isDrilldown ? state.errorsPerAppDrilldownData : state.tileData,
    );

    if (isAllSelected) {
      errorGroups = [];
    } else {
      const selector = payload.isDrilldown ? Selectors.getDrilldownData : Selectors.getTileData;
      const id = payload.isDrilldown ? payload.applicationId : payload.tileId;
      const response = get(
        selector.resultFunc(state)[id],
        `data`,
        [],
      ) as Actions.ErrorGroupsPayload;

      const tileData = response.Data;

      errorGroups = (tileData as Actions.ErrorGroupListData[]).map((t: { Id: number; ApplicationId: number; }) => ({
        errorGroupId: t.Id,
        applicationId: t.ApplicationId,
      }));
    }

    if (payload.isDrilldown) {
      // Include applications which are in the current application
      const currentErrors = get(
        state.selectedErrorGroupsData,
        payload.tileId,
        [],
      ) as Models.SelectedErrorGroup[];
      errorGroups = [
        ...errorGroups,
        ...currentErrors.filter(e => e.applicationId !== payload.applicationId),
      ];
    }

    return {
      ...state,
      selectedErrorGroupsData: {
        ...state.selectedErrorGroupsData,
        [payload.tileId]: errorGroups,
      },
    };
  })
  .case(Actions.deselectAllErrorGroups, (state, tileId) => {
    return {
      ...state,
      selectedErrorGroupsData: {
        ...state.selectedErrorGroupsData,
        [tileId]: [],
      },
    };
  })
  .case(Actions.updateTileState, (state, payload) => {
    const existingTileState = state.tileState[payload.tileId];
    const clonedPayload = { ...payload };

    if (!isNil(existingTileState)) {
      // If the state update is modifying the drilldown state
      // And it's not the same as the previous drilldown state
      // Reset the sorting, this ensures that the drilldown tables don't clash over the sorting
      // It's not the best fix (a better one would be giving separate state to the drilldown tile
      // But that is a lot more complicated
      if (
        !isNil(payload.tileState.isDrilledDown) &&
        existingTileState.isDrilledDown !== clonedPayload.tileState.isDrilledDown
      ) {
        clonedPayload.tileState.sorting = undefined;
      }
    }

    return {
      ...state,
      tileState: {
        ...state.tileState,
        [payload.tileId]: {
          ...state.tileState[payload.tileId],
          ...payload.tileState,
        },
      },
    };
  });
