/** @format */

import * as React from 'react';
import classnames from 'classnames';

interface Props {
  onlyBorderBetween?: boolean;
  type?: 'white' | 'green' | 'transparent' | 'grey';
  className?: string;
}

export const Item: React.FunctionComponent<Props> = props => {
  const classNames = classnames(
    'active-list-row',
    {
      'active-list-row--border-between-only': props.onlyBorderBetween,
      'active-list-row--green': props.type === 'green',
      'active-list-row--white': props.type === 'white',
      'active-list-row--transparent': props.type === 'transparent',
    },
    props.className,
  );

  return <li className={classNames}>{props.children}</li>;
};

Item.defaultProps = {
  onlyBorderBetween: false,
};

export default Item;
