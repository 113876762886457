/**
 * @prettier
 */
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { applyModifiers } from "fela-rules/modifiers";
import { Theme } from 'app/theme';

type Props = {
  big?: boolean;
  clickable?: boolean;
  forDropdownTrigger?: boolean;
};

const StyledIconContainer = ({ big, clickable, forDropdownTrigger } : Props): IStyle => applyModifiers(
  [clickable, {
    cursor: 'pointer'
  }],
  [!big, {
    width: '32px',
    height: '32px'
  }],
  [forDropdownTrigger, {
    width: '64px',
    cursor: 'pointer',
    userSelect: 'none'
  }],
  [forDropdownTrigger && !big, {
    width: '56px'
  }],
)({
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: 'none'
});

export const TextIconContainer = createComponentWithProxy<Props>(StyledIconContainer, 'div');

TextIconContainer.defaultProps = {
  big: true,
  clickable: false,
  forDropdownTrigger: false,
};

type IconTextProps = {
  backgroundColor: string;
  big: boolean;
};

const StyledIconText = ({ backgroundColor, big }: IconTextProps): IStyle => applyModifiers(
  [!big, {
    width: '32px',
    height: '32px',
    lineHeight: '32px',
  }],
)({
  display: 'block',
  width: '40px',
  height: '40px',
  textAlign: 'center',
  lineHeight: '40px',
  borderRadius: '2px',
  color: Theme.ds.getColorByName('white'),
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor,
});

export const TextIconText = createComponentWithProxy<IconTextProps>(StyledIconText, 'span');
