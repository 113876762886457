/**
 * @prettier
 */

import * as React from 'react';
import { connect } from 'react-redux';

import { DESCENDING } from 'constants/sorting';
import { formatMillisecondsAsMultiPartDuration } from 'utils/durationFormatting';

import { Column, SortedTable, Table } from 'components/table';
import { NumberCell } from 'components/table/cells';
import Text from 'components/text';
import { Padding } from 'components/layout';

import { CELL_TYPES, TypeCell } from 'apm/components/typeCell';

import { DashboardModuleStateAtom } from '../../../state';
import { SlowestQueryPayload } from '../../../actions';

import { SortingHoCProps, SortingHoC } from '../../';

import { LoadingSlowestQueries } from '../';
import { Props as TileDataProviderProps, TileDataProvider } from '../dataProvider';
import { getApplicationsById } from 'app/selectors/application';

type MessageCellSuppliedProps = SlowestQueryPayload;
type MessageCellStateProps = { applicationName: string };
type MessageCellProps = MessageCellStateProps & MessageCellSuppliedProps;
const UnconnectedMessageCell: React.FunctionComponent<MessageCellProps> = props => {
  const link = `/apm/${props.applicationId.toString(36)}/discover/queries/${props.identifier}`;

  return (
    <>
      <Text size="medium" ellipsis>
        <a href={link} title={props.name}>
          {props.name}
        </a>
      </Text>
      <Padding top="4">
        <Text size="small" color="grey">
          {props.applicationName}
        </Text>
      </Padding>
    </>
  );
};
const MessageCell = connect<MessageCellStateProps, {}, MessageCellSuppliedProps>(
  (state: DashboardModuleStateAtom, ownProps: MessageCellSuppliedProps) => ({
    applicationName: getApplicationsById(state)[ownProps.applicationId].name,
  }),
)(UnconnectedMessageCell);

type Props = TileDataProviderProps & SortingHoCProps;
const SlowestQueries: React.FunctionComponent<Props> = props => {
  const columns: Column[] = [
    {
      header: 'Type',
      width: 80,
      sortOptions: { sortField: 'type', defaultSortDirection: DESCENDING },
      cell: new TypeCell({
        accessor: 'type',
        type: CELL_TYPES.QUERY,
      }),
      spaceLeft: true,
    },
    {
      header: 'Query name',
      sortOptions: { sortField: 'name', defaultSortDirection: DESCENDING },
      cell: MessageCell,
    },
    {
      header: 'Average duration',
      width: 160,
      sortOptions: { sortField: 'averageDuration', defaultSortDirection: DESCENDING },
      cell: new NumberCell({
        accessor: rowItem => Math.round(rowItem.averageDuration / 1000),
        formatter: formatMillisecondsAsMultiPartDuration,
        title: rowItem =>
          `Hit count: ${rowItem.count}\nTotal duration: ${formatMillisecondsAsMultiPartDuration(
            rowItem.totalDuration / 1000,
          )}`,
      }),
    },
    {
      header: 'Total duration',
      width: 160,
      sortOptions: { sortField: 'totalDuration', defaultSortDirection: DESCENDING },
      cell: new NumberCell({
        accessor: rowItem => Math.round(rowItem.totalDuration / 1000),
        formatter: formatMillisecondsAsMultiPartDuration,
        title: rowItem =>
          `Hit count: ${rowItem.count}\nAverage duration: ${formatMillisecondsAsMultiPartDuration(
            rowItem.averageDuration / 1000,
          )}`,
      }),
    },
  ];

  return (
    <SortedTable
      data={props.data as any}
      columns={columns}
      onSortChange={props.updateSorting}
      sortField={props.sortField}
      sortDirection={props.sortDirection}
      noBorderOnLastRow
    />
  );
};

const ConnectedSlowestQueries = TileDataProvider(SortingHoC(SlowestQueries), LoadingSlowestQueries);
ConnectedSlowestQueries.moduleProps = {
  noPadding: true,
};

export { ConnectedSlowestQueries as SlowestQueries };
