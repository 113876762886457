/** @format */

import * as React from 'react';
import { Column, Table } from 'components/table';
import { SkeletonCell, SkeletonTableData } from 'components/table/cells';

export const LoadingTopActiveIssues: React.FunctionComponent<{}> = () => {
  const columns: Column[] = [
    {
      header: 'Type',
      width: 80,
      cell: new SkeletonCell(),
    },
    {
      header: 'Issue name',
      cell: new SkeletonCell(),
    },
    {
      header: 'Count',
      width: 60,
      cell: new SkeletonCell(),
    },
  ];

  const data = SkeletonTableData.map(item => ({ ...item, Type: 'method' }));

  return <Table columns={columns} data={data} />;
};
