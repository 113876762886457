/** @format **/

import * as React from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';

import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { Avatar } from 'components/avatar';
import { Padding } from 'components/layout';
import { SnowplowLink } from 'components/snowplow';
import { addE2EIdentifier } from 'utils/end-to-end';
import { Medium } from 'components/breakpoints';

export type SwitcherProps = {
  title: string;
  open: boolean;
  enableTitle?: boolean;
  avatar?: {
    url: string;
    alt: string;
  };
};

export const Switcher: React.FunctionComponent<SwitcherProps> = ({
  title,
  open,
  enableTitle,
  avatar,
}) => {
  const classes = classNames('header-switcher-button', {
    'header-switcher-button__active header-switcher-button--open': open,
  });
  const icon = title === 'Dashboards' ? 'dashboard-switcher' : 'app-switcher';
  const iconClasses = classNames('header-switcher-button-icon', {
    'header-switcher-button-icon--open': open,
  });

  return (
    <SnowplowLink
      interactionId={`sp-app-chrome-switcher-click${
        title === 'Dashboards' ? 'Dashboards' : 'Application'
      }Dropdown`}
    >
      <a
        id={addE2EIdentifier('e2e-app-switcher-toggle')}
        className={classes}
        title={enableTitle ? title : null}
      >
        <Icon
          extraClasses={'header-switcher-button__icon-mobile'}
          size={16}
          set={'icomoon'}
          type={icon}
          center
        />
        <div className={'hide-mob'}>
          <Flex>
            {!isNil(avatar) && (
              <Medium andAbove>
                <Padding right={16}>
                  <Avatar url={avatar.url} alt={avatar.alt} size={24} border={false} />
                </Padding>
              </Medium>
            )}
            <div className="header-switcher-button__text">{title}</div>
          </Flex>

          <div className="header-switcher-button__icon-after">
            <Icon extraClasses={iconClasses} size={16} type="dropdown" />
          </div>
        </div>
      </a>
    </SnowplowLink>
  );
};
