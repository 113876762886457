/** @format */

import * as React from 'react';
import classnames from 'classnames';

import Link from 'redux-first-router-link';

import BreadcrumbModel from './model';
import { Icon } from 'components/icon';

interface Props {
  isLast?: boolean;
  heading?: boolean;
  model: BreadcrumbModel;
}

const renderLink = (props: Props): React.ReactNode => {
  const classNames = classnames({
    'breadcrumb-item__content': !props.heading,
    'breadcrumb-heading-item__content': props.heading,
  });

  if (props.model.link || props.model.onClick) {
    if (props.model.external || props.model.onClick) {
      return (
        // If it's an external link, it will be a string
        // Still Bad™
        <a
          className={classNames}
          href={props.model.link as string}
          onClick={() => props.model.onClick(props.model)}
        >
          {props.model.text}
        </a>
      );
    } else {
      return (
        <Link className={classNames} to={props.model.link}>
          {props.model.text}
        </Link>
      );
    }
  } else {
    return <span className={classNames}>{props.model.text}</span>;
  }
};

const renderArrow = (props: Props): React.ReactNode => {
  if (props.isLast) {
    return null;
  }

  const classes = classnames({
    'breadcrumb-item__arrow': !props.heading,
    'breadcrumb-heading-item__arrow': props.heading,
  });

  return (
    <span className={classes}>
      <Icon type="arrowright" size={16} />
    </span>
  );
};

const BreadcrumbItem: React.FunctionComponent<Props> = props => {
  const classNames = classnames({
    'breadcrumb-item': !props.heading,
    'breadcrumb-item--arrow': !props.heading && props.isLast === false,
    'breadcrumb-item--clip': !props.heading && props.model.clip,
    'breadcrumb-heading-item': props.heading,
    'breadcrumb-heading-item--arrow': props.heading && props.isLast === false,
    'breadcrumb-heading-item--clip': props.heading && props.model.clip,
  });

  return (
    <div className={classNames}>
      {renderLink(props)}
      {renderArrow(props)}
    </div>
  );
};

export default BreadcrumbItem;
