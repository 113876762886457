/**
 * @Format
**/
import React from 'react';
import { EmphasisButton } from "components/ds/button/standard";
import { getTextAlignmentClass, HEADING_CLASSES } from "components/text";
import { Padding } from "components/layout";
import { SupportLink } from 'components/supportLink';

export const Title: React.FunctionComponent<{}> = ({ children }) => (
  <Padding bottom="18" top="32">
    <h3 className={HEADING_CLASSES.TWO}>{children}</h3>
  </Padding>
);

export const SubTitle: React.FunctionComponent<{}> = ({ children }) => (
  <Padding bottom="16">
      <p>{children}</p>
  </Padding>
);

type Props = {
  title?: string;
  subTitle: string;
  buttonText?: string;
  buttonUrl: string;
  image?: string;
};

export const UpgradePaywall: React.FunctionComponent<Props> = (props) => (
  <Padding top="104" bottom="104">
    <div className={getTextAlignmentClass('center')}>
      <img
        src="/Content/Images/illustrations/paywall/no-access.svg"
        alt="Raygun Rocket"
        className="center"
        width="269"
        height="165"
      />
      <Title>{props.title}</Title>
      <SubTitle>{props.subTitle}</SubTitle>
    </div>

    <div className={getTextAlignmentClass('center')}>
      <Padding top="12">
        <a href={props.buttonUrl}>
          <EmphasisButton>
            {props.buttonText}
          </EmphasisButton>
        </a>
      </Padding>
    </div>
  </Padding>
);

UpgradePaywall.defaultProps = {
  title: 'Upgrade to unlock this feature.',
  buttonText: 'Contact us'
};
