/**
 * @prettier
 */

import { SagaIterator } from 'redux-saga';
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { Action } from 'typescript-fsa';
import { isNil, get } from 'lodash';

import { AppState } from 'interfaces/appState';
import { createFetchSaga } from 'utils/sagas';
import { externalRequestFactory } from 'utils/fetching/utils';

import * as Actions from './actions';
import { NetworkWhatsNewItem, WhatsNewBanner } from './models';

const BlogUrl = get(get(window, 'RaygunConfiguration', {}), 'BlogUrl', 'https://raygun.com/blog');
const externalRequest = externalRequestFactory(BlogUrl, 'GET');
const LAST_FETCHED_KEY = 'whatsnew-lastfetched';
const WHATS_NEW_ITEMS_KEY = 'whatsnew-items';
const LAST_VIEWED_KEY = 'whatsnew-lastviewed';

const fetchWhatsNewSaga = createFetchSaga<AppState>(
  {
    start: Actions.whatsNewItemFetchingStarted,
    done: Actions.whatsNewItemFetchingSucceeded,
    error: Actions.whatsNewItemFetchingFailed,
  },
  'whatsnew.json',
  {},
  externalRequest,
  { includeBaseName: false },
);
function* fetchWhatsNew() {
  yield call(fetchWhatsNewSaga as any);
}

const fetchWhatsNewBannerSaga = createFetchSaga<AppState>(
  {
    start: Actions.whatsNewBannerFetchingStarted,
    done: Actions.whatsNewBannerFetchingSucceeded,
    error: Actions.whatsNewBannerFetchingFailed,
  },
  'whats-new-banner.json',
  {},
  externalRequest,
  { includeBaseName: false },
);
function* fetchWhatsNewBanner() {
  yield call(fetchWhatsNewBannerSaga as any);
}

function* fetchWhatsNewIfNeeded() {
  try {
    const lastFetchedAtRaw = localStorage.getItem(LAST_FETCHED_KEY);
    const itemsInStorage = localStorage.getItem(WHATS_NEW_ITEMS_KEY);
    // If no value is set, fallback to 2 days ago to ensure we fetch
    const lastFetchedAt = !isNil(lastFetchedAtRaw)
      ? moment(lastFetchedAtRaw)
      : moment().subtract(2, 'days');

    if (isNil(itemsInStorage) || lastFetchedAt.isBefore(moment().subtract(1, 'days'))) {
      yield call(fetchWhatsNew);
    }
  } catch (e) {
    const _ignored = -1;
  }
}

function updateLastFetchedInLocalStorage(action: Action<NetworkWhatsNewItem[]>) {
  try {
    localStorage.setItem(WHATS_NEW_ITEMS_KEY, JSON.stringify(action.payload));
    localStorage.setItem(LAST_FETCHED_KEY, moment().toISOString());
  } catch (e) {
    const _ignored = -1;
  }
}

function updateLastViewedInLocalStorage(action: Action<Date>) {
  try {
    localStorage.setItem(LAST_VIEWED_KEY, moment(action.payload).toISOString());
  } catch (e) {
    const _ignored = -1;
  }
}

function* forwardWhatsNewItemsToStore() {
  while (true) {
    const action: Action<NetworkWhatsNewItem[]> = yield take(
      Actions.whatsNewItemFetchingSucceeded.type,
    );

    yield put(Actions.updateWhatsNewItems(action.payload));
  }
}

function* updateStoreFromLocalStorage() {
  try {
    const whatsNewItemsRaw = localStorage.getItem(WHATS_NEW_ITEMS_KEY);
    const lastViewedDateRaw = localStorage.getItem(LAST_VIEWED_KEY);

    if (!isNil(whatsNewItemsRaw)) {
      const whatNewItems: NetworkWhatsNewItem[] = JSON.parse(whatsNewItemsRaw);

      yield put(Actions.updateWhatsNewItems(whatNewItems));
    }

    const lastViewedDate = !isNil(lastViewedDateRaw)
      ? moment(lastViewedDateRaw).toDate()
      : new Date();

    yield put(Actions.updateLastViewedDate(lastViewedDate));
  } catch (e) {
    const _ignored = -1;
  }
}

function* forwardWhatsNewBannerToStore() {
  while (true) {
    const action: Action<NetworkWhatsNewItem[]> = yield take(
      Actions.whatsNewBannerFetchingSucceeded.type,
    );

    yield put(Actions.updateWhatsNewBanner(action.payload));
  }
}

export function* Saga(): SagaIterator {
  yield takeLatest(Actions.whatsNewItemFetchingSucceeded.type, updateLastFetchedInLocalStorage);
  // Always fetch now, think it's ok with how the caching on the CDN is set up
  yield takeLatest(Actions.fetchWhatsNewItems.type, fetchWhatsNew);
  yield takeLatest(Actions.forceFetchWhatsNewItems.type, fetchWhatsNew);
  yield takeLatest(Actions.updateLastViewedDate.type, updateLastViewedInLocalStorage);
  yield takeLatest(Actions.fetchWhatsNewBanner.type, fetchWhatsNewBanner);

  yield fork(updateStoreFromLocalStorage);
  yield fork(forwardWhatsNewItemsToStore);
  yield fork(forwardWhatsNewBannerToStore);
}
