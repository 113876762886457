/**
 * @prettier
 */

import * as React from 'react';
import classnames from 'classnames';

import { SortOptions, CellBase, CellPadding } from './tableModel';
import { SortDirection, ASCENDING, DESCENDING } from 'constants/sorting';
import { TableCellWidths } from 'constants/widths';

import { isComponent } from 'utils/types';

interface TableHeaderItemProps<T = {}> {
  header: string | JSX.Element;
  cell?: CellBase | React.ComponentType<T>;
  sortOptions?: SortOptions;
  sortDirection?: SortDirection | undefined;
  width?: TableCellWidths | undefined;
  onSortChanged?: (sortField: string, sortDirection: SortDirection) => void;
  padding?: CellPadding;
  flatLeft?: boolean;
  spaceLeft?: boolean;
  headerSpaceRight?: boolean;
}

class TableHeaderItem extends React.Component<TableHeaderItemProps, {}> {
  constructor(props: TableHeaderItemProps) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  static defaultProps: Partial<TableHeaderItemProps> = {
    sortOptions: undefined,
    sortDirection: undefined,
    width: undefined,
  };

  getSortField(): string {
    if (this.props.sortOptions.sortField) {
      return this.props.sortOptions.sortField;
    } else if (
      !isComponent(this.props.cell) &&
      this.props.cell &&
      typeof this.props.cell.accessor === 'string'
    ) {
      return this.props.cell.accessor as string;
    }
    return '';
  }

  getSortDirection(): SortDirection {
    if (this.props.sortDirection === ASCENDING) {
      return DESCENDING;
    } else if (this.props.sortDirection === DESCENDING) {
      return ASCENDING;
    } else if (this.props.sortOptions.defaultSortDirection) {
      return this.props.sortOptions.defaultSortDirection;
    } else {
      return ASCENDING;
    }
  }

  onClick() {
    if (this.props.sortOptions && this.props.onSortChanged) {
      this.props.onSortChanged(this.getSortField(), this.getSortDirection());
    }
  }

  render() {
    const className = classnames('table__cell it-table-header-cell', {
      'table__cell--size-normal': this.props.padding !== 'none',
      [`table__cell--${this.props.width}`]: this.props.width !== undefined,
      'table__cell-control': this.props.sortOptions !== undefined,
      'is-active': this.props.sortDirection !== undefined,
      'table__cell--flat-left': this.props.flatLeft,
      'table__cell--space-left': this.props.spaceLeft,
      'table__cell--space-right': this.props.headerSpaceRight,
    });

    return (
      <th className={className} onClick={this.onClick}>
        {this.renderContent()}
      </th>
    );
  }

  renderContent(): React.ReactNode {
    if (this.props.sortOptions) {
      const className = classnames('flat-icon icon-16 it-sort-icon', {
        'order-inactive-icon': this.props.sortDirection === undefined,
        'arrowup-icon': this.props.sortDirection === ASCENDING,
        'arrowdown-icon': this.props.sortDirection === DESCENDING,
      });

      return (
        <span className="table__sort-text">
          {this.props.header}
          <span className={className} />
        </span>
      );
    } else {
      return <span>{this.props.header}</span>;
    }
  }
}

export default TableHeaderItem;
