/** @format **/

import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';

import UserButton from '../components/userButton';

export interface ContainerChildProps {
  impersonatedBy?: string;
  isImpersonated?: boolean;
  username: string;
  avatarurl: string;
}

const mapStateToProps = (state: AppState): ContainerChildProps => {
  return {
    avatarurl: state.app.configuration.AvatarUrl,
    impersonatedBy: state.app.configuration.ImpersonatingUser,
    isImpersonated: state.app.configuration.IsImpersonated,
    username: state.app.configuration.UsersName,
  };
};

const UserButtonContainer = connect(mapStateToProps)(UserButton);

export default UserButtonContainer;
