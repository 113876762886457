/**
 * @prettier
 */

import React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';

import { isComponent } from 'utils/types';

import { KeyDisc, RowToggleCell } from './groupedTable/components';
import { DiscColorMap } from './groupedTable/models';

import { applyGutterCells, getCellName } from './tableUtils';
import { Column, CellPadding } from './tableModel';

export type TableRowProperties = {
  color?: 'red' | 'grey' | 'blue' | 'disabled';
};

type TableRowPropsInternal = {
  item: any;
  columns: Column[];
  padding: CellPadding;
  gutters?: boolean;
  className?: string;
  props?: TableRowProperties;
  isParentRow?: boolean;
  isChildRow?: boolean;
  groupId?: string;
  showRow?: boolean;
  showChildRows?: boolean;
  toggleChildRows?: () => void;
  discColorMap?: DiscColorMap;
};

const constructTableCells = (props: TableRowPropsInternal): React.ReactNode[] => {
  return props.columns.map((column, index) => {
    const padding = !isNil(column.padding) ? column.padding : props.padding;

    const className = classnames('table__cell', {
      'table__cell--size-small': padding === 'tight',
      'table__cell--size-normal': padding === 'standard',
      'table__cell--size-large': padding === 'large',
      'table__cell--size-xlarge': padding === 'xlarge',
      'table__cell--size-xxlarge': padding === 'xxlarge',
      [`table__cell-${column.width}`]: column.width !== undefined,
      'table__cell--top': column.align === 'top',
      'table__cell--flat-left': column.flatLeft,
      'table__cell--flat-right': column.flatRight,
      'table__cell--space-left': column.spaceLeft,
    });

    const Cell = column.cell;
    const renderedColumn = isComponent(Cell) ? <Cell {...props.item} /> : Cell.render(props.item);

    // Add a colored disc next to the first cell in the row for grouped tables only
    let discColor: string = null;

    if (index === 0 && !isNil(props.discColorMap)) {
      discColor = !isNil(props.groupId) && props.discColorMap[props.groupId];
    }

    return (
      <td key={index} className={className} data-content={getCellName(column)}>
        {!isNil(discColor) && <KeyDisc color={discColor} />}
        {renderedColumn}
      </td>
    );
  });
};

export const TableRow: React.FunctionComponent<TableRowPropsInternal> = props => {
  if (!props.showRow) {
    return null;
  }

  const cells = applyGutterCells(constructTableCells(props), props.gutters);
  const tableProps = props.props || {};

  // Add an button to toggle the expanded state of the child rows at the start of each parent row
  // (for grouped tables only)
  if (props.isParentRow) {
    cells.unshift(
      <RowToggleCell
        key={-1}
        showChildRows={props.showChildRows}
        toggleChildRows={props.toggleChildRows}
      />,
    );
  } else if (props.isChildRow) {
    cells.unshift(<td key={-1}>&nbsp;</td>);
  }

  const classes = classnames(
    {
      'table__row--red': tableProps.color === 'red',
      'table__row--grey': tableProps.color === 'grey',
      'table__row--blue': tableProps.color === 'blue',
      'table__row--disabled': tableProps.color === 'disabled',
    },
    props.className,
  );

  return <tr className={classes}>{cells}</tr>;
};

TableRow.defaultProps = {
  props: {},
  showRow: true,
};
