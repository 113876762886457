/** @format */

import * as React from 'react';
import classnames from 'classnames';
import { omit } from 'lodash';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

type Props = {
  color?: 'grey' | 'white' | null;
  noBorder?: boolean;
  padding?: '12' | '24' | 'top-tight' | null;
  extraClasses?: string;
};

export const ModuleHeader: React.FunctionComponent<Props> = props => {
  const classes = classnames(
    'module__header',
    {
      'module__header--white': props.color === 'white',
      'module__header--grey': props.color === 'grey',
      'module__header--no-border': props.noBorder,
      'module__header--padding': props.padding === '24',
      'module__header--padding-tight': props.padding === '12',
      'module__header--padding-top-tight': props.padding === 'top-tight',
    },
    props.extraClasses,
  );

  return <div className={classes}>{props.children}</div>;
};

ModuleHeader.defaultProps = {
  color: 'grey',
  padding: null,
  noBorder: false,
};

export default ModuleHeader;

type ModuleHeaderWithButtonProps = {
  button: React.ReactNode;
  largeButton?: boolean;
} & Props;

export const ModuleHeaderWithNoButton: React.FunctionComponent<Props> = props => {
  return (
    <ModuleHeader {...omit(props, 'children')} extraClasses={'clearfix'}>
      {props.children}
    </ModuleHeader>
  );
};

export const ModuleHeaderWithButton: React.FunctionComponent<
  ModuleHeaderWithButtonProps
> = props => {
  const moduleSettingClasses = classnames('module-header-setting', {
    'module-header-setting--large-button': props.largeButton,
  });

  return (
    <ModuleHeader {...omit(props, 'children')} extraClasses={'clearfix'}>
      <div className={moduleSettingClasses}>
        <div className="module-header-setting__title">{props.children}</div>
        <div className="module-header-setting__button">{props.button}</div>
      </div>
    </ModuleHeader>
  );
};

const StyledHeaderContainer = (): IStyle => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
});

const HeaderContainer = createComponent(StyledHeaderContainer);

const StyledHeaderItem = (): IStyle => ({
  lineHeight: '20px',
});

const HeaderItem = createComponent(StyledHeaderItem);

type ModuleHeaderWithTextProps = {
  link: React.ReactNode;
} & Props;

export const ModuleHeaderWithText: React.FunctionComponent<ModuleHeaderWithTextProps> = props => (
  <ModuleHeader {...omit(props, 'children')}>
    <HeaderContainer>
      <HeaderItem>{props.children}</HeaderItem>
      <HeaderItem>{props.link}</HeaderItem>
    </HeaderContainer>
  </ModuleHeader>
);
