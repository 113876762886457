/** @format */

import createActionFactory from 'typescript-fsa';
import { ThunkAction } from 'redux-thunk';
import { ToplevelFiltersState } from './state';
import * as Selectors from './selectors';
import * as Models from './models';
import { postToAPI } from 'utils/fetching';
import { isNil, filter, forEach } from 'lodash';

const createAction = createActionFactory('toplevel-filters');

export const addFilter = createAction<Models.Filter>('ADD_FILTER');
export const editFilter = createAction<Models.Filter>('EDIT_FILTER');
export const removeFilter = createAction<string>('REMOVE_FILTER');
export const setFilters = createAction<Models.Filter[]>('SET_FILTERS');
export const resetFilters = createAction('RESET_FILTERS');

export const setDimensions = createAction<Models.DimensionWithComparators[]>('SET_DIMENSIONS');

export type UpdateAddFilterStatePayload = {
  selectedDimensionId?: string;
  selectedComparatorId?: string;
  currentQuery?: string;
  currentMode?: Models.Mode;
};

export type UpdateEditFilterStatePayload = {
  selectedFilterId?: string;
  selectedMode?: Models.Mode;
  openDropdown?: boolean;
  showComparatorDropdown?: boolean;
  showValuesDropdown?: boolean;
};

export type UpdateComponentStatePayload = {
  collapse?: boolean;
  showToggleButton?: boolean;
  advancedSearchActive?: boolean;
};

export const updateAddFilterState = createAction<UpdateAddFilterStatePayload>(
  'UPDATE_ADD_FILTER_STATE',
);
export const updateEditFilterState = createAction<UpdateEditFilterStatePayload>(
  'UPDATE_EDIT_FILTER_STATE',
);
export const updateComponentState = createAction<UpdateComponentStatePayload>(
  'UPDATE_COMPONENT_STATE',
);

export type UpdateExternalConfigurationPayload = {
  applicationIdentifier?: string;
  endpoint?: string;
  fromDate?: string;
  toDate?: string;
  apiKey?: string;
  theme?: string;
  addFilterText?: string;
  noResultsText?: string;
  supportAdvancedSearch?: boolean;
};
export const updateExternalConfiguration = createAction<UpdateExternalConfigurationPayload>(
  'UPDATE_EXTERNAL_CONFIGURATION',
);

export type FetchValuesForDimensionStartedPayload = { dimension: string };
export const fetchValuesForDimensionStarted = createAction<FetchValuesForDimensionStartedPayload>(
  'FETCH_VALUES_FOR_DIMENSION_STARTED',
);

export type FetchValuesForDimensionFailedPayload = { dimension: string; error: Error };
export const fetchValuesForDimensionFailed = createAction<FetchValuesForDimensionFailedPayload>(
  'FETCH_VALUES_FOR_DIMENSION_FAILED',
);

export type FetchValuesForDimensionSucceededPayload = {
  dimension: string;
  values: Models.FilterValue[];
};
export const fetchValuesForDimensionSucceeded = createAction<
  FetchValuesForDimensionSucceededPayload
>('FETCH_VALUES_FOR_DIMENSION_SUCCESS');

export type AddValuesForDimensionPayload = { dimension: string; values: Models.FilterValue[] };
export const addValuesForDimension = createAction<AddValuesForDimensionPayload>(
  'ADD_VALUES_FOR_DIMENSION',
);

type FetchDimensionsResponse = {
  dimensionName: string;
  values: Models.FilterValue[];
};

type PostToValuesApiParams = {
  state: ToplevelFiltersState;
  dispatch: any;
  dimension: Models.Dimension;
  comparator: Models.Comparator;
  currentQuery: string;
};

export const fetchDimensionValues = (
  ignoreQueryText?: boolean,
): ThunkAction<any, ToplevelFiltersState, null, any> => (dispatch, getState) => {
  const state = getState();
  const { currentQuery } = Selectors.getAddFilterState(state);
  const comparator = Selectors.getActiveComparator(state);
  const dimension = Selectors.getActiveDimension(state);

  // Check store for matches before checking API
  const currentValues = Selectors.getCurrentValues(state);
  if (currentValues.length > 0) {
    const results = filter(
      currentValues,
      v => v.displayName.toLowerCase().indexOf(currentQuery.toLowerCase()) !== -1,
    );

    if (results.length > 0) {
      fetchValuesForDimensionSucceeded({
        dimension: dimension.id,
        values: currentValues,
      });
      return;
    }
  }

  postToValuesApi({
    dimension,
    comparator,
    state,
    dispatch,
    currentQuery: ignoreQueryText ? '' : currentQuery,
  });
};

export const fetchDimensionValuesByFilter = (
  currentFilter: Models.Filter,
  currentQuery: string = '',
): ThunkAction<any, ToplevelFiltersState, null, any> => (dispatch, getState) => {
  const state = getState();

  postToValuesApi({
    dimension: currentFilter.dimension,
    comparator: currentFilter.comparator,
    state,
    dispatch,
    currentQuery,
  });
};

function postToValuesApi(params: PostToValuesApiParams) {
  const { state, dispatch, dimension, comparator, currentQuery } = params;
  const {
    applicationIdentifier,
    endpoint,
    fromDate,
    toDate,
    apiKey,
  } = Selectors.getExternalConfiguration(state);

  if (!dimension) {
    return;
  }

  const selectedDimensionId = dimension.id;
  dispatch(fetchValuesForDimensionStarted({ dimension: selectedDimensionId }));

  const requestParams = {
    fromDate,
    toDate,
    applicationIdentifier,
    query: currentQuery,
    comparator: isNil(comparator) ? '' : comparator.id,
    dimensionName: dimension.id,
  };

  postToAPI(endpoint, apiKey, requestParams).then(
    (response: FetchDimensionsResponse) => {
      dispatch(
        fetchValuesForDimensionSucceeded({
          dimension: selectedDimensionId,
          values: response.values,
        }),
      );
    },
    (error: Error) =>
      dispatch(fetchValuesForDimensionFailed({ dimension: selectedDimensionId, error })),
  );
}
