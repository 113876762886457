/** @format */
import { get, isNil, find } from 'lodash';

import { Application } from 'interfaces/application';

import { toId } from 'utils/linking';

function getApplicationsFromPageData(): Application[] {
  return get(window, 'RaygunPageData.Applications', []);
}

export function getApplicationForPage(): Application {
  const applicationId: number = get(window, 'RaygunConfiguration.ApplicationId', null);

  if (!isNil(applicationId)) {
    const applications = getApplicationsFromPageData();
    const matchingApplication = find(applications, a => a.id === applicationId);

    if (!isNil(matchingApplication)) {
      return matchingApplication;
    }
  }

  return null;
}

export function getApplicationNameByIdentifier(appIdentifier: string) {
  const appId = toId(appIdentifier);
  const applications = getApplicationsFromPageData();
  const applicationDetails = find(applications, app => app.id === appId);

  if (!isNil(applicationDetails)) {
    return applicationDetails.name;
  }

  return null;
}

export function getApplicationIdentifier() {
  return get(window, 'RaygunConfiguration.ApplicationIdentifier');
}
