/** @format */

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { clone } from 'lodash';
import { reducerUpdate } from 'utils/reducerUpdate';

import * as Actions from './actions';
import * as Models from './models';

const defaultState: Models.NotificationState = {};

export const NotificationReducer = reducerWithInitialState(defaultState)
  .case(Actions.show, (state, notification) => {
    if (!notification.id) {
      notification.id = Actions.getID();
    }
    const id = notification.id;
    return reducerUpdate(state, { [`${id}`]: { id, created: Date.now(), ...notification } });
  })
  .case(Actions.update, (state, newInfo) => {
    const id = newInfo.id;
    const current = state[id];
    return reducerUpdate(state, { [`${id}`]: { ...current, ...newInfo } });
  })
  .case(Actions.remove, (state, id) => {
    const newState = clone(state);
    delete newState[id];

    return newState;
  });
