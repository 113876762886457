/**
 * @prettier
 */

import { createSelector } from 'reselect';
import { get, includes, isEmpty, isNil, map, some, every, keyBy } from 'lodash';

import {
  ApplicationPermissions,
  PermissionCategory,
  PermissionManager,
  PermissionTypes,
} from 'modules/permissions';

import { ErrorGroupListData } from '../actions';
import { DashboardModuleStateAtom } from '../state';
import * as Models from '../models';
import { TileSettings } from '../models';
import { isTimeboard } from './dashboard';
import { Constants } from '../index';

const dashboardSelector = (state: DashboardModuleStateAtom) => state.dashboard.tiles;
const currentDashboardTilesSelector = (state: DashboardModuleStateAtom) =>
  state.dashboard.layout.current.Tiles;

export const getTileData = createSelector(
  dashboardSelector,
  dashboard => dashboard.tileData,
);

export const getDrilldownData = createSelector(
  dashboardSelector,
  dashboard => dashboard.errorsPerAppDrilldownData,
);

export const getSelectedErrorGroups = createSelector(
  dashboardSelector,
  dashboard => dashboard.selectedErrorGroupsData,
);

export const getTileState = createSelector(
  dashboardSelector,
  dashboard => dashboard.tileState,
);

export const getListSorting = (tileId: string) =>
  createSelector(
    dashboardSelector,
    dashboard => get(get(dashboard.tileState, tileId, {}), 'sorting') as Models.SortingState,
  );

export const isErrorGroupSelectedFactory = (tileId: string, errorGroupId: number) =>
  createSelector(
    getSelectedErrorGroups,
    errorGroupTiles => some(get(errorGroupTiles, tileId, []), t => t.errorGroupId === errorGroupId),
  );

/**
 * Check whether there are additional settings that need to be displayed besides
 * settings that can be ignored, defined in TIMEBOARD_SETTINGS
 */
export const shouldRenderSettings = (tileType: string, tileSettings: TileSettings) =>
  createSelector(
    isTimeboard,
    timeboard => {
      if (!timeboard || includes(Constants.TIMEBOARD_CAN_IGNORE_TYPES, tileType)) {
        return true;
      }

      const settingsMap = map(tileSettings, (val: string, key: string) => ({ key, val }));
      const filteredMap = settingsMap.filter(
        setting =>
          !(
            isEmpty(setting.val) ||
            isNil(setting.val) ||
            includes(Constants.TIMEBOARD_CAN_IGNORE_SETTINGS, setting.key)
          ),
      );

      return filteredMap.length > 0;
    },
  );

export const allErrorGroupsSelectedFactory = (
  tileId: string,
  isDrilldown: boolean = false,
  applicationId?: number,
) =>
  createSelector(
    getSelectedErrorGroups,
    isDrilldown ? getDrilldownData : getTileData,
    (allSelectedErrors, allTileData) => {
      const tileData = get(
        allTileData,
        `[${isDrilldown ? applicationId : tileId}].data.Data`,
        [],
      ) as ErrorGroupListData[];

      const selectedErrors = get(allSelectedErrors, tileId, []) as Models.SelectedErrorGroup[];
      if (isDrilldown) {
        return (
          tileData.length === selectedErrors.filter(e => e.applicationId == applicationId).length
        );
      }
      return tileData.length === selectedErrors.length;
    },
  );

/**
 * Checks if user has permission to all applications associated with the tile.
 * Only checks application permissions.
 * @param tileId, of the target error group table tile
 * @param permission, to check
 */
export const hasPermissionForAllApplications = (tileId: string, permission: PermissionTypes) =>
  createSelector(
    currentDashboardTilesSelector,
    data => {
      if (isNil(tileId)) {
        return false;
      }
      const tileData = keyBy(data, 'InstanceId')[tileId];
      const applications = tileData.Settings.Applications.split(',');
      return every(applications, id =>
        PermissionManager.can(permission, PermissionCategory.APPLICATION, parseInt(id)),
      );
    },
  );

/**
 * Checks if user has permission to all applications associated with the tile.
 * @param tileId, of the target error group table tile
 */
export const shouldRenderDelete = (tileId: string) =>
  createSelector(
    currentDashboardTilesSelector,
    data => {
      if (isNil(tileId)) {
        return false;
      }
      const tileData = keyBy(data, 'InstanceId')[tileId];
      const applications = tileData.Settings.Applications.split(',');
      return every(applications, id =>
        PermissionManager.can(
          ApplicationPermissions.DeleteErrorGroups,
          PermissionCategory.APPLICATION,
          parseInt(id),
        ),
      );
    },
  );
