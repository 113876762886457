/**
 * @prettier
 */

import { forEach, isNil, get, find, inRange, isNumber, slice } from 'lodash';

import { ApdexDataPoint } from 'apm/modules/overview/models';

interface Label {
  show: boolean;
}

interface LineStyle {
  type: string;
  width: number;
  color: string;
}

export interface MarkLine {
  lineStyle: { [key: string]: LineStyle };
  label: { [key: string]: Label };
  data: MarkLineDataPoint[][];
  silent: boolean;
  symbol: string;
}

export interface MarkLineDataPoint {
  name: string;
  xAxis: number;
  yAxis: number;
}

export const CHART_LINE_COLOR = '#348ac8';
export const CHART_LINE_COLOR_NO_DATA = '#B0BEC5';
export const APDEX_EXCELLENT = '#4CAF50';
export const APDEX_GOOD = '#CDDC39';
export const APDEX_FAIR = '#FFEB3B';
export const APDEX_POOR = '#FE9E00';
export const APDEX_UNACCEPTABLE = '#DF1F00';
export const NO_DATA_ITEM_NAME = 'No data';

export const APDEX_POOR_MIN = 0.49;
export const APDEX_FAIR_MIN = 0.69;
export const APDEX_GOOD_MIN = 0.84;
export const APDEX_EXCELLENT_MIN = 0.93;

/**
 * gettMarkLineItem
 *
 * Build a mark line data point based on x and y values
 *
 * @param {number} xAxis
 * @param {number} yAxis
 * @param {string} name - optional
 * @returns {MarkLineDataPoint}
 */

function getMarkLineItem(
  xAxis: number,
  yAxis: number,
  name: string = NO_DATA_ITEM_NAME,
): MarkLineDataPoint {
  return {
    name,
    xAxis,
    yAxis,
  };
}

/**
 * getMarkLineData
 *
 * Generate a series of coordinates to display lines for sections where there is no data
 *
 * @param {ApdexDataPoint[]} collection
 * @returns {MarkLineDataPoint[][]}
 */

export function getMarkLineData(collection: ApdexDataPoint[]) {
  const markLineData: MarkLineDataPoint[][] = [];
  let markLineIndex = 0;

  for (let index = 0; index < collection.length; index++) {
    const item = collection[index];
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = collection[prevIndex];
    const next = collection[nextIndex];
    const hasPrev = !isNil(prev);
    const hasNext = !isNil(next);
    const isFirst = index === 0;
    const isLast = index === collection.length - 1;

    if (item.SampleSize === 0) {
      // If the first item has no data, get the score of the next item (or the next item in the collection which has a score) as the y-axis value
      if (isFirst) {
        const yAxisFirst = !isNil(next.Score)
          ? next.Score
          : get(find<ApdexDataPoint>(slice(collection, 2), i => !isNil(i.Score)), 'Score', 0);
        markLineData[markLineIndex] = [getMarkLineItem(index, yAxisFirst)];
      }

      // If the previous item has data and the current entry is empty
      // Start a new line at the coords of the previous entry
      if (hasPrev && prev.SampleSize !== 0 && isNil(markLineData[markLineIndex])) {
        markLineData[markLineIndex] = [getMarkLineItem(prevIndex, prev.Score)];
      }

      // If the next item has data and the current entry is not empty
      // End the current line at the coords of the next entry
      if (hasNext && next.SampleSize !== 0 && !isNil(markLineData[markLineIndex])) {
        markLineData[markLineIndex].push(getMarkLineItem(nextIndex, next.Score));
        markLineIndex++;
      }
    }

    // If this is the last item and the current entry is not closed, close the line
    if (isLast && !isNil(markLineData[markLineIndex])) {
      // If there is data, use the current score, otherwise use the previous entry's y axis value
      const yAxis = item.SampleSize > 0 ? item.Score : markLineData[markLineIndex][0].yAxis;
      markLineData[markLineIndex].push(getMarkLineItem(index, yAxis));
    }
  }

  return markLineData.filter(i => i.length === 2);
}

/**
 * Build a mark line object with formatting defaults for missing data
 *
 * @param {MarkLineDataPoint[][]} data
 * @param {string} color - optional
 * @param {string} lineType - optional
 * @returns {MarkLine}
 */

function getMarkLineObject(
  data: MarkLineDataPoint[][],
  color: string,
  lineType: string = 'dotted',
): MarkLine {
  return {
    silent: true,
    lineStyle: {
      normal: {
        type: lineType,
        width: 2,
        color,
      },
    },
    symbol: 'none',
    label: {
      normal: {
        show: false,
      },
    },
    data,
  };
}

/**
 * getMarkLine
 *
 * Get the markLine object based on the data in the collection
 *
 * @param {ApdexDataPoint[]} collection
 * @returns {MarkLine}
 */

export function getMarkLine(
  collection: ApdexDataPoint[],
  color: string = CHART_LINE_COLOR_NO_DATA,
) {
  if (isNil(collection) || collection.length === 0) {
    return null;
  }

  return getMarkLineObject(getMarkLineData(collection), color);
}

/**
 * getColorForApdexScore
 *
 * Get the color associated with a particular Apdex range based on the score
 *
 * @param {number} score
 * @returns {string}
 */

export function getColorForApdexScore(score: number) {
  if (isNil(score) || !isNumber(score)) {
    return CHART_LINE_COLOR_NO_DATA;
  } else if (inRange(score, 0, APDEX_POOR_MIN)) {
    return APDEX_UNACCEPTABLE;
  } else if (inRange(score, APDEX_POOR_MIN, APDEX_FAIR_MIN)) {
    return APDEX_POOR;
  } else if (inRange(score, APDEX_FAIR_MIN, APDEX_GOOD_MIN)) {
    return APDEX_FAIR;
  } else if (inRange(score, APDEX_GOOD_MIN, APDEX_EXCELLENT_MIN)) {
    return APDEX_GOOD;
  } else if (score >= APDEX_EXCELLENT_MIN) {
    return APDEX_EXCELLENT;
  }

  return CHART_LINE_COLOR_NO_DATA;
}
