/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { isNil } from 'lodash';
import { createComponent } from 'react-fela';

import { Labeltip } from 'components/ds/tooltip';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { preventDefault } from 'utils/events';
import { Icon } from './icon';

type LinkWrapperProps = {
  active: boolean;
};

const StyledLinkWrapper = (props: LinkWrapperProps & ThemeType) => {
  const styles: IStyle = {
    width: '64px',
    height: '40px',
    listStyle: 'none',
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
  };

  return applyModifiers([
    props.active,
    {
      borderRight: `4px solid ${props.theme.ds.colors.blue.blue900}`,
    },
  ])(styles);
};

export const LinkWrapper = createComponent(StyledLinkWrapper);

type LinkProps = {
  url?: string;
  onClick?: (e: any) => void;
  label: string;
  svgSymbol: string;
  active?: boolean;
};

type Props = LinkProps;

export const Link: React.FunctionComponent<Props> = ({
  onClick,
  url,
  label,
  active,
  svgSymbol,
}) => {
  const onClickHandler = !isNil(onClick) ? preventDefault(onClick) : null;

  return (
    <Labeltip position="right" label={label}>
      <LinkWrapper active={active}>
        <a href={url} onClick={onClickHandler}>
          <Icon iconName={svgSymbol} big={false} active={active} />
        </a>
      </LinkWrapper>
    </Labeltip>
  );
};

Link.defaultProps = {
  active: false,
};
