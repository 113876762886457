/** @format **/

import * as React from 'react';

import {
  errorsUrl,
  filtersUrl,
  reportsUrl,
  exportUrl,
  symbolsUrl,
  findApplicationId,
  onFilters,
  onReports,
  onExport,
  onSymbols,
  onErrors,
  getLastViewedApplicationIdentifier,
} from 'utils/links';
import { Navigation, NavigationItem } from 'components/page/header/navigation';

const createNavigationTabs = (): JSX.Element[] => {
  const tabs: JSX.Element[] = [];

  const lastViewedAppIdentifier = getLastViewedApplicationIdentifier();
  const applicationIdentifier = findApplicationId(
    (window as any).RaygunConfiguration,
    lastViewedAppIdentifier,
  );

  const navigationMap: [string, string, boolean][] = [
    [errorsUrl(applicationIdentifier), 'Issues', onErrors()],
    [filtersUrl(applicationIdentifier), 'Filters', onFilters()],
    [reportsUrl(applicationIdentifier), 'Reports', onReports()],
    [exportUrl(applicationIdentifier), 'Export', onExport()],
    [symbolsUrl(applicationIdentifier), 'Symbols', onSymbols()],
  ];

  navigationMap.forEach(([url, title, isActive]) => {
    tabs.push(
      <NavigationItem key={title} text={title} to={url} isActive={isActive} color={'blue'} />,
    );
  });

  return tabs;
};

export const Tabs: React.FunctionComponent<{}> = () => {
  const tabs = createNavigationTabs();

  return <Navigation>{tabs}</Navigation>;
};
