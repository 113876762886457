/** @format */

import * as React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';

type COLOR_TYPES =
  | 'white'
  | 'black'
  | 'grey'
  | 'light'
  | 'dark'
  | 'blue'
  | 'red'
  | 'green'
  | 'yellow'
  | 'purple';

const getTextColorClass = (color: COLOR_TYPES) => {
  return classnames({
    'text-white': color === 'white',
    'text-black': color === 'black',
    'text-grey': color === 'grey',
    'text-light': color === 'light',
    'text-dark': color === 'dark',
    'text-blue': color === 'blue',
    'text-red': color === 'red',
    'text-green': color === 'green',
    'text-yellow': color === 'yellow',
    'text-purple': color === 'purple',
  });
};

type TEXT_SIZES = 'tiny' | 'small' | 'medium' | 'large';

const getTextSizeClass = (size: TEXT_SIZES) => {
  return classnames({
    'text-size-tiny': size === 'tiny', // 10px
    'text-size-small': size === 'small', // 12px
    'text-size-medium': size === 'medium', // 14px
    'text-size-large': size === 'large', // 16px
  });
};

type ALIGNMENTS = 'left' | 'right' | 'center';

const getTextAlignmentClass = (alignment: ALIGNMENTS) => {
  return classnames({
    'text-left': alignment === 'left',
    'text-center': alignment === 'center',
    'text-right': alignment === 'right',
  });
};

type WEIGHTS = 'normal' | 'semi-bold' | 'bold';

const getTextWeightClass = (weight: WEIGHTS) => {
  return classnames({
    'text-weight-normal': weight === 'normal',
    'text-weight-semi-bold': weight === 'semi-bold',
    'text-weight-bold': weight === 'bold',
  });
};

type LAYOUTS = 'block' | 'inline' | 'inline-block';

const getTextLayoutClass = (layout: LAYOUTS) => {
  return classnames({
    'text-layout-block': layout === 'block',
    'text-layout-inline': layout === 'inline',
    'text-layout-inline-block': layout === 'inline-block',
  });
};

interface TextProps {
  size?: 'tiny' | 'small' | 'medium' | 'large';
  height?: '16' | '24' | '32' | '40';
  alignment?: ALIGNMENTS;
  color?: COLOR_TYPES;
  uppercase?: boolean;
  caps?: boolean;
  ellipsis?: boolean;
  break?: boolean;
  mono?: boolean;
  weight?: WEIGHTS;
  layout?: LAYOUTS;
  title?: string;
  inlineBlock?: boolean;
}

const Text: React.FunctionComponent<TextProps> = props => {
  const classNames = classnames(
    {
      'line-height-16': props.height === '16',
      'line-height-24': props.height === '24',
      'line-height-32': props.height === '32',
      'line-height-40': props.height === '40',
      'text-uppercase': props.uppercase,
      'text-capitalize': props.caps,
      'text-ellipsis': props.ellipsis,
      'text-break': props.break,
      'text-mono': props.mono,
      'text-layout-inline-block': props.inlineBlock,
    },
    getTextAlignmentClass(props.alignment),
    getTextSizeClass(props.size),
    getTextColorClass(props.color),
    getTextWeightClass(props.weight),
    getTextLayoutClass(props.layout),
  );

  const elementProps: { title?: string } = {};

  if (!isNil(props.title)) {
    elementProps.title = props.title;
  }

  return (
    <p className={classNames} {...elementProps}>
      {props.children}
    </p>
  );
};

Text.defaultProps = {
  caps: false,
  uppercase: false,
  ellipsis: false,
  break: false,
};

export enum HEADING_CLASSES {
  ZERO = 'heading-size-0',
  ONE = 'heading-size-1',
  TWO = 'heading-size-2',
  THREE = 'heading-size-3',
  FOUR = 'heading-size-4',
  FIVE = 'heading-size-5',
}

type TypographyProps = {
  alignment?: ALIGNMENTS;
  break?: boolean;
};

const Typography: React.FunctionComponent<TypographyProps> = props => {
  const classNames = classnames(
    'typography',
    {
      'text-break': props.break,
    },
    getTextAlignmentClass(props.alignment),
  );

  return <div className={classNames}>{props.children}</div>;
};

export default Text;

export {
  getTextSizeClass,
  getTextColorClass,
  getTextAlignmentClass,
  COLOR_TYPES,
  Text,
  Typography,
};

export { AlignText } from './alignText';
