/** @format **/

import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';

import * as ConfigSelectors from 'app/selectors/configuration';
import { showApplicationSwitcher, showDashboardSwitcher } from 'app/actions/chrome';

import { EmphasisButton } from 'components/ds/button';
import { openModal } from 'components/modal';
import { trackLinkClick } from 'components/snowplow';

import { CreateDashboardModal } from 'modules/createDashboardModal';

type StateProps = {
  hasTimeboardFeatureFlag: boolean;
};

type DispatchProps = {
  createDashboard: () => void;
};

type ReduxDispatchProps = {
  createDashboard: (hasTimeboardFeatureFlag: boolean) => void;
};

type PassedProps = {
  createDashboardLink: string;
  buttonHeight?: number;
};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedCreateDashboardButton: React.FunctionComponent<Props> = ({
  hasTimeboardFeatureFlag,
  createDashboard,
  buttonHeight,
  createDashboardLink,
}) => (
  <EmphasisButton
    onClick={() => createDashboard()}
    href={!hasTimeboardFeatureFlag && createDashboardLink}
    title={`Create a new dashboard`}
    block
    isLink={!hasTimeboardFeatureFlag}
    className={!hasTimeboardFeatureFlag ? 'link-button' : null}
    style={{
      height: `${buttonHeight}px`,
      lineHeight: `${buttonHeight}px`,
      fontSize: buttonHeight === 40 ? '14px' : '12px',
      width: 'auto',
    }} /* TODO: Remove this prop and style attribute once the revamped chrome is deployed */
  >
    Create dashboard
  </EmphasisButton>
);

UnconnectedCreateDashboardButton.defaultProps = {
  buttonHeight: 40,
};

export const ConnectedCreateDashboardButton = connect<
  StateProps,
  ReduxDispatchProps,
  PassedProps,
  Props,
  AppState
>(
  state => ({
    hasTimeboardFeatureFlag: ConfigSelectors.hasTimeboardFeatureFlag(state),
  }),
  dispatch => ({
    createDashboard: (hasTimeboardFeatureFlag: boolean) => {
      trackLinkClick('sp-app-chrome-dashboards-createDashboard');
      dispatch(showDashboardSwitcher(false));
      dispatch(showApplicationSwitcher(false));
      if (hasTimeboardFeatureFlag) {
        dispatch(openModal(CreateDashboardModal));
      }
    },
  }),
  (stateProps, dispatchProps, passedProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...passedProps,
    createDashboard: () => dispatchProps.createDashboard(stateProps.hasTimeboardFeatureFlag),
  }),
)(UnconnectedCreateDashboardButton);

export { ConnectedCreateDashboardButton as CreateDashboardButton };
