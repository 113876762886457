/** @format */
/* tslint:disable:max-file-line-count */

import { filter, find, isNil } from "lodash";
import { Products } from "sections/productpages/setupinstructions/models";
import { APM, CrashReporting, RealUserMonitoring } from "interfaces/products";

export enum EnvironmentIdEnum {
  PHP = 1,
  JavaScript = 2,
  NodeJs = 3,
  Java = 4,
  RubyOnRails = 5,
  ColdFusion = 6,
  ASPNETFramework = 7,
  WPF = 8,
  WinRT = 9,
  WindowsPhone = 10,
  Nancy = 11,
  XamarinIOs = 12,
  XamarinAndroid = 13,
  WordPress = 14,
  Android = 15,
  Python = 16,
  Apple = 17,
  Unity3D = 18,
  XamarinMac = 19,
  ASPNETMVC = 20,
  ASPNETWebAPI = 21,
  WinForms = 22,
  WindowsStore = 23,
  DotNETFramework = 24,
  Go = 25,
  /**
   * @deprecated
   */
  MacOS = 26,
  Angular = 27,
  React = 28,
  ASPNET = 29,
  CPP = 30, // C++ key is renamed to CPP because in enum keys needs to be valid identifiers
  DotNET6Plus = 31,
  Swift = 32,
  XamarinForms = 33,
  UWP = 34,
  Ruby = 35,
  ReactNative = 36,
  Flutter = 37,
  MAUI = 38,
  DotNETForAzure = 39,
  SerilogSinks = 40,
  Svelte = 41,
  NuxtJS = 42,
  VueJS = 43,
  NextJS = 44,
  Blazor = 45,
  Remix = 46,
  Laravel = 47,
  Aspire = 48,
  Magento = 49,
  Elixir = 50,
  SpringBoot = 51,
  Other = 100,
  NotSelected = 1000,
}

type EnvironmentFeatures = {
  supportedProducts: {
    // Providing a string reference allows us to override 
    // with a different instruction set for the product.
    [product in Products]: boolean | string;
  };
  userTrackingSupport: boolean | string;
};

export type Environment = {
  id: EnvironmentIdEnum;
  name: string;
  icon: string;
  instructions: string | null;
  features: EnvironmentFeatures;
};

export const Angular: Environment = {
  id: EnvironmentIdEnum.Angular,
  name: 'Angular',
  icon: 'platform-angular',
  instructions: 'angular',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Android: Environment = {
  id: EnvironmentIdEnum.Android,
  name: 'Android',
  icon: 'platform-android',
  instructions: 'android',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const AspDotNetFramework: Environment = {
  id: EnvironmentIdEnum.ASPNETFramework,
  name: 'ASP.NET Framework',
  icon: 'platform-asp-net',
  instructions: 'aspnet-framework',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'aspnet',
      [APM]: true,
    },
    userTrackingSupport: 'aspnet',
  },
};
export const AspDotNet: Environment = {
  id: EnvironmentIdEnum.ASPNET,
  name: 'ASP.NET',
  icon: 'platform-asp-net',
  instructions: 'aspnet',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: 'dotnet-core',
    },
    userTrackingSupport: true,
  },
};
export const AspDotNetWebApi: Environment = {
  id: EnvironmentIdEnum.ASPNETWebAPI,
  name: 'ASP.NET WebAPI',
  icon: 'platform-asp-net',
  instructions: 'aspnet-webapi',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: 'dotnet-core',
    },
    userTrackingSupport: 'aspnet',
  },
};
export const AspDotNetMvc: Environment = {
  id: EnvironmentIdEnum.ASPNETMVC,
  name: 'ASP.NET MVC',
  icon: 'platform-asp-net',
  instructions: 'aspnet-mvc',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: 'dotnet-core',
    },
    userTrackingSupport: 'aspnet',
  },
};
export const Aspire: Environment = {
  id: EnvironmentIdEnum.Aspire,
  name: '.NET Aspire',
  icon: 'platform-aspire',
  instructions: 'aspire',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const Blazor: Environment = {
  id: EnvironmentIdEnum.Blazor,
  name: 'Blazor',
  icon: 'platform-dot-net',
  instructions: 'blazor',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const CPlusPlus: Environment = {
  id: EnvironmentIdEnum.CPP,
  name: 'C++',
  icon: 'platform-c-plus-plus',
  instructions: 'cpp',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const ColdFusion: Environment = {
  id: EnvironmentIdEnum.ColdFusion,
  name: 'ColdFusion',
  icon: 'platform-cold-fusion',
  instructions: 'coldfusion',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: false,
    },
    userTrackingSupport: 'javascript',
  },
};

export const Elixir: Environment = {
  id: EnvironmentIdEnum.Elixir,
  name: 'Elixir',
  icon: 'platform-elixir',
  instructions: 'elixir',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};

export const DotNetForAzure: Environment = {
  id: EnvironmentIdEnum.DotNETForAzure,
  name: '.NET for Azure',
  icon: 'platform-azure-app-service',
  instructions: 'dotnet-azure',
  features: {
    supportedProducts: {
      [CrashReporting]: false,
      [RealUserMonitoring]: false,
      [APM]: true,
    },
    userTrackingSupport: 'aspnet',
  },
};
export const DotNetFramework: Environment = {
  id: EnvironmentIdEnum.DotNETFramework,
  name: '.NET Framework',
  icon: 'platform-dot-net',
  instructions: 'dotnet-framework',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: true,
    },
    userTrackingSupport: 'javascript',
  },
};
export const DotNetCore: Environment = {
  id: EnvironmentIdEnum.DotNET6Plus,
  name: '.NET 6+',
  icon: 'platform-dot-net',
  instructions: 'dotnet-core',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: true,
    },
    userTrackingSupport: true,
  },
};
export const Flutter: Environment = {
  id: EnvironmentIdEnum.Flutter,
  name: 'Flutter',
  icon: 'platform-flutter',
  instructions: 'flutter',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Go: Environment = {
  id: EnvironmentIdEnum.Go,
  name: 'Go',
  icon: 'platform-go-lang',
  instructions: 'go',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Apple: Environment = {
  id: EnvironmentIdEnum.Apple,
  name: 'Apple',
  icon: 'platform-apple',
  instructions: 'apple',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Java: Environment = {
  id: EnvironmentIdEnum.Java,
  name: 'Java',
  icon: 'platform-java',
  instructions: 'java',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const JavaScript: Environment = {
  id: EnvironmentIdEnum.JavaScript,
  name: 'JavaScript',
  icon: 'platform-javascript',
  instructions: 'javascript',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Laravel: Environment = {
  id: EnvironmentIdEnum.Laravel,
  name: 'Laravel',
  icon: 'platform-laravel',
  instructions: 'laravel',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const Magento: Environment = {
  id: EnvironmentIdEnum.Magento,
  name: 'Magento',
  icon: 'platform-magento',
  instructions: 'magento',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const Maui: Environment = {
  id: EnvironmentIdEnum.MAUI,
  name: 'MAUI',
  icon: 'platform-dot-net',
  instructions: 'maui',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const Nancy: Environment = {
  id: EnvironmentIdEnum.Nancy,
  name: 'Nancy',
  icon: 'platform-nancy',
  instructions: 'nancy',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: false,
    },
    userTrackingSupport: 'javascript',
  },
};
export const NextJs: Environment = {
  id: EnvironmentIdEnum.NextJS,
  name: 'Next.js',
  icon: 'platform-nextjs',
  instructions: 'nextjs',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: 'javascript',
  },
};
export const NodeJs: Environment = {
  id: EnvironmentIdEnum.NodeJs,
  name: 'Node.js',
  icon: 'platform-nodejs',
  instructions: 'nodejs',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: true,
    },
    userTrackingSupport: true,
  },
};
export const NuxtJs: Environment = {
  id: EnvironmentIdEnum.NuxtJS,
  name: 'Nuxt.js',
  icon: 'platform-nuxtjs',
  instructions: 'nuxtjs',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: 'javascript',
  },
};
export const Php: Environment = {
  id: EnvironmentIdEnum.PHP,
  name: 'PHP',
  icon: 'platform-php',
  instructions: 'php',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Python: Environment = {
  id: EnvironmentIdEnum.Python,
  name: 'Python',
  icon: 'platform-python',
  instructions: 'python',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const React: Environment = {
  id: EnvironmentIdEnum.React,
  name: 'React',
  icon: 'platform-react',
  instructions: 'javascript',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const ReactNative: Environment = {
  id: EnvironmentIdEnum.ReactNative,
  name: 'React Native',
  icon: 'platform-react',
  instructions: 'react-native',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Remix: Environment = {
  id: EnvironmentIdEnum.Remix,
  name: 'Remix',
  icon: 'platform-remix',
  instructions: 'remix',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: 'javascript',
  },
};
export const Ruby: Environment = {
  id: EnvironmentIdEnum.Ruby,
  name: 'Ruby',
  icon: 'platform-ruby-alt',
  instructions: 'ruby',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: true,
    },
    userTrackingSupport: false,
  },
};
export const RubyOnRails: Environment = {
  id: EnvironmentIdEnum.RubyOnRails,
  name: 'Ruby on Rails',
  icon: 'platform-rails',
  instructions: 'ruby-on-rails',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: 'javascript',
      [APM]: true,
    },
    userTrackingSupport: true,
  },
};

export const SerilogSinks: Environment = {
  id: EnvironmentIdEnum.SerilogSinks,
  name: 'Serilog Sinks',
  icon: 'platform-serilog-sinks',
  instructions: 'serilog-sinks',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};

export const SpringBoot: Environment = {
  id: EnvironmentIdEnum.SpringBoot,
  name: 'Spring Boot',
  icon: 'platform-spring-boot',
  instructions: 'spring-boot',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};

export const Swift: Environment = {
  id: EnvironmentIdEnum.Swift,
  name: 'Swift',
  icon: 'platform-swift',
  instructions: 'swift',
  features: {
    supportedProducts: {
      [CrashReporting]: 'apple',
      [RealUserMonitoring]: 'apple',
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Svelte: Environment = {
  id: EnvironmentIdEnum.Svelte,
  name: 'Svelte',
  icon: 'platform-svelte',
  instructions: 'javascript',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Unity: Environment = {
  id: EnvironmentIdEnum.Unity3D,
  name: 'Unity 3D',
  icon: 'platform-unity',
  instructions: 'unity',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: 'aspnet',
  },
};
export const Uwp: Environment = {
  id: EnvironmentIdEnum.UWP,
  name: 'UWP',
  icon: 'platform-windows-8',
  instructions: 'uwp',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const VueJs: Environment = {
  id: EnvironmentIdEnum.VueJS,
  name: 'Vue.js',
  icon: 'platform-vuejs',
  instructions: 'vuejs',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const WinForms: Environment = {
  id: EnvironmentIdEnum.WinForms,
  name: 'WinForms',
  icon: 'platform-winforms',
  instructions: 'winforms',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: 'aspnet',
  },
};
// TODO: Candidate for removal?
export const WindowsPhone: Environment = {
  id: EnvironmentIdEnum.WindowsPhone,
  name: 'Windows Phone',
  icon: 'platform-windows-8',
  instructions: 'windows-phone',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: 'aspnet',
  },
};
// TODO: Candidate for removal?
export const WindowsStore: Environment = {
  id: EnvironmentIdEnum.WindowsStore,
  name: 'Windows Store',
  icon: 'platform-windows-store',
  instructions: 'windows-store',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: 'aspnet',
  },
};
export const WinRT: Environment = {
  id: EnvironmentIdEnum.WinRT,
  name: 'WinRT',
  icon: 'platform-windows-8',
  instructions: 'winrt',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: 'aspnet',
  },
};

export const WordPress: Environment = {
  id: EnvironmentIdEnum.WordPress,
  name: 'WordPress',
  icon: 'platform-wordpress',
  instructions: 'wordpress',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Wpf: Environment = {
  id: EnvironmentIdEnum.WPF,
  name: 'WPF',
  icon: 'platform-windows-8',
  instructions: 'wpf',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
// TODO: Candidate for removal?
export const XamarinAndroid: Environment = {
  id: EnvironmentIdEnum.XamarinAndroid,
  name: 'Xamarin.Android',
  icon: 'platform-xamarin',
  instructions: 'xamarin-android',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: 'xamarin-forms',
  },
};
export const XamarinIOs: Environment = {
  id: EnvironmentIdEnum.XamarinIOs,
  name: 'Xamarin.iOS',
  icon: 'platform-xamarin',
  instructions: 'xamarin-ios',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: 'xamarin-forms',
  },
};
export const XamarinMac: Environment = {
  id: EnvironmentIdEnum.XamarinMac,
  name: 'Xamarin.Mac',
  icon: 'platform-xamarin',
  instructions: 'xamarin-mac',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: false, 
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const XamarinForms: Environment = {
  id: EnvironmentIdEnum.XamarinForms,
  name: 'Xamarin.Forms',
  icon: 'platform-xamarin',
  instructions: 'xamarin-forms',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: true,
  },
};
export const Other: Environment = {
  id: EnvironmentIdEnum.Other,
  name: 'Other',
  icon: 'platform-other',
  instructions: 'other',
  features: {
    supportedProducts: {
      [CrashReporting]: true,
      [RealUserMonitoring]: true,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};
export const NotSelected: Environment = {
  id: EnvironmentIdEnum.NotSelected,
  name: 'NotSelected',
  icon: 'question',
  instructions: null,
  features: {
    supportedProducts: {
      [CrashReporting]: false,
      [RealUserMonitoring]: false,
      [APM]: false,
    },
    userTrackingSupport: false,
  },
};

export const Environments: Environment[] = [
  Angular,
  AspDotNet,
  AspDotNetFramework,
  AspDotNetWebApi,
  AspDotNetMvc,
  Apple,
  Android,
  Blazor,
  CPlusPlus,
  ColdFusion,
  Elixir,
  Flutter,
  Go,
  Java,
  JavaScript,
  Laravel,
  Nancy,
  Aspire,
  DotNetCore,
  DotNetFramework,
  DotNetForAzure,
  NextJs,
  NodeJs,
  NuxtJs,
  Magento,
  Maui,
  Php,
  Python,
  React,
  ReactNative,
  Remix,
  Ruby,
  RubyOnRails,
  SerilogSinks,
  SpringBoot,
  Svelte,
  Swift,
  Unity,
  Uwp,
  VueJs,
  WinForms,
  WindowsPhone,
  WindowsStore,
  WinRT,
  WordPress,
  Wpf,
  XamarinForms,
  XamarinAndroid,
  XamarinIOs,
  XamarinMac,
  Other,
  NotSelected,
];

export const getEnvironmentById = (id: number) => {
  const environment = find(Environments, e => e.id === id);

  if (isNil(environment)) {
    throw new Error(`Environment with id ${id} does not exist`);
  }

  return environment;
};

export const getEnvironmentByInstructions = (instructions: string) => {
  const environment = find(Environments, e => e.instructions === instructions);

  if (isNil(environment)) {
    throw new Error(`Environment with name ${name} does not exist`);
  }

  return environment;
};

// Environment features can be bools or strings, 
// by using !! we are able to assert truthy values at the same time.
export const getEnvironmentsForProduct = (product: Products): Environment[] => {
  return Environments.filter(env => {
    if (env.id !== EnvironmentIdEnum.NotSelected) {
      switch (product) {
        case CrashReporting:
          return !!env.features.supportedProducts[CrashReporting];
        case RealUserMonitoring:
          return !!env.features.supportedProducts[RealUserMonitoring];
        case APM:
          return !!env.features.supportedProducts[APM];
        default:
          throw new Error(`Product ${product} is not supported`);
      }
    }
  });
};

export const getEnvironmentsWithUserTracking = (): Environment[] => {
  return filter(Environments, env => !!env.features.userTrackingSupport);
};