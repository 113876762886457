/** @format */
import React from 'react';
import { map } from 'lodash';
import { connect, Dispatch } from 'react-redux';

import { Plan } from 'interfaces/plan';
import { AppState } from 'interfaces/appState';
import { PlanAvatar } from 'components/ds/planAvatar';
import { getPlans } from 'app/selectors/configuration';
import { PlanAvatarDropdown } from 'components/ds/dropdown/triggers';
import { MenuItemWithIcon } from 'components/ds/dropdown/menu/items/icon';

import { selectPlan } from '../../../actions';
import { getSwitcherSelectedPlan } from '../../../selectors';

type StateProps = {
  plans: Plan[];
  selectedPlan: Plan;
};

type DispatchProps = {
  setPlan: (id: number) => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedDropdownPlanSelector: React.FunctionComponent<Props> = ({ selectedPlan, plans, setPlan }) => (
  <PlanAvatarDropdown
    triggerText={selectedPlan.PlanName}
    triggerProps={{
      name: selectedPlan.PlanName,
      identifier: selectedPlan.Id,
    }}
  >
    {map(plans, (p, i) => (
      <MenuItemWithIcon
        Icon={<PlanAvatar name={p.PlanName} big={false} identifier={p.Id} planNumber={i} />}
        iconHeight={32}
        key={p.Id}
        label={p.PlanName}
        value={p.Id}
        onClick={() => setPlan(p.Id)}
        role="button"
      />
    ))}
  </PlanAvatarDropdown>
);

const ConnectedDropdownPlanSelector = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => ({
    plans: getPlans(state),
    selectedPlan: getSwitcherSelectedPlan(state)
  }),
  (dispatch: Dispatch<AppState>) => ({
    setPlan: (id: number) => dispatch(selectPlan(id))
  })
)(UnconnectedDropdownPlanSelector);

export { ConnectedDropdownPlanSelector as DropdownPlanSelector };