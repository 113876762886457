/** @format **/

import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

const StyledIconWrapper = (): IStyle => ({
  display: 'flex',
  marginRight: '8px',
  height: '19px'
});

export const IconWrapper = createComponent(StyledIconWrapper);