/**
 * @prettier
 */

import { assign } from 'lodash';

import { SingleValueTile, AuditLogTile, TextHeader, Textbox } from '../components/tiles';
import * as Models from '../models';

import { RumTileMap } from './rum';
import { ApmTileMap } from './apm';
import { CrashReportingTileMap } from './cr';
import {
  crashReportingRequired,
  DEFAULT_DATE_RANGE,
  fetchAction,
  planLevelSBAndAbove,
  pulseRequired,
} from './shared';

const { createTileMap, ActionFilterType } = Models;

export const allTileMaps: Models.TileDefinition[] = [
  ...RumTileMap,
  ...ApmTileMap,
  ...CrashReportingTileMap,
  {
    id: 'textHeader',
    name: 'Section header',
    category: 'free-text',
    fetchAction: () => [],
    icon: 'dashboard--tiles-text',
    products: ['RealUserMonitoring', 'CrashReporting', 'APM'],
    defaultSettings: {
      TextSize: '14px',
      TextAlignment: Models.TextAlignment.LEFT,
      TextBold: null,
      TextItalic: null,
      TextUnderline: null,
      TextColor: '#333333',
      TileColor: '#ffffff',
    },
    metadata: {
      MinHeight: 1,
      DefaultWidth: 4,
      DefaultHeight: 1,
      SupportsSettings: true,
      HideHeader: true,
    },
    component: TextHeader,
  },
  {
    id: 'textBox',
    name: 'Textbox',
    category: 'free-text',
    fetchAction: () => [],
    icon: 'dashboard--tiles-text',
    products: ['RealUserMonitoring', 'CrashReporting', 'APM'],
    defaultSettings: {
      TextSize: '14px',
      TextAlignment: Models.TextAlignment.LEFT,
      TextBold: null,
      TextItalic: null,
      TextUnderline: null,
      Text: '',
    },
    metadata: {
      MinHeight: 3,
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
    },
    component: Textbox,
  },
  {
    id: 'crashFreeUserPercentage',
    name: 'Crash-free users',
    category: 'value',
    fetchAction,
    apiEndpoint: 'crashfreeuserpercentage',
    requirements: [crashReportingRequired, pulseRequired],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 3,
      SupportsSettings: true,
      DisplayMode: 'percent',
    },
    defaultSettings: {
      Applications: '',
      DateRange: DEFAULT_DATE_RANGE,
    },
    component: SingleValueTile,
    icon: 'dashboard--tiles-users',
    products: ['RealUserMonitoring', 'CrashReporting'],
    helpTips: {
      HelpTipText:
        'Only applications that have sent data to both Crash Reporting and RUM will be shown in this list.',
    },
  },
  {
    id: 'auditLog',
    name: 'Audit log',
    category: 'list',
    fetchAction,
    apiEndpoint: 'auditlog',
    requirements: [planLevelSBAndAbove],
    metadata: {
      DefaultWidth: 4,
      DefaultHeight: 6,
      SupportsSettings: true,
      Live: true,
    },
    defaultSettings: {
      Applications: '',
      Actions: [ActionFilterType.Application, ActionFilterType.Error, ActionFilterType.Plan].join(
        ',',
      ),
    },
    component: AuditLogTile,
    icon: 'dashboard--tiles-users',
    products: ['RealUserMonitoring', 'CrashReporting', 'APM'],
  },
];

export const tiles = createTileMap(...allTileMaps);
export let getTileInfo: Models.getTileInfo;
export let getDefinitionForTile: Models.getDefinitionForTile;
export let getComponentForTile: Models.getComponentForTile;
export let getFetchActionsForTile: Models.getFetchActionsForTile;
export let getIconForTile: Models.getIconForTile;
export let getApiEndpointForTile: Models.getApiEndpointForTile;
export let getTilesByTileCategory: Models.getTilesByTileCategory;

export function setTiles(newTiles: Models.TileMap) {
  assign(tiles, newTiles);

  getTileInfo = tiles.getTileInfo;
  getDefinitionForTile = tiles.getDefinitionForTile;
  getComponentForTile = tiles.getComponentForTile;
  getFetchActionsForTile = tiles.getFetchActionsForTile;
  getIconForTile = tiles.getIconForTile;
  getApiEndpointForTile = tiles.getApiEndpointForTile;
  getTilesByTileCategory = tiles.getTilesByTileCategory;
}

setTiles(tiles);

export const DUMMY_APP_ID = -1;

const onProton = window.location.pathname.indexOf('apm') > -1;

export const POLLING_TIME = 1000 * 60 * 5;

export * from './shared';
