/** @format **/

import React, { useState } from 'react';

import { UtilityIconButton } from 'components/ds/button';
import { Padding } from 'components/layout';

import { AccountMenu, Plans } from '../../accountMenu';
import { MobileListLinks } from '../links';
import { MobileLink } from '../link';
import { NavigationContainer, NavigationLevel } from './navigationLevels';
import { CurrentUserAvatar } from '../links/currentUserAvatar';
import { Icon } from 'components/icon';

enum MenuState {
  Root = 0,
  Account = 1,
  Plans = 2,
}

type Props = {
  closeMenu: () => void;
};

export const Menu: React.FunctionComponent<Props> = ({ closeMenu }) => {
  const [navState, setNavState] = useState<MenuState>(MenuState.Root);

  return (
    <NavigationContainer>
      <NavigationLevel currentLevel={navState} level={MenuState.Root}>
        <Padding top="8" left="8">
          <UtilityIconButton icon="menu" onClick={closeMenu} />
        </Padding>
        {MobileListLinks.map((generateLink, index) => (
          <div key={index}>{generateLink()}</div>
        ))}
        <MobileLink
          label="Account &amp; billing"
          active={false}
          icon={<CurrentUserAvatar />}
          iconRight={<Icon type="arrow-right" set="icomoon" dsColor={'blueGrey600'} />}
          onClick={() => setNavState(MenuState.Account)}
        />
      </NavigationLevel>
      <NavigationLevel currentLevel={navState} level={MenuState.Account}>
        <Padding top="8" left="8">
          <UtilityIconButton icon="arrow-back" onClick={() => setNavState(MenuState.Root)} />
        </Padding>
        <AccountMenu openPlanSettings={() => setNavState(MenuState.Plans)} />
      </NavigationLevel>
      <NavigationLevel currentLevel={navState} level={MenuState.Plans}>
        <Padding top="8" left="8">
          <UtilityIconButton icon="arrow-back" onClick={() => setNavState(MenuState.Account)} />
        </Padding>
        <Plans />
      </NavigationLevel>
    </NavigationContainer>
  );
};
