/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { useEscapeClose } from "hooks/useEscapeClose";

import * as Actions from './actions';
import * as Models from './models';

type StateProps = {};

type DispatchProps = {
  close: (id: string) => void;
};

type PassedProps = {} & Models.Popup;

type Props = PassedProps & StateProps & DispatchProps;

const DumbPopup: React.FunctionComponent<Props> = props => {
  useEscapeClose(() => props.close(props.id));

  const Component: any = props.component;
  return <Component {...props.props} {...props} />;
};

DumbPopup.defaultProps = {
  props: {},
};

/**
 Properties which are passed down to PopupComponents so that they can
 close themselves.
*/
export type PopupProps = {
  id: string;
  close: (id: string) => void;
};

export const Popup = connect<StateProps, DispatchProps, PassedProps>(
  undefined,
  (dispatch: Dispatch) => ({
    close: (id: string) => dispatch(Actions.close(id)),
  }),
)(DumbPopup);
