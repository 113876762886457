/** @format **/

import * as React from 'react';
import classnames from 'classnames';

import { Dashboard } from 'interfaces/dashboard';
import { Icon } from "components/icon";

import { Favourite } from './favouriteButton';
import { NoData } from './noData';

const PRIMARY_ID = 1;
const MAX_DASHBOARD_APP_IMAGES = 3;

interface Props {
  dashboards: Dashboard[];
  isFeatureGated: boolean;
  isOnTrial: boolean;
  openFeatureGatingModal: () => void;
}

interface DashboardItemProps {
  dashboard: Dashboard;
  isFeatureGated: boolean;
  isOnTrial: boolean;
  openFeatureGatingModal: () => void;
}

// Note: This is a port of existing dashboard logic and is in a need a of a little refactoring. You should not base your architecture off of this file.

function getApplicationImages(dash: Dashboard, listItemSize: boolean = false) {
  // Rewrite...

  const id = dash.id;
  const applicationCount = dash.applicationCount;
  const avatarUrls = dash.avatarUrls;
  const listItemClass = classnames('dashboard-application-images__item', {
    'dashboard-application-images__item--24': listItemSize,
  });
  const imgClass = classnames('dashboard-application-image', {
    'dashboard-application-image--24': listItemSize,
  });
  const textClass = classnames('dashboard-application-image-text', {
    'dashboard-application-image-text--24': listItemSize,
  });

  if (avatarUrls && applicationCount) {
    const apps = avatarUrls
      .filter((_url: any, index: any) => index < MAX_DASHBOARD_APP_IMAGES)
      .map((url: any, index: any) => {
        return (
          <li key={index} className={listItemClass}>
            <img src={url} alt="Application icon" className={imgClass} />
          </li>
        );
      });

    if (apps.length !== avatarUrls.length) {
      apps.push(
        <li key={apps.length + 1} className={listItemClass}>
          <span className={textClass}>{`+ ${applicationCount - MAX_DASHBOARD_APP_IMAGES}`}</span>
        </li>,
      );
    }

    return apps;
  } else if (id === PRIMARY_ID) {
    return (
      <li key="1" className={listItemClass}>
        <img
          src="/Content/Images/icons/avatars/2.png"
          alt="No applications for this dashboard"
          className={imgClass}
        />
      </li>
    );
  }

  return (
    <li key="1" className={listItemClass}>
      <span className={textClass}>0</span>
    </li>
  );
}

const DashboardListItem: React.FunctionComponent<DashboardItemProps> = props => {
  const appImages = getApplicationImages(props.dashboard, true);
  let url = `/tiledashboard/${props.dashboard.id.toString(36)}/display`;

  if (props.dashboard.isPrimary) {
    url = `/tiledashboard/${props.dashboard.planId.toString(36)}/primary`;
  }

  return (
    <li className="context-switcher-view-list__item context-switcher-view-list__item--list">
      <a
        href={!props.isFeatureGated && url}
        onClick={() => (props.isFeatureGated) && props.openFeatureGatingModal()}
        className={'context-list-item'}
        tabIndex={1}
        title={props.dashboard.name}
      >
        <div className="context-list-item__favourite">
          <Favourite
            dashboardId={props.dashboard.id}
            planId={props.dashboard.planId}
            name={props.dashboard.name}
            active={props.dashboard.isFavourite}
          />
        </div>
        <div className="context-list-item__image">
          <div className="dashboard-application-images-wrapper">
            <div className="crown-icon-wrapper-for-dashboard-app-images">
              {(props.isOnTrial || props.isFeatureGated) && (
                <Icon size={16} set={'icomoon'} type={'crown'} dsColor={'yellow700'} inline/>
              )}
            </div>
            <ul className="dashboard-application-images">{appImages}</ul>
          </div>
        </div>
        <p className="context-list-item__name context-list-item__name--dashboard">
          {props.dashboard.name}
        </p>
      </a>
    </li>
  );
};

const Dashboards: React.FunctionComponent<Props> = props => {
  const dashboards = props.dashboards.map(dash => {
    return (
      <DashboardListItem
        key={dash.id}
        dashboard={dash}
        isFeatureGated={props.isFeatureGated}
        isOnTrial={props.isOnTrial}
        openFeatureGatingModal={() => props.openFeatureGatingModal()}
      />
    );
  });

  const dashboardView = dashboards.length ? dashboards : <NoData text="No dashboards found" />;

  return <ul className="context-switcher-view-list">{dashboardView}</ul>;
};

export { Dashboards, getApplicationImages };
