/**
 * @prettier
 */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { debounce } from 'lodash';

type EllipseTextProps = {
  ellipseAtFullWidth: boolean;
};

const StyledEllipseText = (props: EllipseTextProps): IStyle => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'block',
  textOverflow: props.ellipseAtFullWidth ? 'ellipsis' : undefined,
});

const EllipseText = createComponent<EllipseTextProps>(StyledEllipseText, 'span', ['ref']);

type Props = {
  children: string;
  splitOn: string;
  ellipseFullWidth?: boolean;
};

type State = {};

export class TextEllipse extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.getContainer = this.getContainer.bind(this);
    this.getTextContainer = this.getTextContainer.bind(this);
    this.onResize = debounce(this.onResize.bind(this), 50);
  }

  static defaultProps: Partial<Props> = {
    ellipseFullWidth: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    this.calculateTextSize();
  }

  onResize() {
    this.calculateTextSize();
  }

  container: HTMLSpanElement;
  textContainer: HTMLSpanElement;

  getTextContainer(textContainer: HTMLSpanElement) {
    this.textContainer = textContainer;
    this.calculateTextSize();
  }

  getContainer(container: HTMLSpanElement): void {
    this.container = container;
    this.calculateTextSize();
  }

  calculateTextSize() {
    if (!this.textContainer || !this.container) {
      return;
    }

    const splitText = this.props.children.split(this.props.splitOn);
    const endWord = splitText.pop();

    const containerWidth = this.container.clientWidth;

    let width = this.textContainer.offsetWidth;
    while (width >= containerWidth && splitText.length > 0) {
      splitText.pop();
      this.textContainer.innerText = `${splitText.join(this.props.splitOn)}...${endWord}`;
      width = this.textContainer.offsetWidth;
    }
  }

  render() {
    return (
      <EllipseText innerRef={this.getContainer} ellipseAtFullWidth={this.props.ellipseFullWidth}>
        <span ref={this.getTextContainer} title={this.props.children}>
          {this.props.children}
        </span>
      </EllipseText>
    );
  }
}
