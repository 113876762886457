/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import * as React from 'react';
import { DefaultProps } from './base';
import { BaseWrapper, BaseWrapperProps } from './base/wrapper';
import { BaseTextProps, BaseText } from './base/text';
import { BaseIconProps, BaseIcon } from './base/icon';
import { BaseTrackProps, BaseTrack } from './track';

export class Tag extends React.Component<BaseWrapperProps> {
  render() {
    const { children, ...props } = this.props;
    return <BaseWrapper {...props}>{children}</BaseWrapper>;
  }
}

type DefaultTagTextPropKeys =
  | 'textColor'
  | 'disabledTextColor'
  | 'backgroundColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | 'disabledBackgroundColor'
  | keyof BaseTextProps;

export class TagText extends React.Component<BaseTextProps> {
  static defaultProps: Pick<BaseTextProps, DefaultTagTextPropKeys> = {
    ...DefaultProps,
    textColor: 'white',
    fontWeight: 600,
    underline: false,
    disabledTextColor: 'grey400',
    disabledBackgroundColor: 'grey200',
    backgroundColor: 'blue1000',
    hoverBackgroundColor: 'blue1200',
    pressedBackgroundColor: 'blue1300',
  };

  render() {
    const { children, ...props } = this.props;
    return <BaseText {...props}>{children}</BaseText>;
  }
}

type DefaultTagIconPropKeys =
  | 'textColor'
  | 'disabledTextColor'
  | 'backgroundColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | 'disabledBackgroundColor'
  | keyof BaseIconProps;

export class TagIcon extends React.Component<BaseIconProps> {
  static defaultProps: Pick<BaseIconProps, DefaultTagIconPropKeys> = {
    ...DefaultProps,
    icon: 'cross',
    textColor: 'white',
    disabledBackgroundColor: 'grey200',
    disabledTextColor: 'grey200',
    backgroundColor: 'blue1000',
    hoverBackgroundColor: 'blue1200',
    pressedBackgroundColor: 'blue1300',
  };

  render() {
    return <BaseIcon {...this.props} />;
  }
}

type DefaultTagCloseIconPropKeys =
  | 'textColor'
  | 'disabledTextColor'
  | 'backgroundColor'
  | 'hoverBackgroundColor'
  | 'pressedBackgroundColor'
  | 'disabledBackgroundColor'
  | keyof BaseIconProps;

export class TagCloseIcon extends React.Component<BaseIconProps> {
  static defaultProps: Pick<BaseIconProps, DefaultTagCloseIconPropKeys> = {
    ...DefaultProps,
    icon: 'cross',
    textColor: 'white',
    disabledBackgroundColor: 'grey200',
    disabledTextColor: 'grey200',
    backgroundColor: 'blue1000',
    hoverBackgroundColor: 'blue1200',
    pressedBackgroundColor: 'blue1300',
  };

  render() {
    return <BaseIcon icon="cross" {...this.props} />;
  }
}

export class TagTrack extends React.Component<BaseTrackProps> {
  static defaultProps: Pick<BaseTrackProps, 'backgroundColor'> = {
    backgroundColor: 'blueGrey50',
  };

  render() {
    const { children, ...props } = this.props;
    return <BaseTrack {...props}>{children}</BaseTrack>;
  }
}
