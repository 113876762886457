/**
 * @prettier
 */

import React from 'react';
import { isNil, first, get } from 'lodash';

import { CHART_LINE_COLOR, getMarkLine } from 'utils/echarts/apdex';

import { CountChartTypes, CountOverTime } from 'components/charts';
import { getColorForApdexScore, NO_DATA_ITEM_NAME } from 'utils/echarts/apdex';
import * as charting from 'utils/echarts';

import { ApdexChartPayload } from '../../../actions';
import { ChartTileLoading } from '../loading/chart';
import { TileDataProvider, Props as ProviderProps } from '../dataProvider';
import { NoData } from '../noData';

const Chart: React.FunctionComponent<ProviderProps> = props => {
  const data = props.data as ApdexChartPayload;

  if (data.Data.length === 0) {
    return <NoData />;
  }

  const periods = data.Times.map(period => {
    const validUtcFormatted = period.indexOf('Z') > -1;
    return validUtcFormatted ? period : `${period}Z`;
  });

  const collection = data.Data.length > 0 ? first(data.Data).Collection : null;

  const count = !isNil(collection) ? collection.map(c => c.Score) : null;

  const tooltipRenderFunc = (params: any, interval: charting.Intervals) => {
    return {
      date: charting.formatUtcTooltipDate(params[0].name, interval),
      series: params.map((s: any) => ({
        name: s.seriesName,
        value: get(s, 'value', NO_DATA_ITEM_NAME).toLocaleString(),
        colorcode: getColorForApdexScore(s.value),
      })),
    };
  };

  return (
    <CountOverTime
      height={'100%'}
      className="apdex-echart-background"
      interval={data.Interval}
      data={{
        count,
        periods,
      }}
      chartType={CountChartTypes.LINE}
      color={CHART_LINE_COLOR}
      groupChart={props.groupCharts}
      allowDateRangeSelect={props.dragToZoom}
      tooltipRenderer={tooltipRenderFunc}
      customOptions={{
        yAxis: [
          {
            splitLine: false,
            min: 0,
            max: 1,
            interval: 0.2,
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
      }}
      label="Score"
      legendItems={[
        { colorCode: '#DF1F00', label: 'Unacceptable' },
        { colorCode: '#FE9E00', label: 'Poor' },
        { colorCode: '#FFEB3B', label: 'Fair' },
        { colorCode: '#CDDC39', label: 'Good' },
        { colorCode: '#4CAF50', label: 'Excellent' },
      ]}
      onDashboard
      markLine={getMarkLine(collection)}
    />
  );
};

const ConnectedTile = TileDataProvider(Chart, ChartTileLoading);

export { ConnectedTile as ApdexChart };
