/**
 * @prettier
 */

import * as React from 'react';

import { PopupProps as ModalProps } from 'app/popups/models';

import { Center, WIDTHS } from 'components/center';
import { ErrorBoundary } from 'components/errorBoundary';

import { Backdrop } from './backdrop';
import { Container } from './container';

export type ModalBaseProps = {
  width?: WIDTHS;
  closeOnBackdropClick?: boolean;
  backdropColor?: 'white' | 'black';
};

type Props = ModalBaseProps & ModalProps;

type State = {
  closing: boolean;
};

export class ModalBase extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.close = this.close.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onBackdropClick = this.onBackdropClick.bind(this);
  }

  static defaultProps: Partial<Props> = {
    width: 640,
    closeOnBackdropClick: true,
  };

  state: State = {
    closing: false,
  };

  timeoutId: number;

  close() {
    if (this.state.closing) {
      // Double click/closing will activate immediately
      this.closeImmediately();
      return;
    }

    this.setState({ closing: true });
    this.timeoutId = window.setTimeout(() => {
      this.props.close(this.props.id);
    }, 250);
  }

  closeImmediately() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.props.close(this.props.id);
  }

  onClick() {
    this.preventCloseOnClick = true;
  }

  onBackdropClick() {
    if (!this.preventCloseOnClick && this.props.closeOnBackdropClick) {
      this.close();
    }

    this.preventCloseOnClick = false;
  }

  preventCloseOnClick: boolean = false;

  render() {
    return (
      <Backdrop
        onClick={this.onBackdropClick}
        closing={this.state.closing}
        backdropColor={this.props.backdropColor}
      >
        <Center width={this.props.width}>
          <Container onClick={this.onClick} closing={this.state.closing}>
            <ErrorBoundary>{this.props.children}</ErrorBoundary>
          </Container>
        </Center>
      </Backdrop>
    );
  }
}
