/**
 * @prettier
 */
/* tslint:disable:max-file-line-count */

/**
 * Design system based colours
 */

export type BLUE_TYPES = {
  blue100: '#E8F4FD';
  blueLA100: '#80D8FF';
  blue200: '#D0E8FB';
  blue300: '#B9DDFA';
  blue400: '#A1D2F8';
  blueL400: '#29B6F6';
  blue500: '#8AC7F7';
  blue600: '#72BBF5';
  blueL600: '#039BE5';
  blue700: '#5BB0F3';
  blue800: '#43A5F2';
  blue900: '#2C9AF0';
  blue1000: '#158FEF';
  blue1100: '#1280D6';
  blue1200: '#1072BF';
  blue1300: '#0E63A6';
  blue1400: '#0C558F';
  blue1500: '#0A4777';
  blue1600: '#08395F';
  blue1700: '#062A47';
  blueRG1000: '#158FEF';
  blueRG1050: '#0478D2';
  blueRG1100: '#1280D6';
  blueRG1200: '#1072BF';
};

export const BLUE: BLUE_TYPES = {
  blue100: '#E8F4FD',
  blueLA100: '#80D8FF',
  blue200: '#D0E8FB',
  blue300: '#B9DDFA',
  blue400: '#A1D2F8',
  blueL400: '#29B6F6',
  blue500: '#8AC7F7',
  blue600: '#72BBF5',
  blueL600: '#039BE5',
  blue700: '#5BB0F3',
  blue800: '#43A5F2',
  blue900: '#2C9AF0',
  blue1000: '#158FEF',
  blue1100: '#1280D6',
  blue1200: '#1072BF',
  blue1300: '#0E63A6',
  blue1400: '#0C558F',
  blue1500: '#0A4777',
  blue1600: '#08395F',
  blue1700: '#062A47',
  blueRG1000: '#158FEF',
  blueRG1050: '#0478D2',
  blueRG1100: '#1280D6',
  blueRG1200: '#1072BF',
};

export type BLUE_GREY_TYPES = {
  blueGrey5: '#F8F9FA';
  blueGrey10: '#F4F6F8';
  blueGrey20: '#F0F2F4';
  blueGrey50: '#ECEFF1';
  blueGrey75: '#E2E8EB';
  blueGrey100: '#CFD8DC';
  blueGrey200: '#B0BEC5';
  blueGrey300: '#90A4AE';
  blueGrey400: '#78909C';
  blueGrey500: '#607D8B';
  blueGrey600: '#546E7A';
  blueGrey700: '#455A64';
  blueGrey800: '#37474F';
  blueGrey900: '#263238';
};

export const BLUE_GREY: BLUE_GREY_TYPES = {
  blueGrey5: '#F8F9FA',
  blueGrey10: '#F4F6F8',
  blueGrey20: '#F0F2F4',
  blueGrey50: '#ECEFF1',
  blueGrey75: '#E2E8EB',
  blueGrey100: '#CFD8DC',
  blueGrey200: '#B0BEC5',
  blueGrey300: '#90A4AE',
  blueGrey400: '#78909C',
  blueGrey500: '#607D8B',
  blueGrey600: '#546E7A',
  blueGrey700: '#455A64',
  blueGrey800: '#37474F',
  blueGrey900: '#263238',
};

export type GREY_TYPES = {
  grey50: '#FAFAFA';
  grey100: '#F5F5F5';
  grey200: '#EEEEEE';
  grey300: '#E0E0E0';
  grey350: '#DDDDDD';
  grey400: '#BDBDBD';
  grey500: '#9E9E9E';
  grey600: '#757575';
  grey700: '#616161';
  grey800: '#424242';
  grey900: '#212121';
};

export const GREY: GREY_TYPES = {
  grey50: '#FAFAFA',
  grey100: '#F5F5F5',
  grey200: '#EEEEEE',
  grey300: '#E0E0E0',
  grey350: '#DDDDDD',
  grey400: '#BDBDBD',
  grey500: '#9E9E9E',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#424242',
  grey900: '#212121',
};

export type CYAN_TYPES = {
  cyan100: '#B2EBF2';
  cyan200: '#80DEEA';
  cyan300: '#4DD0E1';
  cyan400: '#26C6DA';
  cyan500: '#00BCD4';
  cyan600: '#00ACC1';
  cyan700: '#0097A7';
  cyan800: '#00838F';
  cyan900: '#006064';
};

export const CYAN: CYAN_TYPES = {
  cyan100: '#B2EBF2',
  cyan200: '#80DEEA',
  cyan300: '#4DD0E1',
  cyan400: '#26C6DA',
  cyan500: '#00BCD4',
  cyan600: '#00ACC1',
  cyan700: '#0097A7',
  cyan800: '#00838F',
  cyan900: '#006064',
};

export type TEAL_TYPES = {
  teal50: '#E0F2F1';
  teal100: '#B2DFDB';
  teal200: '#80CBC4';
  teal300: '#4DB6AC';
  teal400: '#28B3AA';
  teal500: '#00A59A';
  teal600: '#00998E';
  teal700: '#008A7E';
  teal800: '#00695C';
  teal900: '#004D40';
};

export const TEAL: TEAL_TYPES = {
  teal50: '#E0F2F1',
  teal100: '#B2DFDB',
  teal200: '#80CBC4',
  teal300: '#4DB6AC',
  teal400: '#28B3AA',
  teal500: '#00A59A',
  teal600: '#00998E',
  teal700: '#008A7E',
  teal800: '#00695C',
  teal900: '#004D40',
};

export type RED_TYPES = {
  red50: '#FFEBEE';
  red100: '#FFCDD2';
  red200: '#EF9A9A';
  red300: '#E57373';
  red400: '#EF5350';
  red500: '#F44336';
  red600: '#E53935';
  red700: '#D32F2F';
  red800: '#C62828';
  red900: '#B71C1C';
};

export const RED: RED_TYPES = {
  red50: '#FFEBEE',
  red100: '#FFCDD2',
  red200: '#EF9A9A',
  red300: '#E57373',
  red400: '#EF5350',
  red500: '#F44336',
  red600: '#E53935',
  red700: '#D32F2F',
  red800: '#C62828',
  red900: '#B71C1C',
};

export type LIME_TYPES = {
  lime400: '#D4E157';
};

export const LIME: LIME_TYPES = {
  lime400: '#D4E157',
};

export type GREEN_TYPES = {
  green50: '#E8F5E9';
  green100: '#C8E6C9';
  green200: '#A5D6A7';
  green300: '#81C784';
  green400: '#66BB6A';
  green500: '#4CAF50';
  greenL500: '#8BC34A';
  green600: '#43A047';
  green700: '#388E3C';
  green800: '#2E7D32';
  green900: '#1B5E20';
};

export const GREEN: GREEN_TYPES = {
  green50: '#E8F5E9',
  green100: '#C8E6C9',
  green200: '#A5D6A7',
  green300: '#81C784',
  green400: '#66BB6A',
  green500: '#4CAF50',
  greenL500: '#8BC34A',
  green600: '#43A047',
  green700: '#388E3C',
  green800: '#2E7D32',
  green900: '#1B5E20',
};

export type GREENL_TYPES = {
  greenL500: '#8BC34A';
};

export const GREENL: GREENL_TYPES = {
  greenL500: '#8BC34A',
};

export type PURPLE_TYPES = {
  purple50: '#EDE7F6';
  purple100: '#D1C4E9';
  purple200: '#B39DDB';
  purple300: '#A78CD7';
  purple400: '#9170CD';
  purpleL400: '#AB47BC';
  purple500: '#7B54C4';
  purple600: '#724EBF';
  purple700: '#6545B7';
  purpleD700: '#512DA8';
  purpleDA700: '#651FFF';
  purple800: '#583EB0';
  purple900: '#311B92';
};

export const PURPLE: PURPLE_TYPES = {
  purple50: '#EDE7F6',
  purple100: '#D1C4E9',
  purple200: '#B39DDB',
  purple300: '#A78CD7',
  purple400: '#9170CD',
  purpleL400: '#AB47BC',
  purple500: '#7B54C4',
  purple600: '#724EBF',
  purple700: '#6545B7',
  purpleD700: '#512DA8',
  purpleDA700: '#651FFF',
  purple800: '#583EB0',
  purple900: '#311B92',
};

export type PINK_TYPES = {
  pink100: '#F8BBD0';
  pink300: '#F06292';
};

export const PINK: PINK_TYPES = {
  pink100: '#F8BBD0',
  pink300: '#F06292',
};

export type INDIGO_TYPES = {
  indigo600: '#3F51B5';
};

export const INDIGO: INDIGO_TYPES = {
  indigo600: '#3F51B5',
};

export type YELLOW_TYPES = {
  yellow300: '#FFF176';
  yellow700: '#FBC02D';
};

export const YELLOW: YELLOW_TYPES = {
  yellow300: '#FFF176',
  yellow700: '#FBC02D',
};

export type ORANGE_TYPES = {
  orange400: '#FFA726';
  orangeD400: '#FFA726';
};

export const ORANGE: ORANGE_TYPES = {
  orange400: '#FFA726',
  orangeD400: '#FFA726',
};

export type AMBER_TYPES = {
  amber50: '#FFF8E1';
  amber75: '#FFF2CB';
  amber100: '#FFECB3';
  amber200: '#FFE082';
  amber300: '#FFD54F';
  amber400: '#FFCA28';
  amber500: '#FFC107';
  amber600: '#FFB300';
  amber700: '#FFA000';
  amber800: '#FF8F00';
  amber900: '#FF8400';
};

export const AMBER: AMBER_TYPES = {
  amber50: '#FFF8E1',
  amber75: '#FFF2CB',
  amber100: '#FFECB3',
  amber200: '#FFE082',
  amber300: '#FFD54F',
  amber400: '#FFCA28',
  amber500: '#FFC107',
  amber600: '#FFB300',
  amber700: '#FFA000',
  amber800: '#FF8F00',
  amber900: '#FF8400',
};

export type SEMANTIC_TYPES = {
  attention: BLUE_TYPES['blue1000'];
  attentionLight: BLUE_TYPES['blue100'];
  warning: AMBER_TYPES['amber700'];
  warningLight: AMBER_TYPES['amber50'];
  success: GREEN_TYPES['green500'];
  successLight: GREEN_TYPES['green50'];
  danger: RED_TYPES['red600'];
  dangerLight: RED_TYPES['red50'];
};

export const SEMANTIC: SEMANTIC_TYPES = {
  attention: BLUE.blue1000,
  attentionLight: BLUE.blue100,
  warning: AMBER.amber700,
  warningLight: AMBER.amber50,
  success: GREEN.green500,
  successLight: GREEN.green50,
  danger: RED.red600,
  dangerLight: RED.red50,
};

export type SPECIAL_TYPES = {
  white: '#FFFFFF';
  transparent: 'transparent';
  black: '#000000';
  body: '#F5F7F8';
};

export const SPECIAL: SPECIAL_TYPES = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  body: '#F5F7F8',
};

export type COLORS =
  | keyof AMBER_TYPES
  | keyof PINK_TYPES
  | keyof INDIGO_TYPES
  | keyof LIME_TYPES
  | keyof YELLOW_TYPES
  | keyof ORANGE_TYPES
  | keyof PURPLE_TYPES
  | keyof GREEN_TYPES
  | keyof RED_TYPES
  | keyof TEAL_TYPES
  | keyof CYAN_TYPES
  | keyof GREY_TYPES
  | keyof BLUE_TYPES
  | keyof BLUE_GREY_TYPES
  | keyof SPECIAL_TYPES;
