/** @format */

import { utc, Moment } from 'moment-timezone';

import { DateInput } from 'utils/types';
import { getTimeZoneName } from 'selectors/time';

function convert(date: DateInput): Moment {
  const timeZoneName = getTimeZoneName();

  const m = utc(date);

  if (timeZoneName) {
    return m.tz(timeZoneName);
  }

  return m;
}

export const convertUtcToUsersMoment = convert;

export const convertUtcToUsersDate = (date: DateInput): Date => {
  const m = convert(date);
  return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), m.second(), m.millisecond());
};
