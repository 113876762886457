/** @format */

import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { reducerUpdate } from 'utils/reducerUpdate';
import * as Actions from '../actions';

export type SidebarStateAtom = {
  accountMenuOpen: boolean;
  slideoutMenuOpen: boolean;
};

const defaultState = {
  accountMenuOpen: false,
  slideoutMenuOpen: false,
};

export const SidebarReducer = reducerWithInitialState<SidebarStateAtom>(defaultState)
  .case(Actions.setAccountMenu, (state, accountMenuOpen) => {
    return reducerUpdate(state, { accountMenuOpen });
  })
  .case(Actions.setSlideoutMenu, (state, slideoutMenuOpen) => {
    return reducerUpdate(state, { slideoutMenuOpen });
  });
