/** @format */

import * as React from 'react';

import { Padding } from 'components/layout';

interface Props {
  noDataMessage?: string;
  cols: number;
}

export const TableNoData: React.FunctionComponent<Props> = ({ noDataMessage, cols }) => (
  <tr>
    <td colSpan={cols}>
      <Padding top="24" bottom="24">
        <p className="text-center">
          <strong className="it-table-no-data-message">{noDataMessage}</strong>
        </p>
      </Padding>
    </td>
  </tr>
);

TableNoData.defaultProps = {
  noDataMessage: 'Table contains no data',
};
