/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ModalProps } from 'components/modal';
import { Checkbox } from 'components/ds/selection';
import { Padding } from 'components/layout';
import {
  Module,
  ModuleBody,
  ModuleFooterButtonGroup,
  ModuleFooterOnRight,
  ModuleHeader,
} from 'components/ds/module';
import { Dialog } from 'components/ds/dialog';
import { EmphasisButton, TextButton } from 'components/ds/button';
import { Flex } from 'components/flex';
import { addE2EIdentifier } from 'utils/end-to-end';

type DispatchProps = {
  discardChanges: () => void;
};

export type ConfirmModalProps = {
  headerText: string;
  confirmText: string;
  confirmButtonText: string;
  callback?: (dispatch: Dispatch) => void;
  doNotShowAgain?: boolean;
};

type Props = {} & DispatchProps & ConfirmModalProps & ModalProps;

type State = {
  confirmed: boolean;
  doNotShowAgainChecked?: boolean;
};

export class DumbConfirmationModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onDoNotShowAgain = this.onDoNotShowAgain.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  state: State = {
    confirmed: false,
    doNotShowAgainChecked: false,
  };

  onConfirm(confirmed: boolean) {
    this.setState({
      confirmed,
    });
  }

  onDoNotShowAgain(doNotShowAgainChecked: boolean) {
    this.setState({
      doNotShowAgainChecked,
    });
  }

  onSubmit() {
    this.props.discardChanges();
    this.saveSkipRender();
    this.cancel();
  }

  cancel() {
    this.props.close(this.props.id);
  }

  saveSkipRender() {
    if (!this.checkSkipRender()) {
      window.localStorage.setItem(
        `skipModal:${this.props.headerText}`,
        this.state.doNotShowAgainChecked.toString(),
      );
    }
  }

  checkSkipRender() {
    return window.localStorage.getItem(`skipModal:${this.props.headerText}`) === 'true';
  }

  render() {
    if (this.checkSkipRender()) {
      this.onSubmit();
      return null;
    }

    return (
      <Dialog {...this.props}>
        <Module forDialog>
          <ModuleHeader heading={this.props.headerText} />
          <ModuleBody borderBottom>
            <Flex align="center">
              <Checkbox
                controlId={addE2EIdentifier('e2e-confirm-modal-checkbox')}
                checked={this.state.confirmed}
                onChange={e => this.onConfirm(e.currentTarget.checked)}
              />
              <span>{this.props.confirmText}</span>
            </Flex>
            {this.props.doNotShowAgain && (
              <Padding top="16">
                <Flex align="center">
                  <Checkbox
                    checked={this.state.doNotShowAgainChecked}
                    onChange={e => this.onDoNotShowAgain(e.currentTarget.checked)}
                  />
                  <span>Do not show this message again.</span>
                </Flex>
              </Padding>
            )}
          </ModuleBody>
          <ModuleFooterOnRight forDialog>
            <ModuleFooterButtonGroup>
              <TextButton onClick={this.cancel}>Cancel</TextButton>
              <EmphasisButton
                disabled={!this.state.confirmed}
                onClick={this.onSubmit}
                id={addE2EIdentifier('e2e-confirm-modal-button')}
              >
                {this.props.confirmButtonText}
              </EmphasisButton>
            </ModuleFooterButtonGroup>
          </ModuleFooterOnRight>
        </Module>
      </Dialog>
    );
  }
}

export const ConfirmModal = connect<{}, DispatchProps, ConfirmModalProps & ModalProps>(
  undefined,
  (dispatch: Dispatch, props) => ({
    discardChanges: () => {
      if (props.callback) {
        props.callback(dispatch);
      }
    },
  }),
)(DumbConfirmationModal);
