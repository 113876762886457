/** @format **/

import { TileCategories } from '../../models';

export function getHumanType(type: TileCategories) {
  switch (type) {
    case 'free-text':
      return 'Text';
    case 'chart':
      return 'Chart';
    case 'list':
      return 'List';
    case 'value':
      return 'Value';
  }
}
