/** @format **/

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classnames from 'classnames';

import { AppState } from 'interfaces/appState';
import { favouriteApp, favouriteDashboard } from 'app/actions/pageData';

import { Icon } from 'components/icon';
import { trackLinkClick } from 'components/snowplow';

type StateProps = {};

type DispatchProps = {
  onFavourite: () => void;
};

type PassedProps = {
  name: string;
  active: boolean;
  applicationId?: number;
  dashboardId?: number;
  planId?: number;
};

type Props = {} & StateProps & PassedProps & DispatchProps;

const DumbFavourite: React.FunctionComponent<Props> = props => {
  const iconType = props.active ? 'favourite-solid' : 'favourite-stroke';

  const classes = classnames('context-favourite-button', {
    'context-favourite-button--favourite': props.active,
  });

  const title = props.active ? `Unfavourite ${props.name}` : `Favourite ${props.name}`;

  const onFavourite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    props.onFavourite();
  };

  return (
    <div className={classes} title={title} onClick={onFavourite}>
      <Icon type={iconType} set="icomoon" size={12} />
    </div>
  );
};

export const DisabledFavourite: React.FunctionComponent<PassedProps> = props => {
  const iconType = props.active ? 'favourite-solid' : 'favourite-stroke';

  const classes = classnames('context-favourite-button__disabled', {
    'context-favourite-button__disabled--favourite': props.active,
  });

  const title = props.active ? `Unfavourite ${props.name}` : `Favourite ${props.name}`;

  return (
    <div className={classes} title={title}>
      <Icon type={iconType} set="icomoon" size={12} />
    </div>
  );
};

export const Favourite = connect<StateProps, DispatchProps, PassedProps>(
  undefined,
  (dispatch: Dispatch, passedProps: PassedProps) => ({
    onFavourite: () => {
      if (passedProps.applicationId) {
        dispatch(favouriteApp({
          id: passedProps.applicationId,
          favourite: !passedProps.active,
        }) as any);
        trackLinkClick('sp-app-chrome-switcher-clickFavouriteApplication');
      } else {
        dispatch(favouriteDashboard({
          dashboardId: passedProps.dashboardId,
          planId: passedProps.planId,
          favourite: !passedProps.active,
        }) as any);
        trackLinkClick('sp-app-chrome-switcher-clickFavouriteDashboard');
      }
    },
  }),
)(DumbFavourite);
