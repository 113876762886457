/** @format */

import * as React from 'react';
import { times } from 'lodash';

const LoadingListItem: React.FunctionComponent<{ opacity: number }> = ({ opacity }) => (
  <li
    className="active-list-row active-list-row--white active-list-row--border-between-only"
    style={{ opacity }}
  >
    <div className="active-list-cell active-list-cell--space-left-none active-list-cell--60">
      <div className="avatar-layout">
        <div className="avatar-layout__main">
          <div className="stencil-ui stencil-ui--zero-margin stencil-ui--square" />
        </div>
        <div className="avatar-layout__secondary">
          <div className="stencil-ui stencil-ui--zero-margin stencil-ui--circle-small stencil-ui--border" />
        </div>
      </div>
    </div>

    <div className="active-list-cell">
      <div className="dashboard-list-component">
        <div className="dashboard-list-component__audit-action">
          <div className="stencil-ui stencil-ui--zero-margin stencil-ui--text" />
        </div>
        <div className="dashboard-list-component__audit-url">
          <div className="stencil-ui stencil-ui--zero-margin stencil-ui--text stencil-ui--full-width" />
        </div>
        <div className="dashboard-list-component__app">
          <div className="stencil-ui stencil-ui--zero-margin stencil-ui--text-small" />
        </div>
      </div>
    </div>
  </li>
);

export const AuditLogListLoading: React.FunctionComponent<{}> = () => {
  const listItemsCount = 6;
  const opacityDelta = 1 / listItemsCount;
  const items = times(listItemsCount, i => (
    <LoadingListItem opacity={opacityDelta * (listItemsCount - i)} key={i} />
  ));

  return <ul className="active-list">{items}</ul>;
};
