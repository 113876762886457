import { DateRange, DateRangeParam, Granularities, Intervals } from './types';

export function paramToDateRange(range: DateRangeParam) {
  switch (range) {
    case '1d':
      return DateRange.OneDay;
    case '7d':
      return DateRange.SevenDays;
    case '30d':
      return DateRange.ThirtyDays;
    default:
      const _ec: never = range;
  }
}

export function dateRangeToParam(range: DateRange): DateRangeParam {
  switch (range) {
    case DateRange.OneDay:
      return '1d';
    case DateRange.SevenDays:
      return '7d';
    case DateRange.ThirtyDays:
      return '30d';
    default:
      const _ec: never = range;
  }
}

export function granularityToInterval(granularity: Granularities): Intervals {
  switch (granularity) {
    case Granularities.Minute:
      return '1m';
    case Granularities.TenMinute:
      return '10m';
    case Granularities.Hour:
      return '1h';
    case Granularities.Day:
      return '1d';
    case Granularities.Week:
      return '1w';
  }
}

export function intervalToGranularity(interval: Intervals): Granularities {
  switch (interval) {
    case '1m':
      return Granularities.Minute;
    case '10m':
      return Granularities.TenMinute;
    case '1h':
      return Granularities.Hour;
    case '1d':
      return Granularities.Day;
    case '1w':
      return Granularities.Week;
  }
}
