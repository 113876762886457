/** @format */

export const lowColor = '#f73415';
export const mediumColor = '#848482';
export const highColor = '#1cc526';

export const firstCompareColor = '#479af0';
export const secondCompareColor = '#f6b458';

export const purple = '#8a4dc7';

export const mobile = '#8a4dc7';
export const desktop = '#fb7a31';

export const chrome = 'gold';
export const firefox = '#fb7a31';
export const ie = '#1cb6c0';
export const safari = '#8a4dc7';
export const opera = '#cc0f16';
export const other = '#cfcfcd';

// Used by Pulse for mobile versions, devices and operating systems
export const color1 = '#8a4dc7';
export const color2 = '#cc0f16';
export const color3 = '#fb7a31';
export const color4 = 'gold';
export const color5 = '#1cb6c0';
export const color6 = '#5a5a58';

export const borderGrey = '#d3d3d3';

export const symbolSize = 5;

export const AxisPointerLine = {
  lineStyle: {
    color: '#cfcfcd',
  },
  animation: false,
};

export const AxisPointerShadow = {
  type: 'shadow',
  shadowStyle: {
    color: 'rgba(230,230,230,0.3)',
  },
  animation: false,
};
