/**
 * @prettier
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'interfaces/appState';

import { DashboardSelection } from '../dashboardSelection';
import { Flex } from 'components/flex';
import { Width } from 'components/width';
import { Padding, Margin } from 'components/layout';
import { getTextAlignmentClass } from 'components/text';
import {
  getActivePlanForSwitcher,
  getAppSwitcherActivePlanId,
  hasReachedDashboardLimit,
} from 'app/selectors/chrome';
import { getFeatureFlags, getPlanId, getPlanIdentifier } from 'app/selectors/configuration';
import { DashboardTypes } from 'modules/dashboard/models';
import { UpgradePaywall } from 'app/chrome/classic/switcher/components/upgradePaywall';

type StateProps = {
  activePlanId: number;
  canAccessTimeboard: boolean;
  hasReachedDashboardLimit: boolean;
};

export const UnconnectedCreateDashboardModule: React.FunctionComponent<StateProps> = ({
  activePlanId,
  canAccessTimeboard,
  hasReachedDashboardLimit,
}) => {
  if (hasReachedDashboardLimit) {
    return (
      <UpgradePaywall
        buttonUrl={`/plan/${activePlanId.toString(36)}`}
        buttonText={'Upgrade'}
        title={'You’ve reached your dashboard limit'}
        subTitle={'Upgrade to add more dashboards.'}
      />
    );
  }

  return (
    <>
      <Padding top="8" bottom="16">
        <Margin bottom="32">
          <h2 className={getTextAlignmentClass('center')}>Select dashboard type</h2>
        </Margin>
        <Flex align="center" direction="row" justify="space-between">
          <Width width={'50'} units="%">
            <Padding right="24">
              <DashboardSelection
                title="Screen board"
                features={[
                  'Custom application and time range for individual tiles',
                  'Best for a high-level view of application metrics',
                ]}
                comingSoon={false}
                greyscaleGraphicUrl={'screenboard.svg'}
                colorGraphicUrl={'screenboard-hovered.svg'}
                createUrl={`/tiledashboard/${activePlanId.toString(36)}/create?type=${
                  DashboardTypes.Screenboard
                }`}
              />
            </Padding>
          </Width>
          <Width width={'50'} units="%">
            <Padding left="24">
              <DashboardSelection
                title="Time board"
                features={[
                  'Apply top-level filters that all tiles will conform to',
                  'Have a time synchronous view of your application metrics',
                ]}
                comingSoon={!canAccessTimeboard}
                greyscaleGraphicUrl={'timeboard.svg'}
                colorGraphicUrl={'timeboard-hovered.svg'}
                createUrl={`/tiledashboard/${activePlanId.toString(36)}/create?type=${
                  DashboardTypes.Timeboard
                }`}
              />
            </Padding>
          </Width>
        </Flex>
      </Padding>
    </>
  );
};

/*
 * Used to only show the timeboard creation for plans with the feature flag enabled
 * AND for current plan the user is viewing.
 *
 * This is due to feature flag settings only being for the current plan of the app/dashboard/area you are viewing
 * but you can create dashboards for other plans.
 *
 * This is to be removed once we have timeboards released
 */
const canAccessTimeboard = (state: AppState) => {
  const hasFeatureFlag = getFeatureFlags(state).indexOf('TimeboardDashboards') > -1;
  const appSwitcherIsOnApplicationsCurrentPlan =
    getAppSwitcherActivePlanId(state) === parseInt(getPlanIdentifier(state), 36);

  return hasFeatureFlag && appSwitcherIsOnApplicationsCurrentPlan;
};

const ConnectedCreateDashboardModule = connect<StateProps, {}, {}>((state: AppState) => ({
  activePlanId: getAppSwitcherActivePlanId(state),
  canAccessTimeboard: canAccessTimeboard(state),
  hasReachedDashboardLimit: hasReachedDashboardLimit(state),
}))(UnconnectedCreateDashboardModule);

export { ConnectedCreateDashboardModule as CreateDashboardModule };
