/** @format */

import * as React from 'react';

import { Icon } from 'components/icon';

interface State {
  text: string;
}

export interface SearchProps {
  onInput: (text: string) => void;
  onSubmit?: () => void;
  placeholder?: string;
  focus?: boolean;
  text?: string;
}

export class Search extends React.Component<SearchProps, State> {
  constructor(props: SearchProps) {
    super(props);

    this.state = { text: props.text ? props.text : '' };

    this.emptySearch = this.emptySearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps: SearchProps) {
    if (nextProps.text !== this.props.text) {
      this.setState({ text: nextProps.text });
    }
    if (nextProps.focus !== this.props.focus && nextProps.focus) {
      this.refocus = true;
    }
  }

  public state: State = {
    text: '',
  };

  public static defaultProps: Partial<SearchProps> = {
    placeholder: 'Search...',
    focus: false,
  };

  searchInput: HTMLInputElement;

  emptySearch() {
    this.setState(() => {
      return { text: '' };
    });
    this.searchInput.focus();
    this.props.onInput('');

    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  onSearch(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') {
      this.emptySearch();
    } else if (e.key === 'Enter') {
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;
    this.setState({ text: text });
    this.props.onInput(text);
  }

  componentDidMount() {
    if (this.props.focus) {
      this.searchInput.focus();
      if (document.activeElement != this.searchInput) {
        this.refocus = true;
      }
    }
  }

  refocus: boolean = false;

  componentDidUpdate() {
    if (this.refocus) {
      this.refocus = false;
      this.searchInput.focus();
    }
  }

  render() {
    let close;

    if (this.state.text.length > 0) {
      close = (
        <button className="autocomplete-close-button" onClick={this.emptySearch}>
          <Icon set="icomoon" size={16} type="box-cross" />
        </button>
      );
    }

    return (
      <div className="autocomplete-input">
        <div className="autocomplete-input__close">{close}</div>
        <div className="autocomplete-input__icon">
          <Icon size={16} type="search" extraClasses="text-grey-light" />
        </div>
        <input
          className="autocomplete-input__input"
          placeholder="Search..."
          value={this.state.text}
          ref={input => {
            this.searchInput = input;
          }}
          onChange={this.handleChange}
          tabIndex={1}
          onKeyUp={this.onSearch}
          onBlur={this.props.onSubmit}
          type="text"
        />
      </div>
    );
  }
}

export default Search;
