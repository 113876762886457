/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

type ButtonIconProps = {
  beforeIcon?: boolean;
};

const StyledButtonIconComponent = (props: ButtonIconProps): IStyle => {
  const style: IStyle = {
    display: 'block',
    position: 'absolute',
    top: '-1px',
  };

  return applyModifiers(
    [
      props.beforeIcon,
      {
        left: '0',
      },
    ],
    [
      !props.beforeIcon,
      {
        right: '-1rem',
      },
    ],
  )(style);
};

export const ButtonInnerIcon = createComponent(StyledButtonIconComponent, 'span');

const StyledButtonTextComponent = (): IStyle => ({
  display: 'block',
  width: '100%',
});

export const ButtonInnerText = createComponent(StyledButtonTextComponent, 'span');

type ButtonWrapperProps = {
  iconBefore: boolean;
  iconAfter: boolean;
};

const StyledButtonWrapperComponent = (props: ButtonWrapperProps): IStyle => {
  const style: IStyle = {
    display: 'block',
    maxWidth: '100%',
    position: 'relative',
  };

  return applyModifiers(
    [
      props.iconAfter,
      {
        paddingRight: '24px',
      },
    ],
    [
      props.iconBefore,
      {
        paddingLeft: '24px',
      },
    ],
  )(style);
};

export const ButtonInnerWrapper = createComponent(StyledButtonWrapperComponent, 'span');
