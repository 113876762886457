/** @format */

export const MessageTypes = {
  AdminGenericMessage: 'AdminGenericMessage',
  DunningProcess: 'DunningProcess',
  EmailBlacklisted: 'EmailBlacklisted',
  ExpiringCard: 'ExpiringCard',
  IntegrationFailure: 'IntegrationFailure',
  InviteApplicationUsers: 'InviteApplicationUsers',
  PlanMonthlyProcessingUsageApproachingAutoUpgradeable:
    'PlanMonthlyProcessingUsageApproachingAutoUpgradeable',
  PlanMonthlyProcessingUsageCapped: 'PlanMonthlyProcessingUsageCapped',
  PlanMonthlyProcessingUsageExceededAutoUpgradeable:
    'PlanMonthlyProcessingUsageExceededAutoUpgradeable',
  PlanUsageExceeded: 'PlanUsageExceeded',
  AzureSubscriptionSuspended: 'AzureSubscriptionSuspended',
};

export type MessageBase = {
  MessageId: number;
  Type: void; // Is defined but usage is not needed
  Data: {};
};

export type AzureSubscriptionSuspended = MessageBase & {
  Key: typeof MessageTypes.AzureSubscriptionSuspended;
};

export type AdminGenericMessage = MessageBase & {
  Key: typeof MessageTypes.AdminGenericMessage;
  Data: {
    BannerColor: 'yellow' | 'blue' | 'red';
    MessageHtml: string;
  };
};

export type DunningProcess = MessageBase & {
  Key: typeof MessageTypes.DunningProcess;
  Data: {
    IsOwner: boolean;
    PlanName: string;
    PaymentUpdateUrl: string;
    AttemptsUntilCancellation: string;
    formattedCancellationDate: string;
  };
};

export type EmailBlacklisted = MessageBase & {
  Key: typeof MessageTypes.EmailBlacklisted;
  Data: {
    Email: string;
    Date: string;
  };
};

export type ExpiringCard = MessageBase & {
  Key: typeof MessageTypes.ExpiringCard;
  Data: {
    BillingUpdateUrl: string;
  };
};

export type IntegrationFailure = MessageBase & {
  Key: typeof MessageTypes.IntegrationFailure;
  Data: {
    Name: string;
    Link: string;
  };
};

export type InviteApplicationUsers = MessageBase & {
  Key: typeof MessageTypes.InviteApplicationUsers;
};

export type PlanMonthlyProcessingUsageApproachingAutoUpgradeable = MessageBase & {
  Key: typeof MessageTypes.PlanMonthlyProcessingUsageApproachingAutoUpgradeable;
};

export type PlanMonthlyProcessingUsageCapped = MessageBase & {
  Key: typeof MessageTypes.PlanMonthlyProcessingUsageApproachingAutoUpgradeable;
};

export type PlanMonthlyProcessingUsageExceededAutoUpgradeable = MessageBase & {
  Key: typeof MessageTypes.PlanMonthlyProcessingUsageApproachingAutoUpgradeable;
};

export type PlanUsageExceeded = MessageBase & {
  Key: typeof MessageTypes.PlanMonthlyProcessingUsageApproachingAutoUpgradeable;
};

export type Message =
  | AdminGenericMessage
  | DunningProcess
  | EmailBlacklisted
  | ExpiringCard
  | IntegrationFailure
  | InviteApplicationUsers
  | PlanMonthlyProcessingUsageApproachingAutoUpgradeable
  | PlanMonthlyProcessingUsageCapped
  | PlanMonthlyProcessingUsageExceededAutoUpgradeable
  | PlanUsageExceeded;
