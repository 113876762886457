/** @format */
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';

const StyledNoData = (props: ThemeType):IStyle => ({
  display: 'block',
  textAlign: 'center',
  paddingLeft: '8px',
  fontSize: '1.4rem',
  lineHeight: '1.25',
  paddingTop: '16px',
  paddingBottom: '16px',
  borderTop: `1px solid ${props.theme.ds.getColorByName('blueGrey75')}`,
  borderBottom: `1px solid ${props.theme.ds.getColorByName('blueGrey75')}`,
});

export const NoData = createComponentWithProxy(StyledNoData, 'p');
