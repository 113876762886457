/** @format */

import * as React from 'react';
import classnames from 'classnames';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';

interface Props {
  marginTop?: boolean;
  marginBottom?: boolean;
  inline?: boolean;
}

const FormLayout: React.FunctionComponent<Props> = props => {
  const classes = classnames('form-block', {
    'form-block--margin-bottom': props.marginBottom,
    'form-block--margin-top': props.marginTop,
    'form-block--inline': props.inline,
  });

  return <div className={classes}>{props.children}</div>;
};

FormLayout.defaultProps = {
  marginBottom: false,
  marginTop: false,
  inline: false,
};

const FormLayoutTitle: React.FunctionComponent<{}> = ({ children }) => {
  return <div className="form-block__title">{children}</div>;
};

interface LabelProps {
  size?: '40' | '32' | '24'; // 40 is the default size
  marginLeft?: boolean;
}

const FormLayoutLabel: React.FunctionComponent<LabelProps> = ({ children, size, marginLeft }) => {
  const classes = classnames('form-block__label', {
    'form-block__label--32': size === '32',
    'form-block__label--24': size === '24',
    'form-block__label--margin-left': marginLeft,
  });

  return <div className={classes}>{children}</div>;
};

const FormLayoutInput: React.FunctionComponent<{}> = ({ children }) => {
  return <div className="form-block__input">{children}</div>;
};

const FormLayoutMessage: React.FunctionComponent<{}> = ({ children }) => {
  return <div className="form-block__message">{children}</div>;
};

const FormLayoutError: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div className="form-block__error">
      <span className="field-validation-error text-red">{children}</span>
    </div>
  );
};

const StyledFormAdditionalInformation = (props: ThemeType): IStyle => ({
  fontSize: '13px',
  color: props.theme.ds.colors.blueGrey.blueGrey600,
  lineHeight: 1.846,
  paddingTop: '8px',
});
const AdditionalInformation = createComponent(StyledFormAdditionalInformation, 'span');

export {
  FormLayout,
  FormLayoutTitle,
  FormLayoutLabel,
  FormLayoutInput,
  FormLayoutMessage,
  FormLayoutError,
  AdditionalInformation,
};
