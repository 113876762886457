/** @format */

import * as React from 'react';

import { ModalProps } from 'components/modal';
import {
  Module,
  ModuleBody,
  ModuleFooterButtonGroup,
  ModuleFooterOnRight,
  ModuleHeader,
} from 'components/ds/module';
import { Dialog } from 'components/ds/dialog';
import { EmphasisButton, TextButton } from 'components/ds/button';
import { Body } from 'components/ds/text';
import { addE2EIdentifier } from 'utils/end-to-end';

export type TextModalProps = {
  headerText: string;
  text: string;
  buttonText: string;
  onSubmit?: () => void;
  onCancel?: () => void;
};

type Props = TextModalProps & ModalProps;

export class TextModal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.close = this.close.bind(this);
    this._close = this._close.bind(this);
  }

  onSubmit() {
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
    this._close();
  }

  close() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this._close();
  }

  _close() {
    this.props.close(this.props.id);
  }

  render() {
    return (
      <Dialog {...this.props} close={this.close}>
        <Module forDialog>
          <ModuleHeader heading={this.props.headerText} />
          <ModuleBody borderBottom>
            <Body>{this.props.text}</Body>
          </ModuleBody>
          <ModuleFooterOnRight forDialog>
            <ModuleFooterButtonGroup>
              <TextButton onClick={this.close}>Cancel</TextButton>
              <EmphasisButton
                id={addE2EIdentifier('e2e-modal-submit-button')}
                onClick={this.onSubmit}
              >
                {this.props.buttonText}
              </EmphasisButton>
            </ModuleFooterButtonGroup>
          </ModuleFooterOnRight>
        </Module>
      </Dialog>
    );
  }
}
