/**
 * @prettier
 */

import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

import { isAppSwitcherOpen, isDashboardSwitcherOpen } from 'app/selectors/chrome';

import { isOnUserSettingsPage, onAlerting, onApiLanding, onPlanSelector } from 'utils/links';
import { AppState } from 'interfaces/appState';
import { ProductTypes } from 'interfaces/products';

import { AppSwitcherBackdrop } from './switcher/backdrop';
import Notifications from '../../notifications';

import Popups from '../../popups';
import { Header } from './header';
import Sidebar from './sidebar/index';
import Footer from './footer';
import Messages from './messages';
import { AppSwitcherContainer, DashboardSwitcherContainer } from './switcher';

type PassedProps = {
  marionetteToggleSidebar?: () => void;
};

type StateProps = {
  sidebar: boolean;
  isSidebarOpen: boolean;
  sidebarActiveLink: string;
  sidebarActiveSecondaryLink: string;
  layout: 'product' | 'platform' | 'anonymous' | 'logo-only' | 'none';
  showMessages: boolean;
  restrictMessagesByProduct: ProductTypes;
  booted: boolean;
  isAppSwitcherOpen: boolean;
  isDashboardSwitcherOpen: boolean;
};

type Props = PassedProps & StateProps;

class UnconnectedChrome extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.renderReactContent = this.renderReactContent.bind(this);
  }

  renderReactContent() {
    // only render the content and sidebar if marionette is not being used.
    const isReactRender = isNil(this.props.marionetteToggleSidebar);
    if (isReactRender) {
      return (
        <div>
          {this.renderSidebar()}

          <main className="layout__content">
            <Messages
              show={this.props.showMessages}
              productType={this.props.restrictMessagesByProduct}
            />
            {this.props.children}
          </main>

          <footer className="layout__footer">
            <Footer />
          </footer>
        </div>
      );
    }
  }

  render() {
    if (this.props.layout === 'none') {
      return this.props.children;
    }

    const layoutClass = classnames({
      layout: isNil(this.props.marionetteToggleSidebar),
      'layout--marionette': !isNil(this.props.marionetteToggleSidebar),
      'layout--white': onAlerting() || isOnUserSettingsPage() || onPlanSelector() || onApiLanding(),
      'layout--sidebar': this.props.sidebar,
      'show-navigation': this.props.isSidebarOpen && !this.props.isAppSwitcherOpen,
      'show-applications': this.props.isAppSwitcherOpen,
      'show-dashboards': this.props.isDashboardSwitcherOpen,
    });

    if (this.props.layout == 'anonymous') {
      return (
        <div className={layoutClass}>
          <div className="layout__above">
            <Notifications />
            <Popups />
          </div>

          <Header />

          {this.renderReactContent()}
        </div>
      );
    }

    return (
      <div className={layoutClass}>
        <div className="layout__above">
          <Notifications />
          <Popups />
        </div>
        {(!isNil(this.props.marionetteToggleSidebar) || this.props.booted) && (
          <>
            <Header
              {...(!isNil(this.props.marionetteToggleSidebar) && {
                ['marionetteToggleSidebar']: () => this.props.marionetteToggleSidebar(),
              })}
            />

            <div className="layout__applications">
              <AppSwitcherContainer />
            </div>

            <div className="layout__dashboards">
              <DashboardSwitcherContainer />
            </div>

            <div className="layout__modal">
              <AppSwitcherBackdrop />
            </div>
          </>
        )}

        {this.renderReactContent()}
      </div>
    );
  }

  renderSidebar() {
    if (this.props.sidebar) {
      return (
        <section className="layout__sidebar">
          <Sidebar
            activeLink={this.props.sidebarActiveLink}
            activeSecondaryLink={this.props.sidebarActiveSecondaryLink}
          />
        </section>
      );
    }
  }
}

export const Chrome = connect<StateProps>((state: AppState) => ({
  ...state.app.chrome,
  isAppSwitcherOpen: isAppSwitcherOpen(state),
  isDashboardSwitcherOpen: isDashboardSwitcherOpen(state),
}))(UnconnectedChrome);
