/**
 * @prettier
 */

import { createRoutingAction } from 'utils/routing';

export const GoToTeamOverview = createRoutingAction<{ planIdentifier: string }>('GO_TO_TEAMS');

export const GoToSpecificTeam = createRoutingAction<{
  planIdentifier: string;
  teamIdentifier: string;
}>('GO_TO_SPECIFIC_TEAM');
