/** @format */

import { createSelector } from 'reselect';
import { AppState } from 'interfaces/appState';

const appSelector = (state: AppState) => state.app;

export const messagesSelector = createSelector(
  appSelector,
  app => app.messages,
);
