/** @format */

import { createSelector } from 'reselect';
import { AppState } from 'interfaces/appState';

export const pageDataSelector = (state: AppState) => state.app.pageData;

export const getApplications = createSelector(
  pageDataSelector,
  pageData => pageData.Applications,
);

export const getOutOfDateAgents = createSelector(
  pageDataSelector,
  pageData => pageData.OutOfDateAgents || [],
);

export const hasSeenWelcomeModal = createSelector(
  pageDataSelector,
  pageData => pageData.APMWelcomeModalSeen,
);

export const getSlackClientId = createSelector(
  pageDataSelector,
  pageData => pageData.SlackClientId,
);

export const getTeamsDetailsId = createSelector(
  pageDataSelector,
  pageData => pageData.TeamsDetailsId,
);

export const getPlanIntegrationStatuses = createSelector(
  pageDataSelector,
  pageData => pageData.PlanIntegrationStatuses,
);

export const hasPersonalAccessToken = createSelector(
  pageDataSelector,
  pageData => pageData.HasPersonalAccessToken,
);

export const getInstructions = createSelector(
  pageDataSelector,
  pageData => pageData.Instructions,
);