/** @format */

import * as React from 'react';
import { CellBase } from 'components/table/tableModel';
import { identity } from 'lodash';

export class TextCell extends CellBase {
  transform: (input: any) => string;

  default: string = '-';

  constructor(init?: Partial<TextCell>) {
    super(init);
    this.transform = init.transform || identity;
  }

  render(rowItem: any) {
    let value = this.transform(this.getValue(rowItem));

    if (!value) {
      value = this.default;
    }

    return <span className="text-ellipsis line-height-24">{value}</span>;
  }
}
