/**
 * @prettier
 */

import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type InteractivityProps = {
  hovered: boolean;
};

const StyledContainer = (props: ThemeType & InteractivityProps): IStyle =>
  applyModifiers([
    props.hovered,
    {
      borderColor: props.theme.colors.border.dark,
      boxShadow: `0 20px 24px rgba(0,0,0,0.15)`,
      color: props.theme.colors.text.one,
    },
  ])({
    borderRadius: '5px',
    border: `1px solid ${props.theme.colors.border.standard}`,
    textDecoration: 'none',
    color: props.theme.colors.text.one,
    display: 'block',
    overflow: 'hidden',
    ':hover': {
      color: props.theme.colors.text.one,
    },
    ':active': {
      color: props.theme.colors.text.one,
    },
    transition: 'all 0.25s ease-out',
  });

export const Container = createComponent(StyledContainer, 'a', [
  'href',
  'onMouseEnter',
  'onMouseLeave',
]);

const StyledImage = (props: ThemeType & InteractivityProps): IStyle =>
  applyModifiers([
    props.hovered,
    {
      borderBottomColor: props.theme.colors.border.dark,
    },
  ])({
    display: 'block',
    width: '100%',
    borderBottom: `1px solid ${props.theme.colors.border.standard}`,
  });

export const Image = createComponent(StyledImage, 'img', ['src']);

const StyledFeatureList = (): IStyle => ({
  listStyleType: 'disc',
  paddingLeft: '16px',
  paddingBottom: '8px',
});

export const FeatureList = createComponent(StyledFeatureList, 'ul');

const StyledFeatureListItem = (): IStyle => ({
  paddingBottom: '16px',
});

export const FeatureListItem = createComponent(StyledFeatureListItem, 'li');
