/**
 * @prettier
 */

import moment from 'moment-timezone';
import { find, clone, isNil, get, has, groupBy, isEmpty } from 'lodash';

import { QueryParameters } from 'utils/fetching/utils';
import { isFunction, isString } from 'utils/types';

import { GlobalDashboardSettings } from '../state';
import { TileDefinition, TileMap, TileMetadata, TileSettings, TileState } from './types';

export function createTileMap(...tiles: TileDefinition[]): TileMap {
  const getTile = (id: string) => {
    const tile = find(tiles, t => t.id === id);
    if (!tile) {
      throw new Error(`Tile not found: ${id}`);
    }
    return tile;
  };
  const tilesByCategory = groupBy(tiles, t => t.category);

  return {
    getTileInfo() {
      return clone(tiles);
    },
    getDefinitionForTile: getTile,
    getComponentForTile(type) {
      return getTile(type).component;
    },
    getFetchActionsForTile(type) {
      return getTile(type).fetchAction;
    },
    getIconForTile(type) {
      return getTile(type).icon;
    },
    getApiEndpointForTile(type) {
      return getTile(type).apiEndpoint;
    },
    getTilesByTileCategory(type) {
      return tilesByCategory[type];
    },
  };
}

export function hasSetting(settings: TileSettings, setting: keyof TileSettings) {
  return !isNil(settings[setting]) && !isEmpty(settings[setting]);
}

export function getSetting(
  settings: TileSettings,
  setting: keyof TileSettings,
  defaultSettings: Partial<TileSettings> = {},
) {
  if (!has(settings, setting)) {
    return defaultSettings[setting];
  }

  return settings[setting];
}

export function queryStringWithSorting(staticQueryParameters: QueryParameters = {}) {
  return (state: TileState, settings: TileSettings) => {
    const sorting = isNil(state)
      ? {
          sortField: settings.TableSortingField,
          sortDirection: settings.TableSortingDirection,
        }
      : state.sorting;

    return {
      ...staticQueryParameters,
      ...sorting,
    };
  };
}

function getFromDate(now: Date, dateRange: string) {
  switch (dateRange) {
    case '1h':
      return new Date(now.getTime() - 1000 * 60 * 60);
    case '6h':
      return new Date(now.getTime() - 1000 * 60 * 60 * 6);
    case '1d':
      return new Date(now.getTime() - 1000 * 60 * 60 * 24);
    case '2d':
      return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 2);
    case '7d':
      return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 7);
    case '14d':
      return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 14);
    case '30d':
      return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 30);
    default:
      throw new Error('range is not one of the accepted values');
  }
}

function getPeriodIntervalFromGranularity(granularity: string) {
  switch (granularity) {
    case '1m':
      return 'OneMinute';
    case '10m':
      return 'TenMinutes';
    case '1h':
      return 'OneHour';
    case '1d':
      return 'OneDay';
    default:
      return null;
  }
}

export function getDateRange(
  range: string | { dateFrom: string; dateTo: string },
): { to: string; from: string } {
  if (isString(range)) {
    const to = new Date();

    return {
      from: moment.utc(getFromDate(to, range)).format(),
      to: moment.utc(new Date()).format(),
    };
  } else {
    const from = moment.utc(range.dateFrom);
    const to = moment.utc(range.dateTo);

    if (to.isBefore(from)) {
      throw new Error('dateTo is before dateFrom');
    }

    return {
      from: from.format(),
      to: to.format(),
    };
  }
}

export function getQueryString(
  settings: TileSettings,
  metadata: TileMetadata,
  state: TileState,
  globalSettings: Partial<GlobalDashboardSettings> = null,
) {
  const queryModel: { [key: string]: any } = {};

  const globalDateRange = get(globalSettings, 'dateRange', null);
  if (hasSetting(settings, 'DateRange') || globalDateRange) {
    const range = getDateRange(globalDateRange || getSetting(settings, 'DateRange'));

    queryModel.to = range.to;
    queryModel.from = range.from;
  }

  if (hasSetting(settings, 'StatusFilter')) {
    queryModel.statusFilter = getSetting(settings, 'StatusFilter');
  }

  if (hasSetting(settings, 'Granularity')) {
    queryModel.interval = getPeriodIntervalFromGranularity(getSetting(settings, 'Granularity'));
  }

  if (hasSetting(settings, 'ResourceType')) {
    queryModel.resourceType = getSetting(settings, 'ResourceType');
  }

  const globalApplications = get(globalSettings, 'applicationIds');
  if (globalApplications) {
    queryModel.applicationids = globalApplications.join(',');
  } else if (hasSetting(settings, 'Applications')) {
    queryModel.applicationids = getSetting(settings, 'Applications')
      .split(',')
      .filter(id => id.length > 0)
      .join(',');
  }

  if (!!state) {
    queryModel.pageIndex = state.pageIndex;
  }

  if (hasSetting(settings, 'Actions')) {
    queryModel.actionfilter = getSetting(settings, 'Actions')
      .split(',')
      .filter(id => id.length > 0)
      .join(',');
  }

  if (!isNil(metadata) && !isNil(metadata.QueryString)) {
    if (isFunction(metadata.QueryString)) {
      return { ...queryModel, ...metadata.QueryString(state, settings) };
    }

    return { ...queryModel, ...metadata.QueryString };
  }

  return queryModel;
}
