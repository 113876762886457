/** @format **/
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';

const StyledHeaderTitle = (props: ThemeType):IStyle => ({
  fontSize: '1.6rem',
  fontWeight: 600,
  lineHeight: '2.5',
  color: props.theme.ds.getColorByName('grey800'),
  whiteSpace: 'nowrap',
  textOverflow: 'clip',
  overflow: 'hidden'
});

export const HeaderTitle = createComponentWithProxy(StyledHeaderTitle, 'h3');
