/** @format */

import * as React from 'react';
import { isNil } from 'lodash';
import { formatAsShorthand } from 'utils/numberFormatting';
import { formatMillisecondsAsMultiPartDuration } from 'utils/durationFormatting';
import { Models } from 'modules/dashboard';
import { TileData, DisplayMode } from '../../models';
import { SingleValuePayload } from '../../actions';
import { SingleValueTileLoading } from './loading/singleValue';
import { TileDataProvider, Props as ProviderProps } from './dataProvider';

type Props = ProviderProps & {
  data: SingleValuePayload;
};

function getValue(displayMode: DisplayMode, value: any): string {
  if (isNil(value)) {
    return 'No data';
  }

  switch (displayMode) {
    case 'duration':
      return formatMillisecondsAsMultiPartDuration(value as number);
    case 'percent':
      return `${value}%`;
    case 'number':
      return value.toLocaleString();
    case 'raw':
      return String(value);
    default:
      console.warn(
        'This should never be hit, check your single value tile definitions for a missing DisplayMode',
      );
      const _ec: never = displayMode;
      break;
  }
}

const SingleValueTile: React.FunctionComponent<Props> = ({ data, metadata }) => {
  const displayedValue: string = getValue(metadata.DisplayMode, data.Value);

  return (
    <div className="dashboard-single-tile">
      <div className="dashboard-single-tile__value">
        <h4 className="dashboard-single-value">{displayedValue}</h4>
      </div>
    </div>
  );
};

const ConnectedTile = TileDataProvider(SingleValueTile, SingleValueTileLoading);

export { ConnectedTile as SingleValueTile };
