/** @format **/

import * as React from 'react';

import { Tooltip } from 'components/tooltip';
import { EmphasisButton } from 'components/ds/button';
import { addE2EIdentifier } from 'utils/end-to-end';

type Props = {
  enableCreateAppButton: boolean;
  createApplicationLink: string;
  tooltipLabelForDisabledButton: string;
  buttonHeight?: number;
};

export const CreateApplicationButton: React.FunctionComponent<Props> = ({
  enableCreateAppButton,
  buttonHeight,
  createApplicationLink,
  tooltipLabelForDisabledButton,
}) => (
  <Tooltip
    label={tooltipLabelForDisabledButton}
    dock="bottom-right"
    width200
    show={!enableCreateAppButton}
  >
    <EmphasisButton
      isLink={enableCreateAppButton}
      href={enableCreateAppButton && createApplicationLink}
      title={enableCreateAppButton && 'Create a new application'}
      disabled={!enableCreateAppButton}
      className={`${addE2EIdentifier('e2e-create-app-button')} link-button`}
      block
      style={{
        height: `${buttonHeight}px`,
        lineHeight: `${buttonHeight}px`,
        fontSize: buttonHeight === 40 ? '14px' : '12px',
      }} /* TODO: Remove this prop and style attribute once the revamped chrome is deployed */
    >
      Create application
    </EmphasisButton>
  </Tooltip>
);

CreateApplicationButton.defaultProps = {
  buttonHeight: 40,
};
