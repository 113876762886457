import React from 'react';
import { isNil, omit } from 'lodash';

import { DefaultProps as DefaultInputProps } from "./components";
import { BaseInput, BaseInputProps } from "./base";
import { Omit } from "../../../utils/types";
import { Icon } from "components/icon";

type TriggerProps = {
  value: string;
};

type Props = TriggerProps & Omit<BaseInputProps, ['selection', 'textArea']>;
export class InputTrigger extends React.Component<Props> {
  static defaultProps: BaseInputProps = {
    ...DefaultInputProps,
    iconRight: <Icon dsColor={"blueGrey700"} type={"dropdown"} />,
    selection: true,
    textArea: false,
  };

  render() {
    const props = omit(this.props, ['children', 'forDropdownTrigger', 'inputId', 'position', 'height', 'forMenuItem']);
    return <BaseInput
      block
      placeholder={this.props.placeholder}
      value={!isNil(this.props.value) ? this.props.value : undefined}
      textArea={false}
      {...props}
    />;
  }
}
