/** @format */

import * as React from 'react';
import { CellBase } from 'components/table/tableModel';
import { assign } from 'lodash';

export class UserCell extends CellBase {
  static NAME_KEY: 'Name' = 'Name';

  static IDENTIFIER_KEY: 'Identifier' = 'Identifier';

  static AVATAR_URL_KEY: 'AvatarURL' = 'AvatarURL';

  constructor(init: Partial<UserCell>) {
    super(init);
    assign(this, init);
  }

  render(rowItem: any) {
    const value = this.getValue(rowItem);

    const avatarUrl = value[UserCell.AVATAR_URL_KEY];
    const altText = value[UserCell.NAME_KEY];

    if (!avatarUrl) {
      return <span>-</span>;
    }

    return (
      <span>
        <img src={avatarUrl} alt={altText} height={32} width={32} />
      </span>
    );
  }
}
