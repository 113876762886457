/** @format **/

import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import { Plan } from 'interfaces/plan';

import UserContent from '../components/userContent';

export interface ContainerChildProps {
  plans: Plan[];
}

const mapStateToProps = (state: AppState): ContainerChildProps => {
  return {
    plans: state.app.configuration.Plans,
  };
};

const UserContentContainer = connect(mapStateToProps)(UserContent);

export default UserContentContainer;
