/** @format */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

import { Flex } from 'components/flex';
import { Models } from 'modules/dashboard';

type Props = Partial<Models.TileProps>;

const Textbox: React.FunctionComponent<Props> = props => (
  <Text {...props.settings}>{props.settings.Text}</Text>
);

const StyledText = (props: Models.TextSettings): IStyle =>
  applyModifiers(
    [
      Models.isTextBold(props.TextBold),
      {
        fontWeight: 600,
      },
    ],
    [
      Models.isTextItalic(props.TextItalic),
      {
        fontStyle: 'italic',
      },
    ],
    [
      Models.isTextUnderline(props.TextUnderline),
      {
        textDecoration: 'underline',
      },
    ],
  )({
    textAlign: props.TextAlignment,
    fontSize: `${props.TextSize}`,
    display: 'block',
    width: '100%',
  });

const Text = createComponent<Models.TextSettings>(StyledText, 'p');

const TextboxTile: Models.TileComponent = props => <Textbox {...props} />;

TextboxTile.moduleProps = {};

export { TextboxTile as Textbox };
