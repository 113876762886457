/** @format */

import { isNil } from 'lodash';
import moment from 'moment';

import { get24HourDisplay } from 'selectors/time';
import { DateInput } from 'utils/types';

import { convertUtcToUsersMoment } from './conversion';

/**
 * Format tokens can be found on the momentjs documentation
 * https://momentjs.com/docs/#/displaying/format/
 */
export function format(
  date: DateInput,
  twelveHourFormat: string,
  twentyFourHourFormat: string = twelveHourFormat,
): string {
  if (isNil(date)) {
    return '-';
  }

  const displayAs24Hour = get24HourDisplay();
  return convertUtcToUsersMoment(date).format(
    displayAs24Hour ? twentyFourHourFormat : twelveHourFormat,
  );
}

export function formatAsTimeAgo(date: DateInput, omitSuffix: boolean = false): string {
  const m = convertUtcToUsersMoment(date);
  return m.fromNow(omitSuffix);
}

export function formatAsISOString(date: DateInput) {
  const m = convertUtcToUsersMoment(date);
  return m.toISOString();
}

/**
 * See {@link https://momentjs.com/docs/#/durations/humanize/|Moment humanize() docs} for more information
 *
 * @param timeSpan
 */
export function formatAsDuration(timeSpan: DateInput): string {
  return moment.duration(timeSpan).humanize();
}

/**
 * Legacy date formatters
 */

export function legacyFormatDate(date: DateInput): string {
  return format(date, 'MMMM Do, YYYY');
}

export function legacyFormatLongDateTime(date: DateInput, includeTime = true): string {
  if (includeTime) {
    return format(date, 'MMMM Do YYYY, h:mm:ss a', 'MMMM Do YYYY, HH:mm:ss');
  } else {
    return format(date, 'MMMM Do YYYY, h:mm a', 'MMMM Do YYYY, HH:mm');
  }
}

export function legacyFormatMediumDateTime(date: DateInput): string {
  return format(date, 'MMM Do h:mm:ss a', 'MMM Do HH:mm:ss');
}

export function legacyFormatTimeForChart(date: DateInput, withDate: boolean = true): string {
  const m = convertUtcToUsersMoment(date);
  const addDate = withDate && m.hour() == 0 ? '\nD MMM' : '';
  return format(date, `h:mm a${addDate}`, `HH:mm${addDate}`);
}
