/** @format */

import * as React from 'react';
import classnames from 'classnames';
import { SearchDropdownComponentProps as Props } from './models';
import { Dropdown } from 'components/dropdown';
import { DropdownTitle, DropdownSection } from '../sections';
import { SearchResult } from 'interfaces/search';
import { SearchResults } from './searchResults';
import { MultiSelectCheckbox } from './multiSelectCheckbox';
import { findIndex, assign } from 'lodash';

class SearchDropdownComponent extends React.Component<Props, {}> {
  private searchInput: HTMLInputElement;

  constructor(props: Props) {
    super(props);

    this.searchInputRefCallback = this.searchInputRefCallback.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleManualSubmit = this.handleManualSubmit.bind(this);
    this.renderSelectedValues = this.renderSelectedValues.bind(this);
  }

  static defaultProps: Partial<Props> = {
    closeOnCancel: true,
    closeOnSubmit: true,
    enableKeyboardControl: true,
    dockOption: 'left',
    maxWidth: 480,
  };

  searchInputRefCallback(el: HTMLInputElement) {
    this.searchInput = el;
  }

  handleResultSelect(values: SearchResult[], isMultiSelect?: boolean) {
    if (!!this.searchInput) {
      this.searchInput.focus();
    }

    this.props.handleResultSelect(values, isMultiSelect);
  }

  handleOpen() {
    this.searchInput.focus();
    this.props.forceOpenDropdown();
    this.props.handleOpen();
  }

  handleCancel(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (this.props.closeOnCancel) {
      this.props.forceCloseDropdown();
    }
    this.props.handleCancel();
  }

  handleManualSubmit(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (this.props.closeOnSubmit) {
      this.props.forceCloseDropdown();
    }
    this.props.handleManualSubmit();
  }

  inCurrentMultiSelect(result: SearchResult): boolean {
    return (
      findIndex(this.props.currentMultiSelectValues, v => v.displayName === result.displayName) !==
      -1
    );
  }

  renderSelectedValues() {
    return this.props.currentMultiSelectValues.map((result, index) => {
      const isChecked: boolean = this.inCurrentMultiSelect(result);
      return (
        <MultiSelectCheckbox
          key={index}
          result={result}
          handleOptionKeyUp={this.props.handleOptionKeyUp}
          handleOptionKeyDown={this.props.handleOptionKeyDown}
          checkboxChangeCallback={() => {
            this.props.handleUnselectValue(result);
          }}
          isChecked={isChecked}
        />
      );
    });
  }

  render() {
    const hideExtraButtonSection = !this.props.showManualSubmit && !this.props.showManualCancel;
    const showSelectedValues =
      this.props.selectMultiple && this.props.currentMultiSelectValues.length > 0;
    const searchResultsClassNames = classnames(
      'dropdown2-section dropdown2-section--search-results',
      {
        'dropdown2-section--search-results-large': this.props.isLarge,
      },
    );
    const submitButtonClasses = classnames(
      'dropdown2-section__option dropdown2-section__option--green js-submit-button test-dropdown-submit-button',
      {
        hidden: !this.props.showManualSubmit,
        'js-dropdown-option': this.props.showManualSubmit,
      },
    );
    const cancelButtonClasses = classnames(
      'dropdown2-section__option dropdown2-section__option--red test-dropdown-cancel-button',
      {
        hidden: !this.props.showManualCancel,
        'js-dropdown-option': this.props.showManualCancel,
      },
    );

    const searchResultProps = assign({}, this.props, {
      handleResultSelect: this.handleResultSelect,
      currentSearchQuery: this.props.currentQuery,
      handleMultiSelect: this.props.handleMultiSelect,
    });

    const showLoadingText = this.props.showLoading && this.props.loadingData;
    
    // Auto trigger behaviour if set & only one result
    if (this.props.autoApplyValue && this.props.filteredSearchResults.length === 1) {
      const result = this.props.filteredSearchResults[0];
      const resultValues: SearchResult[] = [{id: result.displayName, displayName: result.displayName}];
      this.props.handleResultSelect(resultValues);
    }
      

    return (
      <div className="search-dropdown js-search-dropdown">
        <Dropdown
          button={this.props.button}
          closeOnExternalClick={this.props.closeOnExternalClick}
          isOpen={this.props.openDropdown}
          handleOpen={this.handleOpen}
          handleClose={this.props.handleClose}
          disabled={this.props.disabled}
          dockOption={this.props.dockOption}
          maxWidth={this.props.maxWidth}
          block
          autoApplyValue={this.props.autoApplyValue}
        >
          <DropdownSection hidden={this.props.hideSearchSection}>
            <DropdownTitle>
              <label className="form-search">
                <input
                  type="text"
                  className="form-search__input form-text form-text--full-width form-text--large-width js-search-input"
                  ref={this.searchInputRefCallback}
                  placeholder="Start typing..."
                  onKeyUp={this.props.handleSearchKeyUp}
                  onChange={this.props.handleSearchChange}
                  tabIndex={1}
                  onFocus={this.props.handleSearchInputFocus}
                  onBlur={this.props.handleSearchInputBlur}
                  onClick={this.props.handleSearchInputClick}
                  value={this.props.currentQuery}
                />
              </label>
            </DropdownTitle>
          </DropdownSection>
          {showSelectedValues && (
            <DropdownSection searchResultsSize="medium">
              {this.renderSelectedValues()}
            </DropdownSection>
          )}
          <DropdownSection>
            <div className={searchResultsClassNames}>
              <div className="search-results">
                <SearchResults {...searchResultProps} />
                {showLoadingText && (
                  <div className="dropdown2-section__option dropdown2-section__option--no-link">
                    Loading...
                  </div>
                )}
              </div>
            </div>
          </DropdownSection>
          <DropdownSection hidden={hideExtraButtonSection}>
            <a
              href="#"
              className={submitButtonClasses}
              onClick={this.handleManualSubmit}
              tabIndex={1}
              onKeyUp={this.props.handleOptionKeyUp}
              onKeyDown={this.props.handleOptionKeyDown}
            >
              Submit
            </a>
            <a
              href="#"
              className={cancelButtonClasses}
              onClick={this.handleCancel}
              tabIndex={1}
              onKeyUp={this.props.handleOptionKeyUp}
              onKeyDown={this.props.handleOptionKeyDown}
            >
              {this.props.cancelText}
            </a>
          </DropdownSection>
        </Dropdown>
      </div>
    );
  }
}

export { SearchDropdownComponent };