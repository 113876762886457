/**
 * @prettier
 */

import React from 'react';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';

import { applyModifiers } from 'fela-rules/modifiers';
import { screenMediumAndAbove } from 'fela-rules/breakpoints';

type Props = {
  left?: boolean;
  right?: boolean;
  rightForMediumAndAbove?: boolean;
};

const StyledFloat = (props: Props): IStyle =>
  applyModifiers(
    [props.left, { float: 'left' }],
    [props.right, { float: 'right' }],
    [props.rightForMediumAndAbove, screenMediumAndAbove({ float: 'right' })],
  )({});

export const Float = createComponent<Props>(StyledFloat, 'div');
