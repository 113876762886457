/** @format **/

/**
 * Creates and dispatches events that can be listened to in marionette
 * @param event, name corresponding to marionette event name
 * @param customData, data that may need to be accessed in marionette
 */
export function dispatchEvent<T>(event: string, customData?: T) {
  const payload = {
    detail: customData,
  };

  const marionetteEvent = new CustomEvent(event, payload);
  window.dispatchEvent(marionetteEvent);
}

/**
 * Used to listen for events which are dispatched on the window.
 * Generally these events are called by Marionette and so this method acts
 * as a bridge allowing communication from Marionette to lower sections React
 * without having to pass them through as properties.
 */
export function addEventListener<T = {}>(
  event: string,
  callback: (props: CustomEvent<T>) => void,
): () => void {
  window.addEventListener(event, callback);

  return () => {
    window.removeEventListener(event, callback);
  };
}
