/** @format */

import React from 'react';
import { nth, isNil } from 'lodash';

import { getGraphColors, PALETTE_TYPES } from 'app/theme/graphColors';

import * as MarkLine from '../markLine';
import { Base, GroupingProps, PublicProps, ZoomProps } from '../base';
import * as Utils from '../utils';
import { MarkLineKey, MarkLineKeyContainer } from '../markLineKey';
import * as Models from './models';
import * as LineChartUtils from './utils';

export type LineChartProps = Models.LineChartResource & {
  paletteType?: PALETTE_TYPES;
  legend?: boolean;
  chartStorageKey?: string;
  useLogarithmicScale?: boolean;
  dataType?: Models.LineChartDataType;
  forceLogBase?: number;
  markLine?: MarkLine.EchartsMarkLineOptions;
  markLineKeyOptions?: Models.MarkLineKeyOptions;
} & PublicProps &
  ZoomProps &
  GroupingProps;

export const LineChart: React.FunctionComponent<LineChartProps> = ({
  data,
  periods,
  paletteType,
  legend,
  chartStorageKey,
  useLogarithmicScale,
  forceLogBase,
  dataType,
  markLine,
  markLineKeyOptions,
  ...props
}) => {
  const colors = getGraphColors(paletteType, data.length);
  const series = LineChartUtils.getSeries(data, markLine);
  const showMarkLineKey = !isNil(markLineKeyOptions) && markLineKeyOptions.show;

  const legendItems = data.map((item, index) => ({
    name: item.name,
    color: nth(colors, index),
  }));

  const labelFormatter = LineChartUtils.getLabelFormatter(dataType);

  const yAxisLabel = {
    type: 'value',
    axisLabel: {
      formatter: labelFormatter,
    },
    minInterval: dataType === Models.LineChartDataType.Count ? 1 : undefined,
  };

  const options = {
    color: colors,
    tooltip: {
      ...Utils.tooltipOptions,
      formatter: LineChartUtils.getTooltipFormatter(dataType),
      hideDelay: 100,
    },
    xAxis: [
      {
        type: 'category',
        data: periods,
        splitLine: {
          show: false,
        },
        axisLabel: {
          ...Utils.dateTimeLabel(periods),
        },
      },
    ],
    yAxis: useLogarithmicScale
      ? [
          {
            ...yAxisLabel,
            ...LineChartUtils.getValuesForLogScale(data, forceLogBase),
            type: 'log',
          },
        ]
      : [yAxisLabel],
  };

  return (
    <MarkLineKeyContainer>
      <Base
        {...props}
        periods={periods}
        options={options}
        series={series}
        legend={legend}
        legendItems={legendItems}
        chartStorageKey={chartStorageKey}
        hasMarkLineKey={showMarkLineKey}
      />
      {showMarkLineKey && (
        <MarkLineKey
          lineColor={markLineKeyOptions.lineColor}
          labelText={markLineKeyOptions.labelText}
          helpTipLabel={markLineKeyOptions.helpTipLabel}
        />
      )}
    </MarkLineKeyContainer>
  );
};

LineChart.defaultProps = {
  paletteType: PALETTE_TYPES.TrafficLight,
  legend: true,
  group: true,
  dataType: Models.LineChartDataType.Duration,
};
