/** @format **/
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Button } from "components/button";
import { Typography } from "components/text";
import { Width } from "components/width";
import { Margin } from "components/layout";
import { STATUS } from 'constants/status';
import { ButtonPatternCancelSubmit } from 'components/button/pattern';
import {
  ModalProps,
  Modal,
  ModalHeaderWithTitle,
  ModalMessage,
  ModalFooter,
  ModalBodyContent,
} from 'components/modal/index';
import { RadioButton } from "components/ds/selection";
import { Input } from "components/ds/inputs";

import * as Actions from '../../../../actions';
import { IgnoredType } from "../../../../models";

type DispatchProps = {
  ignoreFor: (tileId: string, ignoredType: IgnoredType, ignoredValue: string) => void;
};

type PassedProps = {
  tileId: string;
} & ModalProps;

type StateProps = {};

type Props = PassedProps & StateProps & DispatchProps;

export const UnconnectedIgnoredForModal: React.FunctionComponent<Props> = ({ close, id, ignoreFor, tileId, ...props }) => {
  const [ignoredType, setIgnoreType] = React.useState(IgnoredType.IgnoreByOccurrences);
  const [ignoredOccurrenceValue, setIgnoredOccurrenceValue] = React.useState('1');
  const [ignoredDurationValue, setIgnoredDurationValue] = React.useState('1');
  
  const onClose = React.useCallback(() => {
    close(id);
  }, [close, id]);

  const onSubmit = React.useCallback(() => {
    const ignoredValue: string = ignoredType == IgnoredType.IgnoreByOccurrences ? ignoredOccurrenceValue : ignoredDurationValue;
    
    onClose();
    ignoreFor(tileId, ignoredType, ignoredValue);
  }, [onClose, ignoreFor, tileId, ignoredType, ignoredOccurrenceValue, ignoredDurationValue]);

  const cancel = (
    <Button onClick={onClose} type="white" block size={36}>
      Cancel
    </Button>
  );

  const submit = (
    <Button onClick={onSubmit} type="green" block size={36}>
      Save
    </Button>
  );

  const footer = (
    <ModalFooter smallPadding>
      <ButtonPatternCancelSubmit submit={submit} cancel={cancel} />
    </ModalFooter>
  );

  return (
    <Modal
      header={<ModalHeaderWithTitle>Ignore error</ModalHeaderWithTitle>}
      footer={footer}
      modalBody={{ noPadding: true }}
      close={onClose}
      {...props}
    >
      <ModalMessage color="blue">
        <Typography>
          <p><strong>We've improved this feature!</strong></p>
          <p>You can now set ignore conditions on error groups, <a href="https://raygun.com/documentation/product-guides/crash-reporting/error-statuses/#ignored" target="_blank">learn more</a>.</p>
        </Typography>
      </ModalMessage>
      <ModalBodyContent>
        <Typography>
          <p><strong>Ignore this error group:</strong></p>
        </Typography>
        <Margin top={8} bottom={8}>
          <RadioButton
            checkboxId='occurrence-based'
            name='ignore-by-occurrences'
            checked={ignoredType == IgnoredType.IgnoreByOccurrences}
            onChange={() => setIgnoreType(IgnoredType.IgnoreByOccurrences)}
            enabledColor='grey900'
            enabledSelectedColor='grey900'
          >
            Until{' '}
            <Margin left={8} right={8}>
              <Width width={60}>
                <Input
                  type='number'
                  name='occurrence-input'
                  value={ignoredOccurrenceValue}
                  min='1'
                  step='1'
                  onChange={(e) => {
                    e.target.value < 1 ? setIgnoredOccurrenceValue('1') : setIgnoredOccurrenceValue(e.target.value);
                  }}
                  smallPadding
                />
              </Width>
            </Margin>
            {' '}new error {parseInt(ignoredOccurrenceValue) < 2 ? 'occurrence' : 'occurrences'}
          </RadioButton>
        </Margin>
        <RadioButton
          checkboxId='duration-based'
          name='ignore-by-duration'
          checked={ignoredType == IgnoredType.IgnoreForDuration}
          onChange={() => setIgnoreType(IgnoredType.IgnoreForDuration)}
          enabledColor='grey900'
          enabledSelectedColor='grey900'
        >
          For the next{' '}
          <Margin left={8} right={8}>
            <Width width={60}>
              <Input
                type='number'
                name='duration-input'
                value={ignoredDurationValue}
                min='1'
                step='1'
                onChange={(e) => {
                  e.target.value < 1 ? setIgnoredDurationValue('1') : setIgnoredDurationValue(e.target.value);
                }}
                smallPadding
              />
            </Width>
          </Margin>
          {' '}{parseInt(ignoredDurationValue) < 2 ? 'hour' : 'hours'}
        </RadioButton>
      </ModalBodyContent>
    </Modal>
  );
};
 
const ConnectedIgnoredForModal = connect<StateProps, DispatchProps, PassedProps>(
  null,
  (dispatch: Dispatch) => ({
    ignoreFor: (tileId: string, ignoredType: IgnoredType, ignoredValue: string) =>
      dispatch(
        Actions.setErrorGroupStatus({
          tileId,
          status: STATUS.IGNORED,
          ignoredType: ignoredType,
          ignoredValue: ignoredValue,
        }),
      ),
  }),
)(UnconnectedIgnoredForModal);

export { ConnectedIgnoredForModal as IgnoredForModal };