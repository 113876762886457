/**
 * @prettier
 */

import React from 'react';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';

import { screenMediumAndAbove } from 'fela-rules/breakpoints';

import { Icon, IconBefore } from 'components/icon';
import { COLOR_TYPES } from 'components/text';

export type ListIconType = 'tick' | 'cross';

type Props = {
  listItems: string[];
  iconColor?: COLOR_TYPES;
  textColor?: string;
  iconType?: ListIconType;
  listItemHeight?: number;
  rowsPerColumn?: number;
};

type ListItemProps = {
  textColor: string;
};

type StyledListWrapperProps = {
  listItemHeight: number;
  rowsPerColumn: number;
};

const StyledListWrapper = (props: StyledListWrapperProps): IStyle => ({
  display: 'flex',
  flexFlow: 'column wrap',
  ...screenMediumAndAbove({
    maxHeight: `${props.listItemHeight * props.rowsPerColumn}px`,
  }),
});

const ListWrapper = createComponent(StyledListWrapper, 'ul');

const StyledListItem = (props: ListItemProps): IStyle => ({
  marginBottom: '8px',
  color: props.textColor,
});

const ListItem = createComponent(StyledListItem, 'li');

export const TickList: React.FunctionComponent<Props> = (props: Props) => (
  <ListWrapper listItemHeight={props.listItemHeight} rowsPerColumn={props.rowsPerColumn}>
    {props.listItems.map(item => (
      <ListItem textColor={props.textColor} key={item}>
        <IconBefore icon={<Icon type={props.iconType} color={props.iconColor} />}>
          {item}
        </IconBefore>
      </ListItem>
    ))}
  </ListWrapper>
);

TickList.defaultProps = {
  iconColor: 'green',
  textColor: '#404040',
  iconType: 'tick',
  listItemHeight: 24,
  rowsPerColumn: 3,
};
