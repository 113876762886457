/** @format */

import { IRenderer, IStyle } from 'fela';
import { createComponent, createComponentWithProxy } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type EmblemAnimateProps = {
  spinning: boolean;
};

const StyledEmblemContainer = (): IStyle => ({
  userSelect: 'none',
  cursor: 'pointer',
  position: 'relative',
  margin: '16px auto',
  width: '64px',
  height: '24px',
  borderRadius: '50%',
  ':active': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  zIndex: 9,
});

export const EmblemContainer = createComponentWithProxy(StyledEmblemContainer);

const StyledEmblemRing = (props: EmblemAnimateProps & ThemeType, renderer: IRenderer) => {
  const animateRing = () => ({
    ['0%' as any]: {
      borderColor: props.theme.ds.getColorByName('white'),
    },
    ['50%' as any]: {
      borderColor: 'rgba(255, 255, 255, 0.6)',
    },
    ['100%' as any]: {
      borderColor: props.theme.ds.getColorByName('white'),
    },
  });

  const styles: IStyle = {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 12px)',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: `2px solid ${props.theme.ds.getColorByName('white')}`,
    backgroundSize: '24px',
  };

  return applyModifiers([
    props.spinning,
    {
      animation: `${renderer.renderKeyframe(animateRing, props)} 1s forwards ease-out`,
    },
  ])(styles);
};

export const EmblemRing = createComponentWithProxy(StyledEmblemRing);

const StyledEmblemBolt = (props: EmblemAnimateProps, renderer: IRenderer) => {
  const animateBolt = () => ({
    ['0%' as any]: {
      transform: 'rotate(0deg)',
    },
    ['100%' as any]: {
      transform: 'rotate(359deg)',
    },
  });

  const styles: IStyle = {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 12px)',
    width: '24px',
    height: '24px',
    backgroundImage: 'url("/Content/Images/logo/raygun-bolt.svg")',
    backgroundSize: '24px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return applyModifiers([
    props.spinning,
    {
      animation: `${renderer.renderKeyframe(animateBolt, props)} 1s forwards ease-out`,
    },
  ])(styles);
};

export const EmblemBolt = createComponent(StyledEmblemBolt);

type EmblemShockwaveProps = {
  key: number;
};

const StyledEmblemShockwave = (
  props: EmblemShockwaveProps & ThemeType,
  renderer: IRenderer,
): IStyle => {
  const animateShockwave = () => ({
    ['0%' as any]: {
      boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.4)',
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
    ['100%' as any]: {
      boxShadow: '0 0 0 280px rgba(255, 255, 255, 0)',
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  });

  return {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 12px)',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    willChange: 'box-shadow',
    animation: `${renderer.renderKeyframe(animateShockwave, props)} 1s forwards ease-out`,
  };
};

export const EmblemShockwave = createComponentWithProxy(StyledEmblemShockwave);
