/** @format **/

import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';
import { Fade } from 'components/animated/fade';

import { getSlideoutMenuOpen } from '../../../selectors';
import { setSlideoutMenu } from '../../../actions';
import { SlideoutMenu, SlideoutBackground } from './wrappers';
import { Menu } from './menu';

type StateProps = {
  open: boolean;
};

type DispatchProps = {
  closeMenu: () => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

const UnconnectedSlideoutMenu: React.FunctionComponent<Props> = ({ open, closeMenu }) => {
  return (
    <>
      <SlideoutMenu as="nav" open={open}>
        <Menu closeMenu={closeMenu} />
      </SlideoutMenu>
      <Fade visible={open} duration={200} easing="ease-out" opacity={0.75}>
        <SlideoutBackground onClick={closeMenu} />
      </Fade>
    </>
  );
};

const ConnectedSlideoutMenu = connect<StateProps, DispatchProps, SuppliedProps, AppState>(
  state => ({
    open: getSlideoutMenuOpen(state),
  }),
  dispatch => ({
    closeMenu: () => dispatch(setSlideoutMenu(false)),
  }),
)(UnconnectedSlideoutMenu);

export { ConnectedSlideoutMenu as SlideoutMenu };
