/** @format */
import createActionFactory from 'typescript-fsa';
import { SWITCHER_TYPE } from '../models';

const createAction = createActionFactory('app/chrome/switcher');

export const selectPlan = createAction<number>('SELECT_PLAN');

export const setFilter = createAction<string>('SET_FILTER');

export const toggleSwitcher = createAction<SWITCHER_TYPE>("TOGGLE_SWITCHER");

export const closeSwitcher = createAction("CLOSE_SWITCHER");