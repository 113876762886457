/** @format */

import { ComponentClass, FunctionComponent } from 'react';
import { NotFound } from 'components/notFound';

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { notFound } from 'utils/routing';

export type ScreenState = {
  type: string;
  component: ComponentClass<{}> | FunctionComponent<{}>;
  meta?: { [key: string]: any };
  layout?: ComponentClass<{}> | FunctionComponent<{}>;
};

export const ScreenReducer = reducerWithInitialState<ScreenState>({
  component: null,
  type: undefined,
  layout: null,
}).case(notFound, state => {
  return { ...state, component: NotFound };
});
