/** @format */
import React from 'react';
import { map } from 'lodash';
import { connect, Dispatch } from 'react-redux';

import { Plan } from 'interfaces/plan';
import { AppState } from 'interfaces/appState';
import { PlanAvatar } from 'components/ds/planAvatar';
import { getPlans } from 'app/selectors/configuration';
import { Margin } from 'components/layout';
import { Flex } from 'components/flex';
import { selectPlan } from '../../../actions';
import { getSwitcherPlanId } from '../../../selectors';

type StateProps = {
  plans: Plan[];
  selectedPlanId: number;
};

type DispatchProps = {
  setPlan: (id: number) => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedVerticalPlanSelector: React.FunctionComponent<Props> = ({ selectedPlanId, plans, setPlan }) => (
  <Flex direction="column" aria-label="Select Plan">
    {map(plans, (p, i) => {
      const selected = selectedPlanId === p.Id;
      const style = {
        opacity: selected ? 1 : 0.4
      };

      return (
        <Margin bottom="8" key={p.Id}>
          <div style={style}>
            <PlanAvatar name={p.PlanName} identifier={p.Id} onClick={() => setPlan(p.Id)} clickable big planNumber={i} role="button" aria-label={p.PlanName} />
          </div>
        </Margin>
      );
    })}
  </Flex>
);

const ConnectedVerticalPlanSelector = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => ({
    plans: getPlans(state),
    selectedPlanId: getSwitcherPlanId(state)
  }),
  (dispatch: Dispatch<AppState>) => ({
    setPlan: (id: number) => dispatch(selectPlan(id))
  })
)(UnconnectedVerticalPlanSelector);

export { ConnectedVerticalPlanSelector as VerticalPlanSelector };