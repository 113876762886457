/**
 * @prettier
 */

import { connect } from 'react-redux';
import { find, isNil } from 'lodash';

import {
  allProductsHaveReceivedData,
  getAPMHasReceivedData,
  getCrashReportingHasReceivedData,
  getRUMHasReceivedData,
} from 'app/selectors/configuration';

import { getSearch } from 'selectors/router';
import { AppState } from 'interfaces/appState';

import { getCurrentApplication } from '../../../selectors/application';

import Sidebar from './components/sidebar';

interface OwnProps {
  activeLink: string;
  activeSecondaryLink?: string;
}

interface State {
  appId: string;
  planId: string;
  featureFlags: string[];
  activeLink: string;
  activeSecondaryLink: string;
  uriQuery: string;
  allProductsHaveReceivedData: boolean;
  appName: string;

  mainImageUrl: string;
  mainImageAlt: string;
}

interface Dispatch {}

export interface ContainerProps extends State, Dispatch {}

const SidebarContainer = connect<State, Dispatch, OwnProps>((state: AppState, props: OwnProps) => ({
  appId: state.app.configuration.ApplicationIdentifier,
  planId: state.app.configuration.PlanIdentifier,
  activeLink: props.activeLink,
  activeSecondaryLink: props.activeSecondaryLink,
  featureFlags: state.app.configuration.Features,
  uriQuery: getSearch(state),
  allProductsHaveReceivedData: allProductsHaveReceivedData(state),
  appName: !isNil(getCurrentApplication(state)) ? getCurrentApplication(state).name : null,
  mainImageUrl: state.app.configuration.ApplicationAvatarUrl,
  mainImageAlt: state.app.configuration.ApplicationName,
}))(Sidebar);

export default SidebarContainer;
