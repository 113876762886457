/** @format **/
/* tslint:disable:max-classes-per-file */

import React from 'react';

import { BaseDefaultPropsType, BaseDefaultProps, BaseSelectionControl, PublicProps } from './base';
import { Switch } from './switch';

export { Switch };

type SharedDefaultPropsType = Pick<
  PublicProps,
  'disabledColor' | 'disabledSelectedColor' | 'enabledColor' | 'enabledSelectedColor'
>;

const SharedDefaultProps: SharedDefaultPropsType = {
  enabledColor: 'blueGrey300',
  enabledSelectedColor: 'blue1500',
  disabledColor: 'blueGrey400',
  disabledSelectedColor: 'blueGrey400',
};

type CheckboxProps = PublicProps;

export class Checkbox extends React.Component<CheckboxProps> {
  static defaultProps: SharedDefaultPropsType &
    BaseDefaultPropsType &
    Pick<PublicProps, 'uncheckedIcon' | 'checkedIcon' | 'name'> = {
    ...BaseDefaultProps,
    ...SharedDefaultProps,
    uncheckedIcon: 'checkbox-unchecked',
    checkedIcon: 'checkbox-checked',
    name: undefined,
  };

  render() {
    return <BaseSelectionControl {...this.props} />;
  }
}

// This isn't meant to be used stand alone, need to build a RadioGroup control to contain these
type RadioButtonProps = PublicProps;
export class RadioButton extends React.Component<RadioButtonProps> {
  static defaultProps: SharedDefaultPropsType &
    BaseDefaultPropsType &
    Pick<PublicProps, 'uncheckedIcon' | 'checkedIcon'> = {
    ...BaseDefaultProps,
    ...SharedDefaultProps,
    isRadioButton: true,
    uncheckedIcon: 'radio-unchecked',
    checkedIcon: 'radio-big',
  };

  render() {
    return <BaseSelectionControl {...this.props} />;
  }
}
