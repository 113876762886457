/** @format */

import { createComponent } from 'react-fela';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type Props = {
  disabled?: boolean;
};

const StyledCheckboxLabel = (props: Props & ThemeType) =>
  applyModifiers([
    props.disabled,
    {
      color: props.theme.colors.text.grey,
    },
  ])({
    display: 'block',
    fontSize: '1.4rem',
  });

export const CheckboxLabel = createComponent<Props>(StyledCheckboxLabel, 'span');
