/**
 * @prettier
 */

import React from 'react';
import { ReactComponent } from 'utils/types';

export type WithPressedSuppliedProps = {
  pressed: boolean;
  onMouseDown: React.MouseEventHandler<HTMLElement>;
  onMouseUp: React.MouseEventHandler<HTMLElement>;
  onTouchStart: React.TouchEventHandler<HTMLElement>;
  onTouchEnd: React.TouchEventHandler<HTMLElement>;
  onKeyPress: React.KeyboardEventHandler<HTMLElement>;
};
type WithPressedState = {
  pressed: boolean;
};

export function withPressed<TPublicProps, TFullProps extends WithPressedSuppliedProps>(
  Component: ReactComponent<TFullProps>,
): React.ComponentType<TPublicProps> {
  return (class WithPressed extends React.Component<TFullProps, WithPressedState> {
    constructor(props: TFullProps) {
      super(props);

      this.state = {
        pressed: false,
      };

      this.onMouseDown = this.onMouseDown.bind(this);
      this.onMouseUp = this.onMouseUp.bind(this);
      this.onTouchStart = this.onTouchStart.bind(this);
      this.onTouchEnd = this.onTouchEnd.bind(this);
      this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
      document.addEventListener('mouseup', this.onMouseUp);
    }

    componentWillUnmount() {
      document.removeEventListener('mouseup', this.onMouseUp);
    }

    onMouseDown(e: React.MouseEvent<HTMLButtonElement>) {
      this.setState({ pressed: true });

      const _ignored = this.props.onMouseDown && this.props.onMouseDown(e);
    }

    onMouseUp() {
      this.setState({ pressed: false });
    }

    onTouchStart(e: React.TouchEvent<HTMLElement>) {
      this.setState({ pressed: true });

      const _ignored = this.props.onTouchStart && this.props.onTouchStart(e);
    }

    onTouchEnd(e: React.TouchEvent<HTMLElement>) {
      this.setState({ pressed: false });

      const _ignored = this.props.onTouchEnd && this.props.onTouchEnd(e);
    }

    onKeyPress(e: React.KeyboardEvent<HTMLElement>) {
      if (e.key === 'Enter') {
        this.setState({ pressed: true }, () =>
          setTimeout(() => this.setState({ pressed: false }), 200),
        );
      }

      const _ignored = this.props.onKeyPress && this.props.onKeyPress(e);
    }

    render() {
      return (
        <Component
          {...this.props}
          pressed={this.state.pressed}
          onMouseDown={this.onMouseDown}
          onMouseUp={this.onMouseUp}
          onTouchStart={this.onTouchStart}
          onTouchEnd={this.onTouchEnd}
          onKeyPress={this.onKeyPress}
        />
      );
    }
  } as unknown) as React.ComponentType<TPublicProps>;
}
