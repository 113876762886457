import { createSelector } from 'reselect';
import { isEmpty, omit, isNil, get, every } from 'lodash';

import { getApplicationsForCurrentPlan } from "app/selectors/application";

import { DashboardState } from "../../../sections/dashboard";
import { DashboardModuleStateAtom } from '../state';
import * as Models from '../models';
import { getDefinitionForTile } from '../constants';
import { isTimeboard } from './dashboard';

const tileSettingSelector = (state: DashboardModuleStateAtom) => state.dashboard.activeTileSettings;

export const getSettings = createSelector(
  tileSettingSelector,
  settings => omit(settings, 'tileId')
);

export const getTileId = createSelector(
  tileSettingSelector,
  settings => settings.tileId
);

const hasDefaultSetting = (tileId: string, setting: keyof Models.TileSettings) => {
  return !isNil(get(getDefinitionForTile(tileId), `defaultSettings[${setting}]`, null));
};

const hasApplicationSettings = createSelector(
  getTileId,
  (tileId) => hasDefaultSetting(tileId, 'Applications')
);

export const isValid = createSelector(
  getSettings,
  hasApplicationSettings,
  isTimeboard,
  (settings, appSettings, isTimeboard) => !isEmpty(settings.Title) && (!appSettings || isTimeboard || (appSettings && !isEmpty(settings.Applications)))
);

export const getValidApplications = (state: DashboardState, tile: Models.TileDefinition) => {
  return getApplicationsForCurrentPlan(state).filter(app => every(tile.requirements, req => req(app)));
};
