/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { OverflowProperty } from 'csstype';

import { applyModifiers } from 'fela-rules/modifiers';

import { COLORS } from 'app/theme/materialDS';
import { ThemeType } from 'app/theme';

export * from './header';
export * from './body';
export * from './footer';

type Props = {
  id?: string;
  forDialog?: boolean;
  backgroundColor?: COLORS;
  overflow?: OverflowProperty;
};

const StyledModuleWrapper = (props: Props & ThemeType): IStyle =>
  applyModifiers(
    [
      props.forDialog,
      {
        border: 'none',
        borderRadius: '3px',
        overflow: 'hidden',
        boxShadow: props.theme.ds.dialogBoxShadow,
      },
    ],
    [
      !!props.overflow,
      {
        overflow: props.overflow,
      },
    ],
  )({
    border: `1px solid ${props.theme.ds.getColorByName('blueGrey100')}`,
    boxShadow: props.theme.ds.standardBoxShadow,
    backgroundColor: props.theme.ds.getColorByName(props.backgroundColor),
  });

const ModuleWrapper = createComponentWithProxy(StyledModuleWrapper);

export const Module: React.FunctionComponent<Props> = ({ children, ...props }) => {
  return <ModuleWrapper {...props}>{children}</ModuleWrapper>;
};

Module.defaultProps = {
  forDialog: false,
  backgroundColor: 'white',
};
