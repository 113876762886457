/** @format **/

import React from 'react';
import { IStyle } from "fela";
import { createComponent } from 'react-fela';
import { applyModifiers } from 'fela-rules/modifiers';

import { ThemeType } from 'app/theme';

type ToggleRowButtonProps = {
  expanded: boolean;
} & ThemeType;

const StyledToggleRowButton = (props: ToggleRowButtonProps): IStyle =>
  applyModifiers([
    props.expanded,
    {
      transform: 'rotate(90deg)',
    },
  ])({
    display: 'inline-block',
    cursor: 'pointer',
    color: props.theme.ds.colors.blueGrey.blueGrey600,
    padding: '12px',
  });

export const ToggleRowButton = createComponent(StyledToggleRowButton, 'div', ['onClick', 'title']);
