/* tslint:disable:max-file-line-count */

/** @format **/

import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { map } from 'lodash';

import { AppState } from 'interfaces/appState';
import { apiKey, planIdentifier } from 'app/selectors/application';
import { getAppIdentifier } from 'app/selectors/configuration';

import { isFunction } from 'utils/types';
import { logError } from 'utils/raygun';
import { HTTP_METHODS, QueryParameters } from 'utils/fetching/utils';

import { generateInjectedQueryString, getEndpointForAction } from '../models/utils';
import { NetworkFunction, fetch, fetchFromApp } from '../networking';
import {
  GenericResourceErrors,
  getResourceByName,
  Resource,
  ResourceAction,
  ResourceError,
  Response,
  ResponseTypes,
  PaginationDirection,
} from '../models';
import * as Actions from '../actions';

import { ResourcesNotificationSaga } from './notifications';

function* handleResource(
  resource: Resource<any, any, any>,
  resourceEndpoint: string,
  resourceQs: QueryParameters,
  apiFunc: NetworkFunction,
  method: HTTP_METHODS,
  failureAction: (e: ResourceError[]) => Action<any>,
  successAction: (response: any) => Action<any>,
  completionCallback: (object: any) => void,
  resourceAction: ResourceAction,
  body: any,
  paginationDirection?: PaginationDirection,
): SagaIterator {
  const state: AppState = yield select();

  try {
    const apiEndpoint = resourceEndpoint
      .replace(':applicationIdentifier', getAppIdentifier(state))
      .replace(':planIdentifier', planIdentifier(state));

    let response: Response;
    let queryString: object;
    switch (resourceAction) {
      case ResourceAction.Creating:
      case ResourceAction.Patching:
      case ResourceAction.Action:
        queryString = {
          ...generateInjectedQueryString(resource, state, resourceAction, paginationDirection),
          ...resourceQs,
        };

        response = yield call(apiFunc, {
          path: apiEndpoint,
          apiKey: apiKey(state),
          parameters: queryString,
          body,
          method,
        });
        break;
      case ResourceAction.Deleting:
        response = yield call(apiFunc, {
          path: apiEndpoint,
          apiKey: apiKey(state),
          parameters: resourceQs,
          method,
        });
        break;
      case ResourceAction.FetchingAll:
      case ResourceAction.FetchingSingle:
        queryString = {
          ...generateInjectedQueryString(resource, state, resourceAction, paginationDirection),
          ...resourceQs,
        };

        response = yield call(apiFunc, {
          path: apiEndpoint,
          apiKey: apiKey(state),
          parameters: queryString,
          method,
        });
        break;
    }

    switch (response.type) {
      case ResponseTypes.Failure:
        yield put(failureAction(response.errors));
        break;
      case ResponseTypes.Success:
      case ResponseTypes.PartialSuccess:
      case ResponseTypes.PaginationSuccess:
        const transformedResponse = isFunction(resource.transformer)
          ? map(response.resources, resource.transformer)
          : response.resources;
        yield put(successAction(transformedResponse));
        if (response.type === ResponseTypes.PartialSuccess) {
          yield put(failureAction(response.errors));
        }
        if (response.type === ResponseTypes.PaginationSuccess) {
          yield put(
            Actions.updatePagination({
              resourceName: resource.name,
              ...response.pagination,
            }),
          );
        }

        if (completionCallback && isFunction(completionCallback)) {
          completionCallback(transformedResponse);
        }
        break;
    }
  } catch (e) {
    console.error(e);

    yield put(
      failureAction([
        {
          errorCode: GenericResourceErrors.NetworkError,
          meta: {
            error: e,
          },
        },
      ]),
    );
    logError(e);
  }
}

function handleResourceFactory(
  method: HTTP_METHODS,
  failureAction: (
    arg1: {
      resourceId: string;
      resourceName: string;
      type?: string;
    },
    initPayload?: object,
  ) => (e: ResourceError[]) => Action<any>,
  successAction: (
    arg1: {
      resourceId: string;
      resourceName: string;
      type?: string;
    },
    initPayload?: object,
  ) => (response: any) => Action<any>,
  resourceActionType: ResourceAction,
) {
  return function*(
    action: Action<{
      resourceName: string;
      completionCallback: () => void;
      type?: string;
      resourceId?: string;
      updates?: any;
      paginationDirection?: PaginationDirection;
    }>,
  ) {
    const state: AppState = yield select();
    const {
      resourceId,
      resourceName,
      completionCallback,
      updates,
      type,
      paginationDirection,
    } = action.payload;
    const resource = getResourceByName(resourceName);

    const resourceEndpoint = isFunction(resource.endpoint)
      ? resource.endpoint(state)
      : resource.endpoint;

    let resourceQs: QueryParameters = {};

    /**
     * NOTE: The queryString function can throw an error in certain circumstances. If it does, we'll log the error and break out of the generator
     * An instance where it can fail is when the query string relies on pulling values out of the current browser's query string
     * If the query string changes after the fetch has started, this can cause an error to be thrown
     */
    try {
       resourceQs= isFunction(resource.queryString)
        ? resource.queryString(state, resourceActionType, resourceId)
        : resource.queryString;
    }
    catch (e) {
      e.message = `[Resource query string error] ${e.message}`;
      logError(e);

      const failureActionFn = failureAction({ resourceId, resourceName, type }, action.payload);
      yield put(failureActionFn([
        {
          errorCode: GenericResourceErrors.FailedToGenerateQueryString,
          meta: {
            error: e,
          },
        },
      ]));
      return;
    }

    yield call(
      handleResource,
      resource,
      getEndpointForAction(resourceEndpoint, resourceActionType, resourceId),
      resourceQs,
      resource.useApp ? fetchFromApp : fetch,
      method,
      failureAction({ resourceId, resourceName, type }, action.payload),
      successAction({ resourceId, resourceName, type }, action.payload),
      completionCallback,
      resourceActionType,
      updates,
      paginationDirection,
    );
  };
}

const fetchItemResource = handleResourceFactory(
  'GET',
  ({ resourceName, resourceId }) => (errors: ResourceError[]) =>
    Actions.fetchingResourceFailed({ resourceName, resourceId, errors }),
  ({ resourceName, resourceId }) => (response: any) =>
    Actions.fetchingResourceSucceeded({ resourceId, resourceName, response }),
  ResourceAction.FetchingSingle,
);

const fetchListItemResource = handleResourceFactory(
  'GET',
  ({ resourceName }) => (errors: ResourceError[]) =>
    Actions.fetchingListResourceFailed({ resourceName, errors }),
  ({ resourceName }, payload: Actions.ListStartedPayload) => (response: any) =>
    Actions.fetchingListResourceSucceeded({
      resourceName,
      response,
      replaceResources: payload.replaceResources,
    }),
  ResourceAction.FetchingAll,
);

const patchResource = handleResourceFactory(
  'PUT',
  ({ resourceName, resourceId }) => (errors: ResourceError[]) =>
    Actions.patchingResourceFailed({ resourceName, resourceId, errors }),
  ({ resourceName, resourceId }) => (response: any) =>
    Actions.patchingResourceSucceeded({ resourceId, resourceName, response }),
  ResourceAction.Patching,
);

const postResource = handleResourceFactory(
  'POST',
  ({ resourceName }) => (errors: ResourceError[]) =>
    Actions.postingResourceFailed({ resourceName, errors }),
  ({ resourceName }) => (response: any) =>
    Actions.postingResourceSucceeded({ resourceName, response }),
  ResourceAction.Creating,
);

const deleteResource = handleResourceFactory(
  'DELETE',
  ({ resourceId, resourceName }) => (errors: ResourceError[]) =>
    Actions.deletingResourceFailed({ resourceName, resourceId, errors }),
  ({ resourceId, resourceName }) => () =>
    Actions.deletingResourceSucceeded({ resourceId, resourceName }),
  ResourceAction.Deleting,
);

function* resourceAction(action: Action<Actions.ResourceActionStartedPayload>): SagaIterator {
  const state: AppState = yield select();
  const { resourceName, completionCallback, payload, type } = action.payload;
  const resource = getResourceByName(resourceName);
  const endpoint = resource.actions[type as any];

  const actionEndpoint = isFunction(endpoint) ? endpoint(state) : endpoint;

  yield call(
    handleResource,
    resource,
    actionEndpoint,
    {},
    resource.useApp ? fetchFromApp : fetch,
    'POST',
    (errors: ResourceError[]) => Actions.resourceActionFailed({ resourceName, errors, type }),
    () => Actions.resourceActionSucceeded({ resourceName, type }),
    completionCallback,
    ResourceAction.Action,
    payload,
  );
}

export function* ResourcesSagas(): SagaIterator {
  yield takeEvery(Actions.fetchingResourceStarted, fetchItemResource);
  yield takeEvery(Actions.patchingResourceStarted, patchResource);
  yield takeEvery(Actions.postingResourceStarted, postResource);
  yield takeEvery(Actions.deletingResourceStarted, deleteResource);
  yield takeEvery(Actions.fetchingListResourceStarted, fetchListItemResource);
  yield takeEvery(Actions.resourceActionStarted, resourceAction);

  yield fork(ResourcesNotificationSaga);
}
