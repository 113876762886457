/**
 * @prettier
 */
import * as React from 'react';
import { Column, Table } from 'components/table';
import { SkeletonCell, SkeletonTableData } from 'components/table/cells/skeletonCell';

export const LoadingSlowestRequests: React.FunctionComponent<{}> = () => {
  const columns: Column[] = [
    {
      header: 'Request URL',
      cell: new SkeletonCell(),
    },
    {
      header: 'Average duration',
      cell: new SkeletonCell(),
      width: 160,
    },
    {
      header: 'Total duration',
      width: 160,
      cell: new SkeletonCell(),
    },
  ];

  return <Table columns={columns} data={SkeletonTableData} />;
};
