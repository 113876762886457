/**
 * @prettier
 */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { omit } from 'lodash';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { screenMediumAndAbove, screenSmallOnly } from 'fela-rules/breakpoints';
import { uuid } from 'utils/generators';

import { ErrorBoundary } from 'components/errorBoundary';
import { Overlay } from 'components/loading';
import { Error } from 'components/error';

type ModuleProps = {
  color?: 'white' | 'grey' | 'light-grey' | null;
  hasTabs?: boolean;
  margin?: boolean;
  minHeight?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  noBorder?: boolean;
  borderBottom?: boolean;
  noBorderBottom?: boolean;
  borderTopColor?: 'blue' | 'green' | 'yellow' | null;
  padded?: boolean;
  paddingTop?: number;
};

type ValidModuleElements = 'div' | 'article' | 'section';

type Props = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  body?: boolean;
  id?: string;
  headerTitle?: string;
  innerRef?: (instance: HTMLElement) => void;
  loading?: boolean;
  error?: Error;
  element?: ValidModuleElements;
} & ModuleProps;

export const ModuleBody: React.FunctionComponent<{}> = ({ children }) => (
  <div className="module__body">{children}</div>
);

const StyledModule = (props: ModuleProps & ThemeType) => {
  const styles: IStyle = {
    position: 'relative',
  };

  return applyModifiers(
    [props.margin, { marginBottom: '24px' }],
    [
      props.padded,
      {
        padding: '24px 8px',
        ...screenMediumAndAbove({
          padding: '50px 40px',
        }),
      },
    ],
    [!!props.paddingTop, { paddingTop: `${props.paddingTop}px` }],
    [props.minHeight, { minHeight: '328px', ...screenSmallOnly({ minHeight: 0 }) }],
    [
      props.color === 'white',
      {
        border: `1px solid ${props.theme.colors.border.standard}`,
        background: '#FFF',
        boxShadow: props.theme.shadows.box,
      },
    ],
    [props.color === 'grey', { background: props.theme.colors.grey.light }],
    [props.color === 'light-grey', { backgroundColor: props.theme.colors.grey.lighter }],
    [props.rounded, { borderRadius: '4px' }],
    [props.shadow, { backgroundColor: '#FFF', boxShadow: '0 4px 16px 0 rgba(#000, 0.25)' }],
    [
      props.hasTabs,
      {
        borderTop: 'none',
        boxShadow: `0 -1px 0 ${props.theme.colors.material.grey500}, ${props.theme.shadows.box}`,
      },
    ],
    [props.noBorder, { border: 0 }],
    [props.noBorderBottom, { borderBottom: 'none' }],
    [props.borderBottom, { borderBottom: `1px solid ${props.theme.colors.border.standard}` }],
    [
      props.borderTopColor === 'blue',
      { borderTop: `3px solid ${props.theme.colors.blue.standard}` },
    ],
    [
      props.borderTopColor === 'green',
      { borderTop: `3px solid ${props.theme.colors.green.standard}` },
    ],
    [
      props.borderTopColor === 'yellow',
      { borderTop: `3px solid ${props.theme.colors.lightning.standard}` },
    ],
  )(styles);
};

const StyledModuleAsDivComponent = createComponent(StyledModule, 'div' as any, ['id']);

const StyledModuleAsArticleComponent = createComponent(StyledModule, 'article' as any, ['id']);

const StyledModuleAsSectionComponent = createComponent(StyledModule, 'section' as any, ['id']);

function getStyledModule(element: ValidModuleElements) {
  if (element === 'section') {
    return StyledModuleAsSectionComponent;
  }
  if (element === 'article') {
    return StyledModuleAsArticleComponent;
  }
  return StyledModuleAsDivComponent;
}

const StyledHeader = (props: ModuleProps & ThemeType): IStyle => ({
  width: '100%',
  backgroundColor: props.theme.colors.grey.lightest,
  borderBottom: `1px solid ${props.theme.colors.border.standard}`,
  padding: '12px 24px 12px',
  zIndex: 2,
  lineHeight: '20px',
  marginBottom: '.25rem',
  fontWeight: 600,
});
const StyledHeaderComponent = createComponent(StyledHeader);

export const Module: React.FunctionComponent<Props> = props => {
  const children = props.body ? <ModuleBody>{props.children}</ModuleBody> : props.children;

  const childrenContent = (
    <>
      {props.header}
      {props.headerTitle && <StyledHeaderComponent>{props.headerTitle}</StyledHeaderComponent>}
      <ErrorBoundary>{children}</ErrorBoundary>
      {props.footer}
    </>
  );

  const StyledModule = getStyledModule(props.element);

  if (props.loading) {
    return (
      <StyledModule {...omit(props, 'ref')} innerRef={props.innerRef}>
        <Overlay>{childrenContent}</Overlay>
      </StyledModule>
    );
  }

  return (
    <StyledModule {...omit(props, 'ref')} innerRef={props.innerRef}>
      {childrenContent}
    </StyledModule>
  );
};

Module.defaultProps = {
  margin: true,
  minHeight: false,
  color: 'white',
  hasTabs: false,
  body: true,
  id: uuid(),
  innerRef: () => null,
  loading: false,
};
