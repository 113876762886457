/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import * as React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { omit } from 'lodash';

import { ThemeType } from 'app/theme';
import { DefaultProps, BaseDefaultProps } from './';
import { Omit } from 'utils/types';

export type PublicBaseWrapperProps = Omit<
  BaseDefaultProps,
  'roundLeftCorners' | 'roundRightCorners'
>;

type BaseWrapperProps = PublicBaseWrapperProps;

const StyledBaseWrapper = (props: BaseWrapperProps & ThemeType): IStyle => ({
  height: '24px',
  border: 'none',
  cursor: 'pointer',
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  ':focus': {
    outline: 0,
  },
  ['-webkit-tap-highlight-color' as any]: props.theme.ds.colors.special.transparent,
});

const FelaBaseWrapper = createComponentWithProxy<BaseWrapperProps>(StyledBaseWrapper, 'div');

class BaseWrapper extends React.Component<BaseWrapperProps> {
  render() {
    const containerProps = omit(this.props, 'disabled', 'roundLeftCorners', 'roundRightCorners');
    const childCount = React.Children.count(this.props.children);

    return (
      <FelaBaseWrapper {...containerProps}>
        {React.Children.map(this.props.children, (child: React.ReactElement<any>, index: number) =>
          React.cloneElement(child, {
            disabled: this.props.disabled,
            roundLeftCorners: index === 0,
            roundRightCorners: index === childCount - 1,
          }),
        )}
      </FelaBaseWrapper>
    );
  }
}

export { BaseWrapper, PublicBaseWrapperProps as BaseWrapperProps };
