/** @format **/
import React from 'react';
import { map } from 'lodash';

import { Dashboard } from 'interfaces/dashboard';
import { buildDashboardLink } from 'utils/links';
import { Row } from './row';
import { RowFavourite } from './favourite';
import { RowText } from './text';
import { RowImages, ImageProps } from './image';

type DashboardRowProps = {} & Dashboard;

export const DashboardRow: React.FunctionComponent<DashboardRowProps> = (props) => (
  <Row href={buildDashboardLink(props)}>
    <RowImages images={convertDashboardAvatarUrlsToImages(props.avatarUrls)} />
    <RowText>{props.name}</RowText>
    <RowFavourite favourited={props.isFavourite} />
  </Row>
);

export function convertDashboardAvatarUrlsToImages(avatarUrls: string[]): ImageProps[] {
  return map(avatarUrls, a => ({
    src: a, 
    alt: 'Application icon for Dashboard'
  }));
}
