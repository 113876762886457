/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { applyModifiers } from 'fela-rules/modifiers';
import { screenMediumAndAbove, screenSmallOnly } from 'fela-rules/breakpoints';

import { ThemeType } from 'app/theme';

import {
  BaseEventProps,
  BaseEventNames,
  BasePassthroughProps,
  BasePassthroughNames,
} from 'components/ds';

type Props = {
  padding: 'none' | 'compact' | 'full' | 'responsive' | 'lightBottom';
  width: '100%' | 'max-width';
  border: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
  container: boolean;
  fullWidth?: boolean;
} & BaseEventProps<HTMLDivElement> &
  BasePassthroughProps;

const StyledSegment = (props: Props & ThemeType): IStyle =>
  applyModifiers(
    [
      props.padding === 'none',
      {
        padding: '0px',
      },
    ],
    [
      props.padding === 'compact',
      {
        padding: '24px',
      },
    ],
    [
      props.padding === 'full',
      {
        padding: '56px',
      },
    ],
    [
      props.padding === 'responsive',
      {
        ...screenSmallOnly({
          padding: '24px',
        }),
        ...screenMediumAndAbove({
          padding: '24px',
        }),
      },
    ],
    [
      props.padding === 'lightBottom',
      {
        padding: '24px 24px 16px',
      },
    ],
    [
      props.border,
      {
        border: `1px solid ${props.theme.ds.getColorByName('blueGrey75')}`,
      },
    ],
    [
      props.borderTop,
      {
        borderTop: `1px solid ${props.theme.ds.getColorByName('blueGrey75')}`,
      },
    ],
    [
      props.borderBottom,
      {
        borderBottom: `1px solid ${props.theme.ds.getColorByName('blueGrey75')}`,
      },
    ],
    [
      !props.fullWidth && props.width === 'max-width',
      {
        maxWidth: '1440px',
      },
    ],
    [
      props.container,
      {
        boxShadow: props.theme.ds.standardBoxShadow,
      },
    ],
  )({
    backgroundColor: 'white',
    width: '100%',
  });

const Segment = createComponent(StyledSegment, 'div', [...BasePassthroughNames, ...BaseEventNames]);

class SegmentContainer extends React.Component<Props> {
  static defaultProps: Pick<
    Props,
    'padding' | 'width' | 'border' | 'container' | 'borderTop' | 'borderBottom'
  > = {
    padding: 'compact',
    width: 'max-width',
    border: false,
    borderTop: false,
    borderBottom: false,
    container: false,
  };

  render() {
    return <Segment {...this.props} />;
  }
}

export { SegmentContainer as Segment, Props as SegmentProps };
