/**
 * @prettier
 */

import React from 'react';
import { ReactComponent } from 'utils/types';

export type WithHoverSuppliedProps = {
  hovered: boolean;
  onMouseEnter: React.MouseEventHandler<HTMLElement>;
  onMouseLeave: React.MouseEventHandler<HTMLElement>;
};

type WithHoverState = {
  hovered: boolean;
};

export function withHover<TPublicProps, TFullProps extends WithHoverSuppliedProps>(
  Component: ReactComponent<TFullProps>,
): React.ComponentType<TPublicProps> {
  return (class WithHover extends React.Component<TFullProps, WithHoverState> {
    constructor(props: TFullProps) {
      super(props);

      this.state = {
        hovered: false,
      };

      this.onMouseEnter = this.onMouseEnter.bind(this);
      this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onMouseEnter(e: React.MouseEvent<HTMLElement>) {
      this.setState({ hovered: true });

      const _ignored = this.props.onMouseEnter && this.props.onMouseEnter(e);
    }

    onMouseLeave(e: React.MouseEvent<HTMLElement>) {
      this.setState({ hovered: false });

      const _ignored = this.props.onMouseLeave && this.props.onMouseLeave(e);
    }

    render() {
      return (
        <Component
          {...this.props}
          hovered={this.state.hovered}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        />
      );
    }
  } as unknown) as React.ComponentType<TPublicProps>;
}
