/** @format **/
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { ThemeType } from 'app/theme';

const StyledRow = (props: ThemeType): IStyle => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  paddingLeft: '8px',
  borderTop: `1px solid ${props.theme.ds.getColorByName('blueGrey75')}`,
  ':last-child': {
    borderBottom: `1px solid ${props.theme.ds.getColorByName('blueGrey75')}`,
  },
  ':hover': {
    backgroundColor: props.theme.ds.getColorByName('blueGrey5'),
  },
  ':active': {
    backgroundColor: props.theme.ds.getColorByName('blue100'),
  },
});

export const Row = createComponentWithProxy(StyledRow, 'a');
