/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { screenSmallOnly } from 'fela-rules/breakpoints';
import { Margin } from 'components/layout';

import { Helptip, HelptipProps } from '../tooltip';

type Props = {
  heading?: string;
  forButton?: boolean;
  clearHeader?: boolean;
  withCloseButton?: boolean;
  helpTipOptions?: HelptipProps;
  overlapContent?: boolean;
};

const StyledHeaderText = (): IStyle => ({
  fontSize: '14px',
  lineHeight: '24px',
});

const HeaderText = createComponent(StyledHeaderText, 'h4');

const StyledHeader = (props: ThemeType & Props): IStyle =>
  applyModifiers(
    [
      props.clearHeader,
      {
        backgroundColor: 'transparent',
      },
    ],
    [
      !props.heading,
      {
        justifyContent: 'flex-end',
      },
    ],
    [
      props.withCloseButton,
      {
        paddingRight: '16px',
      },
    ],
    [
      !!props.helpTipOptions,
      {
        justifyContent: 'flex-start',
      },
    ],
    [
      props.overlapContent,
      {
        position: 'absolute',
        width: '100%',
        paddingRight: '0',
      }
    ],
  )({
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: props.theme.ds.getColorByName('blueGrey5'),
    color: props.theme.ds.getColorByName('grey800'),
    ...screenSmallOnly({
      paddingLeft: '16px',
      paddingRight: '16px',
    }),
  });

const Header = createComponent(StyledHeader, 'div');

export const ModuleHeader: React.FunctionComponent<Props> = props => (
  <Header {...props}>
    <HeaderText>{props.heading}</HeaderText>
    {props.helpTipOptions && (
      <Margin left={8}>
        <Helptip {...props.helpTipOptions}>
          <img src="/Content/Images/icons/ds/help.svg" alt="Help icon." width={16} height={16} />
        </Helptip>
      </Margin>
    )}
  </Header>
);

export const ModuleHeaderWithButton: React.FunctionComponent<Props> = props => (
  <Header forButton {...props}>
    {props.heading && <HeaderText>{props.heading}</HeaderText>}
    {props.children}
  </Header>
);
