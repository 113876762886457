/** @format **/

import React from 'react';

import { Segment, SegmentProps } from 'components/ds/segment';

type Props = Partial<Pick<SegmentProps, 'padding' | 'borderTop' | 'borderBottom' | 'width'>>;

export const ModuleBody: React.FunctionComponent<Props> = ({ children, ...props }) => (
  <Segment {...props} fullWidth>
    {children}
  </Segment>
);

ModuleBody.defaultProps = {
  padding: 'compact',
};
