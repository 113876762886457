/**
 * @prettier
 */

import { createSelector } from 'reselect';
import { sortBy, first, filter, isNil, reverse, intersection } from 'lodash';

import { AppState } from 'interfaces/appState';

const whatsNewSelector = (state: AppState) => state.whatsnew;

export const lastViewedItemDate = createSelector(
  whatsNewSelector,
  whatsNew => whatsNew.lastViewedItemDate,
);

export const whatsNewItems = createSelector(
  whatsNewSelector,
  whatsNew => whatsNew.whatsNewItems,
);

export const mostRecentWhatsNewItem = createSelector(
  whatsNewItems,
  items => first(reverse(sortBy(items, i => i.date))),
);

export const unreadWhatsNewItems = createSelector(
  lastViewedItemDate,
  whatsNewItems,
  (lastViewed, items) => items.filter(i => i.date.getTime() > lastViewed.getTime()),
);

export const unreadItemCount = createSelector(
  unreadWhatsNewItems,
  unreadItems => unreadItems.length,
);

export const getFilters = createSelector(
  whatsNewSelector,
  whatsNew => whatsNew.filters,
);

export const getFilteredWhatsNewItems = createSelector(
  whatsNewItems,
  getFilters,
  (items, filters) =>
    filter(
      items,
      item =>
        filters.categories[item.type] &&
        (filters.tags.length === 0 || intersection(filters.tags, item.tags).length > 0),
    ),
);

export const getPinnedPost = createSelector(
  getFilteredWhatsNewItems,
  items => first(filter(items, item => item.pinned)),
);

export const getFilteredWhatsNewItemsWithoutPinnedPost = createSelector(
  getFilteredWhatsNewItems,
  getPinnedPost,
  (items, pinnedPost) =>
    isNil(pinnedPost)
      ? items
      : filter(items, item => item.date.getTime() !== pinnedPost.date.getTime()),
);

export const getFilteredAnnouncementCount = createSelector(
  getFilteredWhatsNewItems,
  filteredItems => filteredItems.length,
);

export const whatsNewAd = createSelector(
  whatsNewSelector,
  whatsNew => whatsNew.banner,
);

export const getWhatsNewAdImgSrc = createSelector(
  whatsNewAd,
  banner => banner.bannerImgSrc,
);

export const getWhatsNewAdImgAlt = createSelector(
  whatsNewAd,
  banner => banner.bannerImgAlt,
);

export const getWhatsNewAdLink = createSelector(
  whatsNewAd,
  banner => banner.bannerLink,
);

export const getWhatsNewAdEnabled = createSelector(
  whatsNewAd,
  banner => banner.bannerEnabled,
);
