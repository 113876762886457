/** @format */

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { clone } from 'lodash';
import { reducerUpdate } from 'utils/reducerUpdate';

import * as Actions from './actions';
import * as Models from './models';

const defaultState: Models.PopupState = {};

export const PopupReducer = reducerWithInitialState(defaultState)
  .case(Actions.open, (state, payload) => {
    return reducerUpdate(state, {
      [payload.id]: payload,
    });
  })
  .case(Actions.close, (state, id) => {
    const newState = clone(state);
    delete newState[id];
    return newState;
  });
