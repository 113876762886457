import React, { FunctionComponent } from 'react';
import { applyModifiers } from "../../fela-rules/modifiers";
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';
import { omit } from 'lodash';

export type SvgProps = {
  src: string;
  width: number;
  height: number;
  disabled?: boolean;
} & React.SVGAttributes<any>;

type SvgContainerProps = {
  disabled?: boolean;
};
const StyledSvgContainer = (props: SvgContainerProps): IStyle =>
  applyModifiers([
    props.disabled, {
      filter: 'grayscale(100%) opacity(60%)'
    }
  ])
({});
const SvgContainer = createComponentWithProxy(StyledSvgContainer, 'svg');

export const Svg: FunctionComponent<SvgProps> = (props) => {
  const containerProps = omit(props, ['src']);
  return (
    <SvgContainer {...containerProps}>
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref={props.src}
        />
    </SvgContainer>
  );
};
