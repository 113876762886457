/** @format **/

import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';

import { showApplicationSwitcher, showDashboardSwitcher } from 'app/actions/chrome';
import * as ConfigSelectors from 'app/selectors/configuration';

import { EmphasisButton } from 'components/ds/button';
import { trackLinkClick } from 'components/snowplow';
import { openModal } from 'components/modal';
import { Flex } from 'components/flex';
import { Icon } from "components/icon";

import { CreateDashboardModal } from 'modules/createDashboardModal';

import { IconWrapper } from "./styles";

type StateProps = {
  hasTimeboardFeatureFlag: boolean;
  isOnTrial: boolean;
  isFeatureGated: boolean;
};

type DispatchProps = {
  createDashboard: () => void;
};

type ReduxDispatchProps = {
  createDashboard: (hasTimeboardFeatureFlag: boolean) => void;
};

type PassedProps = {
  createDashboardLink: string;
  buttonHeight?: number;
  openFeatureGatingModal: () => void;
};

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedCreateDashboardButton: React.FunctionComponent<Props> = ({
  hasTimeboardFeatureFlag,
  createDashboard,
  buttonHeight,
  createDashboardLink,
  openFeatureGatingModal,
  isOnTrial,
  isFeatureGated,
}) => (
  <EmphasisButton
    onClick={() => isFeatureGated ? openFeatureGatingModal() : createDashboard()}
    href={(!hasTimeboardFeatureFlag && !isFeatureGated) && createDashboardLink}
    title={!isFeatureGated &&`Create a new dashboard`}
    block
    isLink={!hasTimeboardFeatureFlag}
    className={!hasTimeboardFeatureFlag ? 'link-button' : null}
    style={{
      height: `${buttonHeight}px`,
      lineHeight: `${buttonHeight}px`,
      fontSize: buttonHeight === 40 ? '14px' : '12px',
      width: 'auto',
    }}
  >
    <Flex align={'center'}>
      {(isOnTrial || isFeatureGated) && (
        <IconWrapper>
          <Icon size={16} set={'icomoon'} type={'crown'} dsColor={'yellow700'} center inline/>
        </IconWrapper>
      )}
      Create dashboard
    </Flex>
  </EmphasisButton>
);

UnconnectedCreateDashboardButton.defaultProps = {
  buttonHeight: 40,
};

export const ConnectedCreateDashboardButton = connect<
  StateProps,
  ReduxDispatchProps,
  PassedProps,
  Props,
  AppState
>(
  state => ({
    hasTimeboardFeatureFlag: ConfigSelectors.hasTimeboardFeatureFlag(state),
    isOnTrial: ConfigSelectors.crashOnTrial(state),
    isFeatureGated: ConfigSelectors.isFeatureGated(state),
  }),
  dispatch => ({
    createDashboard: (hasTimeboardFeatureFlag: boolean) => {
      trackLinkClick('sp-app-chrome-dashboards-createDashboard');
      dispatch(showDashboardSwitcher(false));
      dispatch(showApplicationSwitcher(false));
      if (hasTimeboardFeatureFlag) {
        dispatch(openModal(CreateDashboardModal));
      }
    },
  }),
  (stateProps, dispatchProps, passedProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...passedProps,
    createDashboard: () => dispatchProps.createDashboard(stateProps.hasTimeboardFeatureFlag),
  }),
)(UnconnectedCreateDashboardButton);

export { ConnectedCreateDashboardButton as CreateDashboardButton };
