/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { Icon } from 'components/icon';
import Tooltip from 'components/tooltip';

import { TileCategories } from '../../models';

import { getHumanType } from './utils';

export type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;

  type: TileCategories;
};

const StyledButton = (): IStyle => ({
  backgroundColor: 'white',
  border: '1px solid #BDBDBD',
  borderRadius: '5px',
  padding: '6px 12px 6px 12px',
  cursor: 'pointer',
  ':hover': {
    borderColor: '#1E88E5',
  },
  ':focus': {
    outline: 'none',
  },
});

const ButtonElement = createComponent(StyledButton, 'button', ['onClick']);

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ type, ...eventProps }, ref) => (
    <Tooltip label={getHumanType(type)}>
      <ButtonElement {...eventProps} innerRef={ref as any}>
        <Icon set="icomoon" type={`dashboard--${type}`} size={24} />
      </ButtonElement>
    </Tooltip>
  ),
);
