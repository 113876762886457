/** @format */

import * as React from 'react';
import { CellBase } from 'components/table/tableModel';

import { Icon, IconProps } from 'components/icon';

export class IconCell extends CellBase {
  onClick: (rowItem: any) => void;
  iconProps: IconProps;

  constructor(init?: Partial<IconCell>) {
    super(init);

    this.onClick = init.onClick;
    this.iconProps = init.iconProps;
  }

  render(rowItem: any) {
    return <Icon onClick={() => this.onClick(rowItem)} {...this.iconProps} />;
  }
}
