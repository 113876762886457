/**
 * @prettier
 */

import React from 'react';

import { Skeleton } from 'components/skeleton';
import { TitleWithButton } from './title';

type Props = {
  title?: string;
  height?: number;
};

export const LoadingChart: React.FunctionComponent<Props> = ({ title, height }) => {
  return (
    <>
      <TitleWithButton title={title}>
        <Skeleton height={36} width={160} />
      </TitleWithButton>
      <Skeleton height={height} radius fullWidth />
    </>
  );
};

LoadingChart.defaultProps = {
  height: 180,
};

export default LoadingChart;
