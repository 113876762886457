/** @format */

import * as React from 'react';
import { connect } from 'react-redux';

import * as Actions from 'modules/dashboard/actions';
import { State as DashboardState } from 'modules/dashboard';

import { Dropdown } from 'components/ds/dropdown/triggers';
import { MenuItem } from 'components/ds/dropdown/menu';
import { Button } from 'components/ds/button';
import { openModal } from 'components/modal';
import { ConfirmModal, ConfirmModalProps } from 'components/modal/prefabs';

type StateProps = {};

type DispatchProps = {
  openConfirmModal: (props: ConfirmModalProps) => void;
};

type PassedProps = {
  canReset: boolean;
};

type Props = StateProps & DispatchProps & PassedProps;

const ClearModalProps: ConfirmModalProps = {
  headerText: 'Clear Overview',
  confirmText: 'Are you sure you wish to remove all tiles?',
  confirmButtonText: 'Yes, clear',
  callback: dispatch => dispatch(Actions.clearLayout()),
};

const ResetModalProps: ConfirmModalProps = {
  headerText: 'Reset Dashboard',
  confirmText:
    'Are you sure you wish to discard the current dashboard and revert to the default dashboard?',
  confirmButtonText: 'Yes, reset',
  callback: dispatch => dispatch(Actions.resetLayout.base({})),
};

export const UnconnectedTileControls: React.FunctionComponent<Props> = props => {
  if (props.canReset) {
    return (
      <Dropdown triggerText="Clear / Reset">
        <MenuItem label="Clear dashboard" onClick={() => props.openConfirmModal(ClearModalProps)} />
        <MenuItem label="Reset dashboard" onClick={() => props.openConfirmModal(ResetModalProps)} />
      </Dropdown>
    );
  } else {
    return <Button onClick={() => props.openConfirmModal(ClearModalProps)}>Clear</Button>;
  }
};

export const TileControls = connect<StateProps, DispatchProps, PassedProps, DashboardState>(
  null,
  dispatch => ({
    openConfirmModal: (props: ConfirmModalProps) => {
      dispatch(openModal(ConfirmModal, props));
    },
  }),
)(UnconnectedTileControls);
