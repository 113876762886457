/** @format */

import { createSelector } from 'reselect';
import { reduce, uniqBy, flatten, has } from 'lodash';

import * as Models from './models';
import { ToplevelFiltersState } from './state';

const stateSelector = (state: ToplevelFiltersState) => state.toplevelFilters;

export const getFilters = createSelector(
  stateSelector,
  state => state.filters,
);

export const getDimensions = createSelector(
  stateSelector,
  state => state.dimensions,
);

export const getAddFilterState = createSelector(
  stateSelector,
  state => state.addFilter,
);

export const getEditFilterState = createSelector(
  stateSelector,
  state => state.editFilter,
);

export const getComponentState = createSelector(
  stateSelector,
  state => state.component,
);

export const getExternalConfiguration = createSelector(
  stateSelector,
  state => state.externalConfiguration,
);

export const getValues = createSelector(
  stateSelector,
  state => state.values,
);

export const getCurrentDimensionValue = createSelector(
  getValues,
  getAddFilterState,
  (values, addFilterState) => {
    const { selectedDimensionId } = addFilterState;

    if (!has(values, selectedDimensionId)) {
      return { loading: false, error: null, values: [] };
    }

    return values[selectedDimensionId];
  },
);

export const getCurrentValues = createSelector(
  getCurrentDimensionValue,
  value => value.values,
);

export const areValuesLoading = createSelector(
  getCurrentDimensionValue,
  value => value.loading,
);

export const getDimensionsById = createSelector(
  getDimensions,
  dimensions =>
    reduce(
      dimensions,
      (indexedDimensions, dimension) => ({
        ...indexedDimensions,
        [dimension.id]: dimension,
      }),
      {},
    ) as { [dimensionId: string]: Models.DimensionWithComparators },
);

export const getComparatorsById = createSelector(
  getDimensions,
  dimensions => {
    const uniqueComparators = uniqBy(
      flatten(dimensions.map(dimension => dimension.availableComparators)),
      comparator => comparator.id,
    );

    return reduce(
      uniqueComparators,
      (indexedComparators, comparator) => ({
        ...indexedComparators,
        [comparator.id]: comparator,
      }),
      {},
    ) as { [comparatorId: string]: Models.Comparator };
  },
);

export const getActiveDimension = createSelector(
  getDimensionsById,
  getAddFilterState,
  (dimensions, addFilterState) => {
    const { selectedDimensionId } = addFilterState;

    if (selectedDimensionId == null) {
      return null;
    }

    return dimensions[selectedDimensionId];
  },
);

export const getActiveComparator = createSelector(
  getComparatorsById,
  getAddFilterState,
  (comparators, addFilterState) => {
    const { selectedComparatorId } = addFilterState;

    if (selectedComparatorId == null) {
      return null;
    }

    return comparators[selectedComparatorId];
  },
);
