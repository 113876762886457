/** @format */

import * as React from 'react';
import { connect } from 'react-redux';
import { round, reverse, merge, get, isNil, max } from 'lodash';

import { AppState } from 'interfaces/appState';
import * as charting from 'utils/echarts';
import { getCurrentRouteData } from 'selectors/router';
import { CurrentRouteProps } from 'utils/actions';
import { Container, Graph, Legend, LegendItem } from 'components/echarts/legend';
import { ONE_DAY, SEVEN_DAYS } from 'constants/dateTimeFilter';
import { DEFAULT_GROUP_ID } from 'components/ds/charts/utils';

import { renderTitle } from '../../title';
import { CustomChart } from 'components/ds/charts';

export type HistoricalSeriesData = {
  time: number[];
  periods: string[];
  color: string;
  name: string;
};

type PassedProps = {
  currentData: HistoricalSeriesData;
  previousData: HistoricalSeriesData;
  lastData: HistoricalSeriesData;
  range: typeof ONE_DAY | typeof SEVEN_DAYS;
  name?: string;
  height: string;
  title?: string;
  chartGroupId?: string;
  groupChart?: boolean;
  className?: string;
  onDashboard?: boolean;
  customOptions?: any;
  legendItems?: LegendItem[];
};

type StateProps = {
  routeData: CurrentRouteProps;
};

type DispatchProps = {};

type Props = StateProps & DispatchProps & PassedProps;

const UnconnectedHistoricalOverTime: React.FunctionComponent<Props> = props => {
  const { currentData, previousData, lastData } = props;

  const combinedData = [currentData, previousData, lastData];

  const series = [
    {
      symbol: 'none',
      type: 'line',
      lineStyle: {
        normal: { color: lastData.color, type: 'dotted', width: 1 },
        emphasis: { color: lastData.color, type: 'dotted', width: 1 },
        type: 'dashed',
      },
      name,
      id: lastData.name,
      data: lastData.time,
    },
    {
      symbol: 'none',
      type: 'line',
      lineStyle: {
        normal: { color: previousData.color, type: 'dotted' },
        emphasis: { color: previousData.color, type: 'dotted' },
        type: 'dashed',
      },
      name,
      id: previousData.name,
      data: previousData.time,
    },
    {
      symbol: 'none',
      type: 'line',
      lineStyle: {
        normal: { color: currentData.color },
        emphasis: { color: currentData.color },
      },
      name,
      id: currentData.name,
      data: currentData.time,
    },
  ];

  const periods = currentData.periods;

  const maxTime = max([...currentData.time, ...previousData.time, ...lastData.time]);

  const options = (chart: any) => {
    const rawOptions = charting.extendOptions({
      tooltip: {
        show: true,
        confine: true,
        trigger: 'axis',
        formatter: (params: any[]) => {
          return charting.renderSeriesTooltip({
            date: charting.formatUtcTooltipHistorical(params[0].name, props.range),
            series: reverse(params).map((s: any) => {
              const item = combinedData.find(i => i.name === s.seriesId);
              const periodPoint = get(item.periods, s.dataIndex, null);
              const date = charting.formatUtcHistoricalSeriesDate(periodPoint);

              return {
                name: `${date} (${s.seriesId})`,
                value: `${round(s.value).toLocaleString()}ms`,
                colorcode: get(item, 'color', null),
              };
            }),
          });
        },
      },
      xAxis: [
        {
          type: 'category',
          data: periods,
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: charting.getHistoricalDateTimeLabelFormatter(
              periods,
              props.range,
              chart ? chart.getEchartsInstance() : undefined,
            ),
          },
          splitNumber: 8,
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: charting.getDurationLabelFormatter(
              chart ? chart.getEchartsInstance() : undefined,
              maxTime,
            ),
          },
        },
      ],
      series,
    });

    return props.customOptions ? merge({}, rawOptions, props.customOptions) : rawOptions;
  };

  const legendItems = !isNil(props.legendItems)
    ? props.legendItems
    : [
        {
          label: props.name,
          colorCode: currentData.color,
        },
      ];

  return (
    <Container>
      {renderTitle(props.title)}
      <Graph hasLegend positionInside={props.onDashboard}>
        <CustomChart
          height={props.height}
          className={props.className}
          options={options}
          group={props.groupChart}
          groupingId={props.chartGroupId}
        />
      </Graph>
      <Legend positionInside={props.onDashboard} legendItems={legendItems} />
    </Container>
  );
};

UnconnectedHistoricalOverTime.defaultProps = {
  chartGroupId: DEFAULT_GROUP_ID,
  groupChart: true,
};

const ConnectedHistoricalOverTime = connect<StateProps, DispatchProps, PassedProps>(
  (state: AppState) => ({
    routeData: getCurrentRouteData(state),
  }),
)(UnconnectedHistoricalOverTime);

export { ConnectedHistoricalOverTime as HistoricalOverTime };
