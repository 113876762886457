/** @format */

import * as Models from './models';
import { uuid } from 'utils/generators';

import createActionFactory from 'typescript-fsa';
const createAction = createActionFactory('notifications');

type NotificationId = string;

export type AddNotification = {
  id?: NotificationId;
  disableDismiss?: boolean;
  type: Models.TYPES;
  message: string;
  position: Models.Position;
};

export type UpdateNotification = {
  id: NotificationId;
  type: Models.TYPES;
  message: string;
  disableDismiss?: boolean;
};

export const show = createAction<AddNotification>('ADD_NOTIFICATION');
export const remove = createAction<NotificationId>('REMOVE_NOTIFICATION');
export const update = createAction<UpdateNotification>('UPDATE_NOTIFICATION');

export const getID = () => uuid();

export const createPayload = (
  type: Models.TYPES,
  message: string,
  disableDismiss: boolean = false,
  position: Models.Position = Models.Position.CENTRE_TOP,
): AddNotification => ({ type, message, disableDismiss, id: getID(), position });
