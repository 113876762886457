/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { ThemeType } from 'app/theme';

import { Padding } from 'components/layout';
import { Flex, FlexBlock } from 'components/flex';
import { screenSmallOnly } from 'fela-rules/breakpoints';
import { applyModifiers } from 'fela-rules/modifiers';

type Props = {
  forDialog?: boolean;
  justify?: string;
  align?: string;
  removePadding?: boolean;
};

const StyledFooter = (props: Props & ThemeType): IStyle =>
  applyModifiers(
    [
      props.forDialog,
      {
        borderTop: 'none',
      },
    ],
    [
      props.removePadding,
      {
        padding: 'none',
      },
    ],
  )({
    backgroundColor: 'white',
    padding: '16px 24px',
    borderTop: `1px solid ${props.theme.ds.getColorByName('blueGrey100')}`,
  });

const Footer = createComponent<Props>(StyledFooter);

export const ModuleFooter: React.FunctionComponent<Props> = ({ children, ...props }) => (
  <Footer {...props}>
    <Flex
      justify={props.justify ? props.justify : 'space-between'}
      align={props.align ? props.align : 'center'}
      smallScreens={{
        direction: 'column-reverse',
        justify: props.justify ? props.justify : 'space-between',
        align: props.align ? props.align : 'flex-end',
      }}
    >
      {children}
    </Flex>
  </Footer>
);

export const ModuleFooterOnRight: React.FunctionComponent<Props> = ({ children, ...props }) => (
  <Footer {...props}>
    <Flex direction="column-reverse" justify="space-between" align="flex-end">
      {children}
    </Flex>
  </Footer>
);

export const ModuleFooterButtonGroup: React.FunctionComponent<{}> = ({ children }) => (
  <FlexBlock order={1}>
    <Flex direction="row">
      <Padding applyToChildren left={8}>
        {children}
      </Padding>
    </Flex>
  </FlexBlock>
);

const StyledModuleFooterContent = (): IStyle => ({
  ...screenSmallOnly({
    height: '40px',
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
  }),
});

export const ModuleFooterContent = createComponent(StyledModuleFooterContent);
