/** @format **/

import * as React from 'react';
import { isNil } from 'lodash';

import { ERRORS } from 'constants/errors';
import { STATUS_URL } from 'utils/constants';
import { SupportLink } from "components/supportLink";

type CustomErrorProps = {
  headline: string;
  resolutions?: React.ReactNode[];
  button?: React.ReactNode;
};

export const CustomError: React.FunctionComponent<CustomErrorProps> = ({
  headline,
  resolutions,
  button,
}) => {
  const items = resolutions.map((message, index) => (
    <li key={index} className="empty-table-list__item">
      {message}
    </li>
  ));

  const list = items.length > 0 ? <ul className="empty-table-list">{items}</ul> : null;

  return (
    <div className="empty-table empty-table--alternative clearfix">
      <div className="empty-table__text empty-table__text--no-padding">
        <div className="empty-table-content">
          <div className="empty-table-content__headline">{headline}</div>
          {list}
          {button}
        </div>
      </div>
    </div>
  );
};

CustomError.defaultProps = {
  resolutions: [],
  button: null,
};

export type Props = {
  error: Error;
  reload?: (event?: React.MouseEvent<HTMLElement>) => void;
  singleLine?: boolean;
  padding?: number;
  reloadText?: string;
};

export const Error: React.FunctionComponent<Props> = (props: Props) => {
  const { error, reload, singleLine, padding, reloadText } = props;

  const reloadBtn = reload ? (
    <a className={!singleLine ? 'empty-table-content__link' : null} onClick={reload}>
      {reloadText}
    </a>
  ) : null;

  if (singleLine) {
    const singleLineStyle = !isNil(padding) ? { padding: `${padding}px` } : null;

    return (
      <p style={singleLineStyle}>
        {getHeadlineForError(error)} {reloadBtn}
      </p>
    );
  }

  return (
    <CustomError
      headline={getHeadlineForError(error)}
      resolutions={getResolutionsforError(error)}
      button={reloadBtn}
    />
  );
};

Error.defaultProps = {
  reloadText: 'Reload',
};

export const getHeadlineForError = (err: Error): string => {
  if (!window.navigator.onLine) {
    return ErrorMessages.NoNet;
  }

  if (err) {
    switch (err.message) {
      case ERRORS.SERVER_FAILURE:
        return 'API failure';
      case ERRORS.WRONG_PATH_404:
        return 'Oops, you took a wrong turn';
    }
  }

  return ErrorMessages.Generic;
};

// Disabled because it gets confused by the array return below
/* tslint:disable:jsx-wrap-multiline */
const getResolutionsforError = (err: Error): React.ReactNode[] => {
  if (!window.navigator.onLine) {
    return ['Check cables, modem and router', 'Reconnect to Wi-Fi or network'];
  }

  if (err) {
    switch (err.message) {
      case ERRORS.SERVER_FAILURE:
        return [
          <span key="server-failure-span">
            Check the{' '}
            <a href={STATUS_URL} target="_blank">
              {' '}
              Raygun's API status
            </a>
          </span>,
          <SupportLink key="server-failure-a">
            Let us know about this issue
          </SupportLink>,
        ];
      case ERRORS.WRONG_PATH_404:
        return ['Double check the URL path', 'Update your bookmark'];
    }
  }

  return [
    'Reload this page',
    <SupportLink key="generic-a">
      Let us know about this issue
    </SupportLink>,
  ];
};

export enum ErrorMessages {
  Generic = 'Oops, something has gone wrong!',
  NoNet = 'There is no internet connection',
}
