/** @format */

import * as React from 'react';

import { Column } from './tableModel';

export const applyGutterCells = (
  cells: React.ReactNode[],
  gutters: boolean = false,
): React.ReactNode[] => {
  if (gutters) {
    return [
      <td className="table__cell--gutter" key={-1} />,
      ...cells,
      <td className="table__cell--gutter" key={cells.length} />,
    ];
  }

  return cells;
};

export const getCellName = (column: Column): string => {
  if (column.cellName) {
    return column.cellName;
  }

  if (typeof column.header === 'string') {
    return column.header;
  }

  return null;
};
