/** @format */

import * as React from 'react';
import { get } from 'lodash';
import { clone, forEach, find, range, max } from 'lodash';
import { Responsive, ResponsiveProps, Layout, WidthProvider } from 'react-grid-layout';
import { breakpoints } from 'fela-rules/breakpoints';

import { Models } from 'modules/dashboard';

import { createTile } from './tile';

type Props = {
  tiles: Models.Tile[];
  isEditing: boolean;
  onTilesUpdate: (updatedTiles: Models.Tile[]) => void;
  showDeleteIcon?: boolean;
  showEditIcon?: boolean;
  onTileDelete?: (id: string) => void;
  style?: React.CSSProperties;
};

type State = {
  tiles: Models.Tile[];
};

const BREAKPOINTS = {
  lg: breakpoints.screenXLarge,
  md: breakpoints.screenLarge,
  sm: breakpoints.screenMedium,
  xs: breakpoints.screenSmall,
  xxs: 0,
};

const COLUMNS = {
  lg: 24,
  md: 24,
  sm: 24,
  xs: 1,
  xxs: 1,
};

const ResponsiveGrid = WidthProvider<ResponsiveProps>(Responsive as any);

type ResizeCallback = () => void;
let ID = 1;
const resizeCallbacks: {
  onBegin: {
    [key: number]: ResizeCallback;
  };
  onResize: {
    [key: number]: ResizeCallback;
  };
  onEnd: {
    [key: number]: ResizeCallback;
  };
} = { onBegin: {}, onResize: {}, onEnd: {} };
type gridResizeType = 'onBegin' | 'onResize' | 'onEnd';

export const onGridResize = (cb: () => void, type: gridResizeType = 'onResize'): number => {
  const id = ++ID;

  resizeCallbacks[type][id] = cb;

  return id;
};

export const offGridResize = (id: number, type: gridResizeType = 'onResize'): void => {
  delete resizeCallbacks[type][id];
};

type DragCallback = () => void;
let DragId = 1;
const dragCallbacks: {
  onBegin: {
    [key: number]: DragCallback;
  };
  onDrag: {
    [key: number]: DragCallback;
  };
  onEnd: {
    [key: number]: DragCallback;
  };
} = { onBegin: {}, onDrag: {}, onEnd: {} };
type gridDragType = 'onBegin' | 'onDrag' | 'onEnd';

export const onGridDrag = (cb: () => void, type: gridDragType = 'onDrag'): number => {
  const id = ++DragId;

  dragCallbacks[type][id] = cb;

  return id;
};

export const offGridDrag = (id: number, type: gridDragType = 'onDrag'): void => {
  delete dragCallbacks[type][id];
};

export function createGrid(tilemap: Models.TileMap) {
  const Tile = createTile(tilemap);

  return class Grid extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);

      this.fireResize = this.fireResize.bind(this);
      this.onUpdate = this.onUpdate.bind(this);
      this.onTitleUpdate = this.onTitleUpdate.bind(this);
    }

    static defaultProps: Partial<Props> = {
      showDeleteIcon: false,
    };

    onUpdate(layout: Layout[]) {
      let updateTiles = true;
      const tiles = layout.map<Models.Tile>(l => {
        const updatedTile = {
          ...this.props.tiles[parseInt(l.i)],
          X: l.x,
          Y: l.y,
          Height: l.h,
          Width: l.w,
        };

        if (l.w === 1) {
          updateTiles = false;
        }

        return updatedTile;
      });

      // This is work around an issue with fullscreen mode in Chrome
      // When entering fullscreen mode, Chrome shrinks the layout enough to
      // trigger the xxs breakpoint putting all the tiles into a single column
      // ReactGridLayout then informs us of this layout change and we update
      // the layout to set all tiles to single column and width of 1
      // Since tiles cannot be manually set to a width of 1
      // If we receive a layout with a tile of width 1, ignore it
      if (updateTiles) {
        this.props.onTilesUpdate(tiles);
      }
    }

    onTitleUpdate(id: string, newTitle: string) {
      // TODO: Update just the individual tile instead of all of them
      const tiles = clone(this.props.tiles);
      find(tiles, t => t.InstanceId === id).Settings.Title = newTitle;
      this.props.onTilesUpdate(tiles);
    }

    fireResize(type: gridResizeType) {
      return () => forEach(resizeCallbacks[type], (cb: ResizeCallback) => cb());
    }

    fireDrag(type: gridDragType) {
      return () => forEach(dragCallbacks[type], (cb: DragCallback) => cb());
    }

    render() {
      const tiles = this.props.tiles.map((t, i) => (
        <div key={i}>
          <Tile
            key={i}
            onTitleUpdate={this.onTitleUpdate}
            isEditing={this.props.isEditing}
            showDeleteIcon={this.props.showDeleteIcon}
            showEditIcon={this.props.showEditIcon}
            {...t}
          />
        </div>
      ));

      const layout: Layout[] = this.props.tiles.map((t, i) => {
        const metadata = tilemap.getDefinitionForTile(t.Type).metadata;

        return {
          x: t.X,
          y: t.Y,
          w: t.Width,
          h: t.Height,
          i: i.toString(),
          minW: get(metadata, 'MinWidth', 3),
          minH: get(metadata, 'MinHeight', 2),
        };
      });

      const layouts = {
        lg: layout,
        md: layout,
        sm: layout,
        xs: layout,
        xxs: layout,
      };

      return (
        <ResponsiveGrid
          measureBeforeMount
          margin={[10, 10]}
          containerPadding={[0, 0]}
          rowHeight={40}
          cols={COLUMNS}
          breakpoints={BREAKPOINTS}
          layouts={layouts}
          draggableCancel=".js-tile-stop-drag"
          isDraggable={this.props.isEditing}
          isResizable={this.props.isEditing}
          onLayoutChange={this.onUpdate}
          onResizeStart={this.fireResize('onBegin')}
          onResize={this.fireResize('onResize')}
          onResizeStop={this.fireResize('onEnd')}
          onDragStart={this.fireDrag('onBegin')}
          onDrag={this.fireDrag('onDrag')}
          onDragStop={this.fireDrag('onEnd')}
          className="it-grid"
          // This needs to have a z-index of 1 because a z-index of auto
          // causes z-fighting with the add tile menu
          style={{ ...this.props.style, zIndex: 1 }}
        >
          {tiles}
        </ResponsiveGrid>
      );
    }
  };
}
