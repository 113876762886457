/** @format **/

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from 'interfaces/appState';

import * as Selectors from '../../selectors';
import * as Actions from '../../actions';
import { InviteTeamResource } from '../../resources/inviteTeam';
import { InviteTeamModalStateAtom } from '../../state';

import { Props, RecipientTable as UnconnectedRecipientTable } from './component';

type StateProps = Pick<Props, 'recipients' | 'error' | 'hasError'>;

type DispatchProps = Pick<Props, 'addRecipient'>;

export const RecipientTable = connect<StateProps, DispatchProps>(
  (state: InviteTeamModalStateAtom & AppState) => ({
    recipients: Selectors.getRecipientIds(state),
    hasError: Selectors.hasError(state),
    error: Selectors.getError(state),
  }),
  (dispatch: Dispatch) => ({
    addRecipient: () => dispatch(Actions.addRecipient()),
  }),
)(UnconnectedRecipientTable);
