import * as React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getPlanIdentifier } from 'app/selectors/configuration';
import { AppState } from 'interfaces/appState';

import { openModal } from 'components/modal';
import {SnowplowLink} from "components/snowplow";
import { preventDefault } from 'utils/events';
import { seenInviteTeam } from 'modules/inviteTeamModal/actions';

import { InviteTeamModal } from '../modal';

type StateProps = {
  planIdentifier: string;
};

type DispatchProps = {
  openInviteModal: () => void;
  seenInviteModal: () => void;
};

type PassedProps = {
  marionette?: boolean;
  onClick?: () => void;
};

type MappedProps = {
  onClick: () => void;
};

type Props = StateProps & DispatchProps & PassedProps & MappedProps;

const UnconnectedInviteTeamSidebar: React.FunctionComponent<Props> = ({
  planIdentifier,
  onClick
}) => (
  <li data-content="1">
    <SnowplowLink interactionId={'sp-app-chrome-sidebar-clickInviteTeamButton'}>
      <a href={`/plan/${planIdentifier}/teams`} className="sidebar-button" onClick={preventDefault(onClick)}>
        <span className="sidebar-button-content">
          <svg className="sidebar-icon">
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref={`/Content/Images/sidebar/sidebar-icons.svg#sidebar-icon--blue-add-team`}
            />
          </svg>
          <span className="sidebar-label">Invite team</span>
        </span>
      </a>
    </SnowplowLink>
  </li>
);

const ConnectedInviteTeamSidebar = connect<StateProps, DispatchProps, PassedProps, Props>(
  (state: AppState) => ({
    planIdentifier: getPlanIdentifier(state)
  }),
  (dispatch: Dispatch) => ({
    openInviteModal: () => dispatch(openModal(InviteTeamModal)),
    seenInviteModal: () => dispatch(seenInviteTeam())
  }),
  (stateProps: StateProps, dispatchProps: DispatchProps, passedProps: PassedProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...passedProps,
    onClick: () => {
      // Handles opening the Modal in Marionette since the modal itself is in that context as well
      if(passedProps.marionette) {
        passedProps.onClick();
      } else {
        dispatchProps.openInviteModal();
      }
      dispatchProps.seenInviteModal();
    },
  })
)(UnconnectedInviteTeamSidebar);

export { ConnectedInviteTeamSidebar as InviteTeamSidebar };
