/** @format */

import { createRoutingAction } from 'utils/routing';

export const GoToPrimaryDashboard = createRoutingAction<{ planIdentifier: string }>(
  'GO_TO_PRIMARY_DASHBOARD',
);
export const GoToDashboard = createRoutingAction<{ dashboardIdentifier: string }>(
  'GO_TO_DASHBOARD',
);
