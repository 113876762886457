/** @format */

import { STATUS, MY_ISSUES, API_STATUS, RESOLVE_IN_VERSION } from 'constants/status';

export const getColorForStatus = (status: string) => {
  switch (status) {
    case MY_ISSUES:
      return 'purple';
    case STATUS.ACTIVE:
      return 'red';
    case STATUS.PERM_IGNORED:
      return 'yellow';
    case STATUS.IGNORED:
      return 'blue';
    case RESOLVE_IN_VERSION:
    case STATUS.RESOLVED:
      return 'green';
    default:
      throw new Error('Invalid status passed to `getColorForStatus`');
  }
};

export const getDisplayForStatus = (status: string) => {
  switch (status) {
    case MY_ISSUES:
      return 'My issues';
    case STATUS.ACTIVE:
      return 'Active';
    case STATUS.PERM_IGNORED:
      return 'Permanently ignored';
    case STATUS.IGNORED:
      return 'Ignored';
    case STATUS.RESOLVED:
      return 'Resolved';
    case RESOLVE_IN_VERSION:
      return 'Resolve in Version...';
    default:
      throw new Error('Invalid status passed to `getDisplayForStatus`');
  }
};

export const getDisplayAsActionForStatus = (status: string): string => {
  switch (status) {
    case STATUS.RESOLVED:
      return 'Resolve';
    case STATUS.ACTIVE:
      return 'Activate';
    case STATUS.IGNORED:
      return 'Ignore';
    case STATUS.PERM_IGNORED:
      return 'Permanently ignore';
    default:
      throw new Error('Invalid status passed to `getDisplayAsActionForStatus`');
  }
};

export function statusToApiEnum(status: string): API_STATUS {
  switch (status) {
    case RESOLVE_IN_VERSION:
      return API_STATUS.RESOLVED;
    case STATUS.RESOLVED:
      return API_STATUS.RESOLVED;
    case STATUS.ACTIVE:
      return API_STATUS.ACTIVE;
    case STATUS.IGNORED:
      return API_STATUS.IGNORED;
    case STATUS.PERM_IGNORED:
      return API_STATUS.PERMANENTLY_IGNORED;
    default:
      throw new Error('Invalid status passed to `statusToApiEnum`');
  }
}
