/** @format */

import React from 'react';

import * as PermissionManager from '../manager';
import * as Models from '../models';

type Props = {
  permission: Models.PermissionTypes;
  permissionCategory?: Models.PermissionCategory;
  failureRender?: React.ReactNode;
};

/**
 * Wrapper component for permission based rendering. Uses PermissionManager and renders components based on whether the user has the right permission or not.
 */
export const PermissionCheck: React.FunctionComponent<Props> = ({
  permission,
  permissionCategory,
  failureRender,
  children,
}) => {
  const hasPermission = PermissionManager.can(permission, permissionCategory);
  return hasPermission ? <>{children}</> : <>{failureRender}</>;
};
