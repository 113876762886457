import * as _ from 'lodash';

export const ActivityAction = function (options) {
  this.value = options.value;
  this.color = options.color;
  this.icon = options.icon;
  this.type = options.type;
  this.singleItemTitle = options.singleItemTitle;
  this.actionText = options.actionText;
  if(typeof options.actionItem_One === 'function'){
    this.actionItem_One = options.actionItem_One;
  }
  if(typeof options.actionItem_Many === 'function'){
    this.actionItem_Many = options.actionItem_Many;
  }
  this.itemName = options.item || 'item';
};

ActivityAction.prototype = {
  action: function() {
    return this.actionText || 'did';
  },
  actionItem: function(length) {
    if (length === 1) {
      return this.actionItem_One();
    } else {
      return this.actionItem_Many(length);
    }
  },
  actionItem_One: function() {
    return '1 '+this.itemName+' in ';
  },
  actionItem_Many: function(number) {
    return number + ' ' + this.itemName + 's ';
  }
};

export const ActivityActions = {};
ActivityActions.actions = {};
ActivityActions.add = function(values, item) {
  if(_.isArray(values)) {
    _.each(values, function(value){
      ActivityActions.actions[value] = item;
    });
  }
  else {
    ActivityActions.actions[values] = item;
  }
};

ActivityActions.get = function(value) {
  return ActivityActions.actions[value];
};

ActivityActions.getColor = function(value) {
  var act = ActivityActions.get(value);
  if(act != null) {
    return act.color || 'green';
  }
  return 'green';
};

ActivityActions.isSingleItemType = function(action) {
  var act = ActivityActions.get(action);
  if(act != null) {
    return act.type === 'linked-single' || act.type === 'single';
  }
  return false;
};

ActivityActions.getIcon = function(action) {
  var act = ActivityActions.get(action);
  if(act != null) {
    return act.icon;
  }
  return "save-icon";
};

ActivityActions.getActionPretty = function(action) {
  var act = ActivityActions.get(action);
  if(act != null) {
    return act.action();
  }
  return 'did';
};

ActivityActions.getActionItemPretty = function(action, length) {
  var act = ActivityActions.get(action);
  if(act != null) {
    return act.actionItem(length);
  }
  if (length === 1) {
    return "1 item in ";
  } else {
    return length + " items ";
  }
};

ActivityActions.add('PendingSubscriptionChange', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'made a pending subscription change',
  actionText: 'made a pending subscription change'
}));

ActivityActions.add('ResolveError', new ActivityAction({
  color: 'green',
  icon: 'resolved-error-icon',
  type: 'linked-single',
  singleItemTitle: 'Resolved error',
  actionText: 'resolved',
  itemName: 'error'
}));

ActivityActions.add(['ArchiveError', 'PermanentlyIgnoreError'], new ActivityAction({
  color: 'yellow',
  icon: 'permanently-ignored-error-icon',
  type: 'linked-single',
  singleItemTitle: 'Permanently ignored error',
  actionText: 'permanently ignored',
  itemName: 'error'
}));

ActivityActions.add(['MuteError', 'IgnoreError'], new ActivityAction({
  color: 'blue',
  icon: 'ignored-error-icon',
  type: 'linked-single',
  singleItemTitle: 'Ignored error',
  actionText: 'ignored',
  itemName: 'error'
}));

ActivityActions.add('ActivateError', new ActivityAction({
  color: 'red',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Activated error',
  actionText: 'activated',
  itemName: 'error'
}));

ActivityActions.add('CommentOnError', new ActivityAction({
  color: 'blue',
  icon: 'comment-double-icon',
  type: 'linked-single',
  singleItemTitle: 'Commented on',
  actionText: 'commented on',
  itemName: 'error'
}));

ActivityActions.add('DeleteComment', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'linked-single',
  singleItemTitle: 'Deleted comment from',
  actionText: 'deleted commented from',
  itemName: 'error'
}));

ActivityActions.add('UpdatedOrganisationDetails', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Updated organisation details for',
  actionText: 'updated organisation details for',
  actionItem_One: function() {
    return 'the details for ';
  }
}));

ActivityActions.add('CreateOrganisationForPlan', new ActivityAction({
  color: 'green',
  icon: 'add-user-icon',
  type: 'linked-single',
  singleItemTitle: 'Created organzation for plan',
  actionText: 'created an organisation for',
  actionItem_One: function() {
    return 'the plan ';
  }
}));

ActivityActions.add('UpdateBillingDetails', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'multiple',
  singleItemTitle: 'Updated billing details',
  actionText: 'updated billing details',
  actionItem_One: function() {
    return '';
  }
}));

ActivityActions.add('ChangeSubscription', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'multiple',
  singleItemTitle: 'Changed Plan',
  actionText: 'changed plan',
  actionItem_One: function() {
    return '';
  },
  actionItem_Many: function() {
    return '';
  }
}));

ActivityActions.add('CreateNewApplication', new ActivityAction({
  color: 'green',
  icon: 'application-icon',
  type: 'linked-single',
  singleItemTitle: 'Added new application called',
  actionText: 'created a new application',
  actionItem_One: function() {
    return 'under ';
  }
}));

ActivityActions.add('DeleteApplication', new ActivityAction({
  color: 'red',
  icon: 'application-icon',
  type: 'linked-single',
  singleItemTitle: 'Deleted an application',
  actionText: 'deleted an application',
  actionItem_One: function() {
    return 'under ';
  }
}));

ActivityActions.add('DeleteTeam', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'single',
  singleItemTitle: 'Deleted team',
  actionText: 'deleted a team',
  itemName: 'team'
}));

ActivityActions.add('CreateTeam', new ActivityAction({
  color: 'green',
  icon: 'add-user-icon',
  type: 'linked-single',
  singleItemTitle: 'Created new team',
  actionText: 'created a new team',
  itemName: 'team'
}));

ActivityActions.add('InvitedUser', new ActivityAction({
  color: 'green',
  icon: 'add-user-icon',
  type: 'linked-single',
  singleItemTitle: 'Invited',
  actionText: 'invited',
  itemName: 'user'
}));

ActivityActions.add('RemoveTeamMember', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'linked-single',
  singleItemTitle: 'Removed',
  actionText: 'removed',
  itemName: 'user'
}));

ActivityActions.add('AddTeamMember', new ActivityAction({
  color: 'green',
  icon: 'add-user-icon',
  type: 'linked-single',
  singleItemTitle: 'Added',
  actionText: 'added',
  itemName: 'user'
}));

ActivityActions.add('BulkDeleteErrorsByStatus', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'single',
  singleItemTitle: 'Bulk deleted all errors with a status of',
  actionText: 'deleted all errors with a status of',
  actionItem_One: function() {
    return 'errors with status of ';
  }
}));

ActivityActions.add('BulkDeleteErrorsById', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'multiple',
  actionText: 'bulk deleted',
  itemName: 'error'
}));

// FirstSentCrashData and SelectedProviderType are being left out as they are hidden types

ActivityActions.add('DeleteError', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'single',
  singleItemTitle: 'Deleted error',
  actionText: 'deleted',
  itemName: 'error'
}));

ActivityActions.add('SuppressedNotifications', new ActivityAction({
  color: 'yellow',
  icon: 'permanently-ignored-error-icon',
  type: 'linked-single',
  singleItemTitle: 'Suppressed notifications for',
  actionText: 'suppressed notifications for',
  itemName: 'error'
}));

ActivityActions.add('EnabledNotifications', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Enabled notifications for',
  actionText: 'enabled notifications for',
  itemName: 'error'
}));

ActivityActions.add('DeleteDeployment', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'single',
  singleItemTitle: 'Deleted deployment',
  actionText: 'deleted',
  actionItem_One: function() {
    return 'deployment in ';
  }
}));

ActivityActions.add('EditDeployment', new ActivityAction({
  color: 'yellow',
  icon: 'edit-icon',
  type: 'linked-single',
  singleItemTitle: 'Edited deployment',
  actionText: 'edited',
  actionItem_One: function() {
    return 'deployment in';
  }
}));

ActivityActions.add('CreateDeployment', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Created deployment',
  actionText: 'created',
  actionItem_One: function() {
    return 'deployment in ';
  }
}));

ActivityActions.add('UpgradedViaBanner', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Upgraded this plan via upgrade banner',
  actionText: 'upgraded to this plan via upgrade banner for',
  actionItem_One: function() {
    return 'plan ';
  },
  actionItem_Many: function(length) {
    return length + ' subscriptions under ';
  }
}));

ActivityActions.add('UpgradedViaNewApp', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Upgraded this plan by adding a new application',
  actionText: 'upgraded to this plan via create app wizard for',
  actionItem_One: function() {
    return 'plan ';
  },
  actionItem_Many: function(length) {
    return length + ' subscriptions under ';
  }
}));

ActivityActions.add(['AssignErrorToUser', 'AssignErrorToTeam'], new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Assigned',
  actionText: 'assigned',
  actionItem_One: function() {
    return '1 error in ';
  },
  actionItem_Many: function(length) {
    return length +' errors in ';
  }
}));

ActivityActions.add(['AssignIssueToUser', 'AssignIssueToTeam'], new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Assigned',
  actionText: 'assigned',
  actionItem_One: function() {
    return '1 issue in ';
  },
  actionItem_Many: function(length) {
    return length +' issues';
  }
}));

ActivityActions.add(['RemoveUserAssignmentFromError', 'RemoveTeamAssignmentFromError'], new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'linked-single',
  singleItemTitle: 'Unassigned',
  actionText: 'unassigned',
  actionItem_One: function() {
    return '1 error in ';
  },
  actionItem_Many: function(length) {
    return length +' errors in ';
  }
}));

ActivityActions.add('MergeErrorGroups', new ActivityAction({
  color: 'blue',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Merged',
  actionText: 'Merged',
  actionItem_One: function () {
    return '1 error group in ';
  },
  actionItem_Many: function (length) {
    return length + ' error group in ';
  }
}));

ActivityActions.add('UpdatedIntegration', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Updated integration',
  actionText: 'Updated integration'
}));

ActivityActions.add('SetupIntegration', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Added integration',
  actionText: 'Added integration'
}));

ActivityActions.add('DisabledIntegration', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'linked-single',
  singleItemTitle: 'Removed integration',
  actionText: 'Removed integration'
}));

ActivityActions.add('AddedPlanIntegration', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Added integration',
  actionText: 'Added integration'
}));

ActivityActions.add('RemovedPlanIntegration', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'linked-single',
  singleItemTitle: 'Removed integration',
  actionText: 'Removed integration'
}));

ActivityActions.add('ChangeApiKey', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Regenerated',
  actionText: 'Regenerated'
}));

ActivityActions.add('ChangeHashingStrategy', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Updated',
  actionText: 'Updated'
}));

ActivityActions.add('EnabledFeature', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Enabled',
  actionText: 'Enabled'
}));

ActivityActions.add('UnlockedProduct', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Unlocked',
  actionText: 'Unlocked'
}));

ActivityActions.add('ChangedAutoUpgradeSetting', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Changed monthly processing usage behaviour to',
  actionText: 'Changed monthly processing usage behaviour to'
}));

ActivityActions.add('RenameErrorGroup', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Renamed an error group',
  actionText: 'Renamed an error group'
}));

ActivityActions.add('CreateInboundFilter', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Created an inbound filter',
  actionText: 'created an inbound filter',
  actionItem_One: function () {
    return 'in ';
  }
}));

ActivityActions.add('DeleteInboundFilter', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'single',
  singleItemTitle: 'Deleted an inbound filter',
  actionText: 'deleted an inbound filter',
  actionItem_One: function () {
    return 'from ';
  }
}));

ActivityActions.add('CreateReport', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Created a report',
  actionText: 'created a report',
  actionItem_One: function () {
    return 'in ';
  }
}));

ActivityActions.add('DeleteReport', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'single',
  singleItemTitle: 'Deleted a report',
  actionText: 'deleted a report',
  actionItem_One: function () {
    return 'from ';
  }
}));

ActivityActions.add('ScheduleReport', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Scheduled a report',
  actionText: 'scheduled a report',
  actionItem_One: function () {
    return 'in ';
  }
}));

ActivityActions.add('ModifyReportSchedule', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Modified a report schedule',
  actionText: 'modified a report schedule',
  actionItem_One: function () {
    return 'in ';
  }
}));

ActivityActions.add('ExportErrors', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Exported',
  actionText: 'exported errors',
  actionItem_One: function () {
    return 'from ';
  }
}));

ActivityActions.add('CreateDashboard', new ActivityAction({
  color: 'green',
  icon: 'application-icon',
  type: 'linked-single',
  singleItemTitle: 'Added new dashboard called',
  actionText: 'created a new dashboard',
  actionItem_One: function () {
    return 'under ';
  }
}));

ActivityActions.add('EditDashboard', new ActivityAction({
  color: 'yellow',
  icon: 'edit-icon',
  type: 'linked-single',
  singleItemTitle: 'Edited a dashboard',
  actionText: 'edited',
  actionItem_One: function () {
    return 'dashboard in';
  }
}));

ActivityActions.add('DeleteDashboard', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'single',
  singleItemTitle: 'Deleted a dashboard',
  actionText: 'deleted a dashboard',
  actionItem_One: function () {
    return 'from ';
  }
}));

ActivityActions.add('ResolveIssue', new ActivityAction({
  color: 'green',
  icon: 'resolved-error-icon',
  type: 'multiple',
  actionText: 'resolved',
  item: 'APM issue',
  actionItem_One: function () {
    return '1 APM issue';
  },
  actionItem_Many: function (length) {
    return length + ' APM issues';
  }
}));

ActivityActions.add('IgnoreIssue', new ActivityAction({
  color: 'blue',
  icon: 'ignored-error-icon',
  type: 'multiple',
  actionText: 'ignored',
  item: 'APM issue',
  actionItem_One: function () {
    return '1 APM issue';
  },
  actionItem_Many: function (length) {
    return length + ' APM issues';
  }
}));

ActivityActions.add('PermanentlyIgnoreIssue', new ActivityAction({
  color: 'yellow',
  icon: 'permanently-ignored-error-icon',
  type: 'multiple',
  actionText: 'permanently ignored',
  item: 'APM issue',
  actionItem_One: function () {
    return '1 APM issue';
  },
  actionItem_Many: function (length) {
    return length + ' APM issues';
  }
}));

ActivityActions.add('ActivateIssue', new ActivityAction({
  color: 'red',
  icon: 'save-icon',
  type: 'multiple',
  actionText: 'activated',
  item: 'APM issue',
  actionItem_One: function () {
    return '1 APM issue';
  },
  actionItem_Many: function (length) {
    return length + ' APM issues';
  }
}));

ActivityActions.add('CreateApmRule', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'single',
  singleItemTitle: 'Added new APM rule called',
  actionText: 'created a new APM rule',
  actionItem_One: function () {
    return 'in ';
  }
}));

ActivityActions.add('EditApmRule', new ActivityAction({
  color: 'yellow',
  icon: 'edit-icon',
  type: 'single',
  singleItemTitle: 'Edited an APM rule called',
  actionText: 'edited an APM rule',
  actionItem_One: function () {
    return 'in ';
  }
}));

ActivityActions.add('MuteApmRule', new ActivityAction({
  color: 'blue',
  icon: 'permanently-ignored-error-icon',
  type: 'multiple',
  actionText: 'muted',
  item: 'APM rule',
  actionItem_One: function () {
    return '1 APM rule';
  },
  actionItem_Many: function (length) {
    return length + ' APM rules';
  }
}));

ActivityActions.add('UnMuteApmRule', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'multiple',
  actionText: 'un-muted',
  item: 'APM rule',
  actionItem_One: function () {
    return '1 APM rule';
  },
  actionItem_Many: function (length) {
    return length + ' APM rules';
  }
}));

ActivityActions.add('SnoozeApmRule', new ActivityAction({
  color: 'blue',
  icon: 'ignored-error-icon',
  type: 'multiple',
  actionText: 'snoozed',
  item: 'APM rule',
  actionItem_One: function () {
    return '1 APM rule';
  },
  actionItem_Many: function (length) {
    return length + ' APM rules';
  }
}));

ActivityActions.add('DeleteApmRule', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'multiple',
  actionText: 'deleted',
  item: 'APM rule',
  actionItem_One: function () {
    return '1 APM rule';
  },
  actionItem_Many: function (length) {
    return length + ' APM rules';
  }
}));

ActivityActions.add('ChangedSpikeProtectionSettings', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Updated settings',
  actionText: 'updated spike protection settings'
}));

ActivityActions.add('CreateAlert', new ActivityAction({
  color: 'green',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Created a new alert called',
  actionText: 'created a new alert'
}));

ActivityActions.add('EditAlert', new ActivityAction({
  color: 'yellow',
  icon: 'edit-icon',
  type: 'linked-single',
  singleItemTitle: 'Edited an alert called',
  actionText: 'edited an alert'
}));

ActivityActions.add('DeleteAlert', new ActivityAction({
  color: 'red',
  icon: 'trashcan-icon',
  type: 'single',
  singleItemTitle: 'Deleted an alert called',
  actionText: 'deleted an alert'
}));

ActivityActions.add('AlertSubscribed', new ActivityAction({
  color: 'blue',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Subscribed to an alert called',
  actionText: 'subscribed to an alert'
}));

ActivityActions.add('AlertUnsubscribed', new ActivityAction({
  color: 'blue',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Unsubscribed from an alert called',
  actionText: 'unsubscribed from an alert'
}));

ActivityActions.add('CreatedPersonalAccessToken', new ActivityAction({
  color: 'green',
  type: 'multiple',
  actionText: 'created personal access token',
  itemName: 'scope',
  actionItem_One: function() {
    return '';
  },
  actionItem_Many: function(){
    return '';
  }
}));

ActivityActions.add('UpdatedPersonalAccessToken', new ActivityAction({
  color: 'blue',
  type: 'multiple',
  actionText: 'updated personal access token',
  actionItem_One: function() {
    return '';
  },
  actionItem_Many: function(){
    return '';
  }
}));

ActivityActions.add('DeletedPersonalAccessToken', new ActivityAction({
  color: 'red',
  type: 'multiple',
  actionText: 'deleted personal access token',
  actionItem_One: function() {
    return '';
  },
  actionItem_Many: function(){
    return '';
  }
}));

ActivityActions.add('RegeneratedPersonalAccessToken', new ActivityAction({
  color: 'green',
  type: 'multiple',
  actionText: 'regenerated personal access token',
  actionItem_One: function() {
    return '';
  },
  actionItem_Many: function(){
    return '';
  }
}));

ActivityActions.add('ChangedBillingNotificationSettings', new ActivityAction({
  color: 'yellow',
  icon: 'save-icon',
  type: 'linked-single',
  singleItemTitle: 'Updated settings',
  actionText: 'actionText',
  itemName: 'itemName'
}));