import { get } from "lodash";
import { isFunction } from "utils/types";

const getNotificationCondition =  <R> (resource: R, path: string) => get(resource, `meta.notifications.${path}`, false);

export const showNotification = <R, P> (resource: R, payload: P, path: string) => {
  const triggerCondition = getNotificationCondition(resource, path);
  
  return isFunction(triggerCondition) ?
    triggerCondition(payload) :
    triggerCondition; 
};

export const hasNotificationCondition = <R> (resource: R, path: string) => isFunction(getNotificationCondition(resource, path)) || !!getNotificationCondition(resource, path);