/** @format **/

import * as React from 'react';

import { Avatar } from 'components/avatar';
import { Plan } from 'interfaces/plan';

interface Props {
  plans: Plan[];
}

const Plans: React.FunctionComponent<Props> = props => {
  const plans = props.plans.map((plan: Plan) => {
    const appString = plan.ApplicationCount > 1 ? 'Applications' : 'Application';
    const memberString = plan.MemberCount > 1 ? 'Members' : 'Member';

    return (
      <a href={plan.PlanLink} key={plan.Id} className="dropdown2-plan">
        <div className="dropdown2-plan__icon">
          <Avatar url={plan.Avatar} alt="Plan Avatar" circle />
        </div>
        <div className="dropdown2-plan__name">{plan.PlanName}</div>
        <ul className="dropdown2-plan__details">
          <li className="text-grey text-italic">{`${plan.ApplicationCount} ${appString}`}</li>
          <li className="text-grey text-italic">{`${plan.MemberCount} ${memberString}`}</li>
        </ul>
      </a>
    );
  });

  return <div className="dropdown2-section__plans">{plans}</div>;
};

export default Plans;
