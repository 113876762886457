/** @format **/
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ProductPagesState } from 'sections/productpages';
import { SystemTeams } from 'sections/productpages/teams/models';

import * as Selectors from '../../selectors';
import * as Actions from '../../actions';
import * as Models from '../../models';

import { Props, RecipientInputRow as UnconnectedRecipientInputRow } from './component';

type StateProps = Pick<Props, 'emailAddress' | 'accessLevel'>;

type DispatchProps = {
  updateRecipient: (emailAddress: string, accessLevel: SystemTeams) => void;
};

type SuppliedProps = Pick<Props, 'id'>;

export const RecipientInputRow = connect<StateProps, DispatchProps, SuppliedProps, Props>(
  (state: ProductPagesState, ownProps: SuppliedProps) => {
    const recipientData = get(
      Selectors.getRecipientData(state),
      ownProps.id,
      {} as Models.Recipient,
    );
    return {
      ...recipientData,
    };
  },
  (dispatch: Dispatch, ownProps: SuppliedProps) => ({
    updateRecipient: (emailAddress, accessLevel) =>
      dispatch(
        Actions.updateRecipient({
          id: ownProps.id,
          emailAddress,
          accessLevel,
        }),
      ),
  }),
  (stateProps, dispatchProps, suppliedProps) => ({
    ...stateProps,
    ...dispatchProps,
    updateEmailAddress: (emailAddress: string) =>
      dispatchProps.updateRecipient(emailAddress, stateProps.accessLevel),
    updateAccessLevel: (accessLevel: SystemTeams) =>
      dispatchProps.updateRecipient(stateProps.emailAddress, accessLevel),
    ...suppliedProps,
  }),
)(UnconnectedRecipientInputRow);
