/** @format */

import * as React from 'react';
import { SearchResult } from 'interfaces/search';
import { MultiSelectCheckbox } from './multiSelectCheckbox';
import { findIndex, remove } from 'lodash';

type Props = {
  pending: boolean;
  showLoading: boolean;
  currentSearchQuery: string;
  currentMultiSelectValues: SearchResult[];
  searchPool: SearchResult[];
  filteredSearchResults: SearchResult[];
  handleResultSelect: (values: SearchResult[], isMultiSelect?: boolean) => void;
  handleOptionKeyUp: (e: React.KeyboardEvent<HTMLElement>) => void;
  handleOptionKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  handleUpdateMultiSelectValues: (values: SearchResult[]) => void;
  loadingData?: boolean;
  selectMultiple?: boolean;
  allowFreeText?: boolean;
  showNoResultsText?: boolean;
  limit?: number;
  noResultsText?: string;
  handleMultiSelect?: () => void;
  autoApplyValue?: boolean;
};

class SearchResults extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.addMultiSelectValue = this.addMultiSelectValue.bind(this);
    this.removeMultiSelectValue = this.removeMultiSelectValue.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handlePlainTextSearchClick = this.handlePlainTextSearchClick.bind(this);
  }

  static defaultProps: Partial<Props> = {
    currentMultiSelectValues: [],
  };

  addMultiSelectValue(value: SearchResult) {
    const currentValues: SearchResult[] = this.props.currentMultiSelectValues;
    if (findIndex(currentValues, v => v.displayName === value.displayName) === -1) {
      const newValues: SearchResult[] = currentValues.concat(value);
      this.props.handleUpdateMultiSelectValues(newValues);
    }
  }

  removeMultiSelectValue(value: SearchResult) {
    const valueIndex: number = this.props.currentMultiSelectValues.indexOf(value);
    const currentValues: SearchResult[] = this.props.currentMultiSelectValues;
    if (valueIndex !== -1) {
      const newValues: SearchResult[] = remove(currentValues, v => v.id === value.id);
      this.props.handleUpdateMultiSelectValues(newValues);
    }
  }

  // Event listeners

  handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, result: SearchResult) {
    if (e.currentTarget.checked) {
      this.addMultiSelectValue(result);
    } else {
      this.removeMultiSelectValue(result);
    }

    if (!!this.props.handleMultiSelect) {
      this.props.handleMultiSelect();
    }
  }

  handleOptionClick(result: SearchResult) {
    return (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      const resultValues: SearchResult[] = [result];
      this.props.handleResultSelect(resultValues);
    };
  }

  handlePlainTextSearchClick(value: SearchResult) {
    return (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      this.props.handleResultSelect([value]);
    };
  }

  // Render
  renderResults() {
    let results: React.ReactNode[] = [];

    const searchPool: SearchResult[] = this.props.searchPool;

    // Filtered search results
    const currentResults: SearchResult[] = this.props.filteredSearchResults;
    const noResultsKey = currentResults.length + 1;
    const loadingKey = noResultsKey + 1;

    if (currentResults.length > 0) {
      results = currentResults.map((result: SearchResult, index: number) => {
        if (this.props.selectMultiple) {
          const isChecked: boolean =
            findIndex(
              this.props.currentMultiSelectValues,
              v => v.displayName === result.displayName,
            ) !== -1;

          return (
            <MultiSelectCheckbox
              key={index}
              result={result}
              handleOptionKeyUp={this.props.handleOptionKeyUp}
              handleOptionKeyDown={this.props.handleOptionKeyDown}
              checkboxChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.handleCheckboxChange(e, result)
              }
              isChecked={isChecked}
            />
          );
        } else {
          return (
            <a
              onClick={this.handleOptionClick(result)}
              key={index}
              href="#"
              className="dropdown2-section__option dropdown2-section__option--blue js-dropdown-option"
              tabIndex={1}
              onKeyUp={this.props.handleOptionKeyUp}
              onKeyDown={this.props.handleOptionKeyDown}
              title={result.displayName}
            >
              {result.displayName}
            </a>
          );
        }
      });
    }

    // If the search results from the server are empty
    // Or the showNoResultsText flag is on and filtered results are empty
    if (!this.props.loadingData && (this.props.showNoResultsText && currentResults.length === 0)) {
      results.push(
        <span
          key={noResultsKey}
          className="dropdown2-section__option dropdown2-section__option--no-link dropdown2-section__option--no-results dropdown2-section__option--no-results-full-width"
        >
          {this.props.noResultsText}
        </span>,
      );
    }

    return results;
  }

  render() {
    return <span className="search-results-wrapper">{this.renderResults()}</span>;
  }
}

export { SearchResults };