/** @format **/
import { ProductType } from "interfaces/plan";

export const CrashReporting = 'Crash Reporting';

export const RealUserMonitoring = 'Real User Monitoring';

export const APM = 'APM';

export const Platform = 'Platform';

export const UsageCapping = 'Usage Capping';

export type ProductTypes =
  | typeof CrashReporting
  | typeof RealUserMonitoring
  | typeof APM
  | typeof Platform
  | typeof UsageCapping;

export const Products = {
  CrashReporting: CrashReporting,
  RealUserMonitoring: RealUserMonitoring,
  APM: APM,
  UsageCapping: UsageCapping
};

// FE Version of the `Mindscape.Raygun.Common.Interfaces.Models.ProductTypes`
export enum ProductTypesEnum {
  CrashReporting = 0,
  Pulse = 1,
  APM = 2,
  Platform = 10,
  AdditionalUser = 11,
  UsageCapping = 12,
}

export function getProductType(productTypeEnum: ProductTypesEnum): ProductType {
  switch (productTypeEnum) {
    case ProductTypesEnum.Platform:
      return ProductType.PLATFORM;
    case ProductTypesEnum.CrashReporting:
      return ProductType.CRASH_REPORTING;
    case ProductTypesEnum.Pulse:
      return ProductType.RUM;
    case ProductTypesEnum.APM:
      return ProductType.APM;
    case ProductTypesEnum.UsageCapping:
      return ProductType.USAGE_CAPPING;
  }
}

export function productEnumToProduct(product: ProductTypesEnum): ProductTypes {
  switch (product) {
    case ProductTypesEnum.CrashReporting:
      return 'Crash Reporting';
    case ProductTypesEnum.Pulse:
      return 'Real User Monitoring';
    case ProductTypesEnum.APM:
      return 'APM';
    case ProductTypesEnum.Platform:
      return 'Platform';
    case ProductTypesEnum.UsageCapping:
      return 'Usage Capping';
  }
}

export function productEnumToDisplayString(product: ProductTypesEnum, full: boolean = false) {
  switch (product) {
    case ProductTypesEnum.CrashReporting:
      return 'Crash Reporting';
    case ProductTypesEnum.Pulse:
      return 'Real User Monitoring';
    case ProductTypesEnum.APM:
      return full ? 'Raygun APM' : 'APM';
    case ProductTypesEnum.Platform:
      return 'Platform';
    case ProductTypesEnum.AdditionalUser:
      return 'User';
    case ProductTypesEnum.UsageCapping:
      return 'Usage Capping';
  }
}

export function productEnumToUrlFriendlyString(
  product: ProductTypesEnum,
  lowercase: boolean = false,
) {
  let val: string = '';
  switch (product) {
    case ProductTypesEnum.CrashReporting:
      val = 'CrashReporting';
      break;
    case ProductTypesEnum.Pulse:
      val = 'Pulse';
      break;
    case ProductTypesEnum.APM:
      val = 'APM';
      break;
    case ProductTypesEnum.Platform:
      val = 'Platform';
      break;
    case ProductTypesEnum.AdditionalUser:
      val = 'AdditionalUser';
      break;
    case ProductTypesEnum.UsageCapping:
      val = 'UsageCapping';
      break;
  }
  return lowercase ? val.toLowerCase() : val;
}

export function stringToProductEnum(product: string): ProductTypesEnum {
  switch (product.replace(/\s/g, '').toLowerCase()) {
    case 'crashreporting':
      return ProductTypesEnum.CrashReporting;
    case 'realusermonitoring':
    case 'pulse':
      return ProductTypesEnum.Pulse;
    case 'apm':
      return ProductTypesEnum.APM;
    case 'platform':
      return ProductTypesEnum.Platform;
    case 'additionaluser':
      return ProductTypesEnum.AdditionalUser;
    case 'usagecapping':
      return ProductTypesEnum.UsageCapping;
  }
}