/** @format */

import * as React from 'react';
import { cloneDeep, assign } from 'lodash';

import { Column } from '../tableModel';

import { CellBase } from 'components/table/tableModel';
import { Skeleton } from 'components/skeleton';

export class SkeletonCell extends CellBase {
  accessor = 'opacity';

  render(rowItem: any) {
    const value = this.getValue(rowItem);

    return <Skeleton height="16" fullWidth style={{ opacity: value }} />;
  }
}

export const SkeletonTableData = [
  { opacity: 1 },
  { opacity: 0.8 },
  { opacity: 0.6 },
  { opacity: 0.4 },
  { opacity: 0.2 },
];

export const updateColumnsToSkeletons = (columns: Column[]): Column[] => {
  return cloneDeep(columns).map(col => {
    return assign(col, {
      cell: new SkeletonCell(),
    });
  });
};
