/** @format **/

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { omit } from 'lodash';

import { canInviteUsers, getPlanIdentifier } from 'app/selectors/configuration';
import { EmphasisButton, TextButton } from 'components/ds/button';
import {
  Module,
  ModuleBody,
  ModuleFooterButtonGroup,
  ModuleFooterOnRight,
  ModuleHeader,
  ModuleFooter,
  ModuleFooterContent,
} from 'components/ds/module';

import { ProductPagesState } from 'sections/productpages';

import { AppState } from '../../../interfaces/appState';

import { InviteTeamResource } from '../resources/inviteTeam';

import { RecipientTable } from './recipientTable';
import { InviteTeamModalStateAtom } from '../state';
import * as Selectors from '../selectors';
import * as Models from '../models';
import * as Actions from '../actions';
import { Tooltip } from 'components/tooltip';

type StateProps = {
  recipients: Models.Recipient[];
  isValid: boolean;
  planIdentifier: string;
  canInviteUsers: boolean;
};

type MappedStateProps = {
  isValid: boolean;
};

type DispatchProps = {
  sendTeamInvites: (recipients: Models.Recipient[]) => void;
};

type MappedDispatchProps = {
  sendTeamInvites: () => void;
};

type PassedProps = {
  close: () => void;
};

type Props = MappedStateProps & MappedDispatchProps & PassedProps & StateProps;

export const UnconnectedInviteTeamModule: React.FunctionComponent<Props> = props => (
  <Module forDialog>
    <ModuleHeader heading="Invite your team" />
    <ModuleBody borderBottom>
      <RecipientTable />
    </ModuleBody>
    <ModuleFooter>
      <ModuleFooterContent>
        <a onClick={props.close} href={`/plan/${props.planIdentifier}/teams`}>
          Manage teams
        </a>
      </ModuleFooterContent>

      <ModuleFooterButtonGroup>
        <TextButton onClick={props.close}>Cancel</TextButton>

        {props.canInviteUsers ? (
          <EmphasisButton disabled={!props.isValid} loading={false} onClick={props.sendTeamInvites}>
            Send invites
          </EmphasisButton>
        ) : (
          <Tooltip
            dock={'left'}
            label={'You need to upgrade your plan to invite more team members.'}
          >
            <EmphasisButton disabled>Send invites</EmphasisButton>
          </Tooltip>
        )}
      </ModuleFooterButtonGroup>
    </ModuleFooter>
  </Module>
);

export const InviteTeamModule = connect<MappedStateProps, DispatchProps, PassedProps, Props>(
  (state: InviteTeamModalStateAtom & AppState) => ({
    recipients: Selectors.getNonEmptyRecipientData(state),
    isValid: Selectors.hasValidRecipientData(state),
    planIdentifier: getPlanIdentifier(state),
    canInviteUsers: canInviteUsers(state),
  }),
  (dispatch: Dispatch, props: PassedProps) => ({
    sendTeamInvites: (recipients: Models.Recipient[]) => {
      InviteTeamResource.perform('createteam', { members: recipients }, () => {
        dispatch(Actions.resetRecipients());
        props.close();
      });
    },
  }),
  (stateProps: StateProps, dispatchProps: DispatchProps, passed: PassedProps) => ({
    ...omit(stateProps, 'recipients'),
    ...dispatchProps,
    ...passed,
    sendTeamInvites: () => dispatchProps.sendTeamInvites(stateProps.recipients),
  }),
)(UnconnectedInviteTeamModule);
