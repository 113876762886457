/** @format */

import React from 'react';

import { EchartsMarkLineOptions } from '../markLine';

export type LineChartData = {
  data: number[];
  name: string;
};

export type LineChartResource = {
  data: LineChartData[];
  periods: string[];
  dataType?: LineChartDataType;
};

export enum LineChartDataType {
  Duration,
  Count,
  Number,
}

export type MarkLineKeyOptions = {
  show: boolean;
  lineColor: string;
  labelText: string;
  helpTipLabel?: React.ReactNode;
};

type LineStyle = {
  width: number;
};

/**
 * Refer to the {@link https://echarts.apache.org/en/option.html#series|eCharts series} documentation
 */
export type LineChartSeriesDatum = {
  symbol: 'circle' | 'rect' | 'roundRect' | 'triangle' | 'diamond' | 'pin' | 'arrow' | 'none';
  type: 'line';
  lineStyle: LineStyle;
  name: string;
  data: number[];
  markLine?: EchartsMarkLineOptions;
};

export type LineChartSeries = LineChartSeriesDatum[];
