/** @format **/

enum CYAN {
  cyan200 = '#80DEEA',
  cyan300 = '#4DD0E1',
  cyan500 = '#00BCD4',
  cyan800 = '#00838F',
}

enum BLUE {
  blueLA100 = '#80D8FF',
  blueL400 = '#29B6F6',
  blueL600 = '#039BE5',
  blueRG1000 = '#158FEF',
  blueRG1100 = '#1280D6',
  blueRG1200 = '#1072BF',
}

enum PURPLE {
  purple400 = '#AB47BC',
  purpleDA700 = '#651FFF',
  purpleD700 = '#512DA8',
}

enum PINK {
  pink100 = '#F8BBD0',
  pink300 = '#F06292',
}

enum INDIGO {
  indigo600 = '#3F51B5',
}

enum GREEN {
  green500 = '#4CAF50',
  greenL500 = '#8BC34A',
}

enum LIME {
  lime400 = '#D4E157',
}

enum AMBER {
  amber300 = '#FFD54F',
}

enum YELLOW {
  yellow300 = '#FFF176',
}

enum ORANGE {
  orange400 = '#FFA726',
  orangeD400 = '#F57C00',
}

enum RED {
  red500 = '#F44336',
}

export const GRAPH_COLORS = {
  ...INDIGO,
  ...PINK,
  ...PURPLE,
  ...BLUE,
  ...CYAN,
  ...GREEN,
  ...LIME,
  ...AMBER,
  ...YELLOW,
  ...ORANGE,
  ...RED,
};
