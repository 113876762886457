/**
 * @prettier
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { DashboardState } from 'sections/dashboard/index';

import {
  ModalProps,
  Modal,
  ModalHeaderWithTitle,
  ModalMessage,
  ModalFooter,
  ModalBodyContent,
} from 'components/modal/index';
import { Button } from 'components/button/index';
import { ButtonPatternCancelSubmit } from 'components/button/pattern';
import { Typography } from 'components/text/index';
import { Checkbox } from 'components/checkbox/index';
import { STATUS } from 'constants/status';

import * as Models from '../../../../models';
import * as Actions from '../../../../actions';

type DispatchProps = {
  permanentlyIgnore: (tileId: string, dropErrors: boolean) => void;
};

type PassedProps = {
  tileId: string;
} & ModalProps;

type Props = PassedProps & DispatchProps;

type State = {
  dropErrors: boolean;
};

export class UnconnectedPermanentlyIgnoreModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dropErrors: true,
    };

    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.setState((prev: State) => ({ dropErrors: !prev.dropErrors }));
  }

  onClose() {
    this.props.close(this.props.id);
  }

  onSubmit() {
    this.onClose();
    this.props.permanentlyIgnore(this.props.tileId, this.state.dropErrors);
  }

  render() {
    const cancel = (
      <Button onClick={this.onClose} type="white" block size={36}>
        Abort!
      </Button>
    );

    const submit = (
      <Button onClick={this.onSubmit} type="green" block size={36}>
        Permanently Ignore
      </Button>
    );

    const footer = (
      <ModalFooter smallPadding>
        <ButtonPatternCancelSubmit submit={submit} cancel={cancel} />
      </ModalFooter>
    );

    return (
      <Modal
        header={<ModalHeaderWithTitle>Permanently Ignore</ModalHeaderWithTitle>}
        footer={footer}
        modalBody={{ noPadding: true }}
        {...this.props}
      >
        <ModalMessage color="yellow">
          <Typography>
            <p>
              By marking errors as permanently ignored you will not receive any further
              notifications about these errors.
            </p>
          </Typography>
        </ModalMessage>
        <ModalBodyContent>
          <Checkbox onToggled={this.onChange} isChecked={this.state.dropErrors}>
            Do not store new occurrences of these errors while permanently ignored or count them
            towards my plan quota.
          </Checkbox>
        </ModalBodyContent>
      </Modal>
    );
  }
}

const ConnectedPermIgnoreModal = connect<null, DispatchProps, PassedProps>(
  null,
  (dispatch: Dispatch) => ({
    permanentlyIgnore: (tileId: string, drop: boolean) =>
      dispatch(
        Actions.setErrorGroupStatus({
          tileId,
          status: STATUS.PERM_IGNORED,
          drop,
        }),
      ),
  }),
)(UnconnectedPermanentlyIgnoreModal);

export { ConnectedPermIgnoreModal as PermIgnoreModal };
