/** @format **/

import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { AppState } from 'interfaces/appState';
import * as Messages from 'interfaces/messages';
import { applicationIdentifier } from 'app/selectors/application';
import { Formatting } from 'utils/date';

import { IconBefore } from 'components/icon';
import { SupportLink } from 'components/supportLink';

type PlanUsagePassedProps = Messages.Message;

type PlanUsageStateProps = {
  upgradeUrl: string;
  settingsUrl: string;
};

type PlanUsageProps = PlanUsageStateProps & PlanUsagePassedProps;

const generateMessage = (props: PlanUsageProps) => {
  switch (props.Key) {
    case Messages.MessageTypes.PlanUsageExceeded:
      return props.upgradeUrl ? (
        <p>
          You've used <strong>100% </strong>of the monthly processing quota for your plan and the
          oldest error data will be removed.
          <br />
          <a href={props.upgradeUrl}>Upgrade</a> to our new monthly quota based model to avoid data
          removal.
        </p>
      ) : (
        <p>
          You've used <strong>100% </strong>of the monthly processing quota for your plan and the
          oldest error data will be removed.
        </p>
      );
    case Messages.MessageTypes.PlanMonthlyProcessingUsageExceededAutoUpgradeable:
      return props.settingsUrl ? (
        <p>
          <strong>Important!</strong> Your plan is set to be upgraded automatically as you've used{' '}
          <strong>100%</strong> of your monthly processing quota.
          <br />
          If you wish to prevent this and reject all new errors instead, you can{' '}
          <a href={props.settingsUrl}>change this here</a>.
        </p>
      ) : (
        <p>
          <strong>Important!</strong>
          Your plan is set to be upgraded automatically as you've used <strong>100%</strong> of your
          monthly processing quota.
        </p>
      );
    case Messages.MessageTypes.PlanMonthlyProcessingUsageCapped:
      const productType = get(props, 'Data.ProductType');
      const productName = get(props, 'Data.ProductName');
      const planTypeMessage = productType && productName ? `${productType} - ${productName}` : ``;
      const upgradeUrlMessage = props.upgradeUrl ? (
        <span>
          <a href={props.upgradeUrl}>Upgrade here</a> to continue recording.
        </span>
      ) : null;
      const billingCycle = get(props, 'Data.BillingCycleStartDate');
      const billingCycleMessage = billingCycle ? (
        <span>
          <br />
          Your next billing cycle starts on <strong>{Formatting.format(billingCycle, 'll')}</strong>
          .
        </span>
      ) : (
        ''
      );

      return (
        <p>
          Important! You've used <strong>100%</strong> of the monthly processing quota for your
          {planTypeMessage} plan.
          {upgradeUrlMessage}
          {billingCycleMessage}
        </p>
      );
    case Messages.MessageTypes.PlanMonthlyProcessingUsageApproachingAutoUpgradeable:
      const settingsUrlMessage = props.settingsUrl ? (
        <span>
          If you wish to prevent this and reject all new errors instead, you can{' '}
          <a href={props.settingsUrl}>change this here</a>.
        </span>
      ) : null;

      return (
        <p>
          <strong>Important!</strong> Your plan is approaching its storage quota and is set to be
          automatically upgraded to the next plan level.
          {settingsUrlMessage}
        </p>
      );
    default:
      return null;
  }
};

const DumbPlanUsage: React.FunctionComponent<PlanUsageProps> = (props: PlanUsageProps) => {
  return generateMessage(props);
};

export const PlanUsageExceeded = connect<PlanUsageStateProps, {}, PlanUsagePassedProps>(
  (state: AppState) => ({
    upgradeUrl: state.app.configuration.UpgradeUrl,
    settingsUrl: state.app.configuration.PlanSettingsLink,
  }),
)(DumbPlanUsage);

type InviteApplicationUsersPassedProps = Messages.InviteApplicationUsers;

type InviteApplicationUsersStateProps = {
  applicationId: string;
};

type InviteApplicationUsersProps = InviteApplicationUsersPassedProps &
  InviteApplicationUsersStateProps;

const DumbInviteApplicationUsers: React.FunctionComponent<InviteApplicationUsersProps> = (
  props: InviteApplicationUsersProps,
) => {
  return (
    <p>
      All Raygun accounts support unlimited users.{' '}
      <a href={`/settings/${props.applicationId}`}>Add your team here</a>.
    </p>
  );
};

export const InviteApplicationUsers = connect<
  InviteApplicationUsersStateProps,
  {},
  InviteApplicationUsersPassedProps
>((state: AppState) => ({
  applicationId: applicationIdentifier(state),
}))(DumbInviteApplicationUsers);

type IntegrationFailureProps = Messages.InviteApplicationUsers;

export const IntegrationFailure: React.FunctionComponent<IntegrationFailureProps> = (
  props: IntegrationFailureProps,
) => {
  const name = get(props, 'Data.Name', '');
  return (
    <IconBefore
      icon={
        <div className={`integration-icon ${name.toLowerCase()}-icon icon-16 page-banner__icon`} />
      }
    >
      Your integration with <strong>{name}</strong> has been disabled - We don't seem to be able to
      connect with <strong>{name}</strong>. <a href={get(props, 'Data.Link', '')}>Fix now</a>
    </IconBefore>
  );
};

type ExpiringCardProps = Messages.ExpiringCard;

export const ExpiredCard: React.FunctionComponent<ExpiringCardProps> = (
  props: ExpiringCardProps,
) => {
  return (
    <p>
      Take action! Looks like your payment card is due to expire soon.{' '}
      <a href={get(props, 'Data.BillingUpdateUrl', '')}>Update details</a>
    </p>
  );
};

type EmailBlacklistedPassedProps = Messages.EmailBlacklisted;

type EmailBlacklistedStateProps = {
  timeZoneName: string;
};

type EmailBlacklistedProps = EmailBlacklistedPassedProps & EmailBlacklistedStateProps;

export const EmailBlacklisted: React.FunctionComponent<EmailBlacklistedProps> = (
  props: EmailBlacklistedProps,
) => {
  const formattedDate = Formatting.format(get(props, 'Data.Date'), 'll');
  return (
    <p>
      Your email address {props.Data.Email} has been blacklisted, due to a permanent non-delivery
      bounce response on {formattedDate}. Please <SupportLink>contact us</SupportLink> to continue
      receiving emails.
    </p>
  );
};

type DunningProcessStateProps = {
  timeZoneName: string;
};

type DunningProcessPassedProps = Messages.DunningProcess;

type DunningProcessProps = DunningProcessStateProps & DunningProcessPassedProps;

export const DunningProcess: React.FunctionComponent<DunningProcessProps> = (
  props: DunningProcessProps,
) => {
  const cancellationDate = Formatting.format(props.Data.formattedCancellationDate, 'll');

  return props.Data.IsOwner ? (
    <p>
      We are having trouble authorizing your payment for your plan '{props.Data.PlanName}'. Please{' '}
      <a href={props.Data.PaymentUpdateUrl}>update your payment method</a> or contact your bank to
      avoid service disruption.
      <br />
      <strong>
        Your subscription will be cancelled after {props.Data.AttemptsUntilCancellation} more
        attempts on the {cancellationDate}
      </strong>
      .
    </p>
  ) : (
    <p>
      We are having trouble authorizing your payment for your plan '{props.Data.PlanName}'. Please
      advise a plan owner to avoid service disruption.
      <br />
      <strong>
        Your subscription will be cancelled after {props.Data.AttemptsUntilCancellation} more
        attempts on the {cancellationDate}
      </strong>
      .
    </p>
  );
};

type AdminGenericMessageProps = Messages.AdminGenericMessage;

export const AdminGenericMessage: React.FunctionComponent<AdminGenericMessageProps> = (
  props: AdminGenericMessageProps,
) => {
  return <div dangerouslySetInnerHTML={{ __html: props.Data.MessageHtml }} />;
};

type AzureSubscriptionSuspendedProps = Messages.AzureSubscriptionSuspended;

export const AzureSubscriptionSuspended: React.FunctionComponent<
  AzureSubscriptionSuspendedProps
> = () => {
  return (
    <p>
      <strong>Important!</strong> Your subscription has been suspended. Confirm your{' '}
      <a href="https://portal.azure.com/">payment details in Azure</a> to reinstate your
      subscription.
    </p>
  );
};
