/** @format **/

import { createSelector } from 'reselect';
import { keys, values, filter, isEmpty, isNil, get } from 'lodash';

import { emailAddress } from 'utils/validation';
import { AppState } from 'interfaces/appState';

import { InviteTeamModalStateAtom } from './state';
import * as Models from './models';
import { InviteTeamResource } from './resources/inviteTeam';
import { allProductsOnTrial } from 'app/selectors/configuration';
import { TeamMemberErrorCode } from 'sections/productpages/teams/models';

const inviteTeamModalSelector = (state: InviteTeamModalStateAtom) => state.inviteTeamModal;

export const getRecipientData = createSelector(
  inviteTeamModalSelector,
  state => state.recipientData,
);

export const getRecipientIds = createSelector(
  getRecipientData,
  recipientData => keys(recipientData),
);

export const getNonEmptyRecipientData = createSelector(
  getRecipientData,
  (recipientData): Models.Recipient[] =>
    filter(values(recipientData), r => !isEmpty(r.emailAddress)),
);

export const hasValidRecipientData = createSelector(
  getNonEmptyRecipientData,
  data => filter(data, r => emailAddress(r.emailAddress)).length > 0,
);

export const hasSeenInviteModal = createSelector(
  inviteTeamModalSelector,
  state => state.seenInviteModal,
);

export const displaySidebarNotification = createSelector(
  hasSeenInviteModal,
  allProductsOnTrial,
  (hasClicked, onTrial) => onTrial && !hasClicked,
);

export function hasError(state: AppState) {
  const errors = InviteTeamResource.getGlobalError(state);
  return errors && errors.length > 0;
}

export function getError(state: AppState) {
  const errors = InviteTeamResource.getGlobalError(state);

  if (isNil(errors)) {
    return '';
  }

  return errors
    .map(e => {
      switch (e.errorCode) {
        case TeamMemberErrorCode.TeamDoesNotExist:
          return 'Could not find the team selected.';
        case TeamMemberErrorCode.CannotAddTeamMembers:
          return 'This plan does not have access to add team members.';
        case TeamMemberErrorCode.EmailAddressBlocked:
          return 'An email address is blocked.';
        case TeamMemberErrorCode.InvalidEmailAddress:
          return 'Please enter a valid email address.';
        case TeamMemberErrorCode.FailedToSendEmail:
          return 'Failed to send an invitation. They might already be a user.';
      }
    })
    .join(' ');
}
