/** @format **/

import createActionFactory from 'typescript-fsa';

import { SORTING_DIRECTION } from 'interfaces/sorting';
import {
  ResourceId,
  Resource,
  ResourceObject,
  ResourceError,
  PaginationDirection,
} from '../models';

const createAction = createActionFactory('resources');

type ResourcePayloadBase = {
  resourceName: string;
};
type ResourceObjectPayloadBase = {
  resourceId: ResourceId;
  resourceName: string;
};
type CompletionCallback = {
  completionCallback?: (response: any) => void;
};

export type StartedPayload = ResourceObjectPayloadBase & CompletionCallback;
export type SucceededPayload = ResourceObjectPayloadBase & { response: ResourceObject[] };
export type FailedPayload = ResourceObjectPayloadBase & { errors: ResourceError[] };

export const fetchingResourceStarted = createAction<StartedPayload>('FETCHING_RESOURCE_STARTED');
export const fetchingResourceSucceeded = createAction<SucceededPayload>(
  'FETCHING_RESOURCE_SUCCEEDED',
);
export const fetchingResourceFailed = createAction<FailedPayload>('FETCHING_RESOURCE_FAILED');

export type PatchingResourceStartedPayload = StartedPayload & { updates: object };
export const patchingResourceStarted = createAction<PatchingResourceStartedPayload>(
  'PATCHING_RESOURCE_STARTED',
);
export const patchingResourceSucceeded = createAction<SucceededPayload>(
  'PATCHING_RESOURCE_SUCCEEDED',
);
export const patchingResourceFailed = createAction<FailedPayload>('PATCHING_RESOURCE_FAILED');

type ListResourcePayloadBase = {
  resourceName: string;
};

type PostingSucceededPayload = ListResourcePayloadBase & { response: ResourceObject[] };
type PostingFailedPayload = ListResourcePayloadBase & { errors: ResourceError[] };

export type PostingResourceStartedPayload = ListResourcePayloadBase & {
  updates: any;
} & CompletionCallback;
export const postingResourceStarted = createAction<PostingResourceStartedPayload>(
  'POSTING_RESOURCE_STARTED',
);
export const postingResourceSucceeded = createAction<PostingSucceededPayload>(
  'POSTING_RESOURCE_SUCCEEDED',
);
export const postingResourceFailed = createAction<PostingFailedPayload>('POSTING_RESOURCE_FAILED');

export type ResourceActionStartedPayload = ListResourcePayloadBase & {
  payload: any;
  type: string;
} & CompletionCallback;
type ResourceActionSucceeded = ListResourcePayloadBase & { type: string };
type ResourceActionFailedPayload = ListResourcePayloadBase & {
  errors: ResourceError[];
  type: string;
};

export const resourceActionStarted = createAction<ResourceActionStartedPayload>(
  'RESOURCE_ACTION_STARTED',
);
export const resourceActionSucceeded = createAction<ResourceActionSucceeded>(
  'RESOURCE_ACTION_SUCCEEDED',
);
export const resourceActionFailed = createAction<ResourceActionFailedPayload>(
  'RESOURCE_ACTION_FAILED',
);

export const deletingResourceStarted = createAction<StartedPayload>('DELETING_RESOURCE_STARTED');
export const deletingResourceSucceeded = createAction<ResourceObjectPayloadBase>(
  'DELETING_RESOURCE_SUCCEEDED',
);
export const deletingResourceFailed = createAction<FailedPayload>('DELETING_RESOURCE_FAILED');

export type ListStartedPayload = ListResourcePayloadBase &
  CompletionCallback & {
    replaceResources: boolean;
    paginationDirection?: PaginationDirection;
  };
export type ListSucceededPayload = ListResourcePayloadBase & {
  response: ResourceObject[];
  replaceResources: boolean;
};
export type ListFailedPayload = ListResourcePayloadBase & { errors: ResourceError[] };

export const fetchingListResourceStarted = createAction<ListStartedPayload>(
  'FETCHING_LIST_RESOURCE_STARTED',
);
export const fetchingListResourceSucceeded = createAction<ListSucceededPayload>(
  'FETCHING_LIST_RESOURCE_SUCCEEDED',
);
export const fetchingListResourceFailed = createAction<ListFailedPayload>(
  'FETCHING_LIST_RESOURCE_FAILED',
);

export type CreateSavingSagasForResourcePayload = {
  resource: Resource<any, any>;
};
export const createNotificationSagaForResource = createAction<CreateSavingSagasForResourcePayload>(
  'CREATE_NOTIFICATION_SAGA_FOR_RESOURCE',
);

export type InitializeResourcePayload = ResourcePayloadBase & {
  paginated: boolean;
  sortable: boolean;
  filterable: boolean;
};
export const initializeResource = createAction<InitializeResourcePayload>('INITIALIZE_RESOURCE');

export type UpdateSortingPayload = ResourcePayloadBase & {
  sortDirection: SORTING_DIRECTION;
  attribute: string;
};
export const updateSorting = createAction<UpdateSortingPayload>('UPDATE_SORT_PAYLOAD');

export type UpdatePaginationBatchSizePayload = ResourcePayloadBase & { batchSize: number };
export const updatePaginationBatchSize = createAction<UpdatePaginationBatchSizePayload>(
  'UPDATE_BATCH_SIZE',
);

export type UpdatePaginationPayload = ResourcePayloadBase & {
  nextCursor: string;
  prevCursor: string;
  totalRecords: number;
};
export const updatePagination = createAction<UpdatePaginationPayload>('UPDATE_PAGINATION_CURSORS');

export type UpdateFiltersPayload = ResourcePayloadBase & {
  filters: any[];
};
export const updateFilters = createAction<UpdateFiltersPayload>('UPDATE_FILTERS');

export const clearResources = createAction<ResourcePayloadBase>('CLEAR_RESOURCES');

export type ResetPaginationPayload = ResourcePayloadBase & { resetBatchSize: boolean };
export const resetPagination = createAction<ResetPaginationPayload>('RESET_PAGINATION');

export type StoreResourcePayload = ResourcePayloadBase & { object: ResourceObject };
export const storeResource = createAction<StoreResourcePayload>('STORE_RESOURCE');
