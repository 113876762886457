/**
 * @prettier
 */
/* tslint:disable:max-classes-per-file */

import React, { Component } from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { createComponentWithProxy } from 'react-fela';

import {
  withInteractivity,
  WithInteractivitySuppliedProps,
} from 'components/ds/hoc/withInteractivity';

import { COLORS } from 'app/theme/materialDS';

import { Omit } from 'utils/types';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { screenSmallOnly } from 'fela-rules/breakpoints';

import { BaseEventNames, BaseEventProps, BasePassthroughNames, BasePassthroughProps } from '../../';

type PublicBaseButtonProps = {
  // display
  disabled: boolean;
  block: boolean;
  loading: boolean;
  forDropdownTrigger: boolean;
  icon?: string;

  fullWidth?: boolean;
  fullWidthOnMobile?: boolean;

  // colors
  textColor: COLORS;
  backgroundColor: COLORS;

  disabledTextColor: COLORS;
  disabledBackgroundColor: COLORS;

  hoverBackgroundColor: COLORS;
  pressedBackgroundColor: COLORS;

  // Link behaviour
  isLink?: boolean;
  as?: string;
  href?: string;
  target?: string;
} & BaseEventProps<HTMLButtonElement> &
  BasePassthroughProps &
  React.ButtonHTMLAttributes<any>;

type BaseButtonWrapperProps = PublicBaseButtonProps & WithInteractivitySuppliedProps;

const StyledBaseButton = (props: BaseButtonWrapperProps & ThemeType): IStyle => {
  return applyModifiers(
    [
      props.loading,
      {
        backgroundColor: props.theme.ds.getColorByName('blue1300'),
      },
    ],
    [
      props.loading || props.disabled,
      {
        pointerEvents: 'none',
      },
    ],
    [
      props.fullWidth,
      {
        width: '100%',
      },
    ],
    [
      props.fullWidthOnMobile,
      {
        ...screenSmallOnly({
          width: '100%',
        }),
      },
    ],
    [
      props.disabled,
      {
        color: props.theme.ds.getColorByName(props.disabledTextColor),
        backgroundColor: props.theme.ds.getColorByName(props.disabledBackgroundColor),
        cursor: 'not-allowed',
        transition: 'none',
      },
    ],
    [
      (props.hovered || props.focused) && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName(props.hoverBackgroundColor),
      },
    ],
    [
      props.pressed && !props.disabled,
      {
        backgroundColor: props.theme.ds.getColorByName(props.pressedBackgroundColor),
      },
    ],
    [
      props.block,
      {
        display: 'block',
        width: '100%',
      },
    ],
    [
      props.forDropdownTrigger,
      {
        paddingRight: '32px',
      },
    ],
    [
      props.isLink,
      {
        lineHeight: '40px',
        width: 'auto',
        color: props.theme.ds.getColorByName(props.textColor),
        ':hover': {
          color: props.theme.ds.getColorByName(props.textColor),
        },
        ':active': {
          color: props.theme.ds.getColorByName(props.textColor),
        },
      },
    ],
  )({
    display: 'inline-block',
    backgroundColor: props.theme.ds.getColorByName(props.backgroundColor),
    color: props.theme.ds.getColorByName(props.textColor),
    transition: 'background-color 200ms linear',
    maxWidth: '1000px',
    minWidth: '96px',
    height: '40px',
    border: 'none',
    cursor: 'pointer',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: 0,
    paddingTop: 0,
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '2px',
    fontFamily: props.theme.ds.fonts.standard,
    position: 'relative',
    ':focus': {
      outline: 0,
    },
    ['-webkit-tap-highlight-color' as any]: props.theme.ds.colors.special.transparent,
  });
};

const FelaBaseButton = createComponentWithProxy<BaseButtonWrapperProps>(
  StyledBaseButton,
  'button',
  ['disabled'],
);

class BaseButtonWrapperWithoutHocs extends Component<BaseButtonWrapperProps> {
  render() {
    return <FelaBaseButton {...this.props} />;
  }
}

const BaseButtonWrapper = withInteractivity<PublicBaseButtonProps, BaseButtonWrapperProps>(
  BaseButtonWrapperWithoutHocs,
);

export type BaseDefaultProps = Pick<
  PublicBaseButtonProps,
  'block' | 'disabled' | 'loading' | 'forDropdownTrigger'
>;
export const DefaultProps: BaseDefaultProps = {
  block: false,
  disabled: false,
  loading: false,
  forDropdownTrigger: false,
};

class BaseButton extends Component<PublicBaseButtonProps> {
  static defaultProps: BaseDefaultProps = DefaultProps;

  render() {
    const { value, children, loading, ...props } = this.props;

    return (
      <BaseButtonWrapper as={props.isLink ? 'a' : 'button'} loading={loading} {...props}>
        {(!!props.icon || loading) && (
          <ButtonIcon
            type={loading ? 'loading-small' : props.icon}
            spinning={loading}
            dropdownTrigger={false}
          />
        )}

        <ButtonIconTextWrapper icon={!!props.icon || loading}>
          {value}
          {children}
        </ButtonIconTextWrapper>

        {props.forDropdownTrigger && (
          <ButtonIcon type="dropdown" spinning={false} dropdownTrigger />
        )}
      </BaseButtonWrapper>
    );
  }
}

type ButtonIconWrapperProps = {
  spinning: boolean;
  dropdownTrigger: boolean;
};
const StyledButtonIconWrapper = (props: ButtonIconWrapperProps): IStyle =>
  applyModifiers(
    [
      props.spinning,
      {
        ['> .raygun-icon' as any]: {
          animation: 'rotation 2s infinite linear',
        },
      },
    ],
    [
      props.dropdownTrigger,
      {
        ['> .raygun-icon' as any]: {
          left: 'auto',
          right: '8px',
        },
      },
    ],
  )({
    ['> .raygun-icon' as any]: {
      display: 'inline',
      position: 'absolute',
      left: '16px',
      bottom: '11px',
    },
  });
const ButtonIconWrapper = createComponent<ButtonIconWrapperProps>(StyledButtonIconWrapper, 'span', [
  ...BaseEventNames,
  ...BasePassthroughNames,
]);

type ButtonIconTextWrapperProps = {
  icon: boolean;
};
const StyledButtonIconTextWrapper = (props: ButtonIconTextWrapperProps): IStyle =>
  applyModifiers([
    props.icon,
    {
      paddingLeft: '24px',
    },
  ])({});
const ButtonIconTextWrapper = createComponent<ButtonIconTextWrapperProps>(
  StyledButtonIconTextWrapper,
  'span',
);

type ButtonIconProps = {
  spinning: boolean;
  type: string;
  dropdownTrigger: boolean;
};
const ButtonIcon: React.FunctionComponent<ButtonIconProps> = props => (
  <ButtonIconWrapper spinning={props.spinning} dropdownTrigger={props.dropdownTrigger}>
    <span className={`raygun-icon icon--${props.type} icon-16`} />
  </ButtonIconWrapper>
);

export { BaseButton, PublicBaseButtonProps as BaseButtonProps };
