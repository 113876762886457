/**
 * @prettier
 */

import { Action as NavigationAction } from 'redux-first-router';
import { ActionCreator, EmptyActionCreator, Action } from 'typescript-fsa';
import {
  call,
  put,
  select,
  takeLatest,
  HelperFunc0,
  Pattern,
  ForkEffect,
} from 'redux-saga/effects';
import { get, omit, isNil, fromPairs } from 'lodash';

import { fetchFromAPI } from 'utils/fetching';
import { apiKey } from 'app/selectors/application';
import { createSavingSaga } from 'app/notifications/sagas';
import { isFunction } from 'utils/types';
import * as RouterSelectors from 'selectors/router';
import { isRedirect } from 'utils/routing';
import { QueryParameters } from 'utils/fetching/utils';

export { createSavingSaga };

let baseUri: string;

export function setBaseUri(t: string) {
  baseUri = t;
}

type apiFunctions = (
  path: string,
  apiKey: string,
  parameters?: QueryParameters,
) => Promise<Response>;

export function createFetchSaga<S = {}, P = {}>(
  actionCreators: {
    start?: EmptyActionCreator;
    startWithPayload?: (payload: P) => Action<any>[];
    error?: ActionCreator<Error>;
    errorWithPayload?: (e: Error, payload: P) => Action<any>[];
    done?: ActionCreator<any>;
    doneWithPayload?: (response: any, payload: P, state?: S) => Action<any>[];
  },
  apiUrl: string | { (payload: P, state: S): string },
  queryParameters: QueryParameters | { (state: S, payload: P): QueryParameters } = {},
  apiFunction: apiFunctions = fetchFromAPI,
  options: { includeBaseName?: boolean } = { includeBaseName: true },
) {
  return function*(action: { payload: P }) {
    const payload = get(action, 'payload') as P;
    const state = yield select();

    try {
      if (actionCreators.start) {
        yield put(actionCreators.start());
      } else {
        const actions = actionCreators.startWithPayload(payload);

        for (const action of actions) {
          yield put(action);
        }
      }

      const base = options.includeBaseName ? baseUri : '';

      const data = yield call(
        apiFunction,
        `${base}${isFunction(apiUrl) ? apiUrl(payload, state) : apiUrl}`,
        apiKey(state),
        isFunction(queryParameters) ? queryParameters(state, payload) : queryParameters,
      );

      if (actionCreators.done) {
        yield put(actionCreators.done(data));
      } else {
        const actions = actionCreators.doneWithPayload(data, payload, state);

        for (const action of actions) {
          yield put(action);
        }
      }
    } catch (e) {
      console.error(e);

      if (actionCreators.error) {
        yield put(actionCreators.error(e));
      } else {
        const actions = actionCreators.errorWithPayload(e, payload);

        for (const action of actions) {
          yield put(action);
        }
      }
    }
  };
}

export function* persistToQueryString(queryKey: string, value: string) {
  const state = yield select();

  const query = omit(RouterSelectors.getQuery(state), queryKey);

  if (!isNil(value) && value.length > 1) {
    query[queryKey] = value;
  }

  const action = {
    type: RouterSelectors.getLocation(state),
    payload: RouterSelectors.getPayload(state),
    meta: {
      query,
      location: {
        kind: 'redirect',
      },
    },
  };
  yield put(action);
}

export function* persistObjectToQueryString(
  object: { [key: number]: any } | { [key: string]: any },
) {
  const state = yield select();

  const action = {
    type: RouterSelectors.getLocation(state),
    payload: RouterSelectors.getPayload(state),
    meta: {
      query: {
        ...RouterSelectors.getQuery(state),
        ...object,
      },
      location: {
        kind: 'redirect',
      },
    },
  };
  yield put(action);
}

export function* persistMultipleToQueryString(queryParams: [string, string][]) {
  const state = yield select();

  const queryStringItems = fromPairs(queryParams);

  const action = {
    type: RouterSelectors.getLocation(state),
    payload: RouterSelectors.getPayload(state),
    meta: {
      query: {
        ...RouterSelectors.getQuery(state),
        ...queryStringItems,
      },
      location: {
        kind: 'redirect',
      },
    },
  };
  yield put(action);
}

function* takeLatestNavActionHelper<A extends NavigationAction>(worker: HelperFunc0<A>, action: A) {
  if (!isRedirect(action)) {
    yield call(worker, action);
  }
}

export function takeLatestNavAction<A extends NavigationAction>(
  pattern: Pattern,
  worker: HelperFunc0<A>,
): ForkEffect {
  return takeLatest<A, HelperFunc0<A>>(pattern, takeLatestNavActionHelper, worker);
}

export function anyRoutingAction(action: Action<NavigationAction>) {
  return !isNil(action.type.match(/routing\/*/));
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}
