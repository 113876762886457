/** @format */

import * as React from 'react';

import { Container as HeaderContainer } from 'components/page/header/container';
import { Title as HeaderTitle } from 'components/page/header/title';

export const Header: React.FunctionComponent<{}> = () => {
  return (
    <HeaderContainer>
      <HeaderTitle title="What's New" />
    </HeaderContainer>
  );
};

export default Header;
