/** @format **/

import React from 'react';
import { ReactComponent } from 'utils/types';

export type WithToggleableSuppliedProps = {
  toggled: boolean;
  toggle: () => void;
};
type WithToggleableState = {
  toggled: boolean;
};

export function withToggleable<TPublicProps, TFullProps extends WithToggleableSuppliedProps>(
  Component: ReactComponent<TFullProps>,
): React.ComponentType<TPublicProps> {
  return (class WithPressed extends React.Component<TFullProps, WithToggleableState> {
    constructor(props: TFullProps) {
      super(props);

      this.state = {
        toggled: false,
      };
    }

    render() {
      return (
        <Component
          {...this.props}
          toggled={this.state.toggled}
          toggle={() => this.setState({ toggled: !this.state.toggled })}
        />
      );
    }
  } as unknown) as React.ComponentType<TPublicProps>;
}
