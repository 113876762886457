/** @format */
import React from 'react';
import { connect, Dispatch } from 'react-redux';
import { get } from 'lodash';

import { AppState } from 'interfaces/appState';
import { getDashboardForPage } from 'utils/dashboard';

import { Padding } from 'components/layout';
import { UtilityIconButton } from 'components/ds/button';
import { Medium, Small } from 'components/breakpoints';
import { Icon } from 'components/icon';
import * as Selectors from 'modules/dashboard/selectors';
import { State as DashboardState } from 'modules/dashboard';

import { isSwitcherOpen } from '../../../selectors';
import { toggleSwitcher } from '../../../actions';
import { SWITCHER_TYPE } from '../../../models';
import { Container, Name } from './styles';

type StateProps = {
  open: boolean;
  name: string;
  isFullscreen: boolean;
};

type DispatchProps = {
  toggleSwitcher: () => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedDashboardTrigger: React.FunctionComponent<Props> = props => {
  const icon = props.open ? 'arrow-up' : 'dropdown';

  if (props.isFullscreen) {
    return (
      <Padding top="12" bottom="12">
        <Name>{props.name}</Name>
      </Padding>
    );
  }

  return (
    <Container
      onClick={props.toggleSwitcher}
      role="button"
      aria-label={props.open ? 'Close application switcher' : 'Open application switcher'}
    >
      <Padding right="8">
        <Name>{props.name}</Name>
      </Padding>
      <Medium andAbove>
        <UtilityIconButton icon={icon} />
      </Medium>
      <Small>
        <Icon set="icomoon" type={icon} />
      </Small>
    </Container>
  );
};

const ConnectedDashboardTrigger = connect<StateProps, DispatchProps, SuppliedProps, DashboardState>(
  state => ({
    name: get(getDashboardForPage(), 'name', ''),
    open: isSwitcherOpen(state),
    isFullscreen: Selectors.isFullscreen(state),
  }),
  dispatch => ({
    toggleSwitcher: () => dispatch(toggleSwitcher(SWITCHER_TYPE.Dashboard)),
  }),
)(UnconnectedDashboardTrigger);

export { ConnectedDashboardTrigger as DashboardTrigger };
