/** @format **/

import React from 'react';
import { Checkbox } from 'components/ds/selection';
import { MenuItem } from './item';
import { PropsOfWithDefaults } from 'utils/types';

export type MenuItemCheckboxProps = {
  label: string;
  labelFontWeight?: number;
  textEllipsis?: boolean;
  selected?: boolean;
  spacing?: boolean;
  disabled?: boolean;
  rightIcon?: boolean;
} & PropsOfWithDefaults<typeof Checkbox, typeof Checkbox.defaultProps>;

export const MenuItemCheckbox: React.FunctionComponent<MenuItemCheckboxProps> = ({
  label,
  labelFontWeight,
  textEllipsis,
  selected,
  spacing,
  disabled,
  rightIcon,
  ...checkboxProps
}) => {
  return (
    <MenuItem
      label={label}
      labelFontWeight={labelFontWeight}
      textEllipsis={textEllipsis}
      as="label"
      checkbox
      selected={selected}
      spacing={spacing}
      disabled={disabled}
      rightIcon={rightIcon}
    >
      <Checkbox disabled={disabled} disabledColor={'grey300'} {...checkboxProps} />
    </MenuItem>
  );
};
