/** @format */
import React from 'react';
import { connect, Dispatch } from 'react-redux';
import { get } from 'lodash';

import { AppState } from 'interfaces/appState';
import { getApplicationForPage } from 'utils/application';
import { addE2EIdentifier } from 'utils/end-to-end';

import { Padding } from 'components/layout';
import { UtilityIconButton } from 'components/ds/button';
import { Avatar } from 'components/avatar';
import { Medium, Large } from 'components/breakpoints';
import { Icon } from 'components/icon';

import { isSwitcherOpen } from '../../../selectors';
import { toggleSwitcher } from '../../../actions';
import { SWITCHER_TYPE } from '../../../models';
import { Container, Name } from './styles';

type StateProps = {
  open: boolean;
  avatar: string;
  name: string;
};

type DispatchProps = {
  toggleSwitcher: () => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedApplicationTrigger: React.FunctionComponent<Props> = props => {
  const icon = props.open ? 'arrow-up' : 'dropdown';

  return (
    <Container
      onClick={props.toggleSwitcher}
      role="button"
      aria-label={props.open ? 'Close application switcher' : 'Open application switcher'}
      id={addE2EIdentifier('e2e-toggle-app-switcher')}
    >
      <Padding right="8">
        <Avatar radius url={props.avatar} alt={`${props.name} application avatar`} size={24} />
      </Padding>
      <Large andAbove>
        <Padding right="4">
          <Name>{props.name}</Name>
        </Padding>
        <UtilityIconButton icon={icon} />
      </Large>
      <Medium andBelow>
        <Medium>
          <Padding right="8">
            <Name>{props.name}</Name>
          </Padding>
        </Medium>
        <Icon set="icomoon" type={icon} />
      </Medium>
    </Container>
  );
};

const ConnectedApplicationTrigger = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => {
    const app = getApplicationForPage();
    return {
      name: get(app, 'name', ''),
      avatar: get(app, 'avatarUrl', ''),
      open: isSwitcherOpen(state),
    };
  },
  (dispatch: Dispatch<AppState>) => ({
    toggleSwitcher: () => dispatch(toggleSwitcher(SWITCHER_TYPE.Application)),
  }),
)(UnconnectedApplicationTrigger);

export { ConnectedApplicationTrigger as ApplicationTrigger };
