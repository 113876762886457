/**
 * @prettier
 */

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { get, isNil } from 'lodash';

import { AppState } from 'interfaces/appState';
import { ErrorBoundary } from 'components/errorBoundary';
import { SidebarLayout } from 'components/layout';

import { WhatsNewItem } from '../models';
import * as Selectors from '../selectors';
import * as Actions from '../actions';
import * as Constants from '../constants';

import { FilterBar } from '../components/filterBar';
import { TagFilterBar } from '../components/tagFilterBar';
import { Announcement } from '../components/announcement';
import { WhatsNewAd } from '../components/whatsNewAd';

type StateProps = {
  mostRecentWhatsNewItem: WhatsNewItem;
  lastViewedItemDate: Date;
  pinnedPost: WhatsNewItem;
  filteredWhatsNewItems: WhatsNewItem[];
};
type DispatchProps = {
  updateLastViewed: (date: Date) => void;
  forceFetchWhatsNewItems: () => void;
};
type Props = StateProps & DispatchProps;

export const WhatsNewAnnouncementItem: React.FunctionComponent<
  WhatsNewItem & { pinned?: boolean }
> = props => {
  const { utmTracking = '', author } = props;
  const { title, avatarUrl } = Constants.getAuthorInfo(author);

  const formattedUTM =
    utmTracking.charAt(0) === '?'
      ? encodeURIComponent(utmTracking.substring(1, utmTracking.length))
      : encodeURIComponent(utmTracking);

  return (
    <Announcement
      key={props.date.getTime()}
      cardType={props.type}
      issueDate={props.date}
      heading={props.title}
      author={author}
      authorRole={title}
      authorAvatarImg={avatarUrl}
      authorAvatarImgAlt={`${props.author}'s avatar`}
      category={props.type.toString()}
      contentImageSrc={`${(window as any).RaygunConfiguration.BlogUrl}/${props.featuredImage}`}
      contentImageAlt=""
      contentPassage={props.summary}
      contentLink={`${(window as any).RaygunConfiguration.BlogUrl}/${
        props.relativePermalink
      }?${formattedUTM}`}
      tags={props.tags}
      pinned={props.pinned}
    />
  );
};
WhatsNewAnnouncementItem.defaultProps = {
  pinned: false,
};

const WhatsNew: React.FunctionComponent<Props> = props => (
  <SidebarLayout width={324} gutter={24} sidebar={<WhatsNewAd />}>
    <FilterBar />

    <TagFilterBar />

    {!isNil(props.pinnedPost) && (
      <ErrorBoundary blackhole>
        <WhatsNewAnnouncementItem {...props.pinnedPost} pinned />
      </ErrorBoundary>
    )}

    {props.filteredWhatsNewItems.map(item => (
      <ErrorBoundary blackhole key={item.date.getTime()}>
        <WhatsNewAnnouncementItem {...item} />
      </ErrorBoundary>
    ))}
  </SidebarLayout>
);

class WhatsNewContainer extends React.Component<Props, {}> {
  updateLastViewedIfNeeded(curProps: Props, prevProps: Props) {
    const mostRecentWhatsNewDate: Date = get(curProps.mostRecentWhatsNewItem, 'date', null);

    if (
      mostRecentWhatsNewDate != null &&
      mostRecentWhatsNewDate.getTime() != prevProps.lastViewedItemDate.getTime()
    ) {
      this.props.updateLastViewed(mostRecentWhatsNewDate);
    }
  }

  componentDidMount() {
    this.props.forceFetchWhatsNewItems();

    this.updateLastViewedIfNeeded(this.props, this.props);
  }

  componentDidUpdate(prevProps: Props) {
    this.updateLastViewedIfNeeded(this.props, prevProps);
  }

  render() {
    return <WhatsNew {...this.props} />;
  }
}

const ConnectedWhatsNewContainer = connect<StateProps, DispatchProps, {}>(
  (state: AppState) => ({
    mostRecentWhatsNewItem: Selectors.mostRecentWhatsNewItem(state),
    lastViewedItemDate: Selectors.lastViewedItemDate(state),
    filteredWhatsNewItems: Selectors.getFilteredWhatsNewItemsWithoutPinnedPost(state),
    pinnedPost: Selectors.getPinnedPost(state),
  }),
  (dispatch: Dispatch) => ({
    updateLastViewed: (date: Date) => dispatch(Actions.updateLastViewedDate(date)),
    forceFetchWhatsNewItems: () => dispatch(Actions.forceFetchWhatsNewItems()),
  }),
)(WhatsNewContainer);

export { ConnectedWhatsNewContainer as WhatsNew };
