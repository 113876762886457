/** @format **/

import { mapValues, forIn, keys } from 'lodash';

import * as charting from 'utils/echarts';

import { ColorMap, GroupedData, Series } from './models';

export function convertGroupedDataToProportionate(
  groupedData: GroupedData,
  totals: number[],
): GroupedData {
  return mapValues(groupedData, group => {
    return group.map((item: number, index: number) => {
      return totals[index] > 0 ? (item / totals[index]) * 100 : 0;
    });
  });
}

export function getSeries(
  groupedData: GroupedData,
  totals: number[],
  colorMap: ColorMap,
  isStacked: boolean = true,
  isProportional: boolean = true,
  markArea: any = {}
): Series[] {
  if(isProportional && (!totals || (totals && totals.length === 0))) {
    throw new Error('Proportional data requires totals');
  }

  const data = isProportional
    ? convertGroupedDataToProportionate(groupedData, totals)
    : groupedData;

  const seriesTemplate: Pick<Series, 'symbol' | 'symbolSize' | 'type' | 'stack'> = {
    symbol: isStacked ? 'none' : 'circle',
    symbolSize: charting.rum.symbolSize,
    type: isStacked ? 'bar' : 'line',
    stack: isStacked ? 'stack' : null,
  };

  const seriesData: Series[] = [];

  forIn(data, (values, key) => {
    const color = colorMap[key];
    const isFirst = keys(data).indexOf(key) === 0;

    seriesData.push({
      ...seriesTemplate,
      id: key,
      name: key,
      data: values,
      lineStyle: {
        color,
      },
      itemStyle: {
        color,
      },
      markArea: isFirst ? markArea : null,
    } as Series);
  });

  return seriesData;
}
