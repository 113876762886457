/** @format **/

import React from 'react';
import { IStyle } from 'fela';

import { createComponentWithProxy } from 'react-fela';

import { Theme } from 'app/theme';

const StyledSupportLinkWrapper = (): IStyle => ({
  ':hover': {
    color: `${Theme.ds.getColorByName('blue1500')}`,
  },
});

export const SupportLinkWrapper = createComponentWithProxy(StyledSupportLinkWrapper, 'a');
