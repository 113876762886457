/**
 * @format
 */
/* tslint:disable:max-file-line-count */

import * as Showdown from 'showdown';
import { find } from 'lodash';

export const Emojis = 'emojis';

type Emoji = {
  key: string;
  icon: string;
};

const INLINE_STYLES = 'style="width:16px; display: inline"';

// :wink:
const RegExp = /(:[a-z0-9_\-\+]+:)/gim;

const EmojiExtension = function() {
  const emojis: Showdown.RegexReplaceExtension = {
    type: 'lang',
    regex: RegExp,
    replace: (_match: string, userEmoji: string) => {
      const emoji = find(EmojiMap, e => userEmoji === e.key);
      if (emoji) {
        return renderEmoji(emoji, INLINE_STYLES);
      }
      return userEmoji;
    },
  };

  return [emojis];
};

Showdown.extension(Emojis, EmojiExtension);

const renderEmoji = (emoji: Emoji, additionalAttributes: string): string => {
  return `<img src="${emoji.icon}" alt="${emoji.key} emoji" ${additionalAttributes} />`;
};

export const EmojiMap: Emoji[] = [
  { key: ':smile:', icon: '1f604.png' },
  { key: ':frowning:', icon: '1f626.png' },
  { key: ':thumbsup:', icon: '1f44d.png' },
  { key: ':wink:', icon: '1f609.png' },
  { key: ':poop:', icon: '1f4a9.png' },
  { key: ':raygun:', icon: 'raygun.png' },
  { key: ':interrobang:', icon: '2049.png' },
  { key: ':tm:', icon: '2122.png' },
  { key: ':information_source:', icon: '2139.png' },
  { key: ':left_right_arrow:', icon: '2194.png' },
  { key: ':arrow_up_down:', icon: '2195.png' },
  { key: ':arrow_upper_left:', icon: '2196.png' },
  { key: ':arrow_upper_right:', icon: '2197.png' },
  { key: ':arrow_lower_right:', icon: '2198.png' },
  { key: ':arrow_lower_left:', icon: '2199.png' },
  { key: ':sunny:', icon: '2600.png' },
  { key: ':cloud:', icon: '2601.png' },
  { key: ':ballot_box_with_check:', icon: '2611.png' },
  { key: ':umbrella:', icon: '2614.png' },
  { key: ':coffee:', icon: '2615.png' },
  { key: ':aries:', icon: '2648.png' },
  { key: ':taurus:', icon: '2649.png' },
  { key: ':sagittarius:', icon: '2650.png' },
  { key: ':capricorn:', icon: '2651.png' },
  { key: ':aquarius:', icon: '2652.png' },
  { key: ':pisces:', icon: '2653.png' },
  { key: ':spades:', icon: '2660.png' },
  { key: ':clubs:', icon: '2663.png' },
  { key: ':hearts:', icon: '2665.png' },
  { key: ':diamonds:', icon: '2666.png' },
  { key: ':hotsprings:', icon: '2668.png' },
  { key: ':anchor:', icon: '2693.png' },
  { key: ':scissors:', icon: '2702.png' },
  { key: ':white_check_mark:', icon: '2705.png' },
  { key: ':airplane:', icon: '2708.png' },
  { key: ':email:', icon: '2709.png' },
  { key: ':envelope:', icon: '2709.png' },
  { key: ':black_nib:', icon: '2712.png' },
  { key: ':heavy_check_mark:', icon: '2714.png' },
  { key: ':heavy_multiplication_x:', icon: '2716.png' },
  { key: ':sparkles:', icon: '2728.png' },
  { key: ':eight_spoked_asterisk:', icon: '2733.png' },
  { key: ':eight_pointed_black_star:', icon: '2734.png' },
  { key: ':snowflake:', icon: '2744.png' },
  { key: ':sparkle:', icon: '2747.png' },
  { key: ':question:', icon: '2753.png' },
  { key: ':grey_question:', icon: '2754.png' },
  { key: ':grey_exclamation:', icon: '2755.png' },
  { key: ':exclamation:', icon: '2757.png' },
  { key: ':heavy_exclamation_mark:', icon: '2757.png' },
  { key: ':heart:', icon: '2764.png' },
  { key: ':heavy_plus_sign:', icon: '2795.png' },
  { key: ':heavy_minus_sign:', icon: '2796.png' },
  { key: ':heavy_division_sign:', icon: '2797.png' },
  { key: ':arrow_heading_up:', icon: '2934.png' },
  { key: ':arrow_heading_down:', icon: '2935.png' },
  { key: ':wavy_dash:', icon: '3030.png' },
  { key: ':congratulations:', icon: '3297.png' },
  { key: ':secret:', icon: '3299.png' },
  { key: ':copyright:', icon: '00a9.png' },
  { key: ':registered:', icon: '00ae.png' },
  { key: ':bangbang:', icon: '203c.png' },
  { key: ':leftwards_arrow_with_hook:', icon: '21a9.png' },
  { key: ':arrow_right_hook:', icon: '21aa.png' },
  { key: ':watch:', icon: '231a.png' },
  { key: ':hourglass:', icon: '231b.png' },
  { key: ':fast_forward:', icon: '23e9.png' },
  { key: ':rewind:', icon: '23ea.png' },
  { key: ':arrow_double_up:', icon: '23eb.png' },
  { key: ':arrow_double_down:', icon: '23ec.png' },
  { key: ':alarm_clock:', icon: '23f0.png' },
  { key: ':hourglass_flowing_sand:', icon: '23f3.png' },
  { key: ':m:', icon: '24c2.png' },
  { key: ':black_small_square:', icon: '25aa.png' },
  { key: ':white_small_square:', icon: '25ab.png' },
  { key: ':arrow_forward:', icon: '25b6.png' },
  { key: ':arrow_backward:', icon: '25c0.png' },
  { key: ':white_medium_square:', icon: '25fb.png' },
  { key: ':black_medium_square:', icon: '25fc.png' },
  { key: ':white_medium_small_square:', icon: '25fd.png' },
  { key: ':black_medium_small_square:', icon: '25fe.png' },
  { key: ':phone:', icon: '260e.png' },
  { key: ':telephone:', icon: '260e.png' },
  { key: ':point_up:', icon: '261d.png' },
  { key: ':relaxed:', icon: '263a.png' },
  { key: ':gemini:', icon: '264a.png' },
  { key: ':cancer:', icon: '264b.png' },
  { key: ':leo:', icon: '264c.png' },
  { key: ':virgo:', icon: '264d.png' },
  { key: ':libra:', icon: '264e.png' },
  { key: ':scorpius:', icon: '264f.png' },
  { key: ':recycle:', icon: '267b.png' },
  { key: ':wheelchair:', icon: '267f.png' },
  { key: ':warning:', icon: '26a0.png' },
  { key: ':zap:', icon: '26a1.png' },
  { key: ':white_circle:', icon: '26aa.png' },
  { key: ':black_circle:', icon: '26ab.png' },
  { key: ':soccer:', icon: '26bd.png' },
  { key: ':baseball:', icon: '26be.png' },
  { key: ':snowman:', icon: '26c4.png' },
  { key: ':partly_sunny:', icon: '26c5.png' },
  { key: ':ophiuchus:', icon: '26ce.png' },
  { key: ':no_entry:', icon: '26d4.png' },
  { key: ':church:', icon: '26ea.png' },
  { key: ':fountain:', icon: '26f2.png' },
  { key: ':golf:', icon: '26f3.png' },
  { key: ':boat:', icon: '26f5.png' },
  { key: ':sailboat:', icon: '26f5.png' },
  { key: ':tent:', icon: '26fa.png' },
  { key: ':fuelpump:', icon: '26fd.png' },
  { key: ':fist:', icon: '270a.png' },
  { key: ':hand:', icon: '270b.png' },
  { key: ':raised_hand:', icon: '270b.png' },
  { key: ':v:', icon: '270c.png' },
  { key: ':pencil2:', icon: '270f.png' },
  { key: ':x:', icon: '274c.png' },
  { key: ':negative_squared_cross_mark:', icon: '274e.png' },
  { key: ':arrow_right:', icon: '27a1.png' },
  { key: ':curly_loop:', icon: '27b0.png' },
  { key: ':loop:', icon: '27bf.png' },
  { key: ':arrow_left:', icon: '2b05.png' },
  { key: ':arrow_up:', icon: '2b06.png' },
  { key: ':arrow_down:', icon: '2b07.png' },
  { key: ':black_large_square:', icon: '2b1b.png' },
  { key: ':white_large_square:', icon: '2b1c.png' },
  { key: ':star:', icon: '2b50.png' },
  { key: ':o:', icon: '2b55.png' },
  { key: ':part_alternation_mark:', icon: '303d.png' },
  { key: ':mahjong:', icon: '1f004.png' },
  { key: ':black_joker:', icon: '1f0cf.png' },
  { key: ':a:', icon: '1f170.png' },
  { key: ':b:', icon: '1f171.png' },
  { key: ':o2:', icon: '1f17e.png' },
  { key: ':parking:', icon: '1f17f.png' },
  { key: ':ab:', icon: '1f18e.png' },
  { key: ':cl:', icon: '1f191.png' },
  { key: ':cool:', icon: '1f192.png' },
  { key: ':free:', icon: '1f193.png' },
  { key: ':id:', icon: '1f194.png' },
  { key: ':new:', icon: '1f195.png' },
  { key: ':ng:', icon: '1f196.png' },
  { key: ':ok:', icon: '1f197.png' },
  { key: ':sos:', icon: '1f198.png' },
  { key: ':up:', icon: '1f199.png' },
  { key: ':vs:', icon: '1f19a.png' },
  { key: ':koko:', icon: '1f201.png' },
  { key: ':sa:', icon: '1f202.png' },
  { key: ':u7121:', icon: '1f21a.png' },
  { key: ':u6307:', icon: '1f22f.png' },
  { key: ':u7981:', icon: '1f232.png' },
  { key: ':u7a7a:', icon: '1f233.png' },
  { key: ':u5408:', icon: '1f234.png' },
  { key: ':u6e80:', icon: '1f235.png' },
  { key: ':u6709:', icon: '1f236.png' },
  { key: ':u6708:', icon: '1f237.png' },
  { key: ':u7533:', icon: '1f238.png' },
  { key: ':u5272:', icon: '1f239.png' },
  { key: ':u55b6:', icon: '1f23a.png' },
  { key: ':ideograph_advantage:', icon: '1f250.png' },
  { key: ':accept:', icon: '1f251.png' },
  { key: ':cyclone:', icon: '1f300.png' },
  { key: ':foggy:', icon: '1f301.png' },
  { key: ':closed_umbrella:', icon: '1f302.png' },
  { key: ':night_with_stars:', icon: '1f303.png' },
  { key: ':sunrise_over_mountains:', icon: '1f304.png' },
  { key: ':sunrise:', icon: '1f305.png' },
  { key: ':city_sunset:', icon: '1f306.png' },
  { key: ':city_sunrise:', icon: '1f307.png' },
  { key: ':rainbow:', icon: '1f308.png' },
  { key: ':bridge_at_night:', icon: '1f309.png' },
  { key: ':ocean:', icon: '1f30a.png' },
  { key: ':volcano:', icon: '1f30b.png' },
  { key: ':milky_way:', icon: '1f30c.png' },
  { key: ':earth_africa:', icon: '1f30d.png' },
  { key: ':earth_americas:', icon: '1f30e.png' },
  { key: ':earth_asia:', icon: '1f30f.png' },
  { key: ':globe_with_meridians:', icon: '1f310.png' },
  { key: ':new_moon:', icon: '1f311.png' },
  { key: ':waxing_crescent_moon:', icon: '1f312.png' },
  { key: ':first_quarter_moon:', icon: '1f313.png' },
  { key: ':moon:', icon: '1f314.png' },
  { key: ':waxing_gibbous_moon:', icon: '1f314.png' },
  { key: ':full_moon:', icon: '1f315.png' },
  { key: ':waning_gibbous_moon:', icon: '1f316.png' },
  { key: ':last_quarter_moon:', icon: '1f317.png' },
  { key: ':waning_crescent_moon:', icon: '1f318.png' },
  { key: ':crescent_moon:', icon: '1f319.png' },
  { key: ':new_moon_with_face:', icon: '1f31a.png' },
  { key: ':first_quarter_moon_with_face:', icon: '1f31b.png' },
  { key: ':last_quarter_moon_with_face:', icon: '1f31c.png' },
  { key: ':full_moon_with_face:', icon: '1f31d.png' },
  { key: ':sun_with_face:', icon: '1f31e.png' },
  { key: ':star2:', icon: '1f31f.png' },
  { key: ':stars:', icon: '1f320.png' },
  { key: ':chestnut:', icon: '1f330.png' },
  { key: ':seedling:', icon: '1f331.png' },
  { key: ':evergreen_tree:', icon: '1f332.png' },
  { key: ':deciduous_tree:', icon: '1f333.png' },
  { key: ':palm_tree:', icon: '1f334.png' },
  { key: ':cactus:', icon: '1f335.png' },
  { key: ':tulip:', icon: '1f337.png' },
  { key: ':cherry_blossom:', icon: '1f338.png' },
  { key: ':rose:', icon: '1f339.png' },
  { key: ':hibiscus:', icon: '1f33a.png' },
  { key: ':sunflower:', icon: '1f33b.png' },
  { key: ':blossom:', icon: '1f33c.png' },
  { key: ':corn:', icon: '1f33d.png' },
  { key: ':ear_of_rice:', icon: '1f33e.png' },
  { key: ':herb:', icon: '1f33f.png' },
  { key: ':four_leaf_clover:', icon: '1f340.png' },
  { key: ':maple_leaf:', icon: '1f341.png' },
  { key: ':fallen_leaf:', icon: '1f342.png' },
  { key: ':leaves:', icon: '1f343.png' },
  { key: ':mushroom:', icon: '1f344.png' },
  { key: ':tomato:', icon: '1f345.png' },
  { key: ':eggplant:', icon: '1f346.png' },
  { key: ':grapes:', icon: '1f347.png' },
  { key: ':melon:', icon: '1f348.png' },
  { key: ':watermelon:', icon: '1f349.png' },
  { key: ':tangerine:', icon: '1f34a.png' },
  { key: ':lemon:', icon: '1f34b.png' },
  { key: ':banana:', icon: '1f34c.png' },
  { key: ':pineapple:', icon: '1f34d.png' },
  { key: ':apple:', icon: '1f34e.png' },
  { key: ':green_apple:', icon: '1f34f.png' },
  { key: ':pear:', icon: '1f350.png' },
  { key: ':peach:', icon: '1f351.png' },
  { key: ':cherries:', icon: '1f352.png' },
  { key: ':strawberry:', icon: '1f353.png' },
  { key: ':hamburger:', icon: '1f354.png' },
  { key: ':pizza:', icon: '1f355.png' },
  { key: ':meat_on_bone:', icon: '1f356.png' },
  { key: ':poultry_leg:', icon: '1f357.png' },
  { key: ':rice_cracker:', icon: '1f358.png' },
  { key: ':rice_ball:', icon: '1f359.png' },
  { key: ':rice:', icon: '1f35a.png' },
  { key: ':curry:', icon: '1f35b.png' },
  { key: ':ramen:', icon: '1f35c.png' },
  { key: ':spaghetti:', icon: '1f35d.png' },
  { key: ':bread:', icon: '1f35e.png' },
  { key: ':fries:', icon: '1f35f.png' },
  { key: ':sweet_potato:', icon: '1f360.png' },
  { key: ':dango:', icon: '1f361.png' },
  { key: ':oden:', icon: '1f362.png' },
  { key: ':sushi:', icon: '1f363.png' },
  { key: ':fried_shrimp:', icon: '1f364.png' },
  { key: ':fish_cake:', icon: '1f365.png' },
  { key: ':icecream:', icon: '1f366.png' },
  { key: ':shaved_ice:', icon: '1f367.png' },
  { key: ':ice_cream:', icon: '1f368.png' },
  { key: ':doughnut:', icon: '1f369.png' },
  { key: ':cookie:', icon: '1f36a.png' },
  { key: ':chocolate_bar:', icon: '1f36b.png' },
  { key: ':candy:', icon: '1f36c.png' },
  { key: ':lollipop:', icon: '1f36d.png' },
  { key: ':custard:', icon: '1f36e.png' },
  { key: ':honey_pot:', icon: '1f36f.png' },
  { key: ':cake:', icon: '1f370.png' },
  { key: ':bento:', icon: '1f371.png' },
  { key: ':stew:', icon: '1f372.png' },
  { key: ':egg:', icon: '1f373.png' },
  { key: ':fork_and_knife:', icon: '1f374.png' },
  { key: ':tea:', icon: '1f375.png' },
  { key: ':sake:', icon: '1f376.png' },
  { key: ':wine_glass:', icon: '1f377.png' },
  { key: ':cocktail:', icon: '1f378.png' },
  { key: ':tropical_drink:', icon: '1f379.png' },
  { key: ':beer:', icon: '1f37a.png' },
  { key: ':beers:', icon: '1f37b.png' },
  { key: ':baby_bottle:', icon: '1f37c.png' },
  { key: ':ribbon:', icon: '1f380.png' },
  { key: ':gift:', icon: '1f381.png' },
  { key: ':birthday:', icon: '1f382.png' },
  { key: ':jack_o_lantern:', icon: '1f383.png' },
  { key: ':christmas_tree:', icon: '1f384.png' },
  { key: ':santa:', icon: '1f385.png' },
  { key: ':fireworks:', icon: '1f386.png' },
  { key: ':sparkler:', icon: '1f387.png' },
  { key: ':balloon:', icon: '1f388.png' },
  { key: ':tada:', icon: '1f389.png' },
  { key: ':confetti_ball:', icon: '1f38a.png' },
  { key: ':tanabata_tree:', icon: '1f38b.png' },
  { key: ':crossed_flags:', icon: '1f38c.png' },
  { key: ':bamboo:', icon: '1f38d.png' },
  { key: ':dolls:', icon: '1f38e.png' },
  { key: ':flags:', icon: '1f38f.png' },
  { key: ':wind_chime:', icon: '1f390.png' },
  { key: ':rice_scene:', icon: '1f391.png' },
  { key: ':school_satchel:', icon: '1f392.png' },
  { key: ':mortar_board:', icon: '1f393.png' },
  { key: ':carousel_horse:', icon: '1f3a0.png' },
  { key: ':ferris_wheel:', icon: '1f3a1.png' },
  { key: ':roller_coaster:', icon: '1f3a2.png' },
  { key: ':fishing_pole_and_fish:', icon: '1f3a3.png' },
  { key: ':microphone:', icon: '1f3a4.png' },
  { key: ':movie_camera:', icon: '1f3a5.png' },
  { key: ':cinema:', icon: '1f3a6.png' },
  { key: ':headphones:', icon: '1f3a7.png' },
  { key: ':art:', icon: '1f3a8.png' },
  { key: ':tophat:', icon: '1f3a9.png' },
  { key: ':circus_tent:', icon: '1f3aa.png' },
  { key: ':ticket:', icon: '1f3ab.png' },
  { key: ':clapper:', icon: '1f3ac.png' },
  { key: ':performing_arts:', icon: '1f3ad.png' },
  { key: ':video_game:', icon: '1f3ae.png' },
  { key: ':dart:', icon: '1f3af.png' },
  { key: ':slot_machine:', icon: '1f3b0.png' },
  { key: ':8ball:', icon: '1f3b1.png' },
  { key: ':game_die:', icon: '1f3b2.png' },
  { key: ':bowling:', icon: '1f3b3.png' },
  { key: ':flower_playing_cards:', icon: '1f3b4.png' },
  { key: ':musical_note:', icon: '1f3b5.png' },
  { key: ':notes:', icon: '1f3b6.png' },
  { key: ':saxophone:', icon: '1f3b7.png' },
  { key: ':guitar:', icon: '1f3b8.png' },
  { key: ':musical_keyboard:', icon: '1f3b9.png' },
  { key: ':trumpet:', icon: '1f3ba.png' },
  { key: ':violin:', icon: '1f3bb.png' },
  { key: ':musical_score:', icon: '1f3bc.png' },
  { key: ':running_shirt_with_sash:', icon: '1f3bd.png' },
  { key: ':tennis:', icon: '1f3be.png' },
  { key: ':ski:', icon: '1f3bf.png' },
  { key: ':basketball:', icon: '1f3c0.png' },
  { key: ':checkered_flag:', icon: '1f3c1.png' },
  { key: ':snowboarder:', icon: '1f3c2.png' },
  { key: ':runner:', icon: '1f3c3.png' },
  { key: ':running:', icon: '1f3c3.png' },
  { key: ':surfer:', icon: '1f3c4.png' },
  { key: ':trophy:', icon: '1f3c6.png' },
  { key: ':horse_racing:', icon: '1f3c7.png' },
  { key: ':football:', icon: '1f3c8.png' },
  { key: ':rugby_football:', icon: '1f3c9.png' },
  { key: ':swimmer:', icon: '1f3ca.png' },
  { key: ':house:', icon: '1f3e0.png' },
  { key: ':house_with_garden:', icon: '1f3e1.png' },
  { key: ':office:', icon: '1f3e2.png' },
  { key: ':post_office:', icon: '1f3e3.png' },
  { key: ':european_post_office:', icon: '1f3e4.png' },
  { key: ':hospital:', icon: '1f3e5.png' },
  { key: ':bank:', icon: '1f3e6.png' },
  { key: ':atm:', icon: '1f3e7.png' },
  { key: ':hotel:', icon: '1f3e8.png' },
  { key: ':love_hotel:', icon: '1f3e9.png' },
  { key: ':convenience_store:', icon: '1f3ea.png' },
  { key: ':school:', icon: '1f3eb.png' },
  { key: ':department_store:', icon: '1f3ec.png' },
  { key: ':factory:', icon: '1f3ed.png' },
  { key: ':izakaya_lantern:', icon: '1f3ee.png' },
  { key: ':lantern:', icon: '1f3ee.png' },
  { key: ':japanese_castle:', icon: '1f3ef.png' },
  { key: ':european_castle:', icon: '1f3f0.png' },
  { key: ':skin-tone-2:', icon: '1f3fb.png' },
  { key: ':skin-tone-3:', icon: '1f3fc.png' },
  { key: ':skin-tone-4:', icon: '1f3fd.png' },
  { key: ':skin-tone-5:', icon: '1f3fe.png' },
  { key: ':skin-tone-6:', icon: '1f3ff.png' },
  { key: ':rat:', icon: '1f400.png' },
  { key: ':mouse2:', icon: '1f401.png' },
  { key: ':ox:', icon: '1f402.png' },
  { key: ':water_buffalo:', icon: '1f403.png' },
  { key: ':cow2:', icon: '1f404.png' },
  { key: ':tiger2:', icon: '1f405.png' },
  { key: ':leopard:', icon: '1f406.png' },
  { key: ':rabbit2:', icon: '1f407.png' },
  { key: ':cat2:', icon: '1f408.png' },
  { key: ':dragon:', icon: '1f409.png' },
  { key: ':crocodile:', icon: '1f40a.png' },
  { key: ':whale2:', icon: '1f40b.png' },
  { key: ':snail:', icon: '1f40c.png' },
  { key: ':snake:', icon: '1f40d.png' },
  { key: ':racehorse:', icon: '1f40e.png' },
  { key: ':ram:', icon: '1f40f.png' },
  { key: ':goat:', icon: '1f410.png' },
  { key: ':sheep:', icon: '1f411.png' },
  { key: ':monkey:', icon: '1f412.png' },
  { key: ':rooster:', icon: '1f413.png' },
  { key: ':chicken:', icon: '1f414.png' },
  { key: ':dog2:', icon: '1f415.png' },
  { key: ':pig2:', icon: '1f416.png' },
  { key: ':boar:', icon: '1f417.png' },
  { key: ':elephant:', icon: '1f418.png' },
  { key: ':octopus:', icon: '1f419.png' },
  { key: ':shell:', icon: '1f41a.png' },
  { key: ':bug:', icon: '1f41b.png' },
  { key: ':ant:', icon: '1f41c.png' },
  { key: ':bee:', icon: '1f41d.png' },
  { key: ':honeybee:', icon: '1f41d.png' },
  { key: ':beetle:', icon: '1f41e.png' },
  { key: ':fish:', icon: '1f41f.png' },
  { key: ':tropical_fish:', icon: '1f420.png' },
  { key: ':blowfish:', icon: '1f421.png' },
  { key: ':turtle:', icon: '1f422.png' },
  { key: ':hatching_chick:', icon: '1f423.png' },
  { key: ':baby_chick:', icon: '1f424.png' },
  { key: ':hatched_chick:', icon: '1f425.png' },
  { key: ':bird:', icon: '1f426.png' },
  { key: ':penguin:', icon: '1f427.png' },
  { key: ':koala:', icon: '1f428.png' },
  { key: ':poodle:', icon: '1f429.png' },
  { key: ':dromedary_camel:', icon: '1f42a.png' },
  { key: ':camel:', icon: '1f42b.png' },
  { key: ':dolphin:', icon: '1f42c.png' },
  { key: ':flipper:', icon: '1f42c.png' },
  { key: ':mouse:', icon: '1f42d.png' },
  { key: ':cow:', icon: '1f42e.png' },
  { key: ':tiger:', icon: '1f42f.png' },
  { key: ':rabbit:', icon: '1f430.png' },
  { key: ':cat:', icon: '1f431.png' },
  { key: ':dragon_face:', icon: '1f432.png' },
  { key: ':whale:', icon: '1f433.png' },
  { key: ':horse:', icon: '1f434.png' },
  { key: ':monkey_face:', icon: '1f435.png' },
  { key: ':dog:', icon: '1f436.png' },
  { key: ':pig:', icon: '1f437.png' },
  { key: ':frog:', icon: '1f438.png' },
  { key: ':hamster:', icon: '1f439.png' },
  { key: ':wolf:', icon: '1f43a.png' },
  { key: ':bear:', icon: '1f43b.png' },
  { key: ':panda_face:', icon: '1f43c.png' },
  { key: ':pig_nose:', icon: '1f43d.png' },
  { key: ':feet:', icon: '1f43e.png' },
  { key: ':paw_prints:', icon: '1f43e.png' },
  { key: ':eyes:', icon: '1f440.png' },
  { key: ':ear:', icon: '1f442.png' },
  { key: ':nose:', icon: '1f443.png' },
  { key: ':lips:', icon: '1f444.png' },
  { key: ':tongue:', icon: '1f445.png' },
  { key: ':point_up_2:', icon: '1f446.png' },
  { key: ':point_down:', icon: '1f447.png' },
  { key: ':point_left:', icon: '1f448.png' },
  { key: ':point_right:', icon: '1f449.png' },
  { key: ':facepunch:', icon: '1f44a.png' },
  { key: ':punch:', icon: '1f44a.png' },
  { key: ':wave:', icon: '1f44b.png' },
  { key: ':ok_hand:', icon: '1f44c.png' },
  { key: ':+1:', icon: '1f44d.png' },
  { key: ':-1:', icon: '1f44e.png' },
  { key: ':thumbsdown:', icon: '1f44e.png' },
  { key: ':clap:', icon: '1f44f.png' },
  { key: ':open_hands:', icon: '1f450.png' },
  { key: ':crown:', icon: '1f451.png' },
  { key: ':womans_hat:', icon: '1f452.png' },
  { key: ':eyeglasses:', icon: '1f453.png' },
  { key: ':necktie:', icon: '1f454.png' },
  { key: ':shirt:', icon: '1f455.png' },
  { key: ':tshirt:', icon: '1f455.png' },
  { key: ':jeans:', icon: '1f456.png' },
  { key: ':dress:', icon: '1f457.png' },
  { key: ':kimono:', icon: '1f458.png' },
  { key: ':bikini:', icon: '1f459.png' },
  { key: ':womans_clothes:', icon: '1f45a.png' },
  { key: ':purse:', icon: '1f45b.png' },
  { key: ':handbag:', icon: '1f45c.png' },
  { key: ':pouch:', icon: '1f45d.png' },
  { key: ':mans_shoe:', icon: '1f45e.png' },
  { key: ':shoe:', icon: '1f45e.png' },
  { key: ':athletic_shoe:', icon: '1f45f.png' },
  { key: ':high_heel:', icon: '1f460.png' },
  { key: ':sandal:', icon: '1f461.png' },
  { key: ':boot:', icon: '1f462.png' },
  { key: ':footprints:', icon: '1f463.png' },
  { key: ':bust_in_silhouette:', icon: '1f464.png' },
  { key: ':busts_in_silhouette:', icon: '1f465.png' },
  { key: ':boy:', icon: '1f466.png' },
  { key: ':girl:', icon: '1f467.png' },
  { key: ':man:', icon: '1f468.png' },
  { key: ':woman:', icon: '1f469.png' },
  { key: ':family:', icon: '1f46a.png' },
  { key: ':couple:', icon: '1f46b.png' },
  { key: ':two_men_holding_hands:', icon: '1f46c.png' },
  { key: ':two_women_holding_hands:', icon: '1f46d.png' },
  { key: ':cop:', icon: '1f46e.png' },
  { key: ':dancers:', icon: '1f46f.png' },
  { key: ':bride_with_veil:', icon: '1f470.png' },
  { key: ':person_with_blond_hair:', icon: '1f471.png' },
  { key: ':man_with_gua_pi_mao:', icon: '1f472.png' },
  { key: ':man_with_turban:', icon: '1f473.png' },
  { key: ':older_man:', icon: '1f474.png' },
  { key: ':older_woman:', icon: '1f475.png' },
  { key: ':baby:', icon: '1f476.png' },
  { key: ':construction_worker:', icon: '1f477.png' },
  { key: ':princess:', icon: '1f478.png' },
  { key: ':japanese_ogre:', icon: '1f479.png' },
  { key: ':japanese_goblin:', icon: '1f47a.png' },
  { key: ':ghost:', icon: '1f47b.png' },
  { key: ':angel:', icon: '1f47c.png' },
  { key: ':alien:', icon: '1f47d.png' },
  { key: ':space_invader:', icon: '1f47e.png' },
  { key: ':imp:', icon: '1f47f.png' },
  { key: ':skull:', icon: '1f480.png' },
  { key: ':information_desk_person:', icon: '1f481.png' },
  { key: ':guardsman:', icon: '1f482.png' },
  { key: ':dancer:', icon: '1f483.png' },
  { key: ':lipstick:', icon: '1f484.png' },
  { key: ':nail_care:', icon: '1f485.png' },
  { key: ':massage:', icon: '1f486.png' },
  { key: ':haircut:', icon: '1f487.png' },
  { key: ':barber:', icon: '1f488.png' },
  { key: ':syringe:', icon: '1f489.png' },
  { key: ':pill:', icon: '1f48a.png' },
  { key: ':kiss:', icon: '1f48b.png' },
  { key: ':love_letter:', icon: '1f48c.png' },
  { key: ':ring:', icon: '1f48d.png' },
  { key: ':gem:', icon: '1f48e.png' },
  { key: ':couplekiss:', icon: '1f48f.png' },
  { key: ':bouquet:', icon: '1f490.png' },
  { key: ':couple_with_heart:', icon: '1f491.png' },
  { key: ':wedding:', icon: '1f492.png' },
  { key: ':heartbeat:', icon: '1f493.png' },
  { key: ':broken_heart:', icon: '1f494.png' },
  { key: ':two_hearts:', icon: '1f495.png' },
  { key: ':sparkling_heart:', icon: '1f496.png' },
  { key: ':heartpulse:', icon: '1f497.png' },
  { key: ':cupid:', icon: '1f498.png' },
  { key: ':blue_heart:', icon: '1f499.png' },
  { key: ':green_heart:', icon: '1f49a.png' },
  { key: ':yellow_heart:', icon: '1f49b.png' },
  { key: ':purple_heart:', icon: '1f49c.png' },
  { key: ':gift_heart:', icon: '1f49d.png' },
  { key: ':revolving_hearts:', icon: '1f49e.png' },
  { key: ':heart_decoration:', icon: '1f49f.png' },
  { key: ':diamond_shape_with_a_dot_inside:', icon: '1f4a0.png' },
  { key: ':bulb:', icon: '1f4a1.png' },
  { key: ':anger:', icon: '1f4a2.png' },
  { key: ':bomb:', icon: '1f4a3.png' },
  { key: ':zzz:', icon: '1f4a4.png' },
  { key: ':boom:', icon: '1f4a5.png' },
  { key: ':collision:', icon: '1f4a5.png' },
  { key: ':sweat_drops:', icon: '1f4a6.png' },
  { key: ':droplet:', icon: '1f4a7.png' },
  { key: ':dash:', icon: '1f4a8.png' },
  { key: ':hankey:', icon: '1f4a9.png' },
  { key: ':shit:', icon: '1f4a9.png' },
  { key: ':muscle:', icon: '1f4aa.png' },
  { key: ':dizzy:', icon: '1f4ab.png' },
  { key: ':speech_balloon:', icon: '1f4ac.png' },
  { key: ':thought_balloon:', icon: '1f4ad.png' },
  { key: ':white_flower:', icon: '1f4ae.png' },
  { key: ':100:', icon: '1f4af.png' },
  { key: ':moneybag:', icon: '1f4b0.png' },
  { key: ':currency_exchange:', icon: '1f4b1.png' },
  { key: ':heavy_dollar_sign:', icon: '1f4b2.png' },
  { key: ':credit_card:', icon: '1f4b3.png' },
  { key: ':yen:', icon: '1f4b4.png' },
  { key: ':dollar:', icon: '1f4b5.png' },
  { key: ':euro:', icon: '1f4b6.png' },
  { key: ':pound:', icon: '1f4b7.png' },
  { key: ':money_with_wings:', icon: '1f4b8.png' },
  { key: ':chart:', icon: '1f4b9.png' },
  { key: ':seat:', icon: '1f4ba.png' },
  { key: ':computer:', icon: '1f4bb.png' },
  { key: ':briefcase:', icon: '1f4bc.png' },
  { key: ':minidisc:', icon: '1f4bd.png' },
  { key: ':floppy_disk:', icon: '1f4be.png' },
  { key: ':cd:', icon: '1f4bf.png' },
  { key: ':dvd:', icon: '1f4c0.png' },
  { key: ':file_folder:', icon: '1f4c1.png' },
  { key: ':open_file_folder:', icon: '1f4c2.png' },
  { key: ':page_with_curl:', icon: '1f4c3.png' },
  { key: ':page_facing_up:', icon: '1f4c4.png' },
  { key: ':date:', icon: '1f4c5.png' },
  { key: ':calendar:', icon: '1f4c6.png' },
  { key: ':card_index:', icon: '1f4c7.png' },
  { key: ':chart_with_upwards_trend:', icon: '1f4c8.png' },
  { key: ':chart_with_downwards_trend:', icon: '1f4c9.png' },
  { key: ':bar_chart:', icon: '1f4ca.png' },
  { key: ':clipboard:', icon: '1f4cb.png' },
  { key: ':pushpin:', icon: '1f4cc.png' },
  { key: ':round_pushpin:', icon: '1f4cd.png' },
  { key: ':paperclip:', icon: '1f4ce.png' },
  { key: ':straight_ruler:', icon: '1f4cf.png' },
  { key: ':triangular_ruler:', icon: '1f4d0.png' },
  { key: ':bookmark_tabs:', icon: '1f4d1.png' },
  { key: ':ledger:', icon: '1f4d2.png' },
  { key: ':notebook:', icon: '1f4d3.png' },
  { key: ':notebook_with_decorative_cover:', icon: '1f4d4.png' },
  { key: ':closed_book:', icon: '1f4d5.png' },
  { key: ':book:', icon: '1f4d6.png' },
  { key: ':open_book:', icon: '1f4d6.png' },
  { key: ':green_book:', icon: '1f4d7.png' },
  { key: ':blue_book:', icon: '1f4d8.png' },
  { key: ':orange_book:', icon: '1f4d9.png' },
  { key: ':books:', icon: '1f4da.png' },
  { key: ':name_badge:', icon: '1f4db.png' },
  { key: ':scroll:', icon: '1f4dc.png' },
  { key: ':memo:', icon: '1f4dd.png' },
  { key: ':pencil:', icon: '1f4dd.png' },
  { key: ':telephone_receiver:', icon: '1f4de.png' },
  { key: ':pager:', icon: '1f4df.png' },
  { key: ':fax:', icon: '1f4e0.png' },
  { key: ':satellite:', icon: '1f4e1.png' },
  { key: ':loudspeaker:', icon: '1f4e2.png' },
  { key: ':mega:', icon: '1f4e3.png' },
  { key: ':outbox_tray:', icon: '1f4e4.png' },
  { key: ':inbox_tray:', icon: '1f4e5.png' },
  { key: ':package:', icon: '1f4e6.png' },
  { key: ':e-mail:', icon: '1f4e7.png' },
  { key: ':incoming_envelope:', icon: '1f4e8.png' },
  { key: ':envelope_with_arrow:', icon: '1f4e9.png' },
  { key: ':mailbox_closed:', icon: '1f4ea.png' },
  { key: ':mailbox:', icon: '1f4eb.png' },
  { key: ':mailbox_with_mail:', icon: '1f4ec.png' },
  { key: ':mailbox_with_no_mail:', icon: '1f4ed.png' },
  { key: ':postbox:', icon: '1f4ee.png' },
  { key: ':postal_horn:', icon: '1f4ef.png' },
  { key: ':newspaper:', icon: '1f4f0.png' },
  { key: ':iphone:', icon: '1f4f1.png' },
  { key: ':calling:', icon: '1f4f2.png' },
  { key: ':vibration_mode:', icon: '1f4f3.png' },
  { key: ':mobile_phone_off:', icon: '1f4f4.png' },
  { key: ':no_mobile_phones:', icon: '1f4f5.png' },
  { key: ':signal_strength:', icon: '1f4f6.png' },
  { key: ':camera:', icon: '1f4f7.png' },
  { key: ':video_camera:', icon: '1f4f9.png' },
  { key: ':tv:', icon: '1f4fa.png' },
  { key: ':radio:', icon: '1f4fb.png' },
  { key: ':vhs:', icon: '1f4fc.png' },
  { key: ':twisted_rightwards_arrows:', icon: '1f500.png' },
  { key: ':repeat:', icon: '1f501.png' },
  { key: ':repeat_one:', icon: '1f502.png' },
  { key: ':arrows_clockwise:', icon: '1f503.png' },
  { key: ':arrows_counterclockwise:', icon: '1f504.png' },
  { key: ':low_brightness:', icon: '1f505.png' },
  { key: ':high_brightness:', icon: '1f506.png' },
  { key: ':mute:', icon: '1f507.png' },
  { key: ':speaker:', icon: '1f508.png' },
  { key: ':sound:', icon: '1f509.png' },
  { key: ':loud_sound:', icon: '1f50a.png' },
  { key: ':battery:', icon: '1f50b.png' },
  { key: ':electric_plug:', icon: '1f50c.png' },
  { key: ':mag:', icon: '1f50d.png' },
  { key: ':mag_right:', icon: '1f50e.png' },
  { key: ':lock_with_ink_pen:', icon: '1f50f.png' },
  { key: ':closed_lock_with_key:', icon: '1f510.png' },
  { key: ':key:', icon: '1f511.png' },
  { key: ':lock:', icon: '1f512.png' },
  { key: ':unlock:', icon: '1f513.png' },
  { key: ':bell:', icon: '1f514.png' },
  { key: ':no_bell:', icon: '1f515.png' },
  { key: ':bookmark:', icon: '1f516.png' },
  { key: ':link:', icon: '1f517.png' },
  { key: ':radio_button:', icon: '1f518.png' },
  { key: ':back:', icon: '1f519.png' },
  { key: ':end:', icon: '1f51a.png' },
  { key: ':on:', icon: '1f51b.png' },
  { key: ':soon:', icon: '1f51c.png' },
  { key: ':top:', icon: '1f51d.png' },
  { key: ':underage:', icon: '1f51e.png' },
  { key: ':keycap_ten:', icon: '1f51f.png' },
  { key: ':capital_abcd:', icon: '1f520.png' },
  { key: ':abcd:', icon: '1f521.png' },
  { key: ':1234:', icon: '1f522.png' },
  { key: ':symbols:', icon: '1f523.png' },
  { key: ':abc:', icon: '1f524.png' },
  { key: ':fire:', icon: '1f525.png' },
  { key: ':flashlight:', icon: '1f526.png' },
  { key: ':wrench:', icon: '1f527.png' },
  { key: ':hammer:', icon: '1f528.png' },
  { key: ':nut_and_bolt:', icon: '1f529.png' },
  { key: ':hocho:', icon: '1f52a.png' },
  { key: ':knife:', icon: '1f52a.png' },
  { key: ':gun:', icon: '1f52b.png' },
  { key: ':microscope:', icon: '1f52c.png' },
  { key: ':telescope:', icon: '1f52d.png' },
  { key: ':crystal_ball:', icon: '1f52e.png' },
  { key: ':six_pointed_star:', icon: '1f52f.png' },
  { key: ':beginner:', icon: '1f530.png' },
  { key: ':trident:', icon: '1f531.png' },
  { key: ':black_square_button:', icon: '1f532.png' },
  { key: ':white_square_button:', icon: '1f533.png' },
  { key: ':red_circle:', icon: '1f534.png' },
  { key: ':large_blue_circle:', icon: '1f535.png' },
  { key: ':large_orange_diamond:', icon: '1f536.png' },
  { key: ':large_blue_diamond:', icon: '1f537.png' },
  { key: ':small_orange_diamond:', icon: '1f538.png' },
  { key: ':small_blue_diamond:', icon: '1f539.png' },
  { key: ':small_red_triangle:', icon: '1f53a.png' },
  { key: ':small_red_triangle_down:', icon: '1f53b.png' },
  { key: ':arrow_up_small:', icon: '1f53c.png' },
  { key: ':arrow_down_small:', icon: '1f53d.png' },
  { key: ':clock1:', icon: '1f550.png' },
  { key: ':clock2:', icon: '1f551.png' },
  { key: ':clock3:', icon: '1f552.png' },
  { key: ':clock4:', icon: '1f553.png' },
  { key: ':clock5:', icon: '1f554.png' },
  { key: ':clock6:', icon: '1f555.png' },
  { key: ':clock7:', icon: '1f556.png' },
  { key: ':clock8:', icon: '1f557.png' },
  { key: ':clock9:', icon: '1f558.png' },
  { key: ':clock10:', icon: '1f559.png' },
  { key: ':clock11:', icon: '1f55a.png' },
  { key: ':clock12:', icon: '1f55b.png' },
  { key: ':clock130:', icon: '1f55c.png' },
  { key: ':clock230:', icon: '1f55d.png' },
  { key: ':clock330:', icon: '1f55e.png' },
  { key: ':clock430:', icon: '1f55f.png' },
  { key: ':clock530:', icon: '1f560.png' },
  { key: ':clock630:', icon: '1f561.png' },
  { key: ':clock730:', icon: '1f562.png' },
  { key: ':clock830:', icon: '1f563.png' },
  { key: ':clock930:', icon: '1f564.png' },
  { key: ':clock1030:', icon: '1f565.png' },
  { key: ':clock1130:', icon: '1f566.png' },
  { key: ':clock1230:', icon: '1f567.png' },
  { key: ':mount_fuji:', icon: '1f5fb.png' },
  { key: ':tokyo_tower:', icon: '1f5fc.png' },
  { key: ':statue_of_liberty:', icon: '1f5fd.png' },
  { key: ':japan:', icon: '1f5fe.png' },
  { key: ':moyai:', icon: '1f5ff.png' },
  { key: ':grinning:', icon: '1f600.png' },
  { key: ':grin:', icon: '1f601.png' },
  { key: ':joy:', icon: '1f602.png' },
  { key: ':smiley:', icon: '1f603.png' },
  { key: ':sweat_smile:', icon: '1f605.png' },
  { key: ':laughing:', icon: '1f606.png' },
  { key: ':satisfied:', icon: '1f606.png' },
  { key: ':innocent:', icon: '1f607.png' },
  { key: ':smiling_imp:', icon: '1f608.png' },
  { key: ':blush:', icon: '1f60a.png' },
  { key: ':yum:', icon: '1f60b.png' },
  { key: ':relieved:', icon: '1f60c.png' },
  { key: ':heart_eyes:', icon: '1f60d.png' },
  { key: ':sunglasses:', icon: '1f60e.png' },
  { key: ':smirk:', icon: '1f60f.png' },
  { key: ':neutral_face:', icon: '1f610.png' },
  { key: ':expressionless:', icon: '1f611.png' },
  { key: ':unamused:', icon: '1f612.png' },
  { key: ':sweat:', icon: '1f613.png' },
  { key: ':pensive:', icon: '1f614.png' },
  { key: ':confused:', icon: '1f615.png' },
  { key: ':confounded:', icon: '1f616.png' },
  { key: ':kissing:', icon: '1f617.png' },
  { key: ':kissing_heart:', icon: '1f618.png' },
  { key: ':kissing_smiling_eyes:', icon: '1f619.png' },
  { key: ':kissing_closed_eyes:', icon: '1f61a.png' },
  { key: ':stuck_out_tongue:', icon: '1f61b.png' },
  { key: ':stuck_out_tongue_winking_eye:', icon: '1f61c.png' },
  { key: ':stuck_out_tongue_closed_eyes:', icon: '1f61d.png' },
  { key: ':disappointed:', icon: '1f61e.png' },
  { key: ':worried:', icon: '1f61f.png' },
  { key: ':angry:', icon: '1f620.png' },
  { key: ':rage:', icon: '1f621.png' },
  { key: ':cry:', icon: '1f622.png' },
  { key: ':persevere:', icon: '1f623.png' },
  { key: ':triumph:', icon: '1f624.png' },
  { key: ':disappointed_relieved:', icon: '1f625.png' },
  { key: ':anguished:', icon: '1f627.png' },
  { key: ':fearful:', icon: '1f628.png' },
  { key: ':weary:', icon: '1f629.png' },
  { key: ':sleepy:', icon: '1f62a.png' },
  { key: ':tired_face:', icon: '1f62b.png' },
  { key: ':grimacing:', icon: '1f62c.png' },
  { key: ':sob:', icon: '1f62d.png' },
  { key: ':open_mouth:', icon: '1f62e.png' },
  { key: ':hushed:', icon: '1f62f.png' },
  { key: ':cold_sweat:', icon: '1f630.png' },
  { key: ':scream:', icon: '1f631.png' },
  { key: ':astonished:', icon: '1f632.png' },
  { key: ':flushed:', icon: '1f633.png' },
  { key: ':sleeping:', icon: '1f634.png' },
  { key: ':dizzy_face:', icon: '1f635.png' },
  { key: ':no_mouth:', icon: '1f636.png' },
  { key: ':mask:', icon: '1f637.png' },
  { key: ':smile_cat:', icon: '1f638.png' },
  { key: ':joy_cat:', icon: '1f639.png' },
  { key: ':smiley_cat:', icon: '1f63a.png' },
  { key: ':heart_eyes_cat:', icon: '1f63b.png' },
  { key: ':smirk_cat:', icon: '1f63c.png' },
  { key: ':kissing_cat:', icon: '1f63d.png' },
  { key: ':pouting_cat:', icon: '1f63e.png' },
  { key: ':crying_cat_face:', icon: '1f63f.png' },
  { key: ':scream_cat:', icon: '1f640.png' },
  { key: ':no_good:', icon: '1f645.png' },
  { key: ':ok_woman:', icon: '1f646.png' },
  { key: ':bow:', icon: '1f647.png' },
  { key: ':see_no_evil:', icon: '1f648.png' },
  { key: ':hear_no_evil:', icon: '1f649.png' },
  { key: ':speak_no_evil:', icon: '1f64a.png' },
  { key: ':raising_hand:', icon: '1f64b.png' },
  { key: ':raised_hands:', icon: '1f64c.png' },
  { key: ':person_frowning:', icon: '1f64d.png' },
  { key: ':person_with_pouting_face:', icon: '1f64e.png' },
  { key: ':pray:', icon: '1f64f.png' },
  { key: ':rocket:', icon: '1f680.png' },
  { key: ':helicopter:', icon: '1f681.png' },
  { key: ':steam_locomotive:', icon: '1f682.png' },
  { key: ':railway_car:', icon: '1f683.png' },
  { key: ':bullettrain_side:', icon: '1f684.png' },
  { key: ':bullettrain_front:', icon: '1f685.png' },
  { key: ':train2:', icon: '1f686.png' },
  { key: ':metro:', icon: '1f687.png' },
  { key: ':light_rail:', icon: '1f688.png' },
  { key: ':station:', icon: '1f689.png' },
  { key: ':tram:', icon: '1f68a.png' },
  { key: ':train:', icon: '1f68b.png' },
  { key: ':bus:', icon: '1f68c.png' },
  { key: ':oncoming_bus:', icon: '1f68d.png' },
  { key: ':trolleybus:', icon: '1f68e.png' },
  { key: ':busstop:', icon: '1f68f.png' },
  { key: ':minibus:', icon: '1f690.png' },
  { key: ':ambulance:', icon: '1f691.png' },
  { key: ':fire_engine:', icon: '1f692.png' },
  { key: ':police_car:', icon: '1f693.png' },
  { key: ':oncoming_police_car:', icon: '1f694.png' },
  { key: ':taxi:', icon: '1f695.png' },
  { key: ':oncoming_taxi:', icon: '1f696.png' },
  { key: ':car:', icon: '1f697.png' },
  { key: ':red_car:', icon: '1f697.png' },
  { key: ':oncoming_automobile:', icon: '1f698.png' },
  { key: ':blue_car:', icon: '1f699.png' },
  { key: ':truck:', icon: '1f69a.png' },
  { key: ':articulated_lorry:', icon: '1f69b.png' },
  { key: ':tractor:', icon: '1f69c.png' },
  { key: ':monorail:', icon: '1f69d.png' },
  { key: ':mountain_railway:', icon: '1f69e.png' },
  { key: ':suspension_railway:', icon: '1f69f.png' },
  { key: ':mountain_cableway:', icon: '1f6a0.png' },
  { key: ':aerial_tramway:', icon: '1f6a1.png' },
  { key: ':ship:', icon: '1f6a2.png' },
  { key: ':rowboat:', icon: '1f6a3.png' },
  { key: ':speedboat:', icon: '1f6a4.png' },
  { key: ':traffic_light:', icon: '1f6a5.png' },
  { key: ':vertical_traffic_light:', icon: '1f6a6.png' },
  { key: ':construction:', icon: '1f6a7.png' },
  { key: ':rotating_light:', icon: '1f6a8.png' },
  { key: ':triangular_flag_on_post:', icon: '1f6a9.png' },
  { key: ':door:', icon: '1f6aa.png' },
  { key: ':no_entry_sign:', icon: '1f6ab.png' },
  { key: ':smoking:', icon: '1f6ac.png' },
  { key: ':no_smoking:', icon: '1f6ad.png' },
  { key: ':put_litter_in_its_place:', icon: '1f6ae.png' },
  { key: ':do_not_litter:', icon: '1f6af.png' },
  { key: ':potable_water:', icon: '1f6b0.png' },
  { key: ':non-potable_water:', icon: '1f6b1.png' },
  { key: ':bike:', icon: '1f6b2.png' },
  { key: ':no_bicycles:', icon: '1f6b3.png' },
  { key: ':bicyclist:', icon: '1f6b4.png' },
  { key: ':mountain_bicyclist:', icon: '1f6b5.png' },
  { key: ':walking:', icon: '1f6b6.png' },
  { key: ':no_pedestrians:', icon: '1f6b7.png' },
  { key: ':children_crossing:', icon: '1f6b8.png' },
  { key: ':mens:', icon: '1f6b9.png' },
  { key: ':womens:', icon: '1f6ba.png' },
  { key: ':restroom:', icon: '1f6bb.png' },
  { key: ':baby_symbol:', icon: '1f6bc.png' },
  { key: ':toilet:', icon: '1f6bd.png' },
  { key: ':wc:', icon: '1f6be.png' },
  { key: ':shower:', icon: '1f6bf.png' },
  { key: ':bath:', icon: '1f6c0.png' },
  { key: ':bathtub:', icon: '1f6c1.png' },
  { key: ':passport_control:', icon: '1f6c2.png' },
  { key: ':customs:', icon: '1f6c3.png' },
  { key: ':baggage_claim:', icon: '1f6c4.png' },
  { key: ':left_luggage:', icon: '1f6c5.png' },
  { key: ':hash:', icon: '0023-20e3.png' },
  { key: ':zero:', icon: '0030-20e3.png' },
  { key: ':one:', icon: '0031-20e3.png' },
  { key: ':two:', icon: '0032-20e3.png' },
  { key: ':three:', icon: '0033-20e3.png' },
  { key: ':four:', icon: '0034-20e3.png' },
  { key: ':five:', icon: '0035-20e3.png' },
  { key: ':six:', icon: '0036-20e3.png' },
  { key: ':seven:', icon: '0037-20e3.png' },
  { key: ':eight:', icon: '0038-20e3.png' },
  { key: ':nine:', icon: '0039-20e3.png' },
  { key: ':flag-ae:', icon: '1f1e6-1f1ea.png' },
  { key: ':flag-at:', icon: '1f1e6-1f1f9.png' },
  { key: ':flag-au:', icon: '1f1e6-1f1fa.png' },
  { key: ':flag-be:', icon: '1f1e7-1f1ea.png' },
  { key: ':flag-br:', icon: '1f1e7-1f1f7.png' },
  { key: ':flag-ca:', icon: '1f1e8-1f1e6.png' },
  { key: ':flag-ch:', icon: '1f1e8-1f1ed.png' },
  { key: ':flag-cl:', icon: '1f1e8-1f1f1.png' },
  { key: ':flag-cn:', icon: '1f1e8-1f1f3.png' },
  { key: ':cn:', icon: '1f1e8-1f1f3.png' },
  { key: ':flag-co:', icon: '1f1e8-1f1f4.png' },
  { key: ':flag-de:', icon: '1f1e9-1f1ea.png' },
  { key: ':de:', icon: '1f1e9-1f1ea.png' },
  { key: ':flag-dk:', icon: '1f1e9-1f1f0.png' },
  { key: ':flag-es:', icon: '1f1ea-1f1f8.png' },
  { key: ':es:', icon: '1f1ea-1f1f8.png' },
  { key: ':flag-fi:', icon: '1f1eb-1f1ee.png' },
  { key: ':flag-fr:', icon: '1f1eb-1f1f7.png' },
  { key: ':fr:', icon: '1f1eb-1f1f7.png' },
  { key: ':flag-gb:', icon: '1f1ec-1f1e7.png' },
  { key: ':gb:', icon: '1f1ec-1f1e7.png' },
  { key: ':uk:', icon: '1f1ec-1f1e7.png' },
  { key: ':flag-hk:', icon: '1f1ed-1f1f0.png' },
  { key: ':flag-id:', icon: '1f1ee-1f1e9.png' },
  { key: ':flag-ie:', icon: '1f1ee-1f1ea.png' },
  { key: ':flag-il:', icon: '1f1ee-1f1f1.png' },
  { key: ':flag-in:', icon: '1f1ee-1f1f3.png' },
  { key: ':flag-it:', icon: '1f1ee-1f1f9.png' },
  { key: ':it:', icon: '1f1ee-1f1f9.png' },
  { key: ':flag-jp:', icon: '1f1ef-1f1f5.png' },
  { key: ':jp:', icon: '1f1ef-1f1f5.png' },
  { key: ':flag-kr:', icon: '1f1f0-1f1f7.png' },
  { key: ':kr:', icon: '1f1f0-1f1f7.png' },
  { key: ':flag-mo:', icon: '1f1f2-1f1f4.png' },
  { key: ':flag-mx:', icon: '1f1f2-1f1fd.png' },
  { key: ':flag-my:', icon: '1f1f2-1f1fe.png' },
  { key: ':flag-nl:', icon: '1f1f3-1f1f1.png' },
  { key: ':flag-no:', icon: '1f1f3-1f1f4.png' },
  { key: ':flag-nz:', icon: '1f1f3-1f1ff.png' },
  { key: ':flag-ph:', icon: '1f1f5-1f1ed.png' },
  { key: ':flag-pl:', icon: '1f1f5-1f1f1.png' },
  { key: ':flag-pr:', icon: '1f1f5-1f1f7.png' },
  { key: ':flag-pt:', icon: '1f1f5-1f1f9.png' },
  { key: ':flag-ru:', icon: '1f1f7-1f1fa.png' },
  { key: ':ru:', icon: '1f1f7-1f1fa.png' },
  { key: ':flag-sa:', icon: '1f1f8-1f1e6.png' },
  { key: ':flag-se:', icon: '1f1f8-1f1ea.png' },
  { key: ':flag-sg:', icon: '1f1f8-1f1ec.png' },
  { key: ':flag-tr:', icon: '1f1f9-1f1f7.png' },
  { key: ':flag-us:', icon: '1f1fa-1f1f8.png' },
  { key: ':us:', icon: '1f1fa-1f1f8.png' },
  { key: ':flag-vn:', icon: '1f1fb-1f1f3.png' },
  { key: ':flag-za:', icon: '1f1ff-1f1e6.png' },
  { key: ':man-man-boy:', icon: '1f468-200d-1f468-200d-1f466.png' },
  { key: ':man-man-boy-boy:', icon: '1f468-200d-1f468-200d-1f466-200d-1f466.png' },
  { key: ':man-man-girl:', icon: '1f468-200d-1f468-200d-1f467.png' },
  { key: ':man-man-girl-boy:', icon: '1f468-200d-1f468-200d-1f467-200d-1f466.png' },
  { key: ':man-man-girl-girl:', icon: '1f468-200d-1f468-200d-1f467-200d-1f467.png' },
  { key: ':man-woman-boy:', icon: '1f468-200d-1f469-200d-1f466.png' },
  { key: ':man-woman-boy-boy:', icon: '1f468-200d-1f469-200d-1f466-200d-1f466.png' },
  { key: ':man-woman-girl:', icon: '1f468-200d-1f469-200d-1f467.png' },
  { key: ':man-woman-girl-boy:', icon: '1f468-200d-1f469-200d-1f467-200d-1f466.png' },
  { key: ':man-woman-girl-girl:', icon: '1f468-200d-1f469-200d-1f467-200d-1f467.png' },
  { key: ':man-heart-man:', icon: '1f468-200d-2764-fe0f-200d-1f468.png' },
  { key: ':man-kiss-man:', icon: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.png' },
  { key: ':woman-woman-boy:', icon: '1f469-200d-1f469-200d-1f466.png' },
  { key: ':woman-woman-boy-boy:', icon: '1f469-200d-1f469-200d-1f466-200d-1f466.png' },
  { key: ':woman-woman-girl:', icon: '1f469-200d-1f469-200d-1f467.png' },
  { key: ':woman-woman-girl-boy:', icon: '1f469-200d-1f469-200d-1f467-200d-1f466.png' },
  { key: ':woman-woman-girl-girl:', icon: '1f469-200d-1f469-200d-1f467-200d-1f467.png' },
  { key: ':woman-heart-woman:', icon: '1f469-200d-2764-fe0f-200d-1f469.png' },
  { key: ':woman-kiss-woman:', icon: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.png' },
].map(e => ({
  ...e,
  icon: `/content/images/emoji/${e.icon}`,
}));
