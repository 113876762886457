/**
 * @prettier
 */

import React from 'react';

import { Text } from 'components/input/text';
import { FormLayout, FormLayoutInput, FormLayoutLabel } from 'components/input';
import { ModalBodyContent } from 'components/modal';

type Props = {
  title: string;
  onTitleChange: (value: string) => void;
  children: JSX.Element[];
};

export const SettingsModalBodyContent: React.FunctionComponent<Props> = (props: Props) => (
  <ModalBodyContent>
    <FormLayout marginBottom>
      <FormLayoutLabel>
        <label htmlFor="title">Title</label>
      </FormLayoutLabel>
      <FormLayoutInput>
        <Text
          type="text"
          id="title"
          value={props.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onTitleChange(e.currentTarget.value)
          }
          isFullWidth
        />
      </FormLayoutInput>
    </FormLayout>
    {props.children}
  </ModalBodyContent>
);
