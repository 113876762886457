/**
 * @prettier
 */
import * as React from 'react';

import { Flex } from 'components/flex';
import { Padding } from 'components/layout';
import HorizontalRule from 'components/horizontalRule';

import { Body } from "components/ds/text";
import { UtilityIconButton } from "components/ds/button";

import { Props as ProviderProps } from '../../dataProvider';
import { SortingHoCProps } from '../../../sortingHoC';


type PassedProps = {
  errorGroupCount: number;
};

type Props = ProviderProps & SortingHoCProps & PassedProps;

export const PageNavigation: React.FunctionComponent<Props> = props => {
  var pageIndex = props.tileState ? (isNaN(props.tileState.pageIndex) ? 0 : props.tileState.pageIndex) : 0;
  const pageSize = 50; // This number has to align with what's in Mindscape.Raygun.Services.ElasticSearch.v2

  const updateIndex = (index: number) => {
    if (isNaN(index)) {
      pageIndex = 0;
    } else {
      pageIndex = index;
    }
    props.updatePageIndex(pageIndex);
  };

  const currentTotalRecords = Math.min(props.errorGroupCount, 1000);

  const currentPageFirstIndex = pageIndex * pageSize + 1;
  const currentPageLastIndex = Math.min((pageIndex * pageSize) + pageSize, currentTotalRecords);
  const paginateLeftDisabled = currentPageFirstIndex <= 1;
  const paginateRightDisabled = currentPageLastIndex >= currentTotalRecords;

  return (
    <>
      <HorizontalRule />
      <Padding bottom={8}/>

      <Flex direction="row" align="center" justify="flex-end">
        <Padding right={8}>
          <Body color={'grey600'}>
            <>
              {currentPageFirstIndex.toLocaleString()}-{currentPageLastIndex.toLocaleString()} of{' '}
              {currentTotalRecords.toLocaleString()}
            </>
          </Body>
        </Padding>

        <Flex direction="row" justify="space-between">
          <UtilityIconButton
            icon="chevron-left"
            onClick={!paginateLeftDisabled ? () => updateIndex(pageIndex - 1) : null}
            disabled={paginateLeftDisabled}
          />
          <UtilityIconButton
            icon="chevron-right"
            onClick={!paginateRightDisabled ? () => updateIndex(pageIndex + 1) : null}
            disabled={paginateRightDisabled}
          />
        </Flex>
      </Flex>
    </>
  );
};
