/** @format */

import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { Fade } from 'components/animated/fade';

import { AppState } from 'interfaces/appState';
import { useEscapeClose } from "hooks/useEscapeClose";

import { isSwitcherOpen } from '../../../selectors';
import { closeSwitcher } from '../../../actions';

import { SwitcherContainer, SwitcherOverlay, SwitcherPositionAbove } from './background';
import { Switcher } from './switcher';

type StateProps = {
  isOpen: boolean;
};

type DispatchProps = {
  closeSwitcher: () => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

// Note: <div> is required by the fade component in order to function
const UnconnectedSwitcher: FunctionComponent<Props> = ({ isOpen, closeSwitcher }) => {
  useEscapeClose(closeSwitcher, isOpen);

  return (
      <Fade duration={150} fadeIn fadeOut visible={isOpen} easing="ease-out">
        <SwitcherPositionAbove aria-hidden={!isOpen}>
          <SwitcherContainer>
            <Switcher />
          </SwitcherContainer>
          <SwitcherOverlay onClick={closeSwitcher} />
        </SwitcherPositionAbove>
      </Fade>
    );
};

const ConnectedSwitcher = connect<StateProps, DispatchProps, SuppliedProps, AppState>(
  (state) => ({
    isOpen: isSwitcherOpen(state)
  }),
  (dispatch) => ({
    closeSwitcher: () => dispatch(closeSwitcher())
  })
)(UnconnectedSwitcher);

export { ConnectedSwitcher as Switcher };
