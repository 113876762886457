/** @format **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { AppState } from 'interfaces/appState';
import { StandaloneLoadingSpinner } from 'components/loading';
import { Omit } from 'utils/types';

import { NoData } from '../noData';
import * as Models from './models';
import { LineChart, LineChartProps } from './chart';

type StateProps = {
  isLoading: boolean;
} & Models.LineChartResource;

type DispatchProps = {
  fetch: () => void;
};

type PassedProps = {
  fetch: () => void;
  isFetching: (state: AppState) => boolean;
  get: (state: AppState) => Models.LineChartResource;
} & Omit<LineChartProps, keyof Models.LineChartResource>;

type Props = StateProps & DispatchProps & PassedProps;

export const UnconnectedFetchLineChart: React.FunctionComponent<Props> = ({
  fetch,
  isLoading,
  periods,
  ...props
}) => {
  React.useEffect(fetch, []);

  if (isLoading) {
    return <StandaloneLoadingSpinner height={props.height} />;
  }

  if (isEmpty(periods)) {
    return <NoData height={props.height} />;
  }

  return <LineChart periods={periods} {...props} />;
};

export const FetchLineChart = connect<StateProps, DispatchProps, PassedProps, AppState>(
  (state, { get, isFetching }) => ({
    ...get(state),
    isLoading: isFetching(state),
  }),
  (_dispatch, { fetch }) => ({
    fetch,
  }),
)(UnconnectedFetchLineChart);
