/** @format */

import * as React from 'react';
import classnames from 'classnames';

import { Icon } from 'components/icon';

interface Props {
  color?: 'yellow' | 'blue' | 'red' | null;
  dismissable?: boolean;
  onDismiss?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const DismissButton: React.FunctionComponent<Props> = props => {
  if (props.dismissable) {
    return (
      <a
        href="#dismiss"
        className="page-banner__dismiss"
        onMouseUp={props.onDismiss}
        title="Dismiss"
      >
        <Icon set="flat" size={16} type="close" />
      </a>
    );
  } else {
    return null;
  }
};

export const Banner: React.FunctionComponent<Props> = props => {
  const className = classnames('page-banner page-banner--above', {
    'page-banner--yellow': props.color === 'yellow',
    'page-banner--blue': props.color === 'blue',
    'page-banner--red': props.color === 'red',
    'page-banner--dismissable': props.dismissable,
  });

  return (
    <div className={className}>
      <DismissButton {...props} />
      {props.children}
    </div>
  );
};

Banner.defaultProps = {
  color: null,
  dismissable: false,
};

export default Banner;
