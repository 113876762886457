/**
 * @format
 */
/* tslint:disable:max-file-line-count */

import * as React from 'react';
import { SearchResult } from 'interfaces/search';
import { filter, isEqual, assign, take, findIndex } from 'lodash';
import { SearchDropdownComponent } from './searchDropdownComponent';
import { SearchDropdownContainerProps as Props } from './models';

type State = {
  pending: boolean;
  openDropdown: boolean;
  noResultsText: string;
  currentQuery: string;
  currentMultiSelectValues: SearchResult[];
};

const SEARCH_DROPDOWN = '.js-search-dropdown';
const SEARCH_DROPDOWN_OPTION = '.js-dropdown-option';
const SEARCH_INPUT = '.js-search-input';
const SUBMIT_BUTTON = '.js-submit-button';

class SearchDropdownContainer extends React.Component<Props, State> {
  private searchInput: HTMLInputElement;
  private dropdownButton: HTMLElement;
  private currentSearchResultElements: HTMLElement[] = [];
  private currentDropdownElement: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.handleOptionKeyUp = this.handleOptionKeyUp.bind(this);
    this.handleOptionKeyDown = this.handleOptionKeyDown.bind(this);
    this.handleSearchKeyUp = this.handleSearchKeyUp.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchInputFocus = this.handleSearchInputFocus.bind(this);
    this.handleSearchInputBlur = this.handleSearchInputBlur.bind(this);
    this.handleUpdateMultiSelectValues = this.handleUpdateMultiSelectValues.bind(this);
    this.handleManualSubmit = this.handleManualSubmit.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.handleUnselectValue = this.handleUnselectValue.bind(this);
    this.forceOpenDropdown = this.forceOpenDropdown.bind(this);
    this.forceCloseDropdown = this.forceCloseDropdown.bind(this);
    this.searchDropdownComponentRef = this.searchDropdownComponentRef.bind(this);
  }

  static defaultProps: Partial<Props> = {
    searchPool: [],
    loadingData: false,
    isLarge: true,
    selectMultiple: false,
    showManualSubmit: false,
    showManualCancel: false,
    openDropdown: false,
    closeOnExternalClick: true,
    hideSearchSection: false,
    showNoResultsText: true,
    noResultsText: 'No search results',
    cancelText: 'Cancel',
    query: '',
    enableKeyboardControl: true,
    currentData: [],
    handleCancel: () => null,
    handleOpen: () => null,
    handleClose: () => null,
    handleMultiSelect: () => null,
    handleManualSubmit: () => null,
    handleSearchInputFocusChange: () => null,
    handleEnterKey: () => null,
    disabled: false,
    autoApplyValue: false,
  };

  state: State = {
    pending: true,
    currentMultiSelectValues: [],
    noResultsText: 'No search results',
    currentQuery: '',
    openDropdown: false,
  };

  // Lifecycle
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.currentData.length) {
      this.setState({
        currentMultiSelectValues: nextProps.currentData,
      });
    }
  }

  // Event handlers
  handleFocusFirst() {
    if (!this.props.enableKeyboardControl) {
      return;
    }
    const firstResult = this.currentDropdownElement
      .querySelector(SEARCH_DROPDOWN)
      .querySelectorAll(SEARCH_DROPDOWN_OPTION)[0] as HTMLElement;

    if (!!firstResult) {
      firstResult.focus();
    }
  }

  handleOptionKeyUp(e: React.KeyboardEvent<HTMLElement>) {
    if (!this.props.enableKeyboardControl) {
      return;
    }
    const searchDropdown = this.currentDropdownElement.querySelector(SEARCH_DROPDOWN);
    const searchInput = searchDropdown.querySelector(SEARCH_INPUT) as HTMLInputElement;
    const submitButton = searchDropdown.querySelector(SUBMIT_BUTTON) as HTMLElement;
    const curr = e.currentTarget as HTMLElement;
    switch (e.key.toLowerCase()) {
      case 'enter':
        // Manually trigger click when enter key is pressed while focused on checkbox
        const checkbox = curr.querySelector('input');
        const evt = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        if (!!checkbox) {
          checkbox.dispatchEvent(evt);
          submitButton.focus();
        } else {
          searchInput.focus();
        }
        break;
      case 'escape':
        this.props.handleCancel();
        break;
      default:
        return;
    }
    e.preventDefault();
  }

  handleOptionKeyDown(e: React.KeyboardEvent<HTMLElement>) {
    if (!this.props.enableKeyboardControl) {
      return;
    }
    const searchDropdown = this.currentDropdownElement.querySelector(SEARCH_DROPDOWN);
    const options = searchDropdown.querySelectorAll(SEARCH_DROPDOWN_OPTION);
    const searchInput = searchDropdown.querySelector(SEARCH_INPUT) as HTMLInputElement;
    const curr = e.currentTarget as HTMLElement;
    const index = Array.prototype.indexOf.call(options, curr);
    const prev = options[index - 1] as HTMLElement;
    const next = options[index + 1] as HTMLElement;

    switch (e.key.toLowerCase()) {
      case 'arrowup':
      case 'up':
        if (!!prev) {
          prev.focus();
        } else {
          searchInput.focus();
        }
        break;
      case 'arrowdown':
      case 'down':
        if (!!next) {
          next.focus();
        } else {
          searchInput.focus();
        }
        break;
      default:
        return;
    }
    e.preventDefault();
  }

  handleSearchKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    e.preventDefault();
    const key = e.key.toLowerCase();
    if (key === 'enter') {
      this.handleEnterKey(e.currentTarget.value);
      return;
    }
    if (key === 'arrowdown' || key === 'down') {
      this.handleFocusFirst();
    }
  }

  handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const textValue = e.currentTarget.value;
    this.setState({ currentQuery: textValue });
    this.props.handleQueryChange(textValue);
  }

  handleSearchInputFocus() {
    this.props.handleSearchInputFocusChange(true);
  }

  handleSearchInputBlur() {
    this.props.handleSearchInputFocusChange(false);
  }

  handleUpdateMultiSelectValues(values: SearchResult[]) {
    this.setState({ currentMultiSelectValues: values });
  }

  handleManualSubmit() {
    if (this.state.currentMultiSelectValues.length) {
      this.props.handleResultSelect(this.state.currentMultiSelectValues, true);
      this.reset();
    }
    this.props.handleManualSubmit();
  }

  handleResultSelect(values: SearchResult[], isMultiSelect?: boolean) {
    this.props.handleResultSelect(values, isMultiSelect);
    this.reset();
  }

  handleOpen() {
    this.props.handleOpen();
  }

  handleClose() {
    this.props.handleClose();
    this.reset();
  }

  handleCancel() {
    this.reset();
    this.props.handleCancel();
  }

  handleEnterKey(query: string) {
    this.props.handleEnterKey(query);
  }

  handleUnselectValue(result: SearchResult) {
    const newValues: SearchResult[] = filter(
      this.state.currentMultiSelectValues,
      r => r.id !== result.id,
    );
    this.setState({ currentMultiSelectValues: newValues });
  }
  getFilteredSearchResults(): SearchResult[] {
    const searchQuery = this.state.currentQuery;

    let currentSearchResults: SearchResult[] = this.props.searchPool.filter(
      (item: SearchResult) => {
        return item.displayName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
      },
    );

    if (this.props.limit) {
      currentSearchResults = take(currentSearchResults, this.props.limit);
    }

    // Remove currently selected multi-select values
    if (this.state.currentMultiSelectValues.length > 0) {
      currentSearchResults = currentSearchResults.filter((item: SearchResult) => {
        return (
          findIndex(
            this.state.currentMultiSelectValues,
            v => v.displayName === item.displayName,
          ) === -1
        );
      });
    }

    if (this.props.allowFreeText && searchQuery.length > 0) {
      const customResult: SearchResult = {
        id: searchQuery,
        displayName: searchQuery,
      };
      currentSearchResults.unshift(customResult);
    }

    return currentSearchResults;
  }

  reset() {
    this.setState({
      currentQuery: '',
      currentMultiSelectValues: [],
    });
  }

  forceOpenDropdown() {
    this.setState({ openDropdown: true });
  }

  forceCloseDropdown() {
    this.setState({ openDropdown: false });
  }

  searchDropdownComponentRef(el: HTMLElement) {
    this.currentDropdownElement = el;
  }

  render() {
    const searchDropdownComponentProps = assign({}, this.props, {
      currentMultiSelectValues: this.state.currentMultiSelectValues,
      currentQuery: this.state.currentQuery,
      pending: this.state.pending,
      handleOpen: this.handleOpen,
      handleClose: this.handleClose,
      handleCancel: this.handleCancel,
      handleSearchKeyUp: this.handleSearchKeyUp,
      handleSearchChange: this.handleSearchChange,
      handleSearchInputFocus: this.handleSearchInputFocus,
      handleSearchInputBlur: this.handleSearchInputBlur,
      handleOptionKeyUp: this.handleOptionKeyUp,
      handleOptionKeyDown: this.handleOptionKeyDown,
      handleUnselectValue: this.handleUnselectValue,
      handleManualSubmit: this.handleManualSubmit,
      handleUpdateMultiSelectValues: this.handleUpdateMultiSelectValues,
      handleResultSelect: this.handleResultSelect,
      forceOpenDropdown: this.forceOpenDropdown,
      forceCloseDropdown: this.forceCloseDropdown,
      openDropdown: this.props.openDropdown,
      filteredSearchResults: this.getFilteredSearchResults(),
    });

    return (
      <div ref={this.searchDropdownComponentRef}>
        <SearchDropdownComponent {...searchDropdownComponentProps} />
      </div>
    );
  }
}

export { SearchDropdownContainer as SearchDropdown };