/** @format */

// Constants used throughout the app

export const CONTACT_EMAIL = 'support@raygun.com';

export const CONTACT_US_URL = 'https://raygun.com/about/contact';

export const STATUS_URL = 'http://status.raygun.com/';

export const SALES_EMAIL = 'sales@raygun.com';

export const SUPPORT_CLASS = 'hs-cta-trigger-button hs-cta-trigger-button-165245250960';

export const UNLIMITED_USAGE = 1000000000;

export const MINIMUM_RESERVED_EVENT_VALUE = 100000;
