/** @format */

import { IStyle } from 'fela';
import { BLUE_GREY } from 'app/theme/materialDS';
import { ThemeType } from '../app/theme';

export function scrollbar(style: IStyle): IStyle {
  return {
    ['::-webkit-scrollbar-thumb' as any]: {
      backgroundColor: BLUE_GREY.blueGrey200,
      borderRadius: '8px',
      border: '4px solid transparent',
      backgroundClip: 'content-box',
    },
    ['::-webkit-scrollbar' as any]: {
      width: '16px',
      height: '16px',
      marginTop: '6px',
      cursor: 'pointer',
      backgroundColor: BLUE_GREY.blueGrey5,
      border: `1px solid ${BLUE_GREY.blueGrey75}`,
    },
    ['::-webkit-scrollbar-track' as any]: {
      backgroundColor: BLUE_GREY.blueGrey5,
    },
    ...style,
  };
}
