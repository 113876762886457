/** @format **/

import { useState, useEffect } from "react";

export function useHubSpotLoaded(): boolean {
  // There are 2 ways to check if the HubSpot script has been loaded:
  // 1. The value hubSpotLoaded on the window
  // 2. Listening to the hubSpotLoaded event
  // This hook will check both of these to determine if HubSpot has been loaded successfully.
  // Check Track.cshtml for where the HubSpot script loading occurs.

  const [isHubSpotLoaded, setIsHubSpotLoaded] = useState(true);

  function handleHubSpotLoaded(event: CustomEvent<{ loaded: boolean }>) {
    const loaded = event.detail.loaded;
    setIsHubSpotLoaded(loaded);
  }

  useEffect(() => {
    const hubSpotLoadedConfig = (window as any).hubSpotLoaded;
    if (hubSpotLoadedConfig !== 'undefined') {
      setIsHubSpotLoaded(hubSpotLoadedConfig);
    }

    window.addEventListener('hubSpotLoaded', handleHubSpotLoaded);
    return () => window.removeEventListener('hubSpotLoaded', handleHubSpotLoaded);
  }, []);

  return isHubSpotLoaded;
}
