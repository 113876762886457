/** @format */
import React from 'react';
import { connect, Dispatch } from 'react-redux';

import { AppState } from 'interfaces/appState';
import { SearchInput } from 'components/ds/inputs';
import { Margin } from 'components/layout';

import { getFilterText, showFilterInput, isApplicationSwitcher } from '../../../selectors';
import { setFilter } from '../../../actions';

type StateProps = {
  filter: string;
  showFilter: boolean;
  forApplications: boolean;
};

type DispatchProps = {
  setFilter: (val: string) => void;
};

type SuppliedProps = {};

type Props = StateProps & DispatchProps & SuppliedProps;

export const UnconnectedFilter: React.FunctionComponent<Props> = ({ filter, showFilter, forApplications, setFilter }) => (
  <>
    {showFilter && (
      <Margin bottom="8" largeScreens={{ bottom: '16' }}>
        <SearchInput 
          value={filter}
          onChange={e => setFilter(e.target.value)}
          clearable
          block
          placeholder={`Filter ${forApplications ? 'applications' : 'dashboards'}`}
        />
      </Margin>
    )}
  </>
);

const ConnectedFilter = connect<StateProps, DispatchProps, SuppliedProps>(
  (state: AppState) => ({
    filter: getFilterText(state),
    showFilter: showFilterInput(state),
    forApplications: isApplicationSwitcher(state)
  }),
  (dispatch: Dispatch<AppState>) => ({
    setFilter: (val:string) => dispatch(setFilter(val))
  })
)(UnconnectedFilter);

ConnectedFilter.defaultProps = {
  placeholder: 'Filter'
};

export { ConnectedFilter as Filter };