/** @format **/

import React from 'react';

import { Omit } from 'utils/types';

import {
  TrackFelaProps,
  ThumbFelaProps,
  FelaProps,
  SwitchWrapper,
  SwitchTrack,
  SwitchThumb,
  SwitchInput,
} from './components';

type PublicProps = {
  onChange: (checked: boolean) => void;
  disabled: boolean;
  checked: boolean;
  small: boolean;
  forMenuItem?: boolean;
} & FelaProps &
  Omit<ThumbFelaProps, 'onClick'> &
  Omit<TrackFelaProps, 'onClick'>;

export class Switch extends React.Component<PublicProps> {
  static defaultProps: Pick<
    PublicProps,
    | 'disabled'
    | 'thumbColor'
    | 'focusCheckedColor'
    | 'focusUncheckedColor'
    | 'hoverCheckedColor'
    | 'hoverUncheckedColor'
    | 'small'
    | 'trackCheckedColor'
    | 'trackUncheckedColor'
    | 'trackCheckedDisabledColor'
    | 'trackUncheckedDisabledColor'
    | 'trackUncheckedFocusColor'
  > = {
    disabled: false,
    small: false,
    thumbColor: 'white',
    focusCheckedColor: 'green100',
    focusUncheckedColor: 'blueGrey100',
    hoverCheckedColor: 'green50',
    hoverUncheckedColor: 'blueGrey50',
    trackCheckedColor: 'green500',
    trackCheckedDisabledColor: 'green200',
    trackUncheckedColor: 'blueGrey200',
    trackUncheckedDisabledColor: 'grey200',
    trackUncheckedFocusColor: 'blueGrey300',
  };

  render() {
    const { disabled, small, checked, forMenuItem, id, className } = this.props;

    return (
      <SwitchWrapper
        as={forMenuItem ? 'div' : 'label'}
        disabled={disabled}
        small={small}
        checked={checked}
        id={id}
        className={className}
      >
        <SwitchInput
          as="input"
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            this.props.onChange(e.currentTarget.checked)
          }
        />
        <SwitchThumb
          small={small}
          disabled={disabled}
          hoverUncheckedColor={this.props.hoverUncheckedColor}
          hoverCheckedColor={this.props.hoverCheckedColor}
          focusCheckedColor={this.props.focusCheckedColor}
          focusUncheckedColor={this.props.focusUncheckedColor}
          thumbColor={this.props.thumbColor}
          checked={checked}
        />
        <SwitchTrack
          disabled={disabled}
          small={small}
          checked={checked}
          trackUncheckedDisabledColor={this.props.trackUncheckedDisabledColor}
          trackCheckedDisabledColor={this.props.trackCheckedDisabledColor}
          trackUncheckedColor={this.props.trackUncheckedColor}
          trackCheckedColor={this.props.trackCheckedColor}
          trackUncheckedFocusColor={this.props.trackUncheckedFocusColor}
        />
      </SwitchWrapper>
    );
  }
}
